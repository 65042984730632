<ng-container *ngIf="!page">
  <div class="more-info-group">
    <div class="more-info-subgroup">
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:flange"
          svg_icon_right="icons:arrow-right"
          (click)="toFlangeDocuments()"
          >{{ i18n.string("flange") }}
        </app-more-info>
      </button>
      <button>
        <app-more-info
          [variant]="'more-info-flat'"
          [grouped]="true"
          svg_icon="icons:steamtrap"
          svg_icon_right="icons:arrow-right"
          (click)="toSteamtrapDocuments()"
          >{{ i18n.string("steamtrap") }}
        </app-more-info>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!!page">
  <ng-container *ngFor="let docType of availableDocTypes">
    <div class="card" [class.mobile]="isMobile" [class.tablet]="isTablet">
      <nz-card>
        <div class="card-header bottom-space">
          <i nz-icon class="inline-error-icon" nzType="icons:document"></i>
          <h4>{{ i18n.string("doctype_label_" + docType) }}</h4>
        </div>

        <div
          *ngIf="availableLanguages[docType].length > 1"
          class="bottom-space"
        >
          <label class="detail">{{ i18n.string("select_language") }}</label>
          <app-select
            [options]="availableLanguages[docType]"
            [(selected)]="selectedLanguages[docType]"
            [placeholder]="i18n.string('select_language')"
            [disabled]="false"
            [optional]="false"
          ></app-select>
        </div>
        <app-nav-button
          [primary]="true"
          svg_icon="icons:eye"
          (click)="openDocument(docType)"
        >
          {{ i18n.string("show_document") }}</app-nav-button
        >
      </nz-card>
    </div>
  </ng-container>
</ng-container>
