import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';

import { Dictionary } from '../util/dictionary';
import { Exception } from '../util/exception';
import { environment } from './../../environments/environment';

const storageIndexKey = 'cc_storage_index_lp';
const authTokenKey = 'cc_js_token_lp';
const currentUserKey = 'cc_current_user_lp';
const currentUserEmailKey = 'cc_current_user_mail_lp';

// service for keeping a separate local storage for each user
@Injectable()
export class StorageService {
  private _keys = new Dictionary<string[]>();

  constructor() {
    const index = localStorage.getItem(storageIndexKey);
    if (index) {
      this._keys = new Dictionary<string[]>(JSON.parse(index));
    }
  }

  get Keys(): string[] {
    try {
      const output = this._keys.get(this.UserName);
      return output ? output : [];
    } catch (e) {
      return [];
    }
  }

  get Token(): string {
    if (this.hasKey(authTokenKey)) return this.getString(authTokenKey);
    else return '';
  }
  set Token(val: string) {
    this.setString(authTokenKey, val);
  }

  get UserEmail(): string {
    if (this.hasKey(currentUserEmailKey))
      return this.getString(currentUserEmailKey);
    else throw new Exception('User not logged in!');
  }
  set UserEmail(val: string) {
    this.setString(currentUserEmailKey, val);
  }

  // the following is used for key hashing, therefore it cannot use the keyHash() function (which isn't necessary here anyway)
  get UserName(): string {
    if (localStorage.getItem(currentUserKey) !== null)
      return localStorage.getItem(currentUserKey);
    else throw new Exception('User not logged in!');
  }
  set UserName(val: string) {
    localStorage.setItem(currentUserKey, val);
  }

  public hasKey(key): boolean {
    return this.Keys.includes(key) && typeof this.getString(key) === 'string';
  }

  private registerKey(key) {
    let array = this.Keys;
    if (!array || !(array.length > 0)) {
      this._keys.set(this.UserName, []);
      array = this.Keys;
    }
    if (!array.includes(key)) array.push(key);
    localStorage.setItem(storageIndexKey, JSON.stringify(this._keys));
  }

  private unregisterKey(key) {
    const array = this.Keys;
    const index = array.findIndex((_key) => _key === key);
    if (index >= 0) {
      // key is registered in _keys
      array.splice(index, 1);
      this._keys.set(this.UserName, array);
    }
    localStorage.setItem(storageIndexKey, JSON.stringify(this._keys));
  }

  public getObject(key: string): any {
    return JSON.parse(this.getString(key));
  }

  public getString(key: string): string {
    return localStorage.getItem(this.keyHash(key));
  }

  public setObject(key: string, value: Object) {
    this.setString(key, JSON.stringify(value));
  }

  public setString(key: string, value: string) {
    if (!!value) {
      localStorage.setItem(this.keyHash(key), value);
      if (!this.hasKey(key)) this.registerKey(key);
    } else if (this.hasKey(key)) {
      localStorage.removeItem(this.keyHash(key));
      this.unregisterKey(key);
    }
  }

  public clear() {
    // another terrible hack to allow redirects
    const redirect_parameter = localStorage.getItem(
      environment['rm_parameter_key']
    );

    this._keys = new Dictionary<string[]>();
    localStorage.clear();

    if (redirect_parameter) {
      localStorage.setItem('cc_rm_param', redirect_parameter);
    }
  }
  private keyHash(key: string): string {
    function _hash(input: string): string {
      let hash = 0;
      if (input.length === 0) {
        return hash.toString();
      }
      for (let i = 0; i < input.length; i++) {
        const char = input.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash.toString();
    }

    return _hash(key + '.' + this.UserName);
  }
}
