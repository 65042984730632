<div class="content content-more-margin desktop-wide">
  <div *ngIf="!showUSCountryPage">
    <h1>{{ i18n.string("terms_of_use_new_title") }}</h1>
    <h3>{{ i18n.string("scope_1") }}</h3>
    <p [innerHTML]="i18n.string('scope_1_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_1_2_content')"></p>
    <p [innerHTML]="i18n.string('scope_1_3_content')"></p>
    <h3>{{ i18n.string("scope_2") }}</h3>
    <p [innerHTML]="i18n.string('scope_2_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_2_2_content')"></p>
    <p [innerHTML]="i18n.string('scope_2_3_content')"></p>
    <p [innerHTML]="i18n.string('scope_2_4_content')"></p>
    <p [innerHTML]="i18n.string('scope_2_5_content')"></p>
    <p [innerHTML]="i18n.string('scope_2_6_content')"></p>
    <p [innerHTML]="i18n.string('scope_2_7_content')"></p>
    <h3>{{ i18n.string("scope_3") }}</h3>
    <p [innerHTML]="i18n.string('scope_3_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_3_1_a_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_1_b_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_1_c_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_1_d_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_1_e_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_1_f_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_content')"></p>
    <p [innerHTML]="i18n.string('scope_3_2_a_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_b_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_c_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_d_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_e_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_f_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_g_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_h_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_3_2_i_content')" class="mar-left"></p>
    <h3>{{ i18n.string("scope_4") }}</h3>
    <p [innerHTML]="i18n.string('scope_4_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_4_2_content')"></p>
    <h3>{{ i18n.string("scope_5") }}</h3>
    <p [innerHTML]="i18n.string('scope_5_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_5_2_content')"></p>
    <p [innerHTML]="i18n.string('scope_5_3_content')"></p>
    <h3>{{ i18n.string("scope_6") }}</h3>
    <p [innerHTML]="i18n.string('scope_6_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_6_2_content')"></p>
    <h3>{{ i18n.string("scope_7") }}</h3>
    <p [innerHTML]="i18n.string('scope_7_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_7_2_content')"></p>
    <p [innerHTML]="i18n.string('scope_7_3_content')"></p>
    <p [innerHTML]="i18n.string('scope_7_4_content')"></p>
    <p [innerHTML]="i18n.string('scope_7_5_content')"></p>
    <h3>{{ i18n.string("scope_8") }}</h3>
    <p [innerHTML]="i18n.string('scope_8_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_8_2_content')"></p>
    <p [innerHTML]="i18n.string('scope_8_3_content')"></p>
    <h3>{{ i18n.string("scope_9") }}</h3>
    <p [innerHTML]="i18n.string('scope_9_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_9_1_a_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_9_1_b_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_9_1_c_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_9_1_d_content')" class="mar-left"></p>
    <p [innerHTML]="i18n.string('scope_9_2_content')"></p>
    <h3>{{ i18n.string("scope_10") }}</h3>
    <p [innerHTML]="i18n.string('scope_10_1_content')"></p>
    <p [innerHTML]="i18n.string('scope_10_2_content')"></p>
    <p [innerHTML]="i18n.string('scope_10_3_content')"></p>
    </div>
  <div *ngIf="showUSCountryPage">
  <h1>{{ i18n.string("terms_of_use_title") }}</h1>
  <h3>{{ i18n.string("terms_introduction") }}</h3>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("terms_introduction_content") }}</p> -->
  <p>{{ i18n.string("terms_introduction_content_us") }}</p>

  <p>{{ i18n.string("terms_introduction_content1") }}</p>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("terms_introduction_content2") }}</p> -->
  <p>{{ i18n.string("terms_introduction_content2_us") }}</p>

  <p>{{ i18n.string("terms_introduction_content3") }}</p>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("terms_introduction_content4") }}</p> -->

  <h3>{{ i18n.string("via_loctite_pulse_app") }}</h3>

  <p>{{ i18n.string("loctite_pulse_content") }}</p>

  <h4>{{ i18n.string("loctite_pulse_function_title") }}</h4>

  <p>
    <span class="body-bold">{{ i18n.string("map_table_overview") }}</span>
    {{ i18n.string("map_table_overview_content") }}
  </p>

  <p>
    <span class="body-bold">{{ i18n.string("data_display") }}</span>
    {{ i18n.string("data_display_content") }}
  </p>

  <p>
    <span class="body-bold">{{
      i18n.string("maintenance_documentation")
    }}</span>
    {{ i18n.string("maintenance_documentation_content") }}
  </p>

  <p>
    <span class="body-bold">{{
      i18n.string("loctite_pulse_notifications")
    }}</span>
    {{ i18n.string("loctite_pulse_notifications_content") }}
  </p>

  <p>
    <span class="body-bold">{{ i18n.string("push_notifications") }}</span>
    {{ i18n.string("push_notifications_content") }}
  </p>

  <p>
    <span class="body-bold">{{ i18n.string("email_notifications") }}</span>
    {{ i18n.string("email_notifications_content") }}
  </p>

  <p>
    <span class="body-bold">{{ i18n.string("installation_agent") }}</span>
    {{ i18n.string("installation_agent_content") }}
  </p>

  <h3>{{ i18n.string("general_terms_loctite_pulse_app") }}</h3>

  <h4>{{ i18n.string("copyright_title") }}</h4>

  <p>{{ i18n.string("copyright_content") }}</p>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("copyright_content1") }}</p> -->
  <p>{{ i18n.string("copyright_content1_us") }}</p>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("copyright_content2") }}</p> -->
  <p>{{ i18n.string("copyright_content2_us") }}</p>

  <h4>{{ i18n.string("brands_title") }}</h4>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("brands_content") }}</p> -->
  <p>{{ i18n.string("brands_content_us") }}</p>

  <h4>{{ i18n.string("access_data_title") }}</h4>

  <p>{{ i18n.string("access_data_content") }}</p>

  <h4>{{ i18n.string("disclaimer_third_party_title") }}</h4>

  <p>{{ i18n.string("disclaimer_third_party_content") }}</p>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("disclaimer_third_party_content1") }}</p> -->
  <p>{{ i18n.string("disclaimer_third_party_content1_us") }}</p>

  <p>{{ i18n.string("disclaimer_third_party_content2") }}</p>

  <p>{{ i18n.string("disclaimer_third_party_content3") }}</p>

  <h4>{{ i18n.string("disclaimer_title") }}</h4>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("disclaimer_content") }}</p> -->
  <p>{{ i18n.string("disclaimer_content_us") }}</p>

  <p>{{ i18n.string("disclaimer_content1") }}</p>

  <p>{{ i18n.string("disclaimer_content2") }}</p>

  <p>{{ i18n.string("disclaimer_content3") }}</p>

  <!-- <p *ngIf="!showUSCountryPage">{{ i18n.string("disclaimer_content4") }}</p> -->
  <p>{{ i18n.string("disclaimer_content4_us") }}</p>

  <p>{{ i18n.string("disclaimer_content5") }}</p>

  <h4>{{ i18n.string("termination_loctite_pulse_app") }}</h4>

  <p>{{ i18n.string("termination_loctite_pulse_content") }}</p>

  <h4>{{ i18n.string("henkel_products") }}</h4>

  <p>{{ i18n.string("henkel_products_content") }}</p>

  <h4>{{ i18n.string("miscellaneous_title") }}</h4>

  <p>{{ i18n.string("miscellaneous_content") }}</p>
 </div>
  <div class="back-btn" *ngIf="!this.isAuth">
    <app-nav-button
      [darksecondary]="true"
      svg_icon="icons:arrow-left"
      (click)="gotoLogin()"
      >{{ i18n.string("back") }}
    </app-nav-button>
  </div>
  <app-scroll-to-top></app-scroll-to-top>
</div>
