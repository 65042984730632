export class Documentation {
  public documentationId: number;
  public userName: string;
  public created: Date;
  public saved: Date;

  public objectID: number;
  public sensorID: number;

  public documentationType: DocumentationType;
  public note: string;

  public images: DocumentationAttachment[] = [];
  public files: DocumentationAttachment[] = [];
  public audios: DocumentationAttachment[] = [];
}

export class DocumentationAttachment {
  public documentationId: number;
  public attachmentId: number;
  public created: Date;

  public name: string;

  public attachmentType: AttachmentType;
  public storageLink: string;
  public thumbnailLink?: string;
}

export type DocumentationType = 'repair' | 'fixed' | 'false_alert' | 'other';
export type AttachmentType = 'image' | 'file' | 'audio';
