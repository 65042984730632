import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})
export class MoreInfoComponent implements OnInit {
  @Input() variant = "more-info-square"; /*more-info-flat, more-info-square, opt, mini-opt, option*/
  @Input() grouped = false;
  @Input() svg_icon = null;
  @Input() svg_icon_right = null;
  @Input() blackBg = false;
  @Input() fillWhite = false;
  constructor() { }

  ngOnInit() {
  }

}
