import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { StorageService } from '../../../../cc-framework/services/storage.service';
import { environment } from '../../../../environments/environment';
import { AssetDetail } from '../../../model/asset';
import { Notification } from '../../../model/object';
import { Sensor } from '../../../model/sensor';
import { UserSettings } from '../../../model/settings';
import { AssetsService } from '../../../services/assets.service';
import { I18nService } from '../../../services/i18n.service';
import { SettingsService } from '../../../services/settings.service';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-repair-and-replace',
  templateUrl: './repair-and-replace.component.html',
  styleUrls: ['./repair-and-replace.component.scss'],
})
export class RepairAndReplaceComponent implements OnInit, AfterViewInit {
  public asset: AssetDetail = null;
  public sensorID: number = -1;
  public loading: boolean = false;
  public error: boolean = false;
  public subscription: Subscription = null;
  public settings: UserSettings = null;

  get Sensor(): Sensor {
    if (!this.asset || !this.sensorID) return null;
    return this.asset.sensors.find(
      (sensor) => sensor.sensorID === this.sensorID
    );
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get CriticalOverride() {
    if (!this.asset) return {};
    const output = {};
    this.asset.notifications.map((noti: Notification) => {
      output[noti.measurementTypeID] = noti.isCritical;
    });
    return output;
  }

  get Telemetry(): any {
    if (!this.asset) return [];
    const co = this.CriticalOverride;
    return this.asset.SensorTileTelemetry.map((_telemetry) => {
      _telemetry.telemetry = _telemetry.telemetry.map((telemetry) => {
        if (!!co && co[telemetry.measurementTypeID]) telemetry.critical = true;
        return telemetry;
      });
      return _telemetry;
    })
      .flat(2)
      .map((item) => item.telemetry)
      .flat(2)
      .find((item) => item.sensor.sensorID === this.sensorID);
  }

  get IsSteamTrap(): boolean {
    return this.asset.steamTrap.length > 0;
  }

  get HasSteamTrapAnalytics(): boolean {
    return (
      !!this.IsSteamTrap &&
      !!this.asset.steamTrap.find(
        (telemetry) => telemetry.measurementTypeID === 1068
      )
    );
  }
  get SteamTrapOutlet(): any {
    return this.asset.steamTrap.find(
      (telemetry) => telemetry.measurementTypeID === 1074
    );
  }

  get InstallationAgentURL(): string {
    return (
      environment.installation_agent_url +
      '/login/refauth/' +
      encodeURIComponent(this.storage.Token) +
      '/' +
      // encodeURIComponent('sensor-connect/' + this.asset.objectID)
      encodeURIComponent(
        btoa(
          JSON.stringify({
            targetUrl:
              'sensor-connect/' + this.asset.objectID + '/' + this.sensorID,
          })
        )
      )
    );
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private ui: UiService,
    public i18n: I18nService,
    private assetsService: AssetsService,
    private settingsService: SettingsService,
    private changeRef: ChangeDetectorRef,
    private location: Location,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.ui.onNavBack(null);
    this.route.paramMap.subscribe((route) => this.onParamMapChange(route));
  }

  onParamMapChange(result: any) {
    if (!this.asset || this.asset.objectID !== +result.params['id'])
      this.refresh(+result.params['id']);

    this.sensorID = +result.params['sensorid'];
  }

  ngAfterViewInit(): void {
    this.ui.OnOrientationChange.subscribe(() => this.changeRef.detectChanges());
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  refresh(assetID: number, disableCache = false) {
    this.loading = true;
    this.error = false;
    if (!!this.subscription) this.subscription.unsubscribe();
    this.subscription = this.settingsService
      .getUserSettings()
      .subscribe((settings: UserSettings) => {
        this.settings = settings;
        this.assetsService
          .getAsset(assetID, disableCache, settings.MeasurementSystem)
          .subscribe(
            (result: { asset: AssetDetail; breadcrumbsLoading: boolean }) => {
              this.loading = false;
              this.asset = result.asset;
              this.changeRef.detectChanges();
            },
            (error) => {
              this.loading = false;
              this.error = true;
              this.changeRef.detectChanges();
            }
          );
      });
  }
  onBack() {
    if (!!this.asset && this.asset.objectID) {
      this.router.navigate(['asset', this.asset.objectID, this.sensorID]);
    } else {
      this.location.back();
    }
  }
}
