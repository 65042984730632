<div
  class="docu-item"
  [class.allButton]="variant !== 'image' && variant !== 'file'"
  [class.desktop]="isDesktop"
  [class.mobile]="isMobile"
  [class.tablet]="isTablet"
  click="onAllButtonClick()"
>
  <button class="docu-header body-bold" (click)="onHeaderClick()">
    <i nz-icon *ngIf="svg_icon" [nzType]="svg_icon" class="svg-icon"></i>
    <span>
      <ng-content></ng-content>
    </span>
    <i
      nz-icon
      *ngIf="variant === 'text' && content && content.length > 0"
      [nzType]="'icons:edit'"
      class="svg-icon right-icon"
    ></i>
  </button>

  <div class="hint detail" *ngIf="variant === 'image'">
    <div>Format: .jpg, .jpeg, .png</div>
    <div [class.red-text]="fileSizeError">{{ i18n.string("max_size") }}</div>
  </div>

  <div class="hint detail" *ngIf="variant === 'file'">
    <div>Format: .xlsx, .docx, .pptx, .pdf</div>
    <div [class.red-text]="fileSizeError">{{ i18n.string("max_size") }}</div>
  </div>

  <div
    class="text-content"
    *ngIf="variant === 'text' && content && content.length > 0"
  >
    <span>{{ content }}</span>
  </div>

  <div class="image-content" *ngIf="variant === 'image'">
    <!-- Hidden input -->
    <input
      #imageInputUpload
      type="file"
      style="display: none"
      accept="image/*"
      (change)="fileChanged($event, 'image')"
    />
    <input
      #imageInputCamera
      type="file"
      style="display: none"
      accept="image/*"
      (change)="fileChanged($event, 'image')"
      capture="environment"
    />

    <!-- Thumbnails/Previews -->
    <div class="preview-container" *ngFor="let item of content">
      <img class="photo-preview" src="{{ item.thumbnailLink }}" />
      <div class="close-icon" (click)="deleteAttachment(item)">
        <i nz-icon nzType="icons:exit" class="svg-icon fillwhite mini-icon"></i>
      </div>
    </div>

    <!-- Upload -->
    <div *ngIf="imageUploadProgress">
      <div
        class="photo-preview"
        (click)="addImage()"
        style="cursor: pointer"
        [ngClass]="{
          'progress-complete': imageUploadProgress.state === 'DONE'
        }"
      >
        <progress
          max="100"
          value="{{ imageUploadProgress.progress }}"
        ></progress>
        <span>{{ imageUploadProgress.progress }} %</span>
      </div>
    </div>
    <!-- Add button -->
    <div
      *ngIf="content.length > 0"
      class="photo-preview"
      (click)="addImage()"
      style="cursor: pointer"
    >
      <i nz-icon nzType="icons:photoadd" class="svg-icon photoadd"></i>
    </div>
  </div>

  <div
    class="file-content"
    [class.noMarginTop]="!(content.length > 0)"
    *ngIf="variant === 'file'"
  >
    <!-- Hidden input -->
    <input
      #fileInput
      type="file"
      style="display: none"
      accept=".xlsx,.docx,.pptx,.pdf"
      (change)="fileChanged($event, 'file')"
    />

    <!-- Previews -->
    <div class="preview-container" *ngFor="let item of content">
      <div class="close-icon" (click)="deleteAttachment(item)">
        <i nz-icon nzType="icons:exit" class="svg-icon fillwhite mini-icon"></i>
      </div>
      <div class="file-preview">
        <a href="{{ item.storageLink }}" target="_blank">{{ item.name }}</a>
      </div>
    </div>
    <!-- Upload -->
    <div
      *ngIf="fileUploadProgress"
      class="file-progress"
      [ngClass]="{ 'progress-complete': fileUploadProgress.state === 'DONE' }"
    >
      <progress max="100" value="{{ fileUploadProgress.progress }}"></progress>
      <span>{{ fileUploadProgress.progress }} %</span>
    </div>
    <!-- Add button -->
    <div
      class="file-preview add-file"
      *ngIf="content.length > 0"
      (click)="addFile()"
      style="cursor: pointer"
    >
      <i nz-icon nzType="icons:fileadd" class="svg-icon mini-icon"></i>
      <span class="body-bold" *ngIf="content && content.length > 0">{{
        i18n.string("upload_another_file")
      }}</span>
      <span class="body-bold" *ngIf="!content || content.length === 0">{{
        i18n.string("upload_file")
      }}</span>
    </div>
  </div>
</div>
<nz-modal
  [nzVisible]="true"
  [nzFooter]="null"
  (nzOnCancel)="take_or_upload_dialog_open = false"
  [nzTitle]="i18n.string('take_or_upload_a_photo')"
  *ngIf="take_or_upload_dialog_open"
  [nzCloseIcon]="'icons:exit'"
>
  <div
    class="image-upload-selection"
    (click)="captureImage()"
    style="cursor: pointer"
  >
    <i nz-icon nzType="icons:camera" class="svg-icon mini-icon"></i>
    <span class="body-bold">{{ i18n.string("take_a_photo") }}</span>
  </div>
  <div
    class="image-upload-selection"
    (click)="uploadImage()"
    style="cursor: pointer"
  >
    <i nz-icon nzType="icons:fileadd" class="svg-icon mini-icon"></i>
    <span class="body-bold">{{ i18n.string("upload_a_photo") }}</span>
  </div>
</nz-modal>
