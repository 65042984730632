import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay-card-item',
  templateUrl: './overlay-card-item.component.html',
  styleUrls: ['./overlay-card-item.component.scss'],
})
export class OverlayCardItemComponent implements OnInit {
  @Input() item = {
    text: '',
    image: '',
    bold: false,
    icon: null,
    hidden: false,
  };

  constructor() {}

  ngOnInit() {}
}
