import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { I18nService } from '../../../../services/i18n.service';
import {
  NotificationService,
  PushPermissionStatus,
} from '../../../../services/notification.service';
import { UiService } from '../../../../services/ui.service';

enum EquipmentType {
  none = 0,
  flange,
  pipe,
  steamtrap,
}

@Component({
  selector: 'app-notifications-settings-subpage',
  templateUrl: './notifications-settings-subpage.component.html',
  styleUrls: ['./notifications-settings-subpage.component.scss'],
})
export class NotificationsSettingsSubpageComponent implements OnInit {
  @Input() public settings: any;
  @Input() public error: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public success: boolean = false;

  public permission_error: boolean = false;
  public selectedType: EquipmentType = EquipmentType.none;
  public requestingNotificationPermission: boolean = false;
  public notificationPermissionStatus: PushPermissionStatus =
    this.notification.PermissionStatus;
  public pushNotificationModalVisible: boolean = false;
  public pushNotificationModalResolve: (
    value: void | PromiseLike<void>
  ) => void = () => {};
  public pushNotificationModalReject: (reason?: any) => void = () => {};

  public reset: boolean; // just a dummy variable to trigger a reset of the push notification toggle by changing its value
  get IsTypeSelected(): boolean {
    return this.selectedType !== EquipmentType.none;
  }

  get AlarmNotificationsEmail(): boolean {
    if (!this.settings || this.selectedType === EquipmentType.none)
      return false;
    switch (this.selectedType) {
      case EquipmentType.flange:
        return this.settings.notificationsFlangeAlarmEmail;
      case EquipmentType.pipe:
        return this.settings.notificationsPipeAlarmEmail;
      case EquipmentType.steamtrap:
        return this.settings.notificationsSteamtrapAlarmEmail;
    }
  }
  set AlarmNotificationsEmail(value: boolean) {
    if (!this.settings) return;
    switch (this.selectedType) {
      case EquipmentType.flange:
        this.settings.notificationsFlangeAlarmEmail = value;
        break;
      case EquipmentType.pipe:
        this.settings.notificationsPipeAlarmEmail = value;
        break;
      case EquipmentType.steamtrap:
        this.settings.notificationsSteamtrapAlarmEmail = value;
        break;
    }
  }

  get AlarmNotificationsPush(): boolean {
    if (!this.settings || this.selectedType === EquipmentType.none)
      return false;
    switch (this.selectedType) {
      case EquipmentType.flange:
        return this.settings.notificationsFlangeAlarmPush;
      case EquipmentType.pipe:
        return this.settings.notificationsPipeAlarmPush;
      case EquipmentType.steamtrap:
        return this.settings.notificationsSteamtrapAlarmPush;
    }
  }
  set AlarmNotificationsPush(value: boolean) {
    if (!this.settings) return;
    const setPushvalue = (value: boolean) => {
      switch (this.selectedType) {
        case EquipmentType.flange:
          this.settings.notificationsFlangeAlarmPush = value;
          break;
        case EquipmentType.pipe:
          this.settings.notificationsPipeAlarmPush = value;
          break;
        case EquipmentType.steamtrap:
          this.settings.notificationsSteamtrapAlarmPush = value;
          break;
      }
    };

    if (value === true) {
      this.triggerNotificationPermissionPrompt().then(
        () => setPushvalue(value),
        () => {
          this.reset = !this.reset;
        }
      );
    } else {
      setPushvalue(value);
    }
  }

  get WarningNotificationsEmail(): boolean {
    if (!this.settings || this.selectedType === EquipmentType.none)
      return false;
    switch (this.selectedType) {
      case EquipmentType.flange:
        return this.settings.notificationsFlangeWarningEmail;
      case EquipmentType.pipe:
        return this.settings.notificationsPipeWarningEmail;
      case EquipmentType.steamtrap:
        return this.settings.notificationsSteamtrapWarningEmail;
    }
  }
  set WarningNotificationsEmail(value: boolean) {
    if (!this.settings) return;
    switch (this.selectedType) {
      case EquipmentType.flange:
        this.settings.notificationsFlangeWarningEmail = value;
        break;
      case EquipmentType.pipe:
        this.settings.notificationsPipeWarningEmail = value;
        break;
      case EquipmentType.steamtrap:
        this.settings.notificationsSteamtrapWarningEmail = value;
        break;
    }
  }

  get WarningNotificationsPush(): boolean {
    if (!this.settings || this.selectedType === EquipmentType.none)
      return false;
    switch (this.selectedType) {
      case EquipmentType.flange:
        return this.settings.notificationsFlangeWarningPush;
      case EquipmentType.pipe:
        return this.settings.notificationsPipeWarningPush;
      case EquipmentType.steamtrap:
        return this.settings.notificationsSteamtrapWarningPush;
    }
  }
  set WarningNotificationsPush(value: boolean) {
    if (!this.settings) return;
    const setPushvalue = (value: boolean) => {
      switch (this.selectedType) {
        case EquipmentType.flange:
          this.settings.notificationsFlangeWarningPush = value;
          break;
        case EquipmentType.pipe:
          this.settings.notificationsPipeWarningPush = value;
          break;
        case EquipmentType.steamtrap:
          this.settings.notificationsSteamtrapWarningPush = value;
          break;
      }
    };

    if (value === true) {
      this.triggerNotificationPermissionPrompt().then(
        () => setPushvalue(value),
        () => {
          this.reset = !this.reset;
        }
      );
    } else {
      setPushvalue(value);
    }
  }

  get IsSteamtrap(): boolean {
    return this.selectedType === EquipmentType.steamtrap;
  }

  constructor(
    public router: Router,
    public ui: UiService,
    public i18n: I18nService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.refresh();
  }
  refresh() {
    const urlParts = this.router.url.replace('/settings/', '').split('/');
    switch (urlParts.length > 1 ? urlParts[1] : '') {
      case 'flange':
        this.selectedType = EquipmentType.flange;
        break;
      case 'pipe':
        this.selectedType = EquipmentType.pipe;
        break;
      case 'steamtrap':
        this.selectedType = EquipmentType.steamtrap;
        break;
      default:
        this.selectedType = EquipmentType.none;
    }
  }
  getTitle() {
    switch (this.selectedType) {
      case EquipmentType.none:
        return this.i18n.string('notifications');
      case EquipmentType.flange:
        return (
          this.i18n.string('flange') + ' ' + this.i18n.string('notifications')
        );
      case EquipmentType.pipe:
        return (
          this.i18n.string('pipe') + ' ' + this.i18n.string('notifications')
        );
      case EquipmentType.steamtrap:
        return (
          this.i18n.string('steamtrap_non_abbrev') +
          ' ' +
          this.i18n.string('notifications')
        );
    }
  }

  get UserEmail(): string {
    return !!this.settings ? this.settings.email : '';
  }

  public SelectFlange() {
    this.router.navigate(['settings', 'notifications', 'flange']);
    // this.selectedType = EquipmentType.flange;
  }

  public SelectPipe() {
    this.router.navigate(['settings', 'notifications', 'pipe']);
    // this.selectedType = EquipmentType.pipe;
  }

  public SelectSteamtrap() {
    this.router.navigate(['settings', 'notifications', 'steamtrap']);
    // this.selectedType = EquipmentType.steamtrap;
  }

  public requestNotificationPermission() {
    this.permission_error = false;
    this.requestingNotificationPermission = true;
    this.notification.subscribeToNotifications(
      (status: PushPermissionStatus) => {
        this.requestingNotificationPermission = false;
        if (status === PushPermissionStatus.denied) {
          console.error(
            'Could not subscribe to notifications. Permission denied'
          );
          this.permission_error = true;
          this.notificationPermissionStatus = PushPermissionStatus.denied;
          this.closePushNotificationModal(false);
        } else {
          this.notificationPermissionStatus = status;
          this.closePushNotificationModal(true);
        }
      },
      (reason) => {
        console.error('Could not subscribe to notifications', reason);
        this.permission_error = true;
        this.requestingNotificationPermission = false;
        this.notificationPermissionStatus = PushPermissionStatus.denied;
        this.closePushNotificationModal(false);
      }
    );
  }

  private triggerNotificationPermissionPrompt(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!('Notification' in window)) {
        // Check if the browser supports notifications
        alert('This browser does not support desktop notification');
      } else if (Notification.permission !== 'granted') {
        this.displayNotificationPermissionPrompt(resolve, reject);
      } else {
        resolve();
      }
    });
  }

  private displayNotificationPermissionPrompt(
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: any) => void
  ) {
    this.pushNotificationModalResolve = resolve;
    this.pushNotificationModalReject = reject;
    this.pushNotificationModalVisible = true;
  }

  public closePushNotificationModal(resolve: boolean) {
    if (resolve) {
      this.pushNotificationModalResolve();
    } else {
      this.pushNotificationModalReject();
    }
    this.pushNotificationModalVisible = false;
    this.requestingNotificationPermission = false;
    this.pushNotificationModalResolve = () => {};
    this.pushNotificationModalReject = () => {};
  }
}
