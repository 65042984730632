import { SlicePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameSlice'
})
export class NameSlicePipe implements PipeTransform {
  nameValue : string;
  transform(value: string, ...args: unknown[]): unknown {
    if(value.length < 32)
    return value;
    else {
      return value.slice(0,16)+' ... '+countWord(value.slice(value.length-17,value.length));
    }
    function countWord(s) {
      return s.includes(' ') ? s.substring(s.indexOf(' ') + 1) : s;
    }
  }
}
