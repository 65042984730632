<div
  class="signup-wrapper"
  [class.mobile]="isMobile"
  [class.tablet]="isTablet"
>
  <div class="signup-image">
    <img
      *ngIf="isDesktop"
      nz-image
      nzDisablePreview
      nzSrc="assets/images/login_image.png"
      height="100%"
    />
    <img
      *ngIf="!isDesktop"
      nz-image
      nzDisablePreview
      nzSrc="assets/images/login_image.png"
      width="100%"
    />
  </div>
  <div class="signupForm">
    <div *ngIf="isGranted" class="granted-note body-bold">
      <div class="note-icon-wrapper">
        <i
          nz-icon
          nzType="icons:check-circle-filled"
          class="svg-icon multicolorIcon"
        ></i>
      </div>
      <span>{{ i18n.string("access_granted_success") }}</span>
    </div>
    <div *ngIf="isDenied" class="denied-note body-bold">
      <div class="note-icon-wrapper">
        <i nz-icon nzType="icons:false-alert" class="svg-icon fillwhite"></i>
      </div>
      <span>{{ i18n.string("access_denied_success") }}</span>
    </div>
    <div class="spinner-container" *ngIf="isLoading">
      <app-spinner [black]="true"></app-spinner>
    </div>
    <div class="errorMsg" *ngIf="loadingError">
      <p>
        {{ i18n.string("loading_approval_data_failed") }}
      </p>
    </div>
    <div class="innersignup" *ngIf="!isLoading && !loadingError">
      <img
        nz-image
        class="logo"
        nzDisablePreview
        nzSrc="assets/images/LoctitePulseLogo_red.svg"
      />
      <h1>{{ i18n.string("approve_user") }}</h1>
      <p>{{ i18n.string("approve_user_info") }}</p>

      <form nz-form [formGroup]="signupForm">
        <div class="signup-inner-wrapper">
          <ng-container>
            <div nz-row class="signup-inner-row">
              <nz-form-item>
                <nz-form-label>{{ i18n.string("name") }}</nz-form-label>
                <nz-input-group>
                  <input
                    formControlName="name"
                    [maxlength]="100"
                    nz-input
                    [placeholder]="name"
                    [(ngModel)]="name"
                    oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                  />
                </nz-input-group>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>{{ i18n.string("surname") }}</nz-form-label>
                <nz-input-group>
                  <input
                    formControlName="surname"
                    [maxlength]="100"
                    nz-input
                    [placeholder]="surname"
                    [(ngModel)]="surname"
                    oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
            <nz-form-item>
              <nz-form-label>{{ i18n.string("email") }}</nz-form-label>
              <nz-input-group>
                <input
                  #emailInputRef
                  type="email"
                  formControlName="email"
                  [maxlength]="50"
                  nz-input
                  [placeholder]="email"
                  [(ngModel)]="email"
                  oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                  [ngClass]="{
                    'is-valid': (f.email.dirty || submitted) && !f.email.errors,
                    'is-invalid': (f.email.dirty || submitted) && f.email.errors
                  }"
                />
              </nz-input-group>
              <div *ngIf="!requiredEmailValid" class="errorMsg">
                <span *ngIf="f.email.errors?.required">{{
                  i18n.string("email_validation1")
                }}</span>
                <span *ngIf="f.email.errors?.email">{{
                  i18n.string("email_validation2")
                }}</span>
                <span *ngIf="emailAlreadyRegistered">{{
                  i18n.string("email_validation3")
                }}</span>
              </div>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
                >{{ i18n.string("designation")
                }}<span *ngIf="optional" class="silver-chalice-text">
                  ({{ i18n.string("optional") }})</span
                ></nz-form-label
              >
              <nz-input-group>
                <input
                  formControlName="designation"
                  [maxlength]="100"
                  nz-input
                  [placeholder]="designation"
                  [(ngModel)]="designation"
                  oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                />
              </nz-input-group>
            </nz-form-item>
            <nz-form-item class="select-form-group">
              <nz-form-label>{{ i18n.string("country") }}</nz-form-label>
              <nz-select
                #countrySelect
                [nzPlaceHolder]="i18n.string('select_country')"
                [class.selected]="
                  signupForm.controls['country'].value !== null &&
                  signupForm.controls['country'].value !==
                    i18n.string('select_country')
                "
                [nzCustomTemplate]="countryTemplate"
                formControlName="country"
                [nzShowArrow]="false"
                [(ngModel)]="+country"
              >
                <nz-template *ngIf="countryArray.length > 0">
                  <nz-option
                    *ngFor="let value of countryArray"
                    [nzLabel]="getName(value.name)"
                    [nzValue]="+value.id"
                  >
                  </nz-option>
                </nz-template>
              </nz-select>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
                >{{ i18n.string("customerentity")
                }}<span *ngIf="optional" class="silver-chalice-text">
                  ({{ i18n.string("optional") }})</span
                ></nz-form-label
              >
              <nz-input-group>
                <input
                  formControlName="customerentity"
                  [maxlength]="100"
                  nz-input
                  [placeholder]="customerentity"
                  [(ngModel)]="customerentity"
                  oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                />
              </nz-input-group>
            </nz-form-item>

            <nz-form-item class="select-form-group">
              <nz-form-label>{{ i18n.string("customer") }}</nz-form-label>
              <nz-select
                #customerSelect
                [nzPlaceHolder]="i18n.string('select_customer')"
                [class.selected]="
                  signupForm.controls['customer_object'].value !== null &&
                  signupForm.controls['customer_object'].value !==
                    i18n.string('select_customer')
                "
                [nzCustomTemplate]="customerTemplate"
                formControlName="customer_object"
                [nzShowArrow]="false"
              >
                <nz-option
                  nzHide
                  [nzValue]="i18n.string('select_customer')"
                  [nzLabel]="i18n.string('select_customer')"
                ></nz-option>
                <nz-option
                  *ngFor="let cu of customerList"
                  [nzLabel]="cu.name"
                  [nzValue]="cu.objectID"
                >
                </nz-option>
              </nz-select>
            </nz-form-item>
            <div *ngIf="!IsCustomerSelected" class="errorMsg">
              <span>{{ i18n.string("customer_required") }}</span>
            </div>

            <p class="error" [class.hidden]="!error">
              {{ i18n.string("registration_processing_failed") }}
            </p>

            <nz-form-item>
              <nz-form-control>
                <div class="submitButtonWrapper signupButtonMarginTop">
                  <app-nav-button
                    [disabled]="isGranted || isDenied || !IsCustomerSelected"
                    [primary]="true"
                    (click)="grant()"
                    [svg_icon_right]="'icons:arrow-right'"
                    >{{ i18n.string("grant_access") }}
                  </app-nav-button>
                </div>
              </nz-form-control> </nz-form-item
            ><nz-form-item>
              <nz-form-control>
                <div class="submitButtonWrapper signupButtonMarginTop">
                  <app-nav-button
                    [disabled]="isGranted || isDenied"
                    [primary]="true"
                    (click)="deny()"
                    [svg_icon_right]="'icons:arrow-right'"
                    >{{ i18n.string("deny_access") }}
                  </app-nav-button>
                </div>
              </nz-form-control>
            </nz-form-item>

            <ng-template #countryTemplate let-selected>
              <div class="customSelectBox">
                <span>{{
                  selected
                    ? selected.nzLabel
                    : selected && i18n.string("select_country")
                }}</span>
                <i
                  nz-icon
                  [nzType]="'icons:dropdown-arrow'"
                  class="customSelectIcon"
                ></i>
              </div> </ng-template
            ><ng-template #customerTemplate let-selected>
              <div class="customSelectBox">
                <span>{{
                  selected
                    ? selected.nzLabel
                    : selected && i18n.string("select_customer")
                }}</span>
                <i
                  nz-icon
                  [nzType]="
                    customerSelect.nzOpen
                      ? 'icons:dropup-arrow'
                      : 'icons:dropdown-arrow'
                  "
                  class="customSelectIcon"
                ></i>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
