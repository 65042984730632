<div class="history-item" [class.drop-shadow]="shadow" [class.grey]="Grey" [class.red]="Red" [class.green]="Green" [class.yellow]="Yellow"
  [class.detailedHistory]="detailedhistory" [class.desktop]="isDesktop" [class.mobile]="isMobile" [class.tablet]="isTablet">
  <!-- *ngFor, [class.red], [class.green], icon handling (green dot, red icon, none) -->
  <div class="history-content-wrapper">
    <div class="history-content-inner-wrapper">
      <div class="history-date">

        <span class="body-bold-cap history-time">{{item.timestamp | date : DateFormat}}</span>
        <!-- <span class="sensor-name" *ngIf="!!item.sensorName">
          | {{item.sensorName}}
        </span> -->
      </div>
      <div class="sub-item hasNote">
        <div class="sub-item-head">
          <i nz-icon *ngIf="Green" nzType="icons:circle" class="svg-icon mini-icon fillgreen circle-icon"></i>
          <i nz-icon *ngIf="Yellow" nzType="icons:status-exclamation-octagon" class="svg-icon mini-icon fillorange"></i>
          <i nz-icon *ngIf="Red" nzType="icons:status-exclamation-triangle" class="svg-icon mini-icon fillred"></i>
          <i nz-icon *ngIf="Grey" nzType="icons:cross-circle" class="svg-icon mini-icon fillblackcoral"></i>
          <span class="body-bold">{{i18n.parse(Message)}}</span>
          <!--<div *ngIf="Documentation && collapsible" class="history-right-icon-wrapper" (click)="item.open = !item.open">
            <i nz-icon [nzType]="item.open ? 'icons:minus' : 'icons:plus'"
              class="svg-icon mini-icon history-right-icon"></i>
            <span *ngIf="item.open && !IsMobile" class="detail-bold">{{i18n.string('hide_details')}}</span>
            <span *ngIf="!item.open && !IsMobile" class="detail-bold">{{i18n.string('show_details')}}</span>
          </div>-->
        </div>
      </div>
    </div>
    <div *ngIf="Documentation && collapsible" class="history-right-icon-wrapper" (click)="item.open = !item.open">
      <i nz-icon [nzType]="item.open ? 'icons:minus' : 'icons:plus'" class="svg-icon mini-icon history-right-icon"></i>
      <span *ngIf="item.open && isDesktop" class="detail-bold">{{i18n.string('hide_details')}}</span>
      <span *ngIf="!item.open && isDesktop" class="detail-bold">{{i18n.string('show_details')}}</span>
    </div>
  </div>

  <div *ngIf="Documentation && Open" class="sub-item document-type">
    <ng-container *ngIf="item.details.documentationType === 'repair'">
      <div class="sub-item-head">
        <i nz-icon nzType="icons:tool" class="svg-icon mini-icon"></i>
        <span class="body-bold">{{i18n.string('needs_to_be_repaired')}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="item.details.documentationType === 'fixed'">
      <div class="sub-item-head">
        <i nz-icon nzType="icons:fixed" class="svg-icon mini-icon"></i>
        <span class="body-bold">{{i18n.string('problem_fixed')}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="item.details.documentationType === 'false_alert'">
      <div class="sub-item-head">
        <i nz-icon nzType="icons:status-exclamation-triangle" class="svg-icon mini-icon"></i>
        <span class="body-bold">{{i18n.string('false_alert')}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="item.details.documentationType === 'other'">
      <div class="sub-item-head">
        <i nz-icon nzType="icons:others" class="svg-icon mini-icon"></i>
        <span class="body-bold">{{i18n.string('others')}}</span>
      </div>
    </ng-container>
  </div>

  <div *ngIf="Documentation && Open && item.details && !!item.details.note" class="sub-item note-item">
    <!-- Note -->
    <div class="sub-item-head">
      <i nz-icon nzType="icons:note" class="svg-icon mini-icon"></i>
      <span class="body-bold">{{i18n.string('note')}}</span>
    </div>
    <div class="sub-item-content note-content">
      <span class="regular">{{ item.details.note }}</span>
    </div>
    <div></div>
  </div>

  <div *ngIf="Documentation && Open && item.details && item.details.photos.length > 0" class="sub-item photo-item">
    <!-- Photos -->
    <div class="sub-item-head">
      <i nz-icon nzType="icons:camera" class="svg-icon mini-icon"></i>
      <span class="body-bold">{{i18n.string('photos')}}</span>
    </div>
    <div class="photowrapper sub-item-content">
      <button class="photo-subwrapper" *ngFor="let photo of item.details.photos">
        <img class="photo-preview" [src]="photo.thumbnail" (click)="overlayImage = photo.picture"
          (error)="onPhotoLoadingError(photo)" />
        <div class="photo-icon-circle" (click)="overlayImage = photo.picture">
          <i nz-icon nzType="icons:filter" class="svg-icon mini-icon"></i>
          <span class="body-bold">{{i18n.string('show')}}</span>
        </div>
      </button>
    </div>
    <div></div>
    <nz-modal [(nzVisible)]="overlayImage" (nzOnCancel)="overlayImage = null" [nzFooter]="null"
      [nzTitle]="i18n.string('photo')" [nzCloseIcon]="'icons:exit'">
      <img class="photo" src="{{ overlayImage }}" />
    </nz-modal>
  </div>

  <div *ngIf="Documentation && Open && item.details && item.details.files.length > 0" class="sub-item file-item">
    <!-- Files -->
    <div class="sub-item-head">
      <i nz-icon nzType="icons:file" class="svg-icon mini-icon"></i>
      <span class="body-bold">{{i18n.string('files')}}</span>
    </div>
    <div class="sub-item-content file-wrapper">
      <div class="file" *ngFor="let file of item.details.files" (click)="openFile(file)">
        <a class="link-underline black-coral-text">{{file.filename}}</a>
        <i nz-icon nzType="icons:eye" class="svg-icon mini-icon history-right-icon"></i>
      </div>
    </div>
    <div></div>
  </div>
  <!--
  <div *ngIf="!Red && !Green" class="sub-item">
    <div class="sub-item-head">
      <i nz-icon nzType="icons:audio" class="svg-icon mini-icon"></i>
      <span class="body-bold">Audio</span>
    </div>
    <div class="sub-item-content audio-wrapper">
      <button class="audio">
        <div class="play-btn">
          <i nz-icon nzType="icons:play" class="svg-icon mini-icon fillwhite"></i>
        </div>
        <span>00:55</span>
      </button>
      <button class="audio">
        <div class="play-btn">
          <i nz-icon nzType="icons:play" class="svg-icon mini-icon fillwhite"></i>
        </div>
        <span>00:55</span>
      </button>
    </div>
    <div></div>
  </div>-->

</div>