<app-header [transparent]="false" [title]="!!plant ? i18n.parse(plant.objectName) : i18n.string('plant')" [backTo]="''">
</app-header>

<ng-container *ngIf="!isDesktop && !!plant">
  <div class="header" *ngIf="true || !!plant.thumbnail">
    <img nz-image nzDisablePreview class="header-img"
      [nzSrc]="plant['thumbnail'] ? plant['thumbnail'] :  'assets/images/plant_default_picture.png'" alt="Plant" />
    <div class="header-img-gradient"></div>
  </div>
</ng-container>

<div class="content" [class.desktop]="isDesktop">
  <div *ngIf="isDesktop" [class.desktop-sidebar]="isDesktop">
    <app-sidebar [showFilter]="true" [plantsFilter]="true" [plantFilterStatus]="filterStatus"></app-sidebar>
  </div>
  <app-plant-overview-content></app-plant-overview-content>
</div>