<div [class.desktop-main]="isDesktop" [class.mobile]="isMobile" [class.tablet]="isTablet">
  <ng-container *ngIf="plant && (!empty_results || !isDesktop)">
    <div class="plant-thumbnail-header">
      <!--<app-thumbnail *ngIf="!isMobile" [src]="plant.thumbnail" [large]="true"></app-thumbnail>-->
      <nz-avatar *ngIf="isDesktop" [nzSrc]="
          plant['thumbnail']
            ? plant['thumbnail']
            : 'assets/images/plant_default_picture.png'
        " [nzSize]="80" nzText="Plant"></nz-avatar>
      <div>
        <app-breadcrumbs [object]="plant" class="breadcrumbsSmallMargin"></app-breadcrumbs>
        <h1>
          {{ plant.objectName }} [{{
          plant.Assets.length -
          plant.Archived.length -
          plant.AssetsWithoutSensors.length
          }}]
        </h1>
      </div>
    </div>
    <app-search-filter (FilterChanged)="onFilterChanged($event)" [Input]="plant.children" [filter]="filter"
      *ngIf="!isDesktop" [plantFilterStatus]="mobfilterStatus" [plantID]="plantID">
    </app-search-filter>
    <ng-container>
      <ng-container *ngIf="!empty_results">
        <div *ngIf="plant.filtered">
          <div *ngIf="AssetCount > 1" class="found-wrapper">
            <h3>{{ AssetCount }} {{ i18n.string("equipments_found") }}</h3>
          </div>
          <div *ngIf="AssetCount === 1" class="found-wrapper">
            <h3>1 {{ i18n.string("equipment_found") }}</h3>
          </div>
          <div *ngIf="AssetCount === 0" class="found-wrapper">
            <h3>{{ i18n.string("no_equipments_found") }}</h3>
            <h5 class="marginTop24">
              {{ i18n.string("no_equipments_found2") }}
            </h5>
          </div>
        </div>
        <div *ngIf="
            plant.Critical.length == 0 &&
            plant.Warning.length == 0 &&
            plant.InActive.length == 0 &&
            plant.Working.length > 0
          " class="nz-multiple-status-reports nz-multiple-reports-left">
          <nz-alert nzType="success" class="nz-status-report" [nzMessage]="allfineTemplate"></nz-alert>
          <ng-template #allfineTemplate><i nz-icon nzType="icons:status-check" class="fillgreen"></i><span
              class="body-bold">{{
              i18n.string("all_equipments_working_fine")
              }}</span></ng-template>
        </div>

        <div *ngIf="
            plant.Critical.length > 0 ||
            plant.Warning.length > 0 ||
            plant.InActive.length > 0
          " class="nz-multiple-status-reports nz-multiple-reports-left">

          <nz-alert *ngIf="plant.InActive.length > 0" nzType="info" class="nz-status-report"
            [nzMessage]="inActiveTemplate"></nz-alert>
          <ng-template #inActiveTemplate><i nz-icon nzType="icons:cross-circle" class="fillblackcoral"></i><span
              *ngIf="plant.InActive.length == 1" class="body-bold">
              {{ plant.InActive.length }}
              {{ i18n.string("in_active_sensor") }}</span>
            <span *ngIf="plant.InActive.length > 1" class="body-bold">
              {{ plant.InActive.length }}
              {{ i18n.string("in_active_sensors") }}</span>
          </ng-template>

          <nz-alert *ngIf="plant.Critical.length > 0" nzType="error" class="nz-status-report"
            [nzMessage]="errorTemplate"></nz-alert>
          <ng-template #errorTemplate><i nz-icon nzType="icons:status-exclamation-triangle" class="fillred"></i><span
              *ngIf="plant.Critical.length == 1" class="body-bold">
              {{ plant.Critical.length }}
              {{ i18n.string("critical_equipment") }}</span>
            <span *ngIf="plant.Critical.length > 1" class="body-bold">
              {{ plant.Critical.length }}
              {{ i18n.string("critical_equipments") }}</span>
          </ng-template>
          <nz-alert *ngIf="plant.Warning.length > 0" nzType="warning" class="nz-status-report"
            [nzMessage]="warningTemplate"></nz-alert>
          <ng-template #warningTemplate><i nz-icon nzType="icons:status-exclamation-octagon"
              class="fillorange"></i><span *ngIf="plant.Warning.length == 1" class="body-bold">
              {{ plant.Warning.length }}
              {{ i18n.string("warning_equipment") }}</span>
            <span *ngIf="plant.Warning.length > 1" class="body-bold">
              {{ plant.Warning.length }}
              {{ i18n.string("warning_equipments") }}</span>
          </ng-template>

          <nz-alert nzType="success" class="nz-status-report" [nzMessage]="otherfineTemplate"
            *ngIf="plant.Working.length > 0"></nz-alert>
          <ng-template #otherfineTemplate><i nz-icon nzType="icons:status-check" class="fillgreen"></i>
            <span *ngIf="plant.Working.length == 1" class="body-bold">
              {{ plant.Working.length }}
              {{ i18n.string("other_equipment_working_fine") }}</span>
            <span *ngIf="plant.Working.length > 1" class="body-bold">
              {{ plant.Working.length }}
              {{ i18n.string("other_equipments_working_fine") }}</span>
          </ng-template>
        </div>
        <ng-container *ngIf="
            (plant.Assets.length -
              plant.Archived.length -
              plant.AssetsWithoutSensors.length <=
              25 ||
              !plant.NextLayerIsAboveEquipment) &&
            !plant.filtered
          ">
          <ng-container *ngFor="let building of Buildings">
            <app-card [plant]="building" [detail]="true" [showLayer]="true">
              <div class="card-layer">
                <ng-container *ngFor="let equipment of building.AssociatedEquipments">
                  <div class="card-layer-subtitle" *ngIf="equipment.objectID !== building.objectID">
                    <span class="card-layer-subtitle-layername detail">
                      {{ i18n.parse(equipment.layerName) }}
                    </span>
                    <span class="body-bold card-layer-subtitle" *ngIf="equipment.Assets.length > 0">
                      {{ i18n.parse(equipment.objectName) }}</span>
                  </div>
                  <div class="card-layer-content">
                    <ng-container *ngFor="let asset of equipment.Assets">
                      <app-asset-nav-button *ngIf="
                          asset.critical && !asset.inactive && !asset.isArchived
                        " [asset]="asset" svg_icon="icons:status-exclamation-triangle">{{ asset.objectName | nameSlice
                        }}
                      </app-asset-nav-button>
                      <app-asset-nav-button *ngIf="
                          asset.warning &&
                          !asset.inactive &&
                          !asset.critical &&
                          !asset.isArchived
                        " [asset]="asset" svg_icon="icons:status-exclamation-octagon">{{ asset.objectName | nameSlice
                        }}
                      </app-asset-nav-button>
                      <app-asset-nav-button *ngIf="asset.inactive && !asset.isArchived" [asset]="asset"
                        svg_icon="icons:cross-circle">{{ asset.objectName | nameSlice }}
                      </app-asset-nav-button>
                      <app-asset-nav-button *ngIf="
                          !asset.critical && !asset.warning && !asset.isArchived
                        " [asset]="asset" svg_icon="icons:status-check">{{
                        asset.objectName | nameSlice
                        }}</app-asset-nav-button>
                    </ng-container>
                    <div class="show_inactive">
                      <app-nav-button (click)="onDisabledAsset(building)" primary="true"
                        *ngIf="building.Archived.length > 0" svg_icon="icons:deinstall">{{
                        i18n.string("show_inactive_equipments") }}
                      </app-nav-button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </app-card>
          </ng-container>
        </ng-container>

        <div *ngIf="
            plant.Assets.length -
              plant.Archived.length -
              plant.AssetsWithoutSensors.length >
              25 &&
            plant.NextLayerIsAboveEquipment &&
            !plant.filtered
          " class="cards ga-zone-wrapper">
          <ng-container *ngFor="let building of sortCritFirst(Buildings)">
            <button class="ga-zone-card" [class.ga-zone-critical]="building.Critical.length > 0"
              (click)="toLowerItem(building)">
              <i nz-icon *ngIf="building.Critical.length > 0" nzType="icons:status-exclamation-triangle"
                class="svg-icon mini-svg-icon fillwhite"></i>
              <h2>
                {{ building.objectName }} [{{
                building.Assets.length -
                building.Archived.length -
                building.AssetsWithoutSensors.length
                }}]
              </h2>
            </button>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="plant.filtered">
      <app-card *ngIf="plant.filtered" [plant]="plant" [fine]="false">
        <ng-container *ngFor="let bcCluster of assetsGroupBy(plant)">
          <app-breadcrumbs [object]="bcCluster[0]" [shorten]="3"></app-breadcrumbs>
          <div class="search-result-wrapper">
            <ng-container *ngFor="let asset of bcCluster">
              <app-asset-nav-button [asset]="asset" [svg_icon]="statusChecked(asset)">{{ asset.objectName | nameSlice }}
              </app-asset-nav-button>
            </ng-container>
          </div>
        </ng-container>
      </app-card>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!loading && empty_results">
    <app-search-filter (FilterChanged)="onFilterChanged($event)" [filter]="filter" *ngIf="!isDesktop"
      [plantsFilter]="true" [plantFilterStatus]="mobfilterStatus">
    </app-search-filter>
    <h3>{{ i18n.string("no_equipments_found") }}</h3>
    <h5 class="marginTop24">{{ i18n.string("no_equipments_found2") }}</h5>
  </ng-container>
</div>