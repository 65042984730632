import { Component, OnInit } from '@angular/core';

import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {
  constructor(public i18n: I18nService) {}

  ngOnInit() {}
}
