import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {
  windowScrolled: boolean;
  svg_icon_top = 'icons:arrow-top';
  scrollevent;
  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  constructor(private ui: UiService, public i18n: I18nService) {}
  onWindowScroll = (e) => {
    if(e!=undefined) {
      if (e.target.scrollTop  > 100) {
          this.windowScrolled = true;
        } 
      else if (this.windowScrolled || e.target.scrollTop < 10) {
          this.windowScrolled = false;
        }
      }
    }
    scrollToTop = () => {
        if(this.scrollevent!=undefined) {
          this.scrollevent.target.scrollTop = 0;
      }
    }

  ngOnInit() {
    window.addEventListener(
      'scroll',
      (evt) => {
        this.scrollevent = evt;
        this.onWindowScroll(evt);
      },
      true
    );
  }
}
