<div *ngIf="documentationAdded" class="ga-documentation-note body-bold">
  <i nz-icon nzType="icons:done" class="svg-icon"></i>
  <span>Documentation successfully added</span>
</div>
<div class="card" [class.desktop]="isDesktop" *ngIf="notification">
  <div class="info-area">
    <div class="plant-header">
      <span *ngIf="notification.isCritical && !notification.inactive" class="body-bold-cap red-text">{{i18n.string('problem_detected')}}</span>
      <span *ngIf="notification.inactive" class="body-bold-cap grey-text">{{i18n.string('problem_detected')}}</span>
      <span *ngIf="notification.isWarning" class="body-bold-cap orange-text">{{i18n.string('warning')}}</span>
      <span *ngIf="!notification.isCritical && !notification.isWarning"
        class="body-bold-cap green-text">{{i18n.string('repaired')}}</span>
      <span class="timestamp" [class.body-bold]="isDesktop" [class.detail-bold]="!isDesktop">{{notification.timestamp |
        date: DateFormat}}</span> <!-- timezone todo!-->
    </div>
    <app-breadcrumbs [array]="notification.breadcrumbs" class="detail breadcrumbsSmallMargin">
      <!-- Hier wird Germany >> Salzberg >> Behälterhalle "Phöenix" generiert und bereits fertig gestyled, es braucht aber das asset als input -->
    </app-breadcrumbs>
    <div class="asset_name" (click)="onAssetName()"> 
      <h2>{{notification.objectName}}</h2>
    </div>
    <span class="body-bold">{{notification.sensorName}}</span>
    <app-notification-alert [notification]="notification" *ngIf="notification.isCritical || notification.isWarning || notification.inactive">
    </app-notification-alert>
  </div>
  <div class="button-area" *ngIf="notification.isCritical || notification.isWarning">
    <div class="button-wrapper no-margin">
      <!-- <app-asset-nav-button [primary]="true" [center]="true" blackBG="true" buttonlink="true" svg_icon="icons:eye"
        svg_icon_right="" [asset]="notification" [sensorID]="notification.sensorID" [bypassConnectedStatus]="true">
        {{i18n.string('review_equipment_status')}}
      </app-asset-nav-button> -->
      <!-- ngIf -> true wenn das Asset critical ist; asset muss übergeben werden -->
    </div>
    <div class="button-wrapper">
      <app-nav-button *ngIf="true && !notification.inactive && !notification.isCritical && !notification.isWarning" [darksecondary]="true" svg_icon="icons:instruction" (click)="onRepairAndReplace()">
        {{i18n.string('repair_and_replace')}}</app-nav-button>
      <!-- ngIf -> true wenn das Asset critical ist-->
    </div>
    <div class="button-wrapper">
      <app-nav-button *ngIf="true" [darksecondary]="true" svg_icon="icons:documentation"
        (click)="overlayDocumentation = true">{{i18n.string('add_documentation')}}</app-nav-button>
      <!-- ngIf -> true wenn das Asset critical ist-->
    </div>
  </div>

  <app-docu-wizard *ngIf="overlayDocumentation" [assetId]="notification.objectID" [sensorId]="notification.sensorID"
    (saved)="onDocumentationAdded($event)" (close)="overlayDocumentation = false"></app-docu-wizard>
  <app-sensor-instruction *ngIf="overlayInstructions" (close)="overlayInstructions = false"></app-sensor-instruction>
</div>