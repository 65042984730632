export class LocationFilter {
  searchTerm: string;
  critical: boolean;
  warning: boolean;
  working: boolean;
  inactive: boolean;
  selectedTypes: string[] = [];
  customer: string = null;
  countryOrRegionSelected: number = null;

  location: number[] = [];
  preselection: number = 0; // number of preselected locations

  get IsFiltering(): boolean {
    return (
      this.inactive ||
      this.critical ||
      this.warning ||
      this.working ||
      !!this.customer ||
      this.selectedTypes.length > 0 ||
      this.location.length > this.preselection
    );
  }

  get IsOnlyFilteringCustomer(): boolean {
    return (
      !!this.customer &&
      !(
        this.inactive ||
        this.critical ||
        this.warning ||
        this.working ||
        this.selectedTypes.length > 0 ||
        this.location.length > this.preselection
      )
    );
  }

  get FilterSettingsCount(): number {
    return (
      (this.inactive || this.critical || this.warning || this.working ? 1 : 0) +
      (this.selectedTypes.length > 0 ? 1 : 0) +
      (!!this.customer ? 1 : 0) +
      Math.max(this.location.length - this.preselection, 0)
    );
  }
  constructor(instance?: any) {
    if (!!instance) {
      this.searchTerm = instance.searchTerm;
      this.critical = instance.critical;
      this.warning = instance.warning;
      this.working = instance.working;
      this.inactive = instance.inactive;
      this.selectedTypes = instance.selectedTypes;
      this.customer = instance.customer;
      this.preselection = instance.preselection;
      this.location = [...instance.location];
    }
  }

  set Flange(active: boolean) {
    this.selectedTypes = this.selectedTypes.filter((item) => item !== 'flange');
    if (active) this.selectedTypes = [...this.selectedTypes, 'flange'];
  }

  set Tank(active: boolean) {
    this.selectedTypes = this.selectedTypes.filter((item) => item !== 'tank');
    if (active) this.selectedTypes = [...this.selectedTypes, 'tank'];
  }

  set Pipe(active: boolean) {
    this.selectedTypes = this.selectedTypes.filter((item) => item !== 'pipe');
    if (active) this.selectedTypes = [...this.selectedTypes, 'pipe'];
  }

  set Valve(active: boolean) {
    this.selectedTypes = this.selectedTypes.filter((item) => item !== 'valve');
    if (active) this.selectedTypes = [...this.selectedTypes, 'valve'];
  }

  set SteamTrap(active: boolean) {
    this.selectedTypes = this.selectedTypes.filter(
      (item) => item !== 'steam trap'
    );
    if (active) this.selectedTypes = [...this.selectedTypes, 'steam trap'];
  }

  set RotatingEquipment(active: boolean) {
    this.selectedTypes = this.selectedTypes.filter(
      (item) => item !== 'rotating equipment'
    );
    if (active) this.selectedTypes = [...this.selectedTypes, 'rotating equipment'];
  }

  get Flange(): boolean {
    return !!this.selectedTypes.find((item) => item === 'flange');
  }
  get Tank(): boolean {
    return !!this.selectedTypes.find((item) => item === 'tank');
  }
  get Pipe(): boolean {
    return !!this.selectedTypes.find((item) => item === 'pipe');
  }
  get Valve(): boolean {
    return !!this.selectedTypes.find((item) => item === 'valve');
  }
  get SteamTrap(): boolean {
    return !!this.selectedTypes.find((item) => item === 'steam trap');
  }
  get RotatingEquipment(): boolean {
    return !!this.selectedTypes.find((item) => item === 'rotating equipment');
  }
}
