<app-header [transparent]="true" *ngIf="!isDesktop"></app-header>

<div
  id="content"
  [class.mobile]="isMobile"
  [class.tablet]="isTablet"
  [class.desktop]="isDesktop"
  cdkScrollable
>
  <!-- mobile view -->
  <ng-container *ngIf="!isDesktop">
    <app-tab-bar
      [opaque]="true"
      [icon]="true"
      [ActiveTab]="activeTab"
      (tabChanged)="onTabChanged($event)"
    >
      <app-tab-bar-item class="one-of-two" svg_icon="assets/icons/map.svg">{{
        i18n.String("map")
      }}</app-tab-bar-item>
      <app-tab-bar-item class="one-of-two" svg_icon="assets/icons/list.svg">{{
        i18n.String("list")
      }}</app-tab-bar-item>
    </app-tab-bar>

    <app-search-filter
      [Input]="plants"
      [countryOrRegionSelected]="countryOrRegionSelected"
      [plantFilterStatus]="homePageFilterStatus"
    ></app-search-filter>
  </ng-container>
  <ng-container *ngIf="isDesktop">
    <div
      id="desktop-sidebar"
      [class.desktop-list]="activeTab === 1"
      [class.desktop-map]="activeTab === 0"
      cdkScrollable
    >
      <div
        id="desktop-sidebar-inner"
        [class.scrolling]="scrolling && activeTab === 0"
        cdkScrollable
        [class.chrome]="isChrome"
        [class.map]="activeTab === 0"
      >
        <div class="title">
          <div class="navigation-icon"></div>
          <h2 class="noMargin">{{ i18n.String("navigation") }}</h2>
        </div>
        <app-tab-bar
          [opaque]="true"
          [icon]="true"
          [ActiveTab]="activeTab"
          (tabChanged)="onTabChanged($event)"
        >
          <app-tab-bar-item
            class="one-of-two"
            svg_icon="assets/icons/map.svg"
            >{{ i18n.String("map") }}</app-tab-bar-item
          >
          <app-tab-bar-item class="one-of-two" svg_icon="assets/icons/list.svg"
            >{{ i18n.String("list") }}
          </app-tab-bar-item>
        </app-tab-bar>

        <app-search-filter
          [sidebar]="true"
          [countryOrRegionSelected]="countryOrRegionSelected"
          [plantFilterStatus]="homePageFilterStatus"
        ></app-search-filter>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 1">
    <!-- List -->
    <app-plant-list
      (statusFilterValue)="setStatusFilterValue($event)"
    ></app-plant-list>
  </ng-container>
</div>
<ng-container *ngIf="activeTab === 0">
  <!-- Map -->
  <div
    id="search-info-overlay"
    class="drop-shadow"
    [class.mobile]="isMobile"
    [class.tablet]="isTablet"
    *ngIf="filteredPlants"
  >
    <!--&& !isMobile-->
    <div *ngIf="AssetCount > 1" class="found-wrapper">
      <h3>{{ AssetCount }} {{ i18n.string("equipments_found") }}</h3>
    </div>
    <div *ngIf="AssetCount === 1" class="found-wrapper">
      <h3>1 {{ i18n.string("equipment_found") }}</h3>
    </div>
    <div *ngIf="AssetCount === 0" class="found-wrapper">
      <h3>{{ i18n.string("no_equipments_found") }}</h3>
      <h5 class="marginTop24">{{ i18n.string("no_equipments_found2") }}</h5>
    </div>

    <div
      *ngIf="allWorking"
      class="nz-multiple-status-reports nz-multiple-reports-left"
    >
      <nz-alert
        nzType="success"
        class="nz-status-report"
        [nzMessage]="allfineTemplate"
      ></nz-alert>
      <ng-template #allfineTemplate
        ><i nz-icon nzType="icons:status-check" class="svg-icon fillgreen"></i
        ><span class="body-bold">
          {{ i18n.string("all_equipments_working_fine") }}</span>
        </ng-template
      >
    </div>
    <div
      *ngIf="AssetCount > 0 && (sumInActive > 0 || sumCritical > 0 || sumWarning > 0)"
      class="nz-multiple-status-reports nz-multiple-reports-left"
    >
    <ng-container *ngIf="sumInActive > 0">
      <div class="clickable" (click)="onSetInactiveFilter()">
        <nz-alert
          nzType="inactive"
          class="nz-status-report"
          [nzMessage]="inactiveTemplate"
        ></nz-alert>
      </div>
      <ng-template #inactiveTemplate
        ><i
          nz-icon
          nzType="icons:cross-circle"
          class="svg-icon fillblackcoral"
        ></i>
        <span *ngIf="sumInActive == 1" class="body-bold">
          {{ sumInActive }}</span
        >
        <span *ngIf="sumInActive > 1" class="body-bold">
          {{ sumInActive }}</span
        >
      </ng-template>
    </ng-container>
      <ng-container *ngIf="sumCritical > 0">
        <div class="clickable" (click)="onSetCriticalFilter()">
          <nz-alert
            nzType="error"
            class="nz-status-report"
            [nzMessage]="errorTemplate"
          ></nz-alert>
        </div>
        <ng-template #errorTemplate
          ><i
            nz-icon
            nzType="icons:status-exclamation-triangle"
            class="svg-icon fillred"
          ></i>
          <span *ngIf="sumCritical == 1" class="body-bold">
            {{ sumCritical }}</span
          >
          <span *ngIf="sumCritical > 1" class="body-bold">
            {{ sumCritical }}</span
          >
        </ng-template>
      </ng-container>
      <ng-container *ngIf="sumWarning > 0">
        <div class="clickable" (click)="onSetWarningFilter()">
          <nz-alert
            nzType="warning"
            class="nz-status-report"
            [nzMessage]="warningTemplate"
          ></nz-alert>
        </div>
        <ng-template #warningTemplate
          ><i
            nz-icon
            nzType="icons:status-exclamation-octagon"
            class="svg-icon fillorange"
          ></i>
          <span *ngIf="sumWarning == 1" class="body-bold">
            {{ sumWarning }}</span
          >
          <span *ngIf="sumWarning > 1" class="body-bold">
            {{ sumWarning }}</span
          >
        </ng-template>
      </ng-container>
      <ng-container *ngIf="sumWorking > 0">
      <div class="clickable" (click)="onSetWorkingFineFilter()">
        <nz-alert
          nzType="success"
          class="nz-status-report"
          [nzMessage]="otherfineTemplate"
        ></nz-alert>
      </div>
      <ng-template #otherfineTemplate
        ><i nz-icon nzType="icons:status-check" class="svg-icon fillgreen"></i>
        <span *ngIf="sumWorking == 1" class="body-bold"> {{ sumWorking }}</span>
        <span *ngIf="sumWorking != 1" class="body-bold"> {{ sumWorking }}</span>
      </ng-template>
      </ng-container>
    </div>
  </div>

  <div
    class="satellite-btn-container"
    [class.mobile]="isMobile"
    [class.tablet]="isTablet"
    *ngIf="isMobile || isTablet"
  >
    <button
      id="satellite"
      class="drop-shadow"
      (click)="satelliteView = !satelliteView"
    >
      <ng-container *ngIf="!satelliteView"
        ><i nz-icon nzType="icons:satellite"></i>
        <!-- <span class="body-bold">{{ i18n.string("satellite_view") }}</span> -->
      </ng-container>
      <ng-container *ngIf="satelliteView"
        ><i nz-icon nzType="icons:map"></i>
        <!-- <span class="body-bold">{{ i18n.string("map_view") }}</span> -->
      </ng-container>
    </button>
  </div>
  <app-map-overlay
    [OverlayPlant]="OverlayPlant"
    (close)="OverlayPlant = null"
  ></app-map-overlay>
  <app-map
    [class.desktop]="isDesktop"
    [class.mobile]="!isDesktop"
    [Plants]="plants"
    [Loading]="loading || loadingThumbnails"
    [SatelliteView]="satelliteView"
    (markerClick)="onMapMarkerClick($event)"
    [InfoOverlay]="filteredPlants"
  ></app-map>
  <div
    class="satellite-btn-container"
    [class.mobile]="isMobile"
    [class.tablet]="isTablet"
    [class.desktop]="isDesktop"
    *ngIf="isDesktop"
  >
    <button
      id="satellite"
      class="drop-shadow"
      (click)="satelliteView = !satelliteView"
    >
      <ng-container *ngIf="!satelliteView"
        ><i nz-icon nzType="icons:satellite"></i>
        <span class="body-bold">{{ i18n.string("satellite_view") }}</span>
      </ng-container>
      <ng-container *ngIf="satelliteView"
        ><i nz-icon nzType="icons:map"></i>
        <span class="body-bold">{{ i18n.string("map_view") }}</span>
      </ng-container>
    </button>
  </div>
</ng-container>
