import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Asset } from '../../../model/asset';
import { Location } from '../../../model/location';
import { AssetsService } from '../../../services/assets.service';
import { I18nService } from '../../../services/i18n.service';
import { UiService } from '../../../services/ui.service';

enum filterStatus {
  inactive = 4,
  critical = 3,
  warning = 2,
  working = 1,
}

// It is necessary to set changeDetection to OnPush because of the way location objects behave.
// But it is also necessary to have the filter component within a component with default detection strategy.
// Therefore, we need this extra component.
@Component({
  selector: 'app-plant-list',
  templateUrl: './plant-list.component.html',
  styleUrls: ['./plant-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlantListComponent implements OnInit, OnDestroy {
  public plants: Location[] = [];
  public bcGroupedPlants: Location[][] = [];
  public sumCritical = 0;
  public sumWarning = 0;
  public sumInactive = 0;
  public sumWorking = 0;
  public filteredPlants;
  public loading;
  public loadingThumbnails;
  public filterStatus = filterStatus;
  plantSubscription = null;

  @Output() statusFilterValue = new EventEmitter<string>();

  get AssetCount(): number {
    return this.plants
      .map((plant) => plant.Assets)
      .flat(2)
      .filter((asset) => !asset.isArchived).length;
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(
    public assetsService: AssetsService,
    public changeRef: ChangeDetectorRef,
    public ui: UiService,
    public i18n: I18nService,
    public router: Router
  ) {}

  public ngOnInit() {
    this.ui.OnOrientationChange.subscribe(() => this.changeRef.detectChanges());
    this.refresh();
  }

  ngOnDestroy() {
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
  }

  get allWorking(): boolean {
    if (this.AssetCount > 0 && this.sumWorking > 0 && (this.sumCritical == 0 && this.sumInactive == 0 && this.sumWarning == 0)) {
      return true;
    }
    return false;
  }

  private refresh() {
    this.plants = [];
    this.loading = true;
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
    this.plantSubscription = this.assetsService.subscribeToPlants(
      (result: {
        plants: Location[];
        loadingComplete: boolean;
        filtered: boolean;
      }) => {
        this.loading = false;
        this.loadingThumbnails = !result.loadingComplete;
        this.filteredPlants = result.filtered;
        this.plants = result.plants.filter(
          (plant: Location) => plant.curLayer === 'c'
        );
        this.bcGroupedPlants = this.groupBy<Location>(this.plants, 'parentID');
        this.sumCritical = this.plants.reduce(function (criticalSum, plant) {
          return criticalSum + plant.Critical.length;
        }, 0);
        this.sumWarning = this.plants.reduce(function (sum, plant) {
          return sum + plant.Warning.length;
        }, 0);
        this.sumInactive = this.plants.reduce(function (sum, plant) {
          return sum + plant.InActive.length;
        }, 0);
        this.sumWorking = this.plants.reduce(function (sum, plant) {
          return sum + plant.Working.length;
        }, 0);
        this.changeRef.detectChanges();
      }
    );
  }

  public groupBy<T>(arr: T[], key): T[][] {
    return Object.values(
      arr.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {})
    );
  }

  public assetsGroupBy(plant) {
    return this.groupBy<Asset[]>(plant.Assets, 'parentID');
  }

  statusChecked(asset) {
    if (asset.critical && !asset.inactive) {
      return 'icons:status-exclamation-triangle';
    } else if (asset.inactive) {
      return 'icons:cross-circle';
    } else if (asset.warning) {
      return 'icons:status-exclamation-octagon';
    } else if (!asset.critical && !asset.warning && !asset.inactive) {
      return 'icons:status-check';
    }
  }

  onClick(plant: { objectID: any }, filterStatus: any) {
    setTimeout(() => {
      if (plant) {
        this.router.navigate(['plant', plant.objectID], {
          state: { status: filterStatus },
        });
      }
    });
  }

  public statusClick(filterStatus: string) {
    this.statusFilterValue.emit(filterStatus);
  }
}
