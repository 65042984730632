import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private storage: StorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!!this.storage.Token && this.storage.Token.length) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    if (route.routeConfig.path.includes('imprint'))
      this.router.navigate(['imprint']);
    else
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
    return false;
  }
}
