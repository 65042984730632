import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

const moveLeft = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    }),
  ]),
  query(':enter', [style({ left: '-100%' })]),
  query(':leave', animateChild()),
  group([
    query(':leave', [animate('300ms ease-out', style({ left: '100%' }))]),
    query(':enter', [animate('300ms ease-out', style({ left: '0%' }))]),
  ]),
  query(':enter', animateChild()),
];

const moveRight = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    }),
  ]),
  query(':enter', [style({ left: '100%' })]),
  query(':leave', animateChild()),
  group([
    query(':leave', [animate('200ms ease-out', style({ left: '-100%' }))]),
    query(':enter', [animate('300ms ease-out', style({ left: '0%' }))]),
  ]),
  query(':enter', animateChild()),
];
export const slideInAnimation = trigger('routeAnimations', [
  transition('Notifications => Homepage', moveLeft),
  transition('PlantOverview => Homepage', moveLeft),
  transition('AssetOverview => Homepage', moveLeft),
  transition('SensorHealth => Homepage', moveLeft),
  transition('Telemetry => Homepage', moveLeft),
  transition('SensorHistory => Homepage', moveLeft),
  transition('Settings => Homepage', moveLeft),
  transition('Homepage => Notifications', moveRight),
  transition('PlantOverview => Notifications', moveRight),
  transition('SensorHealth => Notifications', moveLeft),
  transition('Telemetry => Notifications', moveLeft),
  transition('SensorHistory => Notifications', moveLeft),
  transition('Settings => Notifications', moveLeft),
  transition('Homepage => PlantOverview', moveRight),
  transition('Notifications => PlantOverview', moveLeft),
  transition('AssetOverview => PlantOverview', moveLeft),
  transition('SensorHealth => PlantOverview', moveLeft),
  transition('Telemetry => PlantOverview', moveLeft),
  transition('SensorHistory => PlantOverview', moveLeft),
  transition('Settings => PlantOverview', moveLeft),
  transition('Homepage => AssetOverview', moveRight),
  transition('PlantOverview => AssetOverview', moveRight),
  transition('Notifications => AssetOverview', moveRight),
  transition('Settings => AssetOverview', moveLeft),
  transition('Homepage => SensorHealth', moveRight),
  transition('PlantOverview => SensorHealth', moveRight),
  transition('Notifications => SensorHealth', moveRight),
  transition('Settings => SensorHealth', moveLeft),
  transition('Homepage => Telemetry', moveRight),
  transition('PlantOverview => Telemetry', moveRight),
  transition('Notifications => Telemetry', moveRight),
  transition('Settings => Telemetry', moveLeft),
  transition('Homepage => SensorHistory', moveRight),
  transition('PlantOverview => SensorHistory', moveRight),
  transition('Notifications => SensorHistory', moveRight),
  transition('AssetOverview => SensorHistory', moveRight),
  transition('SensorHealth => SensorHistory', moveRight),
  transition('Settings => SensorHistory', moveLeft),
  transition('Telemetry => Settings', moveRight),
  transition('Homepage => Settings', moveRight),
  transition('PlantOverview => Settings', moveRight),
  transition('Notifications => Settings', moveRight),
  transition('AssetOverview => Settings', moveRight),
  transition('SensorHealth => Settings', moveRight),
  transition('SensorHistory => Settings', moveRight),
  transition('Telemetry => Settings', moveRight),
  transition('Settings => SettingsSub', moveRight),
  transition('SettingsSub => SettingsSubSub', moveRight),
  transition('SettingsSub => Settings', moveLeft),
  transition('SettingsSubSub => SettingsSub', moveLeft),
  transition('Settings => SettingsSubSub', moveRight),
  transition('SettingsSubSub => Settings', moveLeft),
  transition('Login => LegalDocuments', moveRight),
  transition('LegalDocuments => Login', moveLeft),
]);
