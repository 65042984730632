import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Location } from '../../model/location';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-disabled-assets',
  templateUrl: './disabled-assets.component.html',
  styleUrls: ['./disabled-assets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisabledAssetsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public plant: Location = null;
  public loading;
  public init = false;
  public empty_results;
  plantSubscription: Subscription = null;

  private tempIsMobile = null;

  isDisabled: boolean;

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get Buildings(): Location[] {
    if (
      !!this.plant &&
      !this.loading &&
      this.init &&
      this.plant.children &&
      this.plant.children[0].isAsset
    )
      return [this.plant]; // if we went too far down in the group view the assets might be displayed as buildings. this avoids that.
    return !!this.plant && !this.loading && this.init
      ? this.plant.children
      : [];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.tempIsMobile !== this.isMobile) {
      this.tempIsMobile = this.isMobile;
      this.changeRef.detectChanges();
    }
  }

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService,
    private changeRef: ChangeDetectorRef,
    public i18n: I18nService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init = true;
      this.changeRef.detectChanges();
    }, 0);
  }

  ngOnInit() {
    this.ui.onNavBack(() => {
      if (!this.plant) this.router.navigate(['home'], { replaceUrl: true });
      else {
        const parentID = this.plant.parentID;
        let parent: Location = this.getParentForBackNav(parentID);
        let nextParent: Location = this.assetsService.findLocation(
          parent.parentID
        );
        // find next layer in which there is more than one child
        while (
          nextParent &&
          parent.children.filter((child) => child.Assets.length > 0).length ===
            1 &&
          nextParent.curLayer !== 'a'
        ) {
          parent = nextParent;
          nextParent = this.assetsService.findLocation(nextParent.parentID);
        }
        if (!!parent && parent.curLayer !== 'a' && parent.curLayer !== 'b') {
          this.router.navigate(['plant', parent.objectID], {
            replaceUrl: true,
          });
        } else this.router.navigate(['home', 'list'], { replaceUrl: true });
      }
    });
    this.route.params.subscribe((params) => {
      this.loading = true;
      const plantID = +params['id'];
      this.assetsService.resetLocationFilter();
      if (this.plantSubscription) this.plantSubscription.unsubscribe();
      this.plantSubscription = this.assetsService.subscribeToPlants(
        (result: any) => {
          this.loading = false;
          let new_plant: Location = result.plants
            .map((item) => item.find(plantID))
            .find((item) => !!item);
          if (
            !!new_plant &&
            new_plant.Assets.length > 25 &&
            !new_plant.NextLayerIsAboveEquipment
          ) {
            // we are in group index -> we don't want to show only one tile
            // find next layer in which there is more than one child
            while (
              new_plant &&
              new_plant.children.length === 1 &&
              !new_plant.NextLayerIsAboveEquipment
            ) {
              new_plant = new_plant.children[0];
            }
          }
          this.plant = new_plant;
          if (!!this.plant) {
            const parentID = this.plant.parentID;
            const parent: Location = this.getParentForBackNav(parentID);
            const parentName =
              !!parent && parent.curLayer !== 'a' && parent.curLayer !== 'b'
                ? parent.objectName
                : this.i18n.string('home');
            this.ui.setBackText(this.i18n.parse(parentName));
            this.assetsService.updateSelectionPreset(
              !!this.plant ? [...this.plant.breadcrumbs, this.plant] : []
            );
          }
          this.empty_results = !new_plant;
          this.changeRef.detectChanges();
        }
      );
    });
  }

  ngOnDestroy() {
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
  }

  sortCritFirst(buildings) {
    return buildings
      .sort((x, y) => {
        return x.critical === y.critcial
          ? x.warning === y.warning
            ? 0
            : x.warning
            ? -1
            : 1
          : x.critical
          ? -1
          : 1;
      })
      .sort((x, y) => {
        return x.objectName.localeCompare(y.objectName);
      });
  }

  toLowerItem(building) {
    this.router.navigate(['plant', building.objectID]);
  }

  public groupBy<T>(arr: T[], key): T[][] {
    return Object.values(
      arr.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {})
    );
  }
  public assetsGroupBy(plant) {
    return this.groupBy<Location[]>(plant.Assets, 'parentID');
  }

  getParentForBackNav(parentID: number): Location {
    let parent: Location = this.assetsService.findLocation(parentID);
    while (
      !!parent &&
      parent.curLayer !== 'a' &&
      parent.curLayer !== 'b' &&
      parent.curLayer !== 'c'
    ) {
      if (
        parent.Assets.length > 25 &&
        !parent.IsAboveEquipment &&
        !parent.NextLayerIsAboveEquipment
      )
        break;
      parent = this.assetsService.findLocation(parent.parentID);
    }
    return parent;
  }
}
