<app-header
  [transparent]="false"
  [title]="!!plant ? i18n.parse(plant.objectName) : i18n.string('plant')"
  [backTo]="''"
>
</app-header>

<div class="content" [class.desktop]="isDesktop">
  <div *ngIf="isDesktop" [class.desktop-sidebar]="isDesktop">
    <app-sidebar [showFilter]="true" [plantsFilter]="true"></app-sidebar>
  </div>

  <div
    [class.desktop-main]="isDesktop"
    [class.mobile]="isMobile"
    [class.tablet]="isTablet"
  >
    <ng-container *ngIf="plant && (!empty_results || !isDesktop)">
      <div class="plant-thumbnail-header">
        <nz-avatar
          *ngIf="isDesktop"
          [nzSrc]="
            plant['thumbnail']
              ? plant['thumbnail']
              : 'assets/images/plant_default_picture.png'
          "
          [nzSize]="80"
          nzText="Plant"
        ></nz-avatar>
        <div class="breadcrump-content">
          <app-breadcrumbs
            [object]="plant"
            class="breadcrumbsSmallMargin"
          ></app-breadcrumbs>
          <h1 class="show_content">{{ plant.objectName }}</h1>
        </div>
      </div>
      <app-search-filter
        (FilterChanged)="onFilterChanged($event)"
        [Input]="plant.children"
        [filter]="filter"
        *ngIf="!isDesktop"
      >
      </app-search-filter>

      <ng-container *ngIf="!plant.filtered">
        <ng-container *ngIf="!empty_results">
          <ng-container
            *ngIf="
              plant.Assets.length <= 25 || plant.NextLayerIsAssociatedEquipment
            "
          >
            <ng-container *ngFor="let building of Buildings">
              <!--<div>
                <h2 class="show-archive">
                  {{ building.objectName }}
                  {{ i18n.string("show_archive") }} [{{
                    building.Assets.length
                  }}]
                </h2>
              </div>-->
              <div class="card-border">
                <app-card
                  [plant]="building"
                  [isDisabled]="false"
                  [detail]="true"
                  [showLayer]="true"
                  [showsArchived]="true"
                >
                  <div class="card-layer">
                    <ng-container
                      *ngFor="let equipment of building.AssociatedEquipments"
                    >
                      <div
                        class="card-layer-subtitle"
                        *ngIf="equipment.objectID !== building.objectID"
                      >
                        <span class="card-layer-subtitle-layername detail">
                          {{ i18n.parse(equipment.layerName) }}
                        </span>
                        <span
                          class="body-bold card-layer-subtitle"
                          *ngIf="equipment.Assets.length > 0"
                        >
                          {{ i18n.parse(equipment.objectName) }}</span
                        >
                      </div>
                      <div class="card-layer-content">
                        <ng-container *ngFor="let asset of equipment.Archived">
                          <app-asset-nav-button
                            [asset]="asset"
                            svg_icon="icons:asset"
                            >{{ asset.objectName | nameSlice }}
                          </app-asset-nav-button>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </app-card>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="plant.filtered">
        <app-card *ngIf="plant.filtered" [plant]="plant" [fine]="false">
          <ng-container *ngFor="let bcCluster of assetsGroupBy(plant)">
            <app-breadcrumbs [object]="bcCluster[0]"></app-breadcrumbs>
            <div class="search-result-wrapper">
              <ng-container *ngFor="let asset of bcCluster">
                <app-asset-nav-button
                  [asset]="asset"
                  [show_icon]="asset.critical"
                  svg_icon="icons:status-exclamation-triangle"
                  >{{ asset.objectName | nameSlice }}
                </app-asset-nav-button>
              </ng-container>
            </div>
          </ng-container>
        </app-card>
      </ng-container>
    </ng-container>
  </div>
</div>
