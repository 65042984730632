import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

import { Location } from '../../model/location';
import { LocationFilter } from '../../model/location.filter';
import { OTObject } from '../../model/object';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';
import { toObservableObject } from '../../util/observable-object';

class FilterSelection {
  public values: Location[] = [];
  public selected: Location = null;
  public name: string = '';
  public disabled: boolean = false;
  constructor(public comp: FilterComponent, public index: number) {}

  set Selected(selected: Location) {
    this.selected = selected;
    this.comp.setLocation(this.index, selected);
  }

  get Selected(): Location {
    return this.selected;
  }
}
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements AfterViewInit, OnInit, OnDestroy {
  private unfiltered: Location[] = [];

  public filterSelections: FilterSelection[] = [];
  public isSuperUser: boolean; // true if there are multiple customers in unfiltered asset list
  public customerList: string[] = [];
  public filterCount = 0;
  public plants: Location[] = [];
  public currentPlant: Location = null;
  plantSubscription = null;

  public empty: Location = new Location(new OTObject(), [], []);

  public filter = toObservableObject(new LocationFilter());

  private selectionPreset: Location[] = [];
  public get SelectionPreset(): Location[] {
    return this.selectionPreset;
  }
  public set SelectionPreset(preset: Location[]) {
    this.selectionPreset = preset;
    this.UpdateFilterSelections();
  }

  loading: boolean;
  @Input() submit_btn: boolean = false;
  @Input() notificationsFilter: boolean = false; // if true, we only want to filter assets that have a notification attached to them
  @Input() plantsFilter: boolean = false; // if true, we want to hide customer filter by default
  @Input() plantID: number = null;
  @Output() close = new EventEmitter<void>();

  _plantFilterStatus = null;
  @Input('plantFilterStatus')
  set plantFilterStatus(val: number) {
    if (this._plantFilterStatus !== val && val > 0) {
      // this is still done twice, so there is still two components when we only see one
      this.Status = val;
    }
    this._plantFilterStatus = val;
  }
  get plantFilterStatus(): number {
    return this._plantFilterStatus;
  }

  regionsSubscription = null;
  filterSubscription = null;
  selectionPresetSubscription = null;

  hasFlanges: boolean = true;
  hasPipes: boolean = true;
  hasValves: boolean = true;
  hasSteamTraps: boolean = true;
  hasTanks: boolean = true;
  hasRotatingEquipment: boolean = true;
  hasWarning: boolean = true;
  hasCritical: boolean = true;
  hasInactive: boolean = true;
  hasWorking: boolean = true;
  countryOrRegionSelected: number = null;

  get IsMobile(): boolean {
    return this.ui.IsMobile;
  }
  get IsTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  get AssetCount(): number {
    if (this.filterCount > 0) {
      if (this.plantID > 0) {
        return this.currentPlant.Assets.flat(2).filter(
          (asset) => !asset.isArchived
        ).length;
      } else {
        return this.plants
          .map((plant) => plant.Assets)
          .flat(2)
          .filter((asset) => !asset.isArchived).length;
      }
    }
    return -1;
  }

  @Input() set CountryOrRegionSelected(countryOrRegionSelected: number) {
    if (this.countryOrRegionSelected !== countryOrRegionSelected) {
      this.countryOrRegionSelected = countryOrRegionSelected;
      this.filter.location = [];
      this.filter.countryOrRegionSelected = this.countryOrRegionSelected;
    }
  }

  constructor(
    private ui: UiService,
    private assetsService: AssetsService,
    private changeRef: ChangeDetectorRef,
    public i18n: I18nService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
    this.plants = [];
    this.regionsSubscription = this.assetsService.subscribeToRegions(
      (items) => {
        const notifications = this.assetsService.getNotificationsInstantly();
        this.unfiltered = items.regions.filter(
          (region) =>
            !!region && region.layerID === 1 && region.Assets.length > 0
        ); // layerID = 1 means it is a region
        if (!!this.notificationsFilter && notifications.length > 0)
          // in notifications filter we only want to filter the objects with notifications
          this.unfiltered = this.unfiltered
            .map((item) => item.ApplyNotificationFilter(notifications))
            .filter((item) => !!item);
        this.UpdateFilterSelections();
        this.loading = false;
        this.changeRef.detectChanges();
      }
    );
    this.filterSubscription = this.assetsService.subscribeToFilter((filter) => {
      if (!filter) return;
      filter.countryOrRegionSelected = this.countryOrRegionSelected;
      this.filter = toObservableObject(filter);

      this.filter.observation$.pipe(debounceTime(50)).subscribe(() => {
        this.UpdateFilterSelections();

        // removed because filter don't update for plant-list filters - LKlum, 376988
        // if (!this.submit_btn) {
        this.assetsService.updateFilter(new LocationFilter(this.filter));
        this.AssetCount;
        // }
      });
      this.changeRef.detectChanges();
    });
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();

    this.plantSubscription = this.assetsService.subscribeToPlants(
      (result: {
        plants: Location[];
        loadingComplete: boolean;
        filtered: boolean;
      }) => {
        if (this.plantID > 0) {
          const new_plant = result.plants
            .map((plant) => plant.findLocation(this.plantID))
            .find((plant) => !!plant && plant.objectID === this.plantID);
          this.currentPlant = !!new_plant ? new_plant : null;
        } else {
          this.plants = result.plants.filter(
            (plant: Location) => plant.curLayer === 'c'
          );
        }

        this.filterCount = this.assetsService.FilterSettingsCount;
        this.changeRef.detectChanges();
      }
    );
    this.selectionPresetSubscription =
      this.assetsService.subscribeToSelectionPreset((preset) => {
        this.SelectionPreset = preset;
      });

    this.UpdateFilterSelections();
  }

  ngAfterViewInit() {
    this.filterCount = this.assetsService.FilterSettingsCount;
  }

  ngOnDestroy() {
    if (!!this.regionsSubscription) this.regionsSubscription.unsubscribe();
    if (!!this.filterSubscription) this.filterSubscription.unsubscribe();
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
    if (!!this.selectionPresetSubscription)
      this.selectionPresetSubscription.unsubscribe();
  }

  UpdateFilterSelections() {
    const notifications = this.assetsService.getNotificationsInstantly();
    setTimeout(() => {
      let temp_filter = new LocationFilter();
      temp_filter.selectedTypes = this.filter.selectedTypes;
      temp_filter.working = this.filter.working;
      temp_filter.warning = this.filter.warning;
      temp_filter.critical = this.filter.critical;
      temp_filter.inactive = this.filter.inactive;
      let last_values = this.unfiltered;
      this.filterSelections = [];
      for (
        let i = 0;
        (i <= this.filter.location.length ||
          i <= this.selectionPreset.length) &&
        last_values.length > 0;
        i++
      ) {
        const values = last_values
          .map((item) => item.ApplyFilter(temp_filter))
          .filter((item) => !!item);
        const selection = !!this.selectionPreset[i]
          ? this.selectionPreset[i]
          : !!this.filter.location[i]
          ? values.find(
              (item: Location) => item.objectID === this.filter.location[i]
            )
          : undefined;
        this.filterSelections.push(
          Object.assign(new FilterSelection(this, i), {
            values: values,
            selected: !!selection ? selection : this.empty,
            name: getChildrenLayerNames(values, this.i18n),
            disabled: this.selectionPreset.length > i,
          })
        );
        if (!selection) break;
        last_values = selection.children;
      }
      temp_filter = new LocationFilter();
      temp_filter.warning = this.filter.warning;
      temp_filter.critical = this.filter.critical;
      temp_filter.working = this.filter.working;
      temp_filter.location = this.filter.location;
      temp_filter.inactive = this.filter.inactive;
      temp_filter.preselection = this.filter.preselection;
      if (!this.plantsFilter) temp_filter.customer = this.filter.customer;
      if (temp_filter.location.length < temp_filter.preselection) {
        // initially, the location filter is empty, which is why we do not set the available
        // equipment type buttons correctly without doing this:
        temp_filter.location = this.selectionPreset.map(
          (item) => item.objectID
        );
      }
      let filtered = this.unfiltered.map((item) =>
        item.ApplyFilter(temp_filter)
      );
      let assets = filtered
        .map((item) => (!!item ? item.Assets : []))
        .flat(2)
        .filter(
          (item) =>
            !this.notificationsFilter ||
            !!notifications.find((noti) => noti.objectID === item.objectID)
        );
      this.hasFlanges = !!assets.find(
        (item) => item.equipmentType.toLowerCase() === 'flange'
      );
      this.hasPipes = !!assets.find(
        (item) => item.equipmentType.toLowerCase() === 'pipe'
      );
      this.hasValves = !!assets.find(
        (item) => item.equipmentType.toLowerCase() === 'valve'
      );
      this.hasSteamTraps = !!assets.find(
        (item) => item.equipmentType.toLowerCase() === 'steam trap'
      );
      this.hasTanks =
        !!assets.find((item) => item.equipmentType.toLowerCase() === 'tank') &&
        false;
      this.hasRotatingEquipment = !!assets.find(
          (item) => item.equipmentType.toLowerCase() === 'rotating equipment'
        );

      if (this.plantsFilter) {
        this.filterStatus(assets);
      }

      if (!this.plantsFilter) {
        function unique(value: string, index: number, array: string[]) {
          return index === array.findIndex((item: string) => item === value);
        }

        // user is super user if the unfiltered assets contain more than one customer
        this.isSuperUser =
          this.unfiltered
            .map((item) => (!!item ? item.Assets : []))
            .flat(2)
            .map((item) => item.customerName)
            .filter(unique).length > 1;

        // filter again with selected types included to get customer list
        temp_filter.selectedTypes = this.filter.selectedTypes;
        temp_filter.customer = null;

        // remove pre selection for this, because otherwise we create a feedback loop that inhibits us from selecting other customers
        if (temp_filter.location.length <= this.filter.preselection)
          temp_filter.location = [];

        filtered = this.unfiltered.map((item) => item.ApplyFilter(temp_filter));
        assets = filtered
          .map((item) => (!!item ? item.Assets : []))
          .flat(2)
          .filter(
            (item) =>
              !this.notificationsFilter ||
              !!notifications.find((noti) => noti.objectID === item.objectID)
          );
        this.filterStatus(assets);

        this.customerList = assets
          .map((item) => item.customerName)
          .filter(unique)
          .sort();
      }
      this.changeRef.detectChanges();
    });
  }

  filterStatus(assets) {
    this.hasInactive = !!assets.find((item) => item.inactive === true);
    this.hasCritical = !!assets.find(
      (item) => item.critical === true && item.inactive === false
    );
    this.hasWarning = !!assets.find(
      (item) =>
        item.warning === true &&
        item.inactive === false &&
        item.critical === false
    );
    this.hasWorking = !!assets.find(
      (item) =>
        item.inactive === false &&
        item.critical === false &&
        item.warning === false
    );
  }

  set Status(val: number) {
    this.filter.inactive = val === 4;
    this.filter.critical = val === 3;
    this.filter.warning = val === 2;
    this.filter.working = val === 1;
  }

  get Status(): number {
    if (this.filter.inactive) return 4;
    if (this.filter.critical) return 3;
    if (this.filter.warning) return 2;
    if (this.filter.working) return 1;
    return 0;
  }

  set Customer(customer: string) {
    if ((!!customer && !customer.length) || customer === 'null')
      customer = null;
    this.filter.customer = customer;
  }

  get Customer(): string {
    if (this.customerList.length === 1) return this.customerList[0]; // display only selectable customer
    if (!this.filter.customer) return 'null';
    return this.filter.customer;
  }

  get CustomerSelectDisabled(): boolean {
    return this.customerList.length < 2;
  }

  setLocation(index: number, value: Location) {
    if (!!this.SelectionPreset && !!this.SelectionPreset[index]) return;
    if (value.objectID !== this.filter.location[index]) {
      if (value === this.empty) value = null;
      this.filter.location = [
        ...this.SelectionPreset.map((item: Location) => item.objectID),
        ...[...this.filter.location]
          .splice(0, index)
          .splice(this.SelectionPreset.length),
        ...(value ? [value.objectID] : []),
      ];
    }
  }

  isFiltered() {
    return (
      this.filter.IsFiltering &&
      (!this.plantsFilter || !this.filter.IsOnlyFilteringCustomer)
    );
  }

  onSubmit() {
    this.assetsService.updateFilter(this.filter);
    this.close.emit();
  }

  clearAllFilters(event) {
    this.assetsService.clearFilter();
    event.stopPropagation();
  }

  fnLocationCompare(o1: Location, o2: Location) {
    if (!o1 || !o2) return false;
    return o1.objectID === o2.objectID;
  }

  fnCustomerCompare(o1: string, o2: string) {
    if (!o1 || !o2) return false;
    return o1 === o2;
  }
}

function getChildrenLayerNames(locs: Location[], i18n: I18nService): string {
  if (!locs || locs.length === 0) {
    return 'N/A';
  }

  if (locs.length > 3) return i18n.string('location');

  const unique = (value: string, index: number, array: string[]) =>
    array.findIndex((item) => item === value) === index;

  return locs
    .map((item: Location) => i18n.parse(item.layerName))
    .filter(unique)
    .filter((item) => !!item)
    .reduce((a: string, b: string) => a + (!!a ? '/' : '') + b, '');
}
