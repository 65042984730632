/**
 * REFACTORING TO DO
 * -----------------
 * 
 * refactor marker creation to be more intuitive than 10 magic numbers in a ctor call
 */

export class Marker {
    public sensorID: number;
    public relativeX: number;
    public relativeY: number;
    public relativeDiameter: number;
    public active: boolean = false;
    public label: string;
    public selected: boolean = true;
  
    public circular: boolean = true;
  
    public relativeWidth: number;
    public relativeHeight: number;
  
    public getAbsoluteX(width: number): number {
      return Math.round(this.relativeX * width);
    }
  
    public getAbsoluteY(height: number): number {
      return Math.round(this.relativeY * height);
    }
  
    public getClientX(
      client_w: number,
      client_h: number,
      nat_w: number,
      nat_h: number,
      offset: number = 0
    ): number {
      const ratio_w = client_w / nat_w,
        ratio_h = client_h / nat_h,
        ratio = ratio_w > ratio_h ? ratio_w : ratio_h,
        offset_w = ratio_w < ratio_h ? -(nat_w * ratio_h - client_w) * 0.5 : 0;
  
      let x = this.getAbsoluteX(nat_w) * ratio + offset_w;
  
      const MARKER_OFFSET = offset;
      if (x < MARKER_OFFSET) x = MARKER_OFFSET;
      if (x > client_w - MARKER_OFFSET) x = client_w - MARKER_OFFSET;
  
      return x;
    }
  
    public getClientY(
      client_w: number,
      client_h: number,
      nat_w: number,
      nat_h: number,
      offset: number = 0
    ): number {
      const ratio_w = client_w / nat_w,
        ratio_h = client_h / nat_h,
        ratio = ratio_w > ratio_h ? ratio_w : ratio_h,
        offset_h = ratio_w > ratio_h ? -(nat_h * ratio_w - client_h) * 0.5 : 0;
  
      let y = this.getAbsoluteY(nat_h) * ratio + offset_h;
  
      const MARKER_OFFSET = offset;
      if (y < MARKER_OFFSET) y = MARKER_OFFSET;
      if (y > client_h - MARKER_OFFSET) y = client_h - MARKER_OFFSET + 5; // +5px is a fixed amount so the marker actually fits nicely on the bottom side
  
      return y;
    }
  
    public getAbsoluteHeight(height: number): number {
      return Math.round(this.relativeHeight * height);
    }
  
    public getAbsoluteWidth(width: number): number {
      return Math.round(this.relativeWidth * width);
    }
  
    public getAbsoluteDiameter(width: number): number {
      return Math.round(this.relativeDiameter * width);
    }
  
    public setAbsoluteX(x: number, width: number) {
      this.relativeX = x > 0 ? x / width : 0;
    }
  
    public setAbsoluteY(y: number, height: number) {
      this.relativeY = y > 0 ? y / height : 0;
    }
  
    public setClientX(
      x: number,
      client_w: number,
      client_h: number,
      nat_w: number,
      nat_h: number
    ) {
      const ratio_w = client_w / nat_w,
        ratio_h = client_h / nat_h,
        ratio = ratio_w > ratio_h ? ratio_w : ratio_h,
        offset_w = ratio_w < ratio_h ? -(nat_w * ratio_h - client_w) * 0.5 : 0,
        offset_h = ratio_w > ratio_h ? -(nat_h * ratio_w - client_h) * 0.5 : 0;
  
      this.setAbsoluteX((x - offset_w) / ratio, nat_w);
    }
  
    public setClientY(
      y: number,
      client_w: number,
      client_h: number,
      nat_w: number,
      nat_h: number
    ) {
      const ratio_w = client_w / nat_w,
        ratio_h = client_h / nat_h,
        ratio = ratio_w > ratio_h ? ratio_w : ratio_h,
        offset_w = ratio_w < ratio_h ? -(nat_w * ratio_h - client_w) * 0.5 : 0,
        offset_h = ratio_w > ratio_h ? -(nat_h * ratio_w - client_h) * 0.5 : 0;
  
      this.setAbsoluteY((y - offset_h) / ratio, nat_h);
    }
  
    public setAbsoluteDiameter(d: number, width: number) {
      this.relativeDiameter = d > 0 ? d / width : 0;
    }
  
    public static getDeselectedEmptyMarker(): Marker {
      const m = new Marker();
      m.selected = false;
      return m;
    }
  
    constructor(
      sensorID: number = -1,
      label: string = '',
      relativeX: number = 0.5,
      relativeY: number = 0.5,
      relativeDiameter: number = 0.1,
      selected: boolean = true,
      // this is a hack to set absolute coordinates. Otherwise ignore
      width = 0,
      height = 0,
      // we need elliptical markers now
      relativeWidth = 0,
      relativeHeight = 0
    ) {
      this.sensorID = sensorID;
      this.label = label;
      this.relativeDiameter = relativeDiameter;
      this.selected = selected;
  
      if (width > 0 || height > 0) {
        this.setAbsoluteX(relativeX, width);
        this.setAbsoluteY(relativeY, height);
      } else {
        this.relativeX = relativeX;
        this.relativeY = relativeY;
      }
  
      if (relativeWidth > 0 || relativeHeight > 0) {
        this.circular = false;
        this.relativeWidth = relativeWidth;
        this.relativeHeight = relativeHeight;
      }
    }
  }
  
  export class FlatMarker {
    sensorId: number;
    x: number;
    y: number;
  }
  