<div id="slider-container">
  <span class="date-label"> {{ range_start | date }} </span>
  <div id="slider" #slider>
    <div id="bar"></div>
    <div
      id="marked-bar"
      [style.left.%]="start_marker_percentage"
      [style.right.%]="100 - end_marker_percentage"
    ></div>
    <div id="marker2" [style.left.%]="end_marker_percentage">
      <div
        class="marker"
        (touchstart)="onMousedown(false)"
        (touchend)="onMouseup()"
        (mousedown)="onMousedown(false)"
      ></div>
    </div>
    <div id="marker1" [style.left.%]="start_marker_percentage">
      <div
        class="marker"
        (touchstart)="onMousedown(true)"
        (touchend)="onMouseup()"
        (mousedown)="onMousedown(true)"
      ></div>
    </div>
  </div>
  <span class="date-label"> {{ range_end | date }} </span>
</div>
