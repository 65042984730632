<div class="sensordata-wrapper" *ngIf="SteamTrapStatusCurrentTelemetry">
  <app-sensor-value
    [telemetry]="SteamTrapStatusCurrentTelemetry"
    [criticalOverride]="CriticalOverride"
    [warningOverride]="WarningOverride"
    [asHeader]="true"
    [isSteamTrap]="true"
  >
  </app-sensor-value>
  <div class="grapharea-wrapper">
    <app-tab-bar
      [opaque]="true"
      [icon]="false"
      (tabChanged)="onTelemetryTimeframeSelect($event, Telemetry)"
      [disabled]="
        noDataTelemetry(Telemetry) ||
        graphBrushActive ||
        getTimeframe(Telemetry).custom
      "
    >
      <app-tab-bar-item
        class="one-of-three"
        [inactive]="isTelemetryTimeframeCustom"
      >
        {{ i18n.string("days") }}</app-tab-bar-item
      >
      <app-tab-bar-item
        class="one-of-three"
        [inactive]="isTelemetryTimeframeCustom"
      >
        {{ i18n.string("weeks") }}</app-tab-bar-item
      >
      <app-tab-bar-item
        class="one-of-three"
        [inactive]="isTelemetryTimeframeCustom"
      >
        {{ i18n.string("months") }}</app-tab-bar-item
      >
    </app-tab-bar>
    <ng-container *ngIf="!graphDisplays.length">
      <div class="graph-wrapper">
        <app-spinner [black]="true"></app-spinner>
      </div>
    </ng-container>
    <ng-container
      *ngIf="graphDisplays.length > 0 && !!graphDisplays[0].graphData"
    >
      <div
        class="range-picker-backdrop"
        *ngIf="graphDisplays[0].graphData[0].openRangePicker > 0"
        (click)="cancelRangePick(graphDisplays[0].graphData[0])"
      ></div>
      <div
        class="range-picker-wrapper"
        [class.disabled]="noDataTelemetry(Telemetry)"
      >
        <ng-container *ngIf="isDesktop; else mobileDatePicker">
          <nz-range-picker
            [nzShowToday]="false"
            [nzAllowClear]="false"
            [nzDisabledDate]="
              rangePickerDisabledDate(graphDisplays[0].graphData[0], 1)
            "
            #datePick
            [(ngModel)]="range"
            [id]="
              'picker_start_' +
              graphDisplays[0].graphData[0].metadata.measurementTypeID
            "
            (ngModelChange)="
              onRangePickerSelect($event, graphDisplays[0].graphData[0])
            "
          >
          </nz-range-picker>
        </ng-container>
        <ng-template #mobileDatePicker>
          <nz-date-picker
            [nzShowToday]="false"
            [nzAllowClear]="false"
            #datePick
            [id]="
              'picker_start_' +
              graphDisplays[0].graphData[0].metadata.measurementTypeID
            "
            [nzDisabledDate]="
              rangePickerDisabledDate(graphDisplays[0].graphData[0], 1)
            "
            [(ngModel)]="range[0]"
            (ngModelChange)="
              onRangeStartPick(
                graphDisplays[0].graphData[0].metadata.measurementTypeID,
                graphDisplays[0].currentTelemetry.sensor.sensorID,
                $event,
                'picker_end_' +
                  graphDisplays[0].graphData[0].metadata.measurementTypeID,
                graphDisplays[0].graphData[0]
              )
            "
          >
          </nz-date-picker>
          <nz-date-picker
            [nzShowToday]="false"
            [nzAllowClear]="false"
            #datePick
            [id]="
              'picker_end_' +
              graphDisplays[0].graphData[0].metadata.measurementTypeID
            "
            [nzDisabledDate]="
              rangePickerDisabledDate(graphDisplays[0].graphData[0], 2)
            "
            [(ngModel)]="range[1]"
            (ngModelChange)="
              onRangeEndPick(
                graphDisplays[0].graphData[0].metadata.measurementTypeID,
                graphDisplays[0].currentTelemetry.sensor.sensorID,
                $event,
                graphDisplays[0].graphData[0]
              )
            "
          >
          </nz-date-picker>
          <button>
            <span
              [class.active]="
                graphDisplays[0].graphData[0].openRangePicker === 1
              "
              (click)="
                openRangePicker(
                  'picker_start_' +
                    graphDisplays[0].graphData[0].metadata.measurementTypeID,
                  graphDisplays[0].graphData[0]
                )
              "
              >{{ range[0] | date : DateFormat }}</span
            >
            -
            <span
              [class.active]="
                graphDisplays[0].graphData[0].openRangePicker === 2
              "
              (click)="
                openRangePicker(
                  'picker_end_' +
                    graphDisplays[0].graphData[0].metadata.measurementTypeID,
                  graphDisplays[0].graphData[0]
                )
              "
              >{{ range[1] | date : DateFormat }}</span
            >
            <i
              nz-icon
              nzType="icons:edit"
              class="sensor-icon svg-icon"
              [class.fillgrey]="noData(graphDisplays[0].graphData[0])"
              (click)="
                openRangePicker(
                  'picker_start_' +
                    graphDisplays[0].graphData[0].metadata.measurementTypeID,
                  graphDisplays[0].graphData[0]
                )
              "
            ></i>
          </button>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngFor="let display of SteamTrapHealth">
      <!-- single object array, because apparently there is no *ngLet directive-->
      <ng-container *ngIf="!display.loading">
        <ng-container *ngFor="let data of display.graphData">
          <div class="temp-spacer"></div>
          <span class="analytics-header">{{
            i18n.string("steam_trap_states")
          }}</span>
          <div
            class="steam-trap-state-legend"
            [class.mobile]="isMobile"
            [class.tablet]="isTablet"
          >
            <div class="green"></div>
            <span>{{ i18n.string("steam_trap_working") }}</span>
            <div class="yellow"></div>
            <span>{{ i18n.string("steam_trap_blow_through") }}</span>
            <div class="red"></div>
            <span>{{ i18n.string("steam_trap_blocked") }}</span>
          </div>
          <div class="graph-wrapper">
            <app-spinner
              *ngIf="!data || !!data.loading"
              [black]="true"
            ></app-spinner>
            <app-graph
              [Data]="data"
              *ngIf="!data.error"
              [class.hidden]="!!data.loading"
              Type="pie"
              [Timeframe]="getTimeframe(Telemetry)"
              [Settings]="settings"
              (brushStateChange)="graphBrushActive = $event"
              (timeframeUpdated)="onDateRangeUpdatedfromGraph($event)"
              [hasResetButton]="false"
            >
            </app-graph>
            <span *ngIf="!data.loading && !!data.error" class="error">{{
              i18n.string("error_loading_data")
            }}</span>
          </div>
          <span class="analytics-header monitor">{{
            i18n.string("steam_trap_health")
          }}</span>
          <div class="graph-wrapper">
            <app-spinner
              *ngIf="!data || !!data.loading"
              [black]="true"
            ></app-spinner>
            <app-graph
              [Data]="data"
              *ngIf="!data.error"
              [class.hidden]="!!data.loading"
              Type="gantt"
              [Timeframe]="getTimeframe(Telemetry)"
              [Settings]="settings"
              (brushStateChange)="graphBrushActive = $event"
              (timeframeUpdated)="onDateRangeUpdatedfromGraph($event)"
              [hasResetButton]="false"
            >
            </app-graph>
            <span *ngIf="!data.loading && !!data.error" class="error">{{
              i18n.string("error_loading_data")
            }}</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="SteamTrapLoss && SteamTrapLoss.length > 0">
      <span class="analytics-header steam-loss">{{
        i18n.string("steam_loss_overview")
      }}</span>
      <span class="analytics-content">{{
        i18n.string("steam_loss_overview_content")
      }}</span>
      <!-- <span class="analytics-daterange">{{range[0]
        |
        date :
        DateFormat}} - {{
        range[1] | date :
        DateFormat}}</span> -->
      <div class="steam-loss-tab-selection">
        <app-tab-bar
          *ngIf="HasSteamTrapLossAmount && HasSteamTrapLossMass"
          [opaque]="true"
          [icon]="true"
          [disabled]="false"
          (tabChanged)="onTabChanged($event)"
        >
          <app-tab-bar-item [inactive]="false" class="one-of-two"
            >{{ i18n.string("steam_loss") }} ({{
              steamLossCurrencyUnit
            }})</app-tab-bar-item
          >
          <app-tab-bar-item [inactive]="false" class="one-of-two"
            >{{ i18n.string("steam_loss") }} ({{steamLossMassUnit}})</app-tab-bar-item
          >
        </app-tab-bar>
      </div>
      <div class="steam-loss-info-wrapper">
        <div class="steam-loss-card">
          <span
            *ngIf="HasSteamTrapLossAmount && displaySteamTrapLossAmount"
            class="value"
            >{{ steamTrapLossAmountSum }} {{ steamLossCurrencyUnit }}</span
          >
          <span
            *ngIf="HasSteamTrapLossMass && !displaySteamTrapLossAmount"
            class="value"
            >{{ steamTrapLossMassSum }} {{steamLossMassUnit}}</span
          >
          <span class="label"
            >{{ range[0] | date : DateFormat }} -
            {{ range[1] | date : DateFormat }}</span
          >
        </div>
      </div>
      <ng-container *ngFor="let display of SteamTrapLoss">
        <!-- single object array, because apparently there is no *ngLet directive-->
        <ng-container *ngIf="!display.loading">
          <ng-contianer *ngFor="let data of display.graphData">
            <div class="graph-wrapper steam-loss">
              <app-spinner
                *ngIf="!data || !!data.loading"
                [black]="true"
              ></app-spinner>
              <app-graph
                [Data]="data"
                *ngIf="!data.error"
                [class.hidden]="!!data.loading"
                Type="bars"
                [Timeframe]="getTimeframe(Telemetry)"
                [Settings]="settings"
                (brushStateChange)="graphBrushActive = $event"
                (timeframeUpdated)="onDateRangeUpdatedfromGraph($event)"
                (timeframeReset)="resetTimeframeSelect(Telemetry)"
                [displayResetBtn]="graphBrushActive"
                [hasResetButton]="true"
              >
              </app-graph>
              <span *ngIf="!data.loading && !!data.error" class="error">{{
                i18n.string("error_loading_data")
              }}</span>
            </div>
          </ng-contianer>
        </ng-container>
      </ng-container>
      <div *ngIf="showPrediction()" class="steam-loss-prediction-wrapper">
        <span class="analytics-header steam-loss">{{
          i18n.string("steam_loss_prediction")
        }}</span>
        <span class="analytics-content">{{
          i18n.string("steam_loss_prediction_content")
        }}</span>
        <div class="steam-loss-info-wrapper">
          <div class="steam-loss-card">
            <span
              *ngIf="HasSteamTrapLossAmount && displaySteamTrapLossAmount"
              class="value"
              >{{ steamTrapLossAmount1Week }} {{ steamLossCurrencyUnit }}</span
            >
            <span
              *ngIf="HasSteamTrapLossMass && !displaySteamTrapLossAmount"
              class="value"
              >{{ steamTrapLossMass1Week }} {{steamLossMassUnit}}</span
            >
            <span class="label"
              >{{ i18n.string("in_the_next") }}
              <span class="text-bold">{{ i18n.string("week_1") }}</span>
            </span>
          </div>
          <div class="steam-loss-card">
            <span
              *ngIf="HasSteamTrapLossAmount && displaySteamTrapLossAmount"
              class="value"
              >{{ steamTrapLossAmount1Month }} {{ steamLossCurrencyUnit }}</span
            >
            <span
              *ngIf="HasSteamTrapLossMass && !displaySteamTrapLossAmount"
              class="value"
              >{{ steamTrapLossMass1Month }} {{steamLossMassUnit}}</span
            >
            <span class="label"
              >{{ i18n.string("in_the_next") }}
              <span class="text-bold">{{ i18n.string("month_1") }}</span>
            </span>
          </div>
          <div class="steam-loss-card">
            <span
              *ngIf="HasSteamTrapLossAmount && displaySteamTrapLossAmount"
              class="value"
              >{{ steamTrapLossAmount3Months }}
              {{ steamLossCurrencyUnit }}</span
            >
            <span
              *ngIf="HasSteamTrapLossMass && !displaySteamTrapLossAmount"
              class="value"
              >{{ steamTrapLossMass3Months }} {{steamLossMassUnit}}</span
            >
            <span class="label"
              >{{ i18n.string("in_the_next") }}
              <span class="text-bold">{{ i18n.string("months_3") }}</span>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
