import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-imprint-page',
  templateUrl: './imprint-page.component.html',
  styleUrls: ['./imprint-page.component.css'],
})
export class ImprintPageComponent implements OnInit, AfterViewInit {
  constructor(
    public router: Router,
    public i18n: I18nService,
    private location: Location
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {}

  onBack() {
    // this.router.navigate(['login']);
    this.location.back();
  }
}
