<div class="asset-back-btn" (click)="onBack()" [class.mobile]="isMobile" [class.tablet]="isTablet">
  <i nz-icon nzType="icons:arrow-left" class="svg-icon"></i>
  <span>{{ i18n.string('back_to_equipment_status') }}</span>
</div>

<div id="content" [class.mobile]="isMobile" [class.tablet]="isTablet">
  <i nz-icon nzType="icons:instruction" class="svg-icon header-icon"></i>
  <p class="title">{{ i18n.string('repair_and_replace') }}</p>
  <div id="sections" *ngIf="!loading && !error">
    <div class="section">
      <div class="section-header">
        <i nz-icon nzType="icons:repair_equipment" class="svg-icon section-icon"></i>
        <span class="section-title">{{ i18n.string('repair_equipment') }}</span>
      </div>
      <p class="section-text">
        Check the equipment and proceed to fix it if needed. Here is some information that may be of help to you:
      </p>
      <app-repair-instructions></app-repair-instructions>
    </div>
    <div class="section-separator" *ngIf="isDesktop"></div>
    <div class="section">
      <div class="section-header">
        <i nz-icon nzType="icons:wifi" class="svg-icon section-icon"></i>
        <span class="section-title">{{ i18n.string('replace_sensor') }}</span>
      </div>
      <p class="section-text">
        Once the problem is fixed, reset the sensor or connect a new one via the installation agent.
      </p>
      <!--<div class="sensor-card" [class.working]="Working" [class.warning]="Warning" [class.critical]="Critical">
        <p class="sensor-name"> {{ SensorName }} </p>

      </div>-->
      <app-tile *ngIf="!!Telemetry" [telemetry]="Telemetry" [isSteamTrap]="HasSteamTrapAnalytics"
        [isSteamTrapInletOutlet]="IsSteamTrap && !HasSteamTrapAnalytics" [steamtrap_outlet]="SteamTrapOutlet"
        [asset]="asset" displayStyle="repair-and-replace"></app-tile>
      <app-nav-button [href]="InstallationAgentURL" [primary]="true" svg_icon="icons:wifi"
        (click)="overlayDocumentation = true">
        {{i18n.string('connect_new_sensor')}}</app-nav-button>
    </div>
    <div id="additional-info-container"
      *ngIf="!!asset && (asset.additionalInfo.length> 0 || asset.additionalDocuments.length > 0)">
      <app-additional-info [asset]="asset" [Sensor]="Sensor"></app-additional-info>
    </div>
  </div>

  <p *ngIf="!loading && error">
    error *todo*
  </p>

  <app-spinner *ngIf="loading" [black]="true"></app-spinner>
</div>