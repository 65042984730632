<div class="content content-more-margin desktop-wide">
  <h1>{{i18n.string('imprint2')}}</h1>
  <p class="body-bold"> {{i18n.string('service_provider_title')}}</p>
  <p>
    {{i18n.string('service_provider')}}
    <br /><br />
  </p>
  <p class="body-bold"> {{i18n.string('registerd_office_title')}}</p>
  <p>
    {{i18n.string('registerd_office')}}
    <br /><br />

  </p>
  <p class="body-bold"> {{i18n.string('postal_adress_title')}}</p>
  <p class="wrap-text">
    {{i18n.string('postal_adress')}}
    <br /><br />
  </p>
  <p class="body-bold"> {{i18n.string('commercial_register_title')}}</p>
  <p>
    {{i18n.string('commercial_register')}}
    <br /><br />
  </p>
  <p class="body-bold"> {{i18n.string('vat_id_no_title')}}</p>
  <p>
    {{i18n.string('vat_id_no')}}
    <br /><br />
  </p>
  <p class="body-bold"> {{i18n.string('email_contact_title')}}</p>
  <p>
    {{i18n.string('email_contact')}}
    <br /><br />
  </p>

  <div class="back-btn">
    <app-nav-button [darksecondary]="true" svg_icon="icons:arrow-left" (click)="gotoLogin()">{{
      i18n.string("back") }}
    </app-nav-button>
  </div>

  <app-scroll-to-top></app-scroll-to-top>

</div>