<nz-modal
  [nzVisible]="true"
  [nzFooter]="null"
  nzTitle="Sensor instructions"
  (nzOnCancel)="close.emit()"
  [nzCloseIcon]="'icons:exit'"
>
  <!-- <app-overlay-card [header]="'Sensor instructions'" [closeBtn]="true" (close)="close.emit()"> -->
  <div class="card-layer">
    <h2>To remove and reinstall the sensor you need to do the next steps:</h2>
    <app-overlay-card-item
      *ngFor="let item of sensorInstructions"
      [item]="item"
    ></app-overlay-card-item>
    <div class="buttonwrapper">
      <app-nav-button [primary]="true" (click)="close.emit()"
        >Got it!</app-nav-button
      >
    </div>
  </div>
  <!-- </app-overlay-card> -->
</nz-modal>
