export enum ValueRangeType {
  green = 0,
  yellow,
  red,
}

const ZONE_COLOR_GREEN = '#d6f5cf';
const ZONE_COLOR_YELLOW = '#f8f1d4';
const ZONE_COLOR_RED = '#f8d3d3';
const ICON_COLOR_GREEN = '#548e54';
const ICON_COLOR_YELLOW = '#d5b742';
const ICON_COLOR_RED = '#e21321';

export class ValueRange {
  constructor(
    public min: number,
    public max: number,
    public type: ValueRangeType,
    public measurementTypeID: number
  ) {}

  public getZoneColor() {
    switch (this.type) {
      case ValueRangeType.green:
        return ZONE_COLOR_GREEN;
      case ValueRangeType.yellow:
        return ZONE_COLOR_YELLOW;
      case ValueRangeType.red:
        return ZONE_COLOR_RED;
      default:
        return 'transparent';
    }
  }

  public getIconColor() {
    switch (this.type) {
      case ValueRangeType.green:
        return ICON_COLOR_GREEN;
      case ValueRangeType.yellow:
        return ICON_COLOR_YELLOW;
      case ValueRangeType.red:
        return ICON_COLOR_RED;
      default:
        return 'black';
    }
  }

  public getIcon() {
    switch (this.type) {
      case ValueRangeType.green:
        return '\uf058';
      case ValueRangeType.yellow:
        return '\uf12a';
      case ValueRangeType.red:
        return '\uf071';
    }
  }
}
