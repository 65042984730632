<div class="content content-more-margin desktop-wide">
    <h1>North America Privacy Policy</h1>
    
    <p><span class="body-bold">Thank you for visiting a website, mobile app or other online service (“Service”) that belongs to or is operated by Henkel Corporation or one of its subsidiary or affiliated companies ("Henkel", “Us”, “We”, “Our”). This Privacy Policy outlines Henkel’s practices with respect to Personal Information we may collect.
    </span></p>
    <p>Click here to view Our Terms of Use (“ToU”). California residents, click here to view Our additional notice about personal information we collect and your California privacy rights.</p>
    <p>Effective as of: August 10, 2023</p>
    
    <h3>1. Scope</h3>
    <p>This Privacy Policy applies to your use of any Service (e.g., website, mobile app, email, or internet-connected product) that posts a link to, or otherwise provides notice of the applicability of, this Privacy Policy, regardless of how you access or use it. This Privacy Policy also applies to Our collection, use and disclosure of Personal Information about you through any other Services or means, including – for example – through sweepstakes and prize drawings we may offer. This Privacy Policy does not apply to third-party websites accessible via hyperlinks found on a Henkel Service.</p>
    <p>For certain aspects of the Service, there may be additional notices about information practices and choices. Please read those additional privacy disclosures to understand how they apply to you.</p>
    <p>By using Our Service in any way, such as browsing or interacting, you signify that you consent to and agree with the practices described in Our current Privacy Policy as posted here. Terms of Use can be found here. If you do not consent to Our practices as described in this Privacy Policy, you may not and should not use Our Service.</p>
    
    <h3>2.  Revisions</h3>
    <p>Henkel reserves the right to change this Privacy Policy prospectively effective upon the posting of the revised Privacy Policy on this page. Your use of the Service after such an update indicates your consent to the Privacy Policy posted at the time of use. However, We will not use your previously collected Personal Information (defined below), to the extent it is not collected under the new Privacy Policy, in a manner materially different than identified at the time it was collected except as permitted by law. To the extent any provision of this Privacy Policy is found by a competent tribunal to be invalid or unenforceable, such provision shall be severed to the extent necessary for the remainder to be valid and enforceable.</p>
    
    <h3>3. Information Collected</h3>
    <p>You can visit Our Service without telling Us who you are or revealing Personal Information about yourself, however, doing so may render certain Henkel Services unavailable to you. You may, however, choose to provide Personal Information (defined as information that identifies you or that can reasonably identify you personally, such as your first and last name, e-mail address, phone number, address, and full payment account number).</p>
    <p>The types of information that Henkel may collect about you include, but are not limited to:</p>
    <p class="list-item">• Contact information and account information (such as name, address, city, state, province/territory, postal/ZIP code, email address, telephone number, username, password, and social media handle), including information you provide when creating an account, or when contacting us such as via Our “Contact Our Company” contact form here.</p>
    <p class="list-item">• Demographic information such as age range, gender, date of birth, marital status, postal/ZIP code, and household composition.</p>
    <p class="list-item">• Device and Usage Information such as IP address, device identifier, browser type, operating system, webpages you visit, information about your use of the Service, including the date and time of use, information about your browsing and search activity on Our Service, data regarding network connected hardware (e.g., computer or mobile device), any other data we require for transmitting requested content, and data collected by Tracking Technologies (as described in Section 6 below).</p>
    <p class="list-item">• User Generated Content, including photographs and images, videos, reviews, articles, and comments, which are subject to the User Generated Content sections of our Terms of Use here.</p>
    <p class="list-item">• Information we may collect in connection with sweepstakes, contests, prize drawings, or promotions, such as information necessary to confirm your eligibility or to provide you with prizes or rewards if you win.</p>
    <p class="list-item">• Information you provide when registering for or participating in our surveys, product research initiatives, quizzes, questionnaires, or similar initiatives.</p>
    <p class="list-item">• Information you elect to share with features of the Services that can help you find products that may be of interest to you (such as hair type).</p>
    <p class="list-item">• Order and transaction history if you purchase products from us or our brands, including product preferences.  When you place an order with a payment card, our Service may enable you to provide payment information to our payment processor(s) to make the payment.  </p>
    <p class="list-item">• Employment application information (such as information in resumes and cover letters, employment history, background screening information, demographic information as permitted by law, and any other information you provide or we collect in connection with your application).</p>
    <p class="list-item">• Information you provide to us in connection with communications with us.  For example, if you send us an email, we collect the information you provide via the email. Similarly, if you call one of our customer service or product assistance lines, we may (as permitted by law) record the call and collect information you provide to us during the call.</p>
    
    <p>If you choose to participate in activities made available by our Services such as website registrations, contests, sweepstakes, questionnaires, surveys, consumer service contacts, product purchases, mobile applications, social media platforms, or through Our online Careers portal or employment inquiries, Henkel will collect Personal Information that you provide. </p>
    <p>We may collect Personal Information directly from you, such as when you make a purchase, or conduct another transaction, contact Us with a question or complaint, create an account on Our website, review Our products, respond to a survey, upload content to any of Our websites, provide your social media content, participate in a contest or other promotion, make an online appointment, sign-up to attend an event, apply for employment, sign-up to receive marketing communications, or use other services, features or resources We make available on Our Service. </p>
    <p>We may enhance or merge the information that you provide to Us directly with information from other sources, including from Our business partners and service providers, such as demographic companies, analytics providers, advertising companies and networks, third-party retailers, and other third parties, for the purposes described below.</p>
    <p>As an example, if you use or add a method of payment within our Service, we may combine that with information we receive from payment processors regarding transactions made with the same payment method.  As permitted by law, we may also collect Personal Information about you from your friends or family members, such as when your friend or family member sends you a gift or makes a referral.</p>
    
    <h3>4. Information Use</h3>
    <p>We collect, maintain and use Personal Information for purposes not inconsistent with this Privacy Policy or any written statements We make at the time of collection, such as for:</p>
    <p class="list-item">• providing the Service to you or others</p>
    <p class="list-item">• establishing and verifying the identity and eligibility of users</p>
    <p class="list-item">• uploading and displaying your postings and similar submitted content (“User Generated Content” or “UGC”). (For more information on how UGC is treated under the Service’s Terms of Use click here to review Section 7 of the ToU.  For more on the public nature of UGC, see Section 7 of the ToU)
    </p>
    <p class="list-item">• operating Our brands and Our business, including management, reporting, auditing, and similar operational functions</p>
    <p class="list-item">• opening, maintaining, administering and servicing users’ profiles, accounts or memberships</p>
    <p class="list-item">• processing, servicing or enforcing transactions and sending related communications</p>
    <p class="list-item">• providing products, services and support to users, such as (for example) by registering you for classes or other education related to our products or Services</p>
    <p class="list-item">• analytics, such as to evaluate and improve Our websites, products, Services, and advertisements</p>
    <p class="list-item">• conducting sweepstakes, surveys and contests, and providing the results thereof</p>
    <p class="list-item">• operating and improving the Service, including tailoring it to users' preferences</p>
    <p class="list-item">• providing users with product or service updates</p>
    <p class="list-item">• sending promotional notices, offers or other targeted marketing, and other information</p>
    <p class="list-item">• responding to your questions, inquiries, comments and instructions, including any requests submitted through Our “Contact Our Company” contact form</p>
    <p class="list-item">• maintaining the security and integrity of Our systems</p>
    <p class="list-item">• understanding consumer preferences regarding our brands</p>
    <p class="list-item">• maintaining customer relationships</p>
    <p class="list-item">• evaluating product performance and safety</p>
    <p class="list-item">• complying with policies, procedures, requests from government bodies, and legal requirements</p>
    <p class="list-item">• improving Our products and services and Our other business purposes and general administrative tasks, and</p>
    <p class="list-item">• other purposes disclosed when Personal Information is submitted to Us.</p>
    <p>Information that you provide when applying for a job or an internship via Our online Careers portal is used to provide you with information about employment opportunities, to administer the application process and consider you for employment. Where applicable, in conjunction with laws and regulations enforced by the Equal Employment Opportunity Commission (“EEOC”), the Office of Federal Contract Compliance Programs (“OFCCP”) and similar state and local regulatory agencies, we may ask you to provide Us with self-identifying information (such as veteran status, gender and ethnicity). Providing such self-identifying information is voluntary, but if you do provide Us with such information, we may submit that information, to the EEOC, the OFCCP and similar state and local regulatory agencies or otherwise use or disclose it as permitted or required by law, including, without limitation, responding to information requests, fulfilling regulatory reporting requirements and defending against employment related complaints.</p>
    
    <h3>5. Information You Disclose Publicly or to Others</h3>
    <p>The Service may permit you to post or submit UGC including, without limitation, written content, user profiles, audio or visual recordings, computer graphics, pictures, data, or other content, including Personal Information.  All UGC is subject to our Terms of Use. In addition to and without prejudice to the Terms of Use, if you choose to submit UGC to any public area of the Service, your UGC will be considered “public” and will be accessible by anyone, including Henkel. Notwithstanding anything to the contrary, Personal Information included in UGC is or may be used and shared by Henkel and third parties to the fullest extent not prohibited by applicable law. Henkel encourages you to exercise caution when making decisions about what you disclose in such public areas. For more information on how UGC is treated under the Service’s Terms of Use click here.</p>
    <p>Additionally, the Service may offer you the option to send a communication to a friend or other contact. If so, you may only send communications to people that have given you permission to do so and who have consented to the disclosure of their Personal Information by you to Us. The recipient’s Personal Information you provide (e.g., name, e-mail address) will be used to facilitate the communication and may be used as permitted by this Privacy Policy and applicable law. Your full name, contact information and message may be included in the communication. </p>
    <p>For residents of Canada, you may only provide us with the contact information of friends or other personal contacts with whom you have had direct voluntary two-way communications, and with whom it is reasonable to conclude you have a personal relationship considering shared interests, experiences, opinions and other relevant factors. You may only provide email addresses of family members to whom you are related by marriage, a common-law partnership, or parent-child relationship, and with whom you have had direct voluntary two-way communications.</p>
    
    <h4>6. Targeted Advertising, Technology Logging, Cookies, and Related Techniques</h4>
    <p>When you interact with Our Service, your browser or device may communicate with the technology that We use as part of Our Service.  This can result in the automated collection of data about your browser and/or mobile app and device. For example, as you use Our Service, your browser communicates with Our technology, which in turn keeps records of your interactivity and requests for services and content. The types of such Usage Information may include your Internet Protocol (IP) address, browser type, URL, requests made, pages visited, content or ads you see or click on during your visit and when and for how long you do so, items you download, the next website you visit when you leave the website, search terms you have entered on the website or a referral site, other activities conducted on the page, day and time of visit and related technical usage information.  We use this information in accordance with this Privacy Policy.</p>
    <p>Cookies and other tracking technologies now and hereafter developed (“Tracking Technologies”) may be used to collect information about interactions with the Service or e-mails, including information about your browsing and purchasing behavior, for the purposes outlined in this Privacy Policy. We use these technical means and so may Our Affiliates, business partners, and Service Providers. </p>
    <p>A cookie is a small text file that is stored on a user’s device, which may be session ID cookies or tracking cookies. Session cookies make it easier for you to navigate the Service and expire when you close your browser. Tracking cookies remain longer and help in understanding how you use the Service and enhance your user experience. Cookies may remain on your hard drive for an extended period of time. The Service may associate some or all of these types of cookies with your devices. You can manage whether your browser permits certain cookies and other Tracking Technologies in your browser settings, or you can review the types of cookies that are used on a site by selecting the “cookies” link on the applicable site and deactivate the types of cookies you do not want active. Other types of Tracking Technologies may include web beacons, scripts, and in-app technologies like software development kits.  See Section 12 regarding certain choices regarding these activities.  </p>
    <p>Like many companies, Henkel processes Personal Information for purposes of targeted advertising.  We or Our third-party advertising and personalization partners may collect and use information from Tracking Technologies to develop personalized content and advertising (including for companies not affiliated with Us) based on your visits over time on Henkel and non-Henkel websites, apps and other online services. You may see these advertisements on other websites, apps and online services. This information may also be used to evaluate Our and third-party online advertising campaigns or to tailor promotions and other marketing messages to you on Our Service and other websites apps and online services.  These ads may be tailored to your interest based on your browsing of the Service and elsewhere on the internet, sometimes referred to as “targeted advertising,” “interest-based advertising” and “online behavioral advertising” (“Targeted Advertising”). For information on your ability to opt-out of Targeted Advertising see Section 12 below.</p>
    
    <h3>7. Data Sharing and Storage</h3>
    <p>Henkel may share your information with third parties to the extent not inconsistent with this Privacy Policy or other written statements made by Us at the time of collection, and not prohibited by applicable law, including without limitation as follows:</p>
    <p class="list-item">• Henkel’s agents, vendors, consultants, and other service providers (collectively “Service Providers”) may receive, or be given access to your information, including in connection with their work on Henkel’s behalf. For more information on choices you may have see Section 12.</p>
    <p class="list-item">• We may share your information with Our co-sponsor(s) if We obtain your information in connection with a contest, sweepstakes, offering, or other promotional activity that is jointly offered by Us and any third parties.</p>
    <p class="list-item">• We may release Personal Information if permitted or required to do so by our policies, law, search warrant, subpoena, court order or upon request of government authorities.</p>
    <p class="list-item">• We may provide Personal Information, and other information, to third parties in connection with the reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of Our business, assets, or stock, including any such activities associated with a bankruptcy or similar proceeding.</p>
    <p class="list-item">• Henkel may share Personal Information with its affiliated companies and subsidiaries (“Affiliates”), and other third parties, some of which may be located outside of the United States and Canada.</p>
    <p>Personal Information may be stored in Henkel databases, some of which are located outside of the United States and Canada, and will be automatically transferred to these databases for storage and maintenance. The Service is based in the U.S. and is intended for residents of the U.S. and Canada. Otherwise, if you are accessing the Service from outside of the U.S., please be aware that information collected through the Service may be transferred to, processed, stored, and used in the U.S. and in other locations other than your location of residence. Data protection laws in the U.S. and in other jurisdictions may be different from those of your country of residence and provide a lower level of protection, and information transferred to the U.S. or elsewhere may be accessed by U.S. or other governments and law enforcement in accordance with U.S. laws or the laws of the applicable jurisdiction. Your use of the Service, or provision of any information via the Service, therefore, constitutes your consent to the transfer to and from, processing, usage, sharing, and storage of your information, including Personal Information, in the U.S. as set forth in this Privacy Policy.</p>
    
    <h3>8. Third-Party Content, Third-Party Services, Social Features</h3>
    <p>The Service may include hyperlinks to, or include on or in connection with, the Service (e.g., apps and plug-ins), websites, locations, platforms, applications or services operated by third parties (“Third-Party Service(s)”). These Third-Party Services may use their own cookies, web beacons, and other Tracking Technologies to independently collect information about you and may solicit Personal Information from you. Henkel is not responsible for the privacy practices or the content of the Third-Party Services. Please note that the privacy statements applicable to such Third-Party Services may differ significantly from the Henkel Privacy Policy, so We advise you to read them carefully before using those services. You agree that Henkel shall not be held liable for any actions by or content of such Third-Party Services.</p>
    <p>Certain functionalities on the Service permit interactions that you initiate between the Service and certain Third-Party Services, such as third-party social networks (“Social Features”). Examples of Social Features include: enabling you to send content such as contacts and photos between the Service and a Third-Party Service; “liking” or “sharing” Henkel’s content; logging in to the Service using your Third-Party Service account (e.g., using Facebook Connect to sign-in to the Service); and to otherwise connect the Service to a Third-Party Service (e.g., to pull or push information to or from the Service). If you use Social Features, and potentially other Third-Party Services, information you post or provide access to may be publicly displayed on the Service (see Section 5) or by the Third-Party Service that you use. Similarly, if you post information on a third-party service that references the Service (e.g., by using a hashtag associated with Henkel or other Henkel Affiliates in a post or status update), you agree that your post may be used on or in connection with the Service or otherwise by Henkel or other Henkel Affiliates. Also, both Henkel and the third party may have access to certain information about you and your use of the Service and any Third-Party Service. This Policy applies to Our use of information you submit to Us via Social Features, but it does not apply to what those third-party platforms do with your information. Those platforms have their own privacy policies, and We encourage you to read them.</p>
    
    <h3>9. Security and Monitoring</h3>
    <p>Henkel will take precautions to protect your Personal Information. Personal Information may be accessed by persons within Our organization as may be permitted or required by the applicable law. Please be aware, however, that any email or other transmission you send through the Internet cannot be completely protected against unauthorized interception and that the internet and online digital storage are not completely secure, and We do not guarantee the security of your information transmitted through the Service.</p>
    
    <h3>10. Notice of Monitoring of Services</h3>
    <p>Henkel, Our brands, and Our Service Providers and third party partners may monitor any use of or activity on our Services for our business purposes.  This may include records of actions you take while using our websites, apps, or other Services, records of parts or all of your sessions our Services, information you submit on our Services, or communications or information sent through or using our Services. Tracking Technologies used on Our Service (including cookies, pixels, and web beacons) may also collect information reflecting your use of and activity on Our Service.  This information may be collected, retained, and/or monitored by Us or by Our Service Providers and third-party partners.  </p>
    <p>Additionally, if you engage with chat functionalities on our Services, your conversations via our chat functionalities may be retained by Us and Our Service Providers for our business purposes. This can include any interaction between you and Our chat technologies, information you input into chats, and the conversations you have through Our chat functionalities. </p>
    <p>If you do not consent to the monitoring described here, you should not use Our Service.</p>
    
    <h3>11. Service Not Directed to Minors</h3>
    <p>The Service is intended for a general audience and not directed to children less than 18 years of age. If you are not 18 or older, you are not permitted to use our Services.</p>
    
    <h3>12. Choices Regarding Personally Information</h3>
    <h4>A. Tracking Technologies Generally</h4>
    <p>Cookies may generally be disabled or removed by tools available as part of most commercial browsers, and in some instances blocked by selecting certain settings. Browsers offer different functionalities and options so you may need to set them separately. Please be aware that if you disable or remove these technologies, some parts of the Service may not work and that when you revisit the Service your ability to limit browser-based Tracking Technologies is subject to your browser settings and limitations.</p>
    
    <h4>B. Analytics and Advertising Tracking Technologies</h4>
    <p>Like many companies, Henkel processes information we collect about you for Targeted Advertising. You may choose whether to receive some Targeted Advertising by:</p>
    <p class="list-item">1 Submitting opt-outs to industry programs. Service Providers that perform advertising-related services for Us and third parties may participate in the Digital Advertising Alliance’s (“DAA”) Self-Regulatory Program for Online Behavioral Advertising. To learn more about how you can opt-out from Targeted Advertising, visit http://www.aboutads.info/choices or Digital Advertising Alliance of Canada at https:/youradchoices.ca/en/tools. These companies may also be members of the Network Advertising Initiative (“NAI”). To learn more about the NAI and your opt-out options for their members, see http://www.networkadvertising.org/choices/.</p>
    <p class="list-item">2  You can also opt out of Targeted Advertising by (a) clicking on Do Not Sell or Share My Personal Information and submitting an opt-out request to us, or by (b) clicking on “Cookie Settings” on the relevant website and deactivate cookies.</p>
    
    <h4>C. Mobile Apps</h4>
    <p>With respect to Henkel’s mobile apps (“apps”), you can stop all collection of data generated by use of the app by uninstalling the app. Also, you may be able to exercise specific privacy choices, such as enabling or disabling certain features (e.g., location-based services, push notifications, accessing calendar/contacts/phots, etc.), by adjusting the permissions in your mobile device and/or the app’s settings. For information about opting out of Targeted Advertising relating to mobile apps, visit the DAA’s opt-out program for mobile apps at https://www.aboutads.info/appchoices.</p>
    
    <h4>D. Communications</h4>
    <p>You can opt out of receiving certain promotional communications (emails or text messaging) from Henkel at any time by (i) for promotional e-mails, following the instructions provided in emails to click on the unsubscribe link, or if available by changing your communication preferences by logging onto your account; and (ii) for text messages, following the instructions provided in text messages from Henkel to text the word, “STOP”; and (iii) for app push notifications turn off push notifications on the settings of your device and/or the app, as applicable. Please note that your opt-out is limited to the e-mail address or phone number used and will not affect subsequent subscriptions. If you opt-out of only certain communications, other subscription communications may continue. Even if you opt-out of receiving promotional communications, Henkel may, subject to applicable law, continue to send you non-promotional communications, such as those about your account, transactions, servicing, or Our ongoing business relations.</p>
    
    <h4>E. Careers Center</h4>
    <p>If you have created a resume using Our online Careers portal you may access, review, correct, update, change or delete your account profile information or resume at any time. Simply log into your account, go to your profile or resume, and make the necessary changes. You may delete your resume from Our online database at any time by accessing your account; provided, however, that We reserve the right to maintain a copy for as long as is required or permitted by applicable law.</p>
    
    <h3>13. Notices and Rights for Residents of Certain States and Canada</h3>
    <h4>A. California Rights: </h4>
    <p>Additional notice about our privacy practices and your California-specific rights are explained here:
    </p>
    
    <h4>B. Connecticut, Colorado, and Virginia Residents: </h4>
    <p>Under applicable law, you may have the rights (in each case under the conditions and to the extent set out in applicable law):</p>
    <p class="list-item">• Right of Access: A right to confirm whether we are processing Personal Information about you, and to request to access such Personal Information (including, if applicable, in a portable and readily usable format). </p>
    <p class="list-item">• Right of Correction: A right to correct inaccuracies in certain Personal Information we may hold about you, and</p>
    <p class="list-item">• Right of Deletion: A right to request that we delete Personal Information concerning you.  Please note that if you have requested a service that requires the use of your Personal Information, we may not be able to provide that service if you choose to delete your Personal Information.</p>
    <p>You can submit a request to exercise your rights by visiting our Consumer Rights Request page here.</p>
    <p>If your rights request is denied, applicable law may give you a right to lodge an appeal with use. The response to your rights request will inform you of any appeal rights you may have and tell you how you can exercise them.</p>
    
    <h4>C. Nevada Rights: </h4>
    <p>Nevada “consumers” have the right to instruct us not to “sell” “covered information” as those terms are defined by Chapter 603A of the Nevada Revised Statutes. Although we do not currently “sell” “covered information” of Nevada “consumers” as those terms are defined under that law, you may contact us at PrivacyNA@henkel.com and provide your name, Nevada resident address, and email address to be verified and exercise your opt-out rights in the event we do sell covered information under that law in the future.  If you change your email address or other contact information, contact us in the same manner to update your contact information to help facilitate your opt-out. Changing your contact information elsewhere (e.g., informational requests, account information, etc.) will not update your Nevada opt-out information and we will only use the information provided to our opt-out program for managing opt-outs. It is your responsibility to keep your opt-out information current.</p>
    
    <h4>D. Canadian Rights: </h4>
    <p>If you are in Canada, you have a right to request access to your Personal Information and to request a correction to it if you believe it is inaccurate. If you have would like to have access to your Personal Information that we hold, or if you would like to have it corrected, please contact Us using the contact information provided below and we will provide you with any such Personal Information to the extent required by applicable laws.  If you successfully demonstrate that your Personal Identifiable Information in Our records is inaccurate or incomplete, we will amend the Personal Identifiable Information as required. We may require you to verify your identity before We allow you to access your Personal Information. You can also contact Us using the contact information provided below if you have questions about this Privacy Policy, how We process Personal Information, or if you would like to submit a complaint. If you wish to withdraw your consent to our collection, use or disclosure of your Personal Information, please contact Us using the contact information provided below. In some cases, withdrawal of your consent may mean that we will no longer be able to provide certain Services or other products or services.</p>
    
    <h3>14. Questions</h3>
    <p>Please send Us your questions or comments regarding Our privacy practices by emailing Us at PrivacyNA@henkel.com, or sending Us a letter addressed to:</p>
    <p class="wrap-text">{{ i18n.string("privacy_inquiry_adress") }}</p>
    </div>