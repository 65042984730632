import {
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'cc-date-range-slider',
  templateUrl: './date-range-slider.component.html',
  styleUrls: ['./date-range-slider.component.css'],
})
export class DateRangeSliderComponent {
  @Input() slider_start: Date = new Date('01 Jan 1970 00:00:00 GMT');
  @Input() slider_end: Date = new Date();
  @Input() range_start: Date = new Date('01 Jan 1970 00:00:00 GMT');
  @Input() range_end: Date = new Date();

  @Output() rangeChanged = new EventEmitter<{ start: Date; end: Date }>();

  private dragging_marker: number = 0; // 0 -> none, 1 -> left, 2 -> right
  private valueChanged: boolean = false;

  get start_marker_percentage(): number {
    const slider =
      Date.parse(this.slider_end.toISOString()) -
      Date.parse(this.slider_start.toISOString());
    const start_range =
      Date.parse(this.range_start.toISOString()) -
      Date.parse(this.slider_start.toISOString());
    return (start_range / slider) * 100;
  }
  get end_marker_percentage(): number {
    const slider =
      Date.parse(this.slider_end.toISOString()) -
      Date.parse(this.slider_start.toISOString());
    const end_range =
      Date.parse(this.range_end.toISOString()) -
      Date.parse(this.slider_start.toISOString());
    return (end_range / slider) * 100;
  }
  get range_width_percentage(): number {
    return 100 - this.start_marker_percentage - this.end_marker_percentage;
  }

  constructor() {}

  @ViewChild('slider') slider: ElementRef;

  @HostListener('document:touchmove', ['$event'])
  onTouchMove(e) {
    const clientX = e.targetTouches[0].clientX;
    const clientY = e.targetTouches[0].clientY;
    if (
      clientY <= 0 ||
      clientX <= 0 ||
      clientX >= window.innerWidth ||
      clientY >= window.innerHeight
    ) {
      this.onMouseup();
    } else {
      this.moveMarker(clientX);
    }
  }
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    const clientX = e.clientX;
    const clientY = e.clientY;
    if (
      clientY <= 0 ||
      clientX <= 0 ||
      clientX >= window.innerWidth ||
      clientY >= window.innerHeight
    ) {
      this.onMouseup();
    } else {
      this.moveMarker(clientX);
    }
  }
  @HostListener('document:mouseleave', [])
  onMouseleave() {
    this.onMouseup();
  }
  @HostListener('document:mouseup', [])
  onMouseup() {
    this.dragging_marker = 0;
    if (this.valueChanged) {
      this.rangeChanged.emit({ start: this.range_start, end: this.range_end });
    }
  }

  private moveMarker(clientX) {
    if (this.dragging_marker === 0) return;
    const left = this.slider.nativeElement.getBoundingClientRect().left;
    const right = this.slider.nativeElement.getBoundingClientRect().right;
    let normalized_x = (clientX - left) / (right - left);
    if (normalized_x < 0) normalized_x = 0;
    if (normalized_x > 1) normalized_x = 1;

    const min = Date.parse(this.slider_start.toISOString());
    const max = Date.parse(this.slider_end.toISOString());
    const new_date_in_ms = min + (max - min) * normalized_x;
    if (this.dragging_marker === 1) {
      this.range_start = new Date(new_date_in_ms);
      if (this.range_start > this.range_end) {
        this.range_start = new Date(this.range_end);
        this.range_start.setDate(this.range_start.getDate() - 1);
      }
      if (this.range_start < this.slider_start) {
        this.range_start = new Date(this.slider_start);
      }
      const upperBound = new Date(this.slider_end);
      upperBound.setDate(upperBound.getDate() - 1);
      if (this.range_start > upperBound && this.slider_start < upperBound)
        this.range_start = upperBound;
    } else if (this.dragging_marker === 2) {
      this.range_end = new Date(new_date_in_ms);
      if (this.range_start > this.range_end) {
        this.range_end = new Date(this.range_start);
        this.range_end.setDate(this.range_end.getDate() + 1);
      }
      if (this.range_end > this.slider_end)
        this.range_end = new Date(this.slider_end);
      const lowerBound = new Date(this.slider_start);
      lowerBound.setDate(lowerBound.getDate() + 1);
      if (this.range_end < lowerBound && this.slider_end > lowerBound) this.range_end = lowerBound;
    }
    this.valueChanged = true;
  }

  public onMousedown(left: boolean) {
    this.dragging_marker = left ? 1 : 2;
    this.valueChanged = false;
  }
}
