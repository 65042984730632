import { ElementRef } from '@angular/core';
import * as d3 from 'd3';

import { UserSettings } from '../../model/settings';
import { I18nService } from '../../services/i18n.service';
import { ValueRange, ValueRangeType } from './value-range';
import { Visualization } from './visualization.interface';

const LOCALE_EN = {
  dateTime: '%x, %X',
  date: '%-m/%-d/%Y',
  time: '%-I:%M:%S %p',
  periods: ['AM', 'PM'],
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

const LOCALE_DE = {
  dateTime: '%A, der %e. %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
};

export class PieChart implements Visualization {
  private x_domain = null; // this defines the section of the historical data we want to see
  private y_domain = null; // this defines the section of the values we are interested in
  private valueRanges: ValueRange[];
  private y_unit: string = '';
  private customRange: boolean = false;
  // TODO hardcoded for dev purposes

  private data: any[];
  public updateChart;

  constructor(
    private chartContainer: ElementRef,
    private id: string,
    private i18n: I18nService
  ) {}

  // public render(data: any[], isMobile, settings: UserSettings, isFirstDraw) {
  public render(data: any[], isMobile, settings: UserSettings) {
    if (!!data) {
      this.data = data.map((t) => {
        return {
          date: new Date(t.timestamp),
          value: t.value,
          text: t.measuredValue,
        };
      });
    }

    this.renderGraph(isMobile, settings);
  }

  public SetValueUnit(unit: string) {
    this.y_unit = unit;
  }

  public SetXDomain(start: Date, end: Date, custom: boolean = false) {
    // set right graph cutoff to midnight of the current day (or next day 00:00):

    /*if (!custom && (end.getHours() > 0 || end.getMinutes() > 0)) {
      end = new Date(new Date(end).setDate(end.getDate()));
    }*/
    // if (!custom) start = new Date(start.toDateString());
    // if (!custom) end = new Date(end.toDateString());
    this.x_domain = d3.extent([start, end], (d) => d);

    this.customRange = custom;
    if (!!this.updateChart) this.updateChart();
  }
  public ResetXDomain() {
    this.x_domain = null;
  }
  public SetYDomain(
    domain: [number, number],
    booleanDomain: boolean = false,
    measurementID: number
  ) {
    this.y_domain = domain;
  }
  public ResetYDomain() {
    this.y_domain = null;
  }
  public SetValueRanges(ranges: ValueRange[]) {
    this.valueRanges = ranges;
  }

  private renderGraph(isMobile: boolean, settings: UserSettings) {
    d3.select('svg#' + this.id).remove();
    const max_width = this.chartContainer.nativeElement.clientWidth;
    const max_height =
      window.innerWidth < 376
        ? 220
        : this.chartContainer.nativeElement.clientHeight;
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
    // set the dimensions and margins of the graph
    const margin = {
        top: 50,
        right: 0,
        bottom: 40,
        left: 0,
      },
      width = max_width - margin.left - margin.right,
      height = max_height - margin.top - margin.bottom;

    const radius = Math.min(width, height) / 2;

    // on mobile renderGraph() sometimes seems to get called with too small clientHeights
    // since it gets called again later with the correct height, we can skip further execution for now
    if (height < 0) return;

    let svg, arcs, outerArc, outerArc2;

    let unique_identifier,
      element, // html element containing the chart
      noData, // true if we literally don't have any data
      pie,
      generator,
      data = {},
      color = {},
      labels = {};

    const drawChart = () => {
      init();
      drawPie();
      drawLabels();
    };

    const init = () => {
      unique_identifier = generateUniqueIdentifier();
      element = this.chartContainer.nativeElement;
      noData = this.data.length < 1;

      if (!noData) {
        genData(true);
      }

      initSVG();
    };

    const genData = (initial: boolean = false) => {
      const temp = [0, 0, 0];
      let last_ts = null;
      let last_index = null;
      let filtered_data = this.data.filter(
        (item) =>
          !this.x_domain ||
          (item.date >= this.x_domain[0] && item.date <= this.x_domain[1])
      );
      if (
        filtered_data.length <
        this.data.filter(
          (item) => !this.x_domain || item.date <= this.x_domain[1]
        ).length
      ) {
        // there is data before our x_domain start
        // -> insert a data point at x_domain[0] with the last value that is not in the range
        filtered_data = [
          {
            ...this.data
              .filter((item) => !this.x_domain || item.date > this.x_domain[0])
              .pop(),
            date: this.x_domain[0],
          },
          ...filtered_data,
        ];
      }

      filtered_data.map((item) => {
        const val = item.value;
        let index = 0;
        if (val > 15) {
          if (val <= 65) {
            index = 1;
          } else {
            index = 2;
          }
        }
        /*if (!!last_ts) {
          const diff = +new Date(item.date) - last_ts;
          temp[last_index] += diff;
        }*/

        const diff = !!last_ts ? +new Date(item.date) - last_ts : 0;
        temp[index] += diff;
        last_ts = new Date(item.date);
        last_index = index;
      });
      const temp_sum = temp[0] + temp[1] + temp[2];
      data = { Working: 0, 'Blow through': 0, Blocked: 0 };
      if (temp[0] > 0) data['Working'] = temp[0] / temp_sum;
      if (temp[1] > 0) data['Blow through'] = temp[1] / temp_sum;
      if (temp[2] > 0) data['Blocked'] = temp[2] / temp_sum;
      /*
        Working: temp[0] > 0 ? temp[0] / temp_sum : undefined,
        'Blow through': temp[1] > 0 ? temp[1] / temp_sum : undefined,
        Blocked: temp[2] > 0 ? temp[2] / temp_sum : undefined,

      */
      // set the color scale
      color = {
        Working: '#00AA75',
        'Blow through': '#ECB102',
        Blocked: '#E1000F',
      };
      labels = {
        Working: (data['Working'] * 100).toFixed(0) + '%',
        'Blow through': (data['Blow through'] * 100).toFixed(0) + '%',
        Blocked: (data['Blocked'] * 100).toFixed(0) + '%',
      };
      if (initial && !!data['Working']) {
        Object.keys(data).map((key) => {
          data[key] = key === 'Working' ? 1 : 0;
        });
      }
    };
    const genTestData = () => {
      const temp = [Math.random(), Math.random(), Math.random()];
      const temp_sum = temp[0] + temp[1] + temp[2];
      data = {
        Working: temp[0] / temp_sum,
        'Blow through': temp[1] / temp_sum,
        Blocked: temp[2] / temp_sum,
      };

      // set the color scale
      color = {
        Working: '#00AA75',
        'Blow through': '#ECB102',
        Blocked: '#E1000F',
      };
      labels = {
        Working: (data['Working'] * 100).toFixed(0) + '%',
        'Blow through': (data['Blow through'] * 100).toFixed(0) + '%',
        Blocked: (data['Blocked'] * 100).toFixed(0) + '%',
      };
    };
    const initSVG = () => {
      // set the dimensions and margins of the graph

      // append the svg object to the div called 'my_dataviz'
      svg = d3
        .select(element)
        .append('svg')
        .attr('id', this.id)
        .attr('width', max_width)
        .attr('height', max_height)
        .append('g')
        .attr(
          'transform',
          'translate(' +
            (width / 2 + margin.left) +
            ',' +
            (height / 2 + margin.top) +
            ')'
        );
      /*.on('click', () => {
          genTestData();
          updatePie();
        });*/

      // Compute the position of each group on the pie:
      pie = d3
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(data));

      // The arc generator
      generator = d3
        .arc()
        .innerRadius(radius * 0.55) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      // Another arc that won't be drawn. Just for labels positioning
      outerArc = d3
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);
      // Another arc that won't be drawn. Just for labels positioning
      outerArc2 = d3
        .arc()
        .innerRadius(radius * 1.15)
        .outerRadius(radius * 1.15);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      arcs = svg
        .selectAll('allSlices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', generator)
        .attr('fill', function (d) {
          return color[d.data.key];
        })
        .attr('stroke', function (d) {
          return color[d.data.key];
        })
        .style('stroke-width', '2px');

      // Add the polylines between chart and labels:
      svg
        .selectAll('allPolylines')
        .data(data_ready)
        .enter()
        .append('polyline')
        .attr('stroke', 'black')
        .style('fill', 'none')
        .attr('stroke-width', 1)
        .attr('points', function (d) {
          const posA = outerArc.centroid(d); // line insertion in the slice
          const posB = outerArc2.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc2.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1.1 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
          return [posA, posB, posC];
        });

      // Add the polylines between chart and labels:
      svg
        .selectAll('allLabels')
        .data(data_ready)
        .enter()
        .append('text')
        .text(function (d) {
          return labels[d.data.key];
        })
        .attr('transform', function (d) {
          const pos = outerArc2.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.2 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 5;
          return 'translate(' + pos + ')';
        })
        .attr('dx', function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          if (midangle < Math.PI) {
            return 0;
          } else {
            return -this.getBBox().width + 'px';
          }
        });
    };

    const drawPie = () => {};
    const drawLabels = () => {};
    // TODO: rename
    const oldArcs = {}; // for pie chart slices
    const oldArcs2 = {}; // for labels
    const oldArcs3 = {}; // for lines
    const oldArcs4 = {}; // for labels part 2

    this.updateChart = () => {
      genData();
      if (!noData) updatePie();
    };

    const updatePie = () => {
      const fn_transition = (d, fn: (_d) => any, oldArcValues) => {
        if (!oldArcValues[d.index]) {
          oldArcValues[d.index] = d;
          // initial build up animation:
          const originalEnd = d.endAngle;
          return (t) => {
            const currentAngle = d3.interpolate(
              pie.startAngle()(),
              pie.endAngle()()
            )(t);
            if (currentAngle < d.startAngle) {
              return '';
            }
            if (d.index > 0) d.endAngle = Math.min(currentAngle, originalEnd);
            else d.endAngle = 6.283185307179586;

            return fn(d);
          };
        } else {
          const originalStart = oldArcValues[d.index].startAngle;
          const originalEnd = oldArcValues[d.index].endAngle;
          const targetStart = d.startAngle;
          const targetEnd = d.endAngle;

          oldArcValues[d.index] = d;
          return (t) => {
            d.startAngle = d3.interpolate(originalStart, targetStart)(t);
            d.endAngle = d3.interpolate(originalEnd, targetEnd)(t);
            return fn(d);
          };
          // transition animation
        }
      };

      const data_ready = pie(d3.entries(data));
      const path = svg.selectAll('path').data(data_ready); // Compute the new angles
      path
        .transition()
        .duration(500)
        .attrTween('d', (d) => fn_transition(d, (_d) => generator(_d), oldArcs))
        .style('stroke-width', (d) =>
          d.startAngle === d.endAngle ? '0px' : '2px'
        ); // redrawing the path with a smooth transition

      // --------
      const _labels = svg.selectAll('text').data(data_ready);

      _labels.text(function (d) {
        return labels[d.data.key];
      });
      _labels
        .transition()
        .duration(500)
        .attrTween('transform', (d) =>
          fn_transition(
            d,
            (_d) => {
              const pos = outerArc2.centroid(_d);
              const midangle =
                _d.startAngle + (_d.endAngle - _d.startAngle) / 2;
              pos[0] = radius * 1.2 * (midangle < Math.PI ? 1 : -1);
              pos[1] += 5;
              return 'translate(' + pos + ')';
            },
            oldArcs2
          )
        )
        .attrTween('dx', function (d) {
          return fn_transition(
            d,
            (_d) => {
              const midangle =
                _d.startAngle + (_d.endAngle - _d.startAngle) / 2;
              if (midangle < Math.PI) {
                return 0;
              } else {
                return -this.getBBox().width + 'px';
              }
            },
            oldArcs4
          );
        })
        .style('opacity', (d) => (d.startAngle === d.endAngle ? 0 : 1));

      const _lines = svg.selectAll('polyline').data(data_ready);
      _lines
        .transition()
        .duration(500)
        .attrTween('points', (d) =>
          fn_transition(
            d,
            (_d) => {
              const posA = outerArc.centroid(_d); // line insertion in the slice
              const posB = outerArc2.centroid(_d); // line break: we use the other arc generator that has been built only for that
              const posC = outerArc2.centroid(_d); // Label position = almost the same as posB
              const midangle =
                _d.startAngle + (_d.endAngle - _d.startAngle) / 2;
              // we need the angle to see if the X position will be at the extreme right or extreme left
              posC[0] = radius * 1.1 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
              return [posA, posB, posC];
            },
            oldArcs3
          )
        )
        .style('opacity', (d) => (d.startAngle === d.endAngle ? 0 : 1));
    };
    drawChart();
    setTimeout(() => this.updateChart(), 0);
  }

  public OnBrush(callback: (x_domain: any) => void) {
    // do nothing
  }

  public OnBrushReset(callback: (x_domain: any) => void) {
    // do nothing
  }

  public ResetBrush() {
    // do nothing
  }
}

function treatAsUTC(date): number {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return +result;
}
function daysBetween(startDate, endDate): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

function hoursBetween(startDate, endDate): number {
  const millisecondsPerHour = 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerHour;
}

function minutesBetween(startDate, endDate): number {
  const millisecondsPerMinute = 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerMinute;
}

function minDate(a: Date, b: Date) {
  return a < b ? a : b;
}

function generateUniqueIdentifier() {
  const _number = Math.random(); // 0.9394456857981651
  _number.toString(36); // '0.xtis06h6'
  return _number.toString(36).substr(2, 9); // 'xtis06h6'
}

function generateCriticalGradient(ranges, y_extent, critical) {
  if (!critical) {
    return [
      {
        offset: '-100%',
        color: 'black',
      },
      {
        offset: '100%',
        color: 'black',
      },
    ];
  }
  let above_crit_offsets = [];
  let below_crit_offsets = [];

  const above_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.max <= red_range.min
      )
  );

  const below_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.min >= red_range.max
      )
  );
  const full_range = y_extent[1] - y_extent[0];
  const offset = full_range * 0.05;

  if (above_crit_range) {
    const gradient_start = above_crit_range.min - offset - y_extent[0];
    const gradient_end = above_crit_range.min + offset - y_extent[0];

    above_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
    ];
  }

  if (below_crit_range) {
    const gradient_start = below_crit_range.max - offset - y_extent[0];
    const gradient_end = below_crit_range.max + offset - y_extent[0];

    below_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
    ];
  }

  return [
    {
      offset: '-100%',
      color: below_crit_range ? '#E1000F' : 'black',
    },
    ...below_crit_offsets,
    ...above_crit_offsets,
    {
      offset: '100%',
      color: above_crit_range ? '#E1000F' : 'black',
    },
  ];
}
