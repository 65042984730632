import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BannerNotificationService } from '../../services/banner-notification.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent implements OnInit {
  backBtn: boolean = false;
  @Input() title: string = '';
  @Input() backTo: string = '';
  public showBannerNotification: boolean = false;

  constructor(
    public router: Router,
    private ui: UiService,
    public i18n: I18nService,
    private bannerNotificationService: BannerNotificationService
  ) {}

  ngOnInit() {
    this.ui.OnBackBtnChang.subscribe((backBtn) =>
      setTimeout(() => (this.backBtn = backBtn))
    );
    this.ui.subscribeToBackText((text: string) => {
      setTimeout(() => {
        this.backTo = text;
      });
    });
    this.bannerNotification();
  }
  back(): void {
    this.ui.goBack();
  }

  toSettings() {
    this.router.navigate(['settings']);
  }

  bannerNotification() {
    this.bannerNotificationService.getBannerNotifications().subscribe((res) => {
      if (res.filter((r) => r.type === 'maintenance').length > 0)
        this.showBannerNotification = true;
    });
  }

  closeBannerNotification(e) {
    this.bannerNotificationService
      .dismissBannerNotification('maintenance')
      .subscribe(
        () => {
          this.showBannerNotification = false;
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
