<div class="signup-wrapper" [class.mobile]="isMobile" [class.tablet]="isTablet">
  <div class="signup-image">
    <img
      *ngIf="isDesktop"
      nz-image
      nzDisablePreview
      nzSrc="assets/images/login_image.png"
      height="100%"
    />
    <img
      *ngIf="!isDesktop"
      nz-image
      nzDisablePreview
      nzSrc="assets/images/login_image.png"
      width="100%"
    />
  </div>
  <div class="signupForm">
    <div class="spinner-container" *ngIf="isLoading">
      <app-spinner [black]="true"></app-spinner>
    </div>
    <div class="innersignup" *ngIf="!isLoading">
      <img
        nz-image
        class="logo"
        nzDisablePreview
        nzSrc="assets/images/LoctitePulseLogo_red.svg"
      />
      <h1>{{ i18n.string("create_account") }}</h1>
      <p>{{ i18n.string("create_account_info") }}</p>

      <form nz-form [formGroup]="signupForm">
        <div class="signup-inner-wrapper">
          <ng-container>
            <div nz-row class="signup-inner-row" [class.mobile]="isMobile" [class.tablet]="isTablet">
              <nz-form-item>
                <nz-form-label>{{ i18n.string("name") }}</nz-form-label>
                <nz-input-group>
                  <input
                    formControlName="name"
                    [maxlength]="100"
                    nz-input
                    placeholder="Name"
                    [(ngModel)]="name"
                    oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                    [ngClass]="{
                      'is-valid': (f.name.dirty || submitted) && !f.name.errors,
                      'is-invalid': (f.name.dirty || submitted) && f.name.errors
                    }"
                  />
                </nz-input-group>
                <div *ngIf="f.name.errors && f.name.touched" class="errorMsg">
                  <div *ngIf="f.name.errors.required">
                    {{ i18n.string("name_validation") }}
                  </div>
                  <div *ngIf="f.name.errors.minlength">
                    {{ i18n.string("length_validation") }}
                  </div>
                </div>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>{{ i18n.string("surname") }}</nz-form-label>
                <nz-input-group>
                  <input
                    formControlName="surname"
                    [maxlength]="100"
                    nz-input
                    placeholder="Surname"
                    [(ngModel)]="surname"
                    oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                    [ngClass]="{
                      'is-valid': (f.surname.dirty || submitted) && !f.surname.errors,
                      'is-invalid': (f.surname.dirty || submitted) && f.surname.errors
                    }"
                  />
                </nz-input-group>
                <div *ngIf="f.surname.errors && f.surname.touched" class="errorMsg">
                  <div *ngIf="f.surname.errors.required">
                    {{ i18n.string("surname_validation") }}
                  </div>
                  <div *ngIf="f.surname.errors.minlength">
                    {{ i18n.string("length_validation") }}
                  </div>
                </div>
              </nz-form-item>
            </div>
            <nz-form-item>
              <nz-form-label>{{ i18n.string("email") }}</nz-form-label>
              <nz-input-group>
                <input
                  #emailInputRef
                  type="email"
                  formControlName="email"
                  [maxlength]="50"
                  nz-input
                  placeholder="Email"
                  [(ngModel)]="email"
                  (focus)="resetEmailAlreadyRegisterdcheck()"
                  oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                  [ngClass]="{
                    'is-valid': (f.email.dirty || submitted) && !f.email.errors,
                    'is-invalid': (f.email.dirty || submitted) && f.email.errors
                  }"
                />
              </nz-input-group>
              <div *ngIf=" (f.email.errors && f.email.touched) || emailAlreadyRegistered" class="errorMsg">
                <span *ngIf="f.email.errors?.required">{{
                  i18n.string("email_validation1")
                }}</span>
                <span *ngIf="f.email.errors?.email">{{
                  i18n.string("email_validation2")
                }}</span>
                <span *ngIf="emailAlreadyRegistered && !f.email.errors">{{
                  i18n.string("email_validation3")
                }}</span>
              </div>
            </nz-form-item>
            <div nz-row [class.mobile]="isMobile" [class.tablet]="isTablet">
              <nz-form-item>
                <nz-form-label
                  >{{ i18n.string("designation")
                  }}<span *ngIf="optional" class="silver-chalice-text">
                    ({{ i18n.string("optional") }})</span
                  ></nz-form-label
                >
                <nz-input-group>
                  <input
                    formControlName="designation"
                    [maxlength]="100"
                    nz-input
                    placeholder="Designation"
                    [(ngModel)]="designation"
                    oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                  />
                </nz-input-group>
              </nz-form-item>
            </div>
            <div nz-row class="signup-form-country" [class.mobile]="isMobile" [class.tablet]="isTablet">
              <nz-form-item class="signup-form-group" [class.mobile]="isMobile" [class.tablet]="isTablet">
                <nz-form-label>{{ i18n.string("country") }}</nz-form-label>
                <nz-select
                  #countrySelect
                  [nzPlaceHolder]="i18n.string('select_country')"
                  [class.selected]="
                    signupForm.controls['country'].value !== null"
                  [nzCustomTemplate]="countryTemplate"
                  formControlName="country"
                  [nzShowArrow]="signupForm.controls['country'].value === null ? true : false"
                >
                <!-- [(ngModel)]="countrySelected" -->
                  <nz-template *ngIf="countryArray.length > 0">
                  <nz-option
                    *ngFor="let value of countryArray"
                    [nzLabel]='getName(value.name)'
                    [nzValue]="value.id"
                  >
                  </nz-option>
                </nz-template>
                </nz-select>
                <ng-template #countryTemplate let-selected>
                  <div class="customSelectBox">
                    <span>{{
                      selected
                        ? selected.nzLabel
                        : i18n.string("select_country")
                    }}</span>
                    <i
                      nz-icon
                      [nzType]="'icons:dropdown-arrow'"
                      class="customSelectIcon"
                    ></i>
                  </div>
                </ng-template>
              </nz-form-item>
            </div>
            <nz-form-item>
              <nz-form-label
                >{{ i18n.string("customerentity")
                }}<span *ngIf="optional" class="silver-chalice-text">
                  ({{ i18n.string("optional") }})</span
                ></nz-form-label
              >
              <nz-input-group>
                <input
                  formControlName="customerentity"
                  [maxlength]="100"
                  nz-input
                  placeholder="Customer Entity"
                  [(ngModel)]="customerentity"
                  oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                />
              </nz-input-group>
            </nz-form-item>

            <span layout="row"
              ><hr style="margin-bottom: 30px; margin-top: 31px" flex
            /></span>

            <nz-form-item>
              <nz-form-label>{{
                i18n.string("choose_password")
              }}</nz-form-label>
              <nz-input-group [nzSuffix]="passwordEye">
                <input
                  #password
                  formControlName="choosepswd"
                  [type]="passwordVisible ? 'text' : 'password'"
                  [(ngModel)]="choosepswd"
                  placeholder="Choose password"
                  oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                  nz-input
                  [ngClass]="{
                    'is-valid':
                      (f.choosepswd.dirty || submitted) && passwordValid,
                    'is-invalid':
                      (f.choosepswd.dirty || submitted) && !passwordValid
                  }"
                  [nzSuffix]="passwordEye"
                />
              </nz-input-group>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label>{{
                i18n.string("repeat_password")
              }}</nz-form-label>
              <nz-input-group [nzSuffix]="passwordEye">
                <input
                  #password
                  formControlName="repeatpswd"
                  nz-input
                  [type]="passwordVisible ? 'text' : 'password'"
                  placeholder="Repeat password"
                  [(ngModel)]="repeatpswd"
                  oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                  [ngClass]="{
                    'is-valid':
                      (submitted || f.repeatpswd.dirty) && !f.repeatpswd.errors,
                    'is-invalid':
                      (submitted || f.repeatpswd.dirty) && f.repeatpswd.errors
                  }"
                  [nzSuffix]="passwordEye"
                />
              </nz-input-group>

              <div
                class="errorMsg"
                *ngIf="(submitted || f.repeatpswd.dirty) && f.repeatpswd.errors"
              >
                <div *ngIf="f.repeatpswd.errors.required">
                  {{ i18n.string("pw_validation6") }}
                </div>
                <div *ngIf="f.repeatpswd.errors.minLength">
                  {{ i18n.string("pw_validation1") }}
                </div>
                <div *ngIf="f.repeatpswd.errors.mismatch">
                  {{ i18n.string("pw_validation5") }}
                </div>
              </div>
            </nz-form-item>

            <div id="validation-rules" *ngIf="requiredValid" class="listHints">
              <div
                [class.greenListHint]="minLengthValid"
                [class.redListHint]="!minLengthValid"
              >
                <i
                  nz-icon
                  [nzType]="
                    minLengthValid
                      ? 'icons:done-full-circle'
                      : 'icons:false-alert'
                  "
                  class="svg-icon"
                >
                </i>
                {{ i18n.string("pw_validation1") }}
              </div>
              <div
                [class.greenListHint]="
                  requiresUppercaseValid && requiresLowercaseValid
                "
                [class.redListHint]="
                  !requiresUppercaseValid || !requiresLowercaseValid
                "
              >
                <i
                  nz-icon
                  [nzType]="
                    requiresUppercaseValid && requiresLowercaseValid
                      ? 'icons:done-full-circle'
                      : 'icons:false-alert'
                  "
                  class="svg-icon"
                >
                </i>
                {{ i18n.string("pw_validation2") }}
              </div>
              <div
                [class.greenListHint]="requiresDigitValid"
                [class.redListHint]="!requiresDigitValid"
              >
                <i
                  nz-icon
                  [nzType]="
                    requiresDigitValid
                      ? 'icons:done-full-circle'
                      : 'icons:false-alert'
                  "
                  class="svg-icon"
                >
                </i>
                {{ i18n.string("pw_validation3") }}
              </div>
              <div
                [class.greenListHint]="requiresSpecialCharsValid"
                [class.redListHint]="!requiresSpecialCharsValid"
              >
                <i
                  nz-icon
                  [nzType]="
                    requiresSpecialCharsValid
                      ? 'icons:done-full-circle'
                      : 'icons:false-alert'
                  "
                  class="svg-icon"
                >
                </i>
                {{ i18n.string("pw_validation4") }}
              </div>
            </div>

            <p class="error" [class.hidden]="!error">
              {{ i18n.string("signup_failed") }}
            </p>
            <div class="checkbox-container" *ngIf="showNotUsPage">
              <button class="black-coral-text checkbox-button" (click)="checkedServiceConditions = !checkedServiceConditions">
                <i nz-icon *ngIf="!checkedServiceConditions" nzType="icons:checkbox-empty" class="svg-icon fillblackcoral" ></i>
                <i nz-icon *ngIf="checkedServiceConditions" nzType="icons:status-check" class="svg-icon fillblackcoral"></i>
                <span class="icon-text">{{i18n.string("agree_to")}}
                  <span class="underline"(click)="toServiceConditions()">{{i18n.string("service_conditions")}}</span>
                  <span class="icon-text">{{i18n.string("the")}}</span>
                  <span class="underline"(click)="toTermsOfUse()">{{i18n.string("app_terms_use")}}</span>
                  <span class="icon-text">{{i18n.string("and_have_read")}}</span>
                  <span class="underline"(click)="toDataProtectionDeclaration()">{{i18n.string("data_protection_decalaration")}}</span>
                </span>
              </button>
              </div>
            <div class="checkbox-container" *ngIf="!showNotUsPage">
            <button class="black-coral-text checkbox-button" (click)="checkedTerms = !checkedTerms">
              <i nz-icon *ngIf="!checkedTerms" nzType="icons:checkbox-empty" class="svg-icon fillblackcoral" ></i>
              <i nz-icon *ngIf="checkedTerms" nzType="icons:status-check" class="svg-icon fillblackcoral"></i>
              <span class="icon-text">{{i18n.string("agree_to_general")}}
                <span class="underline"(click)="toTermsOfUse()">{{i18n.string("terms_and_conditon")}}</span></span>
            </button>

            <button class="black-coral-text checkbox-button" (click)="checkedDataProtection = !checkedDataProtection">
              <i nz-icon *ngIf="!checkedDataProtection" nzType="icons:checkbox-empty" class="svg-icon fillblackcoral" ></i>
              <i nz-icon *ngIf="checkedDataProtection" nzType="icons:status-check" class="svg-icon fillblackcoral"></i>
              <span class="icon-text">{{i18n.string("have_read")}}
                <span class="underline"(click)="toDataProtectionDeclaration()">{{i18n.string("data_protection_decalaration")}}</span>
              </span>
            </button>
          </div>
          
            <nz-form-item>
              <nz-form-control>
                <div class="submitButtonWrapper signupButtonMarginTop">
                  <app-nav-button *ngIf="!showNotUsPage"
                    [disabled]="!signupForm.valid || !checkedTerms || !checkedDataProtection"
                    [primary]="true"
                    (click)="signup()"
                    [svg_icon_right]="'icons:arrow-right'"
                    >{{ i18n.string("submit_form") }}
                  </app-nav-button>
                  <app-nav-button *ngIf="showNotUsPage"
                    [disabled]="!signupForm.valid || !checkedServiceConditions"
                    [primary]="true"
                    (click)="signup()"
                    [svg_icon_right]="'icons:arrow-right'"
                    >{{ i18n.string("submit_form") }}
                  </app-nav-button>
                </div>
              </nz-form-control>
            </nz-form-item>

            <ng-template #passwordEye>
              <i
                nz-icon
                class="passwordEye"
                [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                (click)="passwordVisible = !passwordVisible"
              ></i>
            </ng-template>  
          </ng-container>
        </div>
      </form>

      <div class="submitButtonWrapper signupButtonSlim installationAgentBtn">
        <app-nav-button
          [darksecondary]="true"
          (click)="goToLoginPage()"
          svg_icon="icons:login"
        >
          {{ i18n.string("already_account") }}
        </app-nav-button>
      </div>
    </div>
  </div>
</div>
