<div [class.inactive]="inactive && !inMovement" [class.disabled]="disabled" class="tab-bar-active-marker-container"
  [class.opaque]="opaque" [@moveMarker]="moveMarker" *ngIf="isActive">
  <div class="tab-bar-active-marker"></div>
</div>
<button class="tab-bar-item body-bold" [class.opaque]="opaque" [class.disabled]="disabled" [class.active]="preIsActive"
  [class.icon]="icon" (click)="onClick()">
  <i *ngIf="fa_icon" [class]="'fa fa-' + fa_icon" aria-hidden="true"></i>
  <div *ngIf="svg_icon" class="svg-icon mini-icon" [class.chrome]="IsChrome" [ngStyle]="{
        mask: 'url(\'' + svg_icon + '\')',
        '-webkit-mask': 'url(\'' + svg_icon + '\')',
        '-webkit-mask-size': 'cover',
        'mask-size': 'cover'
      }"></div>
  <span>
    <ng-content></ng-content>
  </span>

</button>