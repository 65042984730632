import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { compareDesc } from 'date-fns';
import { formatDistanceToNow } from 'date-fns/esm';
import { de, enUS } from 'date-fns/esm/locale';
import { forkJoin, Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  AdditionalInfo,
  AdditionalInfoType,
  GroupedAdditionalInfo,
} from '../../model/additionalinfo';
import { AssetDetail, RotaingEquipmentStatus } from '../../model/asset';
import { HistoryItem } from '../../model/history';
import { Location } from '../../model/location';
import { getDefaultImage, Notification } from '../../model/object';
import { CurrentTelemetry, Sensor } from '../../model/sensor';
import { UserSettings } from '../../model/settings';
import { GraphData, TelemetryHistory } from '../../model/telemetry_history';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { SettingsService } from '../../services/settings.service';
import { UiService } from '../../services/ui.service';

class SensorMarker {
  public text: string;
  public warning: boolean;
  public critical: boolean;
  public inactive: boolean;
  public nodata: boolean;
  public sensorID: number;
  public x: number;
  public y: number;
  public hidden: boolean;
  public selected: boolean;
}

export class GraphDisplay {
  public graphData: GraphData[];
  public history: TelemetryHistory;
  public loading: boolean;
  public error: boolean;
  public timeframe: {
    start: Date;
    end: Date;
    custom: boolean;
  };
  public loadedTimeframe: {
    start: Date;
    end: Date;
    custom: boolean;
  };
  public graphBrushActive: boolean = false;

  get Range(): Date[] {
    return this.timeframe
      ? [this.timeframe.start, this.timeframe.end]
      : [new Date(), new Date()];
  }

  set Range(range: Date[]) {
    if (
      range[0].toISOString() !== this.timeframe.start.toISOString() ||
      range[1].toISOString() !== this.timeframe.end.toISOString()
    ) {
      this.timeframe = {
        start: range[0],
        end: range[1],
        custom: true,
      };
    }
  }

  public currentTelemetry: CurrentTelemetry;

  public setTimeframe(
    timeframe: { start: Date; end: Date; custom: boolean },
    component: AssetOverviewComponent
  ) {
    if (
      !timeframe ||
      !timeframe.start ||
      !timeframe.end ||
      !isValidDate(timeframe.start) ||
      !isValidDate(timeframe.end)
    )
      timeframe = { start: new Date(), end: new Date(), custom: false };

    this.timeframe = timeframe;
    let reloadData = false;
    if (!!this.graphData && !!this.graphData.find((data) => data.error))
      reloadData = true;
    if (!this.loadedTimeframe) {
      this.loadedTimeframe = timeframe;
      reloadData = true;
    }
    if (timeframe.start < this.loadedTimeframe.start) {
      this.loadedTimeframe.start = timeframe.start;
      reloadData = true;
    }
    if (timeframe.end > this.loadedTimeframe.end) {
      this.loadedTimeframe.end = timeframe.end;
      reloadData = true;
    }
    if (reloadData) component.reloadTelemetry(this);
  }
}

@Component({
  selector: 'app-asset-overview',
  templateUrl: './asset-overview.component.html',
  styleUrls: ['./asset-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetOverviewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public asset: AssetDetail = null;
  public parentName: string = '';
  public sensorMarkers: SensorMarker[] = [];
  public resizeTrigger = null; // used for recalculating marker positions on window resize
  public loading;
  public error;
  public thumbnailLoading = null;
  public thumbnailError = false;
  public breadcrumbsLoading;
  public addInfo: boolean[] = [false, false, false];
  public showAllEntries: boolean = false;
  public selectedSensor: number = null;
  public overlayImage = null;
  public graphDisplays: GraphDisplay[] = [];
  public historyItems: HistoryItem[] = [];
  public settings: UserSettings = null;
  public rangePickerValue: Date[] = [new Date(), new Date()];
  public subscription: Subscription = null;
  public subscription2: Subscription = null;
  public npToken = null;
  public npResp = null;
  public rotatingAssetDetails: RotaingEquipmentStatus[] = [];
  private lastTimeframeSelectIndex: number = 0;  

  @ViewChildren('datePick') datePickers: QueryList<any>;

  public additionalInfoLoading;
  public additionalInfoError;
  addInfoValueTypes: AdditionalInfoType[] = [];
  addInfoDefaultFields: AdditionalInfo[] = [];
  groupedInfoValues: GroupedAdditionalInfo[] = [];
  sensorPosition: { lat: number; lng: number } = null;

  get HistoryItems(): HistoryItem[] {
    if (!this.historyItems) return [];
    return this.historyItems.filter(
      (item) =>
        !this.selectedSensor ||
        this.selectedSensor === item.sensorID ||
        !item.sensorID
    );
  }
  get SensorTiles(): {
    title: string;
    icon: string;
    telemetry: CurrentTelemetry[];
  }[] {
    if (!this.asset) return [];
    const co = this.CriticalOverride;
    return [
      this.asset.SensorTileTelemetry.map((_telemetry) => {
        _telemetry.telemetry = _telemetry.telemetry.map((telemetry) => {
          if (!!co && co[telemetry.measurementTypeID])
            telemetry.critical = true;
          return telemetry;
        });
        return _telemetry;
      })[0],
    ];
  }

  get SensorDetailedTelemetryJSON(): string {
    try {
      return JSON.stringify(this.SensorDetailedTelemetry);
    } catch (e) {
      return e;
    }
  }

  get SensorDetailedTelemetry(): CurrentTelemetry[] {
    if (!this.asset) return null;
    const co = this.CriticalOverride;
    return this.asset.getSensorDetailTelemetry(this.Sensor.sensorID)
      ? this.asset
          .getSensorDetailTelemetry(this.Sensor.sensorID)
          .map((_telemetry) => {
            _telemetry.telemetry = _telemetry.telemetry.map((telemetry) => {
              if (!!co && co[telemetry.measurementTypeID])
                telemetry.critical = true;
              return telemetry;
            });
            return _telemetry;
          })
          .map((item) => item.telemetry)
          .flat(2)
      : null;
  }

  private range: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 1)),
    new Date(),
  ];
  get Range(): Date[] {
    return this.range;
  }

  get DateFormat(): string {
    return this.settings.DateFormat.formatString;
  }
  get Locale(): string {
    return this.settings.Language.abbreviation === 'de' ? 'de-DE' : 'en-US';
  }

  set Range(range: Date[]) {
    this.range = range;
  }

  get WarningOverride() {
    if (!this.asset || !this.Sensor) return {};
    const output = {};
    this.asset.notifications.map((noti: Notification) => {
      if (
        !this.asset.critical &&
        noti.sensorID === this.Sensor.sensorID &&
        noti.measurementTypeID !== 1078 &&
        noti.measurementTypeID !== 1079 &&
        noti.measurementTypeID !== 1080
      )
        // inactive notifications don't count
        output[noti.measurementTypeID] = noti.isWarning;
    });
    return output;
  }

  get InactiveOverride() {
    if (!this.asset || !this.Sensor) return {};
    const output = {};
    this.asset.notifications.map((noti: Notification) => {
      if (
        !this.asset.inactive &&
        noti.sensorID === this.Sensor.sensorID &&
        (noti.measurementTypeID === 1078 ||
          noti.measurementTypeID === 1079 ||
          noti.measurementTypeID === 1080)
      )
        // only inactive notifications count
        output[noti.measurementTypeID] = noti.inactive;
    });
    return output;
  }

  get CriticalOverride() {
    if (!this.asset || !this.Sensor) return {};
    const output = {};
    this.asset.notifications.map((noti: Notification) => {
      if (
        noti.sensorID === this.Sensor.sensorID &&
        noti.measurementTypeID !== 1078 &&
        noti.measurementTypeID !== 1079 &&
        noti.measurementTypeID !== 1080
      )
        // inactive notifications don't count
        output[noti.measurementTypeID] = noti.isCritical;
    });
    return output;
  }

  get Sensor(): Sensor {
    if (!this.asset || !this.selectedSensor) return null;
    return this.asset.sensors.find(
      (sensor) => sensor.sensorID === this.selectedSensor
    );
  }

  get Breadcrumbs(): Location[] {
    if (!this.asset) return [];
    if (!this.Sensor) return this.asset.breadcrumbs;
    return [...this.asset.breadcrumbs, this.asset];
  }

  get Temperature(): {
    title: string;
    icon: string;
    telemetry: CurrentTelemetry[];
  }[] {
    if (!this.asset || !this.asset.temperature) return [];
    return [
      {
        title: this.asset.temperature.title,
        icon: 'temperature',
        telemetry: [this.asset.temperature],
      },
    ];
  }

  get SensorHealth(): CurrentTelemetry[] {
    if (!this.asset) return [];
    if (!this.asset.health || this.asset.health.length === 0) return [];
    return this.asset.health.filter(
      (telemetry) =>
        telemetry.title.toLowerCase().includes('battery') ||
        telemetry.title.toLowerCase().includes('signal') ||
        telemetry.title.toLowerCase().includes('tilt')
    );
  }

  get sensorGeoPosition() {
    const position = this.Sensor.geoPosition
      ? this.Sensor.geoPosition.split(';', 2)
      : '';
    return {
      lat: Number(position[0]),
      lng: Number(position[1]),
    };
  }

  get IsSteamTrap(): boolean {
    return this.asset.steamTrap.length > 0;
  }

  get IsPipe(): boolean {
    return this.asset.equipmentType === 'Pipe';
  }

  get isRotating(): boolean {
    return !!this.asset ? this.asset.equipmentType === 'Rotating Equipment' : false;
  }

  get HasSteamTrapAnalytics(): boolean {
    return (
      !!this.graphDisplays.find(
        (item) => item.currentTelemetry.page === 'steam_trap'
      ) &&
      !!this.asset.steamTrap.find(
        (telemetry) => telemetry.measurementTypeID === 1068
      )
    );
  }

  get SteamTrapOutlet(): CurrentTelemetry {
    return this.asset.steamTrap.find(
      (telemetry) => telemetry.measurementTypeID === 1074
    );
  }

  get SteamTrapAnalyticsLoading(): boolean {
    return !!this.graphDisplays.find(
      (item) => item.currentTelemetry.page === 'steam_trap' && item.loading
    );
  }

  get SteamTrapGraphData(): GraphData[] {
    return this.graphDisplays
      .filter((item) => item.currentTelemetry.page === 'steam_trap')
      .map((display: GraphDisplay) => display.graphData)
      .flat(2);
  }

  get SteamTrapGraphDisplays(): GraphDisplay[] {
    return this.graphDisplays.filter(
      (item) => item.currentTelemetry.page === 'steam_trap'
    );
  }

  get SteamTrapTelemetry(): CurrentTelemetry[] {
    return this.graphDisplays
      .filter((item) => item.currentTelemetry.page === 'steam_trap')
      .map((display: GraphDisplay) => display.currentTelemetry);
  }

  get SteamLossCurrencyUnit() {
    if (environment.disableSelectedSteamRateCurrency) return '€';
    return this.asset.additionalInfo.find((item) =>
      item.category.includes('currency_steam_rate_currency')
    );
  }

  get SteamTrapNoPricePerTon(): boolean {
    const pricePerTon = this.asset.additionalInfo.find((item) =>
      item.category.includes('Price per ton')
    );
    const pricePerTonValue = !pricePerTon ? null : pricePerTon.value;
    return this.HasSteamTrapAnalytics && !pricePerTonValue;
  }

  get IsTelemetryTimeframeCustom(): boolean {
    const temp = this.SteamTrapTelemetry[0];
    return !!temp ? this.isTelemetryTimeframeCustom(temp) : false;
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get GroupedAdditionalInfo(): {
    header: string;
    items: {
      text: string;
      value: number;
      valueType: number;
      category: string;
      icon: string;
    }[];
  }[] {
    const groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    if (!this.asset) return [];
    const grouped = groupBy(this.asset.additionalInfo, 'category');
    return Object.keys(grouped).map((key) => {
      return {
        header: key,
        items: grouped[key],
      };
    });
  }

  get Notifications(): Notification[] {
    return !!this.asset
      ? this.asset.notifications
          .filter(
            (notification) =>
              notification.sensorID === this.selectedSensor ||
              (!notification.sensorID && !this.selectedSensor)
          )
          .sort((a, b) =>
            a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0
          )
      : [];
  }

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    public router: Router,
    public changeRef: ChangeDetectorRef,
    public ui: UiService,
    public i18n: I18nService,
    public settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((route) => this.onParamMapChange(route));
    if (this.isRotating) {
      this.rotatingEqAssetDetails();
    }

    this.ui.onNavBack(() => {
      if (!this.asset) this.router.navigate(['home'], { replaceUrl: true });
      else if (this.selectedSensor) this.onSensorDeselect();
      else {
        const parentID = this.asset.parentID;
        let parent: Location = this.assetsService.findLocation(parentID);
        while (!!parent && parent.curLayer !== 'c') {
          parent = this.assetsService.findLocation(parent.parentID);
        }
        if (!!parent) {
          if (parent.isAsset) this.router.navigate(['asset', parentID]);
          else
            this.router.navigate(['plant', parent.objectID], {
              replaceUrl: true,
            });
        } else this.router.navigate(['home'], { replaceUrl: true });
      }
    });    
  }

  ngOnDestroy() {
    if (!!this.subscription) this.subscription.unsubscribe();
    if (!!this.subscription2) this.subscription2.unsubscribe();
  }

  onParamMapChange(result: any) {
    if (
      !result.params['sensorid'] ||
      result.params['sensorid'] === -1 ||
      result.params['sensorid'] === '-1'
    ) {
      this.selectedSensor = -1;
    }
    if (!this.asset || this.asset.objectID !== +result.params['id'])
      this.refresh(+result.params['id']);

    // if (+result.params['sensorid'] < 0) result.params['sensorid'] = undefined;
    if (!!result.params['sensorid'] && +result.params['sensorid'] > 0) {
      this.selectedSensor = +result.params['sensorid'];
      this.ui.setBackText(
        this.i18n.parse(!!this.Sensor ? this.asset.objectName : this.parentName)
      );
      setTimeout(() => {
        this.sensorMarkers = this.sensorMarkers.map((marker) => {
          return {
            ...marker,
            selected: marker.sensorID === this.selectedSensor,
            hidden: marker.sensorID !== this.selectedSensor,
          };
        });
        this.loadTelemetry();
        this.changeRef.detectChanges();
      });
      this.changeRef.detectChanges();
    } else {
      this.ui.setBackText(this.i18n.parse(this.parentName));
      this.selectedSensor = null;
      setTimeout(() => {
        this.sensorMarkers = this.sensorMarkers.map((marker) => {
          return {
            ...marker,
            selected: false,
            hidden: false,
          };
        });
        this.changeRef.detectChanges();
      });
      this.changeRef.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.ui.OnOrientationChange.subscribe(() => this.changeRef.detectChanges());
    if(!this.isRotating) return;
    if (this.isRotating) {
      this.rotatingEqAssetDetails();
    }
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  refresh(assetID: number, disableCache = false) {
    this.loading = true;
    this.error = false;
    this.changeRef.detectChanges();
    if (!!this.subscription) this.subscription.unsubscribe();
    this.subscription = this.settingsService
      .getUserSettings()
      .subscribe((settings: UserSettings) => {
        this.settings = settings;
        if (!!this.subscription2) this.subscription2.unsubscribe();
        this.subscription2 = this.assetsService.subscribeToAsset(
          assetID,
          disableCache,
          settings.MeasurementSystem,
          (result: {
            asset: AssetDetail;
            plant: Location;
            thumbnailLoading: boolean;
            breadcrumbsLoading: boolean;
          }) => {
            const loadTelemetry =
              !!disableCache || !this.asset || this.asset.objectID !== assetID; // stop unnecessary telemetry reloads
            if (result.asset.objectID !== assetID) return; // subscription triggered for wrong object
            this.loading = false;
            this.breadcrumbsLoading = result.breadcrumbsLoading;
            this.asset = result.asset;
            const parent = this.getParentForBackNav(this.asset.parentID);
            this.parentName = !!parent
              ? parent.objectName
              : this.i18n.string('home');

            this.ui.setBackText(
              this.Sensor
                ? this.i18n.parse(this.asset.objectName)
                : this.i18n.parse(this.parentName)
            );
            /*this.parentName = this.asset.breadcrumbs[
                this.asset.breadcrumbs.length - 1
              ]
                ? this.asset.breadcrumbs[this.asset.breadcrumbs.length - 1]
                    .objectName
                : '';*/
            // use default image if none is set        
            if (!this.asset.picture) {
              if(this.isRotating) {
                this.asset.picture = getDefaultImage(
                  this.asset.equipmentType, this.asset.defaultImageId);
                 }
               else {
                 this.asset.picture = getDefaultImage(this.asset.equipmentType);
               }
            }

            if (this.thumbnailLoading === null) {
              // check thumbnailLoading with null to avoid setting thumbnailLoading to true twice (happens on initial page load)
              this.thumbnailLoading = true;
              this.thumbnailError = false;
              this.sensorMarkers = [];
            }
            if (this.isRotating) {
              this.rotatingEqAssetDetails();
            }
            if (loadTelemetry) this.loadTelemetry();
            this.changeRef.detectChanges();
            this.loadAdditionalInfo();
          },
          (error) => {
            this.loading = false;
            this.error = true;
            this.changeRef.detectChanges();
          }
        );
      });

    this.assetsService
      .getObjectHistory(
        assetID,
        !!this.selectedSensor ? this.selectedSensor : -1
      )
      .subscribe((result: HistoryItem[]) => {
        this.historyItems = result;
      });
  }

  rotatingEqAssetDetails() {
    if (!this.asset) return;
    if (this.asset) {
      this.assetsService.getNanoPreciseAssetsToken().subscribe((result) => {
        this.npToken = result;
        if(this.npToken != null) {
          this.npResp = this.assetsService.getNanoPreciseAssetsHealthStatus(this.npToken.token, this.asset.objectID).subscribe(
            (result) => {              
              this.rotatingAssetDetails = result;
              this.rotatingAssetDetails[0].lifePercentage = Math.trunc(this.rotatingAssetDetails[0].lifePercentage);
            });
            this.changeRef.detectChanges();
        }        
      });
      this.changeRef.detectChanges();
    }
  }

  loadTelemetry() {
    if (!this.asset) return;
    const telemetryList = [];
    if (!!this.Sensor)
      this.Sensor.telemetry.map((_telemetry: CurrentTelemetry) =>
        telemetryList.push(_telemetry)
      );
    if (!!this.asset.steamTrap && !this.Sensor)
      this.asset.steamTrap.map((_telemetry) => telemetryList.push(_telemetry));

    this.graphDisplays = telemetryList
      .filter(
        (telemetry: CurrentTelemetry) => !!this.Sensor || telemetry.preload
      )
      .map((telemetry: CurrentTelemetry, index: number) => {
        const end =
          !!telemetry &&
          !!telemetry.Timestamp &&
          isValidDate(telemetry.Timestamp)
            ? telemetry.Timestamp
            : new Date();
        const defaultTimeframe = {
          start: new Date(new Date(end).setDate(end.getDate() - 7)),
          end: end,
        };
        const defaultLoadedTimeframe = {
          start: new Date(new Date(end).setMonth(end.getMonth() - 6)),
          end: end,
        };
        this.Range = [defaultTimeframe.start, defaultTimeframe.end];
        return Object.assign(new GraphDisplay(), {
          graphData: null,
          history: null,
          loading: true,
          timeframe: defaultTimeframe,
          loadedTimeframe: defaultLoadedTimeframe,
          currentTelemetry: telemetry,
        });
      });

    try {
      this.changeRef.detectChanges();
    } catch (e) {
      console.log(e);
    }

    this.graphDisplays.map((graphTelemetry) => {
      const loadedTimeframe = this.getLoadedTimeframe(
        graphTelemetry.currentTelemetry
      );
      graphTelemetry.loading = true;
      if (!!graphTelemetry.graphData)
        graphTelemetry.graphData.map((data) => (data.loading = true));

      this.changeRef.detectChanges();
      this.assetsService
        .getTelemetry(
          this.asset.objectID,
          graphTelemetry.currentTelemetry.sensor.sensorID,
          graphTelemetry.currentTelemetry.measurementTypeID,
          this.settings.MeasurementSystem,
          loadedTimeframe
        )
        .subscribe(
          (result: TelemetryHistory) => {
            if (result.telemetry.length > 0) {
              const firstMeasurement = new Date(result.telemetry[0].timestamp);
              const lastMeasurement = new Date(
                result.telemetry[result.telemetry.length - 1].timestamp
              );
              let end: Date = lastMeasurement;
              let start = new Date(new Date(end).setDate(end.getDate() - 7));
              if (start < firstMeasurement) {
                start = firstMeasurement;
                end = new Date(new Date(start).setDate(start.getDate() + 7));
              }
              graphTelemetry.timeframe = {
                start: start,
                end: end,
                custom: false,
              };
              graphTelemetry.loadedTimeframe = loadedTimeframe;
            }
            graphTelemetry.history = result;
            graphTelemetry.history.metadata =
              graphTelemetry.history.metadata.map((metadata) => {
                const telemetry = this.Sensor?.telemetry.find(
                  (item) =>
                    item.measurementTypeID === metadata.measurementTypeID
                );
                if (!!telemetry) {
                  metadata.critical = telemetry.critical;
                  metadata.warning = telemetry.warning;
                  metadata.inactive = telemetry.inactive;
                }
                return metadata;
              });

            graphTelemetry.graphData = graphTelemetry.history.GetGraphData();
            graphTelemetry.loading = false;

            // if this is a steam trap, we do not want the steam trap temperature data to dictate the
            // displayed analytics data range, should these differ
            // ( I'm sorry for this horrible hack :[ )
            if (
              !this.IsSteamTrap &&
              this.HasSteamTrapAnalytics &&
              graphTelemetry.currentTelemetry.measurementTypeID !== 1073 &&
              graphTelemetry.currentTelemetry.measurementTypeID !== 1074
            ) {
              this.onTelemetryTimeframeSelect(
                0,
                graphTelemetry.currentTelemetry
              );
            }
            this.changeRef.detectChanges();
          },
          (error) => {
            console.error(error);
            graphTelemetry.loading = false;
            graphTelemetry.error = true;
            this.changeRef.detectChanges();
          }
        );
    });
  }
  reloadTelemetry(graphTelemetry: GraphDisplay) {
    graphTelemetry.graphData.map((data) => (data.loading = true));
    this.changeRef.detectChanges();
    this.assetsService
      .getTelemetry(
        this.asset.objectID,
        graphTelemetry.currentTelemetry.sensor.sensorID,
        graphTelemetry.currentTelemetry.measurementTypeID,
        this.settings.MeasurementSystem,
        graphTelemetry.loadedTimeframe
      )
      .subscribe(
        (result: TelemetryHistory) => {
          graphTelemetry.loading = false;
          if (result.telemetry.length > 0) {
            const end: Date = new Date(
              result.telemetry[result.telemetry.length - 1].timestamp
            );
          }
          graphTelemetry.history = result;
          graphTelemetry.history.metadata = graphTelemetry.history.metadata.map(
            (metadata) => {
              const telemetry = this.Sensor?.telemetry.find(
                (item) => item.measurementTypeID === metadata.measurementTypeID
              );
              if (!!telemetry) {
                metadata.critical = telemetry.critical;
                metadata.warning = telemetry.warning;
              }
              return metadata;
            }
          );

          graphTelemetry.graphData = graphTelemetry.history.GetGraphData();

          graphTelemetry.graphData.map((data) => (data.loading = false));
          graphTelemetry.graphData.map((data) => (data.error = false));
          this.changeRef.detectChanges();
        },
        (error) => {
          graphTelemetry.graphData.map((data) => (data.loading = false));
          graphTelemetry.graphData.map((data) => (data.error = true));
          this.changeRef.detectChanges();
        }
      );
  }

  groupBy<T>(arr: T[], key): T[] {
    return Object.values(
      arr.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {})
    );
  }

  lastChecked() {
    if (this.SensorHealth) {
      const filteredTelemetry = this.SensorHealth.sort((a, b) =>
        !a.Timestamp || !b.Timestamp ? 0 : compareDesc(a.Timestamp, b.Timestamp)
      );

      // TODO: decide which timestamp is used in this case

      return filteredTelemetry[0] &&
        !!filteredTelemetry[0].Timestamp &&
        isValidDate(filteredTelemetry[0].Timestamp)
        ? formatDistanceToNow(filteredTelemetry[0].Timestamp, {
            locale: this.i18n.Lng === 'de' ? de : enUS,
          })
        : null;
    }
  }

  isSensorHealthCrit() {
    if (this.SensorHealth) {
      return this.SensorHealth.some(function (sensor) {
        return sensor.critical;
      });
    }
  }

  isSensorHealthWarning() {
    if (this.SensorHealth) {
      return this.SensorHealth.some(function (sensor) {
        return sensor.warning;
      });
    }
  }

  noData(data: GraphData) {
    return !data || !data.telemetry.length;
  }
  noDataTelemetry(telemetry: CurrentTelemetry) {
    return !this.getVisibleGraphs(telemetry).find(
      (data: GraphData) => !this.noData(data)
    );
  }

  isGraphLoading(telemetry: CurrentTelemetry) {
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !display || display.loading;
  }

  getVisibleGraphs(telemetry: CurrentTelemetry) {
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !!display && display.graphData
      ? display.graphData.filter((graphData) => graphData.metadata.displayGraph)
      : [];
  }

  getTimeframe(telemetry: CurrentTelemetry) {
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !!display ? display.timeframe : null;
  }

  resetTimeframeSelect(telemetry: CurrentTelemetry) {
    this.onTelemetryTimeframeSelect(this.lastTimeframeSelectIndex, telemetry);
  }

  getLoadedTimeframe(telemetry: CurrentTelemetry) {
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !!display ? display.loadedTimeframe : this.getTimeframe(telemetry);
  }

  toSensorHealth() {
    this.router.navigate(['sensor-health', this.asset.objectID]);
  }

  toSensorHistory(sensorID) {
    if (!this.HistoryItems || this.HistoryItems.length === 0) return;
    if (sensorID) {
      this.router.navigate([
        'sensor-history',
        this.asset.objectID,
        sensorID,
        -1,
      ]);
    } else {
      this.router.navigate(['sensor-history', this.asset.objectID, -1, -1]);
    }
  }

  toSensorValue(sensorID, measurementTypeID) {
    if (sensorID && measurementTypeID) {
      this.router.navigate([
        'sensor-value',
        this.asset.objectID,
        sensorID,
        measurementTypeID,
      ]);
    }
  }

  cancelRangePick(data) {
    data.openRangePicker = 0;
    if (this.range[0] > this.range[1]) {
      const temp = this.range[0];
      this.range[0] = this.range[1];
      this.range[1] = temp;
    }
    this.datePickers.forEach((item) => item.close());
  }

  dateRangeUpdatedfromGraph(event) {
    if (
      this.range[0].toISOString() !== event[0].toISOString() ||
      this.range[1].toISOString() !== event[1].toISOString()
    ) {
      this.setTimeFrameForAllDisplays(event[0], event[1], event[2]); // event[2] is a boolean value that determines whether the timeframe is custom or not
      this.Range = event;
      this.changeRef.detectChanges();
    }
  }

  openDocument(link: string) {
    window.open(link, '_blank', 'noreferrer');
    // noreferrer needed, otherwise the pdf reader in the new tab will freeze current tab for some reason
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.resizeTrigger) this.resizeTrigger();
  }
  onThumbnailLoadFailed(event) {
    const target = event.target;
    this.sensorMarkers = [];
    this.resizeTrigger = () => {};
    this.thumbnailLoading = false;
    this.thumbnailError = true;
    this.changeRef.detectChanges();
  }
  onThumbnailLoaded(event) {
    const target = event.target;
    this.resizeTrigger = () => {
      const nat_w = target.naturalWidth,
        nat_h = target.naturalHeight,
        client_w = target.clientWidth,
        client_h = target.clientHeight,
        ratio_w = client_w / nat_w,
        ratio_h = client_h / nat_h,
        ratio = ratio_w > ratio_h ? ratio_w : ratio_h,
        offset_w = ratio_w < ratio_h ? -(nat_w * ratio_h - client_w) * 0.5 : 0,
        offset_h = ratio_w > ratio_h ? -(nat_h * ratio_w - client_h) * 0.5 : 0;

      const MARKER_OFFSET = 19;
      const wo = this.WarningOverride;
      const co = this.CriticalOverride;
      this.sensorMarkers = this.asset.sensors
        .map((sensor: Sensor) => {
          if (!sensor.markerX || !sensor.markerY || !sensor.markerText)
            return null;

          let x = sensor.markerX * ratio + offset_w;
          let y = sensor.markerY * ratio + offset_h;

          if (x < MARKER_OFFSET) x = MARKER_OFFSET;
          if (x > client_w - MARKER_OFFSET) x = client_w - MARKER_OFFSET;
          if (y < MARKER_OFFSET) y = MARKER_OFFSET;
          if (y > client_h - MARKER_OFFSET) y = client_h - MARKER_OFFSET;
          const warning_override =
            !!wo &&
            !!sensor.telemetry.find((telemetry) => {
              return !!wo[telemetry.measurementTypeID];
            });
          const critical_override =
            !!co &&
            !!sensor.telemetry.find((telemetry) => {
              return !!co[telemetry.measurementTypeID];
            });
          return {
            text: sensor.markerText,
            warning:
              (sensor.Warning || warning_override) &&
              !(sensor.Critical || critical_override) &&
              !sensor.Inactive,
            inactive: sensor.Inactive && !critical_override,
            critical: sensor.Critical || critical_override,
            nodata: sensor.NoData,
            sensorID: sensor.sensorID,
            x: x,
            y: y,
            hidden: this.selectedSensor
              ? this.selectedSensor !== sensor.sensorID
              : false,
            selected: this.selectedSensor
              ? this.selectedSensor === sensor.sensorID
              : false,
          };
        })
        .filter((item) => !!item);
    };
    this.resizeTrigger();
    this.thumbnailLoading = false;
    this.thumbnailError = false;
    this.changeRef.detectChanges();
  }

  onDocumentationAdded(event) {
    // this.refresh(this.asset.objectID, true);
  }

  onHeaderImageClick() {
    if (this.sensorMarkers && this.sensorMarkers.length > 0)
      this.onSensorDeselect();
  }
  onSensorDeselect() {
    this.router.navigate(['asset', this.asset.objectID, -1]);
  }
  onSensorSelect(sensorID: number) {
    if (!!this.Sensor && this.Sensor.sensorID === sensorID)
      this.onSensorDeselect();
    else this.router.navigate(['asset', this.asset.objectID, sensorID]);
  }

  isTelemetryTimeframeCustom(telemetry: CurrentTelemetry): boolean {
    const sensorID = telemetry.sensor.sensorID;
    const measurementTypeID = telemetry.measurementTypeID;
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID === measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID === sensorID
    );

    return !!display && !!display.timeframe && !!display.timeframe.custom;
  }

  onTimeframeSelect(index: number): void {
    this.SensorDetailedTelemetry.map((telemetry) =>
      this.onTelemetryTimeframeSelect(index, telemetry)
    );
  }

  onTelemetryTimeframeSelect(index: number, telemetry: CurrentTelemetry): void {
    this.lastTimeframeSelectIndex = index;
    const sensorID = telemetry.sensor.sensorID;
    const measurementTypeID = telemetry.measurementTypeID;
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID === measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID === sensorID
    );
    if (!display) return;
    let firstMeasurement = null;
    let lastMeasurement = null;
    if (!display.graphData || !display.graphData.length) return;
    for (let i = 0; i < display.graphData.length; i++) {
      const gdata = display.graphData[i];
      if (!!gdata && gdata.metadata.displayGraph) {
        if (
          !firstMeasurement ||
          gdata.metadata.firstMeasurement < firstMeasurement
        ) {
          firstMeasurement = gdata.metadata.firstMeasurement;
        }
        if (
          !lastMeasurement ||
          gdata.metadata.lastMeasurement > lastMeasurement
        ) {
          lastMeasurement = gdata.metadata.lastMeasurement;
        }
      }
    }
    if (!!firstMeasurement)
      firstMeasurement = new Date(Date.parse(firstMeasurement));
    if (!!lastMeasurement)
      lastMeasurement = new Date(Date.parse(lastMeasurement));
    // const end: Date = telemetry.Timestamp;
    let end: Date = lastMeasurement;

    let start: Date = new Date(new Date(end).setDate(end.getDate() - 7)); // default: 7 days
    switch (index) {
      case 0:
        if (start < firstMeasurement) {
          start = firstMeasurement;
          end = new Date(new Date(start).setDate(start.getDate() + 7));
        }
        this.setTimeFrameForAllDisplays(start, end, false);
        this.Range = [display.timeframe.start, display.timeframe.end];
        break;
      case 1:
        start = new Date(new Date(end).setDate(end.getDate() - 35));
        if (start < firstMeasurement) {
          start = firstMeasurement;
          end = new Date(new Date(start).setDate(start.getDate() + 35));
        }
        this.setTimeFrameForAllDisplays(start, end, false);
        this.Range = [display.timeframe.start, display.timeframe.end];
        break;
      case 2:
        start = new Date(new Date(end).setMonth(end.getMonth() - 6));
        if (start < firstMeasurement) {
          start = firstMeasurement;
          end = new Date(new Date(start).setMonth(start.getMonth() + 6));
        }

        this.setTimeFrameForAllDisplays(start, end, false);
        this.Range = [display.timeframe.start, display.timeframe.end];
        break;
    }
    this.changeRef.detectChanges();
  }

  onRangeStartPick(measurementTypeID, sensorID, event, endPickerID, data) {
    if (data.openRangePicker === 1) data.openRangePicker = 2;
    this.range[0] = event;

    // if range[1] is after lastMeasurment (can and will happen), set it to lastMeasurement so that the date picker is not
    // deep within "disabled date space"
    let lastMeasurement = data.metadata.lastMeasurement;
    if (!!lastMeasurement) {
      lastMeasurement = new Date(Date.parse(lastMeasurement));
      if (this.range[1] > lastMeasurement) {
        this.range[1] = lastMeasurement;
      }
    }

    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        (graphDisplay.currentTelemetry.measurementTypeID ===
          measurementTypeID ||
          !!graphDisplay.graphData.find(
            (item) => item.metadata.measurementTypeID === measurementTypeID
          )) &&
        graphDisplay.currentTelemetry.sensor.sensorID === sensorID
    );
    if (!display) return;
    // const end: Date = telemetry.Timestamp;

    if (
      this.range[0].toISOString() !== display.timeframe.start.toISOString() ||
      this.range[1].toISOString() !== display.timeframe.end.toISOString()
    ) {
      this.setTimeFrameForAllDisplays(this.range[0], this.range[1], true);
    }
    if (data.openRangePicker === 2) {
      const el: any = document.getElementById(endPickerID);
      if (!!el) el.click();
    }
    this.changeRef.detectChanges();
  }
  onRangeEndPick(measurementTypeID, sensorID, event, data) {
    data.openRangePicker = 0;
    if (event < this.range[0]) {
      this.range[1] = this.range[0];
      this.range[0] = event;
    } else {
      this.range[1] = event;
    }
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        (graphDisplay.currentTelemetry.measurementTypeID ===
          measurementTypeID ||
          !!graphDisplay.graphData.find(
            (item) => item.metadata.measurementTypeID === measurementTypeID
          )) &&
        graphDisplay.currentTelemetry.sensor.sensorID === sensorID
    );

    if (!display) return;
    // const end: Date = telemetry.Timestamp;
    if (
      this.range[0].toISOString() !== display.timeframe.start.toISOString() ||
      this.range[1].toISOString() !== display.timeframe.end.toISOString()
    ) {
      this.setTimeFrameForAllDisplays(this.range[0], this.range[1], true);
    }
    this.changeRef.detectChanges();

    // make sure that the datepicker really closes
    setTimeout(() => {
      data.openRangePicker = 0;
      this.changeRef.detectChanges();
    });
  }

  openRangePicker(id, data) {
    data.openRangePicker = id.includes('end') ? 2 : 1;
    const el: any = document.getElementById(id);
    if (!!el) el.click();
  }

  onRangePickerSelect(event, data) {
    this.setTimeFrameForAllDisplays(event[0], event[1], true);
    this.Range = event;
    this.changeRef.detectChanges();
  }

  setTimeFrameForAllDisplays(start: Date, end: Date, custom: boolean) {
    this.graphDisplays.map((display) =>
      this.setDisplayTimeframe(display, start, end, custom)
    );
  }

  setDisplayTimeframe(
    display: GraphDisplay,
    start: Date,
    end: Date,
    custom: boolean
  ) {
    if (display.currentTelemetry.measurementTypeID === 1068) {
      // steam trap data: we want to fetch additional data from 1069 & 1070
      const steamLossAmountDisplay = this.graphDisplays.find(
        (graphDisplay) =>
          graphDisplay.currentTelemetry.measurementTypeID === 1069
      );
      if (!!steamLossAmountDisplay)
        this.setDisplayTimeframe(steamLossAmountDisplay, start, end, custom);
      const steamLossKGDisplay = this.graphDisplays.find(
        (graphDisplay) =>
          graphDisplay.currentTelemetry.measurementTypeID === 1070
      );
      if (!!steamLossKGDisplay)
        this.setDisplayTimeframe(steamLossKGDisplay, start, end, custom);
    }
    display.setTimeframe({ start: start, end: end, custom: custom }, this);
  }

  rangePickerDisabledDate(data, pickerIndex): (current: Date) => boolean {
    if (!data) {
      return (current: Date) => false;
    }
    let otherDate = null; // otherDate removes the ability to create empty timespans (in other words, pick the same day twice)
    if (pickerIndex === 1) otherDate = this.range[1];
    if (pickerIndex === 2) otherDate = this.range[0];
    if (!!otherDate) otherDate = new Date(otherDate.toDateString()); // remove time from datetime object
    let first: Date = new Date(Date.parse(data.metadata.firstMeasurement));
    first = new Date(first.toDateString()); // remove time from datetime object
    let last: Date = new Date(Date.parse(data.metadata.lastMeasurement));
    last = new Date(last.toDateString()); // remove time from datetime object
    const fn = (current: Date) => {
      current = new Date(current.toDateString()); // remove time from datetime object
      return current < first || current > last || +current === +otherDate;
    };
    return fn;
  }

  trackByMarker(index, marker: SensorMarker) {
    return marker ? marker.sensorID : undefined;
  }

  getParentForBackNav(parentID: number): Location {
    let parent: Location = this.assetsService.findLocation(parentID);
    while (
      !!parent &&
      parent.curLayer !== 'a' &&
      parent.curLayer !== 'b' &&
      parent.curLayer !== 'c'
    ) {
      if (
        parent.Assets.length > 25 &&
        !parent.IsAboveEquipment &&
        !parent.NextLayerIsAboveEquipment
      )
        break;
      parent = this.assetsService.findLocation(parent.parentID);
    }
    return parent;
  }

  isBreadcrumbClickable(breadcrumb: Location) {
    return true; // Made all breadcrumbs clickable. This function is now deprecated.
    /*return (
      !!breadcrumb.curLayer &&
      breadcrumb.curLayer !== 'a' &&
      breadcrumb.curLayer !== 'b'
    );*/
  }

  navToBreadcrumb(breadcrumb: Location) {
    if (!this.isBreadcrumbClickable(breadcrumb)) return;
    if (breadcrumb.isAsset)
      this.router.navigate(['asset', breadcrumb.objectID, -1]);
    else if (breadcrumb.layerID === 1 || breadcrumb.layerID === 2)
      // regions or countries
      this.router.navigate(['home', 'list', breadcrumb.objectID]);
    else this.router.navigate(['plant', breadcrumb.objectID]);
  }

  // Additional Info
  loadAdditionalInfo() {
    this.additionalInfoLoading = true;

    forkJoin([
      this.assetsService.getAdditionalInfo(this.asset.objectID),
      this.assetsService.getAdditionalInfoTypes(),
    ]).subscribe(([res, infoTypeResult]) => {
      if (!res || !infoTypeResult) {
        this.additionalInfoError = true;
      } else {
        this.additionalInfoError = false;
        this.addInfoValueTypes = infoTypeResult.infoTypes;
        this.addInfoDefaultFields = infoTypeResult.defaultFields.map((f) => {
          f.dropdownValues = f.dropdownValues
            ? JSON.parse(f.dropdownValues)
            : null;
          f.unit = f.unit ? JSON.parse(f.unit) : null;
          f.conversionFactor = f.conversionFactor
            ? JSON.parse(f.conversionFactor)
            : null;
          return f;
        });
        this.groupAdditionalInformation(res);
      }
      this.additionalInfoLoading = false;
      this.changeRef.detectChanges();
    });
  }

  groupAdditionalInformation(loadedValues: AdditionalInfo[]) {
    const availableValues = loadedValues.map((v) => new AdditionalInfo(v));
    const availableTypes = loadedValues
      .map((t) => t.infoTypeID)
      .filter((t, i, a) => a.indexOf(t) === i);

    this.groupedInfoValues = [];
    availableTypes.forEach((typeID) => {
      this.groupedInfoValues.push({
        infoType: this.addInfoValueTypes.find((t) => t.id === typeID),
        infoValues: availableValues.filter((v) => v.infoTypeID === typeID),
        displayInfoValues: availableValues.filter(
          (v) => v.infoTypeID === typeID && (v.text || v.value)
        ),
      });
    });
  }
  getProvidedAdditionalInformationLength() {
    // returns number of Additional Information Types where Information has been provided
    return this.groupedInfoValues.filter((value) => {
      return value.displayInfoValues.length > 0 ? true : false;
    }).length;
  }

  showHighTempText() {
    return this.asset.additionalInfo.find(
      (addInfoValue) => addInfoValue.text === 'High (up to 500°C)'
    );
  }
}

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(+d);
}
