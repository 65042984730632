<!-- DEPRECATED -->

<div id="overlay" [class.desktop]="isDesktop">
  <div
    id="blocker"
    [ngClass]="{ 'opaque-blocker': opaque, 'transparent-blocker': !opaque }"
    (click)="close.emit()"
  ></div>
  <div id="popup">
    <ng-content></ng-content>
  </div>
</div>

<!-- DEPRECATED -->
