import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-overlay-card',
  templateUrl: './overlay-card.component.html',
  styleUrls: ['./overlay-card.component.scss'],
})
export class OverlayCardComponent implements OnInit {
  @Input() header = '';
  @Input() closeBtn: boolean = false;
  @Input() fullsize: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() nav: EventEmitter<void> = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}
  onNav() {
    this.nav.emit();
  }
}
