<div class="splash-screen" *ngIf="maintenance">
    <div [class.desktop]="isDesktop" *ngIf="isDesktop">
    <div class="error-image-container">
      <img src="assets/images/banner-maintenance.png">
    </div>
    <div class="desktop-maintenance">
      <p class="heading-maintenance">{{ i18n.string("banner_heading_maintenance") }}</p>
      <p class="text-maintenance">{{ i18n.string("banner_maintenance_text1") }}</p>
      <p class="text-maintenance">{{ i18n.string("banner_maintenance_text2") }}</p>
    </div>
  </div>
  <div [class.mobile]="isMobile"
  [class.tablet]="isTablet" *ngIf="isMobile || isTablet">
    <div class="error-image-container">
      <img src="assets/images/banner-maintenance.png">
    </div>
    <div class="mobile-maintenance">
      <p class="heading-maintenance">{{ i18n.string("banner_heading_maintenance") }}</p>
      <p class="text-maintenance">{{ i18n.string("banner_maintenance_text1") }}</p>
      <p class="text-maintenance">{{ i18n.string("banner_maintenance_text2") }}</p>
    </div>
  </div>
  </div>
  