import { Subject } from 'rxjs';

export const toObservableObject = (targetObject) => {
  const observation$ = new Subject();
  return new Proxy(targetObject, {
    set: (target, name, value) => {
      const oldValue = target[name];
      const newValue = value;
      target[name] = value;
      observation$.next({ name, oldValue, newValue });
      return true;
    },

    get: (target, name) =>
      name === 'observation$' ? observation$ : target[name],
  });
};
