export class Dictionary<T> {
  public constructor(obj?: any) {
    if (obj && obj['dict']) obj = obj['dict'];
    if (obj && obj instanceof Object) {
      const keys = Object.keys(obj);
      for (const key of keys) {
        this.set(key, obj[key]);
      }
    }
  }

  private dict: { [key: string]: T } = {};

  get(key: string): T {
    return this.dict[key];
  }

  set(key: string, value: T) {
    this.dict[key] = value;
  }

  contains(key: string) {
    return (typeof this.dict[key] !== 'undefined') && (this.dict[key] !== null);
  }

  remove(key: string) {
    delete this.dict[key];
  }

  get keys(): string[] {
    return Object.keys(this.dict);
  }

  get count(): number {
    return this.keys.length;
  }

  find(fn: (T) => boolean): T {
    for (const key of Object.keys(this.dict)) {
      const item = this.dict[key];
      if (fn(item)) return item;
    }
  }
}
