import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-us-privacy-rights',
  templateUrl: './us-privacy-rights.component.html',
  styleUrls: ['./us-privacy-rights.component.scss']
})
export class UsPrivacyRightsComponent implements OnInit {

  constructor( public i18n: I18nService) { }

  ngOnInit(): void {
  }

}
