import { ElementRef } from '@angular/core';
import * as d3 from 'd3';

import { UserSettings } from '../../model/settings';
import { I18nService } from '../../services/i18n.service';
import { ValueRange, ValueRangeType } from './value-range';
import { Visualization } from './visualization.interface';

const LOCALE_EN = {
  dateTime: '%x, %X',
  date: '%-m/%-d/%Y',
  time: '%-I:%M:%S %p',
  periods: ['AM', 'PM'],
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

const LOCALE_DE = {
  dateTime: '%A, der %e. %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
};

export class MultiLineChart implements Visualization {
  public x_domain = null; // this defines the section of the historical data we want to see
  private y_domain = null; // this defines the section of the values we are interested in
  private valueRanges: ValueRange[];
  private y_unit: string = '';
  private booleanDomain: boolean = false;
  private customRange: boolean = false;
  // TODO hardcoded for dev purposes

  private data: any[];
  private split_data: object = {};
  public updateChart;
  private resetBrush;
  private brushCallback;
  private brushResetCallback;

  public MaxText;
  public MinText;

  public warningOverride: boolean = false;
  public criticalOverride: boolean = false;

  get Data(): any[][] {
    const keys = Object.keys(this.split_data);
    return keys.map((key) => this.split_data[key]);
  }

  constructor(
    private chartContainer: ElementRef,
    private id: string,
    private i18n: I18nService
  ) {}

  public render(data: any[], isMobile, settings: UserSettings) {
    if (!!data) {
      this.data = data.map((t) => {
        const temp = {
          date: new Date(t.timestamp),
          value: t.value,
          text: t.measuredValue,
        };
        if (!this.split_data[t.measurementTypeID]) {
          this.split_data[t.measurementTypeID] = [temp];
        } else {
          this.split_data[t.measurementTypeID].push(temp);
        }
        return temp;
      });
    }

    this.renderGraph(isMobile, settings);
  }

  public SetValueUnit(unit: string) {
    this.y_unit = unit;
  }

  public SetXDomain(start: Date, end: Date, custom: boolean = false) {
    // set right graph cutoff to midnight of the current day (or next day 00:00):

    if (!custom && (end.getHours() > 0 || end.getMinutes() > 0)) {
      end = new Date(new Date(end).setDate(end.getDate() + 1));
    }
    if (!custom) start = new Date(start.toDateString());
    if (!custom) end = new Date(end.toDateString());
    this.x_domain = d3.extent([start, end], (d) => d);
    this.customRange = custom;
    if (!!this.updateChart) this.updateChart();
  }
  public ResetXDomain() {
    this.x_domain = null;
  }
  public SetYDomain(
    domain: [number, number],
    booleanDomain: boolean = false,
    measurementID: number
  ) {
    if (booleanDomain) {
      this.y_domain = [-0.2, 1.2];
      this.booleanDomain = true;
    } else {
      // if there are no ranges defined, this function might be called with min = infinity and max = -infinity, which causes problems
      // solution: leave this.y_domain as is
      if (domain[1] > domain[0]) {
        this.y_domain = domain;
      }
    }
    if (!!this.updateChart) this.updateChart();
  }
  public ResetYDomain() {
    this.y_domain = null;
  }
  public SetValueRanges(ranges: ValueRange[]) {
    this.valueRanges = ranges /*.filter(
      (range) => range.type !== ValueRangeType.red
    )*/;
  }

  private renderGraph(isMobile: boolean, settings: UserSettings) {
    d3.select('svg#' + this.id).remove();
    if (!this.chartContainer) return;
    const max_width = this.chartContainer.nativeElement.clientWidth;
    const max_height = this.chartContainer.nativeElement.clientHeight;
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
    // set the dimensions and margins of the graph
    const margin = {
        top: 55,
        right: 85,
        bottom: 71,
        left: 0,
      },
      width = max_width - margin.left - margin.right,
      height = max_height - margin.top - margin.bottom;

    // on mobile renderGraph() sometimes seems to get called with too small clientHeights
    // since it gets called again later with the correct height, we can skip further execution for now
    if (height < 0) return;

    let svg,
      xAxis,
      yAxis,
      yearAxis, // second y axis only showing the current year
      clipMask,
      focusLine,
      focusDate, // date under the vertical line on the right most side of the chart
      focusDateBG,
      focusValues,
      focusValueLines,
      focusValuesBG,
      circlePointsInner, // dot marking the current value on the right most line of the chart
      circlePointsOuter;

    let unique_identifier,
      element, // html element containing the chart
      x, // domain function for determining pixels from x domain
      y, // domain function for determining pixels from y domain
      x_extent, // x domain extent as array [min,max]
      y_extent, // y domain extent as array [min,max]
      bisect, // gets the next x value in the dataset from a given x value (date)
      currentData, // is set to the right most data point
      sx, // horizontal pixel position of the right most data point
      sy, // vertical pixel position of the right most data point
      warning, // whether right most data point is in a warning range
      critical, // whether right most data point is in a critical range
      noData; // true if we literally don't have any data;

    const drawChart = () => {
      init();
      drawAxes();
      drawClipmask();
      drawHorizontalLines();
      if (!noData) drawRanges();
      if (!noData) drawLegend();
      if (!noData) drawAreaBetweenCurves();
      if (!noData) drawCurve();
      if (!noData) drawFocus();

      initBrushing();
      if (noData) drawNoDataIndicator();
    };

    const init = () => {
      unique_identifier = generateUniqueIdentifier();
      element = this.chartContainer.nativeElement;
      noData = !this.data || this.data.length < 1;
      // avoid errors
      if (noData) {
        this.data = [
          {
            date: new Date(new Date().setDate(new Date().getDate() - 7)),
            value: 0,
            text: '0',
          },
          { date: new Date(), value: 100, text: '100' },
        ];
      }
      x_extent = d3.extent(this.data, (d) => {
        return d.date;
      });
      if (!this.x_domain) this.x_domain = [x_extent[0], x_extent[1]];
      const partial_data = this.data.filter(
        (item) =>
          (item.date > this.x_domain[0] && item.date < this.x_domain[1]) ||
          noData // use fall back data to avoid display errors
      );
      const temp = [
        d3.min(partial_data, (d) => {
          return +d.value;
        }),
        d3.max(partial_data, (d) => {
          return +d.value;
        }),
      ];
      // temp[0] = Math.min(temp[0], 0);
      y_extent = !!this.y_domain
        ? [
            Math.min(temp[0], this.y_domain[0]),
            Math.max(temp[1], this.y_domain[1]),
          ]
        : temp;
      // adding a 10 % buffer to the scale
      const buffer = (y_extent[1] - y_extent[0]) * 0.1;

      x = d3
        .scaleTime()
        .domain(x_extent)
        .range([20, width - 20]);

      y = d3
        .scaleLinear()
        .domain([y_extent[0] - buffer, y_extent[1] + buffer])
        .range([height, 0]);

      bisect = (data, _x) => {
        const bisect_right = d3.bisector((d) => {
          return d.date;
        }).right;
        const right = bisect_right(data, _x);
        if (right === 0) return right;
        const left = right - 1;
        if (!data[right]) return left;
        const diff1 = _x - data[left].date;
        const diff2 = data[right].date - _x;
        return diff1 < diff2 ? left : right;
      };

      currentData = this.data[bisect(this.data, x_extent[1])];
      sx = x(currentData.date);
      sy = y(currentData.value);

      const x0 = this.data[this.data.length - 1].date;
      selectedData = this.Data.map((data) => {
        const i = bisect(data, x0.getTime());
        return data[i];
      });

      const keys = Object.keys(this.split_data);
      warning = selectedData.map(
        (item, index) =>
          that.warningOverride ||
          !!that.valueRanges.find(
            (range) =>
              range.type === ValueRangeType.yellow &&
              range.min <= item.value &&
              range.max >= item.value &&
              range.measurementTypeID === parseInt(keys[index], 10)
          )
      );
      critical = selectedData.map(
        (item, index) =>
          that.criticalOverride ||
          !!that.valueRanges.find(
            (range) =>
              range.type === ValueRangeType.red &&
              range.min <= item.value &&
              range.max >= item.value &&
              range.measurementTypeID === parseInt(keys[index], 10)
          )
      );
      initSVG();
    };

    const initSVG = () => {
      // append the svg object to the body of the page
      svg = d3
        .select(element)
        .append('svg')
        .attr('id', this.id)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // svg.on('click', mousemove);

      // filters go in defs element
      const defs = svg.append('defs');

      const filter = defs
        .append('filter')
        .attr('id', 'pill-gradient')
        .attr('height', '1000%')
        .attr('width', '1000%')
        .attr('x', '-500%')
        .attr('y', '-500%');

      filter
        .append('feColorMatrix')
        .attr('type', 'matrix')
        .attr('values', '0 0 0 1 0  0 0 0 1 0  0 0 0 1 0  0 0 0 1 0');

      filter
        .append('feGaussianBlur')
        .attr('stdDeviation', 2)
        .attr('result', 'coloredBlur');

      const feMerge = filter.append('feMerge');

      feMerge.append('feMergeNode').attr('in', 'coloredBlur');
      //    .attr("in", "SourceAlpha");
    };

    const drawClipmask = () => {
      // Add a clipPath: everything out of this area won't be drawn.
      svg
        .append('defs')
        .append('svg:clipPath')
        .attr('id', 'multiline-clip-' + this.id)
        .append('svg:rect')
        .attr('width', width)
        .attr('height', height + 10)
        .attr('x', 0)
        .attr('y', -5);

      // Create the line variable: where both the line and the brush take place
      clipMask = svg
        .append('g')
        .attr('clip-path', 'url(#multiline-clip-' + this.id + ')');
    };

    const drawAxes = () => {
      svg
        .append('rect')
        .style('fill', 'rgb(255, 255, 255)')
        .style('cursor', 'pointer')
        .attr('x', -1)
        .attr('y', 0)
        .attr('width', width + 1)
        .attr('height', height);
      if (!noData) {
        xAxis = svg
          .append('g')
          .attr('id', 'x-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height)
              .tickPadding(10)
              .tickFormat((d) => {
                return '';
              })
          )
          .call((g) =>
            g
              .selectAll('.tick line')
              .attr('stroke', '#e6e7e7')
              .style('cursor', 'pointer')
          )
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', 12)
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr('transform', 'translate(0,-3)')
          );
        yearAxis = svg
          .append('g')
          .attr('id', 'year-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height + 28)
              .ticks(d3.timeYear)
              .tickPadding(15)
              .tickFormat((d) => {
                return d3.timeFormat('%Y')(d);
              })
          )
          .call((g) => g.selectAll('.tick line').attr('stroke', '#e6e7e7'))
          .call((g) => g.select('.domain').remove())
          .call((g) => g.selectAll('.tick text').attr('color', '#afb4b9'));
      }

      const false_icon =
        '<i nz-icon nzType="icons:status-check" class="mini-svg-icon fillgreen"></i>';
      const true_icon =
        '<i nz-icon nzType="icons:status-exclamation-triangle" class="mini-svg-icon fillwhite"></i>';
      yAxis = svg
        .append('g')
        .attr('id', 'y-axis')
        .attr('transform', 'translate(' + width + ', 0)')
        .call(
          this.booleanDomain
            ? d3
                .axisRight(y)
                .tickValues([0, 1])
                .tickFormat((d) => d)
            : d3
                .axisRight(y)
                .tickValues([y_extent[0], y_extent[1]])
                .tickFormat((d) => {
                  return d + ' ' + this.y_unit;
                })
        )
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', 4)
            .attr('color', '#afb4b9')
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('text-anchor', 'end')
            .attr('dx', 35)
        );

      svg.select('.axis').selectAll('text').remove();
      if (this.booleanDomain) {
        yAxis.selectAll('.tick text').remove();
        yAxis
          .selectAll('.tick:first-child')
          .append('svg:image')
          .attr('xlink:href', function (d) {
            return 'assets/icons/tilt_untilted.svg';
          })
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -36);
        yAxis
          .selectAll('.tick:last-child')
          .append('svg:image')
          .attr('xlink:href', function (d) {
            return 'assets/icons/tilt_tilted_red.svg';
          })
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -32);
      }
    };
    let unbrushed_domain = null;
    const initBrushing = () => {
      // Add brushing
      const brush = d3
        .brushX() // Add the brush feature using the d3.brush function
        .extent([
          [0, -6],
          [sx, height + 12],
          // making the brushing taller than the clipmask hides the top and bottom strokes so that we only have
          // solid blue lines at the left and right
        ])
        // initialise the brush area: start at 0,0 and finishes at sx,height: it means I select the whole graph area
        .on('end', () => {
          if (!d3.event.sourceEvent) return;
          const extent = d3.event.selection;
          const epsilon =
            0.008 * Math.abs(+this.x_domain[1] - +this.x_domain[0]);
          if (
            !extent ||
            !extent[0] ||
            Math.abs(+x.invert(extent[1]) - +x.invert(extent[0])) < epsilon
          ) {
            mousemove(!!extent ? extent[0] : d3.event.sourceEvent.offsetX);
          } else {
            if (minutesBetween(x.invert(extent[0]), x.invert(extent[1])) > 60) {
              if (!unbrushed_domain) {
                unbrushed_domain = this.x_domain;
              }
              this.SetXDomain(x.invert(extent[0]), x.invert(extent[1]), true);
              if (!!this.brushCallback) this.brushCallback(this.x_domain);
            }
          }
          // This remove the grey brush area as soon as the selection has been done
          setTimeout(() => svg.select('.brush').call(brush.move, null));
        });
      this.resetBrush = () => {
        if (!!unbrushed_domain)
          this.SetXDomain(unbrushed_domain[0], unbrushed_domain[1]);

        unbrushed_domain = null;
        if (!!this.brushResetCallback) this.brushResetCallback(this.x_domain);

        updateFocusline(
          that.data[bisect(that.data, that.x_domain[1])].date,
          true
        );

        unbrushed_domain = null;
        if (!!this.brushResetCallback) this.brushResetCallback();
      };
      svg.on('dblclick', this.resetBrush);
      // Add the brushing
      clipMask.append('g').attr('class', 'brush').call(brush);
      clipMask.selectAll('.selection').attr('fill-opacity', 1);
    };
    const drawHorizontalLines = () => {
      clipMask // appends to line object for clipping purposes
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', y(0))
        .attr('y2', y(0))
        .attr('stroke', '#979b9b');
      clipMask
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', height)
        .attr('y2', height)
        .attr('stroke', '#e6e7e7');
      svg
        .append('line')
        .attr('x1', 0)
        .attr('x2', max_width)
        .attr('y1', height + 31)
        .attr('y2', height + 31)
        .attr('stroke', '#e6e7e7');
    };
    const drawNoDataIndicator = () => {
      svg
        .append('text')
        .html('No data')
        .attr('y', 16)
        .attr('x', 36)
        .style('opacity', 0.6);
    };
    const drawRanges = () => {
      if (!this.valueRanges || !this.valueRanges.length) return;
      let lowest_crit_range: ValueRange = null;
      let highest_crit_range: ValueRange = null;
      this.valueRanges.map((range) => {
        if (range.type === ValueRangeType.red) {
          if (!lowest_crit_range || lowest_crit_range.max < range.max) {
            lowest_crit_range = range;
          }
          if (!highest_crit_range || highest_crit_range.min > range.min) {
            highest_crit_range = range;
          }
        }
      });
      const warning_y_extent = [lowest_crit_range.max, highest_crit_range.min];
      if (warning_y_extent[0] > warning_y_extent[1]) {
        warning_y_extent[0] = warning_y_extent[1];
      }
      if (false) {
        for (const range of this.valueRanges) {
          if (range.type === ValueRangeType.red) {
            // currently, we only display critical range
            if (critical) {
              /*clipMask
                .append('rect')
                .style('fill', '#E1000F')
                .style('cursor', 'pointer')
                .style('opacity', 0.05)
                .attr('x', 0)
                .attr('y', y(range.max))
                .attr('width', width)
                .attr('height', y(range.min) - y(range.max));*/
            }
            if (range.min > y_extent[0])
              clipMask
                .append('line')
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', y(range.min))
                .attr('y2', y(range.min))
                .attr('stroke', '#E1000F')
                .style('cursor', 'pointer');
            if (range.max < y_extent[1])
              clipMask
                .append('line')
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', y(range.max))
                .attr('y2', y(range.max))
                .attr('stroke', '#E1000F')
                .style('cursor', 'pointer');
          } else if (range.type === ValueRangeType.yellow) {
            // currently, we only display critical range
            if (warning) {
              /*clipMask
                .append('rect')
                .style('fill', '#e8680c')
                .style('cursor', 'pointer')
                .style('opacity', 0.05)
                .attr('x', 0)
                .attr('y', y(range.max))
                .attr('width', width)
                .attr('height', y(range.min) - y(range.max));*/
            }
            if (range.min > warning_y_extent[0])
              clipMask
                .append('line')
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', y(range.min))
                .attr('y2', y(range.min))
                .attr('stroke', '#e8680c')
                .style('cursor', 'pointer');
            if (range.max < warning_y_extent[1])
              clipMask
                .append('line')
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', y(range.max))
                .attr('y2', y(range.max))
                .attr('stroke', '#e8680c')
                .style('cursor', 'pointer');
          }
        }
      }

      const gradient_test = d3.range(100).map((d) => ({
        offset: d + '%',
        color: d3.interpolateRainbow(d / 100),
      }));
      const keys = Object.keys(this.split_data);
      keys.map((key, index) => {
        const key_num = parseInt(key, 10);
        const value = this.Data[index][this.Data[index].length - 1].value;
        // Set the gradient
        clipMask
          .append('linearGradient')
          .attr(
            'id',
            key === '1074' || key === '1025' || key === '1076'
              ? 'line-gradient' + unique_identifier + key
              : 'dotted-line-gradient' + unique_identifier + key
          )
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', 0)
          .attr('y1', y(y_extent[0]))
          .attr('x2', 0)
          .attr('y2', y(y_extent[1]))
          .selectAll('stop')
          // .data(gradient_test)
          .data(
            generateCriticalGradient(
              this.valueRanges.filter(
                (range) => range.measurementTypeID === key_num
              ),
              value,
              y_extent,
              critical[index],
              warning[index]
            )
          )
          .enter()
          .append('stop')
          .attr('offset', (d) => {
            return d.offset;
          })
          .attr('stop-color', (d) => {
            return d.color;
          });
      });
    };

    const drawLegend = () => {
      if (Object.keys(this.split_data).indexOf('1073') > -1) {
        this.MaxText = this.i18n.string('inlet');
        this.MinText = this.i18n.string('outlet');
      } else {
        this.MaxText = this.i18n.string('max');
        this.MinText = this.i18n.string('min');
      }
      svg
        .append('line')
        .attr('x1', 9)
        .attr('x2', 31)
        .attr('y1', -31)
        .attr('y2', -31)
        .attr('stroke', 'black')
        .attr('stroke-width', 3);
      //   .style('stroke-linecap', 'round');
      svg
        .append('text')
        .html(this.MaxText)
        .attr('font-size', 12)
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', 18)
        .attr('letter-spacing', 0)
        .attr('dy', -4)
        .attr('class', 'brushText2Time')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('y', -23)
        .attr('x', 51);
      svg
        .append('line')
        .attr('x1', 91)
        .attr('x2', 118)
        .attr('y1', -31)
        .attr('y2', -31)
        .attr('stroke', 'black')
        .attr('stroke-width', 3)
        .style('stroke-dasharray', '3, 5')
        .style('stroke-dashoffset', '0');
      //  .style('stroke-linecap', 'round');
      svg
        .append('text')
        .html(this.MinText)
        .attr('font-size', 12)
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', 18)
        .attr('letter-spacing', 0)
        .attr('dy', -4)
        .attr('class', 'brushText2Time')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('y', -23)
        .attr('x', 142);
      // stroke-linecap: round;
      // stroke-linejoin: round;
    };
    let areaData = null;
    const drawAreaBetweenCurves = () => {
      if (
        Object.keys(this.split_data).includes('1073') ||
        Object.keys(this.split_data).includes('1074')
      )
        return;
      const x_values = this.Data.map((data) => data.map((item) => item.date))
        .flat(2)
        .filter(
          (value, index, array) =>
            index === array.findIndex((_value) => _value === value)
        )
        .sort((a, b) => a - b);
      areaData = x_values.map((date) => {
        const keys = Object.keys(this.Data);
        const val1 = this.Data[keys[0]][bisect(this.Data[keys[0]], date)].value;
        const val2 = this.Data[keys[1]][bisect(this.Data[keys[1]], date)].value;

        const minVal = Math.min(val1, val2);
        const maxVal = Math.max(val1, val2);
        return {
          date: date,
          minVal: minVal,
          maxVal: maxVal,
        };
      });
      clipMask
        .append('path')
        .datum(areaData)
        .attr('class', 'area')
        .attr('fill', '#cce5df')
        .attr('stroke', '#69b3a2')
        .attr('stroke-width', 1.5)
        .attr(
          'd',
          isSafari
            ? d3
                .area()
                .x(function (d) {
                  return x(d.date);
                })
                .y0(function (d) {
                  return y(d.minVal);
                })
                .y1(function (d) {
                  return y(d.maxVal);
                })
            : d3
                .area()
                .x(function (d) {
                  return x(d.date);
                })
                .y0(function (d) {
                  return y(d.minVal);
                })
                .y1(function (d) {
                  return y(d.maxVal);
                })
                .curve(d3.curveMonotoneX)
        );
    };

    const drawCurve = () => {
      const keys = Object.keys(this.split_data);
      keys.map((key, index) => {
        const data = this.split_data[key];
        clipMask
          .append('path')
          .attr('id', 'datapath')
          .datum(data)
          .attr(
            'class',
            key === '1074' || key === '1025' || key === '1076'
              ? 'curve no-linecaps'
              : 'curve'
          ) // I add the class .curve to be able to modify this line later on.
          .attr('fill', 'none')
          .attr(
            'stroke',
            !this.valueRanges || !this.valueRanges.length
              ? key === '1074' || key === '1025' || key === '1076'
                ? '#5F6973'
                : 'black'
              : (index === 0
                  ? 'url(#line-gradient'
                  : 'url(#dotted-line-gradient') +
                  unique_identifier +
                  key +
                  ')'
          )
          .attr('stroke-width', 2)
          .style(
            'stroke-dasharray',
            key === '1074' || key === '1025' || key === '1076'
              ? '2,2'
              : 'initial'
          )
          .style(
            'stroke-dashoffset',
            key === '1074' || key === '1025' || key === '1076' ? '2' : 'initial'
          )
          .style('cursor', 'pointer')
          .attr(
            'd',
            isSafari
              ? d3
                  .line()
                  .x((d) => {
                    return x(d.date);
                  })
                  .y((d) => {
                    return y(d.value);
                  })
              : d3
                  .line()
                  .x((d) => {
                    return x(d.date);
                  })
                  .y((d) => {
                    return y(d.value);
                  })
                  .curve(d3.curveMonotoneX)
          );
      });
    };

    const drawFocus = () => {
      if (noData) return;
      focusLine = svg
        .append('g')
        .append('line')
        .attr('x1', sx)
        .attr(
          'y1',
          y(
            this.y_domain
              ? this.y_domain[1]
              : d3.max(this.data, (d) => {
                  return +d.value;
                })
          )
        )
        .attr('x2', sx)
        .attr(
          'y2',
          y(
            this.y_domain
              ? this.y_domain[0]
              : d3.min(this.data, (d) => {
                  return +d.value;
                })
          )
        )
        .attr('stroke', 'black')
        .style('cursor', 'pointer');
      focusDateBG = svg
        .append('rect')
        .style('fill', 'white')
        .attr('x', sx)
        .attr('y', height + 7)
        .attr('width', 35)
        .attr('height', 12)
        .style('filter', 'url(#pill-gradient)')
        .attr('rx', '3')
        .attr('ry', '3')
        .style('transform', 'translate(-18px, 0px)');
      focusDate = svg
        .append('text')
        .html(
          d3.timeFormat(
            !!settings.DateFormat &&
              settings.DateFormat.formatString === 'MM/dd/yyyy'
              ? '%m/%d'
              : '%e-%m'
          )(this.data[this.data.length - 1].date)
        )
        .attr('font-size', 12)
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', 18)
        .attr('letter-spacing', 0)
        .attr('dy', -4)
        .attr('class', 'brushText2Time')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('y', height + (isSafari ? 16 : 21))
        .attr('x', sx);

      focusValuesBG = [];

      focusValues = [];
      focusValueLines = [];
      circlePointsInner = [];
      circlePointsOuter = [];
      that.Data.map((data, index) => {
        const _sy = sy[index];
        focusValuesBG.push(
          svg
            .append('rect')
            .style('fill', 'white')
            .attr('x', width)
            .attr('y', _sy)
            .attr('width', 46)
            .attr('height', 12)
            .attr('opacity', 0)
            .style('filter', 'url(#pill-gradient)')
            .attr('rx', '3')
            .attr('ry', '3')
        );
        focusValues.push(
          svg
            .append('text')
            .html(data[data.length - 1].value + this.y_unit)
            .attr('x', width)
            .attr('y', _sy)
            .attr(
              'fill',
              critical[index]
                ? '#E1000F'
                : warning[index]
                ? '#FFCC33'
                : '#00AA75'
            )
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('text-anchor', 'end')
            .attr('dx', 40)
            .attr('dy', 4)
        );
        focusValueLines.push(
          svg
            .append('line')
            .attr('x1', sx)
            .attr('x2', width - 5)
            .attr('y1', _sy)
            .attr('y2', _sy)
            .attr(
              'stroke',
              critical[index]
                ? '#E1000F'
                : warning[index]
                ? '#FFCC33'
                : '#00AA75'
            )
        );
        circlePointsInner.push(
          svg
            .append('circle')
            .attr('class', 'inner')
            .attr(
              'fill',
              critical[index]
                ? '#E1000F'
                : warning[index]
                ? '#FFCC33'
                : '#00AA75'
            )
            .attr('cx', sx)
            .attr('cy', _sy)
            .attr('r', 8)
        );
        circlePointsOuter.push(
          svg
            .append('circle')
            .attr('class', 'outer')
            .attr('cx', sx)
            .attr('cy', _sy)
            .attr('fill', 'transparent')
            .attr(
              'stroke',
              critical[index]
                ? '#E1000F'
                : warning[index]
                ? '#FFCC33'
                : '#00AA75'
            )
            .attr('r', 12)
        );
      });
    };

    this.updateChart = () => {
      // update the chart for given boundaries / x domain
      if (!noData) updateXAxis();
      if (!noData) updateYAxis();
      if (!noData) updateAreaBetweenCurves();
      if (!noData) updateCurve();
      if (!noData) updateFocusPoint();
      // if (!noData)
      //  updateFocusline(that.data[bisect(that.data, that.x_domain[1])].date);
      mouseout();
    };

    const updateAreaBetweenCurves = () => {
      /*
      clipMask
        .append('path')
        .datum(areaData)
        .attr('fill', '#cce5df')
        .attr('stroke', '#69b3a2')
        .attr('stroke-width', 1.5);
*/
      clipMask
        .selectAll('.area')
        .transition()
        .duration(1000)
        .attr(
          'd',
          isSafari
            ? d3
                .area()
                .x(function (d) {
                  return x(d.date);
                })
                .y0(function (d) {
                  return y(d.minVal);
                })
                .y1(function (d) {
                  return y(d.maxVal);
                })
            : d3
                .area()
                .x(function (d) {
                  return x(d.date);
                })
                .y0(function (d) {
                  return y(d.minVal);
                })
                .y1(function (d) {
                  return y(d.maxVal);
                })
                .curve(d3.curveMonotoneX)
        );
    };
    const updateCurve = () => {
      clipMask
        .selectAll('.curve')
        .transition()
        .duration(1000)
        .attr(
          'd',
          isSafari
            ? d3
                .line()
                .x((d) => {
                  return x(d.date);
                })
                .y((d) => {
                  return y(d.value);
                })
            : d3
                .line()
                .x((d) => {
                  return x(d.date);
                })
                .y((d) => {
                  return y(d.value);
                })
                .curve(d3.curveMonotoneX)
        );
    };
    const updateGanttBars = () => {
      clipMask
        .selectAll('.gantt-bar')
        .transition()
        .duration(1000)
        .attr('x', (d) => x(d.start) + (x(d.end) - x(d.start) - 2 <= 0 ? 0 : 1))
        .attr('width', (d) => Math.max(x(d.end) - x(d.start) - 2, 1));
      updateCurve();
    };

    const that = this;
    let selectedData;

    function mousemove(_x?: any) {
      if (noData) return;
      if (!_x && !this) return;
      updateFocusline(x.invert(_x ? _x : d3.mouse(this)[0]));
      updateYAxis(
        that.Data.map(
          (data) =>
            data[bisect(data, x.invert(_x ? _x : d3.mouse(this)[0]))].value
        )
      );
    }

    function mouseout() {
      if (noData) return;
      updateFocusline(
        that.data[bisect(that.data, that.x_domain[1])].date,
        true
      );
      updateYAxis(
        that.Data.map((data) => data[bisect(data, that.x_domain[1])].value)
      );
      // focusValues.map((elem) => elem.attr('opacity', 0));
      // focusValuesBG.map((elem) => elem.attr('opacity', 0));
      focusValueLines.map((elem) => elem.attr('opacity', 0));
    }

    function updateFocusline(x0, reset = false) {
      if (noData) return;
      if (that.booleanDomain) return;
      const left_margin = 18;
      const right_margin = 25;
      if (noData) return;
      selectedData = that.Data.map((data) => {
        const i = bisect(data, x0.getTime());
        return data[i];
      });
      let _sx = x(selectedData[0].date);
      if (_sx < 0) _sx = 0;
      if (_sx > width) _sx = width;
      let tx = _sx;
      if (tx < left_margin) tx = left_margin;
      if (tx > width - right_margin) tx = width - right_margin;
      if (selectedData) {
        focusLine
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x1', _sx)
          .attr('x2', _sx);
        warning = selectedData.map(
          (item) =>
            that.warningOverride ||
            !!that.valueRanges.find(
              (range) =>
                range.type === ValueRangeType.yellow &&
                range.min <= item.value &&
                range.max >= item.value
            )
        );
        const keys = Object.keys(that.split_data);
        critical = selectedData.map(
          (item, index) =>
            that.criticalOverride ||
            !!that.valueRanges.find(
              (range) =>
                range.type === ValueRangeType.red &&
                range.min <= item.value &&
                range.max >= item.value &&
                range.measurementTypeID === parseInt(keys[index], 10)
            )
        );
        focusDate
          .html(
            d3.timeFormat(
              !!settings.DateFormat &&
                settings.DateFormat.formatString === 'MM/dd/yyyy'
                ? '%m/%d'
                : '%e-%m'
            )(selectedData[0].date)
          )
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', _sx);

        focusDateBG
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', _sx);

        sy = selectedData.map((item) => y(item.value));
        selectedData.map((item, index) => {
          circlePointsInner[index]
            .transition()
            .duration(reset ? 1000 : 500)
            .attr('cy', sy[index])
            .attr('cx', _sx)
            .attr(
              'fill',
              critical[index]
                ? '#E1000F'
                : warning[index]
                ? '#FFCC33'
                : '#00AA75'
            );
          circlePointsOuter[index]
            .transition()
            .duration(reset ? 1000 : 500)
            .attr('cy', sy[index])
            .attr('cx', _sx)
            .attr(
              'stroke',
              critical[index]
                ? '#E1000F'
                : warning[index]
                ? '#FFCC33'
                : '#00AA75'
            );

          // if (!reset) {
          focusValues[index]
            .html(item.value + that.y_unit)
            .transition()
            .duration(reset ? 1000 : 500)
            .attr('y', sy[index])
            .attr(
              'fill',
              critical[index]
                ? '#E1000F'
                : warning[index]
                ? '#FFCC33'
                : '#00AA75'
            )
            .attr('opacity', 1);
          focusValuesBG[index]
            .transition()
            .duration(reset ? 1000 : 500)
            .attr('y', sy[index] - 6)
            .attr('opacity', 1);
          if (!reset) {
            focusValueLines[index]
              .transition()
              .duration(reset ? 1000 : 500)
              .attr('x1', _sx)
              .attr('y1', sy[index])
              .attr('y2', sy[index])
              .attr(
                'stroke',
                critical[index]
                  ? '#E1000F'
                  : warning[index]
                  ? '#FFCC33'
                  : '#00AA75'
              )
              .attr('opacity', 0); // hides the focus lines permanently for now, if you want them back, put a 1 here
          } else {
            /* focusValues[index]
              .html(item.value + that.y_unit)
              .attr('y', sy[index])
              .attr(
                'fill',
                critical[index]
                  ? '#E1000F'
                  : warning[index]
                  ? '#FFCC33'
                  : '#00AA75'
              );*/
            focusValueLines[index]
              .attr('x1', _sx)
              .attr('y1', sy[index])
              .attr('y2', sy[index])
              .attr(
                'stroke',
                critical[index]
                  ? '#E1000F'
                  : warning[index]
                  ? '#FFCC33'
                  : '#00AA75'
              );

            // we use transition here mainly because the transition from a previous call might overwrite opacity if it
            // occured less then 0.5 seconds ago
            // focusValues[index].transition().duration(500).attr('opacity', 0);
            focusValueLines[index]
              .transition()
              .duration(500)
              .attr('opacity', 0);
            // focusValuesBG[index].transition().duration(500).attr('opacity', 0);
          }
        });
      }
    }

    function formatDate(date) {
      const o_date_en = new Intl.DateTimeFormat('en');
      return o_date_en.format(date);
    }

    const updateXAxis = () => {
      if (this.x_domain) {
        x.domain(this.x_domain);
      }

      if (!this.customRange) unbrushed_domain = null;

      const tickCall = generateTicks(this.x_domain[0], this.x_domain[1]);

      xAxis
        .transition()
        .duration(1000)
        .call(tickCall)
        .call((g) => g.selectAll('.tick line').attr('stroke', '#e6e7e7'))
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('color', '#afb4b9')
            .attr('font-size', isMobile ? '12px' : '14px')
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', '18px')
            .attr('letter-spacing', 0)
            .attr('transform', 'translate(0,-3)')
        );

      const yearTransitionInRange =
        d3.timeYear.range(this.x_domain[0], this.x_domain[1]).length > 0;
      yearAxis
        .transition()
        .duration(1000)
        .call(
          d3
            .axisBottom(x)
            .tickSize(height + (isSafari ? 30 : 28))
            .tickValues(
              yearTransitionInRange
                ? d3.timeYear.range(this.x_domain[0], this.x_domain[1])
                : [
                    new Date(
                      (this.x_domain[0].getTime() +
                        this.x_domain[1].getTime()) /
                        2
                    ),
                  ]
            )
            .tickPadding(15)
            .tickFormat((d) => {
              return !noData ? d3.timeFormat('%Y')(d) : '';
            })
        )
        .call((g) => g.selectAll('.tick line').attr('stroke', 'transparent'))
        .call((g) => g.select('.domain').remove())
        .call(
          (g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', 12)
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr(
                'dx',
                yearTransitionInRange ? 0 : (margin.right - margin.left) / 2
              ) // this centers the year in the wrapper when the year tick is not placed at the year transition
        )
        .call((g) =>
          g.selectAll('.tick:last-child text').attr('color', 'black')
        );
    };

    const updateYAxis = (s = null) => {
      if (this.booleanDomain) return;
      if (!s)
        s = that.Data.map((data) => data[bisect(data, that.x_domain[1])].value);

      const partial_data = this.data.filter(
        (item) => item.date > this.x_domain[0] && item.date < this.x_domain[1]
      );
      if (partial_data.length < 2) return;
      const temp = [
        d3.min(partial_data, (d) => {
          return +d.value;
        }),
        d3.max(partial_data, (d) => {
          return +d.value;
        }),
      ];
      // temp[0] = Math.min(temp[0], 0);
      y_extent = !!this.y_domain
        ? [
            Math.min(temp[0], this.y_domain[0]),
            Math.max(temp[1], this.y_domain[1]),
          ]
        : temp;
      // adding a 10 % buffer to the scale
      const buffer = (y_extent[1] - y_extent[0]) * 0.1;
      // if (this.y_domain) {
      if (y_extent[0] < y_extent[1]) {
        y.domain([y_extent[0] - buffer, y_extent[1] + buffer]);
      }

      const delta = Math.abs(y_extent[0] - y_extent[1]);
      const epsilon = 0.1;
      yAxis
        .transition()
        .duration(1000)
        .call(
          d3
            .axisRight(y)
            .tickValues([y_extent[0], y_extent[1]])
            .tickFormat((d) => {
              return d + ' ' + this.y_unit;
            })
        )
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', 4)
            .attr('color', '#afb4b9')
            .attr('opacity', (d) => {
              return Math.min(...s.map((_s) => Math.abs(_s - d))) <
                delta * epsilon
                ? 0
                : 1;
            })
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('text-anchor', 'end')
            .attr('dx', 35)
        );
      yAxis.call((g) => g.selectAll('.tick line').remove());
    };
    const updateFocusPoint = () => {
      if (noData) return;
      currentData = this.Data.map(
        (data) => data[bisect(data, this.x_domain[1])]
      );
      warning = selectedData.map(
        (item) =>
          that.warningOverride ||
          !!that.valueRanges.find(
            (range) =>
              range.type === ValueRangeType.yellow &&
              range.min <= item.value &&
              range.max >= item.value
          )
      );
      critical = selectedData.map(
        (item) =>
          that.criticalOverride ||
          !!that.valueRanges.find(
            (range) =>
              range.type === ValueRangeType.red &&
              range.min <= item.value &&
              range.max >= item.value
          )
      );
      sy = currentData.map((data) => y(data.value));
      sy.map((_sy, index) => {
        circlePointsInner[index]
          .transition()
          .duration(1000)
          .attr('cy', _sy)
          .attr(
            'fill',
            critical[index] ? '#E1000F' : warning[index] ? '#FFCC33' : '#00AA75'
          );
        circlePointsOuter[index]
          .transition()
          .duration(1000)
          .attr('cy', _sy)
          .attr(
            'stroke',
            critical[index] ? '#E1000F' : warning[index] ? '#FFCC33' : '#00AA75'
          );
      });

      focusDate
        .html(
          d3.timeFormat(
            !!settings.DateFormat &&
              settings.DateFormat.formatString === 'MM/dd/yyyy'
              ? '%m/%d'
              : '%e-%m'
          )(this.data[bisect(this.data, this.x_domain[1])].date)
        )
        .attr('font-size', isMobile ? '12px' : '14px')
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', '18px')
        .attr('letter-spacing', 0)
        .attr('transform', 'translate(0,2)');
    };

    function generateTicks(start, end) {
      const small_screen = width < 450;
      const timespan_days = daysBetween(start, end);
      const timespan_hours = hoursBetween(start, end);

      const locale = d3.timeFormatLocale(
        settings.Language.abbreviation === 'de' ? LOCALE_DE : LOCALE_EN
      );

      let ticks: any = d3.timeDay.every(small_screen ? 2 : 1);
      let tickFormat = (d) => {
        return d3.timeFormat(
          !!settings.DateFormat &&
            settings.DateFormat.formatString === 'MM/dd/yyyy'
            ? '%m/%d'
            : '%e-%m'
        )(d);
      };
      let tickValuesRangeEnd = minDate(
        new Date(that.data[that.data.length - 1].date),
        end
      );
      let tickValuesRangeStart = start;
      if (timespan_days > 15) {
        ticks = d3.timeWeek.every(1);
        tickFormat = (d) => {
          return (
            (settings.Language.abbreviation === 'de' ? 'KW' : 'CW') +
            d3.timeFormat('%U')(d)
          );
        };
      }
      if (timespan_days > 45 || (small_screen && timespan_days > 25)) {
        ticks = d3.timeWeek.every(2);
      }
      if (timespan_days > 90 || (small_screen && timespan_days > 60)) {
        ticks = d3.timeMonth.every(1);
        // avoid jumbled month names
        if (!small_screen) {
          const tempTicks = ticks.range(
            tickValuesRangeStart,
            tickValuesRangeEnd
          );
          const widthPerTick = width / tempTicks.length;
          if (widthPerTick < 35) ticks = d3.timeMonth.every(4);
          else if (widthPerTick < 75) ticks = d3.timeMonth.every(2);
        }
        tickFormat = (d) => {
          return locale.format(small_screen ? '%b' : '%B')(d);
        };
      }
      const end_sx = x(that.data[that.data.length - 1].date);
      let tickValues = ticks
        .range(tickValuesRangeStart, tickValuesRangeEnd)
        .filter((d) => {
          return (
            x(d) >=
              (timespan_days > 90 || (small_screen && timespan_days > 60)
                ? 30
                : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
          );
        }); // filter out ticks which are too far left or too far right which may result in text being cut off

      if (noData) {
        tickFormat = (d) => '';
      }
      let tickCall = d3
        .axisBottom(x)
        .tickSize(height)
        .tickValues(tickValues)
        .tickPadding(12)
        .tickFormat(tickFormat);

      if (that.customRange) {
        tickValuesRangeStart = start;
        tickValuesRangeEnd = end;
        if (timespan_days <= 15 && timespan_days > 7) {
          ticks = d3.timeDay.every(small_screen && timespan_days >= 6 ? 3 : 2);
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
        } else if (timespan_hours < 3) {
          ticks = d3.timeMinute.every(
            small_screen
              ? timespan_hours < 1
                ? 60
                : 30
              : timespan_hours < 1
              ? 30
              : 15
          );
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
        } else if (timespan_hours <= 24) {
          ticks = d3.timeHour.every(
            small_screen
              ? timespan_hours < 6
                ? 2
                : timespan_hours < 12
                ? 3
                : 6
              : timespan_hours < 6
              ? 1
              : timespan_hours < 12
              ? 2
              : 3
          );
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
          if (tickValues.length < 5 && timespan_hours < 6 && !small_screen) {
            tickValues = tickValues
              .map((d) => [
                new Date(new Date(d).setMinutes(d.getMinutes() - 30)),
                d,
                new Date(new Date(d).setMinutes(d.getMinutes() + 30)),
              ])
              .flat(2)
              .filter((d) => d < tickValuesRangeEnd)
              .filter((d) => {
                return (
                  x(d) >=
                    (timespan_days > 90 || (small_screen && timespan_days > 60)
                      ? 30
                      : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
                );
              }); // filter out ticks which are too far left or too far right which may result in text being cut off
          }
        } else if (timespan_days <= 7) {
          ticks = d3.timeDay.every(small_screen && timespan_days >= 6 ? 2 : 1);
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
          if (tickValues.length < 5 && !small_screen) {
            tickValues = tickValues
              .map((d) => [
                new Date(new Date(d).setHours(d.getHours() - 12)),
                d,
                new Date(new Date(d).setHours(d.getHours() + 12)),
              ])
              .flat(2)
              .filter((d) => d < tickValuesRangeEnd)
              .filter((d) => {
                return (
                  x(d) >=
                    (timespan_days > 90 || (small_screen && timespan_days > 60)
                      ? 30
                      : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
                );
              }); // filter out ticks which are too far left or too far right which may result in text being cut off
          }
        }

        const _locale = d3.timeFormatLocale(
          settings.Language.abbreviation === 'de' ? LOCALE_DE : LOCALE_EN
        );
        tickCall = d3
          .axisBottom(x)
          .tickSize(height)
          .tickValues(tickValues)
          .tickPadding(12)
          .tickFormat(
            timespan_days > 90 || (small_screen && timespan_days > 60)
              ? (d) => {
                  return _locale.format(small_screen ? '%b' : '%B')(d);
                }
              : timespan_days > 15
              ? (d) => {
                  return (
                    (settings.Language.abbreviation === 'de' ? 'KW' : 'CW') +
                    d3.timeFormat('%U')(d)
                  );
                }
              : timespan_days <= 1
              ? (d: Date) => {
                  return d3.timeFormat('%H:%M')(d);
                }
              : timespan_days < 7
              ? (d) => {
                  if (d.getHours() > 0 || d.getMinutes() > 0)
                    return _locale.format('%H:%M')(d);
                  else
                    return _locale.format(
                      !!settings.DateFormat &&
                        settings.DateFormat.formatString === 'MM/dd/yyyy'
                        ? '%m/%d'
                        : '%e-%m'
                    )(d);
                }
              : tickFormat
          );
      }

      return tickCall;
    }

    drawChart();
    setTimeout(() => this.updateChart(), 0);
  }

  public OnBrush(callback: (x_domain: any) => void) {
    this.brushCallback = callback;
  }

  public OnBrushReset(callback: (x_domain: any) => void) {
    this.brushResetCallback = callback;
  }

  public ResetBrush() {
    this.resetBrush(); // call to private member
  }
}

function treatAsUTC(date): number {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return +result;
}
function daysBetween(startDate, endDate): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

function hoursBetween(startDate, endDate): number {
  const millisecondsPerHour = 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerHour;
}

function minutesBetween(startDate, endDate): number {
  const millisecondsPerMinute = 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerMinute;
}

function minDate(a: Date, b: Date) {
  return a < b ? a : b;
}

function generateUniqueIdentifier() {
  const _number = Math.random(); // 0.9394456857981651
  _number.toString(36); // '0.xtis06h6'
  return _number.toString(36).substr(2, 9); // 'xtis06h6'
}

function generateCriticalGradient(
  ranges: ValueRange[],
  value,
  y_extent,
  critical,
  warning
) {
  ranges = ranges.filter(
    (range) =>
      range.type === ValueRangeType.green ||
      (range.min < value && range.max > value)
  );
  if (!critical && !warning) {
    return [
      {
        offset: '-100%',
        color: 'black',
      },
      {
        offset: '100%',
        color: 'black',
      },
    ];
  }
  let above_crit_offsets = [];
  let below_crit_offsets = [];

  const above_crit_range = ranges.find(
    (red_range) =>
      red_range.type ===
        (critical ? ValueRangeType.red : ValueRangeType.yellow) &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.max <= red_range.min
      )
  );

  const below_crit_range = ranges.find(
    (red_range) =>
      red_range.type ===
        (critical ? ValueRangeType.red : ValueRangeType.yellow) &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.min >= red_range.max
      )
  );
  const full_range = y_extent[1] - y_extent[0];
  const offset = full_range * 0.05;

  if (above_crit_range) {
    const gradient_start = above_crit_range.min - offset - y_extent[0];
    const gradient_end = above_crit_range.min + offset - y_extent[0];

    above_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: critical ? '#E1000F' : '#e8680c',
      },
    ];
  }

  if (below_crit_range) {
    const gradient_start = below_crit_range.max - offset - y_extent[0];
    const gradient_end = below_crit_range.max + offset - y_extent[0];

    below_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
    ];
  }
  return [
    {
      offset: '-100%',
      color: below_crit_range ? '#E1000F' : 'black',
    },
    ...below_crit_offsets,
    ...above_crit_offsets,
    {
      offset: '100%',
      color: above_crit_range ? '#E1000F' : 'black',
    },
  ];
}
