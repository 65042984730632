<div
  class="sensor-value"
  [class.ghost]="ghost"
  [class.asHeader]="asHeader"
  [class.critical]="!isInactive && isCritical"
  [class.warning]="isWarning"
  [class.inactive]="isInactive"
  [class.nodata]="isWorking && noData(telemetry)"
  [class.desktop]="isDesktop"
>
  <div [class.german]="IsGerman">
    <div class="sensor-value-data data" *ngIf="!IsInletOutletTemp">
      <i
        nz-icon
        *ngIf="isWorking && !isInactive"
        nzType="icons:status-check"
        class="svg-icon mini-svg-icon left-icon"
        [class.fillgreen]="asHeader && !noData(telemetry)"
      ></i>
      <i
        nz-icon
        *ngIf="!isInactive && isWarning"
        nzType="icons:status-exclamation-octagon"
        class="svg-icon mini-svg-icon left-icon"
        [class.fillyellow]="!asHeader && !asHealth"
      ></i>
      <i
        nz-icon
        *ngIf="isInactive"
        nzType="icons:cross-circle"
        class="svg-icon mini-svg-icon left-icon"
        [class.fillgrey]="!asHeader && !noData(telemetry)"
      ></i>
      <i
        nz-icon
        *ngIf="!isInactive && isCritical"
        nzType="icons:status-exclamation-triangle"
        class="svg-icon mini-svg-icon left-icon"
        [class.fillred]="!asHeader && !asHealth"
        [class.fillwhite]="asHeader || asHealth"
      ></i>
      <ng-container *ngIf="noData(telemetry)">
        {{ i18n.string("no_data") }}
      </ng-container>
      <ng-container *ngIf="!noData(telemetry)">
        <span
          *ngIf="
            telemetry.valueType !== 'minmax' &&
            telemetry.valueType !== 'boolean' &&
            telemetry.valueType !== 'flag' &&
            !this.telemetry.inactive == true
          "
          >{{ i18n.parse(Title) }}
          <span [class.margin-span]="!!Title" *ngIf="!isSteamTrap">{{
            telemetry.value.valStr
          }}</span></span
        >
        <span *ngIf="telemetry.valueType === 'minmax'"
          >{{ i18n.parse(Title) }}
          <ng-container *ngIf="!isSteamTrap && !batteryCurrentVal"
            >{{ " " }}{{ telemetry.value.valStr.min }} -
            <span [class.margin-span]="!!Title">{{
              telemetry.value.valStr.max
            }}</span>
          </ng-container>
          <ng-container *ngIf="!isSteamTrap && batteryCurrentVal"
            >{{ " " }}{{ telemetry.value.valStr.min }}
          </ng-container>
        </span>
        <span
          *ngIf="
            (telemetry.valueType === 'boolean' ||
              telemetry.valueType === 'flag') &&
            !this.telemetry.inactive == true
          "
          >{{ i18n.parse(Title) }}</span
        >
        <span class="tile-title" *ngIf="this.telemetry.inactive == true">{{
          i18n.string("inactive_status")
        }}</span>
      </ng-container>
    </div>
    <div
      class="sensor-value-data data inlet-outlet-data"
      *ngIf="IsInletOutletTemp"
    >
      <div class="sensor-value-data-row">
        <i
          nz-icon
          *ngIf="!InletWarning && !InletCritical"
          nzType="icons:status-check"
          class="svg-icon mini-svg-icon left-icon"
          [class.fillgreen]="!asHeader && !noData(telemetry)"
        ></i>
        <i
          nz-icon
          *ngIf="InletWarning && !InletCritical"
          nzType="icons:status-exclamation-octagon"
          class="svg-icon mini-svg-icon left-icon"
          [class.fillyellow]="!asHeader && !asHealth"
        ></i>
        <i
          nz-icon
          *ngIf="InletCritical"
          nzType="icons:status-exclamation-triangle"
          class="svg-icon mini-svg-icon left-icon"
          [class.fillred]="!asHeader && !asHealth"
          [class.fillwhite]="asHeader || asHealth"
        ></i>
        <ng-container *ngIf="noData(telemetry)">
          {{ i18n.string("no_data") }}
        </ng-container>
        <ng-container *ngIf="!noData(telemetry)">
          <span>{{ i18n.string("mean_inlet") }}: {{ InletTempString }} </span>
        </ng-container>
      </div>
      <div class="sensor-value-data-row">
        <i
          nz-icon
          *ngIf="!OutletWarning && !OutletCritical"
          nzType="icons:status-check"
          class="svg-icon mini-svg-icon left-icon"
          [class.fillgreen]="!asHeader && !noData(telemetry)"
        ></i>
        <i
          nz-icon
          *ngIf="OutletWarning && !OutletCritical"
          nzType="icons:status-exclamation-octagon"
          class="svg-icon mini-svg-icon left-icon"
          [class.fillyellow]="!asHeader && !asHealth"
        ></i>
        <i
          nz-icon
          *ngIf="OutletCritical"
          nzType="icons:status-exclamation-triangle"
          class="svg-icon mini-svg-icon left-icon"
          [class.fillred]="!asHeader && !asHealth"
          [class.fillwhite]="asHeader || asHealth"
        ></i>
        <ng-container *ngIf="noData(telemetry)">
          {{ i18n.string("no_data") }}
        </ng-container>
        <ng-container *ngIf="!noData(telemetry)">
          <span>{{ i18n.string("mean_outlet") }}: {{ OutletTempString }} </span>
        </ng-container>
      </div>
    </div>

    <div class="sensor-value-time detail-bold">
      <i
        nz-icon
        nzType="icons:refresh"
        class="svg-icon mini-svg-icon"
        [class.fillblackcoral]="
          !isCritical && !isWarning && !asHeader && !noData(telemetry)
        "
        [class.fillwhite]="isCritical"
      ></i>
      <span *ngIf="validTimeValue(telemetry.Timestamp)" class="align-right"
        >{{ i18n.string("ago1") }}{{ minutesToNow(telemetry.Timestamp)
        }}{{ i18n.string("ago2") }}</span
      >
      <span *ngIf="!validTimeValue(telemetry.Timestamp)" class="align-right">{{
        i18n.string("never_checked")
      }}</span>
    </div>
  </div>
  <div
    class="battery-indicator"
    [class.nodata]="noData(telemetry)"
    #battery
    *ngIf="IsBattery && !asHeader"
  >
    <div
      class="charge-blob"
      *ngFor="let blob of Range(Numblobs)"
      [class.filled]="blob <= ChargingStatus"
    ></div>
  </div>
  <div *ngIf="lineColor" [class]="'line ' + lineColor"></div>
</div>
