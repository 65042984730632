<div class="splash-screen">
  <div class="reveal" *ngIf="!error">
    <i nz-icon nzType="icons:loading" class="svg-icon fillwhite"></i>
  </div>
  <ng-container *ngIf="error">
    <h1>Something went wrong. :(
    </h1>
    <a href=".">Retry</a>
  </ng-container>
</div>
