import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { UiService } from '../services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  constructor(private router: Router, private ui: UiService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.ui.IsMobile || this.ui.IsTablet) {
      return true;
    }

    let id = parseInt(state.url.substr(state.url.lastIndexOf('/') + 1), 10);
    if (Number.isNaN(id)) id = -1;
    // not mobile, redirect to asset page or dashboard page
    if (id >= 0) this.router.navigate(['asset', id, -1]);
    else this.router.navigate(['home']);
    return false;
  }
}
