import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent implements OnInit {
  @Input() label: string = '';
  @Input() key: string = '';
  @Input() value: any;
  @Input() optional: boolean = false;
  @Output() onchange = new EventEmitter<string>();

  constructor(public i18n: I18nService) {}

  ngOnInit() {}

  onChange(value: string) {
    this.onchange.emit(value);
  }
}
