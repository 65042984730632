import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() optional: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string;

  @Input() options: string[];
  @Input() selected: string;

  @Output() selectedChange = new EventEmitter<string>();

  constructor(public i18n: I18nService) {}

  ngOnInit() {}

  onChange(value: string) {
    this.selectedChange.emit(value);
  }
}
