import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Location } from '../../model/location';
import { OTObject } from '../../model/object';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';

export class ObjectFilter {
  searchTerm: string = '';

  public filterList(list: OTObject[]): filterType {
    return list.filter((item) => {
      return item.objectName
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase());
    });
  }
}

type filterType = OTObject[] | Location[];
@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'expanded',
        style({
          width: '100%',
        })
      ),
      state(
        'collapsed',
        style({
          width: '113px',
        })
      ),
      transition('collapsed => expanded', [
        animate('500ms cubic-bezier(0.15, 0, 0.25, 1)'),
      ]),
      transition('expanded => collapsed', [
        animate('250ms cubic-bezier(0.15, 0, 0.25, 1)'),
      ]),
    ]),
  ],
})
export class SearchFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() filter: ObjectFilter = new ObjectFilter();
  @Input() sidebar: boolean = false;
  @Input() onlysearch: boolean = false;
  @Input() notificationsFilter: boolean = false;
  @Input() plantsFilter: boolean = false;
  @Input() plantFilterStatus = null;
  @Input() countryOrRegionSelected: number = null;
  @Input() plantID: number = null;
  @Output() click = new EventEmitter<void>();
  public collapsed: boolean = true;

  @ViewChild('searchInput') searchInput: ElementRef;

  filter_open;
  filterCount = 0;
  plantSubscription = null;
  lastSearchTerm = '';

  constructor(
    public router: Router,
    public assetsService: AssetsService,
    public changeRef: ChangeDetectorRef,
    public i18n: I18nService
  ) {}

  ngOnInit() {}

  checkUpdates() {
    setTimeout(() => this.changeRef.detectChanges());
  }

  ngAfterViewInit(): void {
    if (this.sidebar) {
      fromEvent(this.searchInput.nativeElement, 'input')
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map((event: Event) => {
            const target = event.target as HTMLInputElement;
            return target.value;
          })
        )
        .subscribe((searchTerm) => {
          this.assetsService.updateSearchTerm(searchTerm);
        });
    }
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
    this.plantSubscription = this.assetsService.subscribeToPlants(
      () => (this.filterCount = this.assetsService.FilterSettingsCount)
    );
    if (!!this.searchInput)
      this.searchInput.nativeElement.value = this.assetsService.getSearchTerm();
  }

  ngOnDestroy() {
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
  }

  onClick() {
    this.click.emit();
    setTimeout(() => this.router.navigate(['filter']));
  }
  expand() {
    this.collapsed = false;
    this.lastSearchTerm = this.assetsService.getSearchTerm();
    this.searchInput.nativeElement.value = this.lastSearchTerm;
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  collapse() {
    this.collapsed = true;
    this.assetsService.updateSearchTerm(this.lastSearchTerm);
  }

  search(collapse: boolean = true) {
    this.collapsed = collapse;
    this.assetsService.updateSearchTerm(this.searchInput.nativeElement.value);
  }

  clearSearchTerm() {
    this.searchInput.nativeElement.value = '';
    this.search();
  }

  clearFilter(event) {
    this.assetsService.clearFilter();
    event.stopPropagation();
  }
}
