<div class="content content-more-margin desktop-wide">
  <h1>{{ i18n.string("data_protection_title") }}</h1>

  <h3>{{ i18n.string("general_title") }}</h3>

  <p *ngIf="!showUSCountryPage">{{ i18n.string("general_content") }}</p>
  <p *ngIf="showUSCountryPage">{{ i18n.string("general_content_us") }}</p>

  <p>{{ i18n.string("general_content1") }}</p>

  <p>{{ i18n.string("general_content2") }}</p>

  <h3>{{ i18n.string("collection_title") }}</h3>

  <p *ngIf="!showUSCountryPage">{{ i18n.string("collection_content") }}</p>
  <p *ngIf="showUSCountryPage">{{ i18n.string("collection_content_us") }}</p>

  <p>{{ i18n.string("collection_content1") }}</p>

  <p>{{ i18n.string("collection_content2") }}</p>

  <p>{{ i18n.string("collection_content3") }}</p>

  <p>{{ i18n.string("collection_content4") }}</p>

  <p>{{ i18n.string("collection_content5") }}</p>

  <p>{{ i18n.string("collection_content6") }}</p>

  <p>{{ i18n.string("collection_content7") }}</p>

  <p>{{ i18n.string("collection_content8") }}</p>

  <p>{{ i18n.string("collection_content9") }}</p>

  <h3>{{ i18n.string("further_processing_title") }}</h3>

  <h4>{{ i18n.string("app_use_title") }}</h4>

  <p>{{ i18n.string("further_processing_content") }}</p>

  <p>{{ i18n.string("further_processing_content1") }}</p>

  <h3>{{ i18n.string("consent_title") }}</h3>

  <p>
    <span class="consent-content">{{ i18n.string("consent_content") }}</span>
  </p>

  <p>{{ i18n.string("consent_content1") }}</p>

  <p>{{ i18n.string("consent_content2") }}</p>

  <p>{{ i18n.string("further_processing_content1") }}</p>

  <p>
    <span class="consent-content">{{ i18n.string("consent_content3") }}</span>
    <span>{{ i18n.string("consent_content4") }}</span>
    <span class="consent-content">{{ i18n.string("consent_content5") }}</span>
    <span>{{ i18n.string("consent_content6") }}</span>
  </p>

  <p>{{ i18n.string("consent_content7") }}</p>

  <h3>{{ i18n.string("cookies_title") }}</h3>

  <p>{{ i18n.string("cookies_content") }}</p>

  <p>{{ i18n.string("cookies_content1") }}</p>

  <h3>{{ i18n.string("rights_title") }}</h3>
  <p>{{ i18n.string("rights_content") }}</p>

  <p class="list-item">{{ i18n.string("rights_content1") }}</p>

  <p class="list-item">{{ i18n.string("rights_content2") }}</p>

  <p class="list-item">{{ i18n.string("rights_content3") }}</p>

  <p class="list-item">{{ i18n.string("rights_content4") }}</p>

  <p class="list-item">{{ i18n.string("rights_content5") }}</p>

  <h4>{{ i18n.string("right_to_object_title") }}</h4>

  <p class="body-bold">{{ i18n.string("right_to_object_content") }}</p>

  <p>{{ i18n.string("right_to_object_content1") }}</p>

  <p *ngIf="!showUSCountryPage">{{ i18n.string("right_to_object_content2") }}</p>
  <p *ngIf="showUSCountryPage">{{ i18n.string("right_to_object_content2_us") }}</p>

  <h3>{{ i18n.string("sharing_data_title") }}</h3>

  <p>{{ i18n.string("sharing_data_content") }}</p>

  <p>{{ i18n.string("sharing_data_content1") }}</p>

  <p>{{ i18n.string("sharing_data_content2") }}</p>

  <p>{{ i18n.string("sharing_data_content3") }}</p>

  <p>{{ i18n.string("sharing_data_content4") }}</p>

  <p>{{ i18n.string("sharing_data_content5") }}</p>

  <div class="back-btn" *ngIf="!isAuth">
    <app-nav-button
      [darksecondary]="true"
      svg_icon="icons:arrow-left"
      (click)="gotoLogin()"
      >{{ i18n.string("back") }}
    </app-nav-button>
  </div>
  <app-scroll-to-top></app-scroll-to-top>
</div>
