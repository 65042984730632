<!-- asset/plant header -->
<div class="header-wrapper">
  <ng-container *ngIf="!backBtn">
    <a aria-label="Home" [routerLink]="['/home']"
      ><img id="loctite-logo" src="assets/images/LoctitePulseLogo.svg"
    /></a>
  </ng-container>
  <ng-container *ngIf="backBtn"
    ><a class="back-btn" (click)="back()" aria-label="label">
      <i nz-icon nzType="icons:arrow-left" class="svg-icon"></i>
      <span class="body-bold">{{ backTo }}</span>
    </a></ng-container
  >
  <div class="header-content">
    <span class="body-bold">{{ title }}</span>
  </div>
  <app-tab-menu [header]="true"></app-tab-menu>
  <!-- banner Notification -->
  <div *ngIf="showBannerNotification" class="banner-notification-hint">
    <div class="inner-banner-notification">
      <div class="inner-message">
        <i
          nz-icon
          nzType="icons:exclamation-mark-filled"
          class="svg-icon fillwhite"
        >
        </i>
        {{ i18n.string("system_maintenance") }}
      </div>
      <div class="exit-icon">
        <button (click)="closeBannerNotification()">
          <i
            nz-icon
            [nzType]="'icons:exit-white'"
            class="svg-icon fillwhite"
          ></i>
        </button>
      </div>
    </div>
    <div class="banner-notification-message">
      <p>{{ i18n.string("maintenance_messsage") }}</p>
      <button class="underline" (click)="closeBannerNotification()">
        {{ i18n.string("understood") }}
      </button>
    </div>
  </div>
</div>
