import { Location as queryState } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '../../model/location';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-plant-overview',
  templateUrl: './plant-overview.component.html',
  styleUrls: ['./plant-overview.component.scss'],
})
export class PlantOverviewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public plant: Location = null;
  public loading;
  public empty_results;
  public getState = null;
  public filterStatus = null;
  plantSubscription = null;

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private router: Router,
    private ui: UiService,
    private changeRef: ChangeDetectorRef,
    private queryState: queryState,
    public i18n: I18nService
  ) {}

  ngOnInit() {
    this.getState = this.queryState.getState();
    this.refresh();
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  ngOnDestroy() {
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
  }

  private refresh() {
    this.loading = true;
    const plantID = +this.route.snapshot.paramMap.get('id');
    this.filterStatus = this.getState['status'];

    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
    this.plantSubscription = this.assetsService.subscribeToPlants(
      (result: any) => {
        this.loading = false;
        const new_plant = result.plants
          .map((plant) => plant.findLocation(plantID))
          .find((plant) => !!plant && plant.objectID === plantID);
        if (!!new_plant) {
          this.plant = new_plant;
          this.empty_results = false;
        } else {
          this.empty_results = true;
        }
        this.changeRef.detectChanges();
      }
    );
  }

  sortCritFirst(buildings) {
    return buildings.sort(function (x, y) {
      return x.critical === y.critcial ? 0 : x.critical ? -1 : 1;
    });
  }

  toLowerItem(building) {
    this.router.navigate(['plant', building.objectID]);
  }
}
