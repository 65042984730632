import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from './../../cc-framework/services/authentication.service';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event) => {},
        (err) => {
          if (err.status === 0) {
            console.error(err);
            // we assume for now that this is sufficient to check for no-connection-error
            // this.state.dispatch(new ConnectionError());
            // this.state.dispatch(
            //   new OpenSnackbar({ snackbar: OfflineSnackBarComponent })
            // );
          } else if (err.status === 401) {
            if (this.auth.isLoggedIn()) {
              this.auth.logout();
              this.router.navigate(['login']);
            }
            /*
            const temp_url_parts = req.url.split('/');
            if (temp_url_parts[temp_url_parts.length - 1] !== 'Auth') {
              // we do not want to trigger this on Authentication, because a failure there will lead to LoginError Action anyway
              this.state.dispatch(new UnauthorizedError());
              this.state.dispatch(new LoginPrompt());
            }
            */
          } else {
            console.error(err);
            /* this.state.dispatch(
              new OpenSnackbar({ snackbar: SomethingWrongSnackBarComponent })
            );*/
          }
        }
      )
    );
  }
}
