import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Location } from '../../model/location';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-asset-nav-button',
  templateUrl: './asset-nav-button.component.html',
  styleUrls: ['./asset-nav-button.component.scss'],
})
export class AssetNavButtonComponent implements OnInit {
  @Input() fa_icon = '';
  @Input() show_icon = true;
  @Input() svg_icon = '';
  @Input() svg_icon_right = 'icons:arrow-right';
  @Input() href = undefined;
  @Input() asset: Location = null;
  @Input() sensorID = null;
  @Input() blackBG = false;
  @Input() blackCoral = false;
  @Input() center = false;
  @Input() buttonlink = false;
  @Input() bypassConnectedStatus = false;
  @Input() InactiveStatus = false;
  @Input() WarningStatus = false;
  @Input() CriticalStatus = false;
  @Output() click: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    public router: Router,
    private ui: UiService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.ui.OnOrientationChange.subscribe(() =>
      this.changeDetectorRef.detectChanges()
    );
  }

  get SensorID(): number {
    return this.sensorID ? this.sensorID : -1;
  }

  get Connected(): boolean {
    return (
      this.bypassConnectedStatus || (!!this.asset && this.asset.isConnected)
    );
  }

  get Warning(): boolean {
    return this.asset ? this.asset.warning : this.WarningStatus;
  }

  get Critical(): boolean {
    return this.asset ? this.asset.critical : this.CriticalStatus;
  }

  get InActive(): boolean {
    return this.asset ? this.asset.inactive : this.InactiveStatus;
  }

  get IsArchived(): boolean {
    return this.asset ? this.asset.isArchived : false;
  }

  onClick(e) {
    if (!this.href) e.preventDefault();
    if (!!this.asset && !this.asset.isConnected && !this.bypassConnectedStatus)
      return;
    this.click.emit();
    // the following is absolutely necessary because otherwise in map view we navigate before we close the overlay, which is quite ugly
    setTimeout(() => {
      if (this.asset) {
        this.router.navigate(['asset', this.asset.objectID, this.SensorID]);
      }
    });
  }
}
