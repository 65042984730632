import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';

import { TabBarItemComponent } from './tab-bar-item/tab-bar-item.component';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit, AfterViewInit {
  activeTab: number = 0;

  @Input() set ActiveTab(activeTab: number) {
    this.onTabSelect(activeTab, true);
  }
  @Input() opaque: boolean = false;
  @Input() icon: boolean = false;
  public _disabled: boolean = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    if (!!this.items) {
      this.items.toArray().forEach((component, index) => {
        component.disabled = disabled;
        component.changeRef.detectChanges();
      });
    }
  }
  @Input() fullWidth: boolean = false;
  @Output() tabChanged: EventEmitter<number> = new EventEmitter<number>();
  @ContentChildren(TabBarItemComponent)
  items: QueryList<TabBarItemComponent>;
  init;
  constructor(public changeRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.init = true;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.items.toArray().forEach((component, index) => {
        component.index = index;
        component.tabBarReference = this;
        component.preIsActive = index === this.activeTab;
        component.isActive = index === this.activeTab;
        component.opaque = this.opaque;
        component.disabled = this._disabled;
        component.changeRef.detectChanges();
      });
    });
  }

  onTabSelect(index: number, noemit = false) {
    const move = this.init && noemit ? '0' : '' + (index - this.activeTab);
    this.init = false;
    this.activeTab = index;
    setTimeout(() =>
      this.items.toArray().forEach((component, _index) => {
        if (component.isActive) {
          component.moveMarker = move;
        }
        component.preIsActive = _index === this.activeTab;
        component.inMovement = true;
        component.changeRef.detectChanges();
        setTimeout(() => {
          component.isActive = _index === this.activeTab;
          component.moveMarker = '0';
          component.inMovement = false;
          component.changeRef.detectChanges();
        }, 250);
      })
    );
    if (!noemit) {
      setTimeout(() => {
        this.tabChanged.emit(index);
      }, 250);
    }
  }
}
