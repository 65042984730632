export class HistoryItem {
  type: 'notification' | 'documentation';
  message: string;
  timestamp: string;
  display: 'default' | 'working' | 'critical' | 'warning' | 'inactive';
  details: DocumentationDetails;
  open?: boolean = false;
  sensorID: number;
  sensorName: string;
}

export class DocumentationDetails {
  documentationType: 'repair' | 'fixed' | 'false_alert' | 'other';
  note: string;
  photos: { thumbnail: string; picture: string }[];
  files: { filename: string; url: string }[];
}
