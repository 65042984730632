import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-change-pw',
  templateUrl: './change-pw.component.html',
  styleUrls: ['./change-pw.component.scss'],
})
export class ChangePwComponent implements OnInit {
  // page state
  isLoading = false;
  error = false;
  isOffline = false;

  constructor(
    private ui: UiService,
    private router: Router,
    private i18n: I18nService
  ) {}

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  ngOnInit() {
    this.ui.onNavBack(() => {
      this.router.navigate(['home'], { replaceUrl: true });
    });
    this.ui.setBackText(this.i18n.string('home'));
  }
}
