<div id="sections-card">
  <div class="section" id="overview">
    <div class="section-header" (click)="overviewCollapsed = !overviewCollapsed">
      <i nz-icon nzType="icons:repair_overview" class="nofill svg-icon section-icon"></i>
      <span class="section-title"> {{ i18n.string('repair_overview') }} </span>
      <i *ngIf="overviewCollapsed" nz-icon nzType="icons:plus" class="svg-icon accordion-icon"></i>
      <i *ngIf="!overviewCollapsed" nz-icon nzType="icons:minus" class="svg-icon accordion-icon"></i>
    </div>
    <div *ngIf="!overviewCollapsed" class="section-content">
      <ng-container *ngIf="applicationType==='smart-pipe_external'">
        <img src="assets/images/repair_instructions_2.png">
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-pipe_composite'">
        <img src="assets/images/repair_instructions_1.png">
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-flange_silicone'">
        <img src="assets/images/repair_instructions_4.png">
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-flange_composite'">
        <img src="assets/images/repair_instructions_5.png">
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-tank'">
        <img src="assets/images/repair_instructions_5.png">
      </ng-container>
      <p class="bold header">Before you start:</p>
      <p>Verify that your personal qualification, training, and experience are suitable for the application.<br>
        <br>
        Familiarize yourself with the overall repair process and the work steps in detail.<br>
        <br>
        Plan the process steps in advance and be aware of the following:<br>
        &nbsp;• accessibility of repair area<br>
        &nbsp;• required tools, supplies, and equipment<br>
        &nbsp;• environmental conditions.<br>
      </p>
      <p class="bold icon">
        <i nz-icon nzType="icons:manual" class="svg-icon nofill"></i>
        Please refer to the Operation Manual for further information.
      </p>
      <p class="bold icon">
        <i nz-icon nzType="icons:info" class="svg-icon"></i>
        In the event of an incident involving a leak, the repair site must first be isolated and offline from the
        source. The repair site must be clean and safe to perform the below operations.
      </p>
      <p class="bold icon">
        <i nz-icon nzType="icons:info" class="svg-icon"></i>
        Do not connect or disconnect sensors with the IoT unit powered ON in hazardous locations. Do not attempt to
        perform any repairs on the IoT unit as it is non-serviceable.
      </p>
    </div>

  </div>
  <div class="section" id="instructions">
    <div class="section-header" (click)="instructionsCollapsed = !instructionsCollapsed">
      <i nz-icon nzType="icons:tool" class="svg-icon section-icon"></i>
      <span class="section-title"> {{ i18n.string('step_by_step_instructions') }} </span>
      <i *ngIf="instructionsCollapsed" nz-icon nzType="icons:plus" class="svg-icon accordion-icon"></i>
      <i *ngIf="!instructionsCollapsed" nz-icon nzType="icons:minus" class="svg-icon accordion-icon"></i>
    </div>
    <div *ngIf="!instructionsCollapsed" class="section-content">
      <ng-container *ngIf="applicationType==='smart-pipe_external'">
        <img src="assets/images/repair_instructions_3.png">
        <p>
          1. <span class="bold">Place the IoT unit in Sleep Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold for 10 seconds. After 5 seconds, two
          beeps will be emitted, indicating a status message was sent. Continue to hold the magnet until five beeps are
          emitted. After five beeps, remove the magnet. The unit is now in sleep mode.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          2. Disconnect the sensor chain from the IoT unit
        </p>
        <p>
          3. Remove the entire sensor chain from the pipe(s) and discard according to local and federal regulations.
        </p>
        <p>
          4. Repair the leak
        </p>
        <p>
          5. Thoroughly clean the entire length of pipe to be monitored and ensure no hydrocarbons are present.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Residual hydrocarbons can cause the new sensor to respond, causing a false alarm. This will require early
          replacement of the sensor and containment
        </p>
        <img src="assets/images/repair_instructions_2.png">
        <p>
          6. Run the new sensor chain along the bottom of the pipe, ensuring the serial number is facing away from the
          pipe.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Improper orientation will cause decreased sensor performance.
        </p>
        <p>
          7. Record the sensor serial numbers in the Loctite® Installation App.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          8. Connect the sensor cable to the IoT unit and ensure the sensors are mapped to the correct IoT unit in the
          Loctite Pulse Installation Agent.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          9.<span class="bold"> Wake the IoT unit and place in Operational Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold in place until two beeps are emitted
          from the unit. The beeps indicate the unit has exited sleep mode and entered operational mode
        </p>
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-pipe_composite'">
        <img src="assets/images/repair_instructions_3.png">
        <p>
          1. <span class="bold">Place the IoT unit in Sleep Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold for 10 seconds. After 5 seconds, two
          beeps will be emitted, indicating a status message was sent. Continue to hold the magnet until five beeps are
          emitted. After five beeps, remove the magnet. The unit is now in sleep mode.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          2. Disconnect the sensors from the junction box.
        </p>
        <img src="assets/images/repair_instructions_6.png">
        <p>
          3. Remove the pipe repair system and outer hull by mechanical means, e.g., grinding or abrading
        </p>
        <p>
          4. Complete the pipe repair process. Please refer to the Loctite® Composite Pipe Repair “Method Statement for
          Installers” for further details.
        </p>
        <img src="assets/images/repair_instructions_7.png">
        <p>
          5. Record the sensor serial numbers in the Loctite® Installation App. Wrap a sensor around the pipe at the
          outer edge of the repair wrap with the serial number of the sensor against the pipe. Bond the sensor ends with
          Loctite® 3090 to secure it in place. Repeat this with another sensor at the opposite end of the repair. Bond a
          third sensor aong the axis of the pipe between the previously mounted sensors. Make sure all cables are
          directed towards axial ends.
        </p>
        <img src="assets/images/repair_instructions_8.png">
        <p>
          6. Place the textile around the wrap, extending 20mm past the pipe repair and sensors on each end. Secure the
          ends of the textile with tape. Secure the cables with tape on the pipe.
        </p>
        <img src="assets/images/repair_instructions_6.png">
        <p>
          7. Wrap the outer hull with one layer of composite pipe repair. Follow the procedure of the method statement.
          Follow the curing instructions
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          8. Connect the sensor cables to the IoT unit and ensure the sensors are mapped to the correct IoT unit
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          9. <span class="bold">Wake the IoT unit and place in Operational Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold in place until two beeps are emitted
          from the unit. The beeps indicate the unit has exited sleep mode and entered operational mode
        </p>
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-flange_silicone'">
        <img src="assets/images/repair_instructions_3.png">
        <p>
          1. <span class="bold">Place the IoT unit in Sleep Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold for 10 seconds. After 5 seconds, two
          beeps will be emitted, indicating a status message was sent. Continue to hold the magnet until five beeps are
          emitted. After five beeps, remove the magnet. The unit is now in sleep mode.
        </p>
        <img src="assets/images/repair_instructions_9.png">
        <p>
          2. <span class="bold">Single sensor system:</span> disconnect the sensor from the IoT unit.<br>
          <span class="bold">Multi-sensor system:</span> disconnect the sensor from the junction box.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          3. <span class="bold">Loctite 5075 Containment:</span><br>
          &nbsp;• Using a knife, cut the silicone wrap and remove it from the flange.<br>
          &nbsp;• Remove the sensor from the flange and discard according to local and federal regulations
        </p>
        <p>
          4. Repair the leak
        </p>
        <p>
          5. Thoroughly clean the entire length of pipe to be monitored and ensure no hydrocarbons are present.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Residual hydrocarbons can cause the new sensor to respond, causing a false alarm. This will require early
          replacement of the sensor and containment
        </p>
        <p>
          6. Record the sensor serial numbers in the Loctite® Pulse Installation App.
        </p>
        <img src="assets/images/repair_instructions_10.png">
        <img src="assets/images/repair_instructions_11.png">
        <p>
          7. <span class="bold">Horizontal Flange:</span> Insert the sensor into the flange gap in the orientation shown
          in the images. Ensure the
          serial number facing away from the bolts. Run the sensor cable so that it exits the flange near the twelve
          o’clock position.<br>
          <br>
          <span class="bold">Vertical Flange:</span> Insert the Sensor into the flange gap with the sensor serial number
          facing away from the
          bolts. The sensor should make approximately one full revolution around the flange. If possible, run the sensor
          cable so that it exits the flange to the top.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Improper orientation will cause decreased sensor performance.
        </p>
        <img src="assets/images/repair_instructions_4.png">
        <p>
          8. <span class="bold">Loctite® 5075 Containment:</span><br>
          Tightly wrap the Loctite® 5075 around the flange with minimum two full wraps, ensuring no air gaps are
          present, except the small opening for the sensor cable.

        </p>
        <img src="assets/images/repair_instructions_4.png">
        <p>
          9. <span class="bold">Single Sensor System:</span> Connect the sensor cable to the IoT unit and ensure the
          sensor is mapped to the
          correct IoT unit.<br>
          <br>
          <span class="bold">Multi-Sensor System:</span> Connect the sensor cable to the proper junction box channel and
          ensure the sensor is
          mapped to the correct IoT unit.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          10. <span class="bold">Wake the IoT unit and place in Operational Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold in place until two beeps are emitted
          from the unit. The beeps indicate the unit has exited sleep mode and entered operational mode
        </p>
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-flange_composite'">
        <img src="assets/images/repair_instructions_3.png">
        <p>
          1. <span class="bold">Place the IoT unit in Sleep Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold for 10 seconds. After 5 seconds, two
          beeps will be emitted, indicating a status message was sent. Continue to hold the magnet until five beeps are
          emitted. After five beeps, remove the magnet. The unit is now in sleep mode.
        </p>
        <img src="assets/images/repair_instructions_9.png">
        <p>
          2. <span class="bold">Single sensor system:</span> disconnect the sensor from the IoT unit.<br>
          <span class="bold">Multi-sensor system:</span> disconnect the sensor from the junction box.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          3. <span class="bold">Loctite Composite Containment:</span><br>
          &nbsp;• Remove the composite by mechanical means, e.g., grinding or abrading<br>
          &nbsp;• Remove the sensor from the flange and discard according to local and federal regulations
        </p>
        <p>
          4. Repair the leak
        </p>
        <p>
          5. Thoroughly clean the entire length of pipe to be monitored and ensure no hydrocarbons are present.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Residual hydrocarbons can cause the new sensor to respond, causing a false alarm. This will require early
          replacement of the sensor and containment
        </p>
        <p>
          6. Record the sensor serial numbers in the Loctite® Pulse Installation App.
        </p>
        <img src="assets/images/repair_instructions_10.png">
        <img src="assets/images/repair_instructions_11.png">
        <p>
          7. <span class="bold">Horizontal Flange:</span> Insert the sensor into the flange gap in the orientation shown
          in the images. Ensure the
          serial number facing away from the bolts. Run the sensor cable so that it exits the flange near the twelve
          o’clock position.<br>
          <br>
          <span class="bold">Vertical Flange:</span> Insert the Sensor into the flange gap with the sensor serial number
          facing away from the
          bolts. The sensor should make approximately one full revolution around the flange. If possible, run the sensor
          cable so that it exits the flange to the top.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Improper orientation will cause decreased sensor performance.
        </p>
        <img src="assets/images/repair_instructions_5.png">
        <p>
          8. <span class="bold">Loctite® Composite Containment:</span>
          Wrap the outer hull with one layer of composite pipe repair. Allow the wrap to fully cure. Follow the
          procedure of the method statement.
        </p>
        <img src="assets/images/repair_instructions_4.png">
        <p>
          9. <span class="bold">Single Sensor System:</span> Connect the sensor cable to the IoT unit and ensure the
          sensor is mapped to the
          correct IoT unit.<br>
          <br>
          <span class="bold">Multi-Sensor System:</span> Connect the sensor cable to the proper junction box channel and
          ensure the sensor is
          mapped to the correct IoT unit.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          10. <span class="bold">Wake the IoT unit and place in Operational Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold in place until two beeps are emitted
          from the unit. The beeps indicate the unit has exited sleep mode and entered operational mode
        </p>
      </ng-container>
      <ng-container *ngIf="applicationType==='smart-tank'">
        <img src="assets/images/repair_instructions_3.png">
        <p>
          1. <span class="bold">Place the IoT unit in Sleep Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold for 10 seconds. After 5 seconds, two
          beeps will be emitted, indicating a status message was sent. Continue to hold the magnet until five beeps are
          emitted. After five beeps, remove the magnet. The unit is now in sleep mode.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          2. Disconnect the sensor chain from the IoT unit
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          3. Remove the sensor from the tank and discard according to local and federal regulations.
        </p>
        <p>
          4. Repair the leak
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          5. Thoroughly clean the entire length of pipe to be monitored and ensure no hydrocarbons are present.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Residual hydrocarbons can cause the new sensor to respond, causing a false alarm. This will require early
          replacement of the sensor and containment
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          6. Run the new sensor chain along the bottom of the pipe, ensuring the serial number is facing away from the
          pipe.
        </p>
        <p class="bold icon">
          <i nz-icon nzType="icons:info" class="svg-icon"></i>
          Improper orientation will cause decreased sensor performance.
        </p>
        <p>
          7. Record the sensor serial numbers in the Loctite® Installation App.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          8. Connect the sensor cable to the IoT unit and ensure the sensors are mapped to the correct IoT unit in the
          Loctite Pulse Installation Agent.
        </p>
        <img src="assets/images/repair_instructions_3.png">
        <p>
          9. <span class="bold">Wake the IoT unit and place in Operational Mode:</span><br>
          Place a magnet on the square indent next to the Loctite® logo and hold in place until two beeps are emitted
          from the unit. The beeps indicate the unit has exited sleep mode and entered operational mode
        </p>
      </ng-container>
    </div>
  </div>
</div>