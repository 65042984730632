<ng-container *ngIf="Scrollable">
  <div
    id="graphFixed"
    [class.pie]="IsPie"
    class="diagram-date"
    #chartFixed
  ></div>
  <ng-scrollbar
    track="horizontal"
    style="height: 320px"
    visibility="always"
    #scrollable
  >
    <div
      id="graph"
      [class.pie]="IsPie"
      class="diagram-date"
      (window:resize)="onResize()"
      #chart
    >
      <div id="dummy">
        <!-- this allows us to find the correct width for the chart -->
      </div>
    </div>
  </ng-scrollbar>
</ng-container>
<ng-container *ngIf="!Scrollable">
  <div
    id="graph"
    [class.pie]="IsPie"
    class="diagram-date"
    (window:resize)="onResize()"
    #chart
  >
    <div id="dummy">
      <!-- this allows us to find the correct width for the chart -->
    </div>
  </div>
</ng-container>
<button
  class="reset-btn"
  *ngIf="hasResetButton && (isBrush || customRange || displayResetBtn)"
  (click)="onReset()"
>
  <i nz-icon nzType="icons:refresh" class="svg-icon fillwhite"></i>
  Reset View
</button>
