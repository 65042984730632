import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Notification } from '../../model/object';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
  animations: [
    trigger('activeTab', [
      state(
        '0',
        style({
          left: '0%',
        })
      ),
      state(
        '1',
        style({
          left: '33%',
        })
      ),
      state(
        '2',
        style({
          left: '66%',
        })
      ),
      state(
        '3',
        style({
          left: '150%',
        })
      ),
      transition('0 <=> 1', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('0 <=> 2', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('0 <=> 3', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('1 <=> 2', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('1 <=> 3', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('2 <=> 3', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
    ]),
  ],
})
export class TabMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() header: boolean = false;

  public activeTab = null;
  public loading = false;
  public notificationCount = 0;
  public notificationsSubscription = null;
  notifications: Notification[] = [];
  public showEdgeDeviceNotifications = false;

   get NumEquipmentNotifications(): number {
    return this.notifications.filter((item) => !item.isEdgeDevice).length;
  }
  get NumEdgeDeviceNotifications(): number {
    return this.notifications.filter((item) => item.isEdgeDevice).length;
  }
  // get IsMobile(): boolean {
  //   return this.ui.IsMobile;
  // }
  // get IsTablet(): boolean {
  //   return this.ui.IsTablet;
  // }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  routerEvents: Subscription;
  constructor(
    private assetsService: AssetsService,
    public router: Router,
    private ui: UiService,
    public i18n: I18nService
  ) {}

  ngOnInit() {
    this.activeTab = this.ui.TabIndex;
    this.ui.OnTabIndexChange.subscribe((idx) =>
      setTimeout(() => {
        if ( !this.isDesktop && idx === 2) idx = -1;
        this.activeTab = idx;
      })
    );
    this.ui.OnOrientationChange.subscribe(() =>
      setTimeout(() => {
        if ( !this.isDesktop && this.activeTab === 2) this.activeTab = -1;
        else if ( this.isDesktop && this.activeTab === -1) this.activeTab = 2;
      })
    );
    this.refresh();
  }

  ngAfterViewInit() {}

  private refresh() {
    this.loading = true;
    // this.assetsService.subscribeToNotificationsCount((count: number) => {
    //   this.loading = false;
    //   this.notificationCount = count;
    // });

    this.notificationsSubscription =
      this.assetsService.subscribeToNotifications(
        (result: { notifications: Notification[]; filtered: boolean }) => {
          setTimeout(() => {
            this.notifications = result.notifications.filter(item => item.notificationStatus !== 'repaired');
            this.notificationCount = result.notifications.length;
          });
        }
      );
  }

  ngOnDestroy() {
    if (this.routerEvents) this.routerEvents.unsubscribe();
    if (!!this.notificationsSubscription)
      this.notificationsSubscription.unsubscribe();
  }

  onTabClicked(index: number) {
    this.activeTab = index;
    setTimeout(() => this.navToTab(), 250);
  }

  navToTab() {
    switch (this.activeTab) {
      case 0:
        this.router.navigate(['home']);
        break;
      case 1:
        this.router.navigate(['notifications']);
        break;
      case 2:
        this.router.navigate(['settings']);
    }
  }
}
