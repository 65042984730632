<app-header [transparent]="false" [title]="i18n.string('history')"></app-header>

<div class="spinner-container" *ngIf="!!loading">
  <app-spinner [black]="true"></app-spinner>
</div>

<div *ngIf="!!asset && !loading" class="content" [class.desktop]="isDesktop">

  <div *ngIf="isDesktop" class="desktop-sidebar white-sidebar">
    <app-sidebar [showList]="true" [Asset]="asset" [sensorID]="sensorID"
      [telemetry]="sensor ? sensor.telemetry[0] : null"></app-sidebar>
  </div>
  <div [class.desktop-main]="isDesktop" [class.white-sidebar-main]="isDesktop">

    <app-breadcrumbs [object]="asset" [isAsset]="true" [showCurrent]="true" [additionalBC]="'History'"
      class="detail-bold" *ngIf="!isDesktop && asset.breadcrumbs && !breadcrumbsLoading"></app-breadcrumbs>
    <app-spinner class="crumbs-spinner" [black]="true" *ngIf="breadcrumbsLoading"></app-spinner>
    <div *ngIf="documentationAdded" class="ga-documentation-note body-bold">
      <i nz-icon nzType="icons:done" class="svg-icon"></i>
      <span>{{i18n.string('add_documentation_success')}}</span>
    </div>
    <div class="sensor-header flat-sensor">
      <div class="sensor-header-inner-div" [class.mobile]="isMobile" [class.tablet]="isTablet">
        <div class="sensor-header-inner-content">
          <i nz-icon nzType="icons:history" class="sensor-icon svg-icon"></i>
          <h2 class="history-sub-header">{{i18n.string('history')}}</h2>
          <div class="detail lighter-label" *ngIf="!isDesktop && sensor">{{sensor.sensorDisplayLabel}}</div>
        </div>
        <app-nav-button [primary]="true" [class.mobile]="isMobile" [class.tablet]="isTablet" svg_icon="icons:documentation"
        (click)="overlayDocumentation = true">
          {{i18n.string('add_documentation')}}</app-nav-button>
      </div>
    </div>

    <button class="filter-btn" *ngIf="false"><span>01 Mar 2021 - 7 Mar 2021</span>
      <i nz-icon nzType="icons:edit" class="mini-icon svg-icon"></i>
    </button>


    <app-docu-wizard *ngIf="overlayDocumentation" (close)="overlayDocumentation = false" [assetId]="assetID"
      [sensorId]="sensorID" (saved)="onDocumentationAdded($event)">
    </app-docu-wizard>

    <div class="detailhistory-wrapper">
      <app-history-item [dateFormat]="dateFormat" [detailedhistory]="true" *ngFor="let item of HistoryItems"
        [item]="item" [asset]="asset" [collapsible]="false" [shadow]="true">
      </app-history-item>
    </div>
    <nz-pagination [(nzPageIndex)]="pageIndex" [nzTotal]="historyItems.length"></nz-pagination>
  </div>
</div>