<!--<app-header [transparent]="true" *ngIf="!isLoading"></app-header>-->
<div class="login-wrapper" [class.mobile]="isMobile" [class.tablet]="isTablet">

  <div class="login-image">
    <img *ngIf="isDesktop" nz-image nzDisablePreview nzSrc="assets/images/login_image.png" height="100%">
    <img *ngIf="!isDesktop" nz-image nzDisablePreview nzSrc="assets/images/login_image.png" width="100%" height="119px">
  </div>

  <div class="loginForm" *ngIf="!isLoading">
    <img nz-image nzDisablePreview class="logo" nzSrc="assets/images/LoctitePulseLogo_red.svg" *ngIf="!forgotPassword">
    <app-change-pw-form></app-change-pw-form>
  </div>
</div>
