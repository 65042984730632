import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { de, enUS } from 'date-fns/esm/locale';

import { CurrentTelemetry } from '../../model/sensor';
import { GraphData } from '../../model/telemetry_history';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-sensor-value',
  templateUrl: './sensor-value.component.html',
  styleUrls: ['./sensor-value.component.scss'],
})
export class SensorValueComponent implements OnInit {
  @Input() ghost: boolean = false;
  @Input() asHeader: boolean = false;
  @Input() asHealth: boolean = false;
  @Input() lineColor: string = '';
  @Input() telemetry: CurrentTelemetry = null;
  @Input() graphData: GraphData = null;
  @Input() criticalOverride: Object = null;
  @Input() warningOverride: Object = null;
  @Input() inactiveOverride: Object = null;
  @Input() isSteamTrap: boolean = false;
  batteryCurrentVal: boolean;
  @ViewChild('battery') battery: ElementRef<any>;
  // get isMobile(): boolean {
  //   return this.ui.IsMobile;
  // }
  // get isTablet(): boolean {
  //   return this.ui.IsTablet;
  // }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  get IsBattery(): boolean {
    this.batteryCurrentVal = (this.i18n.parse(this.telemetry.title) == "Battery" || this.i18n.parse(this.telemetry.title) == "Batterie") ? true : false;
    return (
      !!this.telemetry &&
      this.telemetry.title.toLocaleLowerCase().includes('battery')
    );
  }

  // sry 4 hardcoding this
  get IsInletOutletTemp(): boolean {
    if (!this.graphData || !this.graphData.telemetry) return null;
    return (
      this.telemetry.measurementTypeID === 1073 ||
      this.telemetry.measurementTypeID === 1074
    );
  }

  get InletTemp(): number {
    if (!this.graphData || !this.graphData.telemetry) return null;
    const data = this.graphData.telemetry.filter(
      (item) => item.measurementTypeID === 1073
    );
    return data.length > 0 ? data[data.length - 1].value : null;
  }

  get InletTempString(): string {
    if (!this.graphData || !this.graphData.metadata) return null;
    return this.InletTemp + this.graphData.metadata.unit;
  }

  get OutletTemp(): number {
    if (!this.graphData || !this.graphData.telemetry) return null;
    const data = this.graphData.telemetry.filter(
      (item) => item.measurementTypeID === 1074
    );
    return data.length > 0 ? data[data.length - 1].value : null;
  }

  get OutletTempString(): string {
    if (!this.graphData || !this.graphData.metadata) return null;
    return this.OutletTemp + this.graphData.metadata.unit;
  }

  // TODO: remove
  get InletWarning(): boolean {
    return this.telemetry.warning;
  }
  get InletCritical(): boolean {
    return this.telemetry.critical;
  }
  get OutletWarning(): boolean {
    return this.telemetry.warning;
  }
  get OutletCritical(): boolean {
    return this.telemetry.critical;
  }
  get Numblobs(): number {
    if (!this.battery) return 0;
    return +parseInt(
      '' +
        (this.battery.nativeElement.clientWidth + 8) /
          ( !this.isDesktop ? 25 : 32),
      10
    );
  }

  get ChargingStatus(): number {
    if (!this.IsBattery) return 0;
    // 3.3V is 12 (mobile)
    // 1.5 is 4 (mobile)
    //  -> f(x) = x * 40/9 - 8/3
    const x = this.telemetry['value'].val.min;
    const m = 40 / 9;
    const n = -8 / 3;
    let y = x * m + n;
    y = (y / 12) * this.Numblobs; // there can be more or less then 12 blobs, depending on screen size
    return Math.round(y);
  }

  get IsGerman(): boolean {
    // used for restructuring when texts are looong
    return this.i18n.Lng === 'de';
  }

  constructor(public i18n: I18nService, private ui: UiService) {}

  get Title() {
    if (this.isWorking && !!this.telemetry.workingMessage)
      return this.telemetry.workingMessage;

    if (this.isInactive && !!this.telemetry.inactiveMessage)
      return this.telemetry.inactiveMessage;
    if (this.isCritical && !!this.telemetry.criticalMessage)
      return this.telemetry.criticalMessage;
    if (this.isWarning && !!this.telemetry.warningMessage)
      return this.telemetry.warningMessage;

    return '';
  }

  get isWorking(): boolean {
    return (
      !!this.telemetry &&
      !(this.telemetry.warning || this.hasWarningOverride) &&
      !(this.telemetry.critical || this.hasCriticalOverride) &&
      !(this.telemetry.inactive || this.hasInactiveOverride)
    );
  }

  get isWarning(): boolean {
    return (
      !!this.telemetry &&
      (this.telemetry.warning || this.hasWarningOverride) &&
      !(this.telemetry.critical || this.hasCriticalOverride) // &&
      // !this.IsInletOutletTemp
    );
  }

  get isInactive(): boolean {
    return (
      !!this.telemetry && (this.telemetry.inactive || this.hasInactiveOverride) // &&
      // !this.IsInletOutletTemp
    );
  }

  get isCritical(): boolean {
    return (
      !!this.telemetry && (this.telemetry.critical || this.hasCriticalOverride) // &&
      // !this.IsInletOutletTemp
    );
  }

  get hasWarningOverride(): boolean {
    return (
      !!this.warningOverride &&
      !!this.warningOverride[this.telemetry.measurementTypeID]
    );
  }

  get hasInactiveOverride(): boolean {
    return (
      !!this.inactiveOverride &&
      !!this.inactiveOverride[this.telemetry.measurementTypeID]
    );
  }
  get hasCriticalOverride(): boolean {
    return (
      !!this.criticalOverride &&
      !!this.criticalOverride[this.telemetry.measurementTypeID]
    );
  }
  ngOnInit() {}

  public Range(length: number) {
    if (length < 1) return [];
    return [...Array(length).keys()].map((i) => i + 1);
  }

  validTimeValue(timestamp) {
    try {
      this.minutesToNow(timestamp);
    } catch (e) {
      return false;
    }
    return true;
  }

  noData(telemetry) {
    if (!telemetry) return true;
    if (telemetry.valueType === 'minmax') {
      return (
        telemetry.value.valStr.min.length +
          telemetry.value.valStr.max.length ===
        0
      );
    }
    return telemetry.value.valStr.length === 0;
  }
  minutesToNow(timestamp) {
    return formatDistanceToNow(timestamp, {
      locale: this.i18n.Lng === 'de' ? de : enUS,
    });
  }
}
