<div class="tabMenu body-bold" [class.header]="header">
  <nz-tabset [nzSelectedIndex]="activeTab" [class.noSelection]="activeTab < 0" [nzTabPosition]="header ? 'top' : 'bottom'" [nzCentered]="true" nzSize="large"
    [nzLinkExact]="false">
    <nz-tab>
      <a *nzTabLink nz-tab-link [routerLink]="['home']"><i nz-icon nzType="icons:navigation"></i>{{i18n.string('navigation')}}</a>
    </nz-tab>
    <nz-tab>
      <a *nzTabLink nz-tab-link [routerLink]="['notifications']">
        <i nz-icon nzType="icons:bell" *ngIf="notificationCount === 0 || activeTab == 1"></i>
        <nz-badge *ngIf="notificationCount > 0 && activeTab != 1" [nzCount]="(NumEquipmentNotifications + NumEdgeDeviceNotifications)" [nzStyle]="{ backgroundColor: '#E1000F'}" nzStandalone></nz-badge>
       <span class="notification">{{i18n.string('notifications')}}</span> 
      </a>
    </nz-tab>
    <nz-tab *ngIf="header">
      <a *nzTabLink nz-tab-link [routerLink]="['settings']"><i nz-icon
        nzType="icons:more-information"></i>{{i18n.string('settings')}}</a>
    </nz-tab>
  </nz-tabset>

  <!--
  <div class="tab-active-marker-container"
    *ngIf="activeTab !== null"
    [@activeTab]="''+ activeTab">
    <div class="tab-active-marker"></div>
  </div>
  <a type="button"
    class="tab-btn"
    [class.active]="activeTab === 0"
    (click)="onTabClicked(0)">
    <div class="navigation-icon"></div><span>Navigation</span>
  </a>
  <a class="tab-btn"
    [class.active]="activeTab === 1"
    (click)="onTabClicked(1)">
    <div *ngIf="notificationCount === 0 || activeTab == 1" class="bell-icon"></div>
    <nz-badge *ngIf="notificationCount > 0 && activeTab != 1" [nzCount]="notificationCount" nzStandalone></nz-badge>
    <span>Notifications</span>
  </a>
  <a type="button"
    class="tab-btn"
    [class.active]="activeTab === 2"
    (click)="onTabClicked(2)">
    <div class="settings-icon"></div><span>Settings</span>
  </a>
-->
</div>
