import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  splashLoading: Subject<boolean> = new Subject();
  splashError: Subject<boolean> = new Subject();

  constructor() {}

  setLoadingStarted() {
    this.splashLoading.next(true);
  }

  setLoadingFinished(error = false) {
    this.splashLoading.next(false);
    this.splashError.next(error);
  }

  isLoading() {
    return this.splashLoading.asObservable();
  }

  loadingFailed() {
    return this.splashError.asObservable();
  }
}
