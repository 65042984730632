import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../../cc-framework/services/authentication.service';
import { environment } from '../../../../environments/environment';
import { I18nService } from '../../../services/i18n.service';
import { UiService } from '../../../services/ui.service';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'app-change-pw-form',
  templateUrl: './change-pw-form.component.html',
  styleUrls: ['./change-pw-form.component.scss'],
})
export class ChangePwFormComponent implements OnInit {
  // ref for resetting password fields
  @ViewChild('curPassword') curPasswordField: ElementRef;
  @ViewChild('password1') passwordField1: ElementRef;
  @ViewChild('password2') passwordField2: ElementRef;

  public token: string = '';
  // form data
  public curPW: string = '';
  public pw1: string = '';
  public pw2: string = '';
  public curPasswordVisible = false;
  public password1Visible = false;
  public password2Visible = false;

  // page state
  isLoading = false;
  success = false;
  error = false;
  isOffline = false;
  loginForm: FormGroup;

  authorized = false;
  authorizing = false;

  // validation
  pwValidation = true;
  pwEqual = true;
  pwValidation1 = false;
  pwValidation2 = false;
  pwValidation3 = false;
  pwValidation4 = false;

  curPWNecessaryError = false;
  pwValidationError1 = false;
  pwValidationError2 = false;
  pwValidationError3 = false;
  pwValidationError4 = false;

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  get credentialLogin(): boolean {
    return environment.credentialLogin;
  }

  get portalLogin(): boolean {
    return environment.portalLogin;
  }

  get debugMode(): boolean {
    return environment.debugMode;
  }

  get displayCurPWError(): boolean {
    return this.curPWNecessaryError;
  }

  get SubmitDisabled(): boolean {
    return (
      (!this.authorized && !this.isLoggedIn) ||
      !this.pwValidation ||
      !(
        (this.curPW !== '' || !this.isLoggedIn) &&
        this.pw1 !== '' &&
        this.pw2 !== ''
      )
    );
  }

  get displayPw1Error(): boolean {
    return (
      (!this.pwValidation1 ||
        !this.pwValidation2 ||
        !this.pwValidation3 ||
        !this.pwValidation4 ||
        !this.pwEqual) &&
      !this.pwValidation
    );
  }
  get displayPw2Error(): boolean {
    return !this.pwEqual;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    private util: UtilService,
    private ui: UiService,
    private _fb: FormBuilder,
    public i18n: I18nService,
    private changeRef: ChangeDetectorRef
  ) {}

  get I18N(): I18nService {
    return this.i18n;
  }

  ngOnInit() {
    this.loginForm = this._fb.group({
      curPassword: this.isLoggedIn
        ? ['', [Validators.required, Validators.minLength(3)]]
        : undefined,
      password1: ['', [Validators.required, Validators.minLength(3)]],
      password2: ['', [Validators.required, Validators.minLength(3)]],
    });

    if (!this.isLoggedIn) {
      this.route.params.subscribe((params) => {
        this.token = '' + params['token'];
        this.checkAuthorization();
      });
    }
  }

  setNewPassword() {
    if (!this.authorized && !this.isLoggedIn) return;

    this.success = false;
    this.error = false;
    if (this.validatePassword()) {
      if (!this.pwValidation) return;
      this.onLoadingStarted();
      if (this.isLoggedIn) {
        this.auth.setNewPasswordViaSettings(this.curPW, this.pw1).subscribe(
          () => {
            this.onLoadingFinished();
            this.success = true;
          },
          (error) => {
            console.error(error);
            this.onLoadingFinished(true);
            this.error = true;
          }
        );
      } else {
        this.auth.setNewPasswordViaEmail(this.token, this.pw1).subscribe(
          () => {
            this.onLoadingFinished();
            this.router.navigate(['login', 'pw-changed']);
          },
          (error) => {
            console.error(error);
            this.onLoadingFinished(true);
            this.error = true;
          }
        );
      }
    }
  }
  onInput() {
    this.validatePassword();
    this.changeRef.detectChanges();
  }

  validateCurPassword() {
    // we only validate whether the password has been entered!
    if (this.isLoggedIn && (!this.curPW || this.curPW.length === 0)) {
      this.curPWNecessaryError = true;
    } else {
      this.curPWNecessaryError = false;
    }
  }

  validatePassword() {
    this.validateCurPassword();

    if (this.pw1 !== this.pw2 && this.pw2.length > 0) {
      this.pwEqual = false;
    } else {
      this.pwEqual = true;
    }
    if (this.pw1.length >= 12) {
      this.pwValidation1 = true;
      this.pwValidationError1 = false;
    } else {
      this.pwValidation1 = false;
      this.pwValidationError1 = true;
    }

    if (
      this.pw1.toLowerCase() !== this.pw1 &&
      this.pw1.toUpperCase() !== this.pw1
    ) {
      this.pwValidation2 = true;
      this.pwValidationError2 = false;
    } else {
      this.pwValidation2 = false;
      this.pwValidationError2 = true;
    }

    function hasNumber(myString) {
      return /\d/.test(myString);
    }

    function hasLetter(myString) {
      return /[a-zA-Z]/.test(myString);
    }

    function hasSpecialCharacter(myString) {
      return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(myString);
    }
    if (hasNumber(this.pw1) && hasLetter(this.pw1)) {
      this.pwValidation3 = true;
      this.pwValidationError3 = false;
    } else {
      this.pwValidation3 = false;
      this.pwValidationError3 = true;
    }

    if (hasSpecialCharacter(this.pw1)) {
      this.pwValidation4 = true;
      this.pwValidationError4 = false;
    } else {
      this.pwValidation4 = false;
      this.pwValidationError4 = true;
    }

    if (
      this.pwValidationError1 ||
      this.pwValidationError2 ||
      this.pwValidationError3 ||
      this.pwValidationError4 ||
      !this.pwEqual ||
      this.pw2.length === 0
    ) {
      this.pwValidation = false;
      return false;
    }
    this.pwValidation = true;
    return true;
  }

  checkAuthorization() {
    this.authorized = false;
    this.authorizing = true;
    this.auth.checkForgotPWToken(this.token).subscribe(
      (isAuth) => {
        this.authorized = isAuth;
        this.authorizing = false;
      },
      (error) => {
        console.error(error);
        this.authorized = false;
        this.authorizing = false;
      }
    );
  }

  onRequestNewMail() {
    const email = this.getMailFromToken();
    this.router.navigate(['login', 'forgotpw', email]);
  }

  getMailFromToken() {
    try {
      return JSON.parse(atob(this.token.split('.')[1])['replaceAll']("'", '"'))
        .email;
    } catch (e) {
      return '';
    }
  }

  onLoadingStarted() {
    this.isLoading = true;
    this.error = false;
    this.util.setLoadingStarted(); // show splash screen for preloading plants and user settings after login
  }
  onLoadingFinished(error = false) {
    this.isLoading = false;
    this.error = error;
    this.util.setLoadingFinished(); // hide splash screen
  }

  onHostReachable(url: string) {
    this.isOffline = false;
  }

  onHostUnreachable(url: string) {
    this.isOffline = true;
    this.onLoadingFinished(true);
    // TODO: display host unreachable error and possible enter offline-mode
  }

  onException(exception) {
    this.onLoadingFinished(true);
    // TODO: display error
  }

  onUnauthorized() {
    this.onLoadingFinished();
    // TODO: display 'login failed'
    // this.router.navigate(['/login']);
  }

  onSubmitCurPW() {
    this.passwordField1.nativeElement.focus();
  }

  onSubmitPW1() {
    this.passwordField2.nativeElement.focus();
  }

  onSubmitPW2() {
    this.setNewPassword();
  }
}
