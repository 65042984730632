import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { de_DE, en_US } from 'ng-zorro-antd/i18n';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { StorageService } from '../../cc-framework/services/storage.service';
import { environment } from '../../environments/environment';
import { UserSettings } from '../model/settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private settingsCached: UserSettings = null;
  private settingsChangedSubject = new Subject<UserSettings>();
  private settingsChangedAfterGetSubject = new Subject<UserSettings>();
  constructor(private storage: StorageService, private http: HttpClient) {}

  getUserSettings(): Observable<UserSettings> {
    return this.http
      .get<UserSettings>(environment.host + 'api/settings', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(
        map((result) => Object.assign(new UserSettings(), result)),
        tap((result) => (this.settingsCached = result)),
        tap((result) => this.settingsChangedAfterGetSubject.next(result))
      );
  }

  setUserSettings(settings: UserSettings) {
    return this.http
      .put(environment.host + 'api/settings', settings, {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(
        tap(() => {
          this.settingsCached = settings;
        }),
        tap(() => {
          this.settingsChangedSubject.next(settings);
        })
      );
  }

  onSettingsChanged(): Observable<UserSettings> {
    return this.settingsChangedSubject;
  }
  onSettingsChangedAfterGet(): Observable<UserSettings> {
    return this.settingsChangedAfterGetSubject;
  }

  getLocaleID(): string {
    return !!this.settingsCached
      ? this.settingsCached.Language.abbreviation === 'de'
        ? 'de-DE'
        : 'en-US'
      : 'en-US';
  }

  getNZI18n(): any {
    return !!this.settingsCached
      ? this.settingsCached.Language.abbreviation === 'de'
        ? de_DE
        : en_US
      : en_US;
  }
}
