<div class="content content-more-margin desktop-wide">
    <h1>Henkel Corporation California Privacy Notice</h1>
    <h4>Effective Date: August 10, 2023</h4>
    <p>This California Privacy Notice (“Notice”) is intended for to “Consumers” as defined by the California Consumer Privacy Act (“CCPA”) and supplements Henkel Corporation’s (“Company” “Us” “We” “Our”) North American Privacy Policy. This Notice is limited to the data practices of Company in California. In the event of a conflict between any other Company policy, statement or notice and this Notice, this Notice will prevail as to California Consumers and their rights under the CCPA.</p>
    <p>This Notice covers our collection, use, and disclosure of California Consumers’ “Personal Information” (“PI”) as defined by the CCPA, for the twelve months preceding the Effective Date. This Notice also covers rights California Consumers have under the CCPA, as well other notices to Californians that may be required by certain other laws.</p>
    <p>Terms defined in the CCPA that are used in this Notice shall have the same meaning as in the CCPA. </p>

    <h3>1. PI We Collect</h3>
    <p>We hereby give you notice that, within the past 12 months, we have collected the following types of PI about California Consumers, with exemplary elements of PI listed. This notice will be updated annually, and our current privacy notices at the point of collection and general privacy statements may reflect more current practices.</p>

    <div class="table-text">
        <table>
            <tbody>
                <tr>
                    <td class="body-bold align-center">Category of PI</td>
                    <td>Examples of PI</td>
                </tr>
                <tr>
                    <td class="body-bold">1.Identifiers </td>
                    <td>This may include but is not limited to: contact information (such as name, address, city, state, province/territory, Postal/ZIP code, email address and telephone number), account credentials, online identifiers, IP address, device identifier, alias/account name, and in limited circumstances, state ID number, cosmetology license number, business tax ID, and other identifiers for professional use.</td>
                </tr>
                <tr>
                    <td class="body-bold">2. Personal Records under Cal. Civil Code § 1798.80(e)</td>
                    <td>This may include information such as: identifiers described above, date of birth, payment information or other financial account information, and other personal, financial, and consumer complaint information.</td>
                </tr>
                <tr>
                    <td class="body-bold">3. Characteristics of Protected Classifications</td>
                    <td>This may include but is not limited to demographic information such as gender, sex, race, or age.</td>
                </tr>
                <tr>
                    <td class="body-bold">4. Commercial Information</td>
                    <td>This may include but is not limited to: customer account details, products or services purchased or considered, service history, other purchasing or consumption histories or tendencies, and transaction records.</td>
                </tr>
                <tr>
                    <td class="body-bold">5. Internet and other Electronic Network Activity Information</td>
                    <td>This may include but is not limited to: information about your use of our sites, apps, or services, information regarding your interaction with Henkel sites and mobile apps and advertisements displayed on Henkel or third party sites and apps, and data regarding network connected hardware, browsing activity, IP address, and device identifiers.</td>
                </tr>
                <tr>
                    <td class="body-bold">6. Geolocation Data</td>
                    <td>This may include but is not limited to: physical location or device location.</td>
                </tr>
                <tr>
                    <td class="body-bold">7. Audio, Visual, or other Sensory Data</td>
                    <td>This may include but is not limited to: customer-submitted photographs, videos, and audio. As permitted by law, we may also record calls to our customer service and product support lines. </td>
                </tr>
                <tr>
                    <td class="body-bold">8. Professional or Employment-related Information</td>
                    <td>This may include but is not limited to: information from resumes or cover letters, third-party employment status, employment history, background screening information, and professional licenses.</td>
                </tr>
                <tr>
                    <td class="body-bold">9. Inferences from PI Collected</td>
                    <td>These may be drawn from any of the categories of information listed in this section, and/or from any other information we process, and may include (but is not limited to): consumer preferences, aptitudes, attitudes, trends, characteristics, predispositions, and behavior, including in connection with profiles created about consumers.</td>
                </tr>
                <tr>
                    <td class="body-bold">10. Sensitive PI</td>
                    <td>This may include account login credentials, and precise geolocation data, as described above. If you win one of our sweepstakes, contests, prize drawings, or promotions, we may be required to collect government-issued ID numbers as part of legal compliance. Some of the characteristics of protected classifications listed above may be sensitive personal information. If you provide sensitive personal information when communicating with us, such as when calling our customer service or product support lines, it may be collected. If you apply for a position via our Our online Careers portal, we may also collect government-issued IDs, and information you choose to provide about protected classifications.</td>
                </tr>
            </tbody>
        </table>
    </div>
    <h4>A. Sources of PI. </h4>
    <p>We may collect your PI directly from you or your device, or from service providers, vendors and suppliers, our affiliates, or other individuals and businesses as well as public sources of data further described in the “Information Collected” Section above</p>

    <h4>B. Use of PI.</h4>
    <p>Generally, we collect, retain, use, and share your PI for the purposes described in the Section of our North American Privacy Policy titled “Information Use”.</p>
    <p>In addition, we may collect, use and share the PI we collect for one or more of the following business purposes:</p>
    <p class="list-item body-bold">• Processing Interactions and Transactions</p>
    <p class="list-item body-bold">• Managing Interactions and Transactions</p>
    <p class="list-item body-bold">• Performing Services</p>
    <p class="list-item body-bold">• Research and Development</p>
    <p class="list-item body-bold">• Quality & Safety Assurance, Verification, Maintenance, Improvement, or Enhancement</p>
    <p class="list-item body-bold">• Security & Integrity, including (without limitation) protecting against security incidents and fraudulent, malicious, illegal, or wrongful conduct</p>
    <p class="list-item body-bold">• Debugging</p>
    <p class="list-item body-bold">• Short-term, transient use, including non-personalized advertising</p>
    <p class="list-item body-bold">• Enabling our service providers and business partners (and their service providers and/or subcontractors) to provide services to us</p>

    <h3>2. Sharing of PI</h3>
    <p>We have disclosed PI in all or substantially all of the categories identified in this Notice for the business purposes listed in this Notice and our North American Privacy Policy. For more information about the categories of entities with which we have disclosed this information, see the section of our North American Privacy Policy titled “Data Sharing and Storage.”</p>
    <p>Henkel does not sell personal information for monetary consideration. As is common among businesses that operate internet websites, we may disclose the following categories of personal information in a way that could be deemed a “sale” or “sharing” of data for cross-context behavioral advertising under California law to third parties including advertising companies and social networks: certain identifiers, commercial information, internet & other electronic network activity information, and inferences. We do not “sell” personal information of individuals we know to be under the age of 16, or share the personal information of individuals we know to be under the age of 16 for cross-context-behavioral advertising.</p>
    <p>For more information, see Section 3(D) (Right to Opt Out of Sale or Sharing) below.</p>

    <h3>3. California Privacy Rights</h3>
    <p>We provide California Consumers the privacy rights described in this section. You may also exercise these rights via an authorized agent who meets the agency requirements of the CCPA. As required by the CCPA, any request you submit to us is subject to a verification process. To verify your request, you must provide your name, email, and state of residence, along with any other information we may require to verify your identity or your request. We may not fulfill your CCPA request unless you have provided sufficient information for us to appropriately verify you and your request. Please follow the instructions at our Consumer Rights Request page here and respond to any follow up inquires we may make. You may also submit a request by calling us at 1-888-285-1935.</p>
    <p>You are not required to create a password-protected account with us to make a Verifiable Consumer Request.  We will typically not charge a fee to respond to your requests; provided, however, that we may charge a fee if permitted under applicable law. The California Consumer privacy rights outlined in this Notice, and our obligation to fulfill requests to exercise them, may be subject to limitations and exceptions as permitted or required by law.</p>
    <p>Your California Consumer privacy rights are as follows:</p>
        <h4>A. The Right to Know and Have Access to Data</h4>
        <p>You have the right to obtain a copy of the specific pieces of PI that we have collected about you.</p>
        <p>To make a request, follow the instructions at our Consumer Rights Request page here or call us at 1-888-285-1935. </p>

        <h4>B. Right to Delete</h4>
        <p>You may request that we delete your PI that we have collected from you. Please note that even if you make a request to delete your PI, applicable law may permit or require us to retain PI that was collected to complete transactions and services you have requested or that are reasonably anticipated, for security purposes, for legitimate internal business purposes, including maintaining business records, to comply with law, to exercise or defend legal claims, and to cooperate with law enforcement.  Please note if you have requested a service that requires the use of your PI, we may not be able to provide that service if you choose to delete your PI.</p>
        <p>To make a request, follow the instructions at our Consumer Rights Request page here or call us at 1-888-285-1935.</p>

        <h4>C. Right to Correct</h4>
        <p>You may request that we correct PI that we maintain about you that is inaccurate.</p>
        <p>To make a request, follow the instructions at our Consumer Rights Request page here or call us at 1-888-285-1935.</p>

        <h4>D. Right to Opt Out of Sale or Sharing</h4>
        <p>You may opt out of “sales” or “sharing” of PI (as defined under applicable law) as follows:</p>
        <p class="list-item">1 Click on “Cookie Settings” at the bottom of the relevant website and deactivate cookies. </p>
        <p class="list-item">2 Follow the instructions at our Consumer Rights Request page here to submit an “Opt Out” of the selling or sharing of Personal Information request to us.</p>
        <p class="list-item">3 Exercising opt-out choices offered by the Digital Advertising Alliance at http://www.aboutads.info/choices, or by the Network Advertising Initiative at http://www.networkadvertising.org/choices/.</p>
        <p>We will also process opt-out requests submitted by a consumer’s opt-out preference signal as required by applicable law. The technical specifications of a consumer’s opt-out preference signal determine how we process such signals. For example, if a consumer installs opt-out preference technology on a browser, and the opt-out preference signal it sends is associated solely with a user’s browser, the opt-out will be applied to the browser. As of the “Last Updated” date of this Privacy Statement, our processing of opt-out preference signals can be considered “frictionless” under California law.
            You can learn how to set up and use an opt-out preference signal by visiting the California Attorney General’s CCPA page, and consulting the section titled “Requests not to Sell Personal Information (Right to Opt-Out of Sale).” You can also consult FAQs published by the California Privacy Protection Agency (CPPA) about how you can set up and use opt-out preference signals such as “Global Privacy Control” (see the FAQ titled “How to submit your requests).</p>
        
        <h4>E. No Right to Limit. </h4>
        <p>Henkel does not use or disclose sensitive personal information for purposes that, under applicable law, require us to offer consumers a “Right to Limit” the use or disclosure of sensitive personal information.</p>

        <h4>F. Non-Discrimination</h4>
        <p>As required by the CCPA, we will not discriminate against you because you exercise your CCPA rights. However, we may charge a different price or rate, or offer a different level or quality of good or service, to the extent that doing so is reasonably related to the value of the applicable data.</p>

        <h4>G. Authorized Agents</h4>
        <p>Authorized agents of Consumers may make a request by visiting here or calling 1-888-285-1935. As permitted by the CCPA, any request you submit to us is subject to an identification and verification process, and confirmation of the agent’s authority, which may include attestation under penalty of perjury. </p>

    <h3>4. Retention</h3>
    <p>We keep your information for the length of time needed to carry out the purposes outlined in this Notice or our North America Privacy Policy and to adhere to our policies on keeping records (unless a longer period is needed by law). Our records policies reflect applicable laws. We will retain and use your information to the extent necessary to manage your relationship with us, personalize and improve your overall customer experience, and to comply with our legal obligations (for example, if we are required to retain your information to comply with applicable tax/revenue laws), resolve disputes, enforce our agreements, and as otherwise described in this Notice or our North America Privacy Policy.</p>

    <h3>5. Additional California Notes</h3>
    <p>Although as of the Effective Date of this revised Notice it was not Henkel’s current practice to share your “personal information” as defined by California’s “Shine the Light” law with third parties, other than Henkel affiliated companies and subsidiaries, for such third parties’ own direct marketing purposes without your consent, we reserve the right to do so. Accordingly, we provide California residents with the option to prospectively opt-out of our sharing of such data for those purposes to third parties other than Henkel affiliated companies and subsidiaries. To do so, contact Henkel at PrivacyNA@henkel.com, or send a letter to Henkel at:</p>
    <p class="wrap-text">{{ i18n.string("ca_privacy_inquiry_adress") }}</p>
    <p>Requests must include “California Privacy Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code. Please note that, by law, Henkel is only required to respond to one request per customer each year, and Henkel is not required to respond to requests made by means other than through the provided e-mail address or mail address.</p>
    
    <h3>6. Contact us</h3>
    <p>For more information on your California privacy rights contact us at 1-888-285-1935 or email us at PrivacyNA@henkel.com. You may also use our California Consumer Rights Portal found here. Or, write to us at:</p>
    <p class="wrap-text">{{ i18n.string("privacy_inquiry_adress") }}</p>

</div>

