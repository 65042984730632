declare const window: any;
export class UserSettings {
  public username: string;
  public dateFormatID: number;
  public languageID: number;
  public measurementSystemID: number;
  public themeID: number;
  public notificationsPush: boolean;
  public notificationsSMS: boolean;
  public notificationsEmail: boolean;
  public notificationsWarning: boolean;
  public name: string;
  public surname: string;
  public designation: string;
  public phonenumber: string;
  public email: string;

  public dateFormats: DateFormatSettings[];
  public languages: LanguageSettings[];
  public measurementSystems: MeasurementSystemSettings[];
  public themes: ThemeSettings[];

  get DateFormat(): DateFormatSettings {
    return this.dateFormats.find((item) => item.id === this.dateFormatID);
  }
  get Language(): LanguageSettings {
    let languageApply = this.languages.find((item) => item.id === this.languageID);
    window.adobeDataLayer[0] ? window.adobeDataLayer[0]._henkel.page['language'] = languageApply.abbreviation : undefined;
    return this.languages.find((item) => item.id === this.languageID);
  }
  get MeasurementSystem(): MeasurementSystemSettings {
    return this.measurementSystems.find(
      (item) => item.id === this.measurementSystemID
    );
  }
  get Theme(): ThemeSettings {
    return this.themes.find((item) => item.id === this.themeID);
  }
}

export class DateFormatSettings {
  public id: number;
  public name: string;
  public formatString: string;
}

export class LanguageSettings {
  public id: number;
  public name: string;
  public abbreviation: string;
}
export class MeasurementSystemSettings {
  public id: number;
  public name: string;
  public abbreviation: string;
}
export class ThemeSettings {
  public id: number;
  public name: string;
  public image: string;
}
