import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../cc-framework/services/authentication.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss'],
})
export class DataProtectionComponent implements OnInit {

  showUSCountryPage: boolean = false;
  
  constructor(
    public i18n: I18nService,
    public router: Router,
    private auth: AuthenticationService
  ) {}

  get isAuth(): boolean {
    return this.auth.isLoggedIn();
  }

  ngOnInit(): void {
    if(this.router.url.includes('/us/')) {
      this.showUSCountryPage = true;
    }
  }
  gotoLogin() {
    this.router.navigate(['login']);
  }
}
