import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { DocumentationAttachment } from '../../../model/documentation';
import { DocumentationService, Upload } from '../../../services/documentation.service';
import { I18nService } from '../../../services/i18n.service';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-docu-item',
  templateUrl: './documentation-item.component.html',
  styleUrls: ['./documentation-item.component.scss'],
})
export class DocumentationItemComponent {
  @Input() header: string = null;
  @Input() svg_icon: string = null;
  @Input() variant: string = null;
  @Input() content: DocumentationAttachment[] = [];
  @Input() documentationId: number = -1;
  @Output() click: EventEmitter<void> = new EventEmitter<void>();
  fileSizeError = false;

  @ViewChild('imageInputUpload') imageInputUpload: ElementRef;
  @ViewChild('imageInputCamera') imageInputCamera: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  imageUploadProgress: Upload | undefined;
  fileUploadProgress: Upload | undefined;
  take_or_upload_dialog_open: boolean = false;

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  
  constructor(
    private service: DocumentationService,
    private changeRef: ChangeDetectorRef,
    public i18n: I18nService,
    public ui: UiService
  ) {}

  onHeaderClick() {
    this.click.emit();

    if (this.variant === 'image') {
      if (!this.isDesktop) {
        this.openAddImageModal();
      } else {
        this.uploadImage();
      }
    }
    if (this.variant === 'file') {
      this.addFile();
    }
  }

  onAllButtonClick() {
    // this item is "all button" (e.g. when it's of variant other than 'image' or 'file')
    if (this.variant === 'image' || this.variant === 'file') return;
    this.click.emit();
  }

  fileChanged(event: any, docType: string) {
    const files = event.target.files;
    const that = this;
    this.changeRef.detectChanges();
    const limit = 20971520; // 20mb
    const delay = 1000;
    this.fileSizeError = false;
    this.changeRef.detectChanges();

    setTimeout(() => {
      this.fileSizeError = !!Array.from(files).find(
        (file: File) => file.size >= limit
      );
      this.changeRef.detectChanges();
    }, delay);

    Array.from(files)
      .filter((file: File) => file.size < limit)
      .forEach((file: File) => {
        const reader = new FileReader();
        reader.onload = function (ev) {
          const fileData = ev.target['result'].toString();
          const upload = that.service.uploadAttachment(
            that.documentationId,
            docType,
            file.name,
            fileData.split(',')[1]
          );

          that.service.traceUploadProgress(upload).subscribe((d) => {
            // show progress bar
            if (docType === 'image') {
              that.imageUploadProgress = d;
            } else {
              that.fileUploadProgress = d;
            }
            if (d.event && d.event['body']) {
              that.content.push(d.event['body']);
              if (docType === 'image') {
                that.imageUploadProgress = undefined;
              } else {
                that.fileUploadProgress = undefined;
              }
            }
            that.changeRef.detectChanges();
          });
        };
        reader.readAsDataURL(file);
      });

    Array.from(files)
      .filter((file: File) => file.size >= limit)
      .forEach((file: File) => {
        if (docType === 'image') {
          that.imageUploadProgress = {
            progress: 0,
            state: 'IN_PROGRESS',
            event: event,
          };
        } else {
          that.fileUploadProgress = {
            progress: 0,
            state: 'IN_PROGRESS',
            event: event,
          };
        }
        this.changeRef.detectChanges();

        setTimeout(() => {
          that.imageUploadProgress = undefined;
          that.fileUploadProgress = undefined;
          this.changeRef.detectChanges();
        }, delay);
      });
  }

  openAddImageModal() {
    this.take_or_upload_dialog_open = true;
  }

  uploadImage() {
    this.take_or_upload_dialog_open = false;
    this.imageInputUpload.nativeElement.click();
  }

  captureImage() {
    this.take_or_upload_dialog_open = false;
    this.imageInputCamera.nativeElement.click();
  }

  addFile() {
    this.fileInput.nativeElement.click();
  }

  deleteAttachment(attachment: DocumentationAttachment) {
    this.service.deleteAttachment(attachment.attachmentId).subscribe(() => {
      this.content = this.content.filter(
        (item) => item.attachmentId !== attachment.attachmentId
      );
      this.changeRef.detectChanges();
    });
  }
}
