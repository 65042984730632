<div class="content" [class.mobile]="isMobile" [class.tablet]="isTablet">
  <div [class.desktop-main]="isDesktop">
    <div *ngIf="filteredPlants">
      <div *ngIf="AssetCount > 1" class="found-wrapper">
        <h3>{{ AssetCount }} {{ i18n.string("equipments_found") }}</h3>
      </div>
      <div *ngIf="AssetCount === 1" class="found-wrapper">
        <h3>1 {{ i18n.string("equipment_found") }}</h3>
      </div>
      <div *ngIf="AssetCount === 0 && bcGroupedPlants.length === 0 && sumCritical === 0 &&
        sumWarning === 0 && sumInactive === 0 && sumWorking === 0" class="found-wrapper">
        <h3>{{ i18n.string("no_equipments_found") }}</h3>
        <h5 class="marginTop24">{{ i18n.string("no_equipments_found2") }}</h5>
      </div>
    </div>
    <div
      *ngIf="allWorking"
      class="nz-multiple-status-reports nz-multiple-reports-left"
    >
      <nz-alert
        nzType="success"
        class="nz-status-report"
        [nzMessage]="allfineTemplate"
      ></nz-alert>
      <ng-template #allfineTemplate
        ><i nz-icon nzType="icons:status-check" class="svg-icon fillgreen"></i
        ><span class="body-bold">{{
          i18n.string("all_equipments_working_fine")
        }}</span></ng-template
      >
    </div>

    <div
      *ngIf="
        AssetCount > 0 && (sumCritical > 0 || sumWarning > 0 || sumInactive > 0)
      "
      class="nz-multiple-status-reports first-status-report nz-multiple-reports-left"
    >
      <ng-container *ngIf="sumInactive > 0">
        <nz-alert
          nzType="info"
          class="nz-status-report"
          [nzMessage]="inActiveTemplate"
          (click)="statusClick(filterStatus.inactive)"
        ></nz-alert>
        <ng-template #inActiveTemplate>
          <a
            ><i
              nz-icon
              nzType="icons:cross-circle"
              class="svg-icon fillblackcoral"
            ></i>
            <span class="body-bold">
              {{ sumInactive }} {{ i18n.string("in_active") }}</span
            ></a
          >
        </ng-template>
      </ng-container>
      <ng-container *ngIf="sumCritical > 0">
        <nz-alert
          nzType="error"
          class="nz-status-report"
          [nzMessage]="errorTemplate"
          (click)="statusClick(filterStatus.critical)"
        ></nz-alert>
        <ng-template #errorTemplate>
          <a
            ><i
              nz-icon
              nzType="icons:status-exclamation-triangle"
              class="svg-icon fillred"
            ></i>
            <span class="body-bold">
              {{ sumCritical }} {{ i18n.string("critical") }}</span
            ></a
          >
        </ng-template>
      </ng-container>
      <ng-container *ngIf="sumWarning > 0">
        <nz-alert
          nzType="warning"
          class="nz-status-report"
          [nzMessage]="warningTemplate"
          (click)="statusClick(filterStatus.warning)"
        ></nz-alert>
        <ng-template #warningTemplate>
          <a
            ><i
              nz-icon
              nzType="icons:status-exclamation-octagon"
              class="svg-icon fillorange"
            ></i>
            <span class="body-bold">
              {{ sumWarning }} {{ i18n.string("warning") }}</span
            ></a
          >
        </ng-template>
      </ng-container>      
      <ng-container *ngIf="sumWorking > 0">
        <nz-alert
          nzType="success"
          class="nz-status-report"
          [nzMessage]="otherfineTemplate"
          (click)="statusClick(filterStatus.working)"
        ></nz-alert>
        <ng-template #otherfineTemplate>
          <a
            ><i
              nz-icon
              nzType="icons:status-check"
              class="svg-icon fillgreen"
            ></i>
            <span class="body-bold">
              {{ sumWorking }} {{ i18n.string("working_fine") }}</span
            ></a
          >
        </ng-template>
      </ng-container>
    </div>
    <ng-container *ngFor="let breadcrumbcluster of bcGroupedPlants">
      <app-breadcrumbs [object]="breadcrumbcluster[0]"></app-breadcrumbs>
      <ng-container *ngFor="let plant of breadcrumbcluster">
        <app-card
          *ngIf="
            plant.Critical.length == 0 &&
            plant.Warning.length == 0 &&
            plant.InActive.length == 0 &&
            !plant.filtered
          "
          [plant]="plant"
          [fine]="true"
        ></app-card>
        <app-card
          *ngIf="
            (plant.Critical.length > 0 ||
              plant.Warning.length > 0 ||
              plant.InActive.length > 0) &&
            ((plant.Critical.length +
              plant.Warning.length +
              plant.InActive.length <
              6 &&
              isDesktop) ||
              (plant.Critical.length +
                plant.Warning.length +
                plant.InActive.length <
                4 &&
                !isDesktop)) &&
            !plant.filtered
          "
          [plant]="plant"
          [fine]="false"
        >
          <ng-container *ngIf="plant.InActive.length > 0">
            <nz-alert
              nzType="info"
              class="nz-status-report noMargin"
              [class.center]="!isDesktop"
              [nzMessage]="inActiveTemplate"
            >
            </nz-alert>
            <ng-template #inActiveTemplate
              ><i
                nz-icon
                nzType="icons:cross-circle"
                class="fillblackcoral"
              ></i>
              <span *ngIf="plant.InActive.length == 1" class="body-bold">
                {{ plant.InActive.length }}
                {{ i18n.string("in_active_sensor") }}</span
              >
              <span *ngIf="plant.InActive.length > 1" class="body-bold">
                {{ plant.InActive.length }}
                {{ i18n.string("in_active_sensors") }}</span
              >
            </ng-template>
            <ng-container *ngFor="let building of plant.children">
              <div class="card-layer" *ngIf="building.InActive.length > 0">
                <h2>{{ building.objectName }}</h2>
                <ng-container *ngFor="let equipment of building.children">
                  <ng-container *ngIf="equipment.InActive.length > 0">
                    <span class="regular black-coral-text">{{
                      equipment.objectName
                    }}</span>
                    <div class="list-equipment-wrapper">
                      <ng-container *ngFor="let asset of equipment.InActive">
                        <app-asset-nav-button
                          [asset]="asset"
                          svg_icon="icons:cross-circle"
                        >
                          {{ asset.objectName | nameSlice }}
                        </app-asset-nav-button>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="plant.Critical.length > 0">
            <nz-alert
              nzType="error"
              class="nz-status-report noMargin"
              [class.center]="!isDesktop"
              [nzMessage]="errorTemplate"
            >
            </nz-alert>
            <ng-template #errorTemplate
              ><i
                nz-icon
                nzType="icons:status-exclamation-triangle"
                class="fillred"
              ></i
              ><span class="body-bold">
                {{ plant.Critical.length }}
                {{ i18n.string("critical_equipments") }}</span
              >
            </ng-template>
            <ng-container *ngFor="let building of plant.children">
              <div class="card-layer" *ngIf="building.Critical.length > 0">
                <h2>{{ building.objectName }}</h2>
                <ng-container *ngFor="let equipment of building.children">
                  <ng-container *ngIf="equipment.Critical.length > 0">
                    <span class="regular black-coral-text">{{
                      equipment.objectName
                    }}</span>
                    <div class="list-equipment-wrapper">
                      <ng-container *ngFor="let asset of equipment.Critical">
                        <!--<ng-container *ngFor="let asset of building.Critical">-->
                        <app-asset-nav-button
                          [asset]="asset"
                          svg_icon="icons:status-exclamation-triangle"
                        >
                          {{ asset.objectName | nameSlice }}
                        </app-asset-nav-button>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="plant.Warning.length > 0">
            <nz-alert
              nzType="warning"
              class="nz-status-report noMargin"
              [class.center]="!isDesktop"
              [nzMessage]="warningTemplate"
            >
            </nz-alert>
            <ng-template #warningTemplate
              ><i
                nz-icon
                nzType="icons:status-exclamation-octagon"
                class="fillorange"
              ></i>
              <span *ngIf="plant.Warning.length == 1" class="body-bold">
                {{ plant.Warning.length }}
                {{ i18n.string("warning_equipment") }}</span
              >
              <span *ngIf="plant.Warning.length > 1" class="body-bold">
                {{ plant.Warning.length }}
                {{ i18n.string("warning_equipments") }}</span
              >
            </ng-template>
            <ng-container *ngFor="let building of plant.children">
              <div class="card-layer" *ngIf="building.Warning.length > 0">
                <h2>{{ building.objectName }}</h2>
                <ng-container *ngFor="let equipment of building.children">
                  <ng-container *ngIf="equipment.Warning.length > 0">
                    <span class="regular black-coral-text">{{
                      equipment.objectName
                    }}</span>
                    <div class="list-equipment-wrapper">
                      <ng-container *ngFor="let asset of equipment.Warning">
                        <!--<ng-container *ngFor="let asset of building.Critical">-->
                        <app-asset-nav-button
                          [asset]="asset"
                          svg_icon="icons:status-exclamation-octagon"
                        >
                          {{ asset.objectName | nameSlice }}
                        </app-asset-nav-button>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>          
        </app-card>
        <app-card
          *ngIf="
            (plant.Critical.length > 0 ||
              plant.Warning.length > 0 ||
              plant.InActive.length > 0) &&
            ((plant.Critical.length +
              plant.Warning.length +
              plant.InActive.length >=
              6 &&
              isDesktop) ||
              (plant.Critical.length +
                plant.Warning.length +
                plant.InActive.length >=
                4 &&
                !isDesktop)) &&
            !plant.filtered
          "
          [plant]="plant"
          [fine]="false"
        >
          <ng-container *ngIf="plant.InActive.length > 0">
            <nz-alert
              nzType="info"
              class="nz-status-report noMargin content-aligment-center"
              [class.center]="!isDesktop"
              [nzMessage]="inActiveTemplate"
            >
            </nz-alert>
            <ng-template #inActiveTemplate
              ><i
                nz-icon
                nzType="icons:cross-circle"
                class="fillblackcoral"
              ></i>
              <span *ngIf="plant.InActive.length == 1" class="body-bold">
                {{ plant.InActive.length }}
                {{ i18n.string("in_active_sensor") }}</span
              >
              <span *ngIf="plant.InActive.length > 1" class="body-bold">
                {{ plant.InActive.length }}
                {{ i18n.string("in_active_sensors") }}</span
              >
            </ng-template>
            <div class="card-layer">
              <div class="list-equipment-wrapper">
                <app-asset-nav-button
                  [InactiveStatus]="true"
                  svg_icon="icons:eye"
                  (click)="onClick(plant, filterStatus.inactive)"
                  >{{ i18n.string("show_inactive_sensors") }}
                </app-asset-nav-button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="plant.Critical.length > 0">
            <nz-alert
              nzType="error"
              class="nz-status-report noMargin"
              [class.center]="!isDesktop"
              [nzMessage]="errorTemplate"
            >
            </nz-alert>
            <ng-template #errorTemplate
              ><i
                nz-icon
                nzType="icons:status-exclamation-triangle"
                class="fillred"
              ></i
              ><span class="body-bold">
                {{ plant.Critical.length }}
                {{ i18n.string("critical_equipments") }}</span
              >
            </ng-template>
            <div class="card-layer">
              <div class="list-equipment-wrapper">
                <app-asset-nav-button
                  [CriticalStatus]="true"
                  svg_icon="icons:eye"
                  (click)="onClick(plant, filterStatus.critical)"
                >
                  {{ i18n.string("show_critical_equipments") }}
                </app-asset-nav-button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="plant.Warning.length > 0">
            <nz-alert
              nzType="warning"
              class="nz-status-report noMargin"
              [class.center]="!isDesktop"
              [nzMessage]="warningTemplate"
            >
            </nz-alert>
            <ng-template #warningTemplate
              ><i
                nz-icon
                nzType="icons:status-exclamation-octagon"
                class="fillorange"
              ></i>
              <span *ngIf="plant.Warning.length == 1" class="body-bold">
                {{ plant.Warning.length }}
                {{ i18n.string("warning_equipment") }}</span
              >
              <span *ngIf="plant.Warning.length > 1" class="body-bold">
                {{ plant.Warning.length }}
                {{ i18n.string("warning_equipments") }}</span
              >
            </ng-template>
            <div class="card-layer">
              <div class="list-equipment-wrapper">
                <app-asset-nav-button
                  [WarningStatus]="true"
                  svg_icon="icons:eye"
                  (click)="onClick(plant, filterStatus.warning)"
                >
                  {{ i18n.string("show_warning_equipments") }}
                </app-asset-nav-button>
              </div>
            </div>
          </ng-container>
           
        </app-card>

        <app-card *ngIf="plant.filtered" [plant]="plant" [fine]="false">
          <ng-container *ngFor="let bcCluster of assetsGroupBy(plant)">
            <app-breadcrumbs
              [object]="bcCluster[0]"
              [searchTealText]="true"
              [shorten]="2"
            ></app-breadcrumbs>
            <div class="search-result-wrapper">
              <ng-container *ngFor="let asset of bcCluster">
                <app-asset-nav-button
                  [asset]="asset"
                  [svg_icon]="statusChecked(asset)"
                  >{{ asset.objectName | nameSlice }}
                </app-asset-nav-button>
              </ng-container>
            </div>
          </ng-container>
        </app-card>
      </ng-container>
    </ng-container>

    <div class="spinner" *ngIf="loading"></div>
  </div>
</div>
