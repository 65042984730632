<div class="content content-more-margin desktop-wide">
  <div *ngIf="!showUSCountryPage">
  <h1 class="center">{{ i18n.string("service_conditions_new_title") }}</h1>
  <h1 class="center" style="margin:0px;">{{ i18n.string("service_conditions_new_subtitle") }}</h1>
  <h3>{{ i18n.string("service_scope_1") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_1_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_1_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_1_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_1_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_1_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_1_6_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_1_7_content')"></p> 
  <h3>{{ i18n.string("service_scope_2") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_2_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_2_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_2_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_2_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_2_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_2_6_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_2_7_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_2_8_content')"></p>
  <h3>{{ i18n.string("service_scope_3") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_3_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_3_1_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_3_2_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_3_3_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_3_4_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_3_5_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_6_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_7_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_8_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_9_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_1_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_2_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_3_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_4_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_5_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_6_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_7_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_3_10_8_content')" class="mar-left"></p>
  <h3>{{ i18n.string("service_scope_4") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_4_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_4_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_4_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_4_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_4_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_4_6_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_4_7_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_4_8_content')"></p>
  <h3>{{ i18n.string("service_scope_5") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_5_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_5_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_5_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_5_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_5_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_5_6_content')"></p>
  <h3>{{ i18n.string("service_scope_6") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_6_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_6_2_content')"></p>
  <h3>{{ i18n.string("service_scope_7") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_7_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_7_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_7_3_content')"></p>
  <h3>{{ i18n.string("service_scope_8") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_8_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_8_1_1_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_8_1_2_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_8_1_3_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_8_1_4_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_8_1_5_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_8_1_6_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_8_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_8_3_content')"></p>
  <h3>{{ i18n.string("service_scope_9") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_9_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_9_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_9_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_9_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_9_4_1_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_9_4_2_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_9_4_3_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_9_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_9_6_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_9_7_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_9_8_content')"></p>
  <h3>{{ i18n.string("service_scope_10") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_10_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_10_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_10_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_10_4_content')"></p>
  <h3>{{ i18n.string("service_scope_11") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_11_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_11_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_11_3_content')"></p>
  <h3>{{ i18n.string("service_scope_12") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_12_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_12_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_12_2_1_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_12_2_2_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_12_2_3_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_12_2_4_content')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_12_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_12_4_content')"></p>
  <h3>{{ i18n.string("service_scope_13") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_13_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_13_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_13_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_13_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_13_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_13_6_content')"></p>
  <h3>{{ i18n.string("service_scope_14") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_14_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_14_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_14_3_content')"></p>
  <h3>{{ i18n.string("service_scope_15") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_15_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_15_2_content')"></p>
  <h3>{{ i18n.string("service_scope_16") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_16_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_16_2_content')"></p>
  <h3>{{ i18n.string("service_scope_17") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_17_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_17_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_17_3_content')"></p>
  <h3>{{ i18n.string("service_scope_18") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_18_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_18_2_content')"></p>
  <h3>{{ i18n.string("service_scope_19") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_19_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_19_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_19_3_content')"></p>
  <h3>{{ i18n.string("service_scope_20") }}</h3>
  <p [innerHTML]="i18n.string('service_scope_20_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_20_2_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_20_3_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_20_4_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_20_5_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_20_6_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_20_7_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_20_8_content')"></p>
  <h2 class="center" style="margin-top:50px;">{{ i18n.string("service_scope_annex_1") }}</h2>
  <h3 class="center" style="margin:0px;">{{ i18n.string("service_scope_specific_terms") }}</h3>
  <p class="center" style="margin:20px;font-size:23px;">{{ i18n.string("service_scope_loctite") }}</p>
  <p style="margin-top:20px;" [innerHTML]="i18n.string('service_scope_loctite_1')"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_2')"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_3')"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_4')"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_5')"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_5_1')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_5_1_content')"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_5_2')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_5_3')" class="mar-left"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_6')"></p>
  <p [innerHTML]="i18n.string('service_scope_loctite_7')"></p>
  <p style="margin-top:20px;" [innerHTML]="i18n.string('service_scope_loctite_content')"></p>
  <h2 class="center" style="margin-top:50px;">{{ i18n.string("service_scope_annex") }}</h2>
  <h3 class="center" style="margin:0px;">{{ i18n.string("service_scope_annex_list") }}</h3>
  <p style="margin-top:20px;" [innerHTML]="i18n.string('service_scope_annex_list_1')"></p>
  <p [innerHTML]="i18n.string('service_scope_annex_list_2')"></p>
  <p [innerHTML]="i18n.string('service_scope_annex_list_3')"></p>
  <p [innerHTML]="i18n.string('service_scope_annex_list_4')"></p>
  <p [innerHTML]="i18n.string('service_scope_annex_list_5')"></p>
  <p [innerHTML]="i18n.string('service_scope_annex_list_6')"></p>
  <p [innerHTML]="i18n.string('service_scope_annex_list_7')"></p>
  </div>
 
  <div class="back-btn" *ngIf="!this.isAuth">
    <app-nav-button
      [svg_icon]="'icons:arrow-left'"
      [darksecondary]="true"
      (click)="gotoLogin()"
      >{{ i18n.string("back") }}
    </app-nav-button>
  </div>
  <app-scroll-to-top></app-scroll-to-top>
</div>
