import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Notification, OTObject } from '../../model/object';
import { DateFormatSettings, UserSettings } from '../../model/settings';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { SettingsService } from '../../services/settings.service';
import { UiService } from '../../services/ui.service';
import { ObjectFilter } from './../../controls/search-filter/search-filter.component';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  filter: ObjectFilter = new ObjectFilter();
  notifications: Notification[] = [];
  notificationsCount: string = '...';
  public loading;
  public filtered;
  public notificationsCountSubscription = null;
  public notificationsSubscription = null;
  public dateFormat: DateFormatSettings = null;
  public showEdgeDeviceNotifications = false;

  get Notifications(): Notification[] {
    return this.showEdgeDeviceNotifications
      ? this.notifications.filter((item) => item.isEdgeDevice)
      : this.notifications.filter((item) => !item.isEdgeDevice);
  }

  get NumEquipmentNotifications(): number {
    return this.notifications.filter((item) => !item.isEdgeDevice).length;
  }
  get NumEdgeDeviceNotifications(): number {
    return this.notifications.filter((item) => item.isEdgeDevice).length;
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(
    private assetsService: AssetsService,
    public route: ActivatedRoute,
    public router: Router,
    public ui: UiService,
    public i18n: I18nService,
    public settings: SettingsService,
    public changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.settings.getUserSettings().subscribe((settings: UserSettings) => {
      this.dateFormat = settings.DateFormat;
    });
    this.loading = true;
    this.changeRef.detectChanges();
    // this.notificationsCount =
    //   '' + this.assetsService.getLastNotificationCount();
    // this.notificationsCountSubscription =
    //   this.assetsService.subscribeToNotificationsCount(
    //     (result: number) => (this.notificationsCount = '' + result)
    //   );
    this.notificationsSubscription =
      this.assetsService.subscribeToNotifications(
        (result: { notifications: Notification[]; filtered: boolean }) => {
          setTimeout(() => {
            this.loading = false;
            this.notifications = result.notifications.filter(item => item.notificationStatus !== 'repaired');
            this.notificationsCount = '' + result.notifications.length;
            this.filtered = result.filtered;
            this.showEdgeDeviceNotifications =
              this.showEdgeDeviceNotifications ||
              (this.NumEdgeDeviceNotifications > 0 &&
                this.NumEquipmentNotifications === 0);
            this.setSelectionPreset();
            this.changeRef.detectChanges();
          });
        }
      );
    this.ui.onNavBack(() => {
      this.router.navigate(['home'], { replaceUrl: true });
    });
    this.ui.setBackText(this.i18n.string('home'));
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  ngOnDestroy(): void {
    if (!!this.notificationsCountSubscription)
      this.notificationsCountSubscription.unsubscribe();
    if (!!this.notificationsSubscription)
      this.notificationsSubscription.unsubscribe();
  }
  onFilterChanged(filter: ObjectFilter) {
    this.filter = filter;
  }

  public onNotificationsTabBarChanged(value) {
    this.showEdgeDeviceNotifications = value === 1;
  }

  private setSelectionPreset(): void {
    function unique(value: OTObject, index: number, array: OTObject[]) {
      return (
        !!value &&
        index ===
          array.findIndex((item: OTObject) => item && item.objectID === value.objectID)
      );
    }

    const selectionPreset = [];
    const regions = this.notifications
      .map((item: Notification) => item.breadcrumbs[0])
      .filter(unique);
    const areas = this.notifications
      .map((item: Notification) => item.breadcrumbs[1])
      .filter(unique);
    const plants = this.notifications
      .map((item: Notification) => item.breadcrumbs[2])
      .filter(unique);

    if (regions.length === 1) {
      selectionPreset.push(regions[0]);
      if (areas.length === 1) {
        selectionPreset.push(areas[0]);
        if (plants.length === 1) {
          selectionPreset.push(plants[0]);
        }
      }
    }

    this.assetsService.updateSelectionPreset(selectionPreset);
  }
}
