import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StorageService } from '../../../../cc-framework/services/storage.service';
import { environment } from '../../../../environments/environment';
import { AssetDetail } from '../../../model/asset';
import { CurrentTelemetry } from '../../../model/sensor';
import { AssetsService } from '../../../services/assets.service';
import { I18nService } from '../../../services/i18n.service';
import { SettingsService } from '../../../services/settings.service';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-sensor-health-page',
  templateUrl: './sensor-health-page.component.html',
  styleUrls: ['./sensor-health-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorHealthPageComponent implements OnInit, AfterViewInit {
  public asset: AssetDetail = null;
  public loading;
  public plantID = -1;
  public edgeDeviceID = '';

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get HealthTelemetry(): CurrentTelemetry[] {
    if (!this.asset || !this.asset.health) return [];
    return this.asset.health.filter(
      // TODO: horrible hack, please remove!
      (telemetry: CurrentTelemetry) =>
        telemetry.measurementTypeID !== 1048 &&
        telemetry.measurementTypeID !== 1025 &&
        telemetry.measurementTypeID !== 1026
    );
  }

  get DisplayErrorCard(): boolean {
    return !!this.HealthTelemetry.find((item) => item.critical);
  }

  get InstallationAgentURL(): string {
    return (
      environment.installation_agent_url +
      '/login/refauth/' +
      encodeURIComponent(this.storage.Token) +
      '/' +
      encodeURIComponent(
        btoa(
          JSON.stringify({
            targetUrl:
              'plant/edge-device/' + this.plantID + '/' + this.edgeDeviceID,
          })
        )
      )
    );
  }

  get Breadcrumbs(): Location[] {
    return [...this.asset.breadcrumbs, this.asset];
  }

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    public router: Router,
    public ui: UiService,
    public i18n: I18nService,
    public settings: SettingsService,
    public storage: StorageService,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.refresh();
    this.ui.onNavBack(() => {
      this.goBack();
    });
    this.ui.OnOrientationChange.subscribe((mobile: boolean) => {
      this.changeRef.detectChanges();
    });
    this.ui.OnOrientationChange.subscribe((tablet: boolean) => {
      this.changeRef.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  refresh(disableCache = false) {
    this.loading = true;
    const assetID = +this.route.snapshot.paramMap.get('id');
    this.settings.getUserSettings().subscribe((settings) => {
      this.assetsService
        .getAsset(assetID, disableCache, settings.MeasurementSystem)
        .subscribe((result: any) => {
          this.loading = false;
          this.asset = result.asset;
          this.plantID = result.plant ? result.plant.objectID : -1;
          const temp = this.HealthTelemetry.find((item) => item.critical);
          const sensor =
            !!temp && !!temp.sensor
              ? this.asset.sensors.find(
                  (item) => item.sensorID === temp.sensor.sensorID
                )
              : null;
          this.edgeDeviceID = !!sensor ? sensor.edgeDigitID : '';
          this.ui.setBackText(this.i18n.parse(this.asset.objectName));
          this.changeRef.detectChanges();
        });
    });
  }

  toSensorValue(sensorID, measurementTypeID) {
    if (sensorID && measurementTypeID) {
      this.router.navigate([
        'sensor-value',
        this.asset.objectID,
        sensorID,
        measurementTypeID,
      ]);
    }
  }

  goBack() {
    this.router.navigate(['asset', this.asset.objectID, -1], {
      replaceUrl: true,
    });
  }
}
