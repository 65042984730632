import { Component, OnInit } from '@angular/core';
import { Exception } from '../../../cc-framework/util/exception';

class DialogOptions {
  error: boolean;
  title: string;
  message: string;
  timeout: number;
  hasCloseButton: boolean;
  onClose: ((any?) => any);
  onYes: ((any?) => any);
  onNo: ((any?) => any);
  onCancel: ((any?) => any);
}

const testOptions = {
  error: true,
  title: 'Lorem Ipsum',
  message: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna',
  timeout: -1,
  hasCloseButton: true,
  onClose: () => console.log('close'),
  onYes:  () => console.log('yes'),
  onNo:  () => console.log('no'),
  onCancel:  () => console.log('cancel')
};

let timeout = null;

@Component({
  selector: 'cc-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  public options: DialogOptions = null;

  constructor() { }

  ngOnInit() {
  }

  public exception(exception: Exception, locale?: string) {
    this.set(exception.getDialogOptions(locale) as DialogOptions);
  }

  public set(options: DialogOptions) {
    if (timeout) clearTimeout(timeout);
    // if (this.options != null) this.onClose();
    this.options = options;
    if (this.options.timeout > 0) {
      timeout = setTimeout(() => this.onClose(), this.options.timeout);
    }
  }

  public onClose() {
    if (this.options && this.options.onClose) {
      this.options.onClose();
    }
    this.options = null;
  }

  public onYes() {
    if (this.options && this.options.onYes) {
      this.options.onYes();
    }
    this.onClose();
  }

  public onNo() {
    if (this.options && this.options.onNo) {
      this.options.onNo();
    }
    this.onClose();
  }

  public onCancel() {
    if (this.options && this.options.onCancel) {
      this.options.onCancel();
    }
    this.onClose();
  }
}
