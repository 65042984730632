import * as convert from 'convert-units';

import { MeasurementSystemSettings } from './settings';

export class TelemetryHistory {
  public metadata: {
    measurementTypeID: number;
    displayGraph: boolean;
    displayText: boolean;
    title: string;
    svg: string;
    unit: string;
    warning: boolean;
    critical: boolean;
    inactive: boolean;
    recommendation: string;
    valueType: string;
    zeroBasedValueRange: boolean; // if true, range always starts at 0 or below
    // first- and lastMeasurement are string timestamps returned by the database, mirroring the timestamps of the
    // first and last entries in the database (does not depend on the timeframe that was set)
    firstMeasurement: string;
    lastMeasurement: string;
  }[];

  public telemetry: {
    measurementTypeID: number;
    value: number;
    measuredvalue: string;
    timestamp: string;
  }[];

  public thresholds: {
    sensorID: number;
    measurementTypeID: number;
    thresholdMin: number;
    thresholdMax: number;
    warning: boolean;
    error: boolean;
  }[];

  public GetGraphData(): GraphData[] {
    return (
      this.metadata
        // prototype solution for displaying steam trap inlet and outlet data in single graph:
        .filter(
          (metadata) =>
            metadata.measurementTypeID !== 1074 &&
            metadata.measurementTypeID !== 1026
        )
        .map((metadata) => {
          // prototype solution for displaying steam trap inlet and outlet data in single graph:
          let co_metadata = null;
          switch (metadata.measurementTypeID) {
            case 1025:
              co_metadata = this.metadata.find(
                (_metadata) => _metadata.measurementTypeID === 1026
              );
              break;
            case 1073:
              co_metadata = this.metadata.find(
                (_metadata) => _metadata.measurementTypeID === 1074
              );
              break;
            case 1076:
              co_metadata = this.metadata.find(
                (_metadata) => _metadata.measurementTypeID === 1077
              );
              break;
          }
          let telemetry_output = this.telemetry.filter(
            (telemetry) =>
              telemetry.measurementTypeID === metadata.measurementTypeID ||
              (!!co_metadata &&
                telemetry.measurementTypeID === co_metadata.measurementTypeID)
          );
          // data preparation for boolean domain (currently only tilt) data
          const booleanDomain =
            metadata.valueType === 'flag' || metadata.valueType === 'boolean';
          if (booleanDomain && this.telemetry.length > 0) {
            telemetry_output = telemetry_output
              .map((item, index, array) => {
                if (index < array.length - 1) {
                  return [
                    item,
                    {
                      ...item,
                      timestamp: new Date(
                        +new Date(array[index + 1].timestamp) - 1000
                      ).toISOString(), // insert a second telemetry item a second before the next one
                    },
                  ];
                } else {
                  return [item];
                }
              })
              .flat(2);
            metadata.lastMeasurement = new Date().toISOString();
            const last = telemetry_output[telemetry_output.length - 1];
            telemetry_output.push({
              ...last,
              timestamp: metadata.lastMeasurement,
            });
          }

          return {
            metadata: metadata,
            co_metadata: co_metadata,
            telemetry: telemetry_output,
            thresholds: this.thresholds.filter(
              (threshold) =>
                threshold.measurementTypeID === metadata.measurementTypeID ||
                (!!co_metadata &&
                  threshold.measurementTypeID === co_metadata.measurementTypeID)
            ),
          };
        })
    );
  }

  public ApplyMeasurementSystem(
    measurementSystem: MeasurementSystemSettings
  ): void {
    this.metadata = this.metadata.map((metadata) => {
      const new_unit =
        measurementSystem.abbreviation === 'im'
          ? convertUnitToImperial(metadata.unit)
          : convertUnitToMetric(metadata.unit);
      //if (new_unit !== metadata.unit) {
      this.telemetry = this.telemetry.map((telemetry) => {
        if (telemetry.measurementTypeID === metadata.measurementTypeID) {
          telemetry.value = convertValue(
            telemetry.value,
            metadata.unit,
            new_unit
          );
          telemetry.measuredvalue = telemetry.value + metadata.unit;
        }

        return telemetry;
      });

      this.thresholds = this.thresholds.map((threshold) => {
        if (threshold.measurementTypeID === metadata.measurementTypeID) {
          threshold.thresholdMax = convertValue(
            threshold.thresholdMax,
            metadata.unit,
            new_unit
          );
          threshold.thresholdMin = convertValue(
            threshold.thresholdMin,
            metadata.unit,
            new_unit
          );
        }
        return threshold;
      });
      metadata.unit = new_unit;
      //}
      /* if (metadata.unit === '°C') {
          // transform to Fahrenheit
          metadata.unit = '°F';

          this.telemetry = this.telemetry.map((telemetry) => {
            if (telemetry.measurementTypeID === metadata.measurementTypeID) {
              telemetry.value = CelsiusToFahrenheit(telemetry.value);
              telemetry.measuredvalue = telemetry.value + metadata.unit;
            }
            return telemetry;
          });
          this.thresholds = this.thresholds.map((threshold) => {
            if (threshold.measurementTypeID === metadata.measurementTypeID) {
              threshold.thresholdMax = CelsiusToFahrenheit(
                threshold.thresholdMax
              );
              threshold.thresholdMin = CelsiusToFahrenheit(
                threshold.thresholdMin
              );
            }
            return threshold;
          });
        } */
      return metadata;
    });
  }
}

function FahrenheitToCelsius(value: number) {
  return (value - 32) * 0.5556;
}

function CelsiusToFahrenheit(value: number) {
  return value * 1.8 + 32;
}
export class GraphData {
  public metadata: {
    measurementTypeID: number;
    displayGraph: boolean;
    displayText: boolean;
    title: string;
    svg: string;
    unit: string;
    warning: boolean;
    critical: boolean;
    recommendation: string;
    valueType: string;
    zeroBasedValueRange: boolean; // if true, range always starts at 0 or below
    firstMeasurement: string;
    lastMeasurement: string;
  };

  // prototype solution: integrate multiple telemetry sources into one graph
  public co_metadata: {
    measurementTypeID: number;
    displayGraph: boolean;
    displayText: boolean;
    title: string;
    svg: string;
    unit: string;
    warning: boolean;
    critical: boolean;
    recommendation: string;
    valueType: string;
    zeroBasedValueRange: boolean; // if true, range always starts at 0 or below
    firstMeasurement: string;
    lastMeasurement: string;
  };

  public telemetry: {
    measurementTypeID: number;
    value: number;
    measuredvalue: string;
    timestamp: string;
  }[];

  public thresholds: {
    sensorID: number;
    measurementTypeID: number;
    thresholdMin: number;
    thresholdMax: number;
    warning: boolean;
    error: boolean;
  }[];

  public loading?: boolean = false;
  public error?: boolean = false;
}

export function GenerateFakeTelemetry(measurementTypeID: number, unit: string) {
  const startTimestamp = new Date('December 17, 2019 03:24:00');
  const endTimestamp = new Date();
  const limit = daysBetween(startTimestamp, endTimestamp);
  // var limit = 500000; //increase number of dataPoints by increasing this

  const base_y = 0.5;
  const deviation = 0.05;

  let y = base_y;
  const x = new Date(startTimestamp);
  const data = [];

  for (let i = 0; i <= limit; i++) {
    y += Math.random() * deviation - deviation / 2;
    data.push({
      measurementTypeID: measurementTypeID,
      value: y,
      measuredvalue: y + ' ' + unit,
      timestamp: x.toISOString(),
    });
    x.setDate(x.getDate() + 1);
  }
  y += Math.random() * deviation - deviation / 2;
  data.push({
    measurementTypeID: measurementTypeID,
    value: y,
    measuredvalue: y + ' ' + unit,
    timestamp: new Date().toISOString(),
  });
  return data;
}

function treatAsUTC(date): number {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return +result;
}

function daysBetween(startDate, endDate): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

function determineMeasurementType(unit: string): string {
  // length, area, volume, temperature, pressure,
  if (!unit) return '';
  switch (unit.toLocaleLowerCase()) {
    case 'mm':
    case 'cm':
    case 'dm':
    case 'm':
    case 'km':
      return 'length';
    case 'mm²':
    case 'cm²':
    case 'dm²':
    case 'm²':
    case 'a':
    case 'ha':
    case 'km²':
      return 'area';
    case 'mm³':
    case 'cm³':
    case 'dm³':
    case 'l':
    case 'm³':
    case 'km³':
      return 'volume';
    case 'g':
    case 'kg':
      return 'mass';
    case '°c':
      return 'temperature';
    default:
      return '';
  }
}

function convertValue(value: number, old_unit: string, new_unit): number {
  const measurementType = determineMeasurementType(old_unit);
  switch (measurementType) {
    case 'length':
    case 'area':
    case 'volume':
    case 'mass':
    case 'temperature':
      const a: any = old_unit.replace('°', '');
      const b: any = new_unit.replace('°', '');
      return Math.round(convert(value).from(a).to(b) * 10) / 10;
      break;
    default:
      return value;
  }
}

function convertUnitToImperial(unit: string) {
  if (!unit || !unit.toLocaleLowerCase) return unit;
  switch (unit.toLocaleLowerCase().replace(' ', '')) {
    case 'mm':
      return 'in';
    case 'cm':
      return 'in';
    case 'dm':
      return 'in';
    case 'm':
      return 'ft';
    case 'km':
      return 'ml';
    case 'mm²':
      return 'in²';
    case 'cm²':
      return 'in²';
    case 'dm²':
      return 'in²';
    case 'm²':
      return 'ft²';
    case 'a':
      return 'ft²';
    case 'ha':
      return 'ft²';
    case 'km²':
      return 'ml²';
    case 'mm³':
      return 'in³';
    case 'cm³':
      return 'in³';
    case 'dm³':
      return 'in³';
    case 'l':
      return 'ft³';
    case 'm³':
      return 'ft³';
    case 'km³':
      return 'ml³';
    case 'g':
      return 'oz';
    case 'kg':
      return 'lb';
    case '°c':
      return '°F';
    default:
      return unit;
  }
}

function convertUnitToMetric(unit: string) {
  if (!unit || !unit.toLocaleLowerCase) return unit;
  switch (unit.toLocaleLowerCase().replace(' ', '')) {
    case 'in':
      return 'cm';
    case 'ft':
      return 'm';
    case 'ml':
      return 'km';
    case 'in²':
      return 'cm²';
    case 'ft²':
      return 'm²';
    case 'ml²':
      return 'km²';
    case 'in³':
      return 'cm³';
    case 'ft³':
      return 'm³';
    case 'ml³':
      return 'km³';
    case 'oz':
      return 'g';
    case 'lb':
      return 'kg';
    case '°f':
      return '°C';
    default:
      return unit;
  }
}
