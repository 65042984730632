<!-- DON'T add a routerLink property to the a-element. Thank you! -->
<a
  class="asset-nav-button body-bold"
  [href]="!!href ? href : '#'"
  [class.disconnected]="
    !Connected && !CriticalStatus && !WarningStatus && !InactiveStatus
  "
  [class.working]="
    !!Connected && !Warning && !Critical && !InActive && !IsArchived
  "
  [class.warning]="(Warning && !InActive && !IsArchived) || WarningStatus"
  [class.critical]="
    (!!Connected && Critical && !InActive && !IsArchived) || CriticalStatus
  "
  [class.inactive]="(InActive && !IsArchived) || InactiveStatus"
  [class.IsArchived]="IsArchived"
  [class.blackBG]="blackBG"
  [class.blackCoral]="blackCoral"
  [class.button-link]="buttonlink"
  [class.center]="center"
  role="link"
  (click)="onClick($event)"
>
  <div class="asset-nav-button-content" [class.no-icon]="!show_icon">
    <i
      nz-icon
      *ngIf="svg_icon && show_icon"
      [nzType]="svg_icon"
      class="svg-icon"
      [class.fillgrey]="!!asset && !Connected"
      [class.fillgreen]="
        !!asset &&
        !IsArchived &&
        !!Connected &&
        !Warning &&
        !Critical &&
        !InActive &&
        !blackBG &&
        !blackCoral
      "
      [class.fillwhite]="
        (!asset && !WarningStatus) ||
        (!IsArchived &&
          !!Connected &&
          (!Warning || !!Critical || !!InActive || blackBG || blackCoral))
      "
      [class.circle-icon]="svg_icon == 'icons:circle'"
      aria-hidden="true"
    ></i>
    <span>
      <ng-content></ng-content>
    </span>
    <i
      nz-icon
      *ngIf="
        svg_icon_right &&
        ((!InactiveStatus && !WarningStatus && !CriticalStatus) || IsArchived)
      "
      [nzType]="svg_icon_right"
      [class.fillwhite]="
        !asset ||
        (!!Connected && !Warning && !!Critical && !IsArchived) ||
        (!!InActive && !IsArchived)
      "
      [class.fillgrey]="!!asset && !Connected"
      [class.fillblack]="
        (!!asset && !!Connected && !Warning && !Critical && !InActive) ||
        (IsArchived && Connected)
      "
      class="svg-icon right-icon"
    ></i>
  </div>
</a>
