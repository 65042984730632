import { Exception } from '../util/exception';


export class HostUnreachableException extends Exception {
  constructor(public url: string, private repeat: boolean) {
    super();
    this.name.set('de', 'Host nicht erreichbar');
    this.name.set('en', 'Host unreachable');
    this.message.set('de', 'Stellen Sie sicher, dass eine Verbindung mit dem Netzwerk besteht.');
    this.message.set('en', 'Make sure, you are connected to the network.');
    const error_suffix = 'Host URL: "' + url + '"';
    this.error = new Error('Host could not be reached.' + error_suffix);
  }

  public getDialogOptions(locale: string): object {
    if (this.repeat) return null;
    const options = super.getDialogOptions(locale);
    options['error'] = false;
    options['message'] = 'Offline Modus';
    options['title'] = null;
    options['timeout'] = 5000;
    options['icon'] = 'ban';

    return options;
  }
}

export class RequestTimeoutException extends Exception {
  constructor(timeout?: number) {
    super();
    this.name.set('de', 'Zeitüberschreitung');
    this.name.set('en', 'Timeout Error');
    this.message.set('de', 'Der Dienst benötigt zu lange zum antworten. Überprüfen sie ihre Netzwerkverbindung.');
    this.message.set('en', 'The Webservice took too long to respond. Check your network connection.');
    this.error = new Error('The Webservice took too long to respond.' + timeout ? '(timeout: ' + timeout.toString + ')' : '');
  }
}

export class UnauthorizedException extends Exception {
  constructor() {
    super();
    this.name.set('de', 'Autorisierung fehlgeschlagen');
    this.name.set('en', 'Authorization Failed');
    this.message.set('de', 'Der Benutzer konnte nicht autorisiert werden. Bitte melden Sie sich mit einem gültigen Benutzerkonto an.');
    this.message.set('en', 'The user could not be authorized. Please log in with a valid user account.');
    this.error = new Error('The user could not be authorized.');
  }

  public getDialogOptions(locale: string): object {
    const options = super.getDialogOptions(locale);
    const that = this;

    return options;
  }
}

// this is a general exception that informs about the status code that was returned by a request to a web service
export class WebServiceException extends Exception {
  constructor(statusCode: number, statusText: string) {
    super();
    const statusString = statusCode.toString() + ': ' + statusText;

    this.name.set('de', 'Fehler mit Statuscode ' + statusString);
    this.name.set('en', 'Error with Status Code ' + statusString);
    this.message.set('de', 'Fehler bei der Kommunikation mit einem Webdienst.');
    this.message.set('en', 'Failed to communicate with web service.');
  }
}

export class UnexpectedException extends Exception {
  constructor(message) {
    super();
    this.name.set('de', 'Ein unerwarteter Fehler ist aufgetreten');
    this.name.set('en', 'An Unexpected Error Occured');
    this.message.set('de', message);
    this.message.set('en', message);
  }
}

export class AppOfflineException extends Exception {
  constructor() {
    super();
    this.name.set('de', 'Die App ist offline.');
    this.name.set('en', 'The App is offline.');
    this.message.set('de', 'Stellen Sie sicher, dass eine Verbindung mit dem Netzwerk besteht.');
    this.message.set('en', 'Make sure, you are connected to the network.');
  }
}
