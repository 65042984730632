import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Notification } from '../../model/object';
import { DateFormatSettings } from '../../model/settings';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  public overlayDocumentation: boolean = false;
  public overlayInstructions: boolean = false;
  public documentationAdded: boolean = false;
  @Input() dateFormat: DateFormatSettings = null;
  @Input() notification: Notification;
  @Input() filtered: boolean;
  // get isMobile(): boolean {
  //   return this.ui.IsMobile;
  // }
  // get isTablet(): boolean {
  //   return this.ui.IsTablet;
  // }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get DateFormat(): string {
    return !!this.dateFormat && this.dateFormat.formatString === 'MM/dd/yyyy'
      ? 'MM/dd/yyyy | h:mm a'
      : 'dd-MM-yyyy | HH:mm';
  }
  constructor(
    public ui: UiService,
    private assetsService: AssetsService,
    public i18n: I18nService,
    private router: Router
  ) {}

  ngOnInit() {
    this.documentationAdded = false;
  }

  onDocumentationAdded() {
    this.documentationAdded = true;
  }

  onRepairAndReplace() {
    this.router.navigate([
      'repair',
      this.notification.objectID,
      this.notification.sensorID,
    ]);
  }

  onAssetName() {
      this.router.navigate([
        'asset',
        this.notification.objectID, 
        this.notification.sensorID
      ]);
  }
}
