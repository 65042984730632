import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { UiService } from '../../../services/ui.service';
import { TabBarComponent } from '../tab-bar.component';

@Component({
  selector: 'app-tab-bar-item',
  templateUrl: './tab-bar-item.component.html',
  styleUrls: ['./tab-bar-item.component.scss'],
  animations: [
    trigger('moveMarker', [
      state(
        '-3',
        style({
          left: '-300%',
        })
      ),
      state(
        '-2',
        style({
          left: '-200%',
        })
      ),
      state(
        '-1',
        style({
          left: '-100%',
        })
      ),
      state(
        '0',
        style({
          left: '0%',
        })
      ),
      state(
        '1',
        style({
          left: '100%',
        })
      ),
      state(
        '2',
        style({
          left: '200%',
        })
      ),
      state(
        '3',
        style({
          left: '300%',
        })
      ),
      transition('0 => -3', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('0 => -2', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('0 => -1', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('0 => 1', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('0 => 2', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
      transition('0 => 3', [animate('0.25s cubic-bezier(0.35, 0, 0.25, 1)')]),
    ]),
  ],
})
export class TabBarItemComponent implements OnInit {
  public preIsActive = true;
  public isActive = false;
  public inMovement = false;

  public moveMarker = '0';
  public tabBarReference: TabBarComponent = null;
  public index: number = -1;
  public opaque = false;
  public icon = false;
  @Input() fa_icon: string = '';
  @Input() svg_icon: string = '';
  @Input() inactive: boolean = false;
  disabled: boolean = false;

  get IsChrome(): boolean {
    return this.ui.IsChrome;
  }

  constructor(public changeRef: ChangeDetectorRef, public ui: UiService) {}

  ngOnInit() {}

  onClick() {
    if (this.disabled) return;
    if (!this.inMovement) {
      this.tabBarReference.onTabSelect(this.index);
    }
  }
}
