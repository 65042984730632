import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';

import { StorageService } from '../../cc-framework/services/storage.service';
import { environment } from '../../environments/environment';

const VAPID_PUBLIC_KEY =
    'BBqgbENcfioGEWKbXHJp0nqKrAkkDgXzIa4Z-IWhzX0M90cnojTL0rhMrflpjw6QDGQ7cawCgeBhXl8UC-AUQzg',
  VAPID_PRIVATE_KEY = 'z24F0J_xk5vPjhloRtHMswx6ga0Tf2FlsVwuXwNCQnE';

export enum PushPermissionStatus {
  pending = 'pending',
  granted = 'granted',
  denied = 'denied',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public subscribed: boolean = false;

  get PermissionStatus(): PushPermissionStatus {
    return this.subscribed
      ? PushPermissionStatus.granted
      : PushPermissionStatus.pending;
  }
  constructor(
    private http: HttpClient,
    private swPush: SwPush,
    private storage: StorageService
  ) {
    this.swPush.subscription.subscribe({
      next: (sub) => {
        this.subscribed = sub !== null;
      },
    });
  }

  public subscribeToNotifications(
    resolve: (status: PushPermissionStatus) => void = () => {},
    reject: (reason: string) => void = () => {}
  ) {
    try {
      if (!this.swPush.isEnabled)
        reject(
          'Unable to subscribe to notifications: Service worker is not enabled.'
        );
      if (this.subscribed) resolve(PushPermissionStatus.granted);
      this.swPush.notificationClicks.subscribe((arg) => {
        switch (arg.action) {
          case 'explore':
            this.acknowledgeReceivedNotification(
              arg.notification.data.notificationID
            ).subscribe(() => {});
            break;
          case 'dismiss':
            this.acknowledgeReceivedNotification(
              arg.notification.data.notificationID
            ).subscribe(() => {});
            break;
          default:
            this.acknowledgeReceivedNotification(
              arg.notification.data.notificationID
            ).subscribe(() => {});
        }
      });
      this.swPush
        .requestSubscription({
          serverPublicKey: VAPID_PUBLIC_KEY,
        })
        .then((sub) => {
          this.addPushSubscriber(sub).subscribe(() => (this.subscribed = true));
          setTimeout(() => resolve(PushPermissionStatus.granted), 500);
        })
        .catch((err) => {
          console.error('Could not subscribe to notifications', err);
          setTimeout(() => resolve(PushPermissionStatus.denied), 500);
        });
    } catch (e) {
      console.error('Could not subscribe to notifications', e);
      reject(e.message);
    }
  }

  private addPushSubscriber(sub: any) {
    const temp = window.location.href.split('/'); // used to pass application host information to service
    const baseTags = document.getElementsByTagName('base');
    const basePath = baseTags.length
      ? baseTags[0].href.substr(location.origin.length, 999)
      : '';
    let host = temp[0] + '//' + temp[2] + basePath;
    if (host.lastIndexOf('/') === host.length - 1) {
      host = host.slice(0, host.length - 1);
    }
    return this.http.post(
      environment.host + 'api/notification/subscribe',
      {
        ...sub.toJSON(),
        host: host,
      },
      {
        headers: {
          Authorization: this.storage.Token,
        },
      }
    );
  }
  private acknowledgeReceivedNotification(notificationID: number) {
    return this.http.post(
      environment.host + 'api/notification/acknowledge/' + notificationID,
      {},
      {
        headers: {
          Authorization: this.storage.Token,
        },
      }
    );
  }

  // test:
  private send() {
    const payload = {
      notification: {
        title: 'Angular News',
        body: 'Newsletter Available!',
        icon: 'assets/main-page-logo-small-hat.png',
        vibrate: [100, 50, 100],
        data: {
          dateOfArrival: Date.now(),
          primaryKey: 1,
        },
        actions: [
          {
            action: 'explore',
            title: 'Go to the site',
          },
        ],
      },
    };
    return this.http.post(environment.host + 'api/notification/test', payload, {
      headers: {
        Authorization: this.storage.Token,
      },
    });
  }

  public onTest() {
    this.send().subscribe(() => console.warn('done'));
  }
}
