import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
  @Input() opaque = true;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  isVisible = false;

  // get isMobile() {
  //   return this.ui.IsMobile;
  // }
  // get isTablet() {
  //   return this.ui.IsTablet;
  // }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(private ui: UiService) {}

  ngOnInit() {
    this.isVisible = true;
  }

  closePopup() {
    this.isVisible = false;
    this.close.emit();
  }
}
