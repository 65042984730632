<form nz-form [formGroup]="loginForm" [class.mobile]="isMobile" [class.tablet]="isTablet">

  <div class="toast success" *ngIf="success">
    <i nz-icon class="inline-error-icon multicolorIcon" nzType="icons:check-circle-filled"></i>
    <span>{{ i18n.string('changes_successful') }}</span>
  </div>

  <div class="toast error" *ngIf="!(authorizing || authorized || isLoggedIn)">
    <i nz-icon class="inline-error-icon fillwhite" nzType="icons:error-exclamation-circle"></i>
    <div class="toast-column"><span>{{ i18n.string('change_password_unauthorized') }}</span>
      <a class="toast-link" [routerLink]="" (click)="onRequestNewMail()">{{ i18n.string('resend_mail') }}</a>
    </div>
  </div>

  <div class="toast error" *ngIf="error">
    <i nz-icon class="inline-error-icon fillwhite" nzType="icons:error-exclamation-circle"></i>
    <span>{{ i18n.string('changes_problem') }}</span>
  </div>

  <div class="toast error" *ngIf="!pwEqual">
    <i nz-icon class="inline-error-icon fillwhite" nzType="icons:error-exclamation-circle"></i>
    <span>{{ i18n.string('passwords_must_be_equal') }}</span>
  </div>


  <ng-container *ngIf="credentialLogin">
    <nz-form-item *ngIf="isLoggedIn">
      <nz-form-label>{{I18N.String('current_password')}}</nz-form-label>
      <nz-input-group [nzSuffix]="passwordEyeCur" [class.error]="displayCurPWError">
        <input #curPassword formControlName="curPassword" nz-input [placeholder]="I18N.String('current_password')"
          [type]="curPasswordVisible ? 'text' : 'password'" [(ngModel)]="curPW" [nzSuffix]="passwordEyeCur"
          oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
          (change)="validateCurPassword()" (keydown.enter)="onSubmitCurPW()" />
      </nz-input-group>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>{{I18N.String('new_password_1')}}</nz-form-label>
      <nz-input-group [nzSuffix]="passwordEye1" [class.error]="displayPw1Error">
        <input #password1 formControlName="password1" nz-input [placeholder]="I18N.String('new_password_1')"
          [type]="password1Visible ? 'text' : 'password'" [(ngModel)]="pw1" [nzSuffix]="passwordEye1"
          oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
          (input)="onInput()" (keydown.enter)="onSubmitPW1()" />
      </nz-input-group>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>{{I18N.String('new_password_2')}}</nz-form-label>
      <nz-input-group [nzSuffix]="passwordEye2" [class.error]="displayPw2Error">
        <input #password2 formControlName="password2" nz-input [type]="password2Visible ? 'text' : 'password'"
          [placeholder]="I18N.String('new_password_2')" [(ngModel)]="pw2" (input)="validatePassword()"
          oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
          (keydown.enter)="onSubmitPW2()" [nzSuffix]="passwordEye2" />
      </nz-input-group>
    </nz-form-item>
    <div class="password-validation-wrapper">
      <div [class.valid]="pwValidation1" [class.invalid]="pwValidationError1">
        <i nz-icon nzType="icons:check-circle" class="svg-icon" [class.fillgreen]="pwValidation1"
          [class.fillblackcoral]="!pwValidation1" *ngIf="!pwValidationError1"></i>
        <i nz-icon nzType="icons:cross-circle" class="svg-icon fillred" *ngIf="pwValidationError1"></i>
        <span>{{i18n.string('pw_validation1')}}</span>
      </div>
      <div [class.valid]="pwValidation2" [class.invalid]="pwValidationError2">
        <i nz-icon nzType="icons:check-circle" class="svg-icon" [class.fillgreen]="pwValidation2"
          [class.fillblackcoral]="!pwValidation2" *ngIf="!pwValidationError2"></i>
        <i nz-icon nzType="icons:cross-circle" class="svg-icon fillred" *ngIf="pwValidationError2"></i>
        <span>{{i18n.string('pw_validation2')}}</span>
      </div>
      <div [class.valid]="pwValidation3" [class.invalid]="pwValidationError3">
        <i nz-icon nzType="icons:check-circle" class="svg-icon" [class.fillgreen]="pwValidation3"
          [class.fillblackcoral]="!pwValidation3" *ngIf="!pwValidationError3"></i>
        <i nz-icon nzType="icons:cross-circle" class="svg-icon fillred" *ngIf="pwValidationError3"></i>
        <span>{{i18n.string('pw_validation3')}}</span>
      </div>
      <div [class.valid]="pwValidation4" [class.invalid]="pwValidationError4">
        <i nz-icon nzType="icons:check-circle" class="svg-icon" [class.fillgreen]="pwValidation4"
          [class.fillblackcoral]="!pwValidation4" *ngIf="!pwValidationError4"></i>
        <i nz-icon nzType="icons:cross-circle" class="svg-icon fillred" *ngIf="pwValidationError4"></i>
        <span>{{i18n.string('pw_validation4')}}</span>
      </div>
    </div>
    <nz-form-item>
      <nz-form-control>
        <div class="loginButtonWrapper loginButtonMarginTop">
          <app-nav-button [primary]="true" svg_icon="icons:forgot_password" (click)="setNewPassword()"
            [disabled]="SubmitDisabled">
            {{I18N.String('change_password_submit')}}
          </app-nav-button>
        </div>
      </nz-form-control>
    </nz-form-item>
    <ng-template #passwordEyeCur>
      <i nz-icon class="passwordEye" [class.fillgrey]="!curPasswordVisible"
        [nzType]="!curPasswordVisible ? 'icons:crossed-eye' : 'icons:eye'"
        (click)="curPasswordVisible = !curPasswordVisible"></i>
      <i nz-icon class="inline-error-icon fillred" nzType="icons:error-exclamation-circle"
        *ngIf="displayCurPWError"></i>
    </ng-template>
    <ng-template #passwordEye1>
      <i nz-icon class="passwordEye" [class.fillgrey]="!password1Visible"
        [nzType]="!password1Visible ? 'icons:crossed-eye' : 'icons:eye'"
        (click)="password1Visible = !password1Visible"></i>
      <i nz-icon class="inline-error-icon fillred" nzType="icons:error-exclamation-circle" *ngIf="displayPw1Error"></i>
    </ng-template>
    <ng-template #passwordEye2>
      <i nz-icon class="passwordEye" [class.fillgrey]="!password2Visible"
        [nzType]="!password2Visible ? 'icons:crossed-eye' : 'icons:eye'"
        (click)="password2Visible = !password2Visible"></i>
      <i nz-icon class="inline-error-icon fillred" nzType="icons:error-exclamation-circle" *ngIf="displayPw2Error"></i>
    </ng-template>

  </ng-container>

</form>