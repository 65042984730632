import { Component, Input, OnInit } from '@angular/core';

import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-banner-maintenance',
  templateUrl: './banner-maintenance.component.html',
  styleUrls: ['./banner-maintenance.component.scss'],
})
export class BannerMaintenanceComponent implements OnInit {
  @Input() maintenance: boolean;
  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(public i18n: I18nService, private ui: UiService) {}

  ngOnInit() {}
}
