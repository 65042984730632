import { ElementRef } from '@angular/core';

import { I18nService } from '../../services/i18n.service';
import { BarChart } from './bar-chart';
import { GanttChart } from './gantt-chart';
import { LineChart } from './line-chart';
import { MultiLineChart } from './multi-line-chart';
import { PieChart } from './pie-chart';
import { Visualization } from './visualization.interface';

export enum VisualizationType {
  line = 1,
  multi_line,
  gantt,
  bars,
  pie,
}

export function ConstructVizualization(
  type: VisualizationType,
  chartContainer: ElementRef[],
  id: string,
  i18n: I18nService
): Visualization {
  switch (type) {
    case VisualizationType.multi_line:
      return new MultiLineChart(chartContainer[0], id, i18n);
    case VisualizationType.gantt:
      return new GanttChart(chartContainer, id, i18n);
    case VisualizationType.bars:
      return new BarChart(chartContainer, id, i18n);
    case VisualizationType.pie:
      return new PieChart(chartContainer[0], id, i18n);
    default:
      return new LineChart(chartContainer[0], id, i18n);
  }
}
