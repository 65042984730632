export const I18N_LEGALS = {
  terms_of_use: { de: 'Nutzungsbedingungen', en: 'Terms of Use' },
  data_protection_declaration: {
    de: 'Datenschutzerklärung',
    en: 'Data Protection Declaration',
  },
  note_for_us_residents: {
    de: 'Hinweis für Einwohner der USA',
    en: 'Note for US residents',
  },

  note_for_US_content: {
    de: 'Hinweis für Einwohner der USA',
    en: 'Note for US residents',
  },
  website_link: {
    de: 'Bitte besuchen Sie [www.loctite-pulse.us] für Datenanalyse- und Überwachungsdienste, die von Henkel Corp. in den Vereinigten Staaten angeboten werden',
    en: 'Please visit [www.loctite-pulse.us] for data analysis and monitoring services offered by Henkel Corp. in the United States',
  },
  learn_more_link: { de: 'Erfahren Sie mehr', en: 'Learn more' },
  note_website_content: {
    de: 'Diese Websites enthalten Informationen und Dokumente, die ausschließlich zu Informationszwecken dienen. Sie stellen weder ein Angebot noch eine Aufforderung dar, Finanzinstrumente – insbesondere Wertpapiere – zu zeichnen, zu kaufen, zu kaufen oder zu halten. Diese Informationen und Dokumente richten sich nicht an Personen mit Wohnsitz in den Vereinigten Staaten von Amerika und dürfen nicht an Personen verteilt werden',
    en: 'These websites contain information and documents which are for information purposes only. They do not constitute an offer or an invitation to subscribe for, purchase, buy or hold any financial instruments – securities in particular. These information and documents are not directed at and may not be distributed to any person residing in the United States of America',
  },
   //Terms of Use doc
   terms_of_use_new_title: {
    de: 'Nutzungsbedingungen für die LOCTITE Pulse® App',
    en: 'Terms of Use of LOCTITE Pulse® App',
  },
  scope_1: {
    de: '1. Umfang',
    en: '1. Scope'
  },
  scope_1_1_content: {
     de: '1.1&nbsp;&nbsp;Die folgenden Bedingungen ("<b>App-Bedingungen</b>") enthalten Verpflichtungen gegenüber der Henkel AG & Co. KGaA ("<b>Henkel</b>"; "<b>wir</b>"; "<b>uns</b>"), die Sie im Rahmen der Nutzung der Datenanalysedienste zur Überwachung von Industrieanlagen ("<b>Dienste</b>"), über die LOCTITE Pulse® App "Progressive Web App" (PWA) ("<b>LOCTITE Pulse® App</b>") einzuhalten haben.',
     en: '1.1&nbsp;&nbsp;The following terms and conditions (“<b>App Terms</b>”) set forth obligations that apply to your use of the data analytics services for monitoring industrial facilities (“<b>Services</b>”) accessible via the LOCTITE Pulse® App “Progressive Web App” (PWA) (“<b>LOCTITE Pulse® App</b>”) vis-á-vis Henkel AG & Co. KGaA (“<b>Henkel</b>”; “<b>we</b>”; “<b>us</b>”).'
  },
  scope_1_2_content: {
     de: '1.2&nbsp;&nbsp;Die Nutzung der Dienste über die LOCTITE Pulse® App erfordert den Abschluss und die Aufrechterhaltung eines Dienstleistungsvertrags ("<b>Dienstleistungsvertrag</b>") mit Henkel, einem verbundenen Unternehmen von Henkel oder mit einem von oder im Namen von Henkel ernannten Vertriebspartner (jeweils ein "<b>Vertragspartner</b>"). Die Dienstleistungsvereinbarungen bleiben von den App-Bedingungen unberührt. Henkel übernimmt im Rahmen oder im Zusammenhang mit den App-Bedingungen keine Verpflichtungen oder Haftungen, insbesondere nicht in Bezug auf die Bereitstellung oder Nutzung der Dienstleistungen oder die Erfüllung des jeweiligen Dienstleistungsvertrages.',
     en: '1.2&nbsp;&nbsp;Use of the Services via the LOCTITE Pulse® App requires the conclusion and maintenance of a service agreement (“<b>Service Agreement</b>”) with Henkel, an affiliate of Henkel or with a distributor appointed by or on behalf of Henkel (each a “<b>Contractual Partner</b>”). Service Agreements will remain unaffected by the App Terms. Under or in connection with the App terms, Henkel will not assume any obligations or liabilities, in particular with regard to the provision or use of the Services or the fulfillment of a Service Agreement.'
  },
  scope_1_3_content: {
    de: '1.3&nbsp;&nbsp;DIE LOCTITE PULSE® APP UND DIE HIERIN ENTHALTENEN INFORMATIONEN SIND NICHT FÜR DIE NUTZUNG INNERHALB DER VEREINIGTEN STAATEN VON AMERIKA ODER DURCH U.S. STAATSBÜRGER ODER ANSÄSSIGE PERSONEN BESTIMMT ODER ZUGELASSEN. SOLCHEN PERSONEN WIRD EMPFOHLEN, DIE LOKALE LOCTITE PULSE® APP ODER DIE WEBSITES DER HENKEL CORPORATION ZU BESUCHEN.',
    en: '1.3&nbsp;&nbsp;THE LOCTITE PULSE® APP AND THE INFORMATION CONTAINED HEREIN ARE NOT INTENDED OR AUTHORIZED FOR USE WITHIN THE UNITED STATES OF AMERICA OR BY U.S. CITIZENS OR RESIDENTS. SUCH PERSONS ARE ENCOURAGED TO REFER TO THE LOCAL LOCTITE PULSE® APP OR TO THE WEBSITES OF HENKEL CORPORATION.'
  },
  scope_2: {
    de: '2. Registrierung und Konto',
    en: '2. Registration and Account'
  },
  scope_2_1_content: {
    de: '2.1&nbsp;&nbsp;Zur Nutzung der LOCTITE Pulse® App ist die vorherige Registrierung zur Eröffnung eines Kontos ("<b>Konto</b>") erforderlich. Mit der Registrierung für Sie selbst oder einen in Ihrem Namen handelnden Nutzer (einschließlich eines Dritten) (Sie und ein solcher Nutzer "<b>Nutzer</b>") akzeptieren Sie die App-Bedingungen ohne Einschränkung oder Vorbehalt. Sie können die App-Bedingungen einsehen, speichern und ausdrucken, bevor Sie sie akzeptieren.',
    en: '2.1&nbsp;&nbsp;Access to the LOCTITE Pulse® App requires the registration of an account (“<b>Account</b>”). By registering an Account for yourself or a user (including a third party) acting on your behalf (you and such user “<b>User</b>”), you accept, without limitation or reservation, the App Terms. You may view, save and print the App Terms before your acceptance.'
  },
  scope_2_2_content: {
    de: '2.2&nbsp;&nbsp;Der Nutzer ist verpflichtet, bei der Registrierung wahrheitsgemäße Angaben zu machen und spätere Änderungen der gemachten Angaben unverzüglich mitzuteilen.',
    en: '2.2&nbsp;&nbsp;When registering, the User shall provide accurate information and communicate subsequent amendments to the information provided promptly.'
  },
  scope_2_3_content: {
    de: '2.3&nbsp;&nbsp;E-Mail-Adressen und andere Kontaktinformationen können von Henkel für Kommunikationszwecke in Verbindung mit der Nutzung der LOCTITE Pulse® App verwendet werden. Der Nutzer stellt sicher, dass er/sie E-Mails erhält, die an die von ihm/ihr angegebene E-Mail-Adresse gesendet werden.',
    en: '2.3&nbsp;&nbsp;Email addresses and other contact information may be used by Henkel for the purpose of communication in connection with the use of the LOCTITE Pulse® App. The User will ensure that he/she receives emails that are sent to the email address that the User provided.'
  },
  scope_2_4_content: {
    de: '2.4&nbsp;&nbsp;Sie dürfen nur solche Mitarbeiter als Nutzer des Kontos (einschließlich der Registrierung für Unterkonten, die Teil eines solchen Kontos sind) ernennen, die auf die Nutzung der LOCTITE Pulse® App geschult und zertifiziert wurden. Sie müssen Henkel unverzüglich über jeden Ihrer Mitarbeiter informieren, der nicht mehr berechtigt ist, die LOCTITE Pulse® App zu nutzen.',
    en: '2.4&nbsp;&nbsp;You shall only appoint those users to use the Account (including to register for sub-accounts part of such Account) who have been trained and certified for the use of the LOCTITE Pulse® App. You shall promptly inform Henkel of any of your employees who shall no longer be entitled to use the LOCTITE Pulse® App.'
  },
  scope_2_5_content: {
    de: '2.5&nbsp;&nbsp;Beim ersten Zugang hat der Nutzer das bei der Registrierung erhaltene Passwort unverzüglich in ein individuelles, nur ihm bekanntes Passwort zu ändern. Die Zugangsdaten sind persönlich. Der Nutzer ist verpflichtet, seine Zugangsdaten sorgfältig aufzubewahren und vor unberechtigtem Zugriff zu schützen. Der Nutzer muss sich nach jeder Nutzung ordnungsgemäß vom Konto abmelden.',
    en: '2.5&nbsp;&nbsp;On first access, the User shall promptly change the password received in the course of the registration into an individual password that is known only to him/her. The access credentials are personal. The User is required to carefully store his/her access credentials and protect them from unauthorized access. The User must properly log out of the Account after each use.'
  },
  scope_2_6_content: {
    de: '2.6&nbsp;&nbsp;Dem Nutzer ist es untersagt, (i) sich den Zugang zur LOCTITE Pulse® App mit anderen Mitteln als dem Konto oder anderen zulässigen Mitteln zu verschaffen; (ii) die Nutzerauthentifizierung oder die Sicherheit des Kontos, der LOCTITE Pulse® App oder eines damit verbundenen Hosts, Netzwerks oder Kontos zu umgehen oder offenzulegen; oder (iii) eine falsche Identität vorzutäuschen, um andere in die Irre zu führen oder sich unrechtmäßigen oder unbefugten Zugang zum Konto oder der LOCTITE Pulse® App zu verschaffen.',
    en: '2.6&nbsp;&nbsp;The User shall not (i) gain access to the LOCTITE Pulse® App by any means other than the Account or other permitted means; (ii) circumvent or disclose the user authentication or security of the Account, the LOCTITE Pulse® App or any host, network, or account related thereto; or (iii) pretend a false identity with the purpose of misleading others or to gain unlawful or unauthorized access to the Account or the LOCTITE Pulse® App.'
  },
  scope_2_7_content: {
    de: '2.7&nbsp;&nbsp;Es ist Ihnen untersagt, die Zugangsdaten (z.B. Nutzer-ID, Passwort, etc.) für die LOCTITE Pulse® App an unbefugte Dritte weiterzugeben oder anderweitig zugänglich zu machen. Sie dürfen die Ihnen über die LOCTITE Pulse® App zur Verfügung gestellten Dienste ausschließlich für den vereinbarten Zweck nutzen. Wenn Sie Kenntnis davon erlangen, dass unbefugten Dritten die Zugangsdaten bekannt geworden sind und/oder dass unbefugte Dritte Ihre Zugangsdaten genutzt haben, sind Sie verpflichtet, Henkel unverzüglich zu informieren.',
    en: '2.7&nbsp;&nbsp;You are prohibited from providing the access data (e.g. user ID, password, etc.) for the LOCTITE Pulse® App to unauthorized third parties or otherwise making them accessible. You may use the services provided to you through the LOCTITE Pulse® App solely for the agreed purpose. If you become aware that the access data have become known to unauthorized third parties and/or that unauthorized third parties have used your access data, you are obliged to inform Henkel immediately.'
  },
  scope_3: {
    de: '3. Nutzung',
    en: '3. Use'
  },
  scope_3_1_content: {
    de: '3.1&nbsp;&nbsp;Bei der Verwendung der LOCTITE Pulse® App muss der Nutzer:',
    en: '3.1&nbsp;&nbsp;In using the LOCTITE Pulse® App, the User shall:'
  },
  scope_3_1_a_content: {
    de: '(a)&nbsp;&nbsp;jederzeit ordnungsgemäß in Ihrem Namen handeln;',
    en: '(a)&nbsp;&nbsp;duly act on your behalf at all times;'
  },
  scope_3_1_b_content: {
    de: '(b)&nbsp;&nbsp;vor dem Zugriff auf die LOCTITE Pulse® App, während der Nutzung und bei der Übertragung von Daten alle angemessenen Vorkehrungen gegen Sicherheitsangriffe einschließlich, aber nicht abschließend, zur Verhinderung von Viren, trojanischen Pferden oder anderen Programmen, die Software beschädigen können, zu treffen;',
    en: '(b)&nbsp;&nbsp;before accessing the LOCTITE Pulse® App, during use and when transferring data, take all reasonable precautions against security attacks and to prevent viruses, trojan horses or other programs that may damage software;'
  },
  scope_3_1_c_content: {
    de: '(c)&nbsp;&nbsp;alle anwendbaren nationalen und internationalen Sanktionen sowie Export- und Reexportkontrollvorschriften einhalten, einschließlich, aber nicht beschränkt auf die Vorschriften der Bundesrepublik Deutschland, der Europäischen Union, der Vereinigten Staaten von Amerika und die Vorschriften anderer Länder oder Rechtsordnungen, die möglicherweise Anwendung finden;',
    en: '(c)&nbsp;&nbsp;comply with all applicable national and international sanctions as well as export and re-export control regulations including, but not limited to, those of the Federal Republic of Germany, of the European Union, of the United States of America and regulations of any other country or jurisdiction which may apply;'
  },
  scope_3_1_d_content: {
    de: '(d)&nbsp;&nbsp;Henkel unverzüglich über jede mögliche unbefugte Nutzung der Zugangsdaten des Nutzers, den Missbrauch oder Diebstahl des Kontos und jedes Sicherheitsrisiko (z. B. eine Schwachstelle) im Zusammenhang mit dem Konto oder der LOCTITE Pulse® App informieren;',
    en: '(d)&nbsp;&nbsp;notify Henkel promptly about any possible unauthorized use of the User’s access credentials, misuse or theft of the Account and any security risk (e.g. vulnerability) related to the Account or the LOCTITE Pulse® App;'
  },
  scope_3_1_e_content: {
    de: '(e)&nbsp;&nbsp;Henkel unverzüglich über alle Fehler in der Funktionalität des Kontos oder der LOCTITE Pulse® App oder der darin enthaltenen Informationen zu informieren; und',
    en: '(e)&nbsp;&nbsp;notify Henkel promptly of any errors in the functionality of the Account or the LOCTITE Pulse® App or the information provided therein; and'
  },
  scope_3_1_f_content: {
    de: '(f)&nbsp;&nbsp;Henkel unverzüglich zu benachrichtigen, wenn der Nutzer irrtümlich Zugang zu Daten erhält, die offensichtlich nicht für den Zugriff des Nutzers bestimmt sind.',
    en: '(f)&nbsp;&nbsp;notify Henkel promptly if the User by mistake  has access to data that is obviously not intended to be accessed by the User.'
  },
  scope_3_2_content: {
    de: '3.2&nbsp;&nbsp;Bei der Nutzung der LOCTITE Pulse® App darf der Nutzer nicht:',
    en: '3.2&nbsp;&nbsp;In using the LOCTITE Pulse® App, the User shall not:'
  },
  scope_3_2_a_content: {
    de: '(a)&nbsp;&nbsp;auf die LOCTITE Pulse® App zugreifen oder sie für andere Zwecke als für Ihre internen Geschäftszwecke zu verwenden;',
    en: '(a)&nbsp;&nbsp;access or use the LOCTITE Pulse® App for any purpose except for your internal business purposes;'
  },
  scope_3_2_b_content: {
    de: '(b)&nbsp;&nbsp;die LOCTITE Pulse® App ganz oder teilweise kopieren und /oder an ein verbundenes Unternehmen oder einen Dritten (es sei denn, ein solches Kopieren ist für die normale Nutzung oder für Backup- oder Betriebssicherheitszwecke erforderlich), verkaufen, weiterverkaufen, lizenzieren, übertragen, abtreten, unterlizenzieren, vermieten, verleasen oder anderweitig zur Verfügung stellen (es sei denn, dies ist ausdrücklich schriftlich gestattet);',
    en: '(b)&nbsp;&nbsp;copy (except where such copying is incidental to normal use or where necessary for back-up or operational security purposes), sell, resell, license, transfer, assign, sublicense, rent, lease, or otherwise make available the LOCTITE Pulse® App in whole or in part to any affiliate or third party (unless expressly permitted in writing);'
  },
  scope_3_2_c_content: {
    de: '(c)&nbsp;&nbsp;die Integrität oder Leistung der LOCTITE Pulse® App oder anderer Geräte oder Netzwerke, die mit der LOCTITE Pulse® App verbunden sind, beeinträchtigen oder stören, und insbesondere keine Inhalte übertragen, die Viren, trojanische Pferde oder andere Programme enthalten, die Software beschädigen können;',
    en: '(c)&nbsp;&nbsp;interfere with or disrupt the integrity or performance of the LOCTITE Pulse® App or other equipment or networks connected to the LOCTITE Pulse® App, and in particular not transmit any content containing viruses, trojan horses or other programs that may damage software;'
  },
  scope_3_2_d_content: {
    de: '(d)&nbsp;&nbsp;die LOCTITE Pulse® App in einer Weise zu verwenden, die die Systeme oder die Sicherheit von Henkel beschädigen, deaktivieren, überlasten, beeinträchtigen, gefährden und/oder andere Nutzer stören könnte;',
    en: '(d)&nbsp;&nbsp;use the LOCTITE Pulse® App in a way that could damage, disable, overburden, impair or compromise Henkel’s systems or security or interfere with other users;'
  },
  scope_3_2_e_content: {
    de: '(e)&nbsp;&nbsp;Penetrationstests von oder mit der LOCTITE Pulse® App durchführen, ohne vorher eine ausdrückliche schriftliche Genehmigung einzuholen;',
    en: '(e)&nbsp;&nbsp;perform any penetration test of or on the LOCTITE Pulse® App without obtaining express written consent in advance;'
  },
  scope_3_2_f_content: {
    de: '(f)&nbsp;&nbsp;Geräte mit der LOCTITE Pulse® App verbinden, die nicht dem neuesten Stand der Sicherheitsrichtlinien entsprechen (z. B. Passwortschutz, Virenschutz, Update- und Patch-Level);',
    en: '(f)&nbsp;&nbsp;connect to the LOCTITE Pulse® App devices that do not comply with state-of-the-art security policies (e.g. password protection, virus protection, update and patch level);'
  },
  scope_3_2_g_content: {
    de: '(g)&nbsp;&nbsp;in einer Weise handeln, die gegen die guten Sitten verstößt;',
    en: '(g)&nbsp;&nbsp;act in a manner contrary to public decency;'
  },
  scope_3_2_h_content: {
    de: '(h)&nbsp;&nbsp;Persönlichkeitsrechte, Rechte an geistigem Eigentum, Urheberrechte oder andere Eigentumsrechte zu verletzen; und',
    en: '(h)&nbsp;&nbsp;infringe personal rights, intellectual property rights, copyrights or any other proprietary rights; and'
  },
  scope_3_2_i_content: {
    de: '(i)&nbsp;&nbsp;die LOCTITE Pulse® App in einer Weise zu nutzen, die die Rechte von Henkel oder geltende lokale, staatliche, nationale, internationale oder ausländische Gesetze, Verträge oder Vorschriften verletzt.',
    en: '(i)&nbsp;&nbsp;make any use of the LOCTITE Pulse® App that violates Henkel’s rights or any applicable local, state, national, international or foreign law, treaty, or regulation.'
  },
  scope_4: {
    de: '4. Verantwortung',
    en: '4.	Responsibility and Representation'
  },
  scope_4_1_content: {
    de: '4.1&nbsp;&nbsp;Sie sind allein für alle Handlungen und Unterlassungen von anderen Nutzern als Ihnen selbst, wie für eigene Handlungen und Unterlassungen verantwortlich.',
    en: '4.1&nbsp;&nbsp;You are solely responsible for all acts and omissions, as if such acts and omissions were your own acts and omissions, by any Users other than yourself.'
  },
  scope_4_2_content: {
    de: '4.2&nbsp;&nbsp;Durch Ihre Auswahl der Nutzer erteilen Sie hiermit allen Nutzern, die nicht Sie selbst sind, Vertretungsmacht in dem Umfang, in, dass diese in Übereinstimmung mit den in der LOCTITE Pulse® App bereitgestellten Funktionalitäten Erklärungen und/oder Meldungen in Ihrem Namen und mit Wirkung für Sie abgeben dürfen. Sie können diese Vertretungsbefugnis nur widerrufen oder ändern, indem Sie die jeweilige Rollenzuweisung an diese Nutzer ändern.',
    en: '4.2&nbsp;&nbsp;You hereby grant power of representation to all Users other than yourself to the extent you assign roles to such Users enabling them to, in accordance with the functionalities provided in the LOCTITE Pulse® App, submit declarations and/or notifications on your behalf. You may only revoke or modify such power of representation by changing the respective assignment of roles to such Users.'
  },
  scope_5: {
    de: '5. Einhaltung der Vorschriften und Aussetzung',
    en: '5.	Compliance and Suspension'
  },
  scope_5_1_content: {
    de: '5.1&nbsp;&nbsp;Henkel ist berechtigt, Ihren Zugriff auf und Ihre Nutzung der LOCTITE Pulse® App für interne Geschäftszwecke von Henkel zu überwachen, zB die Sicherstellung der Einhaltung der App-Bedingungen.',
    en: '5.1&nbsp;&nbsp;Henkel may monitor your access to and use of the LOCTITE Pulse® App for Henkel’s internal business purposes, e.g. to the extent required to ensure compliance with the App Terms.'
  },
  scope_5_2_content: {
    de: '5.2&nbsp;&nbsp;Henkel ist berechtigt, Ihre Daten oder Informationen zu löschen und/oder den Zugriff auf diese Daten oder Informationen zu sperren, wenn und soweit Ihre Daten oder Informationen nach vernünftigem Ermessen von Henkel nicht den Anforderungen der App-Bedingungen oder dem jeweiligen Dienstleistungsvertrag entsprechen oder nicht mit geltendem Recht vereinbar sind.',
    en: '5.2&nbsp;&nbsp;Henkel is entitled to delete your data or information and/or to disable access to such data or information if and to the extent that, based on Henkel’s reasonable judgement, your data or information does not meet the requirements of the App Terms or the Service Agreement or is non-compliant with applicable law.'
  },
  scope_5_3_content: {
    de: '5.3&nbsp;&nbsp;Henkel ist berechtigt, den Zugriff eines Nutzers auf die LOCTITE Pulse® App ganz oder teilweise, vorübergehend oder dauerhaft zu sperren, wenn der entsprechende Dienstleistungsvertrag gekündigt wird oder automatisch endet oder wenn nach vernünftigem Ermessen von Henkel (i) der Nutzer seine Verpflichtungen aus den App-Bedingungen oder dem Dienstleistungsvertrag wesentlich verletzt oder (ii) eine solche Sperrung aufgrund gesetzlicher Vorschriften oder wegen eines dringenden Sicherheits- oder sonstigen schwerwiegenden Problems erforderlich ist.',
    en: '5.3&nbsp;&nbsp;Henkel is entitled to completely or partially, temporarily or permanently, disable any User’s access to the LOCTITE Pulse® App if the applicable Service Agreement is terminated or, according to Henkel’s reasonable judgment, (i) the User materially breaches obligations under the App Terms or the Service Agreement or (ii) such suspension is necessary due to legal requirements or due to an emergency security or other serious issue.'
  },
  scope_6: {
    de: '6. Vertraulichkeit',
    en: '6.	Confidentiality'
  },
  scope_6_1_content: {
    de: '6.1&nbsp;&nbsp;Der Nutzer kann über das Konto Zugang zu vertraulichen Informationen von Henkel erlangen. Vertrauliche Informationen dürfen nur für den Zweck des Zugriffs auf und der Nutzung der LOCTITE Pulse® App und in Übereinstimmung mit den App-Bedingungen verwendet werden.',
    en: '6.1&nbsp;&nbsp;The User may have access to Henkel’s confidential information through the Account. Confidential information may only be used for the purpose of access to and use of the LOCTITE Pulse® App and in accordance with the App Terms.'
  },
  scope_6_2_content: {
    de: '6.2&nbsp;&nbsp;Sie sind verpflichtet, Henkel unverzüglich zu informieren, sobald der Nutzer Kenntnis von einer drohenden oder bereits eingetretenen Verletzung der Geheimhaltungsinteressen von Henkel hat oder ein entsprechender Verdacht besteht.',
    en: '6.2&nbsp;&nbsp;You are required to immediately inform Henkel as soon as the User becomes aware of an imminent breach of Henkel’s confidentiality interests or that such a breach has taken place or upon existence of such a suspicion.'
  },
  scope_7: {
    de: '7. Urheberrechte, Warenzeichen und Marken',
    en: '7.	Copyrights, Trademarks and Brands'
  },
  scope_7_1_content: {
    de: '7.1&nbsp;&nbsp;Die App-Bedingungen gewähren dem Nutzer keine Rechte an geistigem Eigentum oder Lizenzen, auch nicht in Bezug auf die LOCTITE Pulse® App und die Dienstleistungen. Solche Lizenzen können vom Vertragspartner im Rahmen des jeweiligen Dienstleistungsvertrages bereitgestellt werden.',
    en: '7.1&nbsp;&nbsp;The App Terms do not provide the User with any intellectual property rights or licenses, including with regard to the LOCTITE Pulse® App and the Services. Such licenses may be provided by Contractual Partner under the Service Agreement.'
  },
  scope_7_2_content: {
    de: '7.2&nbsp;&nbsp;Die LOCTITE Pulse® App und ihr Inhalt sind durch geistige Eigentumsrechte geschützt. Insbesondere Vervielfältigungen, Bearbeitungen, Übersetzungen, Einspeicherung und Verarbeitung in anderen Medien, einschließlich der Einspeicherung oder Verarbeitung mit elektronischen Mitteln, sind durch geistige Eigentumsrechte geschützt. Jede Verwertung, auch auszugsweise, bedarf der vorherigen schriftlichen Zustimmung von Henkel. Sowohl der Inhalt als auch die Struktur der LOCTITE Pulse® App, einschließlich u. a. der Software, des grafischen Materials, der Karten, der Illustrationen, der Tabellen, der Texte, des Designs, des Videomaterials und der künstlerischen Darstellung, sind durch geistige Eigentumsrechte geschützt.',
    en: '7.2&nbsp;&nbsp;The LOCTITE Pulse® App and its contents are protected by intellectual property rights. In particular, reproductions, adaptations, translations, storage and processing in other media, including storage or processing by electronic means, are protected by intellectual property rights. Any utilization, in whole or in part, requires the prior written consent of Henkel. Both the content and the structure of the LOCTITE Pulse® App including, among others, the software, graphic material, maps, illustrations, tables, texts, design, video material, artistic presentation, are protected by intellectual property rights.'
  },
  scope_7_3_content: {
    de: '7.3&nbsp;&nbsp;Die Verwendung, Weitergabe und/oder Vervielfältigung von Informationen und/oder Daten, insbesondere die Verwendung von technischen Daten, Auswertungen, Analysen, Grafiken, Schaubildern, Texten, Textteilen, Lageplänen oder Bildmaterial bedarf, soweit nicht im Einzelfall schriftlich anders vereinbart, der vorherigen schriftlichen Zustimmung von Henkel. Die Rechte für die Verbreitung und Vervielfältigung liegen bei Henkel. Die Rechte bleiben auch dann in vollem Umfang bestehen, wenn Bilder, technische Daten und/oder Analysen automatisch oder manuell in ein Archiv übernommen werden.',
    en: '7.3&nbsp;&nbsp;Unless otherwise individually agreed in writing, the use, disclosure and/or reproduction of information and/or data, in particular the use of technical data, evaluations, analyses, graphics, charts, texts, parts of texts, site plans or image material requires the prior written consent of Henkel. The rights for distribution and reproduction are held by Henkel. The rights also remain in full force if images, technical data and/or analyses are automatically or manually transferred to an archive.'
  },
  scope_7_4_content: {
    de: '7.4&nbsp;&nbsp;Das Henkel Oval-Logo, die Marke LOCTITE und LOCTITE Pulse® sowie alle auf diesen Seiten verwendeten Produktnamen und/oder Produktdarstellungen sind eingetragene Marken von Henkel, seinen verbundenen Unternehmen oder Lizenzgebern. Jede unbefugte oder missbräuchliche Verwendung dieser Marken ist ausdrücklich verboten und stellt eine Verletzung des Markenrechts, des Urheberrechts, anderer gewerblicher Schutzrechte oder des Wettbewerbsrechts dar.',
    en: '7.4&nbsp;&nbsp;The Henkel Oval-Logo, the LOCTITE brand and LOCTITE Pulse® as well as all product names and/or product presentations used on these pages are registered trademarks of Henkel, its affiliates or licensors. Any unauthorized or improper use of these trademarks is expressly prohibited and constitutes a violation of trademark law, copyright law, other industrial property rights or competition law.'
  },
  scope_7_5_content: {
    de: '7.5&nbsp;&nbsp;Die in dieser LOCTITE Pulse® App gezeigten Marken/Produkte sind Beispiele für die weltweit verfügbaren Marken/Produkte von Henkel-Unternehmen. Henkel garantiert nicht, dass eine gezeigte Marke/ein gezeigtes Produkt auch in Ihrem Land erhältlich ist.',
    en: '7.5&nbsp;&nbsp;The brands/products shown on this LOCTITE Pulse® App are examples of the brands / products of Henkel companies available worldwide. Henkel does not guarantee that a brand/product shown is also available in your country.'
  },
  scope_8: {
    de: '8. Haftungsausschluss für Websites von Drittanbietern',
    en: '8.	Disclaimer for Third-Party Websites'
  },
  scope_8_1_content: {
    de: '8.1&nbsp;&nbsp;Die Seiten der LOCTITE Pulse® App enthalten auch Verknüpfungen (sog. Hyperlinks) zu Webseiten im Internet, die von Dritten gepflegt werden und deren Inhalte Henkel nicht bekannt sind. Henkel vermittelt lediglich den Zugang zu diesen Websites und übernimmt keine Verantwortung für deren Inhalte. Die von Henkel gesetzten Links zu Webseiten Dritter dienen lediglich der Erleichterung Ihrer Navigation. Henkel macht sich die auf den verlinkten Seiten dargestellten Aussagen nicht zu eigen, sondern distanziert sich ausdrücklich von allen Inhalten aller verlinkten Seiten Dritter auf den Seiten des Internetauftritts von Henkel. Insbesondere haftet Henkel nicht für dort begangene Verstöße gegen gesetzliche Bestimmungen und Rechte Dritter.',
    en: '8.1&nbsp;&nbsp;The pages of the LOCTITE Pulse® App also contain links (so-called hyperlinks) to websites on the internet that are maintained by third parties and whose contents are not known to Henkel. Henkel only provides access to these websites and does not assume any responsibility for their contents. Henkel’s links to third party websites are only meant to facilitate your navigation. Henkel does not adopt the statements presented on linked pages as Henkel’s own, but rather dissociates itself explicitly from all contents of all linked pages of third parties on the pages of Henkel’s internet presence. In particular, Henkel is not liable for violations of legal provisions and third-party rights committed there.'
  },
  scope_8_2_content: {
    de: '8.2&nbsp;&nbsp;Die Inhaber der Websites, zu denen über die LOCTITE Pulse® App ein Hyperlink besteht, sind für deren Inhalt sowie für den Verkauf der dort angebotenen Produkte und die Abwicklung der Bestellung allein verantwortlich. Henkel haftet nicht für die Verletzung von Urheber-, Marken- und anderen gewerblichen Schutzrechten sowie Persönlichkeitsrechten, die auf einer mit einem Hyperlink versehenen Seite begangen wird.',
    en: '8.2&nbsp;&nbsp;The owners of those websites to which there is a hyperlink via the LOCTITE Pulse® App are solely responsible for their content as well as for the sale of the products offered there and the processing of the order. Henkel is not liable for the violation of copyrights, trademark rights and other industrial property rights as well as personal rights, which is committed on a page provided with a hyperlink.'
  },
  scope_8_3_content: {
    de: '8.3&nbsp;&nbsp;Im Falle einer Bestellung oder sonstigen rechtsgeschäftlichen Erklärung kommt lediglich ein Vertrag zwischen dem Nutzer und dem jeweiligen Betreiber der Website oder dem dortigen Anbieter zustande, keinesfalls aber ein Vertrag zwischen Henkel und dem Nutzer. Bitte beachten Sie die Allgemeinen Geschäftsbedingungen des jeweiligen Anbieters der verlinkten Website.',
    en: '8.3&nbsp;&nbsp;In case of an order or any other legal declaration only a contract between the user and the respective owner of the website or the provider present there is concluded, but in no case a contract between Henkel and the user. Please note the general terms and conditions of the respective provider of the linked website.'
  },
  scope_9: {
    de: '9. Haftung',
    en: '9.	Liability'
  },
  scope_9_1_content: {
    de: '9.1&nbsp;&nbsp;Für die Haftung von Henkel unter und im Zusammenhang mit diesen App-Bedingungen gilt Folgendes:',
    en: '9.1&nbsp;&nbsp;Regarding Henkel’s liability under and in connection with these App Terms, the following shall apply:'
  },
  scope_9_1_a_content: {
    de: '(a)&nbsp;&nbsp;Die Haftung von Henkel für zufällige, besondere oder Folgeschäden, z.B. Produktions- und/oder Gewinnausfall, ist ausgeschlossen.',
    en: '(a)&nbsp;&nbsp;Henkel’s liability for incidental, special, or consequential loss or damage, e.g., loss of production and/or profits, shall be excluded.'
  },
  scope_9_1_b_content: {
    de: '(b)&nbsp;&nbsp;Henkel haftet nicht für Kosten und Schäden, die Sie durch ordnungsgemäße und regelmäßige Datensicherungsmaßnahmen hätten vermeiden können.',
    en: '(b)&nbsp;&nbsp;Henkel shall not be liable for costs and damages which you could have prevented via proper and regular data backup measures.'
  },
  scope_9_1_c_content: {
    de: '(c)&nbsp;&nbsp;Alle Ansprüche gegen Henkel müssen spätestens ein (1) Jahr nach Entstehen des jeweiligen Anspruchs geltend gemacht werden, danach verjähren alle Ansprüche gegen Henkel.',
    en: '(c)&nbsp;&nbsp;Any claims against Henkel shall be brought no later than one (1) year after the respective claim arose, after which all claims against Henkel shall be barred.'
  },
  scope_9_1_d_content: {
    de: '(d)&nbsp;&nbsp;Die Haftungsbeschränkungen gemäß diesem Abschnitt 9.1 gelten nicht im Falle von Körperverletzungen oder Tod, vorsätzlichem Fehlverhalten oder zwingendem Produkthaftungsrecht.',
    en: '(d)&nbsp;&nbsp;The limitations of liability under this Section 10.1 do not apply in the event of bodily injuries or death, willful misconduct or mandatory product liability law.'
  },
  scope_9_2_content: {
    de: '9.2&nbsp;&nbsp;Der Umfang, in dem die Haftung von Henkel ausgeschlossen oder beschränkt ist, gilt auch zugunsten seiner Mitarbeiter, Geschäftsführer, Subunternehmer und aller anderen Personen, die von Henkel zur Bereitstellung der LOCTITE Pulse® App eingesetzt werden.',
    en: '9.2&nbsp;&nbsp;The extent to which Henkel’s liability is excluded or limited also applies for the benefit of its employees, managing directors, sub-contractors and any other persons used by Henkel to provide the LOCTITE Pulse® App.'
  },
  scope_10: {
    de: '10. Sonstiges',
    en: '10. Miscellaneous'
  },
  scope_10_1_content: {
    de: '10.1&nbsp;&nbsp;Die App-Bedingungen unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Übereinkommens über Verträge über den internationalen Warenkauf.',
    en: '10.1&nbsp;&nbsp;The App Terms are governed by the laws of the Federal Republic of Germany, excluding the UN Convention on Contracts for the International Sale of Goods.'
  },
  scope_10_2_content: {
    de: '10.2&nbsp;&nbsp;Der Gerichtsstand für Streitigkeiten im Zusammenhang mit den App-Bedingungen ist Düsseldorf.',
    en: '10.2&nbsp;&nbsp;The place of jurisdiction for disputes relating to the App Terms is Düsseldorf. '
  },
  scope_10_3_content: {
    de: '10.3&nbsp;&nbsp;Sollte eine der Bestimmungen der App-Bedingungen unwirksam sein oder werden, so wird die Wirksamkeit der übrigen Bestimmungen davon nicht berührt.',
    en: '10.3&nbsp;&nbsp;Should one of the provisions of the App Terms be or become invalid, this shall not affect the validity of the remaining provisions.'
  },
  //End of Terms of use doc
  //Service Conditions doc
  service_conditions_new_title: {
    de: 'Allgemeine Service-Bedingungen LOCTITE Pulse®',
    en: 'General Conditions of Service LOCTITE Pulse®',
  },
  service_conditions_new_subtitle: {
    de: '-	Europa -',
    en: '-  Europe -',
  },
  service_scope_1: {
    de: '1 Umfang',
    en: '1 Scope'
  },
  service_scope_1_1_content: {
    de: '1.1&nbsp;&nbsp;Die <b>Henkel AG & Co. KGaA</b> ("<b>Henkel KGaA</b>") oder ihr jeweiliges verbundenes Unternehmen (wie in Abschnitt 3.7) ("<b>Henkel KGaA</b>" oder ein solches verbundenes Unternehmen im Folgenden "<b>Henkel</b>") erbringt digitale Datenanalyse- und Überwachungsdienstleistungen für Industrieanlagen in Verbindung mit den LOCTITE Pulse® Services ("<b>Services</b>") für Kunden, Wartungsdienstleister, Unternehmer und juristische Personen des öffentlichen Rechts ("<b>Kunde</b>", zusammen mit Henkel die "<b>Parteien</b>" und jeweils eine "<b>Partei</b>"). Diese Allgemeinen Servicebedingungen ("<b>Servicebedingungen</b>") gelten für alle Angebote und Verträge von Henkel über die Lieferung von Services an den Kunden.',
    en: '1.1&nbsp;&nbsp;<b>Henkel AG & Co. KGaA</b> (“<b>Henkel KGaA</b>”) or its relevant Affiliate (as defined in Section 3.7) (“<b>Henkel KGaA</b>” or such Affiliate hereinafter “<b>Henkel</b>”) provides digital data analysis and monitoring services for industrial assets in connection with the LOCTITE Pulse® Services (“<b>Services</b>”) to customers, maintenance service providers, entrepreneurs and legal entities under public law (”<b>Customer</b>”, together with Henkel the “<b>Parties</b>” and each a “<b>Party</b>”). These General Conditions of Service (“<b>Service Conditions</b>”) shall apply to all offers and contracts of Henkel for the delivery of Services to Customer.'
  },
  service_scope_1_2_content: {
    de: '1.2&nbsp;&nbsp;Die Dienstleistungen umfassen (i) die Überwachung und Algorithmus basierte Analyse von Rohdaten, die mit kompatiblen Sensoren und anderer Hardware ("<b>IoT-Hardware</b>") gemessen werden, die sich auf vom Kunden individuell ausgewählten industriellen Anlagen ("<b>industrielle Anlagen</b>") befinden und über eine von Henkel festgelegte Schnittstelle an die bereitgestellte technische Infrastruktur ("<b>Plattform</b>") übertragen werden ("<b>Rohdaten</b>"), (ii) die Visualisierung der auf der Plattform analysierten Rohdaten ("<b>Analysierte Daten</b>") über die LOCTITE Pulse® App oder - je nach IoT-Hardware - eine andere Plattform, die Henkel zur Ausführung des Dienstes anbieten kann ("<b>App</b>"), und (iii) die Benachrichtigung des Kunden über die App oder per E-Mail über Unregelmäßigkeiten an industriellen Anlagen, die durch die auf der Plattform angewandten Algorithmen erkannt wurden.',
    en: '1.2&nbsp;&nbsp;The Services encompass (i) monitoring and algorithm-based analysis of raw data measured through compatible sensors and other hardware (“<b>IoT Hardware</b>”) located on industrial assets individually selected by Customer (“<b>Industrial Assets</b>”) and transmitted to the technical infrastructure provided (“<b>Platform</b>”) through an interface specified by Henkel (“<b>Raw Data</b>”), (ii) visualizing Raw Data analyzed on the Platform (“<b>Analyzed Data</b>”) via the LOCTITE Pulse® App or – depending on the IoT Hardware - another Platform that Henkel may offer to execute the Service (“<b>App</b>”), and (iii) notifying Customer via the App or email about irregularities on Industrial Assets detected through the algorithms applied on the Platform.'
  },
  service_scope_1_3_content: {
    de: '1.3&nbsp;&nbsp;Zusätzlich zu den Servicebedingungen kann jeder vereinbarte Service durch einschlägige besondere Bedingungen ("<b>Besondere Bedingungen</b>") geregelt werden, zusammen mit allen anderen Bedingungen, die in den Servicebedingungen dargelegt oder durch Verweis darin aufgenommen sind (zusammen "<b>Dienstleistungsvertrag</b>"). Die anwendbaren Besonderen Bedingungen und andere Dokumente sind in <u><b>Anhang 1</b></u> zu diesen Servicebedingungen einsehbar. Die englischsprachige Fassung dieser Dokumente ist maßgebend. Jeder zwischen Henkel und dem Kunden vereinbarte Service stellt einen separaten Servicevertrag für diesen Service dar.',
    en: '1.3&nbsp;&nbsp;In addition to the Service Conditions, any agreed Service may be governed by relevant specific terms (“<b>Specific Terms</b>”), along with any other terms set forth or incorporated by reference in the Service Conditions (collectively “<b>Service Agreement</b>”). Applicable Specific Terms and other documents are accessible in <u><b>Annex 1</b></u> to these Service Conditions. The English language version of such documents prevails. Each of the Services agreed between Henkel and Customer constitutes a separate Service Agreement on such Service.'
  },
  service_scope_1_4_content: {
    de: '1.4&nbsp;&nbsp;Jeder Dienstleistungsvertrag wird immer und ausschließlich zwischen dem Kunden und der Henkel KGaA oder ihrem jeweiligen Tochterunternehmen geschlossen, das gemäß <u><b>Anhang 2</b></u> als Auftraggeber für den Ort benannt ist, an dem der Kunde seine Hauptniederlassung hat, und der Begriff "Henkel", wie er in diesem Servicevertrag verwendet wird, bezieht sich nur auf diesen benannten Auftraggeber. Das einzelne lokale Tochterunternehmen (wie in Ziffer 3.7 definiert), das die Dienstleistungen anbietet und dem jeweiligen lokalen Kunden, der die Dienstleistungen erhält und bezahlt, in Rechnung stellt, haftet allein und individuell für seine eigenen Handlungen, Unterlassungen, Aufgaben und Verpflichtungen, einschließlich, aber nicht beschränkt auf die Erbringung der Dienstleistungen. Ein solches lokales verbundenes Unternehmen, das den individuellen Dienstleistungsvertrag abschließt, haftet nicht für Handlungen oder Unterlassungen, Nichterfüllung oder Erfüllung durch Henkel KGaA oder ein anderes lokales verbundenes Unternehmen, einschließlich, aber nicht beschränkt auf die Erbringung von Dienstleistungen im Rahmen dieser Dienstleistungsbedingungen oder einer anderen lokalen Vereinbarung.',
    en: '1.4&nbsp;&nbsp;Each Service Agreement shall always and solely be concluded between Customer and Henkel KGaA or its relevant Affiliate designated under <u><b>Annex 2</b></u> as the contracting entity for the location where the Customer has its principal place of business and the term ‘Henkel’ as used in the Service Agreement shall only refer to such designated contracting entity. The individual local Affiliate (as defined in Clause 3.7) offering and invoicing the Services with the respective local Customer receiving and paying the Services shall solely and individually liable for its own acts, omissions, duties and obligations including, but not limited to provision of Services. Such Local Affiliate concluding the individual Service Agreement shall not be liable for Henkel KGaA’s or any other Locals Affiliate’s acts or omissions, non-performance or performance including, but not limited to, provision of Services under these Service Conditions or any other local agreement.'
  },
  service_scope_1_5_content: {
    de: '1.5&nbsp;&nbsp;Von den Servicebedingungen abweichende oder ergänzende Bedingungen des Kunden, wie z.B. Qualitätsvorschriften, Handbücher, Normen und/oder Standards, gelten nur, wenn Henkel sie ausdrücklich schriftlich anerkennt. Dies gilt auch dann, wenn Henkel in Kenntnis abweichender oder ergänzender Bedingungen des Kunden Leistungen vorbehaltlos erbringt oder Zahlungen annimmt.',
    en: '1.5&nbsp;&nbsp;Any terms and conditions of Customer deviating from or supplementing the Service Conditions, including, for example, any quality, manuals, norms and/or standards shall only apply if Henkel expressly acknowledges such terms and conditions in writing. This shall also apply if Henkel provides Services without reservation or accepts payments in the knowledge of deviating or supplementary terms and conditions of Customer.'
  },
  service_scope_1_6_content: {
    de: '1.6&nbsp;&nbsp;Im Rahmen einer laufenden Geschäftsbeziehung gelten die Servicebedingungen und die jeweils gültigen Besonderen Bedingungen auch für alle zukünftigen Dienstleistungsverträge über Dienstleistungen zwischen Henkel und dem Kunden.',
    en: '1.6&nbsp;&nbsp;Within the scope of an ongoing business relationship, the Service Conditions and the applicable Specific Terms shall also apply to any and all future Service Agreements regarding Services entered into between Henkel and Customer.'
  },
  service_scope_1_7_content: {
    de: '1.7&nbsp;&nbsp;Im Rahmen des Dienstleistungsvertrags oder anderweitig in Verbindung mit der Erbringung eines Dienstes übernimmt Henkel keine Verpflichtung in Bezug auf und keine Verantwortung für (i) den Inhalt von Websites anderer Anbieter als Henkel oder seiner Unterauftragnehmer, selbst wenn diese über  Hyperlinks in Verbindung mit den Diensten aufgerufen werden; und (ii) die Verbindung von Industrieanlagen mit der Plattform und die Übertragung von Daten an die Plattform oder anderweitig über das Internet in Verbindung mit den Dienstenliestungen, und eine solche Verbindung und Übertragung ist nicht Bestandteil der Plattform oder der Dienstleistungen. IoT-Hardware, die für die Nutzung der Dienstleistungen erforderlich ist, kann separat auf der Grundlage der jeweils gültigen Verkaufsbedingungen erworben werden. Die Anforderungen an die IoT-Hardware, einschließlich der technischen Informationen und Betriebsanleitungen, sind in der Bedienungsanleitung ("<b>Betriebshandbuch</b>") enthalten, die unter <a href="https://app.loctite-pulse.com" target="_blank" class="linkStyle">https://app.loctite-pulse.com</a> abrufbar ist.',
    en: '1.7&nbsp;&nbsp;Under the Service Agreement, or otherwise in connection with the provision of any Service, Henkel will not assume any obligation with respect to nor responsibility for any (i) content of websites of providers other than Henkel or its subcontractors even where they are accessed through hyperlinks provided in connection with the Services; and (ii) connection of Industrial Assets with and transfer of data to the Platform or otherwise via the internet in connection with the Services, and such connection and transfer is not part of the Platform or the Services. IoT Hardware that is necessary to use the Services can be purchased separately on the basis of the then-current terms and conditions of sale. Requirements for IoT Hardware, including technical information and operating conditions, may be found in the user instruction (“<b>Operating Manual</b>”) accessible at <a href="https://app.loctite-pulse.com" target="_blank" class="linkStyle">https://app.loctite-pulse.com</a>.'
  },
  service_scope_2: {
    de: '2 Nutzung von Dienstleistungen',
    en: '2 Use of Services'
  },
  service_scope_2_1_content: {
    de: '2.1&nbsp;&nbsp;Henkel ermöglicht dem Kunden die Verwaltung und, soweit auf der Plattform vorgesehen, den Zugang und die Nutzung der Dienstliestung über ein Konto über die App unter Verwendung der dem Kunden von Henkel zur Verfügung gestellten Anmeldedaten ("<b>Konto</b>").',
    en: '2.1&nbsp;&nbsp;Henkel shall enable Customer to manage and, where provided on the Platform, access and use the Services through an account via the App using the log-in data provided by Henkel to Customer (“<b>Account</b>”).'
  },
  service_scope_2_2_content: {
    de: '2.2&nbsp;&nbsp;Henkel gewährt dem Kunden hiermit eine nicht ausschließliche, nicht übertragbare, nicht unterlizenzierbare, zeitlich begrenzte und widerrufliche Lizenz für den Zugriff auf und die Nutzung der auf der Plattform über das Konto und die App bereitgestellten Dienstleistungen ("<b>Lizenz</b>"), die der Kunde hiermit akzeptiert. Der Kunde hat kein Recht, die Dienstleistungen oder die App zu verändern. Die Lizenz ist auf den alleinigen Zweck beschränkt, dem Kunden oder vom Kunden im Namen des Kunden beauftragten Drittdienstleistern ("<b>Wartungsdienstleister</b>") die Überwachung der industriellen Anlagen des Kunden zu ermöglichen. Die Lizenz endet automatisch mit der Kündigung oder dem Ablauf des zugrunde liegenden Dienstleistungsvertrags.',
    en: '2.2&nbsp;&nbsp;Henkel hereby grants to Customer, and Customer hereby accepts the grant of a non-exclusive, non-transferable, non-sublicensable, time limited, revocable license to access and use the Services provided on the Platform through the Account via the App (“<b>License</b>”). Customer has no right to modify the Services or the App. The License shall be limited to the sole purpose to allow Customer or third-party service providers being appointed by Customer on behalf of Customer (“<b>Maintenance Service Provider</b>”) to monitor Customer’s Industrial Assets. The License automatically ends with the termination or expiration of the underlying Service Agreement.'
  },
  service_scope_2_3_content: {
    de: '2.3&nbsp;&nbsp;Henkel ermöglicht dem Kunden den Zugang zu den Dienstleistungen über die App, etwa durch Anlegen und Löschen von (i) Personen, die im Namen des Kunden oder von Wartungsdienstleistern auf die Dienste zugreifen ("<b>Nutzer</b>") und (ii) IoT-Hardware, Industrieanlagen oder Ähnlichem. Entsprechende Änderungen werden mit der Bestätigung durch den Kunden in der App wirksam, wobei Henkel solchen Änderungen widersprechen und sie nicht zulassen kann, wenn ihre Durchführung für Henkel unzumutbar ist, insbesondere, aber nicht ausschließlich, aufgrund der begrenzten Kapazitäten der Plattform.',
    en: '2.3&nbsp;&nbsp;Henkel shall enable Customer to access the Services through the App, such as, where applicable, through creation and deletion of (i) individuals accessing the Services on behalf of Customer or Maintenance Service Providers (“<b>User</b>”) and (ii) IoT Hardware, Industrial Assets, or similar. Respective changes will become effective upon Customer’s confirmation in the App, provided that Henkel may object to and not permit such changes where their implementation is unacceptable to Henkel, in particular, but without limitation, due to limited capacities of the Platform.'
  },
  service_scope_2_4_content: {
    de: '2.4&nbsp;&nbsp;Der Kunde darf nur solche Nutzer mit der Nutzung des Kontos beauftragen, die von ihm für die Nutzung der Dienstleistungen geschult und zertifiziert wurden. Der Kunde wird Henkel unverzüglich darüber informieren, wenn einer seiner Mitarbeiter oder eine für den Kunden handelnde Person nicht mehr zur Nutzung der App berechtigt ist.',
    en: '2.4&nbsp;&nbsp;Customer shall only appoint those Users to use the Account who have been trained and certified by Customer for the use of the Services. Customer shall promptly inform Henkel of any of its employees or any persons acting on Customer’s behalf who shall no longer be entitled to use the App.'
  },
  service_scope_2_5_content: {
    de: '2.5&nbsp;&nbsp;Beauftragt der Kunde Wartungsdienstleister, so hat er Henkel vorher schriftlich zu informieren. Der Kunde ist für die Einhaltung des Servicevertrags durch die Wartungsdienstleister in vollem Umfang verantwortlich, als wäre er selbst Vertragspartei.',
    en: '2.5&nbsp;&nbsp;In case Customer appoints Maintenance Service Providers, Customer shall inform Henkel with prior written notice. Customer shall be fully responsible for any Maintenance Service Providers’ compliance with the Service Agreement as if it was a party hereto.'
  },
  service_scope_2_6_content: {
    de: '2.6&nbsp;&nbsp;Der Kunde trägt die alleinige Verantwortung für alle Handlungen und Unterlassungen (i) der Nutzer, (ii) der Mitarbeiter des Kunden, der Wartungsdienstleister und Dritter, die im Namen des Kunden handeln, und (iii) aller Dritten, die nicht von Henkel im Rahmen des Kundenkontos beauftragt wurden, es sei denn, sie wurden durch eine Verletzung der Verpflichtungen von Henkel im Rahmen des Servicevertrags verursacht. Darüber hinaus gilt jede Bezugnahme in der Servicevereinbarung auf den Kunden als einschließlich der Mitarbeiter des Kunden und Dritter, die im Namen des Kunden handeln.',
    en: '2.6&nbsp;&nbsp;Customer is solely responsible for all acts and omissions, as if such acts and omissions were Customer’s own acts and omissions, by (i) Users, (ii) Customer’s employees, Maintenance Service Providers and third parties acting on Customer’s behalf, and (iii) except to the extent caused by Henkel’s violation of Henkel’s obligations under the Service Agreement, any third party not commissioned by Henkel under the Customer’s Account. Further, any reference in the Service Agreement to Customer shall be deemed to include Customer’s employees and third parties acting on Customer’s behalf.'
  },
  service_scope_2_7_content: {
    de: '2.7&nbsp;&nbsp;Der Kunde erteilt hiermit allen Nutzern Vertretungsmacht in dem Umfang, in dem der Kunde diesen Nutzern Rollen zuweist, die es ihnen ermöglichen, im Rahmen der auf der Plattform bereitgestellten Funktionalitäten Erklärungen und/oder Mitteilungen im Namen des Kunden abzugeben, einschließlich, aber nicht beschränkt auf die Bestellung von Dienstleistungen und Änderungen der Konfiguration der vereinbarten Dienstleistungen über die Plattform. Der Kunde kann diese Vertretungsbefugnis nur widerrufen oder ändern, indem er die jeweilige Rollenzuweisung für diese Nutzer auf der Plattform ändert.',
    en: '2.7&nbsp;&nbsp;Customer hereby grants power of representation to all Users to the extent Customer assign roles to such Users enabling them to, in accordance with the functionalities provided on the Platform, submit declarations and/or notifications on Customer’s behalf, including, but not limited to, orders of Services and changes to the configuration of agreed Services through the Platform. Customer may only revoke or modify such power of representation by changing the respective assignment of roles to such Users on the Platform.'
  },
  service_scope_2_8_content: {
    de: '2.8&nbsp;&nbsp;Die Nutzung der Dienste erfordert die vorherige Zustimmung des Kunden zu den Nutzungsbedingungen für die LOCTITE Pulse App ("<b>App-Bedingungen</b>") in ihrer jeweils gültigen Fassung. Im Falle von Widersprüchen zwischen den Servicebedingungen und den App-Bedingungen haben letztere Vorrang. Die Nichteinhaltung der App-Bedingungen durch den Kunden gilt als Nichteinhaltung des Servicevertrags.',
    en: '2.8&nbsp;&nbsp;The use of the Services requires Customer’s prior agreement with Henkel on the Terms of Use of LOCTITE Pulse App (“<b>App Terms</b>”) as amended from time to time. In case of inconsistencies between the Service Conditions and the App Terms, the latter shall prevail. Customer’s non-compliance with the App Terms shall be deemed to constitute non-compliance with the Service Agreement.'
  },
  service_scope_3: {
    de: '3 Bereitstellung von Dienstleistungen',
    en: '3 Provision of Services'
  },
  service_scope_3_1_content: {
    de: '3.1&nbsp;&nbsp;Während der geltenden Laufzeit und vorbehaltlich der folgenden Bestimmungen dieses Abschnitts 3 stellt Henkel dem Kunden den Zugang zu den Dienstleistungen und deren Nutzung als Dienstleistung zur Verfügung, (i) in wesentlicher Übereinstimmung mit den Merkmalen und Funktionalitäten, wie sie in diesem Dienstleistungsvertrag abschließend beschrieben sind, und (ii) vorbehaltlich der bestehenden technischen und betrieblichen Möglichkeiten von Henkel und seinen Unterauftragnehmern.',
    en: '3.1&nbsp;&nbsp;During the applicable term and subject to the following provisions of this Section 3, Henkel shall provide Customer access to and use of the Services as a service (i) in substantial conformance with the features and functionalities as conclusively described in this Service Agreement; and (ii) subject to Henkel’s and its subcontractors’ existing technical and operational capabilities.'
  },
  service_scope_3_2_content: {
    de: '3.2&nbsp;&nbsp;Henkel stellt dem Kunden den Zugang und die Nutzung der Plattform am WAN-Ausgang des von Henkel genutzten Rechenzentrums zur Verfügung. Henkel übernimmt keine Verpflichtung oder Verantwortung, eine Datenverbindung zu einem solchen WAN-Ausgang herzustellen.',
    en: '3.2&nbsp;&nbsp;Henkel shall provide Customer access to and use of the Platform at the WAN exit of the data center used by Henkel. Henkel will not assume any obligation nor responsibility to effect any data connection to such WAN exit.'
  },
  service_scope_3_3_content: {
    de: '3.3&nbsp;&nbsp;Henkel strebt für die Plattform und die App eine Verfügbarkeit von 96 % an, wobei Folgendes gilt:',
    en: '3.3&nbsp;&nbsp;For the Platform and App, Henkel shall strive to achieve an availability level of 96 % subject to the following:'
  },
  service_scope_3_3_1_content: {
    de: '(i)&nbsp;&nbsp;Die Plattform und die App können aufgrund von Wartungs- und Servicearbeiten vorübergehend unterbrochen oder eingeschränkt werden (solche geplanten Arbeiten "<b>Geplante Ausfallzeit</b>").',
    en: '(i)&nbsp;&nbsp;The Platform and App may be subject to temporary suspension or limitation of the provision due to maintenance and service work (such planned work “<b>Planned Downtime</b>”).'
  },
  service_scope_3_3_2_content: {
    de: '(ii)&nbsp;&nbsp;Henkel wird den Kunden über eine Geplante Ausfallzeit durch eine Benachrichtigung in der App informieren. Wenn Henkel erwartet, dass die Geplante Ausfallzeit länger als eine (1) Stunde dauert, wird Henkel diese Information mit angemessener Vorankündigung in der App bereitstellen.',
    en: '(ii)&nbsp;&nbsp;Henkel shall inform Customer of any Planned Downtime by providing a notification on the App. If Henkel expects the Planned Downtime to last longer than one (1) hour, Henkel shall provide this information on the App with reasonable prior notice.'
  },
  service_scope_3_3_3_content: {
    de: '(iii)&nbsp;&nbsp;Wenn möglich, führt Henkel Service- und Wartungsarbeiten in Zeiten geringer Nutzung durch.',
    en: '(iii)&nbsp;&nbsp;If possible, Henkel shall carry out service and maintenance work during periods of low usage.'
  },
  service_scope_3_3_4_content: {
    de: '(iv)&nbsp;&nbsp;Henkel behält sich das Recht vor, ohne vorherige Ankündigung notwendige Maßnahmen zu ergreifen, um Bedrohungen für die Sicherheit und Integrität der Systeme abzumildern.',
    en: '(iv)&nbsp;&nbsp;Henkel retains the right to take necessary action without prior notice to mitigate threats to the security and integrity of the systems.'
  },
  service_scope_3_3_5_content: {
    de: '(v)&nbsp;&nbsp;Bei der Berechnung der Verfügbarkeit werden Einschränkungen der Verfügbarkeit aufgrund solcher Sicherheitsmaßnahmen und/oder geplanter Ausfallzeiten abgezogen.',
    en: '(v)&nbsp;&nbsp;When calculating the availability, limitations to the availability due to such security measures and/or Planned Downtime shall be deducted.'
  },
  service_scope_3_4_content: {
    de: '3.4&nbsp;&nbsp;Wenn ein Dienst die Verarbeitung von Rohdaten beinhaltet, verarbeitet Henkel die bezeichneten Daten in Übereinstimmung mit dem Programmieralgorithmus der jeweiligen Anwendung und stellt die analysierten Daten oder andere Ergebnisse dieser Verarbeitung in Übereinstimmung mit dem Dienstleistungsvertrag, insbesondere den Besonderen Bedingungen, zur Verfügung, vorausgesetzt, dass die in der LOCTITE Pulse® App angezeigten analysierten Daten von den jeweiligen Rohdaten abweichen können und letztere nur eine indikative Bedeutung haben.',
    en: '3.4&nbsp;&nbsp;Where a Service involves the processing of Raw Data, Henkel shall process the designated data in accordance with the programming algorithm of the respective application and make the Analyzed Data or other outcome of such processing available in accordance with the Service Agreement, in particular the Specific Terms, provided that any Analyzed Data shown in the LOCTITE Pulse® App may differ from the respective Ra w Data and shall only have indicative meaning.'
  },
  service_scope_3_5_content: {
    de: '3.5&nbsp;&nbsp;Während der Laufzeit des Dienstleistungsvertrags und nach dessen Beendigung ist Henkel nicht verpflichtet, vom Kunden zur Verfügung gestellte oder im Rahmen oder in Verbindung mit den Dienstleistungen verarbeitete Daten, einschließlich Rohdaten, Analysierte Daten und Abgeleitete Daten, zurückzugeben. Der Kunde ist nicht berechtigt, einen Auszug oder einen Überblick über diese Daten zu verlangen und hat keinen Anspruch auf Löschung dieser Daten. Zwingende gesetzliche Rechte des Kunden bleiben hiervon unberührt.',
    en: '3.5&nbsp;&nbsp;During the term of the Service Agreement and after termination, Henkel shall not be obliged to return data provided by Customer or processed as part of or in connection with the Services, including Raw Data, Analyzed Data and Derived Data. Customer is not entitled to request an extract or an overview of above data and has no right to request the deletion of such data. Mandatory rights of Customer under statutory law remain unaffected.'
  },
  service_scope_3_6_content: {
    de: '3.6&nbsp;&nbsp;Jegliche Benachrichtigungen, gleich in welcher Form (sei es in-App-Benachrichtigungen, Push-Benachrichtigungen für mobile Geräte und/oder Benachrichtigungen per E-Mail, zusammen "<b>Benachrichtigungen</b>") werden von Henkel nicht überprüft. Henkel ist nicht verpflichtet, einer Benachrichtigung nachzugehen, insbesondere nicht, (i) zu überwachen, ob und welche Maßnahmen der Kunde aufgrund einer Benachrichtigung einleitet oder (ii) wiederholte Benachrichtigungen (einschließlich Warnungen/Erinnerungen) zu versenden.',
    en: '3.6&nbsp;&nbsp;Any notifications, notwithstanding in which form (be it in-App-notifications, push notifications for mobile devices and/or alerts via email, jointly “<b>Notifications</b>”) are not being reviewed by Henkel. Henkel is not obliged to follow up with any Notification, in particular not to (i) monitor if and which action Customer initiates as a result of a Notification or (ii) send out repeated Notifications (including warnings/reminder).'
  },
  service_scope_3_7_content: {
    de: '3.7&nbsp;&nbsp;Für die Erbringung von Dienstleistungen kann Henkel beauftragen (i) jede Körperschaft oder Gesellschaft, die sich direkt oder indirekt im Besitz von Henkel befindet oder von Henkel kontrolliert wird, soweit der Besitz mehr als 50 % der für die Wahl der Direktoren stimmberechtigten Aktien beträgt (im Folgenden "<b>Verbundene Unternehmen</b>" genannt), (ii) Henkels Verbundene Unternehmen, (iii) oder dritte Dienstleister für die Erbringung von Dienstleistungen beauftragen, wobei sich der Begriff auf Unternehmen bezieht, die die oben genannten Kriterien in Bezug auf Henkel, den Kunden bzw. Henkel erfüllen, (iv) oder Drittanbieter von Dienstleistungen für die Erbringung eines Teils der Dienstleistungen.',
    en: '3.7&nbsp;&nbsp;For the provision of Services, Henkel may engage (i) Henkel, (ii) any corporation or company directly or indirectly owning, owned by or under common control with Henkel to the extent that the ownership is of more than 50 % of the stock entitled to vote for the election of directors (hereinafter “<b>Affiliates</b>”, with the term referring to entities meeting the aforementioned criteria in relation to either Henkel, Customer, or Henkel, as applicable), (iii) Henkel’s Affiliates, (iv) or third-party service suppliers for providing any part of the Services.'
  },
  service_scope_3_8_content: {
    de: '3.8&nbsp;&nbsp;Henkel stellt die Plattform in einer Mehrbenutzerumgebung zur Verfügung und gewährt dem Kunden Zugang zu und die Nutzung der jeweils aktuellen Version der Plattform und der Services, die Henkel den Kunden allgemein zur Verfügung stellt. Henkel aktualisiert und entwickelt die Technologie, Merkmale und Funktionalitäten der Plattform weiter und Henkel ist nicht verpflichtet, frühere Versionen der Plattform aufrechtzuerhalten. Sollten wesentliche Änderungen an der Plattform oder den Services vorgenommen werden, die Auswirkungen auf den Zugang oder die Nutzung der Plattform durch den Kunden haben, oder sollten wesentliche Merkmale oder Funktionalitäten der Plattform oder der Services eingeschränkt oder deaktiviert werden, wird Henkel angemessene Anstrengungen unternehmen, um den Kunden im Voraus zu informieren. Erfordert eine solche Aktualisierung oder Weiterentwicklung eine Änderung des Servicevertrags, so findet Ziffer 18 Anwendung.',
    en: '3.8&nbsp;&nbsp;Henkel provides the Platform in a multiuser environment and grants Customer access to and use of the then-current version of the Platform and the Services made generally available by Henkel to customers. Henkel updates and further develops the technology, features and functionalities of the Platform and neither Henkel nor Henkel is under any obligation to maintain prior versions of the Platform. Should material changes to the Platform or Services be implemented which have an impact on Customer’s access to or use of the Platform or material features or functionalities of the Platform or Services be restricted or disabled, Henkel will use reasonable efforts to notify Customer in advance. If such update or further development requires an amendment of the Service Agreement, Section 18 shall apply.'
  },
  service_scope_3_9_content: {
    de: '3.9&nbsp;&nbsp;Im Rahmen der Bereitstellung der Plattform kann Henkel dem Kunden nach Maßgabe der jeweiligen Besonderen Bedingungen Nutzer-Support zur Verfügung stellen.',
    en: '3.9&nbsp;&nbsp;As part of the provision of the Platform, Henkel may provide Customer with user support under relevant Specific Terms.'
  },
  service_scope_3_10_content: {
    de: '3.10&nbsp;&nbsp;Sofern nicht ausdrücklich etwas anderes vereinbart ist, bleibt der Kunde allein verantwortlich,',
    en: '3.10&nbsp;&nbsp;Unless specifically agreed otherwise, Customer shall remain solely responsible, and the Services do not include any obligations of Henkel to'
  },
  service_scope_3_10_1_content: {
    de: '(i)&nbsp;&nbsp;die Leistungen im Hinblick auf ihre Eignung für die vom Kunden verfolgten Ziele und Zwecke sowie etwaige individuelle finanzielle Vorteile zu prüfen, insbesondere im Zusammenhang mit einer Nutzung für den Betrieb von oder innerhalb eines Systems, das zusätzliche Sicherheitsfunktionen wie ausfallsichere oder fehlertolerante Merkmale zur Aufrechterhaltung eines sicheren Zustands erfordert, bei dem nach vernünftigem Ermessen vorhersehbar ist, dass ein Versagen des Geräts oder Systems unmittelbar zu Tod, Körperverletzung oder erheblichen Sachschäden führen kann. Dies gilt auch, wenn Dienstleistungen für einen bestimmten Zweck allgemein empfohlen werden; in diesem Fall darf sich der Kunde nicht auf eine solche Empfehlung verlassen und bestätigt, dass er die Dienstleistungen vor Abschluss eines Dienstleistungsvertrags unabhängig auf ihre Eignung für die vom Kunden verfolgten spezifischen Ziele und im Hinblick auf die Betriebsbedingungen vor Ort geprüft hat.',
    en: '(i)&nbsp;&nbsp;examine Services with respect to its suitability for the objectives and intended purposes pursued by Customer, as well as any potential individual financial benefits and in particular in connection with any use for the operation of or within a system that requires extra safety functionalities such as fail-safe or fault-tolerant features to maintain a safe state where it is reasonably foreseeable that failure of the device or system could lead directly to death, personal injury, or material property damage. This shall also apply if Services are generally recommended for a particular purpose; in this case Customer shall not rely on such recommendation and confirms that it has independently examined the Services with respect to their suitability for the specific objectives pursued by the Customer and with respect to the operating conditions on site prior to entering into a Service Agreement.'
  },
  service_scope_3_10_2_content: {
    de: '(ii)&nbsp;&nbsp;sich strikt an die Anforderungen der jeweiligen Bedienungsanleitung(en) zu halten, die jeweilige Bedienungsanleitung vor der Installation der IoT-Hardware erhalten und die darin festgelegten technischen Voraussetzungen, Anforderungen und Anweisungen verstanden zu haben und sich regelmäßig über etwaige aktualisierte Versionen der Bedienungsanleitung(en) zu informieren, die unter <a href="https://app.loctite-pulse.com" target="_blank" class="linkStyle">https://app.loctite-pulse.com</a> zugänglich sind.',
    en: '(ii)&nbsp;&nbsp;strictly adhere to the requirements of the applicable user instruction in the Operating Manual(s), to have obtained the respective Operating Manual prior to the installation of the IoT Hardware and to have understood the technical preconditions, requirements and instructions laid down in such document and to regularly check for any updated versions of the Operating Manual(s) which will be accessible at <a href="https://app.loctite-pulse.com" target="_blank" class="linkStyle">https://app.loctite-pulse.com</a>.'
  },
  service_scope_3_10_3_content: {
    de: '(iii)&nbsp;&nbsp;die Dienste nur als Ergänzung zu den bestehenden, zwingend erforderlichen und/oder dem Stand der Technik entsprechenden Schutzmaßnahmen des Kunden zur Überwachung des Gesundheitszustands von Industrieanlagen und/oder zur Aufdeckung von Unregelmäßigkeiten bei Industrieanlagen zu nutzen.',
    en: '(iii)&nbsp;&nbsp;only use the Services as an add-on to Customer’s existing, mandatory required and/or state-of-art protective measures to monitor the health status of Industrial Assets and/or to detect any irregularities of Industrial Assets.'
  },
  service_scope_3_10_4_content: {
    de: '(iv)&nbsp;&nbsp;die Art und Menge der Industriegüter sowie die Standorte der Sensoren zu bestimmen.',
    en: '(iv)&nbsp;&nbsp;determine the kind and amount of Industrial Assets as well as sensor locations.'
  },
  service_scope_3_10_5_content: {
    de: '(v)&nbsp;&nbsp;die Rohdaten über die von Henkel vorgegebene technische Schnittstelle an Henkel zu übermitteln.',
    en: '(v)&nbsp;&nbsp;transmit the Raw Data to Henkel via the technical interface specified by Henkel.'
  },
  service_scope_3_10_6_content: {
    de: '(vi)&nbsp;&nbsp;die Richtigkeit der Henkel zur Verfügung gestellten Unterlagen und/oder sonstigen Informationen über Rohdaten, auch im Hinblick auf etwaige damit verbundene Rechte Dritter, zu gewährleisten.',
    en: '(vi)&nbsp;&nbsp;ensure the correctness of documents and/or other information relating to Raw Data and provided to Henkel, also with regard to any associated rights of third parties.'
  },
  service_scope_3_10_7_content: {
    de: '(vii)&nbsp;&nbsp;die Sicherstellung und Aufrechterhaltung einer Internetverbindung und geeigneter Soft- und Hardware, wie im Dienstleistungsvertrag beschrieben, einschließlich der Bestellung, der ordnungsgemäßen Installation, des Betriebs und der Wartung geeigneter Hard- und/oder Software in ihrer neuesten verfügbaren Version, einschließlich etwaiger Updates, für die Verbindung von Industrieanlagen und die Übertragung von Daten durch den Kunden oder die Nutzer.',
    en: '(vii)&nbsp;&nbsp;secure and maintain an internet connection and suitable soft- and hardware as described in the Service Agreement, including the ordering, proper installation, operation and maintenance of suitable hardware and/or software in its latest available version, including any updates, for the connection of Industrial Assets and transfer of data by Customer or Users.'
  },
  service_scope_3_10_8_content: {
    de: '(viii)&nbsp;&nbsp;geeignete Maßnahmen zu ergreifen und auf Benachrichtigungen über Rohdaten und/oder Analysierte Daten zu reagieren, einschließlich, aber nicht beschränkt auf mögliche Benachrichtigungen, die der Kunde in Bezug auf den Status seiner relevanten industriellen Anlagen erhalten könnte, wobei der Kunde nach eigenem Ermessen und in eigener Verantwortung entscheidet, ob, welche und wann Konsequenzen oder Maßnahmen in Bezug auf eine solche Benachrichtigung zu ergreifen sind.',
    en: '(viii)&nbsp;&nbsp;take appropriate measures and react to any Notifications regarding Raw Data and/or Analyzed Data including but not limited to any potential Notification that Customer might receive regarding the status of its relevant Industrial Asset, Customer shall in its sole discretion and responsibility decide upon if, which and when any consequence or actions shall result or be taken in relation of any such Notification.'
  },
  service_scope_4: {
    de: '4 Verpflichtungen des Kunden',
    en: '4 Obligations of Customer'
  },
  service_scope_4_1_content: {
    de: '4.1&nbsp;&nbsp;Der Kunde ist verpflichtet, in seinen geschäftlichen Angelegenheiten alle Vorschriften und Gesetze einzuhalten, die am Standort der Industrieanlagen gelten und auf die Dienstleistungen anwendbar sind, einschließlich, aber nicht beschränkt auf solche, auf die  Abschnitt 17 Bezug nimmt. Der Kunde garantiert, dass er über alle notwendigen und erforderlichen Lizenzen, behördlichen Genehmigungen und Erlaubnisse verfügt, um seine Industrieanlagen zu betreiben und die Dienstleistungen in Anspruch zu nehmen, und dass diese Lizenzen und/oder Genehmigungen während der gesamten Laufzeit des Dienstleistungsvertrags in vollem Umfang aufrecht erhalten werden.',
    en: '4.1&nbsp;&nbsp;Customer shall in its business affairs comply with all regulations and laws prevailing in the location of Industrial Assets and applicable to the Services including, but not limited to, in accordance with Section 17. Customer warrants that it has all necessary and required licenses, governmental approvals and permits to operate its Industrial Assets and to receive the Services and that such licenses and/or approvals will be maintained in full force and effect throughout the term of the Service Agreement.'
  },
  service_scope_4_2_content: {
    de: '4.2&nbsp;&nbsp;Im Hinblick auf die Einhaltung der individuell auf den Kunden und die Industrieanlagen anwendbaren gesetzlichen Bestimmungen führt der Kunde in eigener Verantwortung und auf eigene Kosten eigene Eignungsprüfungen bei der zuständigen Behörde durch.',
    en: '4.2&nbsp;&nbsp;With respect to compliance with legal requirements individually applicable to Customer and Industrial Assets, Customer shall conduct its own suitability tests with the competent authority in its sole responsibility and on its own costs.'
  },
  service_scope_4_3_content: {
    de: '4.3&nbsp;&nbsp;Der Kunde ist verpflichtet, so weit wie möglich mit Henkel oder seinen Subunternehmern zusammenzuarbeiten, um die für die Erbringung der Dienstleistungen erforderlichen Lizenzen und Genehmigungen zu erhalten.',
    en: '4.3&nbsp;&nbsp;Customer shall cooperate to the fullest extent possible with Henkel or its sub-contractors as Henkel or its sub-contractors may require in obtaining of licenses and permits necessary for the performance of the Services.'
  },
  service_scope_4_4_content: {
    de: '4.4&nbsp;&nbsp;Der Kunde ist verpflichtet, Henkel unverzüglich schriftlich über einen möglichen Interessenkonflikt im Zusammenhang mit der im Servicevertrag geregelten Geschäftsbeziehung zu informieren.',
    en: '4.4&nbsp;&nbsp;Customer shall immediately inform Henkel in writing about any potential conflict of interest occurring in context with the business relationship set out in the Service Agreement.'
  },
  service_scope_4_5_content: {
    de: '4.5&nbsp;&nbsp;Der Kunde ist verpflichtet, die Funktionalität der Dienste regelmäßig zu überwachen, einschließlich, aber nicht beschränkt auf die tägliche Überprüfung von Statusaktualisierungen und Benachrichtigungen. Der Kunde ist verpflichtet, sichtbare oder anderweitig auffällige Unregelmäßigkeiten der Dienste unverzüglich nach Feststellung schriftlich zu melden. In Bezug auf die App wird der Kunde Henkel insbesondere dann schriftlich benachrichtigen, wenn eine tägliche Statusaktualisierung nicht erkennbar ist. Erfolgt keine oder eine verspätete Benachrichtigung, so gelten die daraus resultierenden Ausfallzeiten der Services als vom Kunden verursacht.',
    en: '4.5&nbsp;&nbsp;Customer shall on a regular basis monitor the functionality of the Services, including but not limited to the daily check of status updates and Notifications. Customer shall without undue delay after detection give written notice of visible or otherwise notable irregularities of the Services. With respect to the App, Customer shall in particular give written notice to Henkel in case a daily status update cannot be identified. If notice is not given or given with delay, Services downtime resulting therefrom shall be considered caused by Customer.'
  },
  service_scope_4_6_content: {
    de: '4.6&nbsp;&nbsp;Sollte der Kunde eine Nichteinhaltung der Servicestandards gemäß Abschnitt 3.1 feststellen, hat er Henkel unverzüglich zu benachrichtigen. Als ausschließlicher Rechtsbehelf des Kunden und als einzige Verpflichtung von Henkel wird Henkel wirtschaftlich angemessene Anstrengungen unternehmen, um die betroffenen Services wieder in Übereinstimmung mit den Servicestandards zu bringen.',
    en: '4.6&nbsp;&nbsp;Should Customer identify a non-compliance with the service standards pursuant to Section 3.1, Customer shall notify Henkel without undue delay. As Customer’s exclusive remedy and Henkel’s sole obligation, Henkel will use commercially reasonable efforts to restore the affected Services to compliance with the service standards.'
  },
  service_scope_4_7_content: {
    de: '4.7&nbsp;&nbsp;Soweit im Einzelfall erforderlich, gewährt der Kunde Henkel, und/oder seinen Auftragnehmern oder Unterauftragnehmern (einschließlich des Wartungsdienstleisters) (i) Zugang zu seinen Räumlichkeiten, Industrieanlagen, WLAN (falls erforderlich) und zur Stromversorgung sowie (ii) jede andere vernünftigerweise angeforderte Unterstützung für die ordnungsgemäße, rechtzeitige und effiziente Ausführung der Dienstleistungen während der regulären örtlichen Geschäftszeiten von Montag bis Samstag. In dringenden Fällen wird der Zugang zu den Räumlichkeiten vor Ort 24/7/365 gewährt. Der Fernzugang wird 24/7/365 gewährt. Wird der Fern- bzw. Vor-Ort-Zugang nicht gewährt, so gilt die Ausfallzeit als vom Kunden verursacht.',
    en: '4.7&nbsp;&nbsp;To the extent necessary in the individual case, Customer will give Henkel, Henkel and/or its contractors or sub-contractors (including Maintenance Service Provider) (i) access to its premises, Industrial Installations, WLAN (if required) and power supply as well as (ii) any other reasonably requested assistance for the orderly, timely and efficient execution of Services during regular local business hours from Monday to Saturday. In case of urgency, on-site access to premises shall be provided 24/7/365. Remote access shall be given 24/7/365. <u>If remote or on-site access</u>, as applicable, <u>is not provided</u>, downtime shall be considered caused by Customer.'
  },
  service_scope_4_8_content: {
    de: '4.8&nbsp;&nbsp;Der Kunde stellt sicher, dass IoT-Hardware und SIM-Karten für die Dienste (falls vorhanden) frei von Manipulationen zur Nutzung durch unbefugte Dritte sind. SIM-Karten (falls vorhanden) dürfen nicht für andere Zwecke verwendet werden, einschließlich, aber nicht beschränkt auf Internet-Chat-Dienste, Peer2Peer-Filesharing-Dienste, Betrieb von SMTP-Relay-Funktionen und andere Dienste, die geeignet sind, die Dienste zu stören.',
    en: '4.8&nbsp;&nbsp;Customer shall ensure that IoT-Hardware and SIM cards for the Services (if any) are free from manipulation for usage from unauthorized third parties. SIM cards (if any) must not be used for any other purposes, including but not limited to internet chat services, Peer2Peer filesharing services, operation of SMTP Relay functions and other services likely to interfere with Services.'
  },
  service_scope_5: {
    de: '5 Gebühren und Bezahlung',
    en: '5 Fees and Payment'
  },
  service_scope_5_1_content: {
    de: '5.1&nbsp;&nbsp;Als Gegenleistung für die Bereitstellung der Dienstleistungen zahlt der Kunde Gebühren an Henkel ("<b>Gebühren</b>").',
    en: '5.1&nbsp;&nbsp;In consideration for the provision of the Services, Customer shall pay fees to Henkel (“<b>Fees</b>”).'
  },
  service_scope_5_2_content: {
    de: '5.2&nbsp;&nbsp;Soweit nicht ausdrücklich anders vereinbart, verstehen sich die Entgelte in Euro netto zuzüglich etwaiger gesetzlicher Mehrwertsteuer und ausschließlich aller sonstigen Steuern, Zölle, Abgaben und Versicherungen. Alle Steuern, Zölle und Abgaben im Zusammenhang mit der Lieferung sind vom Kunden zu tragen oder Henkel zu erstatten.',
    en: '5.2&nbsp;&nbsp;Unless otherwise expressly agreed, Fees are quoted in Euro net plus statutory value added tax, if any, and exclusive of all other taxes, duties, levies and insurance. All taxes, customs duties and levies in connection with the delivery shall be borne by Customer or reimbursed to Henkel.'
  },
  service_scope_5_3_content: {
    de: '5.3&nbsp;&nbsp;Die Erbringung der Leistungen wird auf der Grundlage der Preisliste von Henkel (mit den jeweiligen Entgelten) vereinbart. Es gelten die im Auftrag genannten oder anderweitig vereinbarten, dem Kunden mitgeteilten oder anderweitig bekannt gegebenen Entgelte, hilfsweise die zum Zeitpunkt der Bestellung gültige Preisliste von Henkel. Die dem Kunden mitgeteilten oder sonst bekannt gegebenen Preislisten sind Bestandteil des Dienstleistungsvertrages, soweit sie nicht gesonderten Vereinbarungen entgegenstehen.',
    en: '5.3&nbsp;&nbsp;Provision of Services is agreed on the basis of Henkel’s price list (showing the respective Fees). Fees which have been communicated or otherwise made known to Customer specified in the order or otherwise agreed shall apply, alternatively Henkel’s price list valid on the date of the order. Price lists communicated or otherwise made known to Customer shall be an integral part of the Service Agreement unless they conflict with separate agreements.'
  },
  service_scope_5_4_content: {
    de: '5.4&nbsp;&nbsp;Um auf veränderte Marktbedingungen oder Marktentwicklungen angemessen reagieren zu können, ist Henkel berechtigt, die Entgelte im Falle von (i) wesentlichen Änderungen der Marktbedingungen, (ii) allgemeinen Erhöhungen von Löhnen oder sonstigen Arbeitskosten und/oder (iii) Änderungen der Beschaffungskosten aufgrund von Preisanpassungen der Lieferanten zu ändern, jeweils soweit diese Änderungen die Erbringung der Leistungen durch Henkel betreffen. Henkel wird den Kunden über jede Änderung, z.B. per E-Mail, informieren. Jede Änderung wird nach einer Ankündigungsfrist von 3 (drei) Monaten wirksam.',
    en: '5.4&nbsp;&nbsp;In order to react adequately to changes in market conditions or market developments, Henkel is entitled to change Fees in case of (i) material changes in the market conditions, (ii) general increases in wages or other employment costs, and/or (iii) changes in procurement costs due to price adaption of suppliers, in each case to the extent such changes affect Henkel’s provision of the Services. Henkel will notify Customer of any change, e.g., via email. Any change will become effective after a period of notice of 3 (three) months.'
  },
  service_scope_5_5_content: {
    de: '5.5&nbsp;&nbsp;Rechnungsbeträge sind innerhalb von 30 Tagen ab Rechnungsdatum fällig, sofern nichts anderes schriftlich vereinbart ist. Die auf der Auftragsbestätigung oder Rechnung angegebenen Zahlungsfristen, insbesondere auch für die Berechnung von Dauer und Umfang der jeweiligen Leistung, beginnen mit dem Rechnungsdatum.',
    en: '5.5&nbsp;&nbsp;Invoiced amounts are due within 30 days from the invoice date, unless otherwise agreed in writing. Payment periods stated on the order confirmation or invoice, in particular also for the calculation of the period and scope of the respective Service, shall commence on the invoice date.'
  },
  service_scope_5_6_content: {
    de: '5.6&nbsp;&nbsp;Der Kunde kann ein Zurückbehaltungsrecht nur geltend machen, wenn sein Gegenanspruch gegen Henkel rechtskräftig festgestellt oder unbestritten ist und auf dieselbe Währung lautet.',
    en: '5.6&nbsp;&nbsp;Customer may only assert a right of retention, if Customer’s counterclaim against Henkel has been established by a final and non-appealable decision or is undisputed and is owed in the same currency.'
  },
  service_scope_6: {
    de: '6 Eingeschränkte Garantie',
    en: '6 Limited Warranty'
  },
  service_scope_6_1_content: {
    de: '6.1&nbsp;&nbsp;Während der geltenden Laufzeit eines Servicevertrags gewährleistet Henkel, dass die Services im Wesentlichen den Servicestandards gemäß Abschnitt 3.1 entsprechen.',
    en: '6.1&nbsp;&nbsp;During the applicable term of a Service Agreement, Henkel warrants that the Services will materially comply with the service standards pursuant to Section 3.1.'
  },
  service_scope_6_2_content: {
    de: '6.2&nbsp;&nbsp;MIT AUSNAHME DER AUSDRÜCKLICHEN BESCHRÄNKTEN GARANTIE GEMÄSS ABSCHNITT 6.1 GIBT HENKEL KEINE AUSDRÜCKLICHEN ODER STILLSCHWEIGENDEN GARANTIEN UND DER KUNDE ERHÄLT KEINE GARANTIEN. JEGLICHE AUSSAGEN ODER DARSTELLUNGEN ÜBER DIE DIENSTLEISTUNGEN UND IHRE JEWEILIGE FUNKTIONALITÄT IN ANDEREN DOKUMENTEN ALS DEM DIENSTLEISTUNGSVERTRAG ODER EINER KOMMUNIKATION MIT DEM KUNDEN STELLEN LEDIGLICH TECHNISCHE INFORMATIONEN UND KEINE GARANTIE ODER AUSDRÜCKLICHE ODER STILLSCHWEIGENDE GEWÄHRLEISTUNG DAR. DARÜBER HINAUS LEHNT HENKEL AUSDRÜCKLICH JEGLICHE ANDERE GARANTIE ODER MÄNGELHAFTUNG AB, EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF DIE GARANTIE DER NICHTVERLETZUNG VON RECHTEN, ODER JEGLICHE STILLSCHWEIGENDE GARANTIE DER MARKTGÄNGIGKEIT, DER EIGNUNG FÜR EINEN BESTIMMTEN ZWECK, DER EIGNUNG ZUR ERREICHUNG BESTIMMTER VORTEILE, DES HANDELSBRAUCHS UND DER HANDELSBRÄUCHE. OHNE EINSCHRÄNKUNG DES VORSTEHENDEN GARANTIERT HENKEL NICHT, DASS DER BETRIEB DER SERVICES AUSFALLSICHER, FEHLERTOLERANT, UNUNTERBROCHEN ODER FEHLERFREI IST.',
    en: '6.2&nbsp;&nbsp;EXCEPT FOR THE EXPRESS LIMITED WARRANTY PROVIDED IN SECTION 6.1, HENKEL MAKES AND CUSTOMER RECEIVES NO EXPRESS OR IMPLIED WARRANTIES. ANY STATEMENTS OR REPRESENTATIONS ABOUT THE SERVICES AND THEIR RESPECTIVE FUNCTIONALITY IN ANY DOCUMENTS OTHER THAN THE SERVICE AGREEMENT OR ANY COMMUNICATION WITH CUSTOMER CONSTITUTES MERELY TECHNICAL INFORMATION AND NOT A GUARANTEE OR AN EXPRESS OR IMPLIED WARRANTY. IN ADDITION, HENKEL SPECIFICALLY DISCLAIMS ANY OTHER WARRANTY OR DEFECT LIABILITY INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF NONINFRINGEMENT, OR ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, FITNESS TO ACHIEVE CERTAIN BENEFITS, COURSE OF DEALING AND USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING, HENKEL DOES NOT WARRANT THAT THE OPERATION OF THE SERVICES WILL BE FAIL-SAFE, FAULT-TOLERANT, UNINTERRUPTED OR ERROR FREE.'
  },
  service_scope_7: {
    de: '7 Geistiges Eigentum',
    en: '7 Intellectual Property'
  },
  service_scope_7_1_content: {
    de: '7.1&nbsp;&nbsp;Henkel, seine Auftragnehmer und/oder Unterauftragnehmer sind Inhaber aller geistigen Eigentumsrechte an den Services, einschließlich der Plattform und der App. Dazu gehören Rechte an geistigem Eigentum, unabhängig davon, ob sie nicht eingetragen oder eingetragen sind oder einen Antrag auf Eintragung beinhalten, einschließlich, aber nicht beschränkt auf alle gegenwärtigen und zukünftigen Urheberrechte, Geschäftsgeheimnisse, Marken, Designs, Patente, Gebrauchsmuster, Ideen, Konzepte, Empfehlungen, Vorschläge, Entdeckungen, Know-how, Erfindungen, Rezepte, Spezifikationen, Software und Verbesserungen sowie alle Rechte und Schutzformen mit gleicher oder ähnlicher Wirkung wie die vorgenannten, überall auf der Welt ("<b>Geistiges Eigentum</b>"). Im Rahmen einer Dienstleistungsvereinbarung werden keine Rechte an geistigem Eigentum auf den Kunden übertragen.',
    en: '7.1&nbsp;&nbsp;Henkel, its contractors and/or sub-contractors own all intellectual property rights in the Services, including the Platform and the App. This includes intellectual property rights, whether unregistered or registered or involving an application for registration, including but not limited to all present and future copyrights, trade secrets, trademarks, designs, patents, utility models ideas, concepts, recommendations, suggestions, discoveries, know how, inventions, recipes, specifications, software and improvements as well as all rights and forms of protection with equivalent or similar effect to the aforementioned, anywhere in the world (“<b>Intellectual Property</b>”). No Intellectual Property right shall be transferred to Customer under any Service Agreement.'
  },
  service_scope_7_2_content: {
    de: '7.2&nbsp;&nbsp;Geistige Eigentumsrechte, die vom Kunden im Zusammenhang mit dem Dienstleistungsvertrag geschaffen, erdacht oder angewandt werden, ob patentierbar oder nicht, sind - im Verhältnis zum Kunden - das alleinige Eigentum von Henkel. Dies umfasst insbesondere, aber nicht ausschließlich, geistiges Eigentum in Bezug auf (i) die Erfassung, Übermittlung und Auswertung der von den Sensoren ermittelten Daten, (ii) mögliche Algorithmen zu deren Nutzung oder Anwendungen und (iii) Software. Solche Rechte gehen, soweit übertragbar, mit ihrer Entstehung automatisch auf Henkel über, ohne dass es einer weiteren Erklärung des Kunden bedarf. Soweit eine Übertragung des Anteils des Kunden an den Schutzrechten aus rechtlichen Gründen ausgeschlossen ist, räumt der Kunde Henkel mit dem Entstehen der Schutzrechte die ausschließlichen, zeitlich, räumlich und inhaltlich unbeschränkten Nutzungsrechte an den Schutzrechten ein. Durch die Rechtseinräumung wird Henkel insbesondere in die Lage versetzt, die Schutzrechte und Leistungsschutzrechte uneingeschränkt zu nutzen und an Dritte, auch als ausschließliche Rechte, zu übertragen oder zu lizenzieren oder in Kooperation mit Dritten zu nutzen.',
    en: '7.2&nbsp;&nbsp;Intellectual Property rights that may be created, conceived or applied by Customer in connection with the Service Agreement whether patentable or not, shall – in relation to Customer – be the sole property of Henkel. This does in particular include but is not limited to Intellectual Property regarding (i) the collection, transmission and evaluation of data determined by sensors, (ii) potential algorithms involved in their use or applications thereof, and (iii) software. Such rights, if transferable, shall be automatically transferred to Henkel when they come into existence without need for any further declaration by Customer. To the extent that a transfer of Customer’s portion in the Intellectual Property rights is ruled out due to legal reasons, Customer grants Henkel, as soon as the Intellectual Property rights come into existence, the exclusive rights of use in the Intellectual Property rights which shall be unlimited in terms of time, space and content. By granting the rights Henkel shall, in particular, be put into the position to use Intellectual Property rights and ancillary copyrights without restrictions and to transfer or license such rights to third parties, also as exclusive rights, or to use them in cooperation with third parties.'
  },
  service_scope_7_3_content: {
    de: '7.3&nbsp;&nbsp;Der Kunde gewährt Henkel eine weltweite, unbefristete, unwiderrufliche, übertragbare, unterlizenzierbare und gebührenfreie Lizenz zur Nutzung aller Vorschläge, Empfehlungen, Funktionswünsche oder sonstiger Rückmeldungen im Zusammenhang mit den Diensten, die von oder im Namen des Kunden, der Kunden des Kunden und der Nutzer bereitgestellt werden, und zur Einbindung der oben genannten Informationen in die Dienste und/oder andere Produkte oder Dienste.',
    en: '7.3&nbsp;&nbsp;Customer grants Henkel a worldwide, perpetual, irrevocable, transferable, sub-licensable, royalty-free license to use any suggestion, recommendation, feature request, or other feedback related to the Services provided by or on behalf of Customer, Customers’ customers and Users, and to incorporate any of the above into the Services and/or any other products or services.'
  },
  service_scope_8: {
    de: '8 Eigentum an den Daten',
    en: '8 Ownership of Data'
  },
  service_scope_8_1_content: {
    de: '8.1&nbsp;&nbsp;Der Kunde ist der Eigentümer der Rohdaten. Henkel ist berechtigt, die Rohdaten auf nicht ausschließlicher, unentgeltlicher, weltweiter, unbefristeter, unwiderruflicher, unterlizenzierbarer und übertragbarer Basis zu nutzen, einschließlich, aber nicht beschränkt auf die Nutzung der Rohdaten in Verbindung mit künstlicher Intelligenz, und die Rohdaten von Henkels Auftragnehmern, Unterauftragnehmern, Lieferanten, Unterlizenznehmern oder anderen Dritten für die Durchführung, Aufrechterhaltung, Verbesserung und Weiterentwicklung der Geschäftsaktivitäten von Henkel oder Henkel, insbesondere in den Bereichen datengestützter Dienstleistungen, nutzen zu lassen; einschließlich, aber nicht beschränkt auf das Recht von Henkel',
    en: '8.1&nbsp;&nbsp;Customer shall be the owner of the Raw Data. Henkel shall be permitted to use Raw Data on a non-exclusive, royalty-free, worldwide, perpetual , irrevocable, sub-licensable, transferable basis, including, without limitation, to use the Raw Data in conjunction with artificial intelligence, and to have Raw Data used by Henkel’s contractors, sub-contractors, suppliers, sub-licensees or other third parties for the performance, maintenance, improvement and further development of Henkel’s or Henkel’s business activities in particular in the fields of data enabled services; including without limitation Henkel’s right'
  },
  service_scope_8_1_1_content: {
    de: '(i)&nbsp;&nbsp;Rohdaten zu aggregieren und zu analysieren;',
    en: '(i)&nbsp;&nbsp;to aggregate and analyze Raw Data;'
  },
  service_scope_8_1_2_content: {
    de: '(ii)&nbsp;&nbsp;Rohdaten dauerhaft zu speichern;',
    en: '(ii)&nbsp;&nbsp;to permanently store Raw Data;'
  },
  service_scope_8_1_3_content: {
    de: '(iii)&nbsp;&nbsp;Rohdaten, aggregierte und analysierte Rohdaten, insbesondere auch Analysierte Daten mit eigenen Daten oder Daten Dritter zu eigenständigen neuen Datensätzen für die Geschäftsaktivitäten von Henkel oder Henkel, insbesondere im Bereich der Data Enabled Services („<b>Abgeleitete Daten</b>“), dauerhaft zu nutzen, weiterzuentwickeln und zusammenzuführen;',
    en: '(iii)&nbsp;&nbsp;to permanently use, further develop and merge Raw Data, aggregated and analyzed Raw Data, including without limitation Analyzed Data with own data or data of third parties into independent new data sets, for Henkel’s or Henkel’s business activities in particular in the fields of data enabled services (“<b>Derived Data</b>”);'
  },
  service_scope_8_1_4_content: {
    de: '(iv)&nbsp;&nbsp;Rohdaten, Analysierte Daten und Abgeleitete Daten in anonymisierter Form an Dritte zu veröffentlichen, weiterzugeben oder zu übermitteln;',
    en: '(iv)&nbsp;&nbsp;to publish/disclose/transfer Raw Data, Analyzed Data and Derived Data in an anonymous form to third parties;'
  },
  service_scope_8_1_5_content: {
    de: '(v)&nbsp;&nbsp;Rohdaten, Analysierte Daten und Abgeleitete Daten in anonymer Form in interne und externe Geschäftsprozesse, Produkte und/oder Anwendungen in öffentlichen und nicht-öffentlichen elektronischen Netzen zu integrieren;',
    en: '(v)&nbsp;&nbsp;to integrate Raw Data, Analyzed Data and Derived Data in an anonymous form into internal and external business processes, products and/or applications in public and non-public electronic networks;'
  },
  service_scope_8_1_6_content: {
    de: '(vi)&nbsp;&nbsp;Rohdaten, Analysierte Daten und Abgeleitete Daten im Rahmen von Tests, Simulationen, Untersuchungen und/oder Analysen zu verwenden, und zwar im Zusammenhang mit der derzeitigen oder künftigen Geschäftstätigkeit von Henkel oder Henkel.',
    en: '(vi)&nbsp;&nbsp;to use Raw Data, Analyzed Data and Derived Data in the context of tests, simulations, investigations and/or analyses, all in connection with Henkel’s or Henkel’s current or future business activities.'
  },
  service_scope_8_2_content: {
    de: '8.2&nbsp;&nbsp;Henkel ist der alleinige und ausschließliche Eigentümer der Analysierten Daten und gewährt dem Kunden hiermit ein unentgeltliches, nicht ausschließliches, nicht übertragbares, nicht unterlizenzierbares, zeitlich begrenztes Recht zur Nutzung der Analysierten Daten für den internen Geschäftsbetrieb des Kunden, und der Kunde akzeptiert hiermit die Gewährung dieses Rechts. Zur Klarstellung: Das Recht zur Unterlizenzierung der Analysierten Daten ist auf den alleinigen Zweck beschränkt, den vom Kunden beauftragten Wartungsdienstleistern die Überwachung der industriellen Anlagen des Kunden zu ermöglichen. Die Lizenz für die Analysierten Daten endet automatisch mit der Beendigung oder dem Ablauf des Servicevertrags.',
    en: '8.2&nbsp;&nbsp;Henkel shall be the sole and exclusive owner of the Analyzed Data and hereby grants to Customer, and Customer hereby accepts the grant of, a royalty-free, non-exclusive, non-transferable, non-sublicensable, timely limited right to use Analyzed Data for Customer’s internal business operations. For clarification purposes, the right to sub-license the Analyzed Data shall be limited to the sole purpose to allow Maintenance Service Providers being appointed by Customer to monitor Customer’s Industrial Assets on behalf of them. The license in Analyzed Data shall automatically end with the termination or expiration of the Service Agreement.'
  },
  service_scope_8_3_content: {
    de: '8.3&nbsp;&nbsp;Analysierte Daten und Abgeleitete Daten sind vertrauliche Informationen von Henkel (gemäß der Definition in Abschnitt 9.1 unten) und stehen unter der rechtmäßigen Kontrolle von Henkel.',
    en: '8.3&nbsp;&nbsp;Analyzed Data and Derived Data shall be Henkel’s Confidential Information (as defined in Section 9.1 below) and under the lawful control of Henkel.'
  },
  service_scope_9: {
    de: '9 Vertraulichkeit',
    en: '9 Confidentiality'
  },
  service_scope_9_1_content: {
    de: '9.1&nbsp;&nbsp;Der Begriff "<b>vertrauliche Informationen</b>" umfasst alle Informationen, die sich auf die Geschäfte, Angelegenheiten, Abläufe, Budgets, das Personal, die Kunden, Lieferanten, Produkte, Entwicklungen, Technologien, technischen Daten, Zeichnungen, Geschäftsgeheimnisse, Bedienungsanleitungen, Werbeschriften, Prozesse, Verfahren, Techniken, Methoden, Strategien und Politiken beziehen, insbesondere Geschäftsmethoden, Marketingmethoden und Preispolitik, unabhängig davon, wie sie übermittelt oder dargestellt werden, sowie alle anderen Informationen, die von einer der Parteien eindeutig als vertraulich bezeichnet werden.',
    en: '9.1&nbsp;&nbsp;“<b>Confidential Information</b>” shall encompass any and all information that relates to a Party’s business, affairs, operations, budgets, personnel, clients, suppliers, products, developments, technologies, technical data, drawings, trade secrets, instruction manuals, advertising literature, processes, procedures, techniques, methods, strategies, and policies including without limitation business methods, marketing methods and pricing policies, however conveyed or presented and any other information clearly designated by either Party as confidential.'
  },
  service_scope_9_2_content: {
    de: '9.2&nbsp;&nbsp;Die empfangende Partei wird alle vertraulichen Informationen, die sie von der offenlegenden Partei erhalten hat, vertraulich behandeln und ohne die vorherige schriftliche Zustimmung der offenlegenden Partei keine vertraulichen Informationen (ganz oder teilweise) in irgendeiner Form oder auf irgendeine Weise an Dritte weitergeben oder offenlegen. Der Begriff "Dritte" gilt nicht für (i) die Verbundenen Unternehmen von Henkel (mit Ausnahme von Verbundenen Unternehmen, die direkte Wettbewerber der offenlegenden Partei sind) und (ii) die Auftragnehmer, Unterauftragnehmer oder Berater von Henkel, einschließlich Henkel und der Verbundenen Unternehmen von Henkel, jeweils nur insoweit, als sie zur gleichen Vertraulichkeit verpflichtet sind wie die Parteien oder gesetzlichen Geheimhaltungspflichten unterliegen.',
    en: '9.2&nbsp;&nbsp;The receiving Party will hold confidential any and all Confidential Information received from the disclosing Party and will not divulge or disclose (in whole or in part) Confidential Information in any form or manner whatsoever to any third party without the prior written consent of the disclosing Party. The term “third party” shall not apply to (i) Henkel’s Affiliates (save Affiliates that are direct competitors of the disclosing Party) and (ii) to Henkel’s contractors, sub-contractors or advisors, including Henkel and Henkel’s Affiliates, both only to the extent they are bound to the same degree of confidentiality as the Parties or subject to statutory confidentiality obligations.'
  },
  service_scope_9_3_content: {
    de: '9.3&nbsp;&nbsp;Die empfangende Vertragspartei wird vertrauliche Informationen nur für die Erfüllung ihrer Pflichten und Rechte im Rahmen der Dienstleistungsvereinbarung verwenden.',
    en: '9.3&nbsp;&nbsp;The receiving Party will not use Confidential Information for any purpose other than to perform its obligations and rights under the Service Agreement.'
  },
  service_scope_9_4_content: {
    de: '9.4&nbsp;&nbsp;Die Verpflichtungen der empfangenden Vertragspartei gemäß den Abschnitten 9.2 und 9.3 gelten nicht für Informationen, die:',
    en: '9.4&nbsp;&nbsp;The obligations of the receiving Party set out in Sections 9.2 and 9.3 will not apply to any information which:'
  },
  service_scope_9_4_1_content: {
    de: '(i)&nbsp;&nbsp;die empfangende Vertragspartei durch Gesetz oder Anordnung zur Offenlegung verpflichtet ist; oder',
    en: '(i)&nbsp;&nbsp;the receiving Party is required to disclose by law or order; or'
  },
  service_scope_9_4_2_content: {
    de: '(ii)&nbsp;&nbsp;zum Zeitpunkt der Offenlegung öffentlich zugänglich ist oder danach öffentlich zugänglich wird, in beiden Fällen ohne rechtswidrige Handlung oder Unterlassung der empfangenden Vertragspartei; oder',
    en: '(ii)&nbsp;&nbsp;is publicly available at the time it was disclosed or becomes publicly available thereafter, in both cases through no unlawful act or omission of the receiving Party; or'
  },
  service_scope_9_4_3_content: {
    de: '(iii)&nbsp;&nbsp;von der empfangenden Vertragspartei eigenständig entwickelt wurde, was durch schriftliche Belege nachgewiesen werden kann.',
    en: '(iii)&nbsp;&nbsp;was independently developed by the receiving Party as can be demonstrated by written evidence.'
  },
  service_scope_9_5_content: {
    de: '9.5&nbsp;&nbsp;Die empfangende Partei stellt sicher, dass nur Mitarbeiter, die vertrauliche Informationen für die Erfüllung der Dienstleistungsvereinbarung benötigen, Zugang zu den vertraulichen Informationen haben und dass diese Mitarbeiter verpflichtet sind, die vertraulichen Informationen vertraulich zu behandeln und sie nicht für andere Zwecke als die Erfüllung der Dienstleistungsvereinbarung zu verwenden.',
    en: '9.5&nbsp;&nbsp;The receiving Party shall ensure that only employees who need Confidential Information for the performance of the Service Agreement have access to the Confidential Information and that such employees are bound to keep Confidential Information confidential and not to use Confidential Information for purposes other than the performance of the Service Agreement.'
  },
  service_scope_9_6_content: {
    de: '9.6&nbsp;&nbsp;Die empfangende Vertragspartei ergreift alle erforderlichen Maßnahmen, um die Vertraulichkeit aller ihr von der offenlegenden Vertragspartei zur Verfügung gestellten vertraulichen Informationen zu gewährleisten. Dazu gehört auch die Durchführung angemessener und dem Stand der Technik entsprechender Sicherheitsmaßnahmen.',
    en: '9.6&nbsp;&nbsp;The receiving Party shall take all necessary measures to ensure the confidentiality of all Confidential Information provided to it by the disclosing Party. This includes the implementation of appropriate and state-of-the-art security measures.'
  },
  service_scope_9_7_content: {
    de: '9.7&nbsp;&nbsp;Vertraulichkeitsverpflichtungen im Sinne dieses Abschnitts 9 bleiben bei und nach Beendigung des Dienstleistungsvertrags gültig.',
    en: '9.7&nbsp;&nbsp;Confidentiality obligations referred to in this Section 9 remain valid upon and after termination of the Service Agreement.'
  },
  service_scope_9_8_content: {
    de: '9.8&nbsp;&nbsp;Vertrauliche Informationen werden in der vorliegenden Form weitergegeben.',
    en: '9.8&nbsp;&nbsp;Confidential Information is disclosed on an as-is basis.'
  },
  service_scope_10: {
    de: '10 Höhere Gewalt',
    en: '10	Force Majeure'
  },
  service_scope_10_1_content: {
    de: '10.1&nbsp;&nbsp;Eine Verzögerung oder ein Versäumnis einer der Vertragsparteien bei der Erfüllung einer Verpflichtung aus der Dienstleistungsvereinbarung, ganz oder teilweise, gilt nicht als Verletzung der Dienstleistungsvereinbarung, wenn und soweit sie durch ein Ereignis höherer Gewalt verursacht wurde, d.h., jedes Ereignis, das außerhalb der zumutbaren Kontrolle einer der Vertragsparteien liegt, einschließlich, aber nicht beschränkt auf höhere Gewalt, Streiks, Pandemien und Epidemien, Aussperrungen, Arbeitskämpfe, Arbeitsstreitigkeiten, Transportunterbrechungen, Krieg, Aufruhr, zivile Unruhen, Unfälle, Naturkatastrophen, geldpolitische, handelspolitische und andere hoheitliche Maßnahmen, erhebliche Betriebsstörungen, wie z. B., Feuer, Ausfall von Anlagen oder Geräten, Maschinenbruch, Mangel an Rohstoffen oder Energie oder Internetverbindung aus welchem Grund auch immer, einschließlich, aber nicht beschränkt auf Engpässe und andere Lieferprobleme bei Rohstoffen, Lieferanten oder anderen Subunternehmern, sowie Behinderung von Transportwegen und Verzögerungen, die sich aus der Erteilung notwendiger behördlicher Genehmigungen oder daraus resultierenden Maßnahmen einer Behörde/eines Staates ergeben, Embargos, Beschränkungen oder Sanktionen gemäß Exportkontrollvorschriften, staatliche Beschränkungen und Auflagen oder die unvorhergesehene Erhöhung des Risikos, dass die Erfüllung von Verpflichtungen aus dem Dienstleistungsvertrag zur Verhängung von Sanktionen führen könnte (z. B.g., Sekundärsanktionen, die die Erbringung von Dienstleistungen erheblich erschweren, verzögern oder unmöglich machen) ("<b>Ereignis höherer Gewalt</b>").',
    en: '10.1&nbsp;&nbsp;Any delay or failure by either Party in the performance of any obligation under the Service Agreement, in full or in part, shall not be deemed a breach of the Service Agreement if and to the extent caused by a force majeure event, i.e., any event beyond the reasonable control of either Party including, without limitation, acts of God, strikes, pandemics and epidemics, lock-outs, industrial dispute, labor disputes, transport interruption, war, riots, civil commotions, accidents, natural disasters, monetary policy, trade policy and other sovereign measures, significant business disruptions, such as e.g., fire, plant or equipment breakdown, machinery breakage, lack of raw materials or energy or internet connection for whatever reason, including, without limitation, shortages and other delivery issues of raw material, suppliers or other subcontractors, as well as obstruction of routes and delays resulting from the issuance of necessary regulatory approvals or resulting actions by an agency/state government, embargoes, restrictions or sanctions under export control regulations, government restrictions and impositions or the unforeseen increase in the risk that performance of obligations under the Service Agreement could result in the imposition of sanctions (e.g., secondary sanctions which significantly impede or delay the delivery of Services or make it impossible) (“<b>Force Majeure Event</b>”).'
  },
  service_scope_10_2_content: {
    de: '10.2&nbsp;&nbsp;Eine Partei, die sich auf ein Ereignis höherer Gewalt beruft, teilt dies der anderen Partei unverzüglich mit und gibt die voraussichtliche Dauer der Aussetzung an, sofern diese absehbar ist.',
    en: '10.2&nbsp;&nbsp;A Party claiming a Force Majeure Event shall without undue delay notify the other Party and the estimated duration of the suspension period, if reasonably predictable.'
  },
  service_scope_10_3_content: {
    de: '10.3&nbsp;&nbsp;Wenn ein Ereignis höherer Gewalt die Fähigkeit von Henkel, Dienstleistungen zu erbringen, nur teilweise behindert, darf der Kunde Teilleistungen nicht unangemessenerweise als verfügbar ablehnen.',
    en: '10.3&nbsp;&nbsp;If a Force Majeure Event only partially hinders Henkel’s ability to provide Services, Customer shall not unreasonably decline partial Services as available.'
  },
  service_scope_10_4_content: {
    de: '10.4&nbsp;&nbsp;Ein Ereignis höherer Gewalt berechtigt keine der Parteien zur Kündigung des Dienstleistungsvertrags, es sei denn, es bestehen zwingende gesetzliche Kündigungsrechte.',
    en: '10.4&nbsp;&nbsp;A Force Majeure Event shall not entitle either Party to terminate the Service Agreement, unless termination rights are given under mandatory statutory law.'
  },
  service_scope_11: {
    de: '11 Löschung und Aussetzung',
    en: '11 Deletion and Suspension'
  },
  service_scope_11_1_content: {
    de: '11.1&nbsp;&nbsp;Henkel ist berechtigt, Kundendaten oder -informationen zu löschen und/oder den Zugang zu solchen Kundendaten oder -informationen zu sperren, wenn und soweit die Kundendaten oder -informationen nach angemessenem Ermessen von Henkel nicht den Anforderungen des Servicevertrags entsprechen oder nicht mit geltendem Recht vereinbar sind.',
    en: '11.1&nbsp;&nbsp;Henkel is entitled to delete Customer data or information and/or to disable access to such Customer data or information if and to the extent that, based on Henkel’s reasonable judgement, the Customer data or information does not meet the requirements of the Service Agreement or is non-compliant with applicable law.'
  },
  service_scope_11_2_content: {
    de: '11.2&nbsp;&nbsp;Henkel ist berechtigt, den Zugang des Kunden und/oder Dritter, denen der Kunde die Nutzung der Plattform gestattet hat, zur Plattform ganz oder teilweise zu sperren, wenn (i) der Kunde seinen Zahlungsverpflichtungen aus dem Dienstleistungsvertrag ganz oder teilweise nicht nachkommt, (ii) der Kunde sonstige Verpflichtungen aus dem Dienstleistungsvertrag wesentlich verletzt, (iii) die Sperrung des Zugangs aufgrund gesetzlicher Vorgaben erforderlich ist oder (iv) eine solche Sperrung aus technischen Gründen aufgrund eines dringenden Sicherheitsproblems (z. B. Viren im Computersystem, Probleme mit der Hard-/Software, Cyberangriffe oder andere dringende Sicherheitsprobleme) erforderlich ist.',
    en: '11.2&nbsp;&nbsp;Henkel is entitled to completely or partially disable Customer’s access and/or that of third parties who Customer allowed to use the Platform to the Platform if (i) Customer does not fulfill Customer’s payment obligations as specified in the Service Agreement in part or in full, (ii) Customer materially breaches other obligations under the Service Agreement, (iii) it is necessary to disable the access due to legal requirements, or (iv) such suspension is necessary for technical reasons (e.g. viruses in the computer system, problems in the hardware/software, cyber attacks or other)emergency security issues)due to an emergency security issue.'
  },
  service_scope_11_3_content: {
    de: '11.3&nbsp;&nbsp;Wenn und soweit Henkel von den Rechten aus Ziffer 11.1 oder 11.2 Gebrauch macht, bleibt der Kunde haftbar für (i) alle Gebühren, die dem Kunden bis zum Zeitpunkt der Aussetzung entstanden sind, (ii) alle vereinbarten Gebühren für die ausgesetzten Dienste, es sei denn, der Zugriff auf die Plattform oder die Dienste oder deren Nutzung durch Henkel oder seine Nutzer ist nicht betrügerisch oder verstößt gegen die Dienstleistungsvereinbarung, und (iii) alle Gebühren für den Zugriff auf und die Nutzung von nicht ausgesetzten Diensten.',
    en: '11.3&nbsp;&nbsp;If and to the extent Henkel makes use of the rights under Section 11.1 or 11.2, Customer remains liable for (i) all Fees Customer has incurred until the date of suspension, (ii) all agreed Fees for the suspended Services, unless the access to or use of the Platform or Services by Henkel or its Users is not fraudulent or in breach of the Service Agreement, and (iii) all Fees for access to and use of any Services not suspended.'
  },
  service_scope_12: {
    de: '12 Dauer der Vereinbarung',
    en: '12 Termination'
  },
  service_scope_12_1_content: {
    de: '12.1&nbsp;&nbsp;Der Dienstleistungsvertrag läuft bis zum Ende (i) des Monats Juni des auf den Abschluss folgenden Jahres, wenn er vor Ende Juni abgeschlossen wird, und (ii) des Monats Dezember des auf den Abschluss folgenden Jahres, wenn er nach Ende Juni abgeschlossen wird, und verlängert sich automatisch um jeweils ein (1) Jahr, wenn er nicht von einer der Vertragsparteien unter Einhaltung einer Frist von drei (3) Monaten zum Ende des jeweiligen Vertragszeitraums, d. h. zum Ende des Monats Juni bzw. Dezember, ganz oder teilweise gekündigt wird.',
    en: '12.1&nbsp;&nbsp;The Service Agreement runs until the end of (i) June of the year following conclusion if concluded before the end of June; and (ii) December of the year following conclusion if concluded after the end of June and automatically extends by periods of one (1) year each unless terminated by either Party in full or in part upon three (3) months prior written notice effective at the end of the relevant contract period, i.e., the end of June or December, respectively.'
  },
  service_scope_12_2_content: {
    de: '12.2&nbsp;&nbsp;Das Recht zur Kündigung aus wichtigem Grund bleibt hiervon unberührt. Henkel kann insbesondere jeden Servicevertrag ganz oder teilweise mit sofortiger Wirkung und ohne Haftung gegenüber dem Kunden kündigen, wenn',
    en: '12.2&nbsp;&nbsp;Termination rights for cause remain unaffected. In particular, Henkel may terminate any Service Agreement in full or in part with immediate effect and without liability to Customer, if'
  },
  service_scope_12_2_1_content: {
    de: '(i)&nbsp;&nbsp;Der Kunde ausstehende Gebühren nicht zahlt, sofern Henkel dem Kunden eine schriftliche Mahnung mit dem ausstehenden Betrag zugesandt hat und dreißig (30) weitere Tage ohne vollständige Zahlung verstrichen sind.',
    en: '(i)&nbsp;&nbsp;Customer fails to pay outstanding Fees provided that Henkel has sent to Customer a written notice with the outstanding amount and thirty (30) additional days have passed without full payment.'
  },
  service_scope_12_2_2_content: {
    de: '(ii)&nbsp;&nbsp;Henkel dem Kunden den Zugriff auf die App nicht mehr ermöglicht (z. B. weil Henkel Kenntnis davon erlangt hat oder vernünftigerweise davon ausgeht, dass der Kunde gegen die App-Bedingungen verstoßen hat).',
    en: '(ii)&nbsp;&nbsp;Henkel has ceased to provide Customer with access to the App (e.g., because Henkel has become aware or reasonably assumes that Customer has breached the App Terms).'
  },
  service_scope_12_2_3_content: {
    de: '(iii)&nbsp;&nbsp;Es zu einer grundlegenden Änderung der Eigentumsverhältnisse, der Struktur, der Kontrolle oder des Managements des Kunden kommt, einschließlich, aber nicht beschränkt auf (a) einen Verkauf von im Wesentlichen allen Vermögenswerten des Kunden, (b) eine Fusion oder Konsolidierung des Kunden mit oder in einem anderen Unternehmen oder (c) das Eintreten eines Ereignisses oder einer Reihe von Ereignissen, bei denen eine Person oder eine Gruppe von Personen direkt oder indirekt mehr als 50 % der Aktien oder Stimmen des Kunden kontrolliert.',
    en: '(iii)&nbsp;&nbsp;There is a fundamental change in ownership, structure, control, or management of Customer, including without limitation (a) a sale of substantially all of Customer’s assets, (b) a merger or consolidation of Customer with or into any other company, or (c) the occurrence of an event or series of events whereby any person or group of persons, controls, directly or indirectly, more than 50 % of Customer’s shares or votes.'
  },
  service_scope_12_2_4_content: {
    de: '(iv)&nbsp;&nbsp;Einer der Lieferanten und/oder Unterauftragnehmer von Henkel die Erfüllung seiner Verpflichtungen aus dem Vertrag mit Henkel wesentlich eingestellt hat, was dazu führt, dass Henkel nicht mehr in der Lage ist, die Dienste, den Zugang zur App und/oder den Verkauf der IoT-Geräte zu erbringen, z. B. aufgrund einer Insolvenz und/oder eines Konkurses eines der Lieferanten von Henkel.',
    en: '(iv)&nbsp;&nbsp;Any of Henkel’s suppliers and/or subcontractors has materially ceased to provide its obligations under its Agreement with Henkel, or as the case may be with Henkel which leads to Henkel no longer being able to provide the Services, access to the App and/or to sell the IoT Equipment, e. g., due to insolvency and/or bankruptcy of any of Henkel’s suppliers.'
  },
  service_scope_12_3_content: {
    de: '12.3&nbsp;&nbsp;Jede Vertragspartei kann die Dienstleistungsvereinbarung mit sofortiger Wirkung und ohne Haftung gegenüber der anderen Vertragspartei kündigen, wenn die kündigende Vertragspartei nach billigem Ermessen feststellt, dass die Ausfuhrgesetze oder deren Änderungen (i) die Erbringung der Dienstleistungen unmöglich oder wirtschaftlich undurchführbar machen oder machen werden oder (ii) wenn die Gefahr besteht, dass für die Erbringung der Dienstleistungen oder die Erfüllung der Verpflichtungen aus der Dienstleistungsvereinbarung Sanktionen verhängt werden könnten.',
    en: '12.3&nbsp;&nbsp;Either Party may terminate the Service Agreement with immediate effect and without liability to the other Party, if the terminating Party determines at its reasonable discretion that Export Laws or changes thereto (i) make/will make the provision of the Services impossible or economically unfeasible or (ii) if there is a risk that sanctions could be imposed for the provision of Services or for the fulfillment of obligations under the Service Agreement.'
  },
  service_scope_12_4_content: {
    de: '12.4&nbsp&nbsp;Die Beendigung des Dienstleistungsvertrags berührt nicht die Rechte, Verpflichtungen oder Verbindlichkeiten einer der Vertragsparteien, die vor dem Zeitpunkt des Inkrafttretens der Beendigung entstanden oder aufgelaufen sind.',
    en: '12.4&nbsp&nbsp;Any termination of the Service Agreement shall be without prejudice to any rights, obligations or liabilities of either Party generated or accrued prior to the effective date of termination.'
  },
  service_scope_13: {
    de: '13 Haftung',
    en: '13 Limitation of Liability'
  },
  service_scope_13_1_content: {
    de: '13.1&nbsp;&nbsp;Die Haftung von Henkel ist auf den typischen und vorhersehbaren Schaden begrenzt, vorausgesetzt, es liegt kein Vorsatz seitens Henkel vor. Die Gesamthaftung von Henkel gegenüber dem Kunden, unabhängig davon, ob sie auf einem Vertrag, einer unerlaubten Handlung oder einer sonstigen Handlung beruht, die sich aus oder in Verbindung mit einem Servicevertrag ergibt, ist auf den Betrag der gesamten an Henkel während der vorangegangenen 12 (zwölf) Kalendermonate gezahlten Gebühren beschränkt.',
    en: '13.1&nbsp;&nbsp;Henkel’s liability is limited to the typical and foreseeable damages, provided there was no intent on the part of Henkel. Henkel’s total aggregate liability towards Customer, whether based on contract, tort or otherwise, arising out of or in connection with any Service Agreement, shall be limited to an amount of total Fees paid to Henkel during preceding 12 (twelve) calendar months.'
  },
  service_scope_13_2_content: {
    de: '13.2&nbsp;&nbsp;Die Haftung von Henkel für indirekte, zufällige, besondere oder Folgeschäden, z.B. Produktions- und/oder Gewinnverluste, ist ausgeschlossen.',
    en: '13.2&nbsp;&nbsp;Henkel’s liability for indirect, incidental, special, or consequential loss or damage, e.g., loss of production and/or profits, shall be excluded.'
  },
  service_scope_13_3_content: {
    de: '13.3&nbsp;&nbsp;Für den Verlust von Analysedaten haftet Henkel nur bis zur Höhe der typischen Wiederherstellungskosten, die bei ordnungsgemäßer und regelmäßiger Datensicherung durch den Kunden angefallen wären.',
    en: '13.3&nbsp;&nbsp;Henkel shall be liable for loss of Analyzed Data only up to the amount of typical recovery costs which would have arisen had Customer taken proper and regular data backup measures.'
  },
  service_scope_13_4_content: {
    de: '13.4&nbsp;&nbsp;Alle Ansprüche gegen Henkel müssen spätestens ein (1) Jahr nach Entstehen des jeweiligen Anspruchs geltend gemacht werden, danach verjähren alle Ansprüche gegen Henkel.',
    en: '13.4&nbsp;&nbsp;Any claims against Henkel shall be brought no later than one (1) year after the respective claim arose, after which all claims against Henkel shall be barred.'
  },
  service_scope_13_5_content: {
    de: '13.5&nbsp;&nbsp;Die Haftungsbeschränkungen nach diesem Abschnitt 13 gelten nicht im Falle von Körperverletzungen oder Tod, vorsätzlichem Fehlverhalten oder zwingendem Produkthaftungsrecht.',
    en: '13.5&nbsp;&nbsp;The limitations of liability under this Section 13 do not apply in the event of bodily injuries or death, willful misconduct or mandatory product liability law.'
  },
  service_scope_13_6_content: {
    de: '13.6&nbsp;&nbsp;Der Umfang, in dem die Haftung von Henkel ausgeschlossen oder beschränkt ist, gilt auch zugunsten seiner Mitarbeiter, Geschäftsführer, Subunternehmer und sonstiger Personen, die von Henkel bei der Erbringung der Leistungen eingesetzt werden, wenn sie vom Kunden direkt in Anspruch genommen werden.',
    en: '13.6&nbsp;&nbsp;The extent to which liability of Henkel is excluded or limited also applies for the benefit of its employees, managing directors, sub-contractors and any other persons used by Henkel in performing any of the Services if claims are brought against them directly by Customer.'
  },
  service_scope_14: {
    de: '14 Freistellung',
    en: '14 Indemnification'
  },
  service_scope_14_1_content: {
    de: '14.1&nbsp;&nbsp;Der Kunde stellt Henkel und Henkel sowie deren Geschäftsführer, leitende Angestellte, verbundene Unternehmen, Auftragnehmer und Subunternehmer von allen Klagen, Ansprüchen, Kosten, Verbindlichkeiten und Schäden frei, die von verbundenen Unternehmen des Kunden oder Dritten erhoben werden ("<b>Ansprüche</b>") und die resultieren aus (i) einer Verletzung der Servicevereinbarung durch den Kunden, seiner Nutzer, seiner verbundenen Unternehmen, Wartungsdienstleister oder deren Mitarbeiter, (ii) einer Verletzung von Rechten Dritter durch den Kunden, seiner Nutzer, seiner verbundenen Unternehmen, Wartungsdienstleister oder deren Mitarbeiter, (iii) einer Verletzung/einem Verstoß gegen geltende Gesetze/Regelungen/sonstige Vorschriften durch den Kunden, seiner Nutzer, seiner verbundenen Unternehmen, Wartungsdienstleister oder deren Mitarbeiter, (iv) einer Verletzung/einem Verstoß der Wartungsdienstleister oder deren Mitarbeiter, (iii) die Nichteinhaltung geltender Gesetze/Verordnungen/Anordnungen durch den Kunden, seiner Nutzer, seiner verbundenen Unternehmen, Wartungsdienstleister oder deren Mitarbeiter, (iv) die Nichteinhaltung bzw. das Fehlen einer behördlichen Genehmigung, die für die Erbringung von Dienstleistungen in Bezug auf die Industrieanlagen des Kunden erforderlich ist, oder (v) die Bereitstellung von Rohdaten oder anderen Daten oder Informationen des Kunden durch den Kunden, die Einräumung von Rechten oder die Nutzung solcher Rohdaten oder anderer Daten oder Informationen des Kunden.',
    en: '14.1&nbsp;&nbsp;Customer shall indemnify and hold harmless Henkel and Henkel and their managing directors, executive employees, Affiliates, contractors, sub-contractors from any and all lawsuits, claims, costs, liabilities and damages raised by Affiliates of Customer or third parties (“<b>Claims</b>”) that result from or are related to (i) any breach of the Service Agreement by Customer, its Users, its Affiliates, Maintenance Service Providers, or their employees, (ii) any beach of third party rights by Customer, its Users, its Affiliates, Maintenance Service Providers, or their employees, (iii) any non-compliance with applicable laws/regulations/orders by Customer, its Users, its Affiliates, Maintenance Service Providers, or their employees, (iv) any breach/lack of an official permit required for the provision of Services with respect to Customer’s Industrial Assets, or (v) the provision of Raw Data or other Customer data or information by Customer, the granting of rights or the use of such Raw Data or other Customer data or information, respectively.'
  },
  service_scope_14_2_content: {
    de: '14.2&nbsp;&nbsp;Wird einer der vorgenannten Ansprüche geltend gemacht, wird Henkel den Kunden hiervon unterrichten. Die Unterlassung der unverzüglichen Mitteilung entbindet den Kunden nicht von seinen Verpflichtungen, es sei denn, der Verzug von Henkel führt zu Beeinträchtigungen und Schäden beim Kunden. Der Kunde ist nicht berechtigt, Ansprüche ohne die vorherige schriftliche Zustimmung von Henkel zu verrechnen.',
    en: '14.2&nbsp;&nbsp;If Claims are made against any of the above, Henkel shall notify Customer of any such Claim. Failure to provide such notification without undue delay does not release Customer from its obligations, unless and only to the extent Henkel’s delay causes impair and damage for Customer. Customer may not settle Claims without Henkel’s prior written consent.'
  },
  service_scope_14_3_content: {
    de: '14.3&nbsp;&nbsp;Die Verpflichtung nach Abschnitt 14.1 gilt nicht, wenn der Kunde den Verstoß oder die Nichteinhaltung nicht zu vertreten hat.',
    en: '14.3&nbsp;&nbsp;The obligation under Section 14.1 does not apply if Customer is not responsible for the breach or the non-compliance.'
  },
  service_scope_15: {
    de: '15 Versicherung',
    en: '15 Insurance'
  },
  service_scope_15_1_content: {
    de: '15.1&nbsp;&nbsp;Während der Laufzeit des Dienstleistungsvertrags wird der Kunde eine allgemeine Haftpflichtversicherung (einschließlich Produkthaftung), eine umfassende Produkthaftpflichtversicherung und eine Umwelthaftpflichtversicherung zur Deckung von Personen-, Sach- und Vermögensschäden sowie eine Umweltschadenversicherung unterhalten. Die kombinierte Deckungssumme für diese Versicherungen beträgt mindestens 10.000.000 EUR pro Schadensereignis im Jahr. Der Geltungsbereich der Versicherung ist weltweit.',
    en: '15.1&nbsp;&nbsp;During the term of the Service Agreement, Customer will maintain a general liability insurance (including products liability), broad form of product liability insurance and an environmental liability insurance to insure bodily injury, property damage and financial losses as well as an environmental damage insurance. The combined limit for these insurances shall be no less than EUR 10,000,000 per occurrence in the annual aggregate. The policy area shall be worldwide.'
  },
  service_scope_15_2_content: {
    de: '15.2&nbsp;&nbsp;Der Kunde wird Henkel zu gegebener Zeit eine Versicherungsbescheinigung vorlegen, die diesen Versicherungsschutz belegt. Diese Versicherung wird während der Laufzeit des Servicevertrags nicht geändert oder gekündigt und bleibt mindestens zwölf (12) Monate nach Kündigung oder Ablauf des Servicevertrags in Kraft.',
    en: '15.2&nbsp;&nbsp;Customer will provide a certificate of insurance to Henkel in due course evidencing such coverage. Such insurance will not be amended or terminated during the term of the Service Agreement and will remain in force at least twelve (12) months after termination or expiration of the Service Agreement.'
  },
  service_scope_16: {
    de: '16 Datenschutz',
    en: '16 Data Protection'
  },
  service_scope_16_1_content: {
    de: '16.1&nbsp;&nbsp;Alle personenbezogenen Daten, die der Kunde Henkel zur Verfügung stellt, werden von Henkel in Übereinstimmung mit den geltenden Datenschutzgesetzen verarbeitet und gespeichert. Der Kunde sichert zu und gewährleistet, dass eine Rechtsgrundlage für die Bereitstellung solcher personenbezogenen Daten vorliegt und wird alle relevanten Zustimmungen einholen, die Henkel in angemessener Weise durch schriftliche Mitteilung an den Kunden einholen kann.',
    en: '16.1&nbsp;&nbsp;Any personal data that is being provided by Customer to Henkel will be processed and stored by Henkel in line with applicable data protection laws. Customer represents and warrants that a legal basis for providing such personal data is in place and will obtain all relevant consents as Henkel may reasonably require through notification in writing to Customer.'
  },
  service_scope_16_2_content: {
    de: '16.2&nbsp;&nbsp;Diese personenbezogenen Daten können unter Einhaltung der geltenden Datenschutzgesetze an verbundene Unternehmen von Henkel und/oder an dritte Dienstleister, die Henkel bei der Erbringung der Dienstleistungen unterstützen, weitergegeben werden.',
    en: '16.2&nbsp;&nbsp;Such personal data may be passed on to any of Henkel’s Affiliates and/or to third-party service providers supporting Henkel in the provision of the Services subject to applicable data protection laws.'
  },
  service_scope_17: {
    de: '17 Außenhandels- und Exportgesetze',
    en: '17	Foreign Trade and Export Laws'
  },
  service_scope_17_1_content: {
    de: '17.1&nbsp;&nbsp;Der Kunde ist verpflichtet, alle anwendbaren nationalen und internationalen Sanktionsvorschriften, Handelsembargos sowie Export- und Reexportkontrollvorschriften einzuhalten, einschließlich, aber nicht beschränkt auf Wirtschaftssanktionen, Handels- und Embargos sowie Exportkontrollgesetze des Sicherheitsrats der Vereinten Nationen, der Vereinigten Staaten, des Vereinigten Königreichs und/oder der Europäischen Union oder eines ihrer Mitgliedstaaten, soweit dies nach dem anwendbaren Blockierungsrecht (insbesondere der EU-Blockierungsverordnung (Verordnung (EG) Nr. 2271/96) in der zuletzt geänderten Fassung und den geltenden Sperrgesetzen der EU-Mitgliedstaaten (zusammenfassend "<b>Ausfuhrgesetze</b>").',
    en: '17.1&nbsp;&nbsp;Customer shall comply with all applicable national and international sanctions regulations, trade embargoes, and export and re-export control regulations including, but not limited to, economic sanctions, trade and embargoes and export control laws of the United Nations Security Council, the United States, the United Kingdom and/or the European Union or any of its Member States, to the extent this is compliant under applicable blocking law (in particular the EU Blocking Regulation (Council Regulation (EC) No. 2271/96), as last amended, and applicable blocking legislation of EU Member States) (collectively “<b>Export Laws</b>”).'
  },
  service_scope_17_2_content: {
    de: '17.2&nbsp;&nbsp;Soweit Exportkontrollprüfungen erforderlich sind, wird der Kunde Henkel auf Anfrage unverzüglich alle Informationen über den jeweiligen Endkunden, den Bestimmungsort und die beabsichtigte Erbringung der Leistungen sowie über bestehende Exportkontrollbeschränkungen zur Verfügung stellen.',
    en: '17.2&nbsp;&nbsp;If required to conduct export control checks, Customer, upon request by Henkel, shall promptly provide Henkel with all information pertaining to any particular end customer, the destination and the intended provision of Services, as well as any export control restrictions existing.'
  },
  service_scope_17_3_content: {
    de: '17.3&nbsp;&nbsp;Die Verpflichtung von Henkel zur Erfüllung des Vertrages steht unter dem Vorbehalt, dass die Erfüllung nicht durch Exportgesetze verhindert wird.',
    en: '17.3&nbsp;&nbsp;Henkel’s obligation to fulfil the contract is conditional upon the fulfilment not being prevented by Export Laws.'
  },
  service_scope_18: {
    de: '18 Änderung der Bedingungen',
    en: '18	Change of Terms'
  },
  service_scope_18_1_content: {
    de: '18.1&nbsp;&nbsp;Henkel kann den Servicevertrag nach billigem Ermessen, insbesondere aus rechtlichen oder sicherheitsrelevanten Gründen, ändern. Dies gilt nicht für die Entgelte, die gemäß Ziffer 5.4 geändert werden können. Henkel wird dem Kunden jede Änderung mindestens dreißig (30) Tage vor dem vorgeschlagenen Inkrafttreten mitteilen.',
    en: '18.1&nbsp;&nbsp;Henkel may amend the Service Agreement at its own reasonable discretion, in particular for legal or security reasons. This does not apply to Fees, which may be changed in line with Clause 5.4. Henkel will notify Customer of any amendment at least thirty (30) days prior to its proposed entry into force.'
  },
  service_scope_18_2_content: {
    de: '18.2&nbsp;&nbsp;Die Änderungen werden mit Ablauf der oben genannten Mitteilungsfrist verbindlich, es sei denn, der Kunde kündigt den Dienstleistungsvertrag schriftlich vor und mit Wirkung zum Ablauf der Mitteilungsfrist, wobei der Kunde nur dann zu einer solchen Kündigung berechtigt ist, wenn die Fortführung des Dienstleistungsvertrags zu den geänderten Bedingungen unzumutbar wäre.',
    en: '18.2&nbsp;&nbsp;The amendments shall become binding upon expiry of the above notification period, unless Customer terminates the Service Agreement in writing prior to and with effect upon the expiry of the notification period, provided that Customer shall only be entitled to such termination if the continuance of the Service Agreement with the amended terms would be unreasonable.'
  },
  service_scope_19: {
    de: '19 Rechtswahl und Gerichtsstand',
    en: '19	Choice of Law and Jurisdiction'
  },
  service_scope_19_1_content: {
    de: '19.1&nbsp;&nbsp;Der Servicevertrag unterliegt den Gesetzen des Landes, in dem Henkel seinen Hauptsitz hat, ohne Berücksichtigung kollisionsrechtlicher Bestimmungen oder Grundsätze.',
    en: '19.1&nbsp;&nbsp;The Service Agreement shall be governed by and construed in accordance with the laws of that country in which Henkel has its principal place of business without regard to any conflict of laws provisions or principles.'
  },
  service_scope_19_2_content: {
    de: '19.2&nbsp;&nbsp;Im Falle von Streitigkeiten, die sich zwischen den Parteien aus oder im Zusammenhang mit dem Dienstleistungsvertrag oder einer Verletzung desselben ergeben können ("<b>Streitigkeiten</b>"), bemühen sich die Parteien um eine gütliche Lösung.',
    en: '19.2&nbsp;&nbsp;In case of any dispute which may arise between Parties out of or in connection with the Service Agreement or a breach thereof (“<b>Dispute</b>”), the Parties shall try to find an amicable solution.'
  },
  service_scope_19_3_content: {
    de: '19.3&nbsp;&nbsp;Können die Parteien keine gütliche Einigung erzielen, so wird die Streitigkeit nach der Vergleichs- und Schiedsgerichtsordnung der Internationalen Handelskammer von drei gemäß dieser Ordnung ernannten Schiedsrichtern endgültig entschieden (unter Ausschluss der Bestimmungen über das beschleunigte Verfahren). Schiedsgerichtsstand und ausschließlicher Gerichtsstand für alle Streitigkeiten ist die Hauptstadt des Landes, in dem Henkel seine Hauptniederlassung hat. Die Sprache des Schiedsgerichtsverfahrens ist Englisch. Der Schiedsspruch bedarf der Schriftform und muss die Gründe und Tatsachen, die zu dieser Entscheidung geführt haben, angeben. Die Parteien verzichten auf die Einlegung eines Rechtsmittels.',
    en: '19.3&nbsp;&nbsp;In case the Parties cannot reach an amicable settlement, the dispute shall be finally settled under the Rules of Conciliation and Arbitration of the International Chamber of Commerce by three arbitrators appointed in accordance with the said Rules (excluding the expedited procedure provisions). Place of Arbitration and exclusive place of jurisdiction for any and all Disputes shall be the capital of that country in which Henkel has its principal place of business. The language of Arbitration shall be English. The award shall be in writing and specify the reasons for and the facts on which such decision was reached. The Parties hereby waive their right to any form of appeal.'
  },
  service_scope_20: {
    de: '20 Sonstiges',
    en: '20	Miscellaneous'
  },
  service_scope_20_1_content: {
    de: '20.1&nbsp;&nbsp;Die Dienstleistungsvereinbarung stellt die vollständige und abschließende Erklärung der Vereinbarung zwischen den Parteien in Bezug auf den Gegenstand dieser Vereinbarung dar und ersetzt alle früheren oder gleichzeitigen Vereinbarungen, Absprachen oder Mitteilungen, ob schriftlich oder mündlich, in Bezug auf diesen Gegenstand.',
    en: '20.1&nbsp;&nbsp;The Service Agreement constitutes the full and complete statement of the agreement between the Parties with respect to the subject matter hereof and supersedes any previous or contemporaneous agreements, understandings or communications, whether written or verbal, relating to such subject matter.'
  },
  service_scope_20_2_content: {
    de: '20.2&nbsp;&nbsp;Dieses Abkommen darf von keiner Vertragspartei ohne die vorherige schriftliche Zustimmung der anderen Vertragspartei ganz oder teilweise abgetreten werden. Die Zustimmung darf nicht unbillig verweigert werden. Ungeachtet des Vorstehenden kann Henkel den Dienstleistungsvertrag oder sein Interesse an dem Dienstleistungsvertrag an (i) seine Verbundenen Unternehmen, (ii) Henkel oder seine Verbundenen Unternehmen oder (iii) an einen Dritten abtreten, wenn Henkel sein gesamtes oder im Wesentlichen gesamtes Geschäft im Zusammenhang mit den Dienstleistungen an diesen Dritten verkauft oder anderweitig überträgt.',
    en: '20.2&nbsp;&nbsp;This Agreement shall not be assigned in whole or in part by either Party without the prior written consent of the other Party. The consent may not unreasonably be withheld. Notwithstanding the foregoing, Henkel may assign the Service Agreement or its interest in the Service Agreement to (i) its Affiliates (ii) Henkel or its Affiliates, or (iii) to a third party if Henkel sells or otherwise transfers all or substantially all of its business concerning the Services to this third party.'
  },
  service_scope_20_3_content: {
    de: '20.3&nbsp;&nbsp;Soweit die Servicebedingungen die Schriftform vorsehen, genügt die E-Mail zur Erfüllung dieses Erfordernisses. Der Empfang einer E-Mail durch eine Partei gilt als erfolgt, wenn die E-Mail der anderen Partei an die von der jeweiligen Partei angegebene E-Mail-Adresse gesendet wird (z. B. für den Kunden an die vom Kunden im Konto angegebene aktuelle E-Mail-Adresse).',
    en: '20.3&nbsp;&nbsp;To the extent that the Service Conditions require written form, email shall be sufficient to fulfill this requirement. Receipt of an email by a Party shall be deemed to have occurred when the email from the other Party is sent to the email address designated by the respective Party (e.g., for Customer, the then-current email address provided by Customer in the Account).'
  },
  service_scope_20_4_content: {
    de: '20.4&nbsp;&nbsp;Verfügt die App über die erforderlichen Funktionalitäten, können die Erklärungen der Parteien über die App übermittelt werden und gelten nach erfolgreicher Übermittlung als ordnungsgemäß abgegeben.',
    en: '20.4&nbsp;&nbsp;Should the App encompass the requisite functionalities, declarations by the Parties may be conveyed through the App and shall be deemed duly delivered upon successful transmission.'
  },
  service_scope_20_5_content: {
    de: '20.5&nbsp;&nbsp;Das Versäumnis einer der Vertragsparteien, eine der Bestimmungen der Dienstleistungsvereinbarung zu irgendeinem Zeitpunkt durchzusetzen, ist in keiner Weise als Verzicht auf diese Bestimmung auszulegen und beeinträchtigt in keiner Weise die Gültigkeit der Dienstleistungsvereinbarung oder das Recht der anderen Vertragspartei, jede einzelne Bestimmung später durchzusetzen.',
    en: '20.5&nbsp;&nbsp;The failure of either Party to enforce at any time any of the provisions of the Service Agreement will in no way be construed to be a waiver of such provision, nor in any way affect the validity of the Service Agreement or the right of the other Party thereafter to enforce each and every provision.'
  },
  service_scope_20_6_content: {
    de: '20.6&nbsp;&nbsp;Die Dienstleistungsbedingungen beinhalten keine Verlagerung der Beweislast.',
    en: '20.6&nbsp;&nbsp;The Service Conditions do not imply any shift in burden of proof.'
  },
  service_scope_20_7_content: {
    de: '20.7&nbsp;&nbsp;Sollten einzelne Bestimmungen der Servicebedingungen unwirksam oder undurchführbar sein oder werden, so berührt dies die Wirksamkeit oder Durchführbarkeit der Servicebedingungen als Ganzes nicht, es sei denn, die unwirksame oder undurchführbare Bestimmung ist für die Fortführung des Servicevertrages für eine der Parteien von wesentlicher Bedeutung. Unwirksame oder undurchsetzbare Bestimmungen sind durch solche rechtswirksamen und durchsetzbaren Bestimmungen zu ersetzen, die dem wirtschaftlichen Sinn der unwirksamen oder undurchsetzbaren Bestimmungen am ehesten entsprechen.',
    en: '20.7&nbsp;&nbsp;Should any individual provision of the Service Conditions be or become invalid or unenforceable, such invalidity or unenforceability shall not affect the validity or enforceability of the Service Conditions as such, unless the invalid or unenforceable provision is of vital importance for the continuation of the Service Agreement for either Party. Invalid or unenforceable provisions shall be replaced by such legally valid and enforceable provisions, which fairly correspond to the economic spirit of the invalid or unenforceable provisions.'
  },
  service_scope_20_8_content: {
    de: '20.8&nbsp;&nbsp;Henkel ist und bleibt bei der Erbringung der Dienstleistungen in jeder Hinsicht ein unabhängiger Dienstleister. Weder Henkel noch seine Mitarbeiter gelten als Vertreter oder Mitarbeiter des Kunden.',
    en: '20.8&nbsp;&nbsp;Henkel shall in all respect be and remain an independent service supplier in the performance of Services. Neither Henkel nor its employees shall be deemed to be agents or employees of Customer.'
  },
  service_scope_annex_1: {
    de: 'ANHANG 1',
    en: 'ANNEX 1'
  },
  service_scope_specific_terms: {
    de: 'Besondere Bedingungen',
    en: 'Specific Terms'
  },
  service_scope_loctite: {
    de: 'Der LOCTITE Pulse® Service besteht aus',
    en: 'The LOCTITE Pulse® Services consists of'
  },
  service_scope_loctite_1: {
    de: '•&nbsp&nbsp;Sammlung und Analyse von technischen Kundendaten zu den vom Endkunden ausgewählten Industrieanlagen ("Industrieanlage")',
    en: '•&nbsp&nbsp;Collection and analysis of technical customer data on industrial assets selected by end customer (“Industrial Asset”)'
  },
  service_scope_loctite_2: {
    de: '•&nbsp&nbsp;Karten- und Tabellenübersicht über die vom Kunden ausgewählten und bereitgestellten Standorte für Industrieanlagen und Sensorinstallationen',
    en: '•&nbsp;&nbsp;Map & Table overview of Industrial Assets and sensor installation locations as selected and provided by customer'
  },
  service_scope_loctite_3: {
    de: '•&nbsp&nbsp;Visualisierung der analysierten Daten in der LOCTITE Pulse® App: Anzeige der überwachten und analysierten Daten pro Industrieanlage',
    en: '•&nbsp&nbsp;Visualization of analyzed data in the LOCTITE Pulse® App: display of monitored and analyzed data per Industrial Asset'
  },
  service_scope_loctite_4: {
    de: '•&nbsp&nbsp;Speicherung der analysierten Daten in der LOCTITE Pulse® App',
    en: '•&nbsp&nbsp;Storage of analyzed data in the LOCTITE Pulse® App'
  },
  service_scope_loctite_5: {
    de: '•&nbsp&nbsp;Warnfunktion bei festgestellten Unregelmäßigkeiten:',
    en: '•&nbsp&nbsp;Alert function about irregularities detected:'
  },
  service_scope_loctite_5_1:{
    de: '(1)&nbsp;&nbsp;In-App-Benachrichtigungen als Standard,',
    en: '(1)&nbsp;&nbsp;In-App Notifications per default,'
  },
  service_scope_loctite_5_1_content:{
    de: '&nbsp;&nbsp;&nbsp;Folgende weitere Optionen können vom Kunden individuell ausgewählt werden:',
    en: '&nbsp;&nbsp;&nbsp;Options to be individually selected by customer:'
  },
  service_scope_loctite_5_2: {
    de: '(2)&nbsp;&nbsp;Push-Benachrichtigungen (verfügbar für ANDROID und iOS ab Version 16.4) und/oder',
    en: '(2)&nbsp;&nbsp;Push-Notifications (available for ANDROID and iOS as of Version 16.4) and/or '
  },
  service_scope_loctite_5_3: {
    de: '(3)&nbsp;&nbsp;E-Mail-Benachrichtigungen',
    en: '(3)&nbsp;&nbsp;Email-Notifications  '
  },
  service_scope_loctite_6: {
    de: '•&nbsp&nbsp;Benutzerverwaltung',
    en: '•&nbsp&nbsp;User Administration'
  },
  service_scope_loctite_7: {
    de: '•&nbsp&nbsp;Bereitstellung von Bedienungsanleitungen zum Download auf der LOCTITE Pulse® App.',
    en: '•&nbsp&nbsp;Provision of Operating Manuals for download on LOCTITE Pulse® App.'
  },
  service_scope_loctite_content: {
    de: 'Es liegt in der alleinigen Verantwortung des Kunden, geeignete Maßnahmen zu ergreifen und auf Informationen zu reagieren, die durch (1) In-App-LOCTITE-Puls-Benachrichtigung, (2) Push-Benachrichtigungen (sofern technisch verfügbar) oder (3) E-Mail oder in beliebiger Form in Bezug auf Rohdaten und/oder Analysierte Daten bereitgestellt werden, einschließlich, aber nicht beschränkt auf potenzielle Benachrichtigungen, die der Kunde in Bezug auf den Status der ausgewählten Industrieanlage erhalten kann (gemeinsam "Benachrichtigungen"). Es liegt daher im alleinigen Ermessen und in der Verantwortung des Kunden zu bestimmen, ob, welche und wann sich aus einer solchen Benachrichtigung Konsequenzen/Aktionen ergeben. Darüber hinaus liegt es in der alleinigen Verantwortung des Kunden, die Art und Anzahl der Benachrichtigungen aktiv auszuwählen, wobei er verstanden hat und sich einverstanden erklärt, dass die Erreichbarkeit im Falle eines Notfalls umso höher ist, je mehr Benachrichtigungen aktiviert werden.',
    en: 'It is customer’s sole responsibility to take appropriate action and respond to information provided through (1) In App-LOCTITE Pulse Notification, (2) Push Notifications (if technically available) or (3) email, or in whatever form regarding raw and/or analyzed data, including but not limited to potential notifications customer may receive regarding the status of the selected Industrial Asset (jointly “Notifications”). Hence, it is at customer’s sole discretion and responsibility to determine whether, what, and when consequences/actions will result from any such Notification. In addition, it is customer’s sole responsibility to actively select the type and number of notifications, having understood that the more notifications are being activated, the higher the accessibility in the event of an emergency case.'
  },
  service_scope_annex: {
    de: 'ANHANG',
    en: 'ANNEX'
  },
  service_scope_annex_list: {
    de: 'Liste der Henkel-Tochtergesellschaften, die lokale Dienstleistungen anbieten',
    en: 'List of Henkel Affiliates that provide local Services'
  },
  service_scope_annex_list_1: {
    de: '•&nbsp;&nbsp;Henkel Belgium N.V., Esplanada 1, 1020 Brüssel',
    en: '•&nbsp;&nbsp;Henkel Belgium N.V., Esplanada 1, 1020 Brussels'
  },
  service_scope_annex_list_2: {
    de: '•&nbsp;&nbsp;Henkel Italia srl.via C. Amoretti 78, 20157 Mailand',
    en: '•&nbsp;&nbsp;Henkel Italia srl.via C. Amoretti 78, 20157 Milan'
  },
  service_scope_annex_list_3: {
    de: '•&nbsp;&nbsp;Henkel Iberica, Bilbao 72-84, 08005 Barcelona',
    en: '•&nbsp;&nbsp;Henkel Iberica, Bilbao 72-84, 08005 Barcelona'
  },
  service_scope_annex_list_4: {
    de: '•&nbsp;&nbsp;Henkel Ltd. Wood Lane End, Hemel Hempstead, Herts, HP24RQ',
    en: '•&nbsp;&nbsp;Henkel Ltd. Wood Lane End, Hemel Hempstead, Herts, HP24RQ'
  },
  service_scope_annex_list_5: {
    de: '•&nbsp;&nbsp;Henkel Norden AB, Gustavslundsvägen 151 A, 167 15 Bromma, Schweden',
    en: '•&nbsp;&nbsp;Henkel Norden AB, Gustavslundsvägen 151 A, 167 15 Bromma, Sweden'
  },
  service_scope_annex_list_6: {
    de: '•&nbsp;&nbsp;Henkel Nederland B.V., Brugwal 11, 3432, Niewegeln, Niederlande',
    en: '•&nbsp;&nbsp;Henkel Nederland B.V., Brugwal 11, 3432, Niewegeln, Netherlands'
  },
  service_scope_annex_list_7: {
    de: '•&nbsp;&nbsp;Henkel Technologies France S.A.S., 299, Rue de Grange Morin,69655 Villefranche sur Saone, Frankreich',
    en: '•&nbsp;&nbsp;Henkel Technologies France S.A.S., 299, Rue de Grange Morin,69655 Villefranche sur Saone, France'
  },
  //End of Service Conditions doc

  terms_of_use_title: {
    de: 'Nutzungsbedingungen der LOCTITE Pulse App',
    en: 'Terms of Use of LOCTITE Pulse App',
  },
  terms_introduction: {
    de: 'Einleitung',
    en: 'Introduction',
  },
  terms_introduction_content: {
    de: 'Diese LOCTITE Pulse App „Progressive Web App“ (PWA) (nachfolgend „LOCTITE Pulse App genannt“) wird Ihnen von der Henkel AG & Co. KGaA, Henkelstraße 67, 40589 Düsseldorf, gegebenenfalls als Dienstleister für die lokale Henkel-Gesellschaften, die den Service Vertrag zu Daten-Analyse Services zur Überwachung von Industrieanlagen mit Ihnen abgeschlossen hat, bereitgestellt. Henkel AG & Co. KGaA sowie die lokalen Henkel Gesellschaften werden hierin gesamthaft als „HENKEL“ genannt.',
    en: 'This LOCTITE Pulse App "Progressive Web App" (PWA) (hereinafter referred to as "LOCTITE Pulse App") is provided to you by Henkel AG & Co. KGaA itself, or if applicable, as a service provider for the local Henkel affiliates that has entered into a service agreement with you for data analytics services regarding monitoring industrial facilities (“Henkel Affiliate”). Henkel AG & Co. KGaA and Henkel Affiliates are hereinafter jointly referred to as “HENKEL”.',
  },
  terms_introduction_content_us: {
    de: 'Diese LOCTITE Pulse App „Progressive Web App“ (PWA) (nachfolgend „LOCTITE Pulse App genannt“) wird Ihnen von der Henkel Corporation, Henkelstraße 67, 40589 Düsseldorf, gegebenenfalls als Dienstleister für die lokale Henkel-Gesellschaften, die den Service Vertrag zu Daten-Analyse Services zur Überwachung von Industrieanlagen mit Ihnen abgeschlossen hat, bereitgestellt. Henkel Corporation sowie die lokalen Henkel Gesellschaften werden hierin gesamthaft als „HENKEL“ genannt.',
    en: 'This LOCTITE Pulse App "Progressive Web App" (PWA) (hereinafter referred to as "LOCTITE Pulse App") is provided to you by Henkel Corporation itself, or if applicable, as a service provider for the local Henkel affiliates that has entered into a service agreement with you for data analytics services regarding monitoring industrial facilities (“Henkel Affiliate”). Henkel Corporation and Henkel Affiliates are hereinafter jointly referred to as “HENKEL”.',
  },
  terms_introduction_content1: {
    de: 'Für den durch die jeweilige Henkel-Gesellschaft bereitgestellten Daten-Analyse-Service einschließlich des Erwerbs und Bereitstellung der erforderlichen IoT-Hardware gelten ergänzend zu diesen Nutzungsbedingungen die individuellen vertraglichen Konditionen zwischen Ihnen und der lokalen Henkel Gesellschaft als Ihr Vertragspartner.',
    en: 'For the data analysis service provided by the respective Henkel Affiliate, including the purchase and provision of the required IoT hardware, the individual contractual terms and conditions between you and the local Henkel Affiliate as your contractual partner shall apply in addition to these Terms of Use.',
  },
  terms_introduction_content2: {
    de: 'Die Informationen, die über diese LOCTITE Pulse App über die Henkel AG & Co. KGaA und ihre Tochterunternehmen sowie über Dritte erhältlich sind, wurden mit größter Sorgfalt zusammengestellt. Eine Garantie für die Vollständigkeit oder Richtigkeit dieser Informationen können wir jedoch nicht übernehmen. Henkel AG & Co. KGaA übernimmt keine Haftung für Fehler in den Inhalten dieser LOCTITE Pulse App.',
    en: 'The information being made available via this LOCTITE Pulse App from Henkel AG & Co. KGaA, the Henkel Affiliates and third parties has been compiled with the utmost care. However, we cannot warrant the completeness or accuracy of this information. HENKEL AG & Co. KGaA accepts no liability for errors in the content of the LOCTITE Pulse App.',
  },
  terms_introduction_content2_us: {
    de: 'Die Informationen, die über diese LOCTITE Pulse App über die Henkel Corporation und ihre Tochterunternehmen sowie über Dritte erhältlich sind, wurden mit größter Sorgfalt zusammengestellt. Eine Garantie für die Vollständigkeit oder Richtigkeit dieser Informationen können wir jedoch nicht übernehmen. Henkel Corporation übernimmt keine Haftung für Fehler in den Inhalten dieser LOCTITE Pulse App.',
    en: 'The information being made available via this LOCTITE Pulse App from Henkel Corporation, the Henkel Affiliates and third parties has been compiled with the utmost care. However, we cannot warrant the completeness or accuracy of this information. Henkel Corporation accepts no liability for errors in the content of the LOCTITE Pulse App.',
  },
  terms_introduction_content3: {
    de: 'Mit der Registrierung auf dieser Website akzeptieren Sie ohne Einschränkung oder Vorbehalt die folgenden Allgemeinen Nutzungsbedingungen der LOCTITE Pulse App, die Sie einsehen oder ausdrucken können.',
    en: 'By registering on this website, you accept, without limitation or reservation, the following Terms and Conditions of Use of the LOCTITE Pulse App, which you may view or print.',
  },
  terms_introduction_content4: {
    de: 'DIESE APP UND DIE HIERIN ENTHALTENEN INFORMATIONEN SIND NICHT FÜR DIE NUTZUNG INNERHALB DER VEREINIGTEN STAATEN VON AMERIKA ODER FÜR U.S. STAATSBÜRGER ODER ANSÄSSIGE PERSONEN BESTIMMT ODER ZUGELASSEN. DIESE PERSONEN WERDEN GEBETEN, SICH AN DIE LOKALE LOCTITE PULSE APP ODER AN DIE WEBSITES DER MIT UNS VERBUNDENEN UNTERNEHMEN VON HENKEL ZU WENDEN.',
    en: 'THIS APP AND THE INFORMATION CONTAINED HEREIN ARE NOT INTENDED OR AUTHORIZED FOR USE WITHIN THE UNITED STATES OF AMERICA OR BY U.S. CITIZENS OR RESIDENTS. SUCH PERSONS ARE ENCOURAGED TO REFER TO THE LOCAL LOCTITE PULSE APP OR TO THE WEBSITES OF OUR HENKEL CORPORATION.',
  },

  via_loctite_pulse_app: {
    de: 'Über diese LOCTITE Pulse App',
    en: 'About this LOCTITE Pulse App',
  },
  loctite_pulse_content: {
    de: 'Die LOCTITE Pulse App ist eine mobile Anwendung, die die in den von Ihnen ausgewählten industriellen Anlagen mittels Sensoren gemessenen technischen Daten visualisiert. Darüber hinaus bietet die LOCTITE Pulse App Unterstützung bei der Verknüpfung der Sensoren mit der LOCTITE Pulse App, sowie die Möglichkeit, bei festgestellten Anomalien, über die LOCTITE Pulse App direkt (In-App-Benachrichtigungen) und auf Wunsch zusätzlich über Push-Nachrichten (sofern technisch verfügbar) und/oder per Email benachrichtigt zu werden.',
    en: 'The LOCTITE Pulse App is a mobile application that visualizes the technical data measured by sensors in your selected industrial equipment. In addition, the LOCTITE Pulse App provides support for linking the sensors to the LOCTITE Pulse App, as well as the possibility to be notified of detected anomalies, via the LOCTITE Pulse App directly (in-app notifications) and additionally via push messages (if technically available) and/or email, if desired.',
  },
  loctite_pulse_function_title: {
    de: 'Die Funktionen:',
    en: 'Features:',
  },
  map_table_overview: {
    de: 'Karten- und Tabellenübersicht',
    en: 'Map and table overview',
  },
  map_table_overview_content: {
    de: ' – Überblick über die von Ihnen ausgewählten Industrieanlagen und Sensorinstallationsorte',
    en: ' – overview of the industrial plants and sensor installation locations you have selected',
  },
  data_display: {
    de: 'Daten-Anzeige',
    en: 'Data display',
  },
  data_display_content: {
    de: ' – Anzeige einer Auswahl der auf der ausgewählten Anlage gemessenen Rohdaten -und – je nach technischer Funktionalität des eingesetzten Sensors - analysierten Daten betreffend den Zustand der überwachten Industrieanlage',
    en: ' – display of selection of raw data measured on the selected installation -and - depending on the technical functionality of the sensor used - analyzed data concerning the condition of the monitored industrial installation',
  },
  maintenance_documentation: {
    de: 'Wartungsdokumentation',
    en: 'Maintenance documentation',
  },
  maintenance_documentation_content: {
    de: ' – Sie können die Wartungsdokumentation in Text und Bild hochladen und der LOCTITE Pulse App hinzufügen',
    en: ' – you can upload the maintenance documentation in text and image and add it to the LOCTITE Pulse App',
  },
  loctite_pulse_notifications: {
    de: 'In-LOCTITE Pulse App-Benachrichtigungen',
    en: 'In-LOCTITE Pulse App notifications',
  },
  loctite_pulse_notifications_content: {
    de: ' – automatische Ad-hoc-Benachrichtigungen in der LOCTITE Pulse App, die über die an der ausgewählten Industrieanlage gemessenen Daten Auskunft informiert',
    en: ' – automatic ad-hoc notifications in the LOCTITE Pulse app, informing about the data measured at the selected industrial plant',
  },
  push_notifications: {
    de: 'Push-Benachrichtigungen',
    en: 'Push notifications',
  },
  push_notifications_content: {
    de: ' – (für Android und IOS ab Version 16.4 verfügbar): sofern Sie in diese Art der zusätzlichen Benachrichtigung eingewilligt haben: automatische und Ad-hoc-Push-Benachrichtigungen, die über die an der ausgewählten Industrieanlage gemessenen Daten informieren',
    en: ' – (available for Android and IOS as of Version 16.4)- if you have consented to this type of additional notification: automatic and ad-hoc push notifications informing about the data measured at the selected industrial plant',
  },
  email_notifications: {
    de: 'Email-Benachrichtigungen',
    en: 'Email notifications',
  },
  email_notifications_content: {
    de: ' – sofern Sie in diese Art der zusätzlichen Benachrichtigung eingewilligt haben: Email-Benachrichtigungen an die von Ihnen bereit gestellte Email-Adresse, die über die an der ausgewählten Industrieanlage gemessenen Daten Auskunft gibt',
    en: ' – if you have consented to this type of additional notification: Email notifications sent to the email address you provide, informing about the data measured at the selected industrial installation',
  },
  installation_agent: {
    de: 'Installations-Agent',
    en: 'Installation agent',
  },
  installation_agent_content: {
    de: ' – Bereich der LOCTITE Pulse LOCTITE Pulse App zum Erstellen der technischen Platzstruktur beim Kunden, sowie eine Schritt-für-Schritt Anleitung zum Verbinden der Sensoren mit der LOCTITE Pulse LOCTITE Pulse App, nach initialer Installation oder Austauschen von IoT-Hardware',
    en: " – area of the LOCTITE Pulse LOCTITE Pulse app to create the technical space structure at the customer's site, as well as step-by-step instructions for connecting sensors to the LOCTITE Pulse LOCTITE Pulse app, after initial installation or replacement of IoT hardware",
  },

  general_terms_loctite_pulse_app: {
    de: 'Allgemeine Nutzungsbedingungen dieser LOCTITE Pulse App',
    en: 'General Terms of Use of this LOCTITE Pulse App',
  },
  copyright_title: {
    de: ' 1. Urheberrecht, Nutzungsrecht',
    en: ' 1. Copyright, Right of Use',
  },
  copyright_content: {
    de: 'Der Inhalt dieser LOCTITE Pulse App ist urheberrechtlich geschützt. Insbesondere Vervielfältigungen, Bearbeitungen, Übersetzungen, Einspeicherung und Verarbeitung in anderen Medien, einschließlich der Einspeicherung oder Verarbeitung mit elektronischen Mitteln, sind urheberrechtlich geschützt. Jede Verwertung im Ganzen oder in Teilen bedarf der vorherigen schriftlichen Zustimmung von Henkel. Sowohl der Inhalt als auch die Struktur der LOCTITE Pulse App einschließlich unter anderem der Software, des grafischen Materials, der Karten, der Tabellen, der Texte, der Gestaltung, des Videomaterials, der künstlerischen Darstellung, sind urheberrechtlich geschützt.',
    en: 'The content of this LOCTITE Pulse App is protected by copyright. In particular, reproductions, adaptations, translations, storage and processing in other media, including storage or processing by electronic means, are protected by copyright. Any utilization in whole or in part requires the prior written consent of Henkel. Both the content and the structure of the LOCTITE Pulse App including, among others, the software, graphic material, maps, tables, texts, design, video material, artistic presentation, are protected by copyright.',
  },
  copyright_content1: {
    de: 'Henkel AG & Co. KGaA, bzw. die lokale Henkel Gesellschaft als Ihr Vertragspartner für die Erbringung der Daten-Analyse-Services hat Ihnen im zugrundeliegenden Service Vertrag ein für die Dauer des Bestehens dieses Service-Vertrages ein nicht-exklusives, nicht übertragbares, nicht unterlizensierbares Recht, die LOCTITE Pulse App zu dem dort vereinbarten Zweck der Daten-Analyse und Überwachung Ihrer industriellen Anlagen (nachfolgend „Zweck“) zu nutzen, gewährt.',
    en: 'HENKEL AG & Co. KGaA, respectively the local Henkel Affiliate as your contractual partner for the provision of the Data Analysis Services, has granted you in the underlying Service Agreement a non-exclusive, non-transferable, non-sublicensable right for the duration of the existence of this Service Agreement to use the LOCTITE Pulse App for the purpose of data analysis and monitoring of your industrial facilities (hereinafter "Purpose") as agreed therein.',
  },
  copyright_content1_us: {
    de: 'Henkel Corporation, bzw. die lokale Henkel Gesellschaft als Ihr Vertragspartner für die Erbringung der Daten-Analyse-Services hat Ihnen im zugrundeliegenden Service Vertrag ein für die Dauer des Bestehens dieses Service-Vertrages ein nicht-exklusives, nicht übertragbares, nicht unterlizensierbares Recht, die LOCTITE Pulse App zu dem dort vereinbarten Zweck der Daten-Analyse und Überwachung Ihrer industriellen Anlagen (nachfolgend „Zweck“) zu nutzen, gewährt.',
    en: 'Henkel Corporation, respectively the local Henkel Affiliate as your contractual partner for the provision of the Data Analysis Services, has granted you in the underlying Service Agreement a non-exclusive, non-transferable, non-sublicensable right for the duration of the existence of this Service Agreement to use the LOCTITE Pulse App for the purpose of data analysis and monitoring of your industrial facilities (hereinafter "Purpose") as agreed therein.',
  },
  copyright_content2: {
    de: 'Sofern nicht anderweitig individuell schriftlich vereinbart, bedarf die Nutzung, Weitergabe und/oder Vervielfältigung von Informationen und/oder Daten, insbesondere die Nutzung von technischen Daten, Auswertungen, Analysen, Graphiken, Schaubildern, Texten, Textteilen, Lageplänen oder Bildmaterial der vorherigen schriftlichen Zustimmung von Henkel AG & Co. KGaA. Illustrationen sind urheberrechtlich geschützt. Die Rechte für die Verbreitung und Vervielfältigung liegen bei HENKEL. Die Rechte bleiben auch in vollem Umfang bestehen, wenn Bilder, technische Daten und/oder Analysen automatisch oder manuell in ein Archiv übernommen werden.',
    en: 'Unless otherwise individually agreed in writing, the use, disclosure and/or reproduction of information and/or data, in particular the use of technical data, evaluations, analyses, graphics, charts, texts, parts of texts, site plans or image material requires the prior written consent of Henkel AG & Co. KGaA. Illustrations are protected by copyright. The rights for distribution and reproduction are held by Henkel AG & Co. KGaA. The rights also remain in full force if images, technical data and/or analyses are automatically or manually transferred to an archive.',
  },
  copyright_content2_us: {
    de: 'Sofern nicht anderweitig individuell schriftlich vereinbart, bedarf die Nutzung, Weitergabe und/oder Vervielfältigung von Informationen und/oder Daten, insbesondere die Nutzung von technischen Daten, Auswertungen, Analysen, Graphiken, Schaubildern, Texten, Textteilen, Lageplänen oder Bildmaterial der vorherigen schriftlichen Zustimmung von Henkel Corporation. Illustrationen sind urheberrechtlich geschützt. Die Rechte für die Verbreitung und Vervielfältigung liegen bei Henkel Corporation. Die Rechte bleiben auch in vollem Umfang bestehen, wenn Bilder, technische Daten und/oder Analysen automatisch oder manuell in ein Archiv übernommen werden.',
    en: 'Unless otherwise individually agreed in writing, the use, disclosure and/or reproduction of information and/or data, in particular the use of technical data, evaluations, analyses, graphics, charts, texts, parts of texts, site plans or image material requires the prior written consent of Henkel Corporation. Illustrations are protected by copyright. The rights for distribution and reproduction are held by Henkel Corporation. The rights also remain in full force if images, technical data and/or analyses are automatically or manually transferred to an archive.',
  },
  brands_title: {
    de: ' 2. Marken',
    en: ' 2. Trademarks',
  },
  brands_content: {
    de: 'Das Henkel Oval-Logo, die Marke LOCTITE und LOCTITE Pulse sowie alle auf diesen Seiten verwendeten Produktbezeichnungen und-/oder Produktaufmachungen sind eingetragene Marken der Henkel AG & Co. KGaA, ihrer verbundenen Unternehmen oder Lizenzgeber. Jede ungenehmigte oder missbräuchliche Verwendung dieser Marken ist ausdrücklich untersagt und stellt eine Verletzung des Markenrechts, des Urheberrechts, anderer gewerblicher Schutzrechte oder des Wettbewerbsrechts dar.',
    en: 'The Henkel Oval-Logo, the LOCTITE brand and LOCTITE Pulse as well as all product names and/or product presentations used on these pages are registered trademarks of HENKEL AG & Co. KGaA, its affiliates or licensors. Any unauthorized or improper use of these trademarks is expressly prohibited and constitutes a violation of trademark law, copyright law, other industrial property rights or competition law.',
  },
  brands_content_us: {
    de: 'Das Henkel Oval-Logo, die Marke LOCTITE und LOCTITE Pulse sowie alle auf diesen Seiten verwendeten Produktbezeichnungen und-/oder Produktaufmachungen sind eingetragene Marken der Henkel Corporation, ihrer verbundenen Unternehmen oder Lizenzgeber. Jede ungenehmigte oder missbräuchliche Verwendung dieser Marken ist ausdrücklich untersagt und stellt eine Verletzung des Markenrechts, des Urheberrechts, anderer gewerblicher Schutzrechte oder des Wettbewerbsrechts dar.',
    en: 'The Henkel Oval-Logo, the LOCTITE brand and LOCTITE Pulse as well as all product names and/or product presentations used on these pages are registered trademarks of Henkel Corporation, its affiliates or licensors. Any unauthorized or improper use of these trademarks is expressly prohibited and constitutes a violation of trademark law, copyright law, other industrial property rights or competition law.',
  },
  access_data_title: {
    de: ' 3. Zugangsdaten',
    en: ' 3. Access data',
  },
  access_data_content: {
    de: 'Ihnen ist es untersagt, die Zugangsdaten (z.B. Benutzerkennung, Passwort ö.ä.) zu dieser LOCTITE Pulse App unbefugten Dritten zu überlassen oder diesen anderweitig zugänglich zu machen. Die Leistungen, die Henkel Ihnen über diese LOCTITE Pulse App zur Verfügung stellt, dürfen Sie ausschließlich für den vereinbarten Zweck nutzen. Sollten Sie davon Kenntnis erlangen, dass die Zugangsdaten unbefugten Dritten bekannt geworden sind und/oder dass unbefugte Dritte Ihre Zugangsdaten benutzt haben, sind Sie verpflichtet, uns unverzüglich hierüber in Kenntnis zu setzen.',
    en: 'You are prohibited from providing the access data (e.g. user ID, password, etc.) for this LOCTITE Pulse App to unauthorized third parties or otherwise making them accessible. You may use the services provided to you by HENKEL through this LOCTITE Pulse App solely for the agreed purpose. If you become aware that the access data have become known to unauthorized third parties and/or that unauthorized third parties have used your access data, you are obliged to inform us immediately.',
  },
  disclaimer_third_party_title: {
    de: '4. Haftungsausschluss für Seiten Dritter',
    en: '4. Disclaimer for third party websites',
  },
  disclaimer_third_party_content: {
    de: 'a. Die Seiten dieser LOCTITE Pulse App enthalten auch Verknüpfungen (sog. "Hyperlinks") zu Websites im Internet, die von Dritten gepflegt werden und deren Inhalte HENKEL nicht bekannt sind. HENKEL vermittelt lediglich den Zugang zu diesen Websites und übernimmt keinerlei Verantwortung für deren Inhalte. Unsere Links auf fremde Internetseiten dienen lediglich zur Erleichterung Ihrer Navigation. Wir machen uns die auf verlinkten Seiten dargestellten Aussagen nicht zu eigen, sondern distanzieren uns vielmehr hiermit ausdrücklich von allen Inhalten aller gelinkten Seiten Dritter auf den Seiten unseres Internetauftritts. Insbesondere haften wir nicht für dort begangene Verstöße gegen gesetzliche Bestimmungen und Rechte Dritter.',
    en: 'a.	The pages of this LOCTITE Pulse App also contain links (so-called "hyperlinks") to websites on the Internet that are maintained by third parties and whose contents are not known to HENKEL. HENKEL only provides access to these websites and does not assume any responsibility for their contents. Our links to third party websites are only meant to facilitate your navigation. We do not adopt the statements presented on linked pages as our own, but rather dissociate ourselves explicitly from all contents of all linked pages of third parties on the pages of our Internet presence. In particular, we are not liable for violations of legal provisions and third-party rights committed there.',
  },
  disclaimer_third_party_content1: {
    de: 'b. Die Inhaber der Internetseiten, zu denen über die von HENKEL betriebene LOCTITE Pulse App ein Hyperlink besteht, sind sowohl für deren Inhalt als auch für den Verkauf der dort angebotenen Produkte und die Abwicklung der Bestellung allein verantwortlich.',
    en: 'b. The owners of those websites to which there is a hyperlink via the LOCTITE Pulse App (operated by Henkel AG & Co. KGaA) are solely responsible for their content as well as for the sale of the products offered there and the processing of the order.',
  },
  disclaimer_third_party_content1_us: {
    de: 'b. Die Inhaber der Internetseiten, zu denen über die von HENKEL betriebene LOCTITE Pulse App ein Hyperlink besteht, sind sowohl für deren Inhalt als auch für den Verkauf der dort angebotenen Produkte und die Abwicklung der Bestellung allein verantwortlich.',
    en: 'b. The owners of those websites to which there is a hyperlink via the LOCTITE Pulse App (operated by Henkel Corporation) are solely responsible for their content as well as for the sale of the products offered there and the processing of the order.',
  },
  disclaimer_third_party_content2: {
    de: 'c. Weder die Henkel AG & Co. KGaA noch die lokale Henkel-Gesellschaft haftet für die Verletzung von Urheber-, Marken- und sonstigen gewerblichen Schutzrechten sowie Persönlichkeitsrechten, die auf einer mit einem Hyperlink versehenen Seite begangen wird.',
    en: 'c.	HENKEL is not liable for the violation of copyrights, trademark rights and other industrial property rights as well as personal rights, which is committed on a page provided with a hyperlink.',
  },
  disclaimer_third_party_content3: {
    de: 'd. Im Falle einer Bestellung oder einer anderen rechtsgeschäftlichen Erklärung kommt lediglich ein Vertrag zwischen dem Nutzer und dem jeweiligen Inhaber der Internetseite bzw. dem dort präsenten Anbieter, in keinem Falle jedoch aber ein Vertrag zwischen HENKEL und dem Nutzer zustande. Bitte beachten Sie die Allgemeinen Geschäftsbedingungen des jeweiligen Anbieters der verlinkten Internetseite.',
    en: 'd.	In case of an order or any other legal declaration only a contract between the user and the respective owner of the website or the provider present there is concluded, but in no case a contract between HENKEL and the user. Please note the general terms and conditions of the respective provider of the linked website.',
  },
  disclaimer_title: {
    de: '5. Haftungsausschluss',
    en: '5. Liability',
  },
  disclaimer_content: {
    de: 'a. Aufgrund der unterschiedlichen Arbeits- und Einsatzmöglichkeiten und der außerhalb von HENKEL‘s Einflussbereichs liegenden Einsatzbedingungen der LOCTITE Pulse App übernimmt weder Henkel AG & Co. KGaA noch die lokale Henkel Gesellschaft eine Haftung für die LOCTITE Pulse App bzw. der mit Hilfe der LOCTITE Pulse App erbrachten Daten-Analyse-Services für Ihre  beabsichtigten Zwecke und Ergebnisse.  Sie  sind ist allein dafür verantwortlich, die Leistungen der LOCTITE Pulse App sowie die hierüber erbrachten Services im Hinblick auf ihre Eignung für die von Ihnen verfolgten Ziele und Zwecke zu prüfen. Dies gilt auch dann, wenn Leistungen allgemein für einen bestimmten Zweck in schriftlicher oder mündlicher Beratung empfohlen werden; auch in diesem Fall sind Sie gehalten, Leistungen auf ihre Eignung für die von Ihnen verfolgten konkreten Ziele und die Betriebsbedingungen vor Ort zu prüfen. Weder Henkel AG & Co. KGaA noch die lokale Henkel Gesellschaft haftet, gleich aus welchem Rechtsgrund, nicht, soweit der Schaden auf einer bei Ihnen bestehenden Verletzung/Unterlassung einer der vorgenannten Prüfpflichten beruht.',
    en: "a. Due to the different ways of working and using the LOCTITE Pulse App and the conditions of use beyond HENKEL's control, Henkel AG & Co. KGaA nor Henkel Affiliate accepts any liability for the LOCTITE Pulse App or the data analysis services provided via the LOCTITE Pulse App (“Services”) for your intended purposes and results. You are solely responsible for checking the performance of the LOCTITE Pulse App as well as the Services provided via it with regard to their suitability for the intention and purposes pursued by you. This also applies if Services are generally recommended for a specific purpose in written or verbal advice; in this case, too, you are required to check Services for their suitability for the specific objectives you are pursuing and the operating conditions on site. Neither Henkel AG & Co. KGaA nor Henkel Affiliate shall be liable, irrespective of the legal basis, insofar as the damage is due to a breach/omission on your part of one of the aforementioned inspection obligations.",
  },
  disclaimer_content_us: {
    de: 'a. Aufgrund der unterschiedlichen Arbeits- und Einsatzmöglichkeiten und der außerhalb von HENKEL‘s Einflussbereichs liegenden Einsatzbedingungen der LOCTITE Pulse App übernimmt weder Henkel Corporation noch die lokale Henkel Gesellschaft eine Haftung für die LOCTITE Pulse App bzw. der mit Hilfe der LOCTITE Pulse App erbrachten Daten-Analyse-Services für Ihre  beabsichtigten Zwecke und Ergebnisse.  Sie  sind ist allein dafür verantwortlich, die Leistungen der LOCTITE Pulse App sowie die hierüber erbrachten Services im Hinblick auf ihre Eignung für die von Ihnen verfolgten Ziele und Zwecke zu prüfen. Dies gilt auch dann, wenn Leistungen allgemein für einen bestimmten Zweck in schriftlicher oder mündlicher Beratung empfohlen werden; auch in diesem Fall sind Sie gehalten, Leistungen auf ihre Eignung für die von Ihnen verfolgten konkreten Ziele und die Betriebsbedingungen vor Ort zu prüfen. Weder Henkel Corporation noch die lokale Henkel Gesellschaft haftet, gleich aus welchem Rechtsgrund, nicht, soweit der Schaden auf einer bei Ihnen bestehenden Verletzung/Unterlassung einer der vorgenannten Prüfpflichten beruht.',
    en: "a. Due to the different ways of working and using the LOCTITE Pulse App and the conditions of use beyond HENKEL's control, Henkel Corporation nor Henkel Affiliate accepts any liability for the LOCTITE Pulse App or the data analysis services provided via the LOCTITE Pulse App (“Services”) for your intended purposes and results. You are solely responsible for checking the performance of the LOCTITE Pulse App as well as the Services provided via it with regard to their suitability for the intention and purposes pursued by you. This also applies if Services are generally recommended for a specific purpose in written or verbal advice; in this case, too, you are required to check Services for their suitability for the specific objectives you are pursuing and the operating conditions on site. Neither Henkel Corporation nor Henkel Affiliate shall be liable, irrespective of the legal basis, insofar as the damage is due to a breach/omission on your part of one of the aforementioned inspection obligations.",
  },
  disclaimer_content1: {
    de: 'b. Wie bei anderen Online-Diensten hängt die Verfügbarkeit der LOCTITE Pulse App und/oder der Dienste auch von Dritten ab, z. B. von Hosting-Subunternehmern, über die HENKEL keine Kontrolle hat. Daher kann die LOCTITE Pulse App vorübergehend nicht oder nur mit eingeschränkter Funktionalität verfügbar sein. HENKEL ist jedoch bestrebt, eine Verfügbarkeit von 96 % zu erreichen. HENKEL übernimmt keine Gewähr dafür, dass die in der LOCTITE Pulse App angezeigten Dienste, Daten und Ergebnisse vollständig und/oder fehlerfrei sind. Der Kunde kann daher die LOCTITE Pulse App und die Dienste als intelligente Ergänzung (aber nicht als Ersatz) zu bestehenden, zwingend erforderlichen und/oder dem Stand der Technik entsprechenden Schutzmaßnahmen für seine Industrieanlagen nutzen.',
    en: 'b. As with other online services, availability of the LOCTITE Pulse App and/or the Services also depends on third parties, e.g. hosting sub-contractors, over which HENKEL has no control. Therefore, LOCTITE Pulse App may be temporarily unavailable or only available with limited functionality. However, HENKEL strives to achieve an availability level of 96%. HENKEL does not warrant that the Services, data and results shown in the LOCTITE Pulse App are complete and/or error-free. Hence, Customer may use LOCTITE Pulse App and the Services as smart add-on (but no substitute) to existing, mandatory required and/or state-of-the art protective measures for its Industrial Installations.',
  },
  disclaimer_content2: {
    de: 'c.	HENKEL unternimmt alle zumutbaren Anstrengungen, um die LOCTITE Pulse Apps virenfrei zu halten, kann aber keine Virenfreiheit garantieren. Wir empfehlen Ihnen daher, vor dem Herunterladen von Dokumenten und Informationen selbst für einen angemessenen Schutz vor Viren zu sorgen, zum Beispiel durch den Einsatz von Virenscannern.',
    en: 'c.	HENKEL undertakes reasonable efforts to keep the HENKEL LOCTITE Pulse Apps virus-free, but cannot guarantee freedom from viruses. Therefore, we recommend that you ensure your own adequate protection against viruses before downloading documents and information, for example by using virus scanners.',
  },
  disclaimer_content3: {
    de: 'd. Es liegt in Ihrer alleinigen Verantwortung, angemessene Maßnahmen zu ergreifen und auf Informationen zu reagieren, die durch Push-Benachrichtigungen (sofern technisch verfügbar), In-LOCTITE Pulse App-Benachrichtigungen, E-Mail oder in welcher Form auch immer in Bezug auf Rohdaten und/oder analysierte Daten bereitgestellt werden, einschließlich, aber nicht beschränkt auf mögliche Benachrichtigungen, die Sie in Bezug auf den Status der von ihm ausgewählten  Industrieanlage erhalten, wobei Sie nach eigenem Ermessen und in eigener Verantwortung entscheiden, ob, welche und wann sich aus einer solcher Benachrichtigung  Konsequenzen/Aktionen ergeben. Es liegt darüber hinaus in Ihrer alleinigen Verantwortung, die Art und Anzahl der Benachrichtigungen, die über die In-LOCTITE Pulse App-Benachrichtigungen hinausgehen (Push-Benachrichtigung (sofern technisch verfügbar) und/oder Email-Benachrichtigung für alle Nutzer), aktiv auszuwählen und damit gegebenenfalls die Erreichbarkeit im Fall einer Mitteilung über den Zustand der Industrieanlage zu erhöhen.',
    en: 'd. It is your sole responsibility to take appropriate action and respond to information provided through Push Notifications  (if technically available), In-LOCTITE Pulse App notifications, email, or in whatever form regarding raw and/or analyzed data, including but not limited to potential notifications you may receive regarding the status of its selected industrial facility, at your sole discretion and responsibility to determine whether, what, and when consequences/actions will result from any such notification. In addition, it is your sole responsibility to actively select the type and number of notifications beyond the In-LOCTITE Pulse App notifications (Push Notifications (if technically available) and/or email notification for all users), thereby increasing accessibility in the event of a notification regarding the status of the Industrial Facility, if applicable.',
  },
  disclaimer_content4: {
    de: 'e.	Eine regelmäßige Prüfung (i) der für die LOCTITE Pulse App erforderlichen Funktionalität der LOCTITE Pulse App, einschließlich der für hierfür erforderlichen IoT-Hardware (ii) der LOCTITE Pulse App, insbesondere der Datenübertragung, der täglichen zu erfolgenden Statusaktualisierung und eventueller Benachrichtigungen in der LOCTITE Pulse App, sowie sichtbare oder anderweitig auffällige Unregelmäßigkeiten der LOCTITE Pulse App, sind unverzüglich schriftlich an Henkel AG & Co. KGaA bzw. die lokale Henkel Gesellschaft als Ihr Vertragspartner zu melden. Unterbleibt die Mitteilung, so gelten die daraus resultierenden Ausfallzeiten der Dienste als von Ihnen verursacht und Sie haften für den durch nicht oder die verspätet übermittelte Mitteilung entstandenen Schaden.',
    en: 'e.	A regular check of (i) the functionality of the LOCTITE Pulse App required for the LOCTITE Pulse App, including the IoT hardware required for this (ii) the LOCTITE Pulse App, in particular the data transmission, the status update to be made daily and any notifications in the LOCTITE Pulse App, as well as visible or otherwise noticeable irregularities of the LOCTITE Pulse App, must be reported immediately in writing to HENKEL AG & Co. KGaA or the local Henkel Affiliate as your contractual partner. If you fail to notify us, the resulting downtime of the Services shall be deemed to be caused by you and you shall be liable for the damage caused by the failure to notify us or the late notification.',
  },
  disclaimer_content4_us: {
    de: 'e.	Eine regelmäßige Prüfung (i) der für die LOCTITE Pulse App erforderlichen Funktionalität der LOCTITE Pulse App, einschließlich der für hierfür erforderlichen IoT-Hardware (ii) der LOCTITE Pulse App, insbesondere der Datenübertragung, der täglichen zu erfolgenden Statusaktualisierung und eventueller Benachrichtigungen in der LOCTITE Pulse App, sowie sichtbare oder anderweitig auffällige Unregelmäßigkeiten der LOCTITE Pulse App, sind unverzüglich schriftlich an Henkel Corporation bzw. die lokale Henkel Gesellschaft als Ihr Vertragspartner zu melden. Unterbleibt die Mitteilung, so gelten die daraus resultierenden Ausfallzeiten der Dienste als von Ihnen verursacht und Sie haften für den durch nicht oder die verspätet übermittelte Mitteilung entstandenen Schaden.',
    en: 'e.	A regular check of (i) the functionality of the LOCTITE Pulse App required for the LOCTITE Pulse App, including the IoT hardware required for this (ii) the LOCTITE Pulse App, in particular the data transmission, the status update to be made daily and any notifications in the LOCTITE Pulse App, as well as visible or otherwise noticeable irregularities of the LOCTITE Pulse App, must be reported immediately in writing to Henkel Corporation or the local Henkel Affiliate as your contractual partner. If you fail to notify us, the resulting downtime of the Services shall be deemed to be caused by you and you shall be liable for the damage caused by the failure to notify us or the late notification.',
  },
  disclaimer_content5: {
    de: 'f. Jegliche Haftung für Henkel AG & Co. KGaA und/oder der lokalen Henkel Gesellschaften, die sich aus der LOCTITE Pulse App oder damit verbundenen, von HENKEL bereitgestellten Diensten ergibt, wird ausdrücklich ausgeschlossen. Soweit die Haftung von Henkel AG & Co. KGaA und/oder den lokalen Henkel Gesellschaften in diesen Nutzungsbedingungen beschränkt oder ausgeschlossen ist, gilt diese Beschränkung oder dieser Ausschluss nicht im Falle der Verletzung des Lebens, des Körpers oder der Gesundheit, im Falle von Vorsatz oder grober Fahrlässigkeit unsererseits oder im Falle einer Haftung nach dem Produkthaftungsgesetz. Soweit Henkel AG & Co. KGaA und/oder der lokalen Henkel Gesellschaften, wegen der Verletzung wesentlicher Vertragspflichten zwingend haftet, ist die Höhe der Schadensersatzansprüche insgesamt auf den typischerweise vorhersehbaren Schaden begrenzt.',
    en: 'f. Any liability for HENKEL resulting from the LOCTITE Pulse App or any related Services provided by HENKEL shall be expressly excluded. To the extent that liability from HENKEL is limited or excluded in these Terms of Use, such limitation or exclusion shall not apply in the event of injury to life, limb or health, in the event of intent or gross negligence on our part or in the event of liability under mandatory product liability law. Insofar as HENKEL is compulsorily liable due to the breach of essential contractual obligations, the amount of the claims for compensation is limited in total to typically foreseeable damage.',
  },
  termination_loctite_pulse_app: {
    de: '6. Beendigung des Betriebs der LOCTITE Pulse App',
    en: '6. Termination of operation of the LOCTITE Pulse App',
  },
  termination_loctite_pulse_content: {
    de: 'HENKEL behält sich das Recht vor, den Betrieb dieser LOCTITE Pulse App oder der darin angebotenen Dienste jederzeit einzustellen. Henkel AG & Co. KGaA kann dies insbesondere, aber nicht ausschließlich, aus technischen Gründen (z.B. Viren im Computersystem, Manipulation von Problemen in der Hard-/Software, Cyberangriffe) tun oder wenn aus irgendwelchen Gründen der Betrieb der in dieser LOCTITE Pulse App angebotenen Dienste nicht mehr gewährleistet werden kann, gesetzliche Anforderungen dies erfordern oder wenn HENKEL seine Marketingkonzepte ändert.',
    en: 'HENKEL reserves the right to discontinue the operation of this LOCTITE Pulse App or the Services offered therein at any time. HENKEL may do so in particular, inter alia, for technical reasons (e.g. viruses in the computer system, manipulation of problems in the hardware/software, cyber attacks) or if for any reason the operation of the Services offered in this LOCTITE Pulse App can no longer be guaranteed, or legal requirements make this necessary.',
  },
  henkel_products: {
    de: '7. Henkel Produkte',
    en: '7. Henkel Products',
  },
  henkel_products_content: {
    de: 'Die in diesem Internetauftritt abgebildeten Marken / Produkte stehen beispielhaft für die weltweit erhältlichen Marken / Produkte der Unternehmen von HENKEL. HENKEL übernimmt keine Garantie dafür, dass ein/e abgebildete/s Marke / Produkt auch in Ihrem Land erhältlich ist.',
    en: 'The brands / products shown on this LOCTITE Pulse App are examples of the brands / products of HENKEL companies available worldwide. HENKEL does not guarantee that a brand / product shown is also available in your country.',
  },
  miscellaneous_title: {
    de: '8. Sonstiges',
    en: '8. Miscellaneous',
  },
  miscellaneous_content: {
    de: 'Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Gerichtsstand für Streitigkeiten, die diesen Internetauftritt betreffen, ist Düsseldorf. Sollte eine der Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden, wird die Gültigkeit der übrigen Bestimmungen davon nicht berührt.',
    en: 'These Terms of Use are governed by the laws of the Federal Republic of Germany, excluding the UN Convention on Contracts for the International Sale of Goods. The place of jurisdiction for disputes relating to this website is Düsseldorf. Should one of the provisions of these Terms of Use be or become invalid, this shall not affect the validity of the remaining provisions.',
  },

  imprint2: { de: 'Impressum', en: 'Imprint' },
  service_provider_title: {
    de: 'Diensteanbieter:',
    en: 'Service Provider:',
  },
  service_provider: {
    de: 'Henkel AG & Co. KGaA \nvertreten durch die persönlich haftende Gesellschafterin: \nHenkel Management AG, diese vertreten durch den Vorstand: \nCarsten Knobel (Vorsitzender), Mark Dorn, Sylvie Nicol, Wolfgang König, Marco Swoboda \nAufsichtsratsvorsitzende: Dr. Simone Bagel-Trah',
    en: 'Henkel AG & Co. KGaA \nrepresented by its personally liable partner: \nHenkel Management AG, represented by the Management Board: \nCarsten Knobel (Chairman), Mark Dorn, Sylvie Nicol, Wolfgang König, Marco Swoboda \nChairwoman of the Supervisory Board: Dr. Simone Bagel-Trah',
  },

  registerd_office_title: {
    de: 'Sitz:',
    en: 'Registered Office:',
  },
  registerd_office: {
    de: 'Henkelstraße 67 \n40589 Düsseldorf',
    en: 'Henkelstrasse 67 \n40589 Düsseldorf, Germany',
  },
  postal_adress_title: {
    de: 'Postanschrift:',
    en: 'Postal Address:',
  },
  postal_adress: {
    de: '40191 Düsseldorf \n Tel.: 0211-797-0',
    en: '40191 Düsseldorf, Germany \n Phone: 0049-211-797-0',
  },
  commercial_register_title: {
    de: 'Handelsregister:',
    en: 'Commercial Register:',
  },
  commercial_register: {
    de: 'Registergericht Düsseldorf \nHRB 4724',
    en: 'Registergericht Düsseldorf: HRB 4724',
  },
  vat_id_no_title: {
    de: 'Umsatzsteuer-ID:',
    en: 'VAT ID No.:',
  },
  vat_id_no: {
    de: 'DE 11 94 29 301',
    en: 'DE 11 94 29 301',
  },
  email_contact_title: {
    de: 'Elektronische Kontaktadresse:',
    en: 'E-Mail contact:',
  },
  email_contact: {
    de: 'Service@loctite-pulse.com',
    en: 'Service@loctite-pulse.com',
  },

  data_protection_title: {
    de: 'Datenschutzerklärung',
    en: 'Data Protection Declaration',
  },
  general_title: {
    de: '1. Allgemeines',
    en: '1. General',
  },
  general_content: {
    de: 'Henkel AG & Co. KGaA, im Folgenden Henkel genannt, respektiert die Privatsphäre jeder Person, die unsere LOCTITE Pulse „Progressive Web App“ (nachfolgend „App“) besucht. Im Folgenden informieren wir Sie über die Kategorien personenbezogener Daten, die Henkel erhebt und wie sie verarbeitet werden. Sie erfahren im Folgenden auch, wie Sie Ihre Betroffenenrechte ausüben können.',
    en: 'Henkel AG & Co. KGaA (hereinafter referred to as “Henkel”) respects the privacy of every person who visits our LOCTITE Pulse "Progressive Web App" (hereinafter referred to as "Loctite Pulse App"). In the following, we inform you about the categories of personal data that Henkel collects and how they are processed. You will also learn below how to exercise your data subject rights.',
  },
  general_content_us: {
    de: 'Henkel Corporation, im Folgenden Henkel genannt, respektiert die Privatsphäre jeder Person, die unsere LOCTITE Pulse „Progressive Web App“ (nachfolgend „App“) besucht. Im Folgenden informieren wir Sie über die Kategorien personenbezogener Daten, die Henkel erhebt und wie sie verarbeitet werden. Sie erfahren im Folgenden auch, wie Sie Ihre Betroffenenrechte ausüben können.',
    en: 'Henkel Corporation (hereinafter referred to as “Henkel”) respects the privacy of every person who visits our LOCTITE Pulse "Progressive Web App" (hereinafter referred to as "Loctite Pulse App"). In the following, we inform you about the categories of personal data that Henkel collects and how they are processed. You will also learn below how to exercise your data subject rights.',
  },
  general_content1: {
    de: 'Alle Änderungen dieser Datenschutzerklärung werden auf dieser Webseite veröffentlicht. Dies ermöglicht es Ihnen, sich jederzeit zu informieren, welche Daten wir erheben und wie wir diese nutzen.',
    en: 'Any changes to this privacy policy will be published on this website. This will enable you to find out at any time what data we collect and how we use it.',
  },
  general_content2: {
    de: 'Diese Datenschutzerklärung gilt nicht für andere Webseiten, die über Hyperlinks aufrufbar sind.',
    en: 'This data protection declaration does not apply to other websites that can be accessed via hyperlinks.',
  },
  collection_title: {
    de: '2.	Erhebung, Nutzung und Verarbeitung personenbezogener Daten',
    en: '2. Collection, use and processing of personal data',
  },
  collection_content: {
    de: 'Name der verantwortlichen Stelle: Henkel AG & Co. KGaA',
    en: 'Name of the responsible entity: Henkel AG & Co. KGaA',
  },
  collection_content_us: {
    de: 'Name der verantwortlichen Stelle: Henkel Corporation',
    en: 'Name of the responsible entity: Henkel Corporation',
  },
  collection_content1: {
    de: 'Adresse der verantwortlichen Stelle: Henkelstraße 67, 40589 Düsseldorf, Germany',
    en: 'Address of the responsible entity: Henkelstrasse 67, 40589 Duesseldorf, Germany',
  },
  collection_content2: {
    de: 'Unsere weiteren Kontaktdaten finden Sie im Impressum dieser App.',
    en: 'Our further contact details can be found in the imprint of this app.',
  },
  collection_content3: {
    de: 'Henkel nutzt personenbezogene Daten vor allem, um Nutzern diese App zur Verfügung zu stellen und um das ordnungsgemäße Funktionieren und die Sicherheit dieser App zu gewährleisten. Jegliche weitere Verarbeitung personenbezogener Daten findet nur aufgrund anderer gesetzlicher Pflichten oder Erlaubnissen oder aufgrund einer Einwilligung des Nutzers gegenüber Henkel statt.',
    en: 'Henkel uses personal data primarily to provide users with this Loctite Pulse App and to ensure the proper functioning and security of this Loctite Pulse App. Any further processing of personal data will only take place on the basis of other legal obligations or permissions or on the basis of consent given by the user to Henkel.',
  },
  collection_content4: {
    de: 'Wenn Nutzer die App nutzen, erhebt und speichert Henkel automatisch gewisse Daten wie die IP-Adresse oder Geräte-ID, die dem jeweiligen Endgerät zugeordnet ist, die wir zur Übermittlung von angeforderten Inhalten benötigen (z.B. Inhalt, Texte, Bilder, Produktinformationen sowie zum Download zur Verfügung gestellter Dateien, usw.), die Nutzeraktivitäten im Zusammenhang mit der App, die Art des jeweiligen Endgeräts, der Browsertyp sowie Datum und Dauer der Nutzung.',
    en: 'When users visit the Loctite Pulse App, Henkel automatically collects and stores certain data such as the IP address or device ID associated with the respective end device, which we need to transmit requested content (e.g. content, texts, images, product information as well as files made available for download, etc.), user activities in connection with the Loctite Pulse App, the type of the respective end device, the used type of browser as well as the date and duration of use.',
  },
  collection_content5: {
    de: 'Henkel speichert diese Daten zur Erkennung und Verfolgung von Missbrauch maximal 7 Tage.',
    en: 'Henkel stores this data for a maximum of 7 days for the purpose of recognizing and pursuing misuse.',
  },
  collection_content6: {
    de: 'Henkel nutzt diese Daten auch, um das eigene Angebot und Funktionen des Dienstes zu verbessern sowie für allgemeine Verwaltungsaufgaben.',
    en: 'Henkel also uses this data also improvements to the service’s presentation, features and functionalities as well as general administration tasks.',
  },
  collection_content7: {
    de: 'Henkel verarbeitet Ihre Daten nur so lange, wie diese benötigt werden. Im Übrigen löscht oder anonymisiert Henkel die Nutzungsdaten einschließlich der IP-Adressen unverzüglich, sobald sie für die oben genannten Zwecke nicht mehr benötigt werden.',
    en: 'Henkel processes your data only as long as it is needed. Otherwise, Henkel deletes or anonymizes the usage data, including IP addresses, immediately as soon as they are no longer needed for the above-mentioned purposes.',
  },
  collection_content8: {
    de: 'Die Verarbeitung und Nutzung der Daten beruht auf gesetzlichen Bestimmungen, die diese Vorgänge rechtfertigen, weil (1) die Verarbeitung für die Bereitstellung der App notwendig ist; oder (2) Henkel ein überwiegendes berechtigtes Interesse daran hat, die Funktionalität und den fehlerfreien Betrieb der App zu gewährleisten, zu verbessern und auf die Bedürfnisse der Nutzer abzustimmen.',
    en: 'The processing and use of the data is based on legal provisions that justify these operations because (1) the processing is necessary for the provision of the Loctite Pulse App; or (2) Henkel has an overriding legitimate interest in ensuring the functionality and error-free operation of the Loctite Pulse App, to improve it and to tailor it to the users’s needs.',
  },
  collection_content9: {
    de: 'Empfänger der erhobenen Daten können sein: Mitglieder der zuständigen internen Abteilungen, andere verbundene Unternehmen von Henkel, externe Dienstleister (z.B. für Hosting und Content Management, Weiterentwicklung der App, Senden von In App-Notifications, Push-Notifications / Emails, Marketing-Agenturen, sonstige Dritte, sofern dies für die von ihnen erbrachten Leistungen erforderlich ist), Behörden in Ausübung ihrer jeweiligen Aufgaben und Befugnisse, z. B. bei behördlicher Anordnung, oder wenn dies zur Begründung, Ausübung oder Verteidigung von Rechten von Henkel erforderlich ist, sowie gegenwärtige oder zukünftige Erwerber von Henkel-Unternehmen im Falle einer geschäftlichen Transaktion, wie z. B. einem Verkauf, einer Fusion oder einer Übernahme.',
    en: "Recipients of the collected data may include: members of the relevant internal departments, other affiliated companies of Henkel, external service providers (e.g. for hosting and content management, further development of the Loctite Pulse App, sending (1) In-App Notifications, (2) Push Notifications (available for Android and IOS as of Version 16.4) / Emails, marketing agencies and/or other third parties to the extent necessary for the services they provide), authorities in the exercise of their respective duties and powers, for example, in the event of an official request on their part, or if this is necessary to establish, exercise or defend Henkel's rights, and current or future acquirers of Henkel companies in the event of a business transaction, such as a sale, merger or acquisition.",
  },
  further_processing_title: {
    de: '3.	Weitere Verarbeitung personenbezogener Daten',
    en: '3. Further processing of personal data ',
  },
  app_use_title: {
    de: 'Nutzung der App',
    en: 'Use of the Loctite Pulse App',
  },
  further_processing_content: {
    de: 'Sie haben uns Ihre Kontaktdaten wie z.B. Ihre E-Mail-Adresse möglicherweise im Rahmen der Registrierung zur Nutzung der App zur Verfügung gestellt. Wir nutzen Ihre Daten zum Zweck der Bereitstellung und weiteren Nutzung der App auf Basis Ihrer Einwilligung.',
    en: 'You may have provided us with your contact details, such as your email address, as part of the registration process for using the Loctite Pulse App. We use your data for the purpose of providing and further using the Loctite Pulse App based on your consent.',
  },
  further_processing_content1: {
    de: 'Wenn Sie in eine längere Speicherung Ihrer Daten nicht eingewilligt haben, z. B. im Rahmen einer vertraglichen Vereinbarung, speichern wir Ihre Daten nicht länger als zur Erfüllung des oben genannten Zwecks erforderlich oder so lange wie es erforderlich ist zur Erfüllung gesetzlicher Aufbewahrungspflichten.',
    en: 'If you have not consented to a longer storage of your data, e.g. in the context of a contractual agreement, we will not store your data longer than necessary to fulfill the above purpose or as long as it is necessary to fulfill legal retention obligations.',
  },
  consent_title: {
    de: '4.	Einwilligung/Widerruf der Einwilligung',
    en: '4. Consent / Withdrawal of Consent',
  },
  consent_content: {
    de: 'Kontakt mittels Push-Notification (aktuell technisch nur bei Android verfügbar) und/oder Email – Benachrichtigung',
    en: 'Contact via Push Notification (currently technically only available for Android) and/or email notification.',
  },
  consent_content1: {
    de: 'Sie können Ihre Einwilligung erteilen, dass Henkel Sie bzgl. der in der Einwilligungserklärung genannten Aspekte auch direkt auf Ihrem Mobilgerät per Push-Notification (für Android und IOS ab Version 16.4 verfügbar) und /oder per E-Mail kontaktieren darf. Die Rechtsgrundlage für die Verarbeitung ist dann Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a) DSGVO.',
    en: 'You can give your consent that – with respect to the aspects expressly mentioned in the consent declaration -  Henkel may also contact you directly on your mobile device via push notification, if technically available (“Push Notification”) and / via or Email  mentioned in the consent form. The legal basis for the processing is then your consent pursuant to Art. 6 para. 1 p. 1 lit. a) GDPR.',
  },
  consent_content2: {
    de: 'Wir nutzen Ihre Daten gemäß Ziffer 2) and 3) dieser Datenschutzerklärung, um Ihnen entsprechende Mitteilungen über den Status der von Ihnen ausgewählten Industrieanlage zur Verfügung zu stellen.',
    en: 'We use your data in accordance with sections 2) and 3) of this data protection declaration in order to provide you with appropriate notifications about the status of the industrial plant you have selected to be monitored.',
  },
  consent_content3: {
    de: 'Sie können Ihre Einwilligung',
    en: 'You may give your consent',
  },
  consent_content4: {
    de: ' – auch nur für einzelne Formen der Kontaktaufnahme - in den Einstellungen erteilen und auch ',
    en: ' - also only for individual ways of being contacted - in the settings of the Loctite Pulse App and – in the same way - ',
  },
  consent_content5: {
    de: 'jederzeit mit Wirkung für die Zukunft individuell widerrufen,',
    en: 'withdraw it individually at any time with effect for the future',
  },
  consent_content6: {
    de: ' indem Sie in der App unter „Mehr Informationen“ die Push-Notifications (falls technisch verfügbar) bzw. E-Mail-Benachrichtigung für diese App aktivieren (für die Einwilligung) bzw. wieder deaktivieren (für den Widerruf).',
    en: ' by activating (for consent) or deactivating again (for withdrawal) the Push Notifications (if technically available) or Email notification for this Loctite Pulse App under "More information" in the Loctite Pulse App.',
  },
  consent_content7: {
    de: 'Der Widerruf dieser Einwilligung berührt die Rechtmäßigkeit der Verarbeitung aufgrund Ihrer Einwilligung bis zu deren Widerruf nicht.',
    en: 'The withdrawal of consent does not affect the lawfulness of the processing based on your consent until its revocation.',
  },
  cookies_title: {
    de: '5. Cookies',
    en: '5. Cookies',
  },
  cookies_content: {
    de: 'Diese Website verwendet sogenannte Cookies. Cookies sind kleine Dateien, die für die Appnutzung erforderlich sind und die die App vorübergehend auf Ihrem Endgerät speichert. Cookies enthalten oft eine per Zufallsgenerator erzeugte anonyme Kennung. Einige dieser Kennungen werden am Ende der App-Sitzung gelöscht, andere bleiben länger auf Ihrem Endgerät.',
    en: 'This Loctite Pulse App uses so-called cookies. Cookies are small units of data temporarily stored on your end device and that are necessary for the use of the Loctite Pulse App. Cookies often contain a randomly generated anonymous identifier. Some of these identifiers are deleted at the end of the Loctite Pulse App session, others remain on your end device for longer.',
  },
  cookies_content1: {
    de: 'Die App verwendet ausschließlich technisch notwendige Cookies, die automatisch verwendet werden, damit Sie sich auf unserer Website anmelden können. Die Rechtsgrundlage für die Verwendung von technisch notwendigen Cookies ist Art. 6 (1) lit. f) DSGVO. Unser berechtigtes Interesse ist die ordnungsgemäße Bereitstellung unseres Onlineangebotes.',
    en: 'The Loctite Pulse App only uses technically necessary cookies, which are used automatically to enable you to log in to our website. The legal basis for the use of technically necessary cookies is Art. 6 (1) lit. f) GDPR  Our legitimate interest is the proper provision of our online offer, namely the Loctite Pulse App.',
  },
  rights_title: {
    de: '6.	Ihre Betroffenenrechte/ Datenschutzbeauftragter',
    en: '6. Your Data Subject Rights / Data Protection Officer',
  },
  rights_content: {
    de: 'Sie können jederzeit Ihr Recht auf Auskunft ausüben. Darüber hinaus haben Sie bei Vorliegen der jeweiligen Voraussetzungen die folgenden weiteren Rechte:',
    en: 'At any time, you may assert the right to access your data. Additionally, provided that the respective requirements are met, you may assert the following rights:',
  },

  rights_content1: {
    de: '• Recht auf Berichtigung',
    en: '• Right to rectification',
  },
  rights_content2: {
    de: '• Recht auf Löschung',
    en: '• Right to erasure',
  },
  rights_content3: {
    de: '• Recht auf Einschränkung der Verarbeitung',
    en: '• Right to restriction of processing',
  },
  rights_content4: {
    de: '• Recht auf Beschwerde bei der jeweils zuständigen Datenschutzaufsichtsbehörde',
    en: '• Right to lodge a complaint with the respectively competent data protection supervisory authority',
  },
  rights_content5: {
    de: '• Recht auf Datenübertragbarkeit',
    en: '• Right to data portability',
  },
  right_to_object_title: {
    de: 'Widerspruchsrecht',
    en: 'Right to object ',
  },
  right_to_object_content: {
    de: 'Im Falle der Verarbeitung Ihrer personenbezogenen Daten, die aufgrund eines berechtigten Interesses von Henkel durchgeführt wird, haben Sie jederzeit das Recht, der Verarbeitung Ihrer personenbezogenen Daten aus Gründen, die sich aus Ihrer besonderen Situation ergeben, zu widersprechen. Henkel wird die Verarbeitung einstellen, es sei denn, Henkel kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen oder wenn die Verarbeitung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.',
    en: 'In case of processing activities involving your personal data that are carried out on basis of a legitimate interest of Henkel, you have the right to object against such processing of your personal data at any time for reasons resulting from your specific situation. Henkel will stop that processing unless Henkel can prove important reasons for the processing which deserve protection which outweigh your interests, rights and freedoms or if the processing serves to assert, exercise or defend against legal claims.',
  },
  right_to_object_content1: {
    de: 'Sollten Sie Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.',
    en: 'Should you have given your consent to the processing of your personal data, you can withdraw it at any time with effect for the future.',
  },
  right_to_object_content2: {
    de: 'Bitte schicken Sie zur Ausübung Ihrer Rechte oder wenn Sie andere Fragen im Hinblick auf Ihre personenbezogenen Daten haben eine E-Mail oder einen Brief an unseren Datenschutzbeauftragten, Herrn Frank Liebich, Henkel AG & Co. KGaA, Henkelstraße 67, D-40191 Düsseldorf (E-Mail: Datenschutz@henkel.com).',
    en: 'In these cases, or if you have any other questions or wishes with regard to your personal data, please send an email or a letter to our data protection officer, Mr. Frank Liebich, Henkel AG & Co. KGaA, Henkelstrasse 67, D 40191 Düsseldorf, Germany (E-Mail: Datenschutz@henkel.com).',
  },
  right_to_object_content2_us: {
    de: 'Bitte schicken Sie zur Ausübung Ihrer Rechte oder wenn Sie andere Fragen im Hinblick auf Ihre personenbezogenen Daten haben eine E-Mail oder einen Brief an unseren Datenschutzbeauftragten, Herrn Frank Liebich, Henkel Corporation, Henkelstraße 67, D-40191 Düsseldorf (E-Mail: Datenschutz@henkel.com).',
    en: 'In these cases, or if you have any other questions or wishes with regard to your personal data, please send an email or a letter to our data protection officer, Mr. Frank Liebich, Henkel Corporation, Henkelstrasse 67, D 40191 Düsseldorf, Germany (E-Mail: Datenschutz@henkel.com).',
  },
  sharing_data_title: {
    de: '7. Weitergabe von Daten',
    en: '7. Passing on of personal data',
  },
  sharing_data_content: {
    de: 'Eine Weitergabe von personenbezogenen Daten ohne ausdrückliche vorherige Zustimmung des jeweiligen Nutzers erfolgt neben den anderen in dieser Datenschutzerklärung genannten Fällen nur in den folgenden Fällen:',
    en: 'In addition to the other cases mentioned in this Data Protection Declaration, personal data will - without the express prior consent of the respective user - only be passed on in the following cases:',
  },
  sharing_data_content1: {
    de: 'Personenbezogene Daten werden an Strafverfolgungsbehörden sowie ggf. an geschädigte Dritte weitergegeben, wenn dies zur Aufklärung einer rechtswidrigen oder missbräuchlichen Nutzung der App erforderlich ist. Dies geschieht jedoch nur, wenn konkrete Anhaltspunkte für eine rechtswidrige oder missbräuchliche Nutzung vorliegen. Eine Weitergabe kann auch erfolgen, wenn dies der Durchsetzung der Nutzungsbedingungen oder anderer Vereinbarungen dient.',
    en: 'If applicable, personal data may be disclosed to law enforcement authorities as well as to injured third parties, if this is necessary to clarify an illegal or abusive use of the Loctite Pulse App. However, this does only apply if there are concrete indications of illegal or improper use. Disclosure may also occur if this serves to enforce the terms of use or other agreements.',
  },
  sharing_data_content2: {
    de: 'Wir sind auch gesetzlich verpflichtet, bestimmten staatlichen Stellen Auskunft zu erteilen. Dabei handelt es sich um die Strafverfolgungsbehörden, Behörden, die Ordnungswidrigkeiten verfolgen, die mit Geldbußen geahndet werden und Steuerbehörden.',
    en: 'We are also legally obligated to provide information to certain government agencies. These are law enforcement agencies, agencies that prosecute regulatory offenses punishable by fines, and tax authorities.',
  },
  sharing_data_content3: {
    de: 'Es kann sein, dass wir auf Drittunternehmen und externe Dienstleister im Rahmen von Vertragsverhältnissen zurückgreifen müssen, die sich außerhalb der EU oder des EWR befinden können, um den Dienst zu erbringen. In solchen Situationen werden Informationen an diese Unternehmen oder Einzelpersonen weitergegeben, um die weitere Verarbeitung zu ermöglichen. Wir wählen diese externen Dienstleister sorgfältig aus und überprüfen sie regelmäßig, um sicherzustellen, dass Ihre Privatsphäre gewahrt bleibt und die Daten nur für die von uns festgelegten Zwecke verwendet werden können. Auch sind sie durch Verträge mit uns verpflichtet, Ihre Daten ausschließlich in Übereinstimmung mit dieser Datenschutzerklärung sowie den europäischen Datenschutzgesetzen zu verarbeiten. Soweit eine Partei außerhalb der EU oder des EWR beteiligt ist, stellen wir ein angemessenes Datenschutzniveau sicher, z.B. durch den Abschluss entsprechender Verträge oder durch Zertifizierungen der jeweiligen datenhaltenden Stelle.',
    en: 'We may need to use third party companies and external service providers under contractual relationships that may be located outside the EU or EEA to provide the service. In such situations, information will be shared with these companies or individuals to enable further processing. We select these external service providers carefully and review them regularly to ensure that your privacy is protected and that the data can only be used for the purposes we have specified. They are also bound by contracts with us to process your data only in accordance with this Privacy Policy and European Data Protection laws. Where a party outside the EU or EEA is involved, we ensure an appropriate level of data protection, e.g. by concluding appropriate contracts or by certifying the respective data-holding entity.',
  },
  sharing_data_content4: {
    de: 'Im Zuge der Weiterentwicklung unseres Geschäfts ist es möglich, dass sich die Struktur unseres Unternehmens durch Änderung der Rechtsform, Gründung, Kauf oder Verkauf von Tochtergesellschaften, Unternehmensbereichen oder Unternehmensteilen ändert. Bei solchen Transaktionen werden die Nutzerinformationen zusammen mit dem übertragenen Unternehmensteil weitergegeben. Wir stellen bei jeder oben beschriebenen Weitergabe von personenbezogenen Daten an Dritte sicher, dass diese in Übereinstimmung mit dieser Datenschutzerklärung und den einschlägigen Datenschutzgesetzen erfolgt.',
    en: 'In the course of the further development of our business, it is possible that the structure of our company will change through a change in legal form, the establishment, purchase or sale of subsidiaries, divisions or parts of companies. In such transactions, user information will be transferred along with the transferred business unit. We will ensure that any transfer of personal data to third parties as described above is in accordance with this Privacy Policy and the relevant data protection laws.',
  },
  sharing_data_content5: {
    de: 'Stand der Datenschutzerklärung: Juli 2023',
    en: 'Status of the data protection declaration: July 2023',
  },
  privacy_inquiry_adress: {
    de: 'Henkel Privacy Inquiries \nCompliance Officer \nHenkel Corporation \nOne Henkel Way \nRocky Hill, CT 06067',
    en: 'Henkel Privacy Inquiries \nCompliance Officer \nHenkel Corporation \nOne Henkel Way \nRocky Hill, CT 06067',
  },
  ca_privacy_inquiry_adress: {
    de: 'Attention: Henkel Privacy Inquiries \nPrivacy Officer Department \nHenkel Corporation \nOne Henkel Way \nRocky Hill, CT 06067',
    en: 'Attention: Henkel Privacy Inquiries \nPrivacy Officer Department \nHenkel Corporation \nOne Henkel Way \nRocky Hill, CT 06067',
  },
};
