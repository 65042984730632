import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../cc-framework/services/authentication.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-note-for-us-residents',
  templateUrl: './note-for-us-residents.component.html',
  styleUrls: ['./note-for-us-residents.component.scss'],
})
export class NoteForUsResidentsComponent implements OnInit {
  constructor(
    public i18n: I18nService,
    public router: Router,
    private auth: AuthenticationService
  ) {}

  get isAuth(): boolean {
    return this.auth.isLoggedIn();
  }

  ngOnInit(): void {}
  gotoLogin() {
    this.router.navigate(['login']);
  }
}
