<div
  class="container"
  [class.mobile]="isMobile"
  [class.tablet]="isTablet"
  *ngIf="
    !!asset &&
    (asset.additionalInfo.length > 0 ||
      asset.additionalDocuments.length > 0 ||
      InstallationData)
  "
>
  <div class="sensor-header">
    <i
      nz-icon
      nzType="icons:additional-information"
      class="sensor-icon svg-icon"
    ></i>
    <h2>{{ i18n.string("additional_information") }}</h2>
  </div>
  <div
    class="additionalInformation"
    *ngIf="
      !!asset &&
      (asset.additionalInfo.length > 0 ||
        asset.additionalDocuments.length > 0 ||
        InstallationData)
    "
  >
    <nz-collapse [nzBordered]="false" [nzExpandIconPosition]="'right'">
      <nz-collapse-panel
        #pA
        [nzHeader]="panelheaderA"
        [nzActive]="addInfo[0]"
        [nzExtra]="collapseIconA"
        nzShowArrow="false"
        *ngIf="asset.additionalInfo.length > 0"
      >
        <ng-container *ngFor="let group of groupedInfoValues">
          <nz-list nzSize="small" *ngIf="group.displayInfoValues.length > 0">
            <nz-list-header class="info-type-name"
              ><h3>{{ i18n.parse(group.infoType.name) }}</h3></nz-list-header
            >
            <hr />
            <nz-list nzSize="small" class="addInfo-list">
              <ng-container *ngFor="let addInfo of group.displayInfoValues">
                <nz-list-item
                  *ngIf="addInfo.fieldType === 'single'"
                  class="regular addInfo-list-item"
                >
                  <span class="body-bold-cap addInfo-label">{{
                    addInfo.labelkey(i18n)
                  }}</span>
                  <i
                    *ngIf="addInfo.icon"
                    nz-icon
                    [nzType]="'icons:done-full-circle'"
                  ></i>
                  <span *ngIf="addInfo.valueType">{{
                    addInfo.value | unit : addInfo : i18n.MeasurementSystem
                  }}</span>
                  <span *ngIf="!addInfo.valueType">{{ addInfo.text }}</span>
                </nz-list-item>
                <nz-list-item
                  *ngIf="addInfo.fieldType === 'dropdown'"
                  class="regular addInfo-list-item"
                >
                  <span class="body-bold-cap addInfo-label">{{
                    addInfo.labelkey(i18n)
                  }}</span>
                  <i
                    *ngIf="addInfo.icon"
                    nz-icon
                    [nzType]="'icons:done-full-circle'"
                  ></i>
                  <span *ngIf="addInfo.valueType">{{
                    addInfo.value | unit : addInfo : i18n.MeasurementSystem
                  }}</span>
                  <span *ngIf="!addInfo.valueType">{{
                    i18n.Parse(addInfo.text)
                  }}</span>
                </nz-list-item>
                <nz-list-item
                  *ngIf="
                    addInfo.fieldType === 'multiple' &&
                    addInfo.multipleValues[0] &&
                    addInfo.multipleValues[0] != ''
                  "
                  class="regular addInfo-list-item"
                >
                  <span class="body-bold-cap addInfo-label">{{
                    addInfo.labelkey(i18n)
                  }}</span>
                  <div *ngFor="let value of addInfo.multipleValues">
                    <i
                      *ngIf="addInfo.icon"
                      nz-icon
                      [nzType]="'icons:done-full-circle'"
                    ></i>
                    <span>{{ value }}</span>
                  </div>
                </nz-list-item>
              </ng-container>
            </nz-list>
          </nz-list>
        </ng-container>
        <ng-template #panelheaderA>
          <div class="collapse-header">
            <i nz-icon [nzType]="'icons:info-about-equip'"></i>
            <span class="body-bold">{{
              i18n.string("information_about_equipment")
            }}</span>
          </div>
        </ng-template>
        <ng-template #collapseIconA let-active>
          <i nz-icon *ngIf="!pA.nzActive" [nzType]="'icons:plus'"></i>
          <i nz-icon *ngIf="pA.nzActive" [nzType]="'icons:minus'"></i>
        </ng-template>
      </nz-collapse-panel>

      <nz-collapse-panel
        #pInstall
        [nzHeader]="panelheaderInstallation"
        [nzActive]="addInfo[1]"
        [nzExtra]="collapseIconInstall"
        nzShowArrow="false"
        *ngIf="InstallationData"
      >
        <nz-list nzSize="small" *ngFor="let group of InstallationData">
          <nz-list-header class="body-bold-cap">{{
            parseGroupHeader(group.header)
          }}</nz-list-header>
          <nz-list-item *ngFor="let item of group.items" class="regular">
            <div>
              <i
                nz-icon
                [nzType]="'icons:' + item.icon"
                *ngIf="!!item.icon"
              ></i>
              <ng-container *ngIf="!!item.text">
                {{ i18n.parse(item.text) }}
              </ng-container>
              <ng-container *ngIf="!!item.value">
                {{
                  item.value | unit : item.valueType : i18n.MeasurementSystem
                }}
              </ng-container>
            </div>
          </nz-list-item>
        </nz-list>
        <ng-template #panelheaderInstallation>
          <div class="collapse-header">
            <i nz-icon [nzType]="'icons:wifi'"></i>
            <span class="body-bold">{{
              i18n.string("sensor_installation_data")
            }}</span>
          </div>
        </ng-template>
        <ng-template #collapseIconInstall let-active>
          <i nz-icon *ngIf="!pInstall.nzActive" [nzType]="'icons:plus'"></i>
          <i nz-icon *ngIf="pInstall.nzActive" [nzType]="'icons:minus'"></i>
        </ng-template>
      </nz-collapse-panel>

      <nz-collapse-panel
        #pB
        [nzHeader]="panelheaderB"
        [nzActive]="addInfo[2]"
        [nzExtra]="collapseIconB"
        nzShowArrow="false"
        style="border: none"
        *ngIf="asset.additionalDocuments.length > 0"
      >
        <div class="image-wrapper">
          <div
            class="single-image-wrapper"
            *ngFor="let doc of asset.additionalDocuments"
          >
            <span class="body-bold-cap">{{ doc.name }}</span>
            <button class="photo-subwrapper" *ngIf="doc.type === 'image'">
              <img
                class="photo-preview"
                [src]="doc.thumbnailLink"
                (click)="overlayImage = doc.storageLink"
              />
              <div class="photo-icon-circle">
                <i nz-icon nzType="icons:eye" class="svg-icon mini-icon"></i>
                <span class="body-bold">{{ i18n.string("show") }}</span>
              </div>
            </button>
            <button
              class="photo-subwrapper"
              *ngIf="doc.type === 'file'"
              (click)="openDocument(doc.storageLink)"
            >
              <!--<img class="photo-preview" src="/assets/images/lama.png" (click)="openDocument(doc.storageLink)" />-->
              <div class="photo-icon-circle">
                <i nz-icon nzType="icons:eye" class="svg-icon mini-icon"></i>
                <span class="body-bold">{{ i18n.string("show") }}</span>
              </div>
              <div class="file-preview">
                <i nz-icon nzType="icons:file" class="svg-icon mini-icon"></i>
              </div>
            </button>
          </div>
        </div>
        <ng-template #panelheaderB>
          <div class="collapse-header">
            <i nz-icon [nzType]="'icons:documents'"></i>
            <span class="body-bold">{{
              i18n.string("additional_documents")
            }}</span>
          </div>
        </ng-template>
        <ng-template #collapseIconB let-active>
          <i nz-icon *ngIf="!pB.nzActive" [nzType]="'icons:plus'"></i>
          <i nz-icon *ngIf="pB.nzActive" [nzType]="'icons:minus'"></i>
        </ng-template>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
  <app-nav-button
  *ngIf="asset?.equipmentType === 'Steam Trap' || asset?.equipmentType === 'Flange'"
    [darksecondary]="true"
    svg_icon="icons:tool"
    (click)="toTechnicalDocuments()"
  >
    {{ i18n.string("view_technical_documents") }}</app-nav-button
  >
</div>
<nz-modal
  [(nzVisible)]="overlayImage"
  (nzOnCancel)="overlayImage = null"
  [nzFooter]="null"
  [nzCloseIcon]="'icons:exit'"
  [nzTitle]="i18n.string('photo')"
>
  <img class="photo" src="{{ overlayImage }}" />
</nz-modal>
