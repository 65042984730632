<app-header [transparent]="false" [title]="i18n.string('edge_device_health')"></app-header>

<div class="spinner-container" [class.desktop]="isDesktop" *ngIf="!!loading">
  <app-spinner [black]="true"></app-spinner>
</div>

<div class="content" [class.desktop]="isDesktop">
  <div *ngIf="isDesktop" class="desktop-sidebar white-sidebar">
    <app-sidebar [showList]="true" [Asset]="asset" [loading]="loading" [sensorHealthPage]="true"></app-sidebar>
  </div>
  <div *ngIf="!!asset && !loading" [class.desktop-main]="isDesktop">
    <app-breadcrumbs [array]="Breadcrumbs" [isAsset]="true"
      *ngIf="!isDesktop && !!asset && asset.breadcrumbs && !breadcrumbsLoading" class="detail-bold">
    </app-breadcrumbs>
    <div class="asset-back-btn" (click)="goBack()" *ngIf="isDesktop">
      <i nz-icon nzType="icons:arrow-left" class="svg-icon fillwhite"></i>
      <span>{{ i18n.string('back_to') + i18n.parse(this.asset.objectName) }}</span>
    </div>

    <div>
      <ng-container *ngFor="let telemetry of HealthTelemetry">
        <div class="telemetry-item">
          <div class="sensor-header flat-sensor" [class.desktop]="isDesktop">
            <i nz-icon [nzType]="'icons:' + telemetry.svg" class="sensor-icon svg-icon"></i>
            <div class="sensor-header-inner-div">
              <h2>{{ i18n.parse(telemetry.title) }} {{i18n.string('of_the_edge_device')}}</h2>
            </div>
          </div>
          <app-sensor-value [telemetry]="telemetry" [asHealth]="true"
            (click)="toSensorValue(telemetry.sensor.sensorID, telemetry.measurementTypeID)">
          </app-sensor-value>
        </div>
      </ng-container>
    </div>

    <div class="error-card" *ngIf="DisplayErrorCard">
      <span class="error-card-title">{{i18n.string('problem_detected_title')}}</span>
      <div class="error-card-content">
        <i nz-icon nzType="icons:status-exclamation-triangle" class="svg-icon fillwhite"></i>
        <span class="error-card-text">
          {{i18n.string('problem_detected_text')}}
        </span>
      </div>
      <app-nav-button [href]="InstallationAgentURL" [primary]="true" svg_icon="icons:edge_device">
        {{i18n.string('replace_edge_device_button')}}</app-nav-button>
    </div>
  </div>
</div>
