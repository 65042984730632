import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BannerNotificationService } from '../../services/banner-notification.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() transparent: boolean = false;
  @Input() overlap: boolean = false;
  @Input() title: string = '';
  @Input() backTo: string = '';
  public backText: string = '';

  get IsOnSettingsPage(): boolean {
    return this.route?.snapshot?.routeConfig?.path.includes('settings');
  }
  public showBannerNotification: boolean = false;

  // get IsMobile(): boolean {
  //   return this.ui.IsMobile;
  // }
  // get IsTablet(): boolean {
  //   return this.ui.IsTablet;
  // }

  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  constructor(
    public router: Router,
    private location: Location,
    private ui: UiService,
    private route: ActivatedRoute,
    public i18n: I18nService,
    private bannerNotificationService: BannerNotificationService
  ) {}

  ngOnInit() {
    this.ui.subscribeToBackText((text: string) => (this.backText = text));
    this.bannerNotification();
  }

  back(): void {
    this.ui.goBack();
  }

  bannerNotification() {
    this.bannerNotificationService.getBannerNotifications().subscribe((res) => {
      if (res.filter((r) => r.type === 'maintenance').length > 0)
        this.showBannerNotification = true;
    });
  }

  closeBannerNotification(e) {
    this.bannerNotificationService
      .dismissBannerNotification('maintenance')
      .subscribe(
        () => {
          this.showBannerNotification = false;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  toSettings() {
    this.router.navigate(['settings']);
  }
}
