<div
  class="card"
  [class.modal]="modal"
  [class.fine]="fine && !detail"
  [class.critical]="!fine && !detail"
  [class.detail]="detail"
  [class.mobile]="isMobile"
  [class.tablet]="isTablet"
>
  <nz-card
    [nzTitle]="!!plant && !plant.filtered ? plantheader : null"
    [class.show-layer]="showLayer"
  >
    <span *ngIf="showLayer" class="layer-label detail">{{
      i18n.parse(plant.layerName)
    }}</span>
    <div
      *ngIf="
        plant.Critical.length === 0 &&
        plant.Warning.length === 0 &&
        plant.InActive.length === 0 &&
        plant.Working.length > 0 &&
        !detail &&
        !!plant &&
        !plant.filtered
      "
      class="nz-multiple-status-reports"
    >
      <nz-alert
        nzType="success"
        class="nz-status-report noMarginTop"
        [class.center]="!isDesktop"
        [nzMessage]="allfineTemplate"
      ></nz-alert>
      <ng-template #allfineTemplate
        ><i nz-icon nzType="icons:status-check" class="fillgreen"></i
        ><span class="body-bold">
          {{ i18n.string("all_equipments_working_fine") }}</span
        ></ng-template
      >
    </div>

    <!--<div *ngIf="plant.Critical.length > 0 && !detail && !!plant && !plant.filtered" class="nz-multiple-status-reports">
      <nz-alert nzType="error" class="nz-status-report noMargin" [class.center]="IsMobile" [nzMessage]="errorTemplate">
      </nz-alert>
      <ng-template #errorTemplate><i nz-icon nzType="icons:status-exclamation-triangle" class="fillred"></i><span
          class="body-bold"> {{ plant.Critical.length }} {{ i18n.string('critical_equipments') }}</span></ng-template>
    </div>

    <div *ngIf="plant.Warning.length > 0 && !detail && !!plant && !plant.filtered" class="nz-multiple-status-reports">
      <nz-alert nzType="warning" class="nz-status-report noMargin" [class.center]="IsMobile"
        [nzMessage]="warningTemplate">
      </nz-alert>
      <ng-template #warningTemplate><i nz-icon nzType="icons:status-exclamation-octagon" class="fillorange"></i><span
          class="body-bold"> {{ plant.Warning.length }} {{ i18n.string('warning_equipments') }}</span></ng-template>
    </div>-->

    <ng-content *ngIf="!fine || detail"></ng-content>
    <div
      *ngIf="
        (plant.Critical.length > 0 ||
          plant.Warning.length > 0 ||
          plant.InActive.length > 0) &&
        !detail &&
        !!plant &&
        !plant.filtered
      "
    >
      <nz-alert
        nzType="success"
        class="nz-status-report"
        [class.center]="!isDesktop"
        [nzMessage]="otherfineTemplate"
        *ngIf="plant.Working.length > 0"
      >
      </nz-alert>
      <ng-template #otherfineTemplate
        ><i nz-icon nzType="icons:status-check" class="fillgreen"></i>
        <span *ngIf="plant.Working.length == 1" class="body-bold">
          {{ plant.Working.length }}
          {{ i18n.string("other_equipment_working_fine") }}</span
        >
        <span *ngIf="plant.Working.length > 1" class="body-bold">
          {{ plant.Working.length }}
          {{ i18n.string("other_equipments_working_fine") }}</span
        >
      </ng-template>
    </div>
    <app-nav-button
      *ngIf="!detail && !isDesktop && !!plant && !plant.filtered"
      primary="true"
      [asset]="plant"
      svg_icon="icons:plant"
      (click)="onNav()"
    >
      {{ i18n.string("see_whole_plant") }}</app-nav-button
    >
  </nz-card>
  <ng-template #plantheader>
    <div class="plant-header" *ngIf="!!plant && !plant.filtered">
      <nz-avatar
        *ngIf="!detail"
        [nzSrc]="
          plant['thumbnail']
            ? plant['thumbnail']
            : 'assets/images/plant_default_picture.png'
        "
        [nzSize]="isMobile || isTablet ? 40 : 48"
        nzText="Plant"
      ></nz-avatar>
      <h1
        *ngIf="!detail"
        class="plant-title"
        [class.mobile]="isMobile"
        [class.tablet]="isTablet"
      >
        {{ plant.objectName }} [{{
          plant.Assets.length -
            (showsArchived ? 0 : plant.Archived.length) -
            plant.AssetsWithoutSensors.length
        }}]
      </h1>
      <h2 *ngIf="detail" class="plant-title">
        {{ plant.objectName }}
        <span *ngIf="!isDisabled"
          >[{{
            plant.Assets.length -
              (showsArchived ? 0 : plant.Archived.length) -
              plant.AssetsWithoutSensors.length
          }}]</span
        >
      </h2>
      <button *ngIf="closeBtn" (click)="close.emit()" id="closeBtn">
        <i nz-icon [nzType]="'icons:exit'" class="svg-icon"></i>
      </button>
      <app-nav-button
        *ngIf="!detail && isDesktop"
        primary="true"
        [asset]="plant"
        svg_icon="icons:plant"
        (click)="onNav()"
        >{{ i18n.string("see_whole_plant") }}
      </app-nav-button>
    </div>
    <div *ngIf="plant.buildingPlan"
    class="file-notice"
    (click)="openBuildingPreview(plant.buildingPlan)"
  >
    <img
      nz-image
      [nzSrc]="'assets/images/building-plan.png'"
      alt="File Preview"
    />
    <span class="body-bold link-underline">{{
      i18n.string("view_building_plan") 
    }}</span>
  </div>
  </ng-template>
</div>

<nz-modal
  nzClassName="viewbuilding_modal"
  [class.desktop]="isDesktop"
  [class.mobile]="isMobile"
  [class.tablet]="isTablet"
  [(nzVisible)]="modalOpen"
  [nzFooter]="null"
  [nzCentered]="true"
  [nzCloseIcon]="'icons:exit'"
  (nzOnCancel)="cancelClick()"
  [nzTitle]="null"
>
<ngx-extended-pdf-viewer *ngIf="modalOpen" [src]="srclink" useBrowserLocale="true" [minifiedJSLibraries]="false" backgroundColor="#ffffff" 
 [showToolbar]="false" [height]="'60vh'"  [mobileZoom]="'100%'" [pageViewMode]="'all'"  [showBorders]="false">
</ngx-extended-pdf-viewer>
 <div class="text-center"> 
<button nz-button class="ant-btn ant-btn-round ng-star-inserted" (click)="openNewTab()">Open in new tab
  <i nz-icon [nzType]="svg_icon_top" class="svg-icon fillwhite"></i>
</button>
</div>
</nz-modal>

