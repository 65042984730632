import { Component, OnInit } from '@angular/core';

import { I18nService } from '../../../../services/i18n.service';

@Component({
  selector: 'app-repair-instructions',
  templateUrl: './repair-instructions.component.html',
  styleUrls: ['./repair-instructions.component.scss'],
})
export class RepairInstructionsComponent implements OnInit {
  public overviewCollapsed: boolean = true;
  public instructionsCollapsed: boolean = true;
  public applicationType: string = 'smart-flange_composite';
  constructor(public i18n: I18nService) {}

  ngOnInit() {}
}
