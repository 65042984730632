import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent implements OnInit {
  @Input() asset;
  @Input() primary = false;
  @Input() darksecondary = false;
  @Input() lighttertiary = false;
  @Input() href = undefined;
  @Input() disabled = false;
  @Input() svg_icon = '';
  @Input() mailto: string = null;
  @Input() phone: string = null;
  @Output() click = new EventEmitter<void>();
  constructor(public router: Router) {}

  ngOnInit() {}

  onClick(e) {
    if (!this.href) e.preventDefault();
    if (!this.disabled) {
      if (this.mailto) {
        window.open('mailto:' + this.mailto, '_self');
        return;
      }
      if (this.phone) {
        window.open('tel:' + this.phone, '_self');
        return;
      }
      // this.click.emit(); // TODO: check if this causes any issues
      if (this.asset)
        setTimeout(() => this.router.navigate(['plant', this.asset.objectID]));
    }
  }
}
