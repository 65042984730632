<div class="dialog" *ngIf="options" [class.dialog-error]="options.error">
  <div class="dialog-frame">
    <button class="dialog-close" *ngIf="options.hasCloseButton" (click)="onClose()">
      <i nz-icon nzType="icons:exit" class="svg-icon" aria-hidden="true"></i>
    </button>
    <div class="dialog-title">
      <h2>
        {{options.title}}
      </h2>
    </div>
    <div class="dialog-content">
      <p>
        {{options.message}}
      </p>
    </div>
    <div class="dialog-buttons">
      <button *ngIf="options.onYes" (click)="onYes()" i18n="confirm dialog @@dlgYes">Ja</button>
      <button *ngIf="options.onNo" (click)="onNo()"i18n="deny dialog @@dlgNo">Nein</button>
      <button *ngIf="options.onCancel"(click)="onCancel()"i18n="cancel dialog @@dlgCancel">Abbrechen</button>
    </div>
  </div>
</div>
