import {
  Directive,
  Input,
  ElementRef,
  SimpleChanges,
  Renderer2,
  OnChanges,
} from '@angular/core';

@Directive({
  selector: '[ccHighlight]',
})
export class HighlightDirective implements OnChanges {
  @Input() searchedWords: string[];
  @Input() text: string;
  @Input() colorToApply: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !this.searchedWords ||
      !this.searchedWords.length ||
      !this.colorToApply
    ) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.text);
      return;
    }

    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      this.getFormattedText()
    );
  }

  getFormattedText() {
    const re = new RegExp(`(${this.searchedWords.join('|')})`, 'g');

    return this.text.replace(
      re,
      `<span style="color: ${this.colorToApply}">$1</span>`
    );
  }
}
