import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { StorageService } from '../services/storage.service';

@Injectable()
export class AntiAuthGuard implements CanActivate {
  constructor(private router: Router, private storage: StorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let redirectArgs = null;
    try {
      redirectArgs = !!route.params['args']
        ? JSON.parse(atob(route.params['args']))
        : null;
    } catch (e) {
      redirectArgs = null;
      console.error(e);
    }

    if (!this.storage.Token || !this.storage.Token.length) {
      // not logged in so return true
      return true;
    }
    // logged in so redirect to list page (default landing page for logged in user)

    if (!!redirectArgs && !!redirectArgs.targetUrl)
      this.router.navigate([redirectArgs.targetUrl]);
    if (route.routeConfig.path.includes('imprint'))
      this.router.navigate(['settings', 'terms', 'imprint']);
    else this.router.navigate(['/list']);
    return false;
  }
}
