import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Asset } from '../../model/asset';
import { Location } from '../../model/location';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() set Asset(asset: Asset) {
    this.asset = asset;
    this.refresh();
  }
  @Input() telemetry = null;
  @Input() sensorID = null;
  @Input() sensorHealthPage: boolean = false;
  @Input() notificationsFilter: boolean = false;
  @Input() plantsFilter: boolean = false;
  @Input() plantFilterStatus = null;
  @Input() title: string = 'Navigation';
  @Input() titleicon: string = 'navigation';
  @Input() loading = false;
  private internal_loading = false;
  @Input() showFilter = false;
  @Input() showList = false;
  public asset = null;
  public building = null;
  public all_buildings = [];
  @Output() click: EventEmitter<void> = new EventEmitter<void>();
  plantSubscription = null;

  get Loading() {
    return this.loading || this.internal_loading;
  }

  get Buildings() {
    /*return !!this.building
      ? [this.building, ...this.other_buildings]
      : this.other_buildings;*/

    return this.all_buildings;
  }

  constructor(
    private assetsService: AssetsService,
    private changeRef: ChangeDetectorRef,
    public router: Router,
    public i18n: I18nService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
  }

  refresh() {
    if (this.asset) {
      this.internal_loading = true;
      if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
      this.plantSubscription = this.assetsService.subscribeToPlants(
        (result: any) => {
          this.internal_loading = false;
          this.building = result.plants
            .map((_plant) => _plant.children)
            .flat(2)
            .find(
              (building) =>
                typeof building.Assets.find(
                  (asset) => asset.objectID === this.asset.objectID
                ) !== 'undefined'
            );
          const plant = result.plants.find(
            (_plant) =>
              this.building && _plant.objectID === this.building.parentID
          );
          if (!plant)
            this.all_buildings = result.plants
              .filter((_plant) => !plant || _plant.objectID === plant.objectID)
              .map((_plant) => _plant.children)
              .flat(2)
              .filter((building) => building.Assets.length > 0);
          else
            this.all_buildings = plant.children.filter(
              (building) => building.Assets.length > 0
            );
          this.changeRef.detectChanges();
        }
      );
    }
  }

  sortAssociatedEquipment(assoc_eq: Location[]) {
    /*return assoc_eq.sort((a: Location, b: Location) => {
      if (a.Assets.find((asset) => asset.objectID === this.asset.objectID))
        return -1;
      if (b.Assets.find((asset) => asset.objectID === this.asset.objectID))
        return 1;
      return this.i18n
        .parse(a.objectName)
        .localeCompare(this.i18n.parse(b.objectName));
    });*/
    return assoc_eq;
  }

  isSensorHealthCrit(asset) {
    let crit = false;
    const health = asset.health.filter(
      // TODO: horrible hack, please remove!
      (telemetry) =>
        telemetry.measurementTypeID !== 1048 &&
        telemetry.measurementTypeID !== 1025 &&
        telemetry.measurementTypeID !== 1026
    );
    for (let i = 0; i < health.length; i++) {
      if (health[i].critical) {
        crit = true;
      }
    }
    return crit;
  }

  isSensorHealthWarning(asset) {
    let warning = false;
    const health = asset.health.filter(
      // TODO: horrible hack, please remove!
      (telemetry) =>
        telemetry.measurementTypeID !== 1048 &&
        telemetry.measurementTypeID !== 1025 &&
        telemetry.measurementTypeID !== 1026
    );
    for (let i = 0; i < health.length; i++) {
      if (health[i].warning) {
        warning = true;
      }
    }
    return warning;
  }

  isSensorHealthInacti(asset) {
    let inactive = false;
    const health = asset.health.filter(
      // TODO: horrible hack, please remove!
      (telemetry) =>
        telemetry.measurementTypeID !== 1048 &&
        telemetry.measurementTypeID !== 1025 &&
        telemetry.measurementTypeID !== 1026
    );
    for (let i = 0; i < health.length; i++) {
      if (health[i].inactive) {
        inactive = true;
      }
    }
    return inactive;
  }

  onClick() {
    this.click.emit();
  }

  toSensorValue(assetID, sensorID, measurementTypeID) {
    if (sensorID && measurementTypeID) {
      this.router.navigate([
        'sensor-value',
        assetID,
        sensorID,
        measurementTypeID,
      ]);
    }
  }

  toSensor(assetID, sensorID) {
    if (sensorID) {
      this.router.navigate(['asset', assetID, sensorID]);
    }
  }

  toSensorHealth(assetID) {
    this.router.navigate(['sensor-health', assetID]);
  }

  isLocationClickable(location: Location): boolean {
    return (
      !!location.curLayer &&
      location.curLayer !== 'a' &&
      location.curLayer !== 'b'
    );
  }

  navToLocation(location: Location) {
    if (!this.isLocationClickable(location)) return;
    if (location.isAsset)
      this.router.navigate(['asset', location.objectID, -1]);
    else this.router.navigate(['plant', location.objectID]);
  }
}
