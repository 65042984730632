<div class="content content-more-margin desktop-wide">
  <h1>{{ i18n.string("note_for_US_content") }}</h1>
  <p>
    <a
      class="link-underline"
      href="https://www.henkel-adhesives.com/us/en.html"
      target="_blank"
      >{{ i18n.string("website_link") }}</a
    >
  </p>
  <p>
    <a
      class="link-underline"
      href="https://www.henkel-adhesives.com/us/en.html"
      target="_blank"
      >{{ i18n.string("learn_more_link") }}</a
    >
  </p>
  <p>
    {{ i18n.string("note_website_content") }}
  </p>

  <div class="back-btn" *ngIf="!isAuth">
    <app-nav-button
      [darksecondary]="true"
      svg_icon="icons:arrow-left"
      (click)="gotoLogin()"
      >{{ i18n.string("back") }}
    </app-nav-button>
  </div>
  <app-scroll-to-top></app-scroll-to-top>
</div>
