<div class="wrapper">
  <div class="line red"></div>
  <div class="outer-circle red">
    <div class="inner-circle"><i nz-icon nzType="icons:check-bubble" class="svg-icon"></i></div>
  </div>
  <div class="line dark"></div>
  <div class="outer-circle dark">
    <div class="inner-circle"><span>2</span></div>
  </div>
  <div class="line"></div>
  <div class="outer-circle">
    <div class="inner-circle"><span>3</span></div>
  </div>
</div>
