<div class="searchfilterbar" [class.sidebar]="sidebar">
  <!-- Search -->
  <div *ngIf="!sidebar" class="search drop-shadow body-bold" [class.collapsed]="collapsed" [class.open]="!collapsed"
    [@expandCollapse]="collapsed ? 'collapsed' : 'expanded'"
    [class.activeSearch]="this.assetsService.getSearchTerm() != '' && this.assetsService.getSearchTerm() != null">
    <div class="search-input-area">
      <button class="close-btn" aria-label="Close Search" (click)="collapse()">
        <i nz-icon nzType="icons:exit" class="svg-icon fillwhite"></i>
      </button>
      <input enterkeyhint="search" #searchInput [placeholder]="i18n.string('find_equipment')" (focus)="$event.target.select()"
        oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
        (keyup)="search(false)" (keyup.enter)="search()" />
    </div>
    <button class="search-btn" aria-label="Search" (click)="collapsed ? expand() : search()">
      <i nz-icon
        *ngIf="!collapsed || this.assetsService.getSearchTerm() === '' || this.assetsService.getSearchTerm() === null"
        nzType="icons:search" class="svg-icon search-icon noMarginRight" [class.fillwhite]="!!collapsed"
        [class.fillblackcoral]="!collapsed"></i>
      <i nz-icon
        *ngIf="!!collapsed && this.assetsService.getSearchTerm() != '' && this.assetsService.getSearchTerm() != null"
        nzType="icons:search-active" class="svg-icon search-icon noMarginRight multicolorIcon"></i>
      <span *ngIf="!!collapsed">{{i18n.String('search')}}</span>
    </button>
    <!--<span *ngIf="!!collapsed && this.assetsService.getSearchTerm() != '' && this.assetsService.getSearchTerm() != null" class="verticalLine"></span>-->
    <button
      *ngIf="!!collapsed && this.assetsService.getSearchTerm() != '' && this.assetsService.getSearchTerm() != null"
      class="clear-btn" aria-label="Close Search" (click)="clearSearchTerm();">
      <i nz-icon nzType="icons:exit" class="svg-icon"></i>
    </button>
  </div>

  <div *ngIf="sidebar" class="search drop-shadow body-bold sidebar-search" [class.onlysearch]="onlysearch">
    <div *ngIf="!onlysearch" class="search-title">
      <i nz-icon nzType="icons:search" class="svg-icon"></i>
      <h4>{{i18n.String('search')}}</h4>
      <button *ngIf="onlysearch || (searchInput.value && searchInput.value != '')" class="clear-btn"
        aria-label="Clear Search Input" (click)="clearSearchTerm()">

        <i nz-icon *ngIf="!searchInput.value || searchInput.value == ''" nzType="icons:search" class="svg-icon"></i>
        <i nz-icon *ngIf="searchInput.value && searchInput.value != ''" nzType="icons:exit" class="svg-icon"></i>
        <span *ngIf="searchInput.value && searchInput.value != ''"
          class="body-bold">{{i18n.String('clear_search')}}</span>
      </button>
    </div>
    <div class="search-input-area" [class.searchFilled]="searchInput.value && searchInput.value != ''">
      <input #searchInput [placeholder]="i18n.string('find_equipment')" (focus)="$event.target.select()"
        oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
        (keydown)="checkUpdates()" />
      <button *ngIf="collapsed && searchInput.value && searchInput.value != ''" class="clear-btn"
        aria-label="Close Search" (click)="clearSearchTerm();">
        <i nz-icon nzType="icons:exit" class="svg-icon fillwhite"></i>
      </button>
    </div>
  </div>

  <!-- Filter -->
  <button *ngIf="!sidebar && collapsed" class="filter drop-shadow body-bold" (click)="filter_open = true"
    [class.active-filter]="filterCount > 0">
    <i nz-icon nzType="icons:filter" class="svg-icon fillwhite" *ngIf="filterCount === 0"></i>
    <nz-badge *ngIf="filterCount > 0" [nzCount]="filterCount" nzStandalone
      [nzStyle]="{ backgroundColor: '#5F6973', color: 'white'}"></nz-badge>
    <span>{{i18n.String('filter')}}</span>
    <i nz-icon nzType="icons:exit" class="svg-icon clear-btn" *ngIf="filterCount > 0" (click)="clearFilter($event)"></i>
  </button>

  <div *ngIf="sidebar && !onlysearch" class="filter sidebar-filter">
    <app-filter (close)="filter_open = false" [sidebar]="true" [notificationsFilter]="notificationsFilter"
      [plantsFilter]="plantsFilter" [CountryOrRegionSelected]="countryOrRegionSelected" 
      [plantFilterStatus]="plantFilterStatus">
    </app-filter>
  </div>
</div>

<nz-modal [(nzVisible)]="filter_open" (nzOnCancel)="filter_open = false" [nzFooter]="null" nzTitle="Filter"
  [nzCloseIcon]="'icons:exit'">
  <app-filter (close)="filter_open = false" [submit_btn]="true" [notificationsFilter]="notificationsFilter"
    [plantsFilter]="plantsFilter" [CountryOrRegionSelected]="countryOrRegionSelected" 
    [plantFilterStatus]="plantFilterStatus" [plantID]="plantID">
  </app-filter>
</nz-modal>