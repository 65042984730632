import { ElementRef } from '@angular/core';
import * as d3 from 'd3';

import { UserSettings } from '../../model/settings';
import { I18nService } from '../../services/i18n.service';
import { ValueRange, ValueRangeType } from './value-range';
import { Visualization } from './visualization.interface';

const LOCALE_EN = {
  dateTime: '%x, %X',
  date: '%-m/%-d/%Y',
  time: '%-I:%M:%S %p',
  periods: ['AM', 'PM'],
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

const LOCALE_DE = {
  dateTime: '%A, der %e. %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
};

export enum BarChartType {
  Hour = 0,
  Day,
  Week,
  Month,
  Year,
}

export class BarChart implements Visualization {
  public type: BarChartType = BarChartType.Month;
  public original_type: BarChartType = BarChartType.Month;
  private x_domain = null; // this defines the section of the historical data we want to see
  private y_domain = null; // this defines the section of the values we are interested in
  private valueRanges: ValueRange[];
  private y_unit: string = '';
  private customRange: boolean = false;
  // TODO hardcoded for dev purposes

  private data: any[];
  private original_data: any[];
  public updateChart;
  private resetBrush;
  private brushCallback;
  private brushResetCallback;

  private chartContainer: ElementRef;
  private chartContainerFixed: ElementRef;
  private scrollable: any;

  constructor(
    private chartContainers: ElementRef[],
    private id: string,
    private i18n: I18nService
  ) {
    this.chartContainer = chartContainers[0];
    this.chartContainerFixed = chartContainers[1];
    this.scrollable = chartContainers[2];
  }

  public render(
    data: {
      measuredValue: string;
      measurementTypeID: number;
      timestamp: Date;
      value: number;
    }[],
    isMobile,
    settings: UserSettings
  ) {
    if (!!data) {
      this.original_data = data;
      this.data = this.groupData(data, this.type);
      let min_value = Infinity;
      let max_value = -Infinity;
      for (let i = 0; i < this.data.length; i++) {
        const val = this.data[i].value;
        if (val < min_value) min_value = val;
        if (val > max_value) max_value = val;
      }
      min_value = Math.floor(min_value / 500) * 500;
      max_value = Math.max(
        Math.ceil(max_value / 500) * 500,
        1000,
        min_value + 500
      );
      this.y_domain = [min_value, max_value];
    }

    this.renderGraph(isMobile, settings);
  }

  private groupData(
    data: {
      measuredValue: string;
      measurementTypeID: number;
      timestamp: Date;
      value: number;
    }[],
    type: BarChartType
  ): {
    measuredValue: string;
    measurementTypeID: number;
    timestamp: Date;
    date: Date;
    value: number;
  }[] {
    // tslint:disable:no-switch-case-fall-through
    function groupBy(xs, transform) {
      return xs.reduce(function (rv, x) {
        (rv[transform(x)] = rv[transform(x)] || []).push(x);
        return rv;
      }, {});
    }
    const groupTemp = this.fillGaps(
      groupBy(data, (x) => pruneTimestamp(x.timestamp, type, true)),
      type
    );

    return Object.keys(groupTemp)
      .map((key) =>
        groupTemp[key].reduce(
          (a, b) =>
            new Object({
              measuredValue: '',
              measurementTypeID: b.measurementTypeID,
              timestamp: !!a.timestamp
                ? a.timestamp
                : pruneTimestamp(b.timestamp, type, true),
              date: !!a.timestamp
                ? a.timestamp
                : pruneTimestamp(b.timestamp, type, true),
              value:
                Math.round((a.value + b.value + Number.EPSILON) * 100) / 100,
            }),
          {
            measuredValue: '',
            measurementTypeID: null,
            timestamp: null,
            date: null,
            value: 0,
          }
        )
      )
      .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  }

  fillGaps(data: object, type: BarChartType) {
    // this fills gaps in the data with empty arrays in order to not display empty graphs
    const _d3 = d3;
    const scale = d3.scaleTime();
    const dates = Object.keys(data)
      .map((item) => new Date(item))
      .sort((a: Date, b: Date) => +a - +b);
    const min = dates[0];
    const max = dates[dates.length - 1];
    let full_range = [];
    switch (type) {
      case BarChartType.Hour:
        full_range = d3.timeHours(min, max);
        break;
      case BarChartType.Day:
        full_range = d3.timeDays(min, max);
        break;
      case BarChartType.Week:
        full_range = d3
          .timeDays(min, max)
          .filter((date) => date.getDay() === 1);
        break;
      case BarChartType.Month:
        full_range = d3.timeMonths(min, max);
        break;
      case BarChartType.Year:
        full_range = d3.timeYears(min, max);
        break;
    }

    full_range.map((date) => {
      date = pruneTimestamp(date, type, true);
      if (!data[date.toString()]) {
        data[date.toString()] = [
          {
            measurementTypeID: data[min.toString()].measurementTypeID,
            value: 0,
            measuredValue: '',
            timestamp: date.toISOString(),
          },
        ];
      }
    });
    return data;
  }

  public SetValueUnit(unit: string) {
    this.y_unit = unit;
  }

  public SetXDomain(
    start: Date,
    end: Date,
    custom: boolean = false,
    unbrush: boolean = false
  ) {
    // set right graph cutoff to midnight of the current day (or next day 00:00):
    if (!custom && (end.getHours() > 0 || end.getMinutes() > 0)) {
      end = new Date(new Date(end).setDate(end.getDate()));
    }
    if (!custom) start = new Date(start.toDateString());
    if (!custom) end = new Date(end.toDateString());
    if (daysBetween(start, end) < 15) {
      this.type = BarChartType.Day;
    } else if (daysBetween(start, end) < 42) {
      this.type = BarChartType.Week;
    } else {
      this.type = BarChartType.Month;
    }
    if (!unbrush) {
      start = pruneTimestamp(start, this.type);
      end = pruneTimestamp(end, this.type, false, true);
    }
    /*switch (this.type) {
      case BarChartType.Day:
        start.setHours(start.getHours() - 12);
        end.setHours(end.getHours() + 12);
        break;
      case BarChartType.Week:
        start.setDate(start.getDate() - 3);
        end.setDate(end.getDate() + 3);
        break;
      case BarChartType.Month:
        start.setDate(start.getDate() - 15);
        end.setDate(end.getDate() + 15);
        break;
      case BarChartType.Year:
        start.setMonth(start.getMonth() - 6);
        end.setMonth(end.getMonth() + 6);
        break;
    }*/
    this.x_domain = d3.extent([start, end], (d) => d);
    this.customRange = custom;
    if (!!this.updateChart) this.updateChart();
  }
  public ResetXDomain() {
    this.x_domain = null;
  }
  public SetYDomain(
    domain: [number, number],
    booleanDomain: boolean = false,
    measurementID: number
  ) {
    // this.y_domain = domain;
  }
  public ResetYDomain() {
    this.y_domain = null;
  }
  public SetValueRanges(ranges: ValueRange[]) {
    this.valueRanges = ranges;
  }

  private renderGraph(isMobile: boolean, settings: UserSettings) {
    d3.select('svg#' + this.id).remove();
    d3.select('svg#' + this.id + '-fixed').remove();
    const max_width = this.chartContainer.nativeElement.clientWidth;
    const max_height = this.chartContainer.nativeElement.clientHeight;
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1;

    // display a maximum of 10 ticks at once:
    const SMALLER_TICK_THRESHOLD = 8;
    // const MAX_TICKS = 10;
    const MAX_TICKS_WIDE_SCREEN = 10;
    const MAX_TICKS_NARROW_SCREEN = 8;
    let tick_factor;
    if (max_width > 375) {
      tick_factor =
        this.data.length > MAX_TICKS_WIDE_SCREEN
          ? this.data.length / MAX_TICKS_WIDE_SCREEN
          : 1.0;
    } else {
      tick_factor =
        this.data.length > MAX_TICKS_NARROW_SCREEN
          ? this.data.length / MAX_TICKS_NARROW_SCREEN
          : 1.0;
    }
    // let tick_factor =
    //   this.data.length > MAX_TICKS ? this.data.length / MAX_TICKS : 1.0;
    // tick factor = actual number of ticks / maximum number of ticks
    // (but never smaller than 1)

    // set the dimensions and margins of the graph
    const margin = {
        top: 25 + 30, // +30 for focus value
        right: 65,
        bottom: 71,
        left: 0,
      },
      width = max_width - margin.left - margin.right,
      // a maximum of 10 ticks at once
      height = max_height - margin.top - margin.bottom;
    let total_width = width * tick_factor; // multiply width by tick factor to get the total width, so that we only display

    // on mobile renderGraph() sometimes seems to get called with too small clientHeights
    // since it gets called again later with the correct height, we can skip further execution for now
    if (height < 0) return;

    let scrollArea,
      fixedArea,
      xAxis,
      yAxis,
      yearAxis, // second y axis only showing the current year
      clipMask,
      focusLine,
      // focusDate, // date under the vertical line on the right most side of the chart
      // focusDateBG,
      focusValue,
      // focusValueLine,
      svgBarsRects;
    // focusValueBG;

    let unique_identifier,
      element, // html element containing the chart
      elementFixed, // html element containing the fixed elements of the chart
      x, // domain function for determining pixels from x domain
      y, // domain function for determining pixels from y domain
      x_extent, // x domain extent as array [min,max]
      y_extent, // y domain extent as array [min,max]
      bisect, // gets the next x value in the dataset from a given x value (date)
      currentData, // is set to the right most data point
      sx, // horizontal pixel position of the right most data point
      sy, // vertical pixel position of the right most data point
      warning, // whether right most data point is in a warning range
      critical, // whether right most data point is in a critical range
      noData; // true if we literally don't have any data;

    const drawChart = () => {
      init();
      drawAxes();
      drawClipmask();
      drawHorizontalLines();
      if (!noData) drawRanges();
      if (!noData) drawBars();
      if (!noData) drawFocus();
      initBrushing();
      if (noData) drawNoDataIndicator();
    };

    const init = () => {
      unique_identifier = generateUniqueIdentifier();
      element = this.chartContainer.nativeElement;
      elementFixed = this.chartContainerFixed.nativeElement;
      noData = !this.data || this.data.length < 1;
      // avoid errors
      if (noData) {
        this.data = [
          {
            date: new Date(new Date().setDate(new Date().getDate() - 7)),
            value: 0,
            text: '0',
          },
          { date: new Date(), value: 100, text: '100' },
        ];
      }
      x_extent = d3.extent(this.data, (d) => {
        return d.date;
      });
      if (!this.x_domain) this.x_domain = [x_extent[0], x_extent[1]];
      y_extent = !!this.y_domain
        ? this.y_domain
        : [
            d3.min(this.data, (d) => {
              return +d.value;
            }),
            d3.max(this.data, (d) => {
              return +d.value;
            }),
          ];

      x = d3
        .scaleTime()
        .domain(x_extent)
        .range([20, total_width - 20]);

      y = d3.scaleLinear().domain(y_extent).range([height, 0]);

      bisect = (data, _x) => {
        const bisect_right = d3.bisector((d) => {
          return d.date;
        }).right;
        const right = bisect_right(data, _x);
        if (right === 0) return right;
        const left = right - 1;
        if (!data[right]) return left;
        const diff1 = _x - data[left].date;
        const diff2 = data[right].date - _x;
        return diff1 < diff2 ? left : right;
      };

      currentData = this.data[bisect(this.data, x_extent[1])];
      sx = x(currentData.date);
      sy = y(currentData.value);

      warning = !!this.valueRanges.find(
        (range) =>
          range.type === ValueRangeType.yellow &&
          range.min <= currentData.value &&
          range.max >= currentData.value
      );
      critical = !!this.valueRanges.find(
        (range) =>
          range.type === ValueRangeType.red &&
          range.min <= currentData.value &&
          range.max >= currentData.value
      );
      initSVG();
    };

    const initSVG = () => {
      // append the svg object to the body of the page
      element.innerHTML = '';
      fixedArea = d3
        .select(elementFixed)
        .append('svg')
        .attr('id', this.id + '-fixed')
        .attr('width', max_width)
        .attr('height', max_height)
        .style('position', 'absolute')
        .style('pointer-events', 'none')
        .style('z-index', 2);
      scrollArea = d3
        .select(element)
        .append('svg')
        .attr('id', this.id)
        .attr('width', total_width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      this.scrollable.scrollTo({ right: 0 });
      // svg.on('click', mousemove);

      // filters go in defs element
      const defs = scrollArea.append('defs');

      const filter = defs
        .append('filter')
        .attr('id', 'pill-gradient')
        .attr('height', '1000%')
        .attr('width', '1000%')
        .attr('x', '-500%')
        .attr('y', '-500%');

      filter
        .append('feColorMatrix')
        .attr('type', 'matrix')
        .attr('values', '0 0 0 1 0  0 0 0 1 0  0 0 0 1 0  0 0 0 1 0');

      filter
        .append('feGaussianBlur')
        .attr('stdDeviation', 2)
        .attr('result', 'coloredBlur');

      const feMerge = filter.append('feMerge');

      feMerge.append('feMergeNode').attr('in', 'coloredBlur');
      //    .attr("in", "SourceAlpha");
    };

    const drawClipmask = () => {
      // Add a clipPath: everything out of this area won't be drawn.
      scrollArea
        .append('defs')
        .append('svg:clipPath')
        .attr('id', 'bar-clip-' + this.id)
        .append('svg:rect')
        .attr('width', total_width)
        .attr('height', height + 10)
        .attr('x', 0)
        .attr('y', -5);

      // Create the line variable: where both the line and the brush take place
      clipMask = scrollArea
        .append('g')
        .attr('clip-path', 'url(#bar-clip-' + this.id + ')');
    };

    const drawAxes = () => {
      scrollArea
        .append('rect')
        .style('fill', 'rgb(255, 255, 255)')
        .style('cursor', 'pointer')
        .attr('x', -1)
        .attr('y', 0)
        .attr('width', width + 1)
        .attr('height', height);
      if (!noData) {
        xAxis = scrollArea
          .append('g')
          .attr('id', 'x-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height + 12)
              .tickFormat((d) => {
                return '';
              })
          )
          .call((g) => g.selectAll('.tick line').remove())
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', 12)
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr('transform', 'translate(0,-3)')
          );
        yearAxis = scrollArea
          .append('g')
          .attr('id', 'year-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height + 28)
              .ticks(d3.timeYear)
              .tickPadding(15)
              .tickFormat((d) => {
                return d3.timeFormat('%Y')(d);
              })
          )
          .call((g) => g.selectAll('.tick line').remove)
          .call((g) => g.select('.domain').remove())
          .call((g) => g.selectAll('.tick text').attr('color', '#afb4b9'));
      }

      const false_icon =
        '<i nz-icon nzType="icons:status-check" class="mini-svg-icon fillgreen"></i>';
      const true_icon =
        '<i nz-icon nzType="icons:status-exclamation-triangle" class="mini-svg-icon fillwhite"></i>';

      yAxis = fixedArea
        .append('g')
        .attr('id', 'y-axis')
        .attr('transform', 'translate(' + width + ', ' + margin.top + ')')
        .call(
          d3
            .axisRight(y)
            .tickValues([this.y_domain[0], this.y_domain[1]])
            .tickSize(-total_width, 0, 0)
            .tickPadding(12)
            .tickFormat((d) => {
              return d + ' ' + this.y_unit;
            })
        )
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').attr('stroke', '#e6e7e7'))
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', 4)
            .attr('color', '#afb4b9')
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('text-anchor', 'end')
            .attr('dx', 35)
        );
      // the following code is a gradient to make the overflow on the right side of the graph look nicer when scrolling to the left
      // it is removed for now though, because it is not displayed correctly in iOS for some reason
      /*
      yAxis
        .insert('rect', ':first-child')
        .style('width', '170px')
        .style('height', '214px')
        .attr('fill', 'url(#fixedGradient' + unique_identifier + ')')
        .style('transform', 'translate(-85px, -10px)'); // To Do: correct dimensions

      const gradient = fixedArea
        .append('def')
        .append('linearGradient')
        .attr('id', 'fixedGradient' + unique_identifier);

      gradient.append('stop').attr('offset', '5%').attr('stop-color', '#FFF0');
      gradient.append('stop').attr('offset', '65%').attr('stop-color', '#FFFF');
      */
      fixedArea.select('.axis').selectAll('text').remove();
      scrollArea.select('.axis').selectAll('text').remove();
    };
    let unbrushed_domain = null;
    const initBrushing = () => {
      // Add brushing
      clipMask.selectAll('.brush').remove(); // remove old clip mask if this is called again (necessary in update)
      const brush = d3
        .brushX() // Add the brush feature using the d3.brush function
        .extent([
          [0, -6],
          [total_width, height + 12],
          // making the brushing taller than the clipmask hides the top and bottom strokes so that we only have
          // solid blue lines at the left and right
        ])
        // initialise the brush area: start at 0,0 and finishes at sx,height: it means I select the whole graph area
        .on('end', () => {
          if (!d3.event.sourceEvent) return;
          const extent = d3.event.selection;
          const epsilon =
            0.008 * Math.abs(+this.x_domain[1] - +this.x_domain[0]);
          if (
            true || // deactivate brushing
            !extent ||
            !extent[0] ||
            Math.abs(+x.invert(extent[1]) - +x.invert(extent[0])) < epsilon
          ) {
            mousemove(!!extent ? extent[0] : d3.event.sourceEvent.offsetX);
          } else {
            if (minutesBetween(x.invert(extent[0]), x.invert(extent[1])) > 60) {
              if (!unbrushed_domain) {
                unbrushed_domain = this.x_domain;
              }
              this.SetXDomain(x.invert(extent[0]), x.invert(extent[1]), true);
              if (!!this.brushCallback) this.brushCallback(this.x_domain);
            }
          }
          // This remove the grey brush area as soon as the selection has been done
          setTimeout(() => scrollArea.select('.brush').call(brush.move, null));
        });

      this.resetBrush = () => {
        if (!!unbrushed_domain)
          this.SetXDomain(unbrushed_domain[0], unbrushed_domain[1]);

        unbrushed_domain = null;
        if (!!this.brushResetCallback) this.brushResetCallback(this.x_domain);

        updateFocusline(
          that.data[bisect(that.data, that.x_domain[1])].date,
          true
        );

        unbrushed_domain = null;
      };
      scrollArea.on('dblclick', this.resetBrush);
      // Add the brushing
      clipMask.append('g').attr('class', 'brush').call(brush);
      clipMask.selectAll('.selection').attr('fill-opacity', 1);
    };
    const drawHorizontalLines = () => {
      /*clipMask // appends to line object for clipping purposes
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', y(0))
        .attr('y2', y(0))
        .attr('stroke', '#979b9b');
      clipMask
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', height)
        .attr('y2', height)
        .attr('stroke', '#e6e7e7');*/
      fixedArea
        .append('line')
        .attr('x1', 0)
        .attr('x2', max_width)
        .attr('y1', height + 31 + margin.top)
        .attr('y2', height + 31 + margin.top)
        .attr('stroke', '#e6e7e7');
    };
    const drawNoDataIndicator = () => {
      fixedArea
        .append('text')
        .html('No data')
        .attr('y', 16 + +margin.top)
        .attr('x', 36)
        .style('opacity', 0.6);
    };
    const drawRanges = () => {
      for (const range of this.valueRanges) {
        if (range.type === ValueRangeType.red) {
          // currently, we only display critical range
          if (critical) {
            clipMask
              .append('rect')
              .style('fill', '#E1000F')
              .style('cursor', 'pointer')
              .style('opacity', 0.05)
              .attr('x', 0)
              .attr('y', y(range.max))
              .attr('width', width - margin.right)
              .attr('height', y(range.min) - y(range.max));
          }
          if (range.min > y_extent[0])
            clipMask
              .append('line')
              .attr('x1', 0)
              .attr('x2', width)
              .attr('y1', y(range.min))
              .attr('y2', y(range.min))
              .attr('stroke', '#E1000F')
              .style('cursor', 'pointer');
          if (range.max < y_extent[1])
            clipMask
              .append('line')
              .attr('x1', 0)
              .attr('x2', width)
              .attr('y1', y(range.max))
              .attr('y2', y(range.max))
              .attr('stroke', '#E1000F')
              .style('cursor', 'pointer');
        }
      }

      const gradient_test = d3.range(100).map((d) => ({
        offset: d + '%',
        color: d3.interpolateRainbow(d / 100),
      }));

      // Set the gradient
      clipMask
        .append('linearGradient')
        .attr('id', 'line-gradient' + unique_identifier)
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', y(y_extent[0]))
        .attr('x2', 0)
        .attr('y2', y(y_extent[1]))
        .selectAll('stop')
        // .data(gradient_test)
        .data(generateCriticalGradient(this.valueRanges, y_extent, critical))
        .enter()
        .append('stop')
        .attr('offset', (d) => {
          return d.offset;
        })
        .attr('stop-color', (d) => {
          return d.color;
        });
    };

    const drawBars = () => {
      if (!!svgBarsRects) {
        clipMask.selectAll('.bar').attr('class', 'old bar');
      } else {
      }
      const svgBars = clipMask
        .append('g')
        .selectAll('this_is_empty')
        .data(this.data)
        .enter();
      // setTimeout(() => oldBars.remove(), 1000);

      svgBarsRects = svgBars
        .append('rect')
        .attr('class', 'bar')
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('x', (d) => x(d.date) - 10)
        .attr('y', (d) => Math.min(height - 1, y(d.value)))
        .attr('width', 21)
        .attr('height', (d) => height - Math.min(height - 1, y(d.value)))
        .attr('stroke', 'none')
        .attr('fill', (d) => '#C4C4C4');
    };

    const drawFocus = () => {
      if (noData) return;
      focusLine = scrollArea
        .append('g')
        .append('line')
        .attr('x1', sx)
        .attr(
          'y1',
          y(
            this.y_domain
              ? this.y_domain[1]
              : d3.max(this.data, (d) => {
                  return +d.value;
                })
          ) - 8
        )
        .attr('x2', sx)
        .attr(
          'y2',
          y(
            this.y_domain
              ? this.y_domain[0]
              : d3.min(this.data, (d) => {
                  return +d.value;
                })
          )
        )
        .attr('stroke', 'black')
        .style('cursor', 'pointer');
      /*
      focusDateBG = svg
        .append('rect')
        .style('fill', 'white')
        .attr('x', sx)
        .attr('y', height + 7)
        .attr('width', 35)
        .attr('height', 12)
        .style('filter', 'url(#pill-gradient)')
        .attr('rx', '3')
        .attr('ry', '3')
        .style('transform', 'translate(-18px, 0px)');
      focusDate = svg
        .append('text')
        .html(
          d3.timeFormat(getTimeFormat(that.type))(
            this.data[this.data.length - 1].date
          )
        )
        .attr('font-size', 12)
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', 18)
        .attr('letter-spacing', 0)
        .attr('dy', -4)
        .attr('class', 'brushText2Time')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('y', height + (isSafari ? 16 : 21))
        .attr('x', sx);

      focusValueBG = svg
        .append('rect')
        .style('fill', 'white')
        .attr('x', width)
        .attr('y', sy)
        .attr('width', 46)
        .attr('height', 12)
        .attr('opacity', 0)
        .style('filter', 'url(#pill-gradient)')
        .attr('rx', '3')
        .attr('ry', '3');
*/
      focusValue = scrollArea
        .append('text')
        .html(that.data[that.data.length - 1].value + this.y_unit)
        .attr('x', sx)
        .attr('y', 0)
        .attr('fill', 'black')
        .attr('font-size', 14)
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', 18)
        .attr('letter-spacing', 0)
        .attr('text-anchor', 'middle')
        .attr('dy', -12);
      /* focusValueLine = svg
        .append('line')
        .attr('x1', sx)
        .attr('x2', width - 5)
        .attr('y1', sy)
        .attr('y2', sy)
        .attr('stroke', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75');*/
    };

    this.updateChart = () => {
      if (this.original_type !== this.type) {
        if (!noData) {
          this.data = this.groupData(this.original_data, this.type);
          // multiply width by tick factor to get the total width, so that we only display

          drawBars();
        }
        this.original_type = this.type;
      }
      const tick_num = this.data.filter(
        (d) =>
          d.timestamp >= this.x_domain[0] && d.timestamp <= this.x_domain[1]
      ).length;
      // tick_factor = tick_num > MAX_TICKS ? tick_num / MAX_TICKS : 1.0;
      if (max_width > 375) {
        tick_factor =
          tick_num > MAX_TICKS_WIDE_SCREEN
            ? tick_num / MAX_TICKS_WIDE_SCREEN
            : 1.0;
      } else {
        tick_factor =
          tick_num > MAX_TICKS_NARROW_SCREEN
            ? tick_num / MAX_TICKS_NARROW_SCREEN
            : 1.0;
      }
      if (total_width !== tick_factor * width) {
        updateScrollbar(tick_factor);
      }
      this.scrollable.scrollTo({ right: 0 });

      // update the chart for given boundaries / x domain
      if (!noData) updateXAxis();
      if (!noData) updateGanttBars();
      initBrushing(); // otherwise brushing area will be below newly created gantt bars
      mouseout();
    };

    const updateGanttBars = () => {
      clipMask
        .selectAll('.bar')
        .transition()
        .duration(1000)
        .attr('x', (d) => x(d.date) - 10);
    };

    const that = this;
    let selectedData;

    function mousemove(_x?: any) {
      if (noData) return;
      if (!_x && !this) return;
      // (do not select data left of margin.left, because this looks weird)
      updateFocusline(x.invert(_x ? _x : d3.mouse(this)[0]));
    }

    function mouseout() {
      if (noData) return;
      updateFocusline(
        that.data[bisect(that.data, that.x_domain[1])].date,
        true
      );
      // focusValue.attr('opacity', 0);
      // focusValueBG.attr('opacity', 0);
      // focusValueLine.attr('opacity', 0);
    }

    function updateFocusline(x0, reset = false) {
      if (noData) return;
      const left_margin = 18;
      const right_margin = 25;
      const temp_data = that.data.filter(
        (item) => item.date > that.x_domain[0] && item.date < that.x_domain[1]
      );
      if (temp_data.length === 0) return;
      const i = bisect(temp_data, x0.getTime());
      selectedData = temp_data[i];
      let _sx = x(selectedData.date);
      if (_sx < 0) _sx = 0;
      if (_sx > total_width) _sx = total_width;
      let tx = _sx;
      if (tx < left_margin) tx = left_margin;
      if (tx > total_width - right_margin) tx = total_width - right_margin;
      if (selectedData) {
        focusLine
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x1', _sx)
          .attr('x2', _sx);
        sy = y(selectedData.value);
        svgBarsRects
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', (d) => x(d.date) - 10)
          .attr('fill', (d) =>
            selectedData.date === d.date
              ? '#5F6973'
              : d.date < that.x_domain[0]
              ? '#C4C4C400'
              : '#C4C4C4'
          );
        clipMask
          .selectAll('.old')
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', (d) => x(d.date) - 10)
          .attr('fill', (d) =>
            selectedData.date === d.date ? '#5F697300' : '#C4C4C400'
          );
        setTimeout(
          () => {
            clipMask.selectAll('.old').remove();
          },
          reset ? 1000 : 500
        );
        xAxis
          .transition()
          .duration(1000)
          .call((g) =>
            g
              .selectAll('.tick text')
              .attr('color', (d) =>
                selectedData.date === d ? '#5F6973' : '#C4C4C4'
              )
          );
        /*focusDate
          .html(d3.timeFormat(getTimeFormat(that.type))(selectedData.date))
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', _sx);

        focusDateBG
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', _sx);*/
        focusValue
          .html(selectedData.value + ' ' + that.y_unit)
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', _sx);
      }
    }

    function formatDate(date) {
      const o_date_en = new Intl.DateTimeFormat('en');
      return o_date_en.format(date);
    }

    const updateScrollbar = (tick_factor) => {
      // this function updates the total_width value which determines the scrollable area
      total_width = tick_factor * width;

      x.range([20, total_width - 20]);
      d3.select(element)
        .select('svg')
        .attr('width', total_width + margin.left + margin.right)
        .style('width', total_width + margin.left + margin.right + 'px');
      scrollArea
        .select('#bar-clip-' + this.id)
        .select('rect')
        .attr('width', total_width);
    };
    const updateXAxis = () => {
      if (this.x_domain) {
        x.domain(this.x_domain);
      }

      x.range([20, total_width - 20]);

      if (!this.customRange) unbrushed_domain = null;

      const tickCall = generateXTicks(this.x_domain[0], this.x_domain[1]);
      xAxis

        .transition()
        .duration(1000)
        .call(tickCall)
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('color', '#afb4b9')
            .attr('font-size', isMobile ? '12px' : '14px')
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', '18px')
            .attr('letter-spacing', 0)
            .attr('transform', 'translate(0,-3)')
        );
      xAxis.call((g) => g.selectAll('.tick line').remove());
      const yearTransitionInRange =
        this.x_domain[0].getFullYear() !== this.x_domain[1].getFullYear();
      yearAxis
        .transition()
        .duration(1000)
        .call(
          d3
            .axisBottom(x)
            .tickSize(height + (isSafari ? 30 : 28))
            .tickValues(
              yearTransitionInRange
                ? d3.timeYear.range(this.x_domain[0], this.x_domain[1])
                : [
                    new Date(
                      (this.x_domain[0].getTime() +
                        this.x_domain[1].getTime()) /
                        2
                    ),
                  ]
            )
            .tickPadding(15)
            .tickFormat((d) => {
              return !noData ? d3.timeFormat('%Y')(d) : '';
            })
        )
        .call((g) => g.selectAll('.tick line').attr('stroke', 'transparent'))
        .call((g) => g.select('.domain').remove())
        .call(
          (g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', 12)
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr(
                'dx',
                yearTransitionInRange ? 0 : (margin.right - margin.left) / 2
              ) // this centers the year in the wrapper when the year tick is not placed at the year transition
        )
        .call((g) =>
          g.selectAll('.tick:last-child text').attr('color', 'black')
        );
    };
    function generateXTicks(start, end) {
      const tick_num = that.data.filter(
        (d) =>
          d.timestamp >= that.x_domain[0] && d.timestamp <= that.x_domain[1]
      ).length;
      const small_screen1 = width < 450 || tick_num > SMALLER_TICK_THRESHOLD;
      const small_screen2 = width < 350 || tick_num > SMALLER_TICK_THRESHOLD;

      const locale = d3.timeFormatLocale(
        settings.Language.abbreviation === 'de' ? LOCALE_DE : LOCALE_EN
      );

      const tickValuesRangeEnd = minDate(
        new Date(that.data[that.data.length - 1].date),
        end
      );
      const tickValuesRangeStart = start;
      let tickFormat = (d) => {
        return locale.format(
          getTimeFormat(that.type, small_screen1, small_screen2, settings)
        )(d);
      };
      const tickValues = that.data
        .map((item) => item.date)
        .filter(
          (item: Date) =>
            item.getTime() > tickValuesRangeStart.getTime() &&
            item.getTime() <= tickValuesRangeEnd.getTime()
        );
      if (noData) {
        tickFormat = (d) => '';
      }
      const tickCall = d3
        .axisBottom(x)
        .tickSize(height)
        .tickValues(tickValues)
        .tickPadding(12)
        .tickFormat(tickFormat);

      return tickCall;
    }

    /*function generateYTicks(start, end) {
      const tickValues = that.data
        .map((item) => item.date)
        .filter(
          (item: Date) =>
            item.getTime() > tickValuesRangeStart.getTime() &&
            item.getTime() <= tickValuesRangeEnd.getTime()
        );
      if (noData) {
        tickFormat = (d) => '';
      }
      const tickCall = d3
        .axisBottom(x)
        .tickSize(height)
        .tickValues(tickValues)
        .tickPadding(12)
        .tickFormat(tickFormat);

      return tickCall;
    }*/
    drawChart();
    setTimeout(() => this.updateChart(), 0);
  }

  public OnBrush(callback: (x_domain: any) => void) {
    // do nothing
    this.brushCallback = callback;
  }

  public OnBrushReset(callback: (x_domain: any) => void) {
    // do nothing
    this.brushResetCallback = callback;
  }

  public ResetBrush() {
    // do nothing
  }
}

function treatAsUTC(date): number {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return +result;
}
function daysBetween(startDate, endDate): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

function hoursBetween(startDate, endDate): number {
  const millisecondsPerHour = 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerHour;
}

function minutesBetween(startDate, endDate): number {
  const millisecondsPerMinute = 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerMinute;
}

function minDate(a: Date, b: Date) {
  return a < b ? a : b;
}

function generateUniqueIdentifier() {
  const _number = Math.random(); // 0.9394456857981651
  _number.toString(36); // '0.xtis06h6'
  return _number.toString(36).substr(2, 9); // 'xtis06h6'
}

function getTimeFormat(
  type: BarChartType,
  small_screen1: boolean = false,
  small_screen2: boolean = false,
  settings
) {
  // TODO: other formats for other types
  switch (type) {
    case BarChartType.Hour:
      return '%H:%M';
    case BarChartType.Day:
      return small_screen2
        ? '%d'
        : !!settings.DateFormat &&
          settings.DateFormat.formatString === 'MM/dd/yyyy'
        ? '%m/%d'
        : '%d-%m';
    case BarChartType.Week:
      return (settings.Language.abbreviation === 'de' ? 'KW' : 'CW') + '%U';
    case BarChartType.Month:
      return small_screen1 ? '%b' : '%B';
    case BarChartType.Year:
      return '%Y';
  }
}

function generateCriticalGradient(ranges, y_extent, critical) {
  if (!critical) {
    return [
      {
        offset: '-100%',
        color: 'black',
      },
      {
        offset: '100%',
        color: 'black',
      },
    ];
  }
  let above_crit_offsets = [];
  let below_crit_offsets = [];

  const above_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.max <= red_range.min
      )
  );

  const below_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.min >= red_range.max
      )
  );
  const full_range = y_extent[1] - y_extent[0];
  const offset = full_range * 0.05;

  if (above_crit_range) {
    const gradient_start = above_crit_range.min - offset - y_extent[0];
    const gradient_end = above_crit_range.min + offset - y_extent[0];

    above_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
    ];
  }

  if (below_crit_range) {
    const gradient_start = below_crit_range.max - offset - y_extent[0];
    const gradient_end = below_crit_range.max + offset - y_extent[0];

    below_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
    ];
  }

  return [
    {
      offset: '-100%',
      color: below_crit_range ? '#E1000F' : 'black',
    },
    ...below_crit_offsets,
    ...above_crit_offsets,
    {
      offset: '100%',
      color: above_crit_range ? '#E1000F' : 'black',
    },
  ];
}
function pruneTimestamp(
  timestamp: Date,
  _type: BarChartType,
  center: boolean = false,
  roundUp: boolean = false
): Date {
  const out = new Date(timestamp);
  switch (_type) {
    case BarChartType.Year:
      out.setMonth(center ? 6 : 0);
      if (roundUp) {
        out.setFullYear(out.getFullYear() + 1);
      }
      out.setDate(15);
      out.setHours(12);
      break;
    case BarChartType.Month:
      out.setDate(center ? 15 : 1);
      if (roundUp) {
        out.setMonth(out.getMonth() + 1);
      }
      out.setHours(12);
      break;
    case BarChartType.Week:
      // source: https://stackoverflow.com/questions/4156434/javascript-get-the-first-day-of-the-week-from-current-date
      const day = out.getDay(),
        diff = out.getDate() - day + (center ? 3 : day === 0 ? -6 : 1); // adjust when day is sunday
      out.setDate(diff + (roundUp ? 7 : 0));
      out.setHours(12);
      break;
    case BarChartType.Day:
      out.setHours(center ? 12 : 0);
      if (roundUp) {
        out.setDate(out.getDate() + 1);
      }
  }
  out.setSeconds(0, 0);
  out.setMinutes(0);
  return out;
}
