<a
  class="tile"
  [class.steamtrap]="isSteamTrap"
  [class.repair-and-replace]="IsRepairAndReplace"
  [class.critical]="!telemetry.inactive && telemetry.critical"
  [class.warning]="!telemetry.inactive && telemetry.warning"
  [class.inactive]="telemetry.inactive"
  [class.mobile]="isMobile" 
  [class.tablet]="isTablet"
>
  <span class="tile-sensor body-bold">{{
    telemetry.sensor.sensorDisplayLabel
  }}</span>
  <i
    nz-icon
    *ngIf="!telemetry.inactive && !telemetry.critical && !telemetry.warning"
    nzType="icons:status-check"
    class="mini-svg-icon fillgreen"
    [class.fillgreen]="validTimeValue(telemetry.Timestamp)"
    [class.fillgrey]="!validTimeValue(telemetry.Timestamp)"
  ></i>
  <i
    nz-icon
    *ngIf="!telemetry.inactive && telemetry.warning"
    nzType="icons:status-exclamation-octagon"
    class="mini-svg-icon"
  ></i>
  <i
    nz-icon
    *ngIf="telemetry.inactive"
    nzType="icons:cross-circle"
    class="mini-svg-icon"
  ></i>
  <i
    nz-icon
    *ngIf="!telemetry.inactive && telemetry.critical"
    nzType="icons:status-exclamation-triangle"
    class="mini-svg-icon"
    [class.fillwhite]="!IsRepairAndReplace"
    [class.fillred]="IsRepairAndReplace"
  ></i>
  <div class="tile-value">
    <ng-container *ngIf="!validTimeValue(telemetry.Timestamp)">
      <h4 class="tile-title">{{ i18n.string("no_data") }}</h4>
    </ng-container>
    <ng-container *ngIf="validTimeValue(telemetry.Timestamp)">
      <ng-container *ngIf="!isSteamTrapInletOutlet || !steamtrap_outlet">
        <h4 class="tile-title" *ngIf="!this.telemetry.inactive == true">
          {{ extraParsing(i18n.parse(Title)) }}
        </h4>
        <h4 class="tile-title" *ngIf="this.telemetry.inactive == true">
          {{ i18n.string("inactive_status") }}
        </h4>
      </ng-container>
      <ng-container *ngIf="isSteamTrapInletOutlet && !!steamtrap_outlet">
        <h4 class="tile-title inlet">{{ i18n.string("inlet") }}</h4>
      </ng-container>
      <ng-container *ngIf="!isSteamTrap">
        <span
          class="tile-data detail-bold"
          [class.inlet]="isSteamTrapInletOutlet && !!steamtrap_outlet"
          *ngIf="telemetry.valueType === 'numerical'"
          >{{ telemetry.value.valStr }}</span
        >
        <span
          class="tile-data detail-bold"
          [class.inlet]="isSteamTrapInletOutlet && !!steamtrap_outlet"
          *ngIf="telemetry.valueType === 'minmax'"
          >{{ telemetry.value.valStr.min }} -
          {{ telemetry.value.valStr.max }}</span
        >
        <span
          class="tile-data detail-bold"
          [class.inlet]="isSteamTrapInletOutlet && !!steamtrap_outlet"
          *ngIf="telemetry.valueType === 'boolean'"
          >{{ i18n.parse(telemetry.value.valStr) }}</span
        >
      </ng-container>
      <ng-container *ngIf="isSteamTrapInletOutlet && !!steamtrap_outlet">
        <h4 class="tile-title outlet">{{ i18n.string("outlet") }}</h4>
        <span
          class="tile-data outlet detail-bold"
          *ngIf="telemetry.valueType === 'numerical'"
          >{{ steamtrap_outlet.value.valStr }}</span
        >
        <span
          class="tile-data outlet detail-bold"
          *ngIf="telemetry.valueType === 'minmax'"
          >{{ steamtrap_outlet.value.valStr.min }} -
          {{ steamtrap_outlet.value.valStr.max }}</span
        >
        <span
          class="tile-data outlet detail-bold"
          *ngIf="telemetry.valueType === 'boolean'"
          >{{ i18n.parse(steamtrap_outlet.value.valStr) }}</span
        >
      </ng-container>
    </ng-container>
    <span
      *ngIf="validTimeValue(telemetry.Timestamp)"
      class="tile-time detail-bold"
      >{{ i18n.string("ago1") }}{{ minutesToNow(telemetry.Timestamp)
      }}{{ i18n.string("ago2") }}</span
    >
    <span
      *ngIf="!validTimeValue(telemetry.Timestamp)"
      class="tile-time detail-bold"
      >{{ i18n.string("never_checked") }}</span
    >
  </div>
</a>
