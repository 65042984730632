import { OTObject } from './object';

export const MOCK_DATA = [
  {
    objectID: 1500,
    objectName: 'Holthausen',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1418,
    objectStatus: 'critical',
    colorCode: '#FFD737',
    description: 'Holthausen',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: true,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 8,
    geoPosition: '51.16993689383225;6.838896355420576',
    picture:
      'https://ccticstorage01.blob.core.windows.net/loctite-pulse/photo/original/1227-1618836795-dzZqA4QGXkSYkPkawvARGw.jpg',
    thumbnail:
      'https://ccticstorage01.blob.core.windows.net/loctite-pulse/photo/thumbnail/1227-1618836795-bEhnmYlTZU6uLFpsEy93gw.png',
    children: [],
    curLayer: 'c',
    nextLayer: 'd',
    layerName: '{"en":"Plant","de":"Anlage"}',
    layerID: 3,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1501,
    objectName: 'K11 Henkel Powerplant',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1500,
    objectStatus: 'critical',
    colorCode: '#FFD737',
    description: 'K11 Henkel Powerplant Holthausen',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: true,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 14,
    geoPosition: null, // '51.17880491665441;6.838474273681641',
    picture:
      'https://ccticstorage01.blob.core.windows.net/loctite-pulse/photo/original/1227-1618836795-dzZqA4QGXkSYkPkawvARGw.jpg',
    thumbnail:
      'https://ccticstorage01.blob.core.windows.net/loctite-pulse/photo/thumbnail/1227-1618836795-bEhnmYlTZU6uLFpsEy93gw.png',
    children: [],
    curLayer: 'd',
    nextLayer: 'e',
    layerName: '{"en":"Building","de":"Gebäude"}',
    layerID: 4,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1502,
    objectName: 'B03 Laundry Plant',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1500,
    objectStatus: 'critical',
    colorCode: '#FFD737',
    description: 'B03 Laundry Plant Holthausen',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: true,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 200,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'd',
    nextLayer: 'e',
    layerName: '{"en":"Building","de":"Gebäude"}',
    layerID: 4,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1503,
    objectName: 'Lab A',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1500,
    objectStatus: 'in operation',
    colorCode: '#ff8084',
    description: 'Lab A',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: false,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 10,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'd',
    nextLayer: 'g',
    layerName: '{"en":"Building","de":"Gebäude"}',
    layerID: 4,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1504,
    objectName: 'Lab B',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1500,
    objectStatus: 'in operation',
    colorCode: '#ff8084',
    description: 'Lab B',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: false,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 16,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'd',
    nextLayer: 'g',
    layerName: '{"en":"Building","de":"Gebäude"}',
    layerID: 4,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1505,
    objectName: 'Lab C',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1500,
    objectStatus: 'in operation',
    colorCode: '#ff8084',
    description: 'Lab C',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: false,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 24,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'd',
    nextLayer: 'g',
    layerName: '{"en":"Building","de":"Gebäude"}',
    layerID: 4,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1506,
    objectName: 'M10 Power Plant',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1500,
    objectStatus: 'in operation',
    colorCode: '#ff8084',
    description: 'M10 Power Plant',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: false,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 16,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'd',
    nextLayer: 'e',
    layerName: '{"en":"Building","de":"Gebäude"}',
    layerID: 4,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1507,
    objectName: '1st floor',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1502,
    objectStatus: 'in operation',
    colorCode: '#ff8084',
    description: 'B03 Laundry Plant - 1st floor',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: false,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 65,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'e',
    nextLayer: 'g',
    layerName: 'Floor',
    layerID: 5,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1508,
    objectName: '2nd floor',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1502,
    objectStatus: 'in operation',
    colorCode: '#ff8084',
    description: 'B03 Laundry Plant - 2nd floor',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: false,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 58,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'e',
    nextLayer: 'g',
    layerName: 'Floor',
    layerID: 5,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: true,
  },
  {
    objectID: 1509,
    objectName: '3rd floor',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1502,
    objectStatus: 'in operation',
    colorCode: '#ff8084',
    description: 'B03 Laundry Plant - 3rd floor',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: false,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 57,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'e',
    nextLayer: 'g',
    layerName: 'Floor',
    layerID: 5,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
  {
    objectID: 1510,
    objectName: 'Ground floor',
    objectTypeID: 2,
    objectType: 'TEC_FunctionalLocation',
    parentID: 1502,
    objectStatus: 'critical',
    colorCode: '#ff8084',
    description: 'B03 Laundry Plant - Ground floor',
    isAsset: false,
    isConnected: false,
    warning: false,
    critical: true,
    inactive: false,
    equipmentTypeID: 7,
    equipmentType: 'Unit',
    childCount: 20,
    geoPosition: null,
    picture: null,
    thumbnail: null,
    children: [],
    curLayer: 'e',
    nextLayer: 'g',
    layerName: 'Floor',
    layerID: 5,
    buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
    numSensors: 0,
    customerName: 'mock',
    sensorDigitIDs: null,
    edgeDeviceIDs: null,
    macs: null,
    gatewayIDs: null,
    isArchived: false,
  },
];

export function AugmentedMockData(): OTObject[] {
  return [];
  let data = [...MOCK_DATA];
  let id = data.sort((a, b) => b.objectID - a.objectID)[0].objectID + 1;
  id++;
  function addObjects(
    equipments: { numWorking: number; numCritical: number }[],
    parentID
  ) {
    for (const e of equipments) {
      const e_id = addEquipment(
        e.numCritical > 0,
        e.numWorking + e.numCritical,
        parentID
      );
      for (let i = 0; i < e.numWorking; i++) {
        addObject(false, e_id);
      }
      for (let i = 0; i < e.numCritical; i++) {
        addObject(true, e_id);
      }
    }
  }
  function addEquipment(hasCritical, childCount, parentID): number {
    const obj = {
      objectID: id++,
      objectName: 'Equipment ' + uid(),
      objectTypeID: 2,
      objectType: 'TEC_FunctionalLocation',
      parentID: parentID,
      objectStatus: hasCritical ? 'critical' : 'in operation',
      colorCode: '#ff8084',
      description: 'Generated Equipment',
      isAsset: false,
      isConnected: false,
      warning: false,
      critical: hasCritical,
      inactive: false,
      equipmentTypeID: 7,
      equipmentType: 'Unit',
      childCount: childCount,
      geoPosition: null,
      picture: null,
      thumbnail: null,
      children: [],
      curLayer: 'g',
      nextLayer: 'h',
      layerName: 'Associated Equipment',
      layerID: 7,
      buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
      numSensors: 0,
      customerName: 'mock',
      sensorDigitIDs: null,
      edgeDeviceIDs: null,
      macs: null,
      gatewayIDs: null,
      isArchived: false,
    };
    data = [...data, obj];
    return obj.objectID;
  }
  function addObject(critical: boolean, parentID) {
    const obj = {
      objectID: id++,
      objectName: 'Asset ' + uid(),
      objectTypeID: 2,
      objectType: 'TEC_FunctionalLocation',
      parentID: parentID,
      objectStatus: critical ? 'critical' : 'in operation',
      colorCode: '#ff8084',
      description: 'Generated Equipment',
      isAsset: true,
      isConnected: false,
      warning: false,
      critical: critical,
      inactive: false,
      equipmentTypeID: 7,
      equipmentType: 'Unit',
      childCount: 0,
      geoPosition: null,
      picture: null,
      thumbnail: null,
      children: [],
      curLayer: 'h',
      nextLayer: 'i',
      layerName: 'Equipment',
      layerID: 8,
      buildingPlan: 'https://ccticstorage01.blob.core.windows.net/loctite-pulse/building_plans/CxGoApp Automated Test Cypress.b1dc9b6f-0e75-4bd6-b4eb-c9ed47882fd4-1693915501.pptx?sv=2022-11-02&spr=https&st=2023-09-06T12%3A57%3A29Z&se=2023-09-06T13%3A58%3A29Z&sr=c&sp=r&sig=VyA9NJsZrYgGOZfp62Orpyk1kWJ0DAbriv1OHUl5v4M%3D',
      numSensors: 1,
      customerName: 'mock',
      sensorDigitIDs: null,
      edgeDeviceIDs: null,
      macs: null,
      gatewayIDs: null,
      isArchived: false,
    };
    data = [...data, obj];
    return obj.objectID;
  }

  addObjects(
    [
      { numWorking: 9, numCritical: 0 },
      { numWorking: 7, numCritical: 0 },
      { numWorking: 9, numCritical: 0 },
      { numWorking: 8, numCritical: 0 },
      { numWorking: 7, numCritical: 0 },
      { numWorking: 5, numCritical: 0 },
      { numWorking: 10, numCritical: 0 },
      { numWorking: 10, numCritical: 0 },
    ],
    1507
  ); // B03 Laundry Plant - 1st Floor
  addObjects(
    [
      { numWorking: 8, numCritical: 0 },
      { numWorking: 7, numCritical: 0 },
      { numWorking: 9, numCritical: 0 },
      { numWorking: 8, numCritical: 0 },
      { numWorking: 9, numCritical: 0 },
      { numWorking: 8, numCritical: 0 },
      { numWorking: 9, numCritical: 0 },
    ],
    1508
  ); // B03 Laundry Plant - 2nd Floor
  addObjects(
    [
      { numWorking: 9, numCritical: 0 },
      { numWorking: 6, numCritical: 0 },
      { numWorking: 7, numCritical: 0 },
      { numWorking: 8, numCritical: 0 },
      { numWorking: 9, numCritical: 0 },
      { numWorking: 6, numCritical: 0 },
      { numWorking: 7, numCritical: 0 },
      { numWorking: 5, numCritical: 0 },
    ],
    1509
  ); // B03 Laundry Plant - 3rd Floor
  addObjects(
    [
      { numWorking: 5, numCritical: 1 },
      { numWorking: 5, numCritical: 1 },
      { numWorking: 9, numCritical: 1 },
    ],
    1510
  ); // B03 Laundry Plant - Ground Floor
  addObjects(
    [
      { numWorking: 2, numCritical: 0 },
      { numWorking: 0, numCritical: 1 },
      { numWorking: 5, numCritical: 0 },
      { numWorking: 6, numCritical: 0 },
    ],
    1501
  ); // K11 Power Plant
  addObjects(
    [
      { numWorking: 4, numCritical: 0 },
      { numWorking: 6, numCritical: 0 },
    ],
    1503
  ); // Lab A
  addObjects(
    [
      { numWorking: 6, numCritical: 0 },
      { numWorking: 6, numCritical: 0 },
      { numWorking: 4, numCritical: 0 },
    ],
    1504
  ); // Lab B
  addObjects(
    [
      { numWorking: 6, numCritical: 0 },
      { numWorking: 8, numCritical: 0 },
      { numWorking: 3, numCritical: 0 },
      { numWorking: 4, numCritical: 0 },
      { numWorking: 3, numCritical: 0 },
    ],
    1505
  ); // Lab C
  addObjects(
    [
      { numWorking: 9, numCritical: 0 },
      { numWorking: 7, numCritical: 0 },
    ],
    1506
  ); // M10 Power Plant
  return data;
}

function uid() {
  return 'xxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
