import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../cc-framework/services/authentication.service';
import { Country } from '../../model/object';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-admin-approve',
  templateUrl: './admin-approve.component.html',
  styleUrls: ['./admin-approve.component.scss'],
})
export class AdminApproveComponent implements OnInit {
  isLoading = false;
  emailAlreadyRegistered = false;
  error = false;
  loadingError = false; // to do: error msg
  showAlert: boolean = false;
  optional: boolean = true;

  isGranted = false;
  isDenied = false;

  token = null;

  name = 'test';
  surname = '';
  email = '';
  designation = '';
  country = '';
  customerentity = '';

  customerList = [];

  @ViewChild('emailInputRef') emailInputRef: ElementRef;

  signupForm = new FormGroup({
    name: new FormControl({ value: null, disabled: true }, [
      Validators.required,
      Validators.minLength(3),
    ]),
    surname: new FormControl({ value: null, disabled: true }, [
      Validators.required,
      Validators.minLength(3),
    ]),
    designation: new FormControl({ value: null, disabled: true }, []),
    email: new FormControl({ value: null, disabled: true }, [
      Validators.email,
      Validators.required,
    ]),
    customerentity: new FormControl({ value: null, disabled: true }, []),
    country: new FormControl({ value: null, disabled: true }, [
      Validators.required,
      Validators.minLength(3),
    ]),
    customer_object: new FormControl(null, [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  submitted: boolean = false;
  isWorking: boolean = false;
  public countryArray: Country[] = [];

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get I18N(): I18nService {
    return this.i18n;
  }

  get IsCustomerSelected(): boolean {
    return this.signupForm.controls.customer_object.value > 0;
  }

  constructor(
    private router: Router,
    private util: UtilService,
    private authService: AuthenticationService,
    private assetsService: AssetsService,
    private ui: UiService,
    public i18n: I18nService,
    private _fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getCountryList();
    this.route.params.subscribe((params) => {
      if (!!params['token']) {
        this.token = params['token'];
        this.loadUserData();
      }
    });
  }

  getCountryList() {
    this.assetsService.getCountryList().subscribe((res: Country[]) => {
      this.countryArray = res;
      this.sortCountries();
      console.warn(this.countryArray);
    });
  }

  sortCountries() {
    this.countryArray = this.countryArray.sort((a, b) => {
      if (
        this.i18n.parse(a.name).toUpperCase() >
        this.i18n.parse(b.name).toUpperCase()
      )
        return 1;
      if (
        this.i18n.parse(a.name).toUpperCase() <
        this.i18n.parse(b.name).toUpperCase()
      )
        return -1;
      return 0;
    });
  }

  getName(value) {
    return JSON.parse(value)[this.i18n.Lng];
  }

  loadUserData() {
    this.loadingError = false;
    this.isLoading = true;
    this.authService.loadUserApprovalData(this.token).subscribe(
      (result) => {
        this.isLoading = false;
        this.name = result.userData.name;
        this.surname = result.userData.surname;
        this.email = result.userData.email;
        this.designation = !!result.userData.designation
          ? result.userData.designation
          : '';
        this.country = result.userData.country;
        this.signupForm.controls.country.setValue(result.userData.country);
        this.customerentity = !!result.userData.customerEntity
          ? result.userData.customerEntity
          : '';

        this.customerList = result.customerList;
      },
      (error) => {
        this.loadingError = true;
        this.isLoading = false;
      }
    );
  }

  grant() {
    this.error = false;
    this.isLoading = true;
    this.authService
      .processRegistrationRequest(
        true,
        this.signupForm.controls.customer_object.value,
        this.token
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.isGranted = true;
        },
        (error) => {
          this.isLoading = false;
          this.error = true;
        }
      );
  }
  deny() {
    this.error = false;
    this.isLoading = true;
    this.authService
      .processRegistrationRequest(false, -1, this.token)
      .subscribe(
        () => {
          this.isLoading = false;
          this.isDenied = true;
        },
        (error) => {
          this.isLoading = false;
          this.error = true;
        }
      );
  }
  /*signup() {
    if (!this.signupForm.valid) return;
    this.error = false;
    this.emailAlreadyRegistered = false;
    this.isLoading = true;
    this.authService
      .signup(this.signupForm.getRawValue(), this.i18n.Lng)
      .subscribe(
        () => {
          this.isLoading = false;
          this.router.navigate(['login', 'signed-up']);
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 409) {
            this.emailAlreadyRegistered = true;
            setTimeout(() => this.emailInputRef.nativeElement.focus(), 100);
          } else {
            this.error = true;
          }
        }
      );
  }*/

  goToLoginPage() {
    this.router.navigate(['login']);
  }

  get f() {
    return this.signupForm.controls;
  }

  get requiredEmailValid() {
    return (
      !this.signupForm.controls['email'].hasError('required') &&
      !this.emailAlreadyRegistered
    );
  }
}
