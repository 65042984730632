import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { Location } from '../../model/location';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  svg_icon_top = 'icons:arrow-right-up';
  @Input() plant: Location = null;
  @Input() fine: boolean = false;
  @Input() modal: boolean = false;
  @Input() detail: boolean = false;
  @Input() closeBtn: boolean = false;
  @Input() showLayer: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() showsArchived: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() nav: EventEmitter<void> = new EventEmitter<void>();
  public modalOpen: boolean = false;
  public srclink: string;
  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }

  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  constructor(
    private ui: UiService,
    public i18n: I18nService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.ui.OnOrientationChange.subscribe(() =>
      this.changeDetectorRef.detectChanges()
    );
  }
  onNav() {
    this.nav.emit();
  }
  
  openBuildingPreview(buildingPlan) {
    this.modalOpen = true;
     this.srclink = buildingPlan;
  }

  openNewTab() {
     window.open(this.plant.buildingPlan, '_blank', 'noreferrer');
  }

  cancelClick() {
    this.modalOpen = false;
  }
}
