let swipeCoord = [0, 0];
let swipeTime = new Date().getTime();

export function swipe(e: TouchEvent, when: string): void {

    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
        swipeCoord = coord;
        swipeTime = time;
    } 
}