import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Asset } from '../../model/asset';
import { Location } from '../../model/location';
import { LocationFilter } from '../../model/location.filter';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  public plants: Location[] = [];
  public loading;
  public loadingThumbnails;
  public filteredPlants;
  public activeTab = 0;
  public sumCritical = 0;
  public sumWarning = 0;
  public sumInActive = 0;
  public sumWorking = 0;
  public assetCount = 0;
  public satelliteView = false;
  public homePageFilterStatus = 0;
  private filter: LocationFilter = new LocationFilter();

  overlayPlant;
  plantSubscription = null;
  filterSubscription = null;
  countryOrRegionSelected = null;

  get OverlayPlant(): Location {
    return this.overlayPlant;
  }

  set OverlayPlant(plant: Location) {
    if (!plant) this.overlayPlant = null;
    else
      this.overlayPlant = Object.assign(new Location(plant, []), {
        ...plant,
        filtered: false,
      });
  }

  get AssetCount(): number {
    return this.assetCount;
  }

  public scrolling: boolean = false;
  private scrollHandler = null;

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  get isChrome(): boolean {
    return navigator.appVersion.indexOf('Chrome/') !== -1;
  }

  constructor(
    private assetsService: AssetsService,
    private router: Router,
    private route: ActivatedRoute,
    private changeRef: ChangeDetectorRef,
    private ui: UiService,
    public i18n: I18nService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((route) => this.onParamMapChange(route));
    this.refresh();
    this.ui.onNavBack(null);
    this.ui.setBackText('');
    if (this.isMobile) {
      this.onTabChanged(1);
    }
  }

  onParamMapChange(result) {
    if (!result) return;
    const params = !!result.params ? result.params : result;
    function map_tab_index(tab) {
      switch (tab) {
        case '':
        case 'map':
          return 0;
        case 'list':
          return 1;
        default:
          return 0;
      }
    }
    if (this.activeTab !== map_tab_index(params['tab'])) {
      setTimeout(() => (this.activeTab = map_tab_index(params['tab'])));
    }
    if (!!params['id']) this.onSelectCountryOrRegion(params['id']);
  }

  onSelectCountryOrRegion(id) {
    if (typeof id === 'string') id = +id;
    if (typeof id === 'number') this.countryOrRegionSelected = id;
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
    let scroll_timeout = null;
    this.scrollHandler = (e) => {
      if (e.target['id'] === 'desktop-sidebar-inner') {
        if (!this.scrolling) {
          this.scrolling = true;
        }
        if (!!scroll_timeout) clearTimeout(scroll_timeout);
        scroll_timeout = setTimeout(() => {
          this.scrolling = false;
          scroll_timeout = null;
        }, 500);
      }
    };
    window.addEventListener('scroll', this.scrollHandler, true);
  }

  ngAfterContentChecked(): void {
    this.changeRef.detectChanges();
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollHandler);
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
    if (!!this.filterSubscription) this.filterSubscription.unsubscribe();
  }

  private refresh() {
    this.plants = [];
    this.loading = true;
    if (!!this.plantSubscription) this.plantSubscription.unsubscribe();
    this.plantSubscription = this.assetsService.subscribeToPlants(
      (result: {
        plants: Location[];
        loadingComplete: boolean;
        filtered: boolean;
      }) => {
        this.loading = false;
        this.loadingThumbnails = !result.loadingComplete;
        this.filteredPlants = result.filtered;
        this.plants = result.plants.filter(
          (plant: Location) => plant.curLayer === 'c'
        );
        this.assetCount = this.plants
          .map((plant) => plant.Assets.length)
          .reduce((a, b) => a + b, 0);

        /*console.log(this.plants);
        this.sumCritical = this.plants.reduce(function (criticalSum, plant) {
          return criticalSum + plant.Critical.length;
        }, 0);
        this.sumWorking = this.plants.reduce(function (criticalSum, plant) {
          return criticalSum + plant.Working.length;
        }, 0);*/

        this.sumCritical = this.plants
          .map((plant) => plant.Critical.length)
          .reduce((a, b) => a + b, 0);
        this.sumWarning = this.plants
          .map((plant) => plant.Warning.length)
          .reduce((a, b) => a + b, 0);
        this.sumInActive = this.plants
          .map((plant) => plant.InActive.length)
          .reduce((a, b) => a + b, 0);
        this.sumWorking = this.plants
          .map((plant) => plant.Working.length)
          .reduce((a, b) => a + b, 0);
        this.setSelectionPreset();

        this.changeRef.detectChanges();
      }
    );
    this.filterSubscription = this.assetsService.subscribeToFilter(
      (filter: LocationFilter) => {
        this.homePageFilterStatus = 0;
        this.filter = filter;
      }
    );
  }

  get allWorking(): boolean {
    if (this.AssetCount > 0 && this.sumWorking > 0 && (this.sumCritical == 0 && this.sumInActive == 0 && this.sumWarning == 0)) {
      return true;
    }
    return false;
  }

  breadcrumbs(asset: Asset) {
    return JSON.stringify(
      asset.breadcrumbs.map((breadcrumb) => breadcrumb.objectName)
    );
  }

  onTabChanged(tab) {
    switch (tab) {
      case 0:
        this.router.navigate(
          !!this.countryOrRegionSelected
            ? ['home', 'map', this.countryOrRegionSelected]
            : ['home', 'map']
        );
        break;
      case 1:
        this.router.navigate(
          !!this.countryOrRegionSelected
            ? ['home', 'list', this.countryOrRegionSelected]
            : ['home', 'list']
        );
        break;
    }
    // reset scroll position
    const sidebar = document.getElementById('desktop-sidebar-inner');
    if (!!sidebar) sidebar.scrollTop = 0;
  }

  private setSelectionPreset(): void {
    function unique(value: Location, index: number, array: Location[]) {
      return (
        index ===
        array.findIndex((item: Location) => item.objectID === value.objectID)
      );
    }

    const temp_filter = new LocationFilter();
    temp_filter.customer = this.filter.customer;

    const selectionPreset = [];
    const regions = this.assetsService.UnfilteredRegions.map((item) =>
      item.ApplyFilter(temp_filter)
    ).filter((item) => !!item && item.Assets.length > 0);
    const areas = regions.map((region) => region.children).flat(2); // countries

    if (regions.length === 1) {
      selectionPreset.push(regions[0]);
      if (areas.length === 1) {
        selectionPreset.push(areas[0]);
      }
    }

    this.assetsService.updateSelectionPreset(selectionPreset);
  }
  public onSetInactiveFilter() {
    this.assetsService.updateFilter(
      Object.assign(new LocationFilter(), {
        ...this.filter,
        working: false,
        warning: false,
        critical: false,
        inactive: true,
      })
    );
  }

  public onSetWorkingFineFilter() {
    this.assetsService.updateFilter(
      Object.assign(new LocationFilter(), {
        ...this.filter,
        working: true,
        warning: false,
        critical: false,
        inactive: false,
      })
    );
  }
  public onSetWarningFilter() {
    this.assetsService.updateFilter(
      Object.assign(new LocationFilter(), {
        ...this.filter,
        working: false,
        warning: true,
        critical: false,
        inactive: false,
      })
    );
  }
  public onSetCriticalFilter() {
    this.assetsService.updateFilter(
      Object.assign(new LocationFilter(), {
        ...this.filter,
        working: false,
        warning: false,
        critical: true,
        inactive: false,
      })
    );
  }

  public onMapMarkerClick(object: Location) {
    this.router.navigate(['plant', object.objectID]);
    // ...go directly to building or area in plant view
  }

  public setStatusFilterValue(value) {
    this.homePageFilterStatus = value;
  }
}
