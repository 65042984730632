import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { CurrentTelemetry } from '../../model/sensor';
import { UserSettings } from '../../model/settings';
import { GraphData } from '../../model/telemetry_history';
import { GraphDisplay } from '../../pages/asset-overview/asset-overview.component';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-telemetry-display',
  templateUrl: './telemetry-display.component.html',
  styleUrls: ['./telemetry-display.component.scss'],
})
export class TelemetryDisplayComponent implements OnInit {
  @Input() MainMeasurementID: number = null;
  graphDisplays: GraphDisplay[] = [];
  @Input() set GraphDisplays(graphDisplays: GraphDisplay[]) {
    this.graphDisplays = graphDisplays;
  }
  graphData: GraphData[] = [];
  @Input() set GraphData(graphData: GraphData[]) {
    this.graphData = graphData;
  }
  telemetry: CurrentTelemetry[] = [];
  @Input() set Telemetry(telemetry: CurrentTelemetry[]) {
    this.telemetry = telemetry;
  }
  get CurrentTelemetry(): CurrentTelemetry {
    if (!this.telemetry || this.telemetry.length === 0) return null;
    return this.telemetry[0];
  }
  @Input() loading: boolean = false;
  @Input() isTelemetryTimeframeCustom: boolean = false;
  @Input() range: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 1)),
    new Date(),
  ];
  @Input() settings: UserSettings = null;

  @Output() OnTelemetryTimeframeSelect = new EventEmitter<{
    index: number;
    telemetry: CurrentTelemetry;
  }>();
  @Output() OnRangeStartPick = new EventEmitter<{
    measurementTypeID: number;
    sensorID: number;
    event: any;
    endPickerID: number;
    data: any;
  }>();
  @Output() OnRangeEndPick = new EventEmitter<{
    measurementTypeID: number;
    sensorID: number;
    event: any;
    data: any;
  }>();
  @Output() OnRangePickerSelect = new EventEmitter<{
    event: any;
    data: any;
  }>();
  @Output() OpenRangePicker = new EventEmitter<{
    id: number;
    data: any;
  }>();
  @Output() RangePickerDisabledDate = new EventEmitter<{
    data: any;
    id: number;
  }>();
  @Output() dateRangeUpdatedfromGraph = new EventEmitter<string[]>();
  @ViewChildren('datePick') datePickers: QueryList<any>;

  get MainCurrentTelemetry(): CurrentTelemetry {
    if (!this.graphDisplays) return null;
    const statusGraphDisplay = this.graphDisplays.find(
      (item) =>
        item.currentTelemetry.measurementTypeID === this.MainMeasurementID
    );
    if (!statusGraphDisplay) return null;
    return statusGraphDisplay.currentTelemetry;
  }

  get CriticalOverride() {
    return {};
  }

  get WarningOverride() {
    return {};
  }

  public graphBrushActive = false;

  constructor(public i18n: I18nService) {}

  ngOnInit() {}

  onTelemetryTimeframeSelect(index: number, telemetry: CurrentTelemetry): void {
    this.OnTelemetryTimeframeSelect.emit({
      index: index,
      telemetry: telemetry,
    });
  }

  onRangeStartPick(measurementTypeID, sensorID, event, endPickerID, data) {
    this.OnRangeStartPick.emit({
      measurementTypeID: measurementTypeID,
      sensorID: sensorID,
      event: event,
      endPickerID: endPickerID,
      data: data,
    });
  }
  onRangeEndPick(measurementTypeID, sensorID, event, data) {
    this.OnRangeEndPick.emit({
      measurementTypeID: measurementTypeID,
      sensorID: sensorID,
      event: event,
      data: data,
    });
  }

  openRangePicker(id, data) {
    this.OpenRangePicker.emit({
      id: id,
      data: data,
    });
  }

  onRangePickerSelect(event, data) {
    this.OnRangePickerSelect.emit({
      event: event,
      data: data,
    });
  }
  getVisibleGraphs(telemetry: CurrentTelemetry) {
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !!display && display.graphData
      ? display.graphData.filter((graphData) => graphData.metadata.displayGraph)
      : [];
  }
  noData(data: GraphData) {
    return !data || !data.telemetry.length;
  }
  noDataTelemetry(telemetry: CurrentTelemetry) {
    return !this.getVisibleGraphs(telemetry).find(
      (data: GraphData) => !this.noData(data)
    );
  }
  rangePickerDisabledDate(data, pickerIndex): (current: Date) => boolean {
    if (!data) {
      return (current: Date) => false;
    }
    let otherDate = null; // otherDate removes the ability to create empty timespans (in other words, pick the same day twice)
    if (pickerIndex === 1) otherDate = this.range[1];
    if (pickerIndex === 2) otherDate = this.range[0];
    if (!!otherDate) otherDate = new Date(otherDate.toDateString()); // remove time from datetime object
    let first: Date = new Date(Date.parse(data.metadata.firstMeasurement));
    first = new Date(first.toDateString()); // remove time from datetime object
    let last: Date = new Date(Date.parse(data.metadata.lastMeasurement));
    last = new Date(last.toDateString()); // remove time from datetime object
    const fn = (current: Date) => {
      current = new Date(current.toDateString()); // remove time from datetime object
      return current < first || current > last || +current === +otherDate;
    };
    return fn;
  }

  cancelRangePick(data) {
    data.openRangePicker = 0;
    if (this.range[0] > this.range[1]) {
      const temp = this.range[0];
      this.range[0] = this.range[1];
      this.range[1] = temp;
    }
    this.datePickers.forEach((item) => item.close());
  }

  getTimeframe(telemetry: CurrentTelemetry) {
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !!display ? display.timeframe : null;
  }

  onDateRangeUpdatedfromGraph(event) {
    this.dateRangeUpdatedfromGraph.emit(event);
  }
}
