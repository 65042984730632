import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'unit',
})
export class UnitConversionPipe implements PipeTransform {
  get SIUnits(): boolean {
    return true;
  }


  transform(value: any, args?: any, ms?: any, prefix?: any): any {
    value = +value; // string to number if necessary
    const trans: UnitTransform =
      ms === 'im' ? new ImperialTransform() : new MetricTransform();

    switch (args.valueType) {
      case 'length':
        return trans.transformLength(
          value,
          args.unitPrefix ? JSON.parse(args.unitPrefix)[ms] : '');
      case 'area':
        return trans.transformArea(value);
      case 'volume':
        return trans.transformVolume(value);
      case 'mass':
        return trans.transformMass(value);
      case 'temperature':
        return trans.transformTemperature(value);
      case 'pressure':
        return trans.transformPressure(value);
      case 'power':
        return trans.transformPower(value);
      case 'nominal':
      return trans.transformNominal(
        value,
        args.unitPrefix ? JSON.parse(args.unitPrefix)[ms] : '',
        args.unit ? args.unit[ms] : ''
      );
      default:
        return '';
    }
  }
}

class ImperialTransform implements UnitTransform {
  transformLength(value: number, unitPrefix: string): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;

    if (value < 0.001) {
      // meter to microns/micrometer
      value *= 10000;
      return sign + +value.toFixed(4) + ' ' + unitPrefix + 'μm';
    } else if (value < 0.3048) {
      // meter to inch
      value *= 39.3700787;
      return sign + +value.toFixed(2) + ' ' + unitPrefix + 'in';
    } else if (value < 0.9144) {
      // meter to foot
      value *= 3.2808399;
      return sign + +value.toFixed(2) + ' ' + unitPrefix + 'ft';
    } else if (value < 1609.3) {
      // meter to yard
      value *= 1.0936133;
      return sign + +value.toFixed(2) + ' ' + unitPrefix + 'yd';
    } else {
      // meter to mile
      value *= 0.000621371192;
      return sign + +value.toFixed(2) + ' ' + unitPrefix + 'mile(s)';
    }
  }
  transformArea(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' m²';
  }
  transformVolume(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' m³';
  }
  transformMass(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' kg';
  }
  transformTemperature(value: number): string {
    value = value * 1.8 + 32;
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    return sign + +value.toFixed(2) + ' °F';
  }
  transformPressure(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' Pa';
  }
  transformPower(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' W';
  }
  transformNominal(value: number, unitPrefix: string, unit: string): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    return unitPrefix + sign + +value.toFixed(2) + ' ' + unit;
  }
}

class MetricTransform implements UnitTransform {
  transformLength(value: number, unitPrefix: string): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    if (value < 1) {
      value *= 100;
      if (value < 1) {
        value *= 10;
        if (value < 1) {
          value *= 1000;
          if (value < 1) {
            value *= 1000;
            return sign + +value.toFixed(2) + ' ' + unitPrefix + 'nm';
          } else return sign + +value.toFixed(2) + ' ' + unitPrefix + 'μm';
        } else return sign + +value.toFixed(2) + ' ' + unitPrefix + 'mm';
      } else return sign + +value.toFixed(2) + ' ' + unitPrefix + 'cm';
    } else if (value > 1000) {
      value /= 1000;
      return sign + +value.toFixed(2) + ' ' + unitPrefix + 'km';
    } else return sign + +value.toFixed(2) + ' ' + unitPrefix + 'm';
  }
  transformArea(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' m²';
  }
  transformVolume(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' m³';
  }
  transformMass(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' kg';
  }
  transformTemperature(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // nothing to change, value is already in degree celsius
    return sign + +value.toFixed(2) + ' °C';
  }
  transformPressure(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' Pa';
  }
  transformPower(value: number): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    // TODO
    return sign + +value.toFixed(2) + ' W';
  }
  transformNominal(value: number, unitPrefix: string, unit: string): string {
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    return unitPrefix + sign + +value.toFixed(2) + ' ' + unit;
  }
}

interface UnitTransform {
  transformLength(value: number, unitPrefix: string): string;
  transformArea(value: number): string;
  transformVolume(value: number): string;
  transformMass(value: number): string;
  transformTemperature(value: number): string;
  transformPressure(value: number): string;
  transformPower(value: number): string;
  transformNominal(value: number, unitPrefix: string, unit: string): string;
}
