import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { I18nService } from '../../../../../services/i18n.service';
import { UiService } from '../../../../../services/ui.service';

@Component({
  selector: 'app-push-notification-toggle',
  templateUrl: './push-notification-toggle.component.html',
  styleUrls: ['./push-notification-toggle.component.scss'],
})
export class PushNotificationToggleComponent implements OnInit {
  @Input() public settings: any;
  @Input() public loading: boolean = false;
  @Input() set value(value: boolean) {
    this._value = value;
    this.input_value = value;
  }

  public _value: boolean = false;
  public input_value: boolean = false;

  @Input() set reset(value: boolean) {
    this._value = this.input_value;
  }
  @Output() public valueChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(public ui: UiService, public i18n: I18nService) {}

  ngOnInit() {}

  toggleValue() {
    this._value = !this._value;
    this.valueChange.emit(this._value);
  }
}
