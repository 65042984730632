<nz-modal
  [nzVisible]="true"
  [nzFooter]="null"
  (nzOnCancel)="this.step === 'note' ? (this.step = 'add') : close.emit()"
  [nzTitle]="i18n.string('add_documentation')"
  [nzCloseIcon]="'icons:exit'"
>
  <!-- <app-overlay-card
    [header]="'Add documentation'"
    [closeBtn]="true"
    (close)="close.emit()"
  > -->
  <app-spinner *ngIf="isLoading" [black]="true"></app-spinner>
  <div
    *ngIf="!isLoading && step === 'start'"
    class="card-layer docu-card-layer docu-card-layer-wide"
  >
    <h3 class="docAdjustmentMargin docAdjustmentCenter">
      {{ i18n.string("pick_something_that_applies") }}
    </h3>
    <div
      class="more-info-group body-bold"
      [class.preselected]="radioValue !== 0"
    >
      <button
        class="documentation-info"
        [class.selected]="radioValue == 1"
        (click)="radioValue = 1; setDocumentationType('repair')"
      >
        <i nz-icon nzType="icons:tool" class="svg-icon mini-icon"></i>
        <label>{{ i18n.string("needs_to_be_repaired") }}</label>
      </button>
      <button
        class="documentation-info"
        [class.selected]="radioValue == 2"
        (click)="radioValue = 2; setDocumentationType('fixed')"
      >
        <i nz-icon nzType="icons:fixed" class="svg-icon mini-icon"></i>
        <label>{{ i18n.string("problem_fixed") }}</label>
      </button>
      <button
        class="documentation-info"
        [class.selected]="radioValue == 3"
        (click)="radioValue = 3; setDocumentationType('false_alert')"
      >
        <i
          nz-icon
          nzType="icons:status-exclamation-triangle"
          class="svg-icon mini-icon"
        ></i>
        <label>{{ i18n.string("false_alert") }}</label>
      </button>
      <button
        class="documentation-info"
        [class.selected]="radioValue == 4"
        (click)="radioValue = 4; setDocumentationType('other')"
      >
        <i nz-icon nzType="icons:others" class="svg-icon mini-icon"></i>
        <label>{{ i18n.string("others") }}</label>
      </button>
    </div>
  </div>

  <div *ngIf="!isLoading && step === 'add'" class="card-layer docu-card-layer">
    <div
      class="docMarginAdjustment"
      [class.mobile-Adjustment]="!isDesktop"
      id="documentation-selected-choice"
    >
      <app-asset-nav-button
        *ngIf="documentation.documentationType === 'repair'"
        [asset]="null"
        (click)="step = 'start'"
        svg_icon="icons:tool"
        blackCoral="true"
        svg_icon_right="icons:edit"
        >{{ i18n.string("needs_to_be_repaired") }}
      </app-asset-nav-button>
      <app-asset-nav-button
        *ngIf="documentation.documentationType === 'fixed'"
        [asset]="null"
        (click)="step = 'start'"
        svg_icon="icons:fixed"
        blackCoral="false"
        svg_icon_right="icons:edit"
        >{{ i18n.string("problem_fixed") }}
      </app-asset-nav-button>
      <app-asset-nav-button
        *ngIf="documentation.documentationType === 'false_alert'"
        [asset]="null"
        (click)="step = 'start'"
        svg_icon="icons:status-exclamation-triangle"
        blackCoral="true"
        svg_icon_right="icons:edit"
      >
        {{ i18n.string("false_alert") }}
      </app-asset-nav-button>
      <app-asset-nav-button
        *ngIf="documentation.documentationType === 'other'"
        [asset]="null"
        (click)="step = 'start'"
        svg_icon="icons:others"
        blackCoral="true"
        svg_icon_right="icons:edit"
        >{{ i18n.string("others") }}
      </app-asset-nav-button>
    </div>

    <h3 class="docAdjustmentCenter">
      {{ i18n.string("please_add_more_information") }}
    </h3>

    <app-docu-item
      [variant]="'text'"
      [svg_icon]="'icons:note'"
      [content]="documentation.note"
      (click)="step = 'note'"
    >
      {{ i18n.string("add_note") }}</app-docu-item
    >
    <app-docu-item
      [variant]="'image'"
      [svg_icon]="'icons:camera'"
      [content]="documentation.images"
      [documentationId]="documentation.documentationId"
      >{{
        !isDesktop
          ? i18n.string("take_or_upload_a_photo")
          : i18n.string("upload_a_photo")
      }}</app-docu-item
    >
    <app-docu-item
      [variant]="'file'"
      [svg_icon]="'icons:file'"
      [content]="documentation.files"
      [documentationId]="documentation.documentationId"
      >{{ i18n.string("add_file") }}</app-docu-item
    >
    <!-- <app-docu-item
      [variant]="'audio'"
      [svg_icon]="'icons:audio'"
      [content]="documentation.audios"
      [documentationId]="documentation.documentationId"
      >Record audio</app-docu-item
    > -->

    <div
      class="buttonwrapper buttonwrapper_bottom"
      [class.mobile-Adjustment]="!isDesktop"
    >
      <app-nav-button [primary]="true" (click)="save()" svg_icon="icons:save"
        >{{ i18n.string("save_documentation") }}
      </app-nav-button>
    </div>
  </div>

  <div
    *ngIf="!isLoading && step === 'note'"
    class="card-layer docu-card-layer add-note"
  >
    <h3>
      <i nz-icon nzType="icons:note" class="svg-icon mini-icon"></i>
      {{ i18n.string("add_note") }}
    </h3>
    <textarea
      id="note-input"
      name="note-input"
      cols="40"
      rows="5"
      [(ngModel)]="documentation.note"
      class="regular"
      placeholder="Please add a description"
    ></textarea>
    <div class="buttonwrapper buttonwrapper_bottom">
      <app-nav-button [primary]="true" svg_icon="icons:note" (click)="setNote()"
        >{{ i18n.string("save_note") }}
      </app-nav-button>
    </div>
  </div>
  <!-- </app-overlay-card> -->
</nz-modal>
