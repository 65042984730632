import 'leaflet';
import 'leaflet.markercluster';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Location } from '../../model/location';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';
import { swipe } from '../../util/swipe';

const L = window['L'];

const ZOOM_CIRCLES_THRESHOLD = 4;
const ZOOM_PLANT_THRESHOLD = 6;
const ZOOM_BUILDING_THRESHOLD = 10; // previously 16
const ZOOM_MAX_THRESHOLD = 18;

enum MarkerMode {
  dots = 0,
  circles,
  plants,
  buildings,
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  })
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  Map: any = null;
  isLoading = false;
  isInit = false;
  plants: Location[] = [];
  markers = null;
  markerLatLngs = [];
  zoomLevel: number = 0;
  // marker modes based on zoom level:
  markerMode: MarkerMode = null;
  satelliteView: boolean = false;
  tileLayer: any = null;
  infoOverlay: boolean = false;
  isPanelOpen: boolean = false;
  public sortedPlantList:Location[]=[];  
  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get ZoomLevel(): number {
    return this.zoomLevel;
  }
  

  set ZoomLevel(zoom: number) {
    if (zoom <= 0) {
      setTimeout(() => {
        this.fitBounds(true);
      }, 5);
      return;
    }
    this.zoomLevel = zoom;
    const markerMode =
      zoom < ZOOM_CIRCLES_THRESHOLD
        ? MarkerMode.dots
        : zoom < ZOOM_PLANT_THRESHOLD
        ? MarkerMode.circles
        : zoom < ZOOM_BUILDING_THRESHOLD
        ? MarkerMode.plants
        : MarkerMode.buildings;
    if (this.markerMode !== markerMode) {
      this.markerMode = markerMode;
      this.addMarkers();
    }
  }

  @Input() set Plants(plants: Location[]) {
    this.plants = plants;
    if (!this.isInit) return;
    this.addMarkers();

    setTimeout(() => {
      if(!!this.plants) this.loadPanelList();
      this.fitBounds();
    }, 1);
  }

  @Input() set Loading(loading: boolean) {
    this.isLoading = loading;
    this.addMarkers();
  }

  @Input() set SatelliteView(satelliteView: boolean) {
    if (this.satelliteView !== satelliteView) {
      this.satelliteView = satelliteView;
      if (!!this.tileLayer) {
        this.addTiles();
      } else {
        this.initMap();
      }
    }
  }

  @Input() set InfoOverlay(infoOverlay: boolean) {
    if (this.infoOverlay !== infoOverlay) {
      this.infoOverlay = infoOverlay;
      // this.fitBounds();
    }
  }

  
  @Output() markerClick: EventEmitter<Location> = new EventEmitter<Location>();

  constructor(public i18n: I18nService,
    private ui: UiService,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    ) {}

  ngOnInit() {
    this.loadPanelList();
  }
  ngAfterViewInit() {
    this.initMap();
    this.fitBounds();
  }
  ngOnDestroy() {
    if (!!this.Map) {
      this.Map.off();
      this.Map.remove();
    }
  }
  onSwipe(e: TouchEvent, when: string) {
     swipe(e, when);
    this.isPanelOpen = !this.isPanelOpen;
}
   loadPanelList(){
    const sortInactiveList:Location[]=[];
    const sortCriticalList:Location[]=[];
    const sortWarningList:Location[]=[];
    const sortWorkingList:Location[]=[];
      this.plants.map((plant:Location)=>{
        if(plant.NumSensors > 0){
        if(plant.InActive.length > 0){
            plant['icon'] = 'inactive';  
            sortInactiveList.push(plant);
        }
         else if(plant.Critical.length > 0){
            plant['icon'] = 'critical';
            sortCriticalList.push(plant);
         }
        else if(plant.Warning.length > 0){
            plant['icon'] = 'warning';
            sortWarningList.push(plant);
        }
        else if(plant.Working.length > 0){
            plant['icon'] = 'working';
            sortWorkingList.push(plant);
        }    
        }      
      })
      sortInactiveList.sort((a:any, b:any) => a.objectName.localeCompare(b.objectName));  
      sortCriticalList.sort((a:any, b:any) => a.objectName.localeCompare(b.objectName)); 
      sortWarningList.sort((a:any, b:any) => a.objectName.localeCompare(b.objectName));     
      sortWorkingList.sort((a:any, b:any) => a.objectName.localeCompare(b.objectName));     
      this.sortedPlantList = sortInactiveList.concat(sortCriticalList,sortWarningList,sortWorkingList);
      this.changeRef.detectChanges();

    }

  navigate(plant) {
    this.router.navigate(['plant', plant.objectID]);
  }

  initMap() {
    if (!!this.Map) {
      this.Map.off();
      this.Map.remove();
    }
    L.Map.addInitHook('addHandler', 'scrollWheelZoom', L.Map.ScrollWheelZoom);
    // this fixes the marker icon shadow
    delete L.Icon.Default.prototype._getIconUrl;
    const center = [51.175545, 10.2180081];
    this.Map = L.map('Map', {
      zoomControl: false,
    });

    this.tileLayer = null;
    this.addTiles();
    // for some reason an error is thrown when i call addmarkers from here
    // this.addMarkers();
    setTimeout(() => {
      try {
        this.Map.invalidateSize();
        this.Map.setView(center, 6);
      } catch (e) {
        console.error(e);
      }

      this.isInit = true;
      if (!!this.plants) this.addMarkers();
      setTimeout(() => {
        this.fitBounds();
      }, 1);
    }, 500);
    this.Map.on('zoom', (event) => {
      this.ZoomLevel = event.target._zoom;
    });

  }

 

  addTiles() {
    if (!!this.tileLayer) this.Map.removeLayer(this.tileLayer);
    const map_access = this.satelliteView
      ? 'https://api.mapbox.com/styles/v1/fbannier/ckseazcz54luo18rhr2sefnlh/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw'
      : 'https://api.mapbox.com/styles/v1/fbannier/ckna87y6l35an17o5bfy7ehkq/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw';
    this.tileLayer = L.tileLayer(map_access, {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      boxZoom: true,
      zoomAnimation: true,
      accessToken:
        'pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
    });
    this.tileLayer.addTo(this.Map);
  }
 

  addMarkers() {
    if (!this.isInit) return;
    if (this.Map) {
      try {
        if (this.markers) {
          this.markers.clearLayers();
          this.Map.removeLayer(this.markers);
        }
      } catch (e) {
        console.error('#########', e);
      }
      this.markerLatLngs = [];
      this.markers = L.markerClusterGroup({
        maxClusterRadius: 120,
        singleMarkerMode: false,
        spiderfyDistanceMultiplier: 15,
        zoomToBoundsOnClick: false,
        spiderfyOnMaxZoom: false,
        spiderfyShapePositions: (count, centerPt) => {
          const distanceFromCenter = 35,
            markerDistance = 75,
            lineLength = markerDistance * (count - 1),
            lineStart = centerPt.y - lineLength / 2,
            res = [];

          res.length = count;

          for (let i = count - 1; i >= 0; i--) {
            res[i] = L.point(
              centerPt.x - distanceFromCenter,
              lineStart + markerDistance * i
            );
          }
          return res;
        },
        iconCreateFunction: (cluster) => {
          const markers = cluster.getAllChildMarkers();

          const numWorking = markers
            .map((marker) => marker.options.data.working)
            .reduce((a, b) => a + b, 0);
          const numWarning = markers
            .map((marker) => marker.options.data.warning)
            .reduce((a, b) => a + b, 0);
          const numCritical = markers
            .map((marker) => marker.options.data.critical)
            .reduce((a, b) => a + b, 0);
          const numInActive = markers
            .map((marker) => marker.options.data.inactive)
            .reduce((a, b) => a + b, 0);

          const size =
            this.markerMode === MarkerMode.dots && numCritical === 0
              ? ClustSizeByChildCount(numWorking)
              : 58;
          return this.markerMode === MarkerMode.dots
            ? L.divIcon({
                html:
                  numCritical > 0
                    ? '<div class="loctite-marker-cluster critical drop-shadow"><span>' +
                      numCritical +
                      ' </span></div>'
                    : numWorking > 0
                    ? '<div class="loctite-marker-cluster dot drop-shadow"><span>' +
                      numWorking +
                      ' </span></div>'
                    : numInActive > 0
                    ? '<div class="loctite-marker-cluster inactive drop-shadow"><span>' +
                      numInActive +
                      ' </span></div>'
                    : '',
                className: 'single-cluster',
                iconSize: L.point(size, size),
                iconAnchor: L.point(size / 2, size / 2),
                popupAnchor: L.point(0, -(size / 2) - 5),
              })
            : L.divIcon({
                html:
                  (numWorking > 0
                    ? '<div class="loctite-marker-cluster drop-shadow"><span>' +
                      numWorking +
                      ' </span></div>'
                    : '') +
                  (numInActive > 0
                    ? '<div class="loctite-marker-cluster inactive drop-shadow"><span>' +
                      numInActive +
                      ' </span></div>'
                    : '') +
                  (numWarning > 0
                    ? '<div class="loctite-marker-cluster warning drop-shadow"><span>' +
                      numWarning +
                      ' </span></div>'
                    : '') +
                  (numCritical > 0
                    ? '<div class="loctite-marker-cluster critical drop-shadow"><span>' +
                      numCritical +
                      ' </span></div>'
                    : ''),
                className:
                  numWorking > 0 || numCritical > 0
                    ? 'multi-cluster'
                    : 'single-cluster',
                iconSize: L.point(size, size),
                iconAnchor: L.point(size / 2, size / 2),
                popupAnchor: L.point(0, -(size / 2) - 5),
              });
        },
      });
      const items =
        this.markerMode === MarkerMode.buildings
          ? this.plants
              .map((plant) =>
                plant.children.map((building) => {
                  if (!building.geoPosition)
                    building.geoPosition = plant.geoPosition;
                  return building;
                })
              )
              .flat(2)
          : this.plants;
          
      items.map((plant) => {
        if (!!plant.geoPosition) {
          const numWorking =
            plant.Assets.length -
            plant.Critical.length -
            plant.Warning.length -
            plant.InActive.length;
          const numWarning = plant.Warning.length;
          const numCritical = plant.Critical.length;
          const numInActive = plant.InActive.length;
          const size =
            this.markerMode === MarkerMode.dots &&
            numCritical === 0 &&
            numWarning === 0 &&
            numInActive === 0
              ? ClustSizeByChildCount(numWorking)
              : 58;
          const icon =
            this.markerMode === MarkerMode.dots
              ? L.divIcon({
                  html:
                    numCritical > 0
                      ? '<div class="loctite-marker-cluster critical drop-shadow"><span>' +
                        numCritical +
                        ' </span></div>'
                      : numWarning > 0
                      ? '<div class="loctite-marker-cluster warning drop-shadow"><span>' +
                        numWarning +
                        ' </span></div>'
                      : numInActive > 0
                      ? '<div class="loctite-marker-cluster inactive drop-shadow"><span>' +
                        numInActive +
                        ' </span></div>'
                      : numWorking > 0
                      ? '<div class="loctite-marker-cluster dot drop-shadow"><span>' +
                        numWorking +
                        ' </span></div>'
                      : '',
                  className: 'single-cluster',
                  iconSize: L.point(size, size),
                  iconAnchor: L.point(size / 2, size / 2),
                  popupAnchor: L.point(0, -(size / 2) - 5),
                })
              : this.markerMode === MarkerMode.circles
              ? L.divIcon({
                  html:
                    (numWorking > 0
                      ? '<div class="loctite-marker-cluster drop-shadow"><span>' +
                        numWorking +
                        ' </span></div>'
                      : '') +
                    (numWarning > 0
                      ? '<div class="loctite-marker-cluster warning drop-shadow"><span>' +
                        numWarning +
                        ' </span></div>'
                      : '') +
                    (numInActive > 0
                      ? '<div class="loctite-marker-cluster inactive drop-shadow"><span>' +
                        numInActive +
                        ' </span></div>'
                      : '') +
                    (numCritical > 0
                      ? '<div class="loctite-marker-cluster critical drop-shadow"><span>' +
                        numCritical +
                        ' </span></div>'
                      : ''),
                  className:
                    numWorking > 0 && numCritical > 0
                      ? 'multi-cluster'
                      : 'single-cluster',
                  iconSize: L.point(size, size),
                  iconAnchor: L.point(size / 2, size / 2),
                  popupAnchor: L.point(0, -(size / 2) - 5),
                })
              : plant.curLayer === 'c'
              ? // plant marker:
                L.divIcon({
                  className: 'custom-div-icon',
                  html:
                    "<div class='plant-marker-pin drop-shadow" +
                    (plant.InActive.length > 0 &&
                    plant.Warning.length === 0 &&
                    plant.Critical.length === 0
                      ? ' inactive'
                      : '') +
                    (plant.Warning.length > 0 && plant.Critical.length === 0
                      ? ' warning'
                      : '') +
                    (plant.Critical.length > 0 ? ' critical' : '') +
                    "'><img class='plant-marker-thumbnail' src='" +
                    (!!plant['thumbnail'] ? plant['thumbnail'] : '') +
                    "' alt='" +
                    this.i18n.string('plant') +
                    "'><div class='plant-marker-info'><span class='plant-marker-name h2'>" +
                    plant.objectName +
                    ' [' +
                    plant.Assets.length +
                    ']' +
                    "</span><span class='plant-marker-status'>" +
                    (plant.Critical.length > 0 ||
                    plant.Warning.length > 0 ||
                    plant.InActive.length > 0
                      ? (plant.Critical.length == 1
                          ? plant.Critical.length +
                            ' ' +
                            this.i18n.string('critical_equipment')
                          : '') +
                        (plant.Critical.length > 1
                          ? plant.Critical.length +
                            ' ' +
                            this.i18n.string('critical_equipments')
                          : '') +
                        (plant.Critical.length > 0 || plant.Warning.length > 0
                          ? ' '
                          : '') +
                        (plant.Warning.length == 1
                          ? plant.Warning.length +
                            ' ' +
                            this.i18n.string('warning_equipment')
                          : '') +
                        (plant.Warning.length > 1
                          ? plant.Warning.length +
                            ' ' +
                            this.i18n.string('warning_equipments')
                          : '') +
                        (plant.InActive.length == 1
                          ? plant.InActive.length +
                            ' ' +
                            this.i18n.string('in_active_equipment')
                          : '') +
                        (plant.InActive.length > 1
                          ? plant.InActive.length +
                            ' ' +
                            this.i18n.string('in_active_equipments')
                          : '')
                      : plant.Working.length > 0
                      ? this.i18n.string('all_equipments_working_fine')
                      : '') +
                    '</span></div>',
                  iconSize: [298, 58],
                  iconAnchor: [29, 29],
                })
              : // building / area marker:
                L.divIcon({
                  className: 'custom-div-icon',
                  html:
                    "<div class='building-marker-pin drop-shadow" +
                    (plant.InActive.length > 0 &&
                    plant.Warning.length === 0 &&
                    plant.Critical.length === 0
                      ? ' inactive'
                      : '') +
                    (plant.Warning.length > 0 && plant.Critical.length === 0
                      ? ' warning'
                      : '') +
                    (plant.Critical.length > 0 ? ' critical' : '') +
                    "'><div class='building-marker-counter body-bold'><span>" +
                    (plant.critical
                      ? plant.Critical.length
                      : plant.warning
                      ? plant.Warning.length
                      : plant.inactive
                      ? plant.InActive.length
                      : plant.Assets.length) +
                    "</span></div><div class='building-marker-info'><span>" +
                    plant.objectName +
                    ' (' +
                    plant.Assets.length +
                    ')' +
                    '</span></div>',
                  iconSize: [247, 62],
                  iconAnchor: [29, 29],
                });
          // check if geoposition is valid coordinate
          const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
          const regexLon =
            /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

          let validLat = regexLat.test(plant.geoPosition.split(';')[0]);
          let validLon = regexLon.test(plant.geoPosition.split(';')[1]);
          if (validLat && validLon) {
            this.markers
              .addLayer(
                L.marker(
                  [
                    plant.geoPosition.split(';')[0],
                    plant.geoPosition.split(';')[1],
                  ],
                  {
                    icon: icon,
                    data: {
                      working: plant.Working.length,
                      warning: plant.Warning.length,
                      critical: plant.Critical.length,
                      inactive: plant.InActive.length,
                    },
                  }
                ).on('click', (e) => {
                  if (
                    this.markerMode === MarkerMode.plants ||
                    this.markerMode === MarkerMode.buildings
                  )
                    this.markerClick.emit(plant);
                  else {
                    const isMobile = this.Map.getSize().x < 550;
                    this.Map.fitBounds([e.latlng, e.latlng], {
                      padding: [100, 50],
                      paddingTopLeft: [
                        isMobile ? 25 : 80,
                        this.infoOverlay ? 224 : 100,
                      ],
                      paddingBottomRight: [
                        Math.min(250, this.Map.getSize().x - 100),
                        100,
                        //  this.infoOverlay ? 0 : 100,
                      ],
                      maxZoom:
                        this.Map.getZoom() <= ZOOM_BUILDING_THRESHOLD - 1
                          ? ZOOM_BUILDING_THRESHOLD - 1
                          : -1,
                      animate: true,
                      duration: 0.25,
                    });
                  }
                })
              )
              .off('clusterclick')
              .on('clusterclick', (a) => {
                const oldZoomLvl = this.Map.getZoom();
                const group = L.featureGroup(a.layer.getAllChildMarkers());
                const bounds = group.getBounds();
                const isMobile = this.Map.getSize().x < 550;

                this.Map.fitBounds(bounds, {
                  padding: [100, 50],
                  paddingTopLeft: [
                    isMobile ? 25 : 80,
                    this.infoOverlay ? 224 : 100,
                  ],
                  paddingBottomRight: [
                    Math.min(250, this.Map.getSize().x - 100),
                    // this.infoOverlay ? 20 : 100,
                    100,
                  ],
                  //maxZoom:
                  //  this.Map.getZoom() <= ZOOM_BUILDING_THRESHOLD - 1
                  //    ? ZOOM_BUILDING_THRESHOLD - 1
                  //    : null,
                  animate: true,
                  duration: 0.25,
                });
                setTimeout(() => {
                  if (this.Map.getZoom() === oldZoomLvl) {
                    this.Map.fitBounds(bounds, {
                      padding: [10, 100],
                      //  maxZoom:
                      //    this.Map.getZoom() <= ZOOM_BUILDING_THRESHOLD - 1
                      //      ? ZOOM_BUILDING_THRESHOLD - 1
                      //      : null,
                      animate: true,
                      duration: 0.25,
                    });
                    const epsilon2 = 0.001;

                    if (boundsWidth < epsilon2 && boundsHeight < epsilon2)
                      setTimeout(() => a.layer.spiderfy(), 200);
                    else if (oldZoomLvl < ZOOM_MAX_THRESHOLD)
                      this.Map.setZoom(oldZoomLvl + 1);
                  }
                }, 300);
                const epsilon = 0.000001;
                const boundsWidth = Math.abs(
                  bounds.getEast() - bounds.getWest()
                );
                const boundsHeight = Math.abs(
                  bounds.getNorth() - bounds.getSouth()
                );
                if (boundsWidth < epsilon && boundsHeight < epsilon)
                  setTimeout(() => a.layer.spiderfy(), 200);
              });
          }
        }
      });
      this.Map.addLayer(this.markers);
      setTimeout(() => {
        if (!this.markers._map) this.markers = null;
      });
    }
  }

  fitBounds(global: boolean = false, checkForMarkerModeChange: boolean = true) {
    if (!this.markers || !this.Map) return;
    const bounds = L.latLngBounds(
      this.markers.getLayers().map((item) => item._latlng)
    );

    const oldMarkerMode = +this.markerMode;
    const isMobile = this.Map.getSize().x < 550;

    this.Map.fitBounds(bounds, {
      padding: global ? [200, 200] : [300, this.infoOverlay ? 224 : 250],
      paddingTopLeft: global
        ? [200, 200]
        : [isMobile ? 25 : 380, this.infoOverlay ? 224 : 250],
      paddingBottomRight: global
        ? [0, 0]
        : [
            Math.min(250, this.Map.getSize().x - 100),
            // this.infoOverlay ? 20 : 100,
            100,
          ],
      //maxZoom:
      //  this.Map.getZoom() <= ZOOM_BUILDING_THRESHOLD - 1
      //    ? ZOOM_BUILDING_THRESHOLD - 1
      //    : null,
      animate: true,
      duration: 0.25,
    });
    if (this.Map.getZoom() <= 0) {
      this.Map.fitBounds(bounds, {
        animate: true,
        duration: 0.25,
      });
    }
    // zoom might have changed the marker mode, so we need to check for the bounds again
    setTimeout(() => {
      if (this.markerMode !== oldMarkerMode && checkForMarkerModeChange) {
        this.fitBounds(global, false);
      }
    });

    setTimeout(() => {
      if (this.Map.getZoom() <= 0) this.ZoomLevel = 1;
      else this.ZoomLevel = this.Map.getZoom();
    });
  }

  // onZoomOut() {
  //   this.Map.setZoom(this.Map.getZoom() - 1);
  // }

  // onZoomIn() {
  //   this.Map.setZoom(this.Map.getZoom() + 1);
  // }
}

function ClustSizeByChildCount(children: number) {
  const min_count = 5;
  const max_count = 750;
  const min_size = 40;
  const max_size = 150;

  let size = 40;

  if (children < min_count) size = min_size;
  else if (children > max_count) size = max_size;
  else {
    const factor = (children - min_count) / (max_count - min_count);
    size = factor * (max_size - min_size) + min_size;
  }
  return size;
}
