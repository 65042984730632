import { I18nService } from '../services/i18n.service';

export class GroupedAdditionalInfo {
  infoType: AdditionalInfoType;
  infoValues: AdditionalInfo[];
  displayInfoValues: AdditionalInfo[];
}

export class AdditionalInfoType {
  id: number;
  name: string;
}

export class AdditionalInfo {
  additionalInfoID?: number;
  objectID: number;
  equipmentTypeID?: number;

  category: string;
  disabled: boolean;
  optional: boolean;
  fieldType: string;

  icon?: boolean;

  // filled for field types single and multiple
  text?: string;
  value?: number;
  valueType?: string;

  minValue?: number;
  maxValue?: number;
  decimals?: number;

  // categorize additional info into different overall types
  infoTypeID?: number;
  infoTypeName?: string;

  // CR 162925 - saves user input without converting it by measurement system
  // A hack to stop rounding errors while users are typing
  valueInProgess: number = null;

  // metric vs imperial unit + conversion to meter
  unit?: { mt: string; im: string };
  unitPrefix?: string;
  conversionFactor?: { mt: number; im: number };

  // filled for field type multiple
  multipleValues?: string[];

  // filled for field type dropdown
  dropdownValues?: string[];

  // convenience
  currentMeasurementSystem: string;

  get convertedValue(): number {
    // a hack to stop rounding errors from preventing user input
    if (this.valueInProgess !== null) {
      return this.valueInProgess;
    }

    if (this.value === null) {
      return null;
    }

    if (this.valueType === 'temperature') {
      return (
        Math.round(
          ((this.currentMeasurementSystem === 'im'
            ? this.value * (9 / 5) + 32 // celsius to fahrenheit
            : this.value) +
            Number.EPSILON) *
            100
        ) / 100 // always round to two decimal places
      );
    }

    return (
      this.value /
      (this.conversionFactor[this.currentMeasurementSystem]
        ? this.conversionFactor[this.currentMeasurementSystem]
        : 1)
    );
  }

  set convertedValue(value: number) {
    this.valueInProgess = value;
    if (this.valueType === 'temperature') {
      this.value =
        this.currentMeasurementSystem === 'im' ? (value - 32) * (5 / 9) : value;
    } else {
      this.value =
        value *
        (this.conversionFactor[this.currentMeasurementSystem]
          ? this.conversionFactor[this.currentMeasurementSystem]
          : 1);
    }
  }

  labelkey(i18n: I18nService): string {
    const parsed = i18n.parse(this.category);
    return parsed[i18n.MeasurementSystem]
      ? parsed[i18n.MeasurementSystem]
      : parsed;
  }

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
