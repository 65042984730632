import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from '../../environments/environment';

declare const window: any;
declare const InstallTrigger: any; // for browser testing
@Injectable({
  providedIn: 'root',
})
export class UiService {
  private isMobile: boolean = null;
  private isTablet: boolean = null;
  private isDesktop: boolean = null;
  private orientationChange = new Subject<boolean>();
  private tabIndexChange = new Subject<number>();
  private tabIndex = null;
  private backBtnChange = new Subject<boolean>();
  private backBtn = null;
  private backBtnText: string = '';
  private backTextSubject = new Subject<string>();
  private backTextSubscription = null;
  private navBackCallback = null;
  private flag: boolean = false;

  get IsMobile(): boolean {
    if (this.isMobile === null) this.checkViewMode();
    return this.isMobile || this.isTablet;
  }
  get IsTablet(): boolean {
    if (environment.disableTabletView) return false;
    if (this.isTablet === null) this.checkViewMode();
    return this.isTablet;
  }
  get IsDesktop(): boolean {
    if (this.isDesktop === null) this.checkViewMode();
    return this.isDesktop;
  }

  get OnOrientationChange(): Subject<boolean> {
    return this.orientationChange;
  }

  get OnTabIndexChange(): Subject<number> {
    return this.tabIndexChange;
  }

  get TabIndex(): number {
    return this.tabIndex;
  }

  get OnBackBtnChang(): Subject<boolean> {
    return this.backBtnChange;
  }

  get BackBtn(): boolean {
    return this.backBtn;
  }

  constructor(private location: Location) {
    setTimeout(() => {
      this.checkViewMode();
    });
    window.addEventListener('resize', () => this.checkViewMode());
  }

  public onNavBack(callback) {
    this.navBackCallback = callback;
  }

  public goBack() {
    if (!!this.navBackCallback) this.navBackCallback();
    else this.location.back();
  }

  public setBackText(text: string) {
    this.backBtnText = text;
    this.backTextSubject.next(text);
  }

  public subscribeToBackText(callback) {
    //  if (!!this.backTextSubscription) this.backTextSubscription.unsubscribe();
    this.backTextSubscription = this.backTextSubject.subscribe(callback);
    this.setBackText(this.backBtnText);
  }

  public updateTabIndex(idx: number) {
    this.tabIndex = idx;
    this.tabIndexChange.next(idx);
  }

  public updateBackBtn(backBtn: boolean) {
    this.backBtn = backBtn;
    this.backBtnChange.next(backBtn);
  }

  get IsOpera(): boolean {
    // source: https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
    // Opera 8.0+
    return (
      (!!window['opr'] && !!window['opr']['addons']) ||
      !!window['opera'] ||
      navigator.userAgent.indexOf(' OPR/') >= 0
    );
  }

  get IsFirefox(): boolean {
    // source: https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
    // Firefox 1.0+
    return typeof InstallTrigger !== 'undefined';
  }

  get IsSafari(): boolean {
    // source: https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
    // Safari 3.0+ "[object HTMLElementConstructor]"
    return (
      /constructor/i.test(window.HTMLElement.toString()) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || window['safari']['pushNotification'])
    );
  }

  get IsIE(): boolean {
    // source: https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
    // Internet Explorer 6-11
    return !!document['documentMode'];
  }

  get IsEdge(): boolean {
    // source: https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
    // Edge 20+
    // return !this.IsIE && !!window['StyleMedia'];
    // source: https://stackoverflow.com/questions/31721250/how-to-target-edge-browser-with-javascript
    return window.navigator.userAgent.indexOf('Edg') > -1;
  }

  get IsChrome(): boolean {
    // source: https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
    // Chrome 1+
    return !!window['chrome'] && !this.IsOpera && !this.IsEdge; // &&
    // (!!window['chrome'].webstore || !!window['chrome'].runtime);
  }

  get IsBlink(): boolean {
    // source: https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
    // Blink engine detection
    return (this.IsChrome || this.IsOpera) && !!window.CSS;
  }

  public util_testBrowsers() {
    console.log('Detecting browsers by ducktyping:');
    console.log('  isFirefox: ' + this.IsFirefox);
    console.log('  isChrome: ' + this.IsChrome);
    console.log('  isSafari: ' + this.IsSafari);
    console.log('  isOpera: ' + this.IsOpera);
    console.log('  isIE: ' + this.IsIE);
    console.log('  isEdge: ' + this.IsEdge);
    console.log('  isBlink: ' + this.IsBlink);
  }

  private checkViewMode() {
    const mobile = environment.disableTabletView
      ? document.documentElement.clientHeight >
          document.documentElement.clientWidth ||
        document.documentElement.clientWidth < 895 ||
        document.documentElement.clientHeight < 600
      : document.documentElement.clientWidth < 767;
    const tablet = environment.disableTabletView
      ? false
      : document.documentElement.clientWidth >= 767 &&
        document.documentElement.clientWidth < 1121;
    const desktop = environment.disableTabletView
      ? !mobile
      : document.documentElement.clientWidth >= 1121;
    if (mobile) {
      document.body.classList.add('mobile');
      document.body.classList.remove('tablet');
      document.body.classList.remove('desktop');
      window.adobeDataLayer[0] ? window.adobeDataLayer[0].device['type']  =  "mobile" : undefined;
    } else if (tablet) {
      document.body.classList.add('tablet');
      document.body.classList.remove('mobile');
      document.body.classList.remove('desktop');
      window.adobeDataLayer[0] ? window.adobeDataLayer[0].device['type']  =  "tablet" : undefined;
    } else {
      document.body.classList.remove('mobile');
      document.body.classList.remove('tablet');
      document.body.classList.add('desktop');
      window.adobeDataLayer[0] ? window.adobeDataLayer[0].device['type']  =  "desktop" : undefined;
    }
    if (
      mobile !== this.isMobile ||
      tablet !== this.isTablet ||
      desktop !== this.isDesktop
    ) {
      this.isMobile = mobile;
      this.isTablet = tablet;
      this.isDesktop = desktop;
      this.orientationChange.next(
        this.isMobile || this.isTablet || this.isDesktop
      );
    }
  }
}
