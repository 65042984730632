import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { HistoryItem } from '../../model/history';
import { Location } from '../../model/location';
import { DateFormatSettings } from '../../model/settings';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss'],
})
export class HistoryItemComponent implements OnInit {
  @Input() item: HistoryItem;
  @Input() asset: Location;
  @Input() collapsible: boolean = true;
  @Input() shadow: boolean = false;
  @Input() dateFormat: DateFormatSettings = null;

  get Documentation(): boolean {
    return this.item.type === 'documentation';
  }
  get Red(): boolean {
    return this.item.display === 'critical';
  }
  get Yellow(): boolean {
    return this.item.display === 'warning';
  }
  get Green(): boolean {
    return this.item.display === 'working';
  }
  get Grey(): boolean {
    return this.item.display === 'inactive';
  }

  get Open(): boolean {
    return this.item.open || !this.collapsible;
  }

  get DateFormat(): string {
    return !!this.dateFormat && this.dateFormat.formatString === 'MM/dd/yyyy'
      ? 'MM/dd/yyyy | h:mm a'
      : 'dd-MM-yyyy | HH:mm';
  }

  get Message(): string {
    return this.enrichMessage(this.i18n.parse(this.item.message));
  }
  @Input() detailedhistory: boolean =
    false; /*true if called in asset sensor history, false if called in asset sensor value*/

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(public i18n: I18nService, private ui: UiService, public changeRef: ChangeDetectorRef,) {}

  enrichMessage(message: string) {
    return !!this.asset
      ? message
          .replace('{ObjectName}', this.asset.objectName)
          .replace('{Objektname}', this.asset.objectName)
      : message;
  }
  ngOnInit() {
    // this.changeRef.detectChanges();
  }
  ngAfterViewInit(): void {
    this.ui.OnOrientationChange.subscribe(() => this.changeRef.detectChanges());
  }
  openFile(file) {
    window.open(file.url, '_black', 'noreferrer');
  }

  onPhotoLoadingError(photo) {
    this.item.details.photos = this.item.details.photos.filter(
      (_photo) => _photo.picture !== photo.picture
    );
  }
}
