import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { StorageService } from '../../cc-framework/services/storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BannerNotificationService {
  constructor(private storage: StorageService, private http: HttpClient) {}

  public getMaintenanceMode(): Observable<any[]> {
    return this.http
      .get<any>(environment.host + 'api/maintenance/', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  public getBannerNotifications(): Observable<any[]> {
    return this.http
      .get<any>(environment.host + 'api/banner_notifications/', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  public dismissBannerNotification(bannerType: string): Observable<any> {
    return this.http
      .post<any>(
        environment.host + 'api/dismiss_banner_notification/',
        {
          BannerType: bannerType,
        },
        {
          headers: {
            Authorization: this.storage.Token,
          },
        }
      )
      .pipe(catchError(this.handleError<any[]>()));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
