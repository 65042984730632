import 'leaflet';
import 'leaflet.markercluster';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Marker } from '../../../model/marker';
import { I18nService } from '../../../services/i18n.service';
import { UiService } from '../../../services/ui.service';

const M = window['L'];

const enum SensorStatus {
  INPROGRESS = 'in operation',
  INACTIVE = 'in active',
  CRITICAL = 'critical',
}

@Component({
  selector: 'app-asset-overview-map',
  templateUrl: './asset-overview-map.component.html',
  styleUrls: ['./asset-overview-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetOverviewMapComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  Map: any = null;
  isLoading = false;

  zoomLevel: number = 13;
  satelliteView: boolean = false;
  center: number[] = [51.175545, 10.2180081];

  markers: any = [new Marker()];
  tileLayer: any = null;

  addedMarker = M.marker(this.center, {
    icon: M.divIcon({
      className: 'sensor-marker',
      // html: '<img src="assets/icons/marker-sensor-o.svg">',
      html: `
          <div class="sub-marker">  
          <img src="assets/icons/wifi.svg">     
          <span>${this.markers[0].label}</span>
          </div>
        `,
      iconSize: [56, 68],
      iconAnchor: [28, 68],
    }),
  });

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }

  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  @Input() set SensorPosition(position: { lat: number; lng: number }) {
    this.center = [position.lat, position.lng];
    this.addMarkers();
  }

  @Input() set Sensor(Sensor) {
    this.addedMarker = M.marker(this.center, {
      icon: M.divIcon({
        className: 'sensor-marker',
        // html: '<img src="assets/icons/marker-sensor-o.svg">',
        html:
          Sensor.Inactive && !Sensor.Critical && !Sensor.Warning
            ? `<div class="sub-marker body-bold">            
            <div class="asset-icon inactive">
              <img class="fillwhite" src="assets/icons/wifi.svg">
            </div>
          </div>
        `
            : Sensor.Critical && !Sensor.Inactive && !Sensor.Warning
            ? `<div class="sub-marker body-bold">            
            <div class="asset-icon critical">
              <img class="fillwhite" src="assets/icons/wifi.svg">
            </div>
          </div>
        `
            : Sensor.Warning && !Sensor.Inactive && !Sensor.Critical
            ? `<div class="sub-marker body-bold">            
          <div class="asset-icon warning">
            <img src="assets/icons/wifi.svg">
          </div>
         </div>
        `
            : `<div class="sub-marker body-bold">            
              <div class="asset-icon working">
                <img src="assets/icons/wifi.svg">
              </div>
            </div>
          `,
        iconSize: [56, 68],
        iconAnchor: [28, 68],
      }),
    });
    this.addMarkers();
  }

  @Input() set Loading(loading: boolean) {
    this.isLoading = loading;
    this.addMarkers();
  }

  constructor(private ui: UiService, public i18n: I18nService) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.initMap();
  }
  ngOnDestroy() {
    if (!!this.Map) {
      this.Map.off();
      this.Map.remove();
    }
  }

  initMap() {
    if (!!this.Map) {
      this.Map.off();
      this.Map.remove();
    }
    // L.Map.addInitHook('addHandler', 'scrollWheelZoom', L.Map.ScrollWheelZoom);
    M.Map.addInitHook('addHandler', 'scrollWheelZoom', M.Map.ScrollWheelZoom);

    // this fixes the marker icon shadow
    delete M.Icon.Default.prototype._getIconUrl;

    this.Map = M.map('AssetOverviewMap', {
      zoomControl: false,
    });
    this.tileLayer = null;
    this.addTiles();
    //this.addMarkers();
    setTimeout(() => {
      try {
        this.Map.invalidateSize();
        if (this.center[0] && this.center[1])
          this.Map.setView(this.center, this.zoomLevel);
      } catch (e) {
        console.error(e);
      }
      this.addMarkers();
    }, 500);
    this.Map.on('zoom', (event) => {
      this.zoomLevel = event.target._zoom;
    });
  }

  addTiles() {
    if (!!this.tileLayer) this.Map.removeLayer(this.tileLayer);
    const map_access = this.satelliteView
      ? environment.mapbox.satelliteView
      : environment.mapbox.mapView;
    this.tileLayer = M.tileLayer(map_access, {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      boxZoom: true,
      zoomAnimation: true,
      accessToken:
        'pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
    });
    this.tileLayer.addTo(this.Map);
  }

  addMarkers() {
    if (this.Map) {
      if (this.markers) this.Map.removeLayer(this.markers);
      this.markers = M.layerGroup({});

      this.addedMarker.setLatLng({ lat: this.center[0], lng: this.center[1] });
      // add a marker for the new sensor
      this.markers.addLayer(this.addedMarker);

      this.Map.addLayer(this.markers);
      if (this.center[0] && this.center[1])
        this.Map.setView(this.center, this.zoomLevel);
    }
  }
}
