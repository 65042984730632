import { AlertStatistics } from './../model/alert';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../cc-framework/services/storage.service';
import { tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlertStatisticsService {
  alertStats: AlertStatistics = null;
  constructor(
    private router: Router,
    private storage: StorageService,
    private http: HttpClient
  ) {
    // TODO update numbers regularly for subscribing components (and post notification)
    /* setInterval(
      () => this.updateReportingNumbers().then(() => this.ui.updateUI()),
      this.config.updateNotificationsInterval
    ); */
  }
  public updateReportingNumbers(): Promise<AlertStatistics> {
    return this.http
      .get<AlertStatistics>(environment.host + 'api/alert_stats/', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(
        tap((stats) => {
          this.alertStats = stats;
        })
      )
      .toPromise();
  }

  public getStats(): AlertStatistics {
    return this.alertStats;
  }
}
