<!-- Old version of the input labels:
<div class="input-label-wrapper">
  <label [for]="key" class="detail-bold">{{ label }}</label>
  <input [placeholder]="label" [name]="key" [id]="key" [value]="value ? value : null"
    (input)="onChange($event.target.value)" />
</div>-->


<nz-form-item class="input-label-wrapper">
  <nz-form-label class="regular"><span>{{ label }}</span><span *ngIf="optional" class="silver-chalice-text"> ({{i18n.string('optional')}})</span></nz-form-label>
  <nz-input-group>
    <input nz-input [ngModel]="value ? value : null" [placeholder]="label" [name]="key" [id]="key" oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');" (input)="onChange($event.target.value)"/>
  </nz-input-group>
</nz-form-item>
