import { Dictionary } from './dictionary';

export class Exception {
  protected name = new Dictionary<string>();
  protected message = new Dictionary<string>();
  protected error: Error;

  constructor(msg?: string) {
    if (msg) {
      this.message.set('de', msg);
      this.message.set('en', msg);
    }
  }

  public getException(): string {
    return this.constructor.name;
  }

  public getName(locale: string): string {
    return this.name.get(locale);
  }

  public getMessage(locale: string): string {
    return this.message.get(locale);
  }

  public getError(): Error {
    return this.error;
  }

  public getDialogOptions(locale: string): object {
    const options = {};
    options['error'] = true;
    options['title'] = this.getName(locale ? locale : 'en');
    options['message'] = this.getMessage(locale ? locale : 'en');
    options['timeout'] = -1;
    options['canRepeat'] = true;
    options['hasCloseButton'] = true;

    return options;
  }
}
