import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-legal-imprint',
  templateUrl: './legal-imprint.component.html',
  styleUrls: ['./legal-imprint.component.scss'],
})
export class LegalImprintComponent implements OnInit {
  constructor(public i18n: I18nService, public router: Router) {}

  ngOnInit(): void {}

  gotoLogin() {
    this.router.navigate(['login']);
  }
}
