<nz-modal *ngIf="OverlayPlant" [nzVisible]="OverlayPlant" [nzMask]="false" [nzFooter]="null" (nzOnCancel)="close.emit()"
  nzClassName="plant-modal" [nzCentered]="true" nzWidth="556" [nzCloseIcon]="'icons:exit'">
  <app-card *ngIf="critical == 0" [plant]="OverlayPlant" [fine]="true" [modal]="true" [closeBtn]="false"
    (close)="close.emit()" (nav)="close.emit()">
  </app-card>
  <app-card *ngIf="critical > 0" [plant]="OverlayPlant" [fine]="false" [modal]="true" [closeBtn]="false"
    (close)="close.emit()" (nav)="close.emit()">
    <ng-container *ngFor="let building of buildings">
      <div class="card-layer" *ngIf="building.critical_length > 0">
        <h2>{{ building.objectName }}</h2>
        <ng-container *ngFor="let equipment of building.children">
          <ng-container *ngIf="equipment.critical_length > 0">
            <span>{{ equipment.objectName }}</span>
            <ng-container *ngFor="let asset of equipment.critical_children">
              <!--<ng-container *ngFor="let asset of building.Critical">-->
              <app-asset-nav-button [asset]="asset" (click)="close.emit()" svg_icon="icons:status-exclamation-triangle">
                {{ asset.objectName | nameSlice}}
              </app-asset-nav-button>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </app-card>
</nz-modal>
