import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { UserSettings } from '../../model/settings';
import { GraphData } from '../../model/telemetry_history';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';
import {
  ValueRange,
  ValueRangeType,
} from '../../util/visualizations/value-range';
import {
  ConstructVizualization,
  VisualizationType,
} from '../../util/visualizations/visualization-type';
import { Visualization } from '../../util/visualizations/visualization.interface';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent implements OnInit {
  private data: GraphData = null;
  private chart: Visualization = null;
  private type: VisualizationType = null;
  public guid: string = uuidv4();

  // range:
  public historyStartDate: Date = new Date('01 Jan 1980 00:00:00 GMT');
  public historyEndDate: Date = new Date();
  public rangeStartDate: Date = new Date();
  public rangeEndDate: Date = new Date();
  public customRange: boolean = false;
  public isBrush: boolean = false; // brushing (zooming by selecting an area) is active

  @Input() hasResetButton: boolean = true;
  @Input() set Type(type: string) {
    switch (type.toLocaleLowerCase()) {
      case 'multi-line':
        this.type = VisualizationType.multi_line;
        break;
      case 'gantt':
        this.type = VisualizationType.gantt;
        break;
      case 'bars':
        this.type = VisualizationType.bars;
        break;
      case 'pie':
        this.type = VisualizationType.pie;
        break;
      default:
        this.type = VisualizationType.line;
    }
    if (
      !!this.data &&
      !!this.settings &&
      !!this.rangeStartDate &&
      !!this.rangeEndDate &&
      !!this.type
    ) {
      this.drawGraph();
    }
  }

  get Scrollable(): boolean {
    return (
      this.type === VisualizationType.bars ||
      this.type === VisualizationType.gantt
    ); // currently only bar charts are scrollable
  }

  @Input() displayResetBtn: boolean = false;

  @Input() set Data(data: GraphData) {
    this.data = data;
    if (
      !!this.data &&
      !!this.settings &&
      !!this.rangeStartDate &&
      !!this.rangeEndDate &&
      !!this.type &&
      !!this.chartContainer
    ) {
      this.drawGraph();
      this.chart.SetXDomain(
        this.rangeStartDate,
        this.rangeEndDate,
        this.customRange
      );
    }
  }

  @Input() set Timeframe(timeframe: {
    start: Date;
    end: Date;
    custom: boolean;
  }) {
    if (!timeframe) return;
    if (!this.rangeStartDate || !this.rangeEndDate) {
      this.rangeStartDate = timeframe.start;
      this.rangeEndDate = timeframe.end;
      this.customRange = timeframe.custom;
      if (
        !!this.data &&
        !!this.settings &&
        !!this.rangeStartDate &&
        !!this.rangeEndDate &&
        !!this.type &&
        !!this.chartContainer
      ) {
        this.drawGraph();
      }
    } else {
      this.rangeStartDate = timeframe.start;
      this.rangeEndDate = timeframe.end;
      this.customRange = timeframe.custom;
      if (
        !!this.data &&
        !!this.settings &&
        !!this.rangeStartDate &&
        !!this.rangeEndDate &&
        !!this.type
      ) {
        this.chart.SetXDomain(
          this.rangeStartDate,
          this.rangeEndDate,
          this.customRange
        );
      }
    }
  }

  private settings: UserSettings = null;
  @Input() set Settings(settings: UserSettings) {
    if (!this.settings) {
      this.settings = settings;
      if (
        !!this.data &&
        !!this.settings &&
        !!this.rangeStartDate &&
        !!this.rangeEndDate &&
        !!this.type &&
        !!this.chartContainer
      ) {
        this.drawGraph(true);
        this.chart.SetXDomain(
          this.rangeStartDate,
          this.rangeEndDate,
          this.customRange
        );
      }
    } else {
      this.settings = settings;
      if (
        !!this.data &&
        !!this.chart &&
        !!this.settings &&
        !!this.rangeStartDate &&
        !!this.rangeEndDate &&
        !!this.type
      ) {
        this.chart.SetXDomain(
          this.rangeStartDate,
          this.rangeEndDate,
          this.customRange
        );
      }
    }
  }

  @Output() brushStateChange = new EventEmitter<boolean>();
  @Output() timeframeUpdated: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  @Output() timeframeReset = new EventEmitter<void>();
  @ViewChild('chart', { static: false })
  private chartContainer: ElementRef;

  @ViewChild('chartFixed', { static: false })
  private chartContainerFixed: ElementRef;

  @ViewChild('scrollable', { static: false })
  private scrollable: ElementRef;

  get IsPie(): boolean {
    return this.type === VisualizationType.pie;
  }

  constructor(
    public ui: UiService,
    private i18n: I18nService,
    public changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (
      !!this.data &&
      !!this.settings &&
      !!this.rangeStartDate &&
      !!this.rangeEndDate &&
      !!this.type &&
      !!this.chartContainer
    ) {
      this.drawGraph(true);
      this.chart.SetXDomain(
        this.rangeStartDate,
        this.rangeEndDate,
        this.customRange
      );
    }
  }

  drawGraph(keepExtent: boolean = false) {
    if (!this.data) return;
    this.chart = ConstructVizualization(
      this.type,
      this.Scrollable
        ? [this.chartContainer, this.chartContainerFixed, this.scrollable]
        : [this.chartContainer],
      'lc' + this.data.metadata.measurementTypeID + this.guid,
      this.i18n
    );
    if (!this.chart) return;
    if (!keepExtent) this.chart.ResetXDomain();

    if (
      this.type === VisualizationType.multi_line &&
      (this.data.metadata.measurementTypeID === 1073 ||
        this.data.metadata.measurementTypeID === 1074)
    ) {
      this.chart['criticalOverride'] = this.data.metadata.critical;
      this.chart['warningOverride'] = this.data.metadata.warning;
    }
    let minY = Infinity;
    let maxY = -Infinity;
    let minTimestamp = Infinity;
    let maxTimestamp = -Infinity;

    let minThreshold = 0;
    let maxThreshold = 0;

    for (let i = 0; i < this.data.thresholds.length; i++) {
      if (minY > this.data.thresholds[i].thresholdMin) {
        minY = this.data.thresholds[i].thresholdMin;
        minThreshold = i;
      }

      if (maxY < this.data.thresholds[i].thresholdMax) {
        maxY = this.data.thresholds[i].thresholdMax;
        maxThreshold = i;
      }
    }
    /*
    for (let i = 0; i < this.data.telemetry.length; i++) {
      if (minY > this.data.telemetry[i].value)
        minY = this.data.telemetry[i].value;
      if (maxY < this.data.telemetry[i].value)
        maxY = this.data.telemetry[i].value;
      if (minTimestamp > Date.parse(this.data.telemetry[i].timestamp))
        minTimestamp = Date.parse(this.data.telemetry[i].timestamp);
      if (maxTimestamp < Date.parse(this.data.telemetry[i].timestamp))
        maxTimestamp = Date.parse(this.data.telemetry[i].timestamp);
    }
*/
    const ranges = this.data.thresholds.map(
      (item, index) =>
        new ValueRange(
          index === minThreshold ? minY : item.thresholdMin,
          index === maxThreshold ? maxY : item.thresholdMax,
          item.error
            ? ValueRangeType.red
            : item.warning
            ? ValueRangeType.yellow
            : ValueRangeType.green,
          item.measurementTypeID
        )
    );
    this.chart.SetValueUnit(
      this.data.metadata.unit ? this.data.metadata.unit : ''
    );
    if (this.data.metadata.zeroBasedValueRange && minY > 0) minY = 0;
    const booleanDomain =
      this.data.metadata.valueType === 'flag' ||
      this.data.metadata.valueType === 'boolean';
    if (this.data.metadata.measurementTypeID === 1028) {
      const min = Math.max.apply(
        null,
        this.data.telemetry.map((item) => item.value)
      );
      const max = Math.min.apply(
        null,
        this.data.telemetry.map((item) => item.value)
      );
      min < -90
        ? (minY = -90)
        : min == 0 && min > max
        ? (minY = max + 10)
        : (minY = min);
      max == 0 || max === min || max > -120 ? (maxY = -120) : (maxY = max - 10);
    }
    this.chart.SetYDomain(
      [minY, maxY],
      booleanDomain,
      this.data.metadata.measurementTypeID
    );
    if (booleanDomain) {
      this.chart['booleanDomainTrueMessage'] =
        this.i18n.String('tilt_true_message');
      this.chart['booleanDomainFalseMessage'] =
        this.i18n.String('tilt_false_message');
    }

    this.chart.SetValueRanges(ranges);
    this.chart.render(
      this.data.telemetry,
      this.ui.IsMobile || this.ui.IsTablet,
      this.settings
    );
    this.chart.SetXDomain(
      this.rangeStartDate,
      this.rangeEndDate,
      this.customRange
    );
    this.isBrush = false;
    this.chart.OnBrush((x_domain) => {
      this.isBrush = true;
      this.brushStateChange.emit(true);
      this.timeframeUpdated.emit([x_domain[0], x_domain[1], true]);
      console.log(x_domain, 'chartdata');
      this.changeRef.detectChanges();
    });
    this.chart.OnBrushReset((x_domain) => {
      this.isBrush = false;
      this.brushStateChange.emit(false);
      this.timeframeUpdated.emit([x_domain[0], x_domain[1], false]);
      console.log(x_domain, 'chartdata');
      this.changeRef.detectChanges();
    });
  }

  onResize() {
    this.drawGraph(true);
  }

  onReset() {
    if (this.isBrush) this.onResetBrush();
    this.onResetTimeframe();

    this.brushStateChange.emit(false);
  }

  onResetTimeframe() {
    this.timeframeReset.emit();
  }

  onResetBrush() {
    this.chart.ResetBrush();
    this.isBrush = false;
  }
}
function uuidv4() {
  // source: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  let d = new Date().getTime(); // Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; // Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}
