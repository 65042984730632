import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Location } from '../../../model/location';

@Component({
  selector: 'app-map-overlay',
  templateUrl: './map-overlay.component.html',
  styleUrls: ['./map-overlay.component.scss'],
})
export class MapOverlayComponent implements OnInit {
  private overlayPlant: Location = null;
  public critical: number = 0;
  public buildings: Location[] = [];
  @Input() set OverlayPlant(plant: Location) {
    this.overlayPlant = plant;
    if (!plant) return;
    // the following stuff is actually necessary because angular is too dumb to check whether Location.Critical
    // has updated, so it checks it all the time and causes the browser to crash
    this.critical = this.overlayPlant.Critical.length;
    this.buildings = this.overlayPlant.children.map((building: Location) => {
      building['critical_length'] = building.Critical.length;
      building.children = building.children.map((equipment: Location) => {
        equipment['critical_length'] = equipment.Critical.length;
        equipment['critical_children'] = equipment.Critical;
        return equipment;
      });
      return building;
    });
  }

  get OverlayPlant(): Location {
    return this.overlayPlant;
  }
  @Output() close = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}
}
