<h1>{{i18n.string('cookie_consent')}}</h1>
<br/>
<p class="cookie-consent-content"> {{i18n.string('cookie_consent_first_paragraph')}}</p>
<br/>
<p class="cookie-consent-content">
  {{i18n.string('cookie_consent_second_paragraph')}}
  <br /><br />
</p>
<p class="cookie-consent-content"> {{i18n.string('cookie_consent_third_paragraph')}}</p>
<br/>
<p class="cookie-consent-content">
  {{i18n.string('cookie_consent_fourth_paragraph')}}
  <br /><br />
</p>
<p class="cookie-consent-content"> {{i18n.string('cookie_consent_fifth_paragraph')}}</p>
<br/>
<p class="body-bold">{{i18n.string('cookie_consent_subtitle')}}</p>
<p class="cookie-consent-content">
  {{i18n.string('cookie_consent_sixth_paragraph')}}
  <br /><br />
</p>