import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-overlay-card-header',
  templateUrl: './overlay-card-header.component.html',
  styleUrls: ['./overlay-card-header.component.scss'],
})
export class OverlayCardHeaderComponent implements OnInit {
  @Input() header = '';
  @Input() closeBtn: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() nav: EventEmitter<void> = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}
  onNav() {
    this.nav.emit();
  }
}
