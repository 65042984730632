import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AdditionalInfo } from '../../../model/additionalinfo';
import { CurrentTelemetry } from '../../../model/sensor';
import { UserSettings } from '../../../model/settings';
import { GraphData } from '../../../model/telemetry_history';
import { I18nService } from '../../../services/i18n.service';
import { UiService } from '../../../services/ui.service';
import { GraphDisplay } from '../asset-overview.component';

const STEAM_TRAP_STATUS_ID = 1068;
const STEAM_TRAP_STEAM_LOSS_AMOUNT_ID = 1069;
const STEAM_TRAP_STEAM_LOSS_MASS_ID = 1070;

@Component({
  selector: 'app-steam-trap-analytics',
  templateUrl: './steam-trap-analytics.component.html',
  styleUrls: ['./steam-trap-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SteamTrapAnalyticsComponent implements OnInit {
  graphDisplays: GraphDisplay[] = [];
  @Input() set NoPricePerTon(noPricePerTon: boolean) {
    if (noPricePerTon) {
      this.displaySteamTrapLossAmount = false;
      this.hideSteamTrapLossAmount = true;
    }
  }
  @Input() set GraphDisplays(graphDisplays: GraphDisplay[]) {
    this.graphDisplays = graphDisplays;
    this.calcSteamLossInfo();
    this.calcSteamLossAmountPrediction();
    this.calcSteamLossMassPrediction();
  }
  graphData: GraphData[] = [];
  @Input() set GraphData(graphData: GraphData[]) {
    this.graphData = graphData;
    this.setSteamLossMassUnitString();
    this.setSteamLossCurrencyUnitGraph();
    this.calcSteamLossInfo();
    this.calcSteamLossAmountPrediction();
    this.calcSteamLossMassPrediction();
  }
  telemetry: CurrentTelemetry[] = [];
  @Input() set SteamTrapTelemetry(telemetry: CurrentTelemetry[]) {
    this.telemetry = telemetry;
    this.calcSteamLossInfo();
    this.calcSteamLossAmountPrediction();
    this.calcSteamLossMassPrediction();
  }
  get Telemetry(): CurrentTelemetry {
    if (!this.telemetry || this.telemetry.length === 0) return null;
    return this.telemetry[0];
  }
  @Input() loading: boolean = false;
  @Input() isTelemetryTimeframeCustom: boolean = false;
  @Input() set Range(range: Date[]) {
    this.range = range;

    this.calcSteamLossInfo();
    this.calcSteamLossAmountPrediction();
    this.calcSteamLossMassPrediction();
  }

  public displaySteamTrapLossAmount = true;
  public hideSteamTrapLossAmount = false;
  public graphBrushActive: boolean = false;

  public range: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 1)),
    new Date(),
  ];
  @Input() settings: UserSettings = null;

  @Input() SteamLossCurrencyUnit: AdditionalInfo = null;

  @Output() OnTelemetryTimeframeSelect = new EventEmitter<{
    index: number;
    telemetry: CurrentTelemetry;
  }>();
  @Output() OnRangeStartPick = new EventEmitter<{
    measurementTypeID: number;
    sensorID: number;
    event: any;
    endPickerID: number;
    data: any;
  }>();
  @Output() OnRangeEndPick = new EventEmitter<{
    measurementTypeID: number;
    sensorID: number;
    event: any;
    data: any;
  }>();
  @Output() OpenRangePicker = new EventEmitter<{
    id: number;
    data: any;
  }>();
  @Output() OnRangePickerSelect = new EventEmitter<{
    event: any;
    data: any;
  }>();
  @Output() RangePickerDisabledDate = new EventEmitter<{
    data: any;
    id: number;
  }>();
  @Output() dateRangeUpdatedfromGraph = new EventEmitter<string[]>();
  @ViewChildren('datePick') datePickers: QueryList<any>;

  public steamTrapLossMassSum: string = '';
  public steamTrapLossAmountSum: string = '';

  public steamTrapLossAmount1Week: number;
  public steamTrapLossAmount1Month: number;
  public steamTrapLossAmount3Months: number;

  public steamTrapLossMass1Week: number;
  public steamTrapLossMass1Month: number;
  public steamTrapLossMass3Months: number;

  public steamLossCurrencyUnit: string = '€';
  public steamLossMassUnit: string = '';

  get SteamTrapStatusCurrentTelemetry(): CurrentTelemetry {
    if (!this.graphDisplays) return null;
    const statusGraphDisplay = this.graphDisplays.find(
      (item) => item.currentTelemetry.measurementTypeID === STEAM_TRAP_STATUS_ID
    );
    if (!statusGraphDisplay) return null;
    return statusGraphDisplay.currentTelemetry;
  }

  get SteamTrapHealth(): GraphDisplay[] {
    if (!this.graphDisplays) return [];
    const steamTrapHealth = this.graphDisplays.find(
      (display) =>
        display.currentTelemetry.measurementTypeID === STEAM_TRAP_STATUS_ID
    );
    return steamTrapHealth ? [steamTrapHealth] : [];
  }
  get SteamTrapLoss(): GraphDisplay[] {
    return this.displaySteamTrapLossAmount && this.HasSteamTrapLossAmount
      ? this.SteamTrapLossAmount
      : this.SteamTrapLossMass;
  }

  get HasSteamTrapLossAmount(): boolean {
    const result = !!this.graphDisplays.find(
      (display) =>
        display.currentTelemetry.measurementTypeID ===
        STEAM_TRAP_STEAM_LOSS_AMOUNT_ID
    );
    if (!result) this.displaySteamTrapLossAmount = false;
    return result && !this.hideSteamTrapLossAmount;
  }

  get HasSteamTrapLossMass(): boolean {
    return !!this.graphDisplays.find(
      (display) =>
        display.currentTelemetry.measurementTypeID ===
        STEAM_TRAP_STEAM_LOSS_MASS_ID
    );
  }

  get SteamTrapLossAmount(): GraphDisplay[] {
    if (!this.graphDisplays) return [];
    const steamTrapLossAmount = this.graphDisplays.find(
      (display) =>
        display.currentTelemetry.measurementTypeID ===
        STEAM_TRAP_STEAM_LOSS_AMOUNT_ID
    );
    return steamTrapLossAmount ? [steamTrapLossAmount] : [];
  }

  get SteamTrapLossMass(): GraphDisplay[] {
    if (!this.graphDisplays) return [];
    const steamTrapLossMass = this.graphDisplays.find(
      (display) =>
        display.currentTelemetry.measurementTypeID ===
        STEAM_TRAP_STEAM_LOSS_MASS_ID
    );
    return steamTrapLossMass ? [steamTrapLossMass] : [];
  }

  get CriticalOverride() {
    return {};
  }

  get WarningOverride() {
    return {};
  }

  get DateFormat(): string {
    return this.settings.DateFormat.formatString;
  }
  get Locale(): string {
    return this.settings.Language.abbreviation === 'de' ? 'de-DE' : 'en-US';
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  private lastTimeframeSelectIndex: number = 0;

  constructor(public i18n: I18nService, public ui: UiService) {}

  ngOnInit() {
    if (!environment.disableSelectedSteamRateCurrency) {
      this.steamLossCurrencyUnit = JSON.parse(
        this.SteamLossCurrencyUnit.text
      ).en;
    }
  }

  onTelemetryTimeframeSelect(index: number, telemetry: CurrentTelemetry): void {
    this.lastTimeframeSelectIndex = index;
    this.OnTelemetryTimeframeSelect.emit({
      index: index,
      telemetry: telemetry,
    });
  }

  onDateRangeUpdatedfromGraph(event) {
    this.dateRangeUpdatedfromGraph.emit(event);
  }

  onRangeStartPick(measurementTypeID, sensorID, event, endPickerID, data) {
    this.OnRangeStartPick.emit({
      measurementTypeID: measurementTypeID,
      sensorID: sensorID,
      event: event,
      endPickerID: endPickerID,
      data: data,
    });
  }
  onRangeEndPick(measurementTypeID, sensorID, event, data) {
    this.OnRangeEndPick.emit({
      measurementTypeID: measurementTypeID,
      sensorID: sensorID,
      event: event,
      data: data,
    });
  }

  onRangePickerSelect(event, data) {
    this.OnRangePickerSelect.emit({
      event: event,
      data: data,
    });
  }

  openRangePicker(id, data) {
    this.OpenRangePicker.emit({
      id: id,
      data: data,
    });
  }
  getVisibleGraphs(telemetry: CurrentTelemetry) {
    if (!telemetry) return [];
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !!display && display.graphData
      ? display.graphData.filter((graphData) => graphData.metadata.displayGraph)
      : [];
  }
  noData(data: GraphData) {
    return !data || !data.telemetry.length;
  }
  noDataTelemetry(telemetry: CurrentTelemetry) {
    return !this.getVisibleGraphs(telemetry).find(
      (data: GraphData) => !this.noData(data)
    );
  }
  rangePickerDisabledDate(data, pickerIndex): (current: Date) => boolean {
    if (!data) {
      return (current: Date) => false;
    }
    let otherDate = null; // otherDate removes the ability to create empty timespans (in other words, pick the same day twice)
    if (pickerIndex === 1) otherDate = this.range[1];
    if (pickerIndex === 2) otherDate = this.range[0];
    if (!!otherDate) otherDate = new Date(otherDate.toDateString()); // remove time from datetime object
    let first: Date = new Date(Date.parse(data.metadata.firstMeasurement));
    first = new Date(first.toDateString()); // remove time from datetime object
    let last: Date = new Date(Date.parse(data.metadata.lastMeasurement));
    last = new Date(last.toDateString()); // remove time from datetime object
    const fn = (current: Date) => {
      current = new Date(current.toDateString()); // remove time from datetime object
      return current < first || current > last || +current === +otherDate;
    };
    return fn;
  }

  cancelRangePick(data) {
    data.openRangePicker = 0;
    if (this.range[0] > this.range[1]) {
      const temp = this.range[0];
      this.range[0] = this.range[1];
      this.range[1] = temp;
    }
    this.datePickers.forEach((item) => item.close());
  }

  getTimeframe(telemetry: CurrentTelemetry) {
    const display: GraphDisplay = this.graphDisplays.find(
      (graphDisplay) =>
        graphDisplay.currentTelemetry.measurementTypeID ===
          telemetry.measurementTypeID &&
        graphDisplay.currentTelemetry.sensor.sensorID ===
          telemetry.sensor.sensorID
    );
    return !!display ? display.timeframe : null;
  }

  resetTimeframeSelect(telemetry: CurrentTelemetry) {
    this.onTelemetryTimeframeSelect(this.lastTimeframeSelectIndex, telemetry);
  }

  private calcSteamLossInfo() {
    // make sure to cutoff at midnight:
    const rangeStart = new Date(this.range[0].toDateString());
    const rangeEnd = new Date(
      new Date(new Date(this.range[1]).setDate(this.range[1].getDate() + 1))
    );
    const amountValue: number = this.SteamTrapLossAmount.map(
      (display: GraphDisplay) =>
        !display.graphData
          ? 0
          : display.graphData
              .map((data: GraphData) =>
                data.telemetry
                  .filter((telemetry) => {
                    return (
                      new Date(telemetry.timestamp).getTime() >
                        rangeStart.getTime() &&
                      new Date(telemetry.timestamp).getTime() <
                        rangeEnd.getTime()
                    );
                  })
                  .map((telemetry) => telemetry.value)
                  .reduce((a, b) => a + b, 0)
              )
              .reduce((a, b) => a + b, 0)
    ).reduce((a, b) => a + b, 0);
    this.steamTrapLossAmountSum =
      amountValue > 1000
        ? (amountValue / 1000).toFixed(1) + ' K'
        : amountValue.toFixed(2);
    const amountMass: number = this.SteamTrapLossMass.map(
      (display: GraphDisplay) =>
        !display.graphData
          ? 0
          : display.graphData
              .map((data: GraphData) =>
                data.telemetry
                  .filter(
                    (telemetry) =>
                      new Date(telemetry.timestamp).getTime() >
                        rangeStart.getTime() &&
                      new Date(telemetry.timestamp).getTime() <
                        rangeEnd.getTime()
                  )
                  .map((telemetry) => telemetry.value)
                  .reduce((a, b) => a + b, 0)
              )
              .reduce((a, b) => a + b, 0)
    ).reduce((a, b) => a + b, 0);
    this.steamTrapLossMassSum =
      amountMass > 1000
        ? (amountMass / 1000).toFixed(1) + ' K'
        : amountMass.toFixed(2);
  }

  onTabChanged(tab) {
    switch (tab) {
      case 0:
        this.displaySteamTrapLossAmount = true;
        this.calcSteamLossAmountPrediction();
        break;
      case 1:
        this.displaySteamTrapLossAmount = false;
        this.calcSteamLossMassPrediction();
        break;
    }
  }

  calcSteamLossAmountPrediction() {
    const stemTrapLossAmt =
      this.SteamTrapLossAmount[0].currentTelemetry.Value['val'];
    this.steamTrapLossAmount1Week = this.displayValue(stemTrapLossAmt * 24 * 7);
    this.steamTrapLossAmount1Month = this.displayValue(
      stemTrapLossAmt * 24 * 30
    );
    this.steamTrapLossAmount3Months = this.displayValue(
      stemTrapLossAmt * 24 * 90
    );
  }

  calcSteamLossMassPrediction() {
    const stemTrapLossMss =
      this.SteamTrapLossMass[0].currentTelemetry.Value['val'];
    this.steamTrapLossMass1Week = this.displayValue(stemTrapLossMss * 24 * 7);
    this.steamTrapLossMass1Month = this.displayValue(stemTrapLossMss * 24 * 30);
    this.steamTrapLossMass3Months = this.displayValue(
      stemTrapLossMss * 24 * 90
    );
  }

  setSteamLossCurrencyUnitGraph() {
    this.SteamTrapLossAmount.map((display: GraphDisplay) =>
      !display.graphData
        ? 0
        : display.graphData.map(
            (data: GraphData) =>
              (data.metadata.unit = this.steamLossCurrencyUnit)
          )
    );
  }

  setSteamLossMassUnitString() {
    if(!this.SteamTrapLossMass || !this.SteamTrapLossMass[0].graphData) return '';
    const SteamTrapLossMassUnit = this.SteamTrapLossMass[0].graphData[0].metadata.unit;
    this.steamLossMassUnit = SteamTrapLossMassUnit !== '' ? SteamTrapLossMassUnit : 'Kg'
  }

  showPrediction() {
    return (
      this.SteamTrapLossAmount[0].currentTelemetry.critical ||
      this.SteamTrapLossAmount[0].currentTelemetry.warning ||
      this.SteamTrapLossAmount[0].currentTelemetry.inactive ||
      this.SteamTrapLossMass[0].currentTelemetry.critical ||
      this.SteamTrapLossMass[0].currentTelemetry.warning ||
      this.SteamTrapLossMass[0].currentTelemetry.inactive
    );
  }

  displayValue = (value) => {
    return value > 1000 ? (value / 1000).toFixed(1) + ' K' : value.toFixed(2);
  };
}
