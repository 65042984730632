import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { scan } from 'rxjs/operators';

import { StorageService } from '../../cc-framework/services/storage.service';
import { environment } from '../../environments/environment';
import { Documentation } from './../model/documentation';

/**
 * Service to create and save Documentation and add or delete Attachments
 *
 * Here is how it works:
 *
 * ***** FIRST STEP *****
 * When the documentation popup opens, hand the asset ID to getUnfinishedDocumentation(..)
 * If there is wip documentation for this asset and the current user, it will return that.
 * Otherwise a new documentation is created and returned. (You will need its id)
 * This has to happen FIRST, because the design requires each attachment to be uploaded and
 * saved before the save button is clicked.
 *
 * ***** UPDATES *****
 * To change note, documentation type etc. use updateDocumentation(..)
 *
 * ***** ATTACHMENTS *****
 * To add attachments upload the file with uploadAttachment(..) and subscribe
 * to the result to receive a finished Attachment item including storage links
 * for file and image thumbnail.
 * It does return intermediate tracing results. Look for a body property in the response.
 *
 * To get a more readable progress percentage, put the observable returned from
 * uploadAttachment(..) into traceUploadProgress(..) and subscribe to get Upload objects.
 *
 * (Upload progress from https://nils-mehlhorn.de/posts/angular-file-upload-progress)
 *
 * Use deleteAttachment(..) to remove an attachment from db and storage.
 *
 * ***** LAST STEP *****
 * Once the save button is clicked, run finishDocumentation(..) to write to event history.
 *
 * @export
 * @class DocumentationService
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  private loading: Subject<boolean> = new Subject();

  constructor(private storage: StorageService, private http: HttpClient) {}

  get Loading(): Subject<boolean> {
    return this.loading;
  }

  onLoadingStarted() {
    this.loading.next(true);
  }

  onLoadingFinished() {
    this.loading.next(false);
  }

  getUnfinishedDocumentation(
    assetId: number,
    sensorId: number
  ): Observable<Documentation> {
    if (!assetId) {
      return of(new Documentation());
    }

    return this.http.get<any>(
      environment.host +
        'api/unfinished_documentation/' +
        assetId +
        (!!sensorId && sensorId > 0 ? '/' + sensorId : ''),
      {
        headers: {
          Authorization: this.storage.Token,
        },
      }
    );
  }

  finishDocumentation(documentationId: number): Observable<boolean> {
    if (!documentationId) {
      return of(false);
    }

    return this.http.get<any>(
      environment.host + 'api/finish_documentation/' + documentationId,
      {
        headers: {
          Authorization: this.storage.Token,
        },
      }
    );
  }

  updateDocumentation(documentation: Documentation): Observable<Documentation> {
    if (!documentation) {
      return of(new Documentation());
    }

    return this.http.post<any>(
      environment.host + 'api/update_documentation/',
      {
        documentation: JSON.stringify(documentation),
      },
      {
        headers: {
          Authorization: this.storage.Token,
        },
      }
    );
  }

  uploadAttachment(
    documentationId: number,
    attachmentType: string,
    fileName: string,
    fileData: string
  ): Observable<any> {
    return this.http.post<any>(
      environment.host + 'api/upload_documentation_attachment/',
      {
        documentationId: documentationId,
        attachmentType: attachmentType,
        fileName: fileName,
        fileData: fileData,
      },
      {
        headers: {
          Authorization: this.storage.Token,
        },
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  traceUploadProgress(upload: Observable<any>): Observable<Upload> {
    const initialState: Upload = { state: 'PENDING', progress: 0, event: null };
    const calculateState = (
      _upload: Upload,
      event: HttpEvent<unknown>
    ): Upload => {
      if (this.isHttpProgressEvent(event)) {
        return {
          progress: event.total
            ? Math.min(Math.round((100 * event.loaded) / event.total), 98) // leave progress bar unfinished until done event is received
            : _upload.progress,
          state: 'IN_PROGRESS',
          event: event,
        };
      }
      if (this.isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
          event: event,
        };
      }
      return _upload;
    };
    return upload.pipe(scan(calculateState, initialState));
  }

  deleteAttachment(attachmentId: number): Observable<boolean> {
    return this.http.post<any>(
      environment.host + 'api/delete_documentation_attachment/',
      {
        attachmentId: attachmentId,
      },
      {
        headers: {
          Authorization: this.storage.Token,
        },
      }
    );
  }

  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  private isHttpProgressEvent(
    event: HttpEvent<unknown>
  ): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
    );
  }
}

export interface Upload {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  event: HttpEvent<unknown>;
}
