import { ElementRef } from '@angular/core';
import * as d3 from 'd3';

import { UserSettings } from '../../model/settings';
import { I18nService } from '../../services/i18n.service';
import { ValueRange, ValueRangeType } from './value-range';
import { Visualization } from './visualization.interface';

enum TickType {
  Hour = 0,
  Day,
  Week,
  Month,
  Year,
}
class GanttChartConfig {
  brackets: GanttChartBracket[];
}

class GanttChartBracket {
  min: number;
  max: number;
  critical: boolean;
  warning: boolean;
  label: { de: string; en: string };
  range_label: string;
}

const GANTT_CHART_CONFIG_MOCK: GanttChartConfig = {
  brackets: [
    {
      min: 65,
      max: 110,
      critical: true,
      warning: false,
      label: { de: 'Blockiert', en: 'Blocked' },
      range_label: null,
    },
    {
      min: 30,
      max: 65,
      critical: false,
      warning: true,
      label: { de: 'Durchstrahlen', en: 'Blow through' },
      range_label: null,
    },
    {
      min: -1,
      max: 30,
      critical: false,
      warning: false,
      label: { de: 'In Ordnung', en: 'Working' },
      range_label: null,
    },
  ],
};

const LOCALE_EN = {
  dateTime: '%x, %X',
  date: '%-m/%-d/%Y',
  time: '%-I:%M:%S %p',
  periods: ['AM', 'PM'],
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

const LOCALE_DE = {
  dateTime: '%A, der %e. %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
};

export class GanttChart implements Visualization {
  private x_domain = null; // this defines the section of the historical data we want to see
  private y_domain = null; // this defines the section of the values we are interested in
  private valueRanges: ValueRange[];
  private y_unit: string = '';
  private customRange: boolean = false;
  // TODO hardcoded for dev purposes
  private ganttChartConfig: GanttChartConfig = GANTT_CHART_CONFIG_MOCK;

  private data: any[];
  private original_data: any[];
  public updateChart;
  private resetBrush;
  private brushCallback;
  private brushResetCallback;

  private chartContainer: ElementRef;
  private chartContainerFixed: ElementRef;
  private scrollable: any;

  constructor(
    private chartContainers: ElementRef[],
    private id: string,
    private i18n: I18nService
  ) {
    this.chartContainer = chartContainers[0];
    this.chartContainerFixed = chartContainers[1];
    this.scrollable = chartContainers[2];
  }

  public render(data: any[], isMobile, settings: UserSettings) {
    if (!!data) {
      this.original_data = data;
      this.data = data.map((t) => {
        return {
          date: new Date(t.timestamp),
          value: t.value,
          text: t.measuredValue,
        };
      });
    }

    this.renderGraph(isMobile, settings);
  }

  public SetValueUnit(unit: string) {
    this.y_unit = unit;
  }

  public SetXDomain(start: Date, end: Date, custom: boolean = false) {
    // set right graph cutoff to midnight of the current day (or next day 00:00):

    /* if (!custom && (end.getHours() > 0 || end.getMinutes() > 0)) {
      end = new Date(new Date(end).setDate(end.getDate()) + 1);
    }*/
    // if we are on steamtrap, set end to midnight of the most recent day, to show full gantt chart
    if (!custom && (end.getHours() > 0 || end.getMinutes() > 0)) {
      const diff = end.getTime() - start.getTime();
      end = new Date(
        new Date(new Date(end).setDate(end.getDate() + 1)).toDateString()
      );
      start = new Date(end.getTime() - diff);
    }
    if (!custom) start = new Date(start.toDateString());
    if (!custom) end = new Date(end.toDateString());
    this.x_domain = d3.extent([start, end], (d) => d);

    this.customRange = custom;
    if (!!this.updateChart) this.updateChart();
  }
  public ResetXDomain() {
    this.x_domain = null;
  }
  public SetYDomain(
    domain: [number, number],
    booleanDomain: boolean = false,
    measurementID: number
  ) {
    // this.y_domain = domain;
    // fixed y_domain for gantt charts: 0-100
    this.y_domain = [0, 100];
  }
  public ResetYDomain() {
    this.y_domain = null;
  }
  public SetValueRanges(ranges: ValueRange[]) {
    this.valueRanges = ranges;
  }

  private approximateTickNumber(): number {
    function groupBy(xs, transform) {
      return xs.reduce(function (rv, x) {
        (rv[transform(x)] = rv[transform(x)] || []).push(x);
        return rv;
      }, {});
    }
    /* const groupTemp = this.fillGaps(
      groupBy(data, (x) => pruneTimestamp(x.timestamp, type, true)),
      type
    ); */
    var type: TickType = TickType.Month;

    if (!!this.x_domain) {
      if (daysBetween(this.x_domain[0], this.x_domain[1]) < 15) {
        type = TickType.Day;
      } else if (daysBetween(this.x_domain[0], this.x_domain[1]) < 42) {
        type = TickType.Week;
      }
    }

    const groupTemp = groupBy(this.original_data, (x) =>
      pruneTimestamp(x.timestamp, type, true)
    );
    const dates = Object.keys(groupTemp)
      .map((item) => new Date(item))
      .sort((a: Date, b: Date) => +a - +b);
    const min = dates[0];
    const max = dates[dates.length - 1];
    let full_range = [];
    switch (type) {
      /*
      case TickType.Hour:
        full_range = d3.timeHours(min, max);
        break;
        */
      case TickType.Day:
        full_range = d3.timeDays(min, max);
        break;
      case TickType.Week:
        full_range = d3
          .timeDays(min, max)
          .filter((date) => date.getDay() === 1);
        break;
      case TickType.Month:
        full_range = d3.timeMonths(min, max);
        break;
      /*
      case TickType.Year:
        full_range = d3.timeYears(min, max);
        break;
        */
    }

    full_range.map((date) => {
      date = pruneTimestamp(date, type, true);
      if (!groupTemp[date.toString()]) {
        groupTemp[date.toString()] = [
          {
            measurementTypeID: groupTemp[min.toString()].measurementTypeID,
            value: 0,
            measuredValue: '',
            timestamp: date.toISOString(),
          },
        ];
      }
    });

    const data = Object.keys(groupTemp)
      .map((key) =>
        groupTemp[key].reduce(
          (a, b) =>
            new Object({
              measuredValue: '',
              measurementTypeID: b.measurementTypeID,
              timestamp: !!a.timestamp
                ? a.timestamp
                : pruneTimestamp(b.timestamp, type, true),
              date: !!a.timestamp
                ? a.timestamp
                : pruneTimestamp(b.timestamp, type, true),
              value:
                Math.round((a.value + b.value + Number.EPSILON) * 100) / 100,
            }),
          {
            measuredValue: '',
            measurementTypeID: null,
            timestamp: null,
            date: null,
            value: 0,
          }
        )
      )
      .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

    if (!!this.x_domain)
      return data.filter(
        (d) =>
          d.timestamp >= this.x_domain[0] && d.timestamp <= this.x_domain[1]
      ).length;
    else return data.length;
  }

  private renderGraph(isMobile: boolean, settings: UserSettings) {
    d3.select('svg#' + this.id).remove();
    d3.select('svg#' + this.id + '-fixed').remove();
    const max_width = this.chartContainer.nativeElement.clientWidth;
    const max_height = this.chartContainer.nativeElement.clientHeight;
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
    // display a maximum of 10 ticks at once:
    const SMALLER_TICK_THRESHOLD = 8;
    // const MAX_TICKS = 10;
    const MAX_TICKS_WIDE_SCREEN = 10;
    const MAX_TICKS_NARROW_SCREEN = 8;
    const tick_num = this.approximateTickNumber();
    let tick_factor;
    if(max_width > 375){
      tick_factor = tick_num > MAX_TICKS_WIDE_SCREEN ? tick_num / MAX_TICKS_WIDE_SCREEN : 1.0;
    }
    else{
       tick_factor = tick_num > MAX_TICKS_NARROW_SCREEN ? tick_num / MAX_TICKS_NARROW_SCREEN : 1.0;
    }
    // let tick_factor = tick_num > MAX_TICKS ? tick_num / MAX_TICKS : 1.0;
    // tick factor = actual number of ticks / maximum number of ticks
    // (but never smaller than 1)
    // set the dimensions and margins of the graph
    const margin = {
        top: 25,
        right: 90,
        bottom: 71,
        left: 0,
      },
      width = max_width - margin.left - margin.right,
      // a maximum of 10 ticks at once
      height = max_height - margin.top - margin.bottom;
    let total_width = width * tick_factor; // multiply width by tick factor to get the total width, so that we only display
    // on mobile renderGraph() sometimes seems to get called with too small clientHeights
    // since it gets called again later with the correct height, we can skip further execution for now
    if (height < 0) return;

    let svg,
      // fixedArea,
      xAxis,
      yAxis,
      yearAxis, // second y axis only showing the current year
      clipMask,
      focusLine,
      focusDate, // date under the vertical line on the right most side of the chart
      focusDateBG,
      focusValueBG;

    let unique_identifier,
      element, // html element containing the chart
      elementFixed, // html element containing the fixed elements of the chart
      x, // domain function for determining pixels from x domain
      y, // domain function for determining pixels from y domain
      x_extent, // x domain extent as array [min,max]
      y_extent, // y domain extent as array [min,max]
      bisect, // gets the next x value in the dataset from a given x value (date)
      currentData, // is set to the right most data point
      sx, // horizontal pixel position of the right most data point
      sy, // vertical pixel position of the right most data point
      warning, // whether right most data point is in a warning range
      critical, // whether right most data point is in a critical range
      noData, // true if we literally don't have any data
      gantt_bars;

    const drawChart = () => {
      init();
      drawAxes();
      if (!noData) drawFocus();
      drawClipmask();
      drawHorizontalLines();
      if (!noData) drawGanttBars();
      initBrushing();
      if (noData) drawNoDataIndicator();
    };

    const init = () => {
      unique_identifier = generateUniqueIdentifier();
      element = this.chartContainer.nativeElement;
      noData = this.data.length < 1;
      // avoid errors
      if (noData) {
        this.data = [
          {
            date: new Date(new Date().setDate(new Date().getDate() - 7)),
            value: 0,
            text: '0',
          },
          { date: new Date(), value: 100, text: '100' },
        ];
      }
      x_extent = d3.extent(this.data, (d) => {
        return d.date;
      });
      if (!this.x_domain) this.x_domain = [x_extent[0], x_extent[1]];
      y_extent = [
        Math.min(
          d3.min(this.data, (d) => {
            return +d.value;
          }),
          d3.min(this.ganttChartConfig.brackets, (d) => d.min)
        ),
        Math.max(
          d3.max(this.data, (d) => {
            return +d.value;
          }),
          d3.max(this.ganttChartConfig.brackets, (d) => d.max)
        ),
      ];

      // if gantt, transform data to gantt format first

      gantt_bars = [];

      for (let i = 0; i < this.data.length; i++) {
        const val = this.data[i].value;
        const timestamp = this.data[i].date;
        const bracketIndex = this.ganttChartConfig.brackets.findIndex(
          (_bracket) => val > _bracket.min && val < _bracket.max
        );
        const bracket = this.ganttChartConfig.brackets[bracketIndex];
        if (!!bracket) {
          if (
            gantt_bars.length === 0 ||
            gantt_bars[gantt_bars.length - 1].bracketIndex !== bracketIndex
          ) {
            // if (gantt_bars.length > 0)
            //   gantt_bars[gantt_bars.length - 1].end = timestamp;
            gantt_bars.push({
              start:
                gantt_bars.length > 0
                  ? gantt_bars[gantt_bars.length - 1].end
                  : new Date(timestamp),
              end: new Date(timestamp),
              bracketIndex: bracketIndex,
            });
          } else {
            gantt_bars[gantt_bars.length - 1].end = timestamp;
          }
        }
      }

      x = d3
        .scaleTime()
        .domain(x_extent)
        .range([20, total_width - 20]);

      y = d3.scaleLinear().domain(y_extent).range([height, 0]);

      bisect = (data, _x) => {
        const bisect_right = d3.bisector((d) => {
          return d.date;
        }).right;
        const right = bisect_right(data, _x);
        if (right === 0) return right;
        const left = right - 1;
        if (!data[right]) return left;
        const diff1 = _x - data[left].date;
        const diff2 = data[right].date - _x;
        return diff1 < diff2 ? left : right;
      };

      currentData = this.data[bisect(this.data, x_extent[1])];
      sx = x(currentData.date);
      sy = y(currentData.value);

      warning = !!this.valueRanges.find(
        (range) =>
          range.type === ValueRangeType.yellow &&
          range.min <= currentData.value &&
          range.max >= currentData.value
      );
      critical = !!this.valueRanges.find(
        (range) =>
          range.type === ValueRangeType.red &&
          range.min <= currentData.value &&
          range.max >= currentData.value
      );
      initSVG();
    };

    const initSVG = () => {
      // append the svg object to the body of the page
      // element.innerHTML = '';
      // fixedArea = d3
      //   .select(elementFixed)
      //   .append('svg')
      //   .attr('id', this.id + '-fixed')
      //   .attr('width', max_width)
      //   .attr('height', max_height)
      //   .style('position', 'absolute')
      //   .style('pointer-events', 'none')
      //   .style('z-index', 2);
      svg = d3
        .select(element)
        .append('svg')
        .attr('id', this.id)
        .attr('width', total_width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      this.scrollable.scrollTo({ right: 0 });
      // svg.on('click', mousemove);

      // filters go in defs element
      const defs = svg.append('defs');

      const filter = defs
        .append('filter')
        .attr('id', 'pill-gradient')
        .attr('height', '1000%')
        .attr('width', '1000%')
        .attr('x', '-500%')
        .attr('y', '-500%');

      filter
        .append('feColorMatrix')
        .attr('type', 'matrix')
        .attr('values', '0 0 0 1 0  0 0 0 1 0  0 0 0 1 0  0 0 0 1 0');

      filter
        .append('feGaussianBlur')
        .attr('stdDeviation', 2)
        .attr('result', 'coloredBlur');

      const feMerge = filter.append('feMerge');

      feMerge.append('feMergeNode').attr('in', 'coloredBlur');
      //    .attr("in", "SourceAlpha");
    };

    const drawClipmask = () => {
      // Add a clipPath: everything out of this area won't be drawn.
      svg
        .append('defs')
        .append('svg:clipPath')
        .attr('id', 'gantt-clip-' + this.id)
        .append('svg:rect')
        .attr('width', total_width + 10)
        .attr('height', height + 10)
        .attr('x', 0)
        .attr('y', -5);

      // Create the line variable: where both the line and the brush take place
      clipMask = svg
        .append('g')
        .attr('clip-path', 'url(#gantt-clip-' + this.id + ')');
    };

    const drawAxes = () => {
      svg
        .append('rect')
        .style('fill', 'rgb(255, 255, 255)')
        .style('cursor', 'pointer')
        .attr('x', -1)
        .attr('y', 0)
        .attr('width', width + 1)
        .attr('height', height);
      if (!noData) {
        xAxis = svg
          .append('g')
          .attr('id', 'x-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height)
              .tickPadding(10)
              .tickFormat((d) => {
                return '';
              })
          )
          .call((g) =>
            g
              .selectAll('.tick line')
              .attr('stroke', '#e6e7e7')
              .style('cursor', 'pointer')
          )
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', isMobile ? '12px' : '16px')
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr('transform', 'translate(0,-3)')
          );
        yearAxis = svg
          .append('g')
          .attr('id', 'year-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height + 28)
              .ticks(d3.timeYear)
              .tickPadding(15)
              .tickFormat((d) => {
                return d3.timeFormat('%Y')(d);
              })
          )
          .call((g) => g.selectAll('.tick line').attr('stroke', '#e6e7e7'))
          .call((g) => g.select('.domain').remove())
          .call((g) => g.selectAll('.tick text').attr('color', '#afb4b9'));
      }

      const false_icon =
        '<i nz-icon nzType="icons:status-check" class="mini-svg-icon fillgreen"></i>';
      const true_icon =
        '<i nz-icon nzType="icons:status-exclamation-triangle" class="mini-svg-icon fillwhite"></i>';
      yAxis = svg
        .append('g')
        .attr('id', 'y-axis')
        .attr('transform', 'translate(' + total_width + ',0)')
        .call(
          d3
            .axisRight(y)
            .tickSize(-total_width, 0, 0)
            .ticks(6)
            .tickFormat((d) => {
              return d + ' ' + this.y_unit;
            })
        )
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', 4)
            .attr('color', '#afb4b9')
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('text-anchor', 'end')
            .attr('dx', 35)
        );

      svg.select('.axis').selectAll('text').remove();
      // fixedArea.select('.axis').selectAll('text').remove();
      // scrollArea.select('.axis').selectAll('text').remove();

      yAxis.selectAll('.tick text').remove();
      const curValue = currentData.value;
      const activeBracketIdx = this.ganttChartConfig.brackets.findIndex(
        (bracket) => bracket.min < curValue && bracket.max > curValue
      );
      for (let i = 0; i < this.ganttChartConfig.brackets.length; i++) {
        const bracket = this.ganttChartConfig.brackets[i];
        const bracket_y = (y(bracket.min) + y(bracket.max)) / 2;
        const isBracketActive = activeBracketIdx === i;
        svg
          .append('text')
          .attr('class', 'bracket-label')
          .attr('x', total_width + 12)
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('letter-spacing', 0)
          .attr('y', bracket_y + 4)
          .attr(
            'fill',
            !isBracketActive
              ? '#AFB4B9'
              : bracket.critical
              ? '#E1000F'
              : bracket.warning
              ? '#ECB102'
              : '#00AA75'
          )
          .text(bracket.label[settings.Language.abbreviation]);
        if (!!bracket.range_label) {
          svg
            .append('text')
            .attr('class', 'bracket-label')
            .attr('x', total_width + 12)
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('y', bracket_y + 22)
            .attr('fill', '#AFB4B9')
            .text(bracket.range_label);
        }
      }
    };
    let unbrushed_domain = null;
    const initBrushing = () => {
      // Add brushing
      const brush = d3
        .brushX() // Add the brush feature using the d3.brush function
        .extent([
          [0, -6],
          [total_width, height + 12],
          // making the brushing taller than the clipmask hides the top and bottom strokes so that we only have
          // solid blue lines at the left and right
        ])
        // initialise the brush area: start at 0,0 and finishes at sx,height: it means I select the whole graph area
        .on('end', () => {
          if (!d3.event.sourceEvent) return;
          const extent = d3.event.selection;
          const epsilon =
            0.008 * Math.abs(+this.x_domain[1] - +this.x_domain[0]);
          if (
            !extent ||
            !extent[0] ||
            Math.abs(+x.invert(extent[1]) - +x.invert(extent[0])) < epsilon
          ) {
            // ...
          } else {
            if (minutesBetween(x.invert(extent[0]), x.invert(extent[1])) > 60) {
              if (!unbrushed_domain) {
                unbrushed_domain = this.x_domain;
              }
              this.SetXDomain(x.invert(extent[0]), x.invert(extent[1]), true);
              if (!!this.brushCallback) this.brushCallback(this.x_domain);
            }
          }
          // This remove the grey brush area as soon as the selection has been done
          setTimeout(() => svg.select('.brush').call(brush.move, null));
        });

      this.resetBrush = () => {
        if (!!unbrushed_domain)
          this.SetXDomain(unbrushed_domain[0], unbrushed_domain[1]);

        unbrushed_domain = null;
        if (!!this.brushResetCallback) this.brushResetCallback(this.x_domain);
      };
      svg.on('dblclick', this.resetBrush);
      // Add the brushing
      clipMask.append('g').attr('class', 'brush').call(brush);
      clipMask.selectAll('.selection').attr('fill-opacity', 1);
    };
    const drawHorizontalLines = () => {
      clipMask // appends to line object for clipping purposes
        .append('line')
        .attr('class', 'total-width-line')
        .attr('x1', 0)
        .attr('x2', total_width)
        .attr('y1', y(0))
        .attr('y2', y(0))
        .attr('stroke', '#979b9b');
      clipMask
        .append('line')
        .attr('class', 'total-width-line')
        .attr('x1', 0)
        .attr('x2', total_width)
        .attr('y1', height)
        .attr('y2', height)
        .attr('stroke', '#e6e7e7');
      svg
        .append('line')
        .attr('class', 'total-width-line')
        .attr('x1', 0)
        .attr('x2', total_width)
        .attr('y1', height + 31)
        .attr('y2', height + 31)
        .attr('stroke', '#e6e7e7');
      for (let i = 0; i < this.ganttChartConfig.brackets.length; i++) {
        const bracket = this.ganttChartConfig.brackets[i];
        const bracket_y = (y(bracket.min) + y(bracket.max)) / 2;
        svg
          .append('line')
          .attr('class', 'total-width-line')
          .attr('x1', 0)
          .attr('x2', total_width)
          .attr('y1', bracket_y)
          .attr('y2', bracket_y)
          .attr(
            'stroke',
            bracket.critical
              ? '#E1000F'
              : bracket.warning
              ? '#ECB102'
              : '#00AA75'
          );
      }
    };
    const drawNoDataIndicator = () => {
      svg
        .append('text')
        .html('No data')
        .attr('y', 16)
        .attr('x', 36)
        .style('opacity', 0.6);
    };

    const drawGanttBars = () => {
      const svgBars = clipMask
        .append('g')
        .selectAll('this_is_empty')
        .data(gantt_bars)
        .enter();
      const svgBarsRects = svgBars
        .append('rect')
        .attr('class', 'gantt-bar')
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('x', (d) => x(d.start) + (x(d.end) - x(d.start) - 2 <= 0 ? 0 : 1))
        .attr(
          'y',
          (d) =>
            (y(this.ganttChartConfig.brackets[d.bracketIndex].min) +
              y(this.ganttChartConfig.brackets[d.bracketIndex].max)) /
              2 -
            11
        )
        .attr('width', (d) => Math.max(x(d.end) - x(d.start) - 2, 1))
        .attr('height', 21)
        .attr('stroke', 'none')
        .attr('fill', (d) =>
          this.ganttChartConfig.brackets[d.bracketIndex].critical
            ? '#E1000F'
            : this.ganttChartConfig.brackets[d.bracketIndex].warning
            ? '#ECB102'
            : '#00AA75'
        );
    };

    const drawFocus = () => {
      if (noData) return;
      focusLine = svg
        .append('g')
        .append('line')
        .attr('x1', total_width)
        .attr(
          'y1',
          y(
            this.y_domain
              ? this.y_domain[1]
              : d3.max(this.data, (d) => {
                  return +d.value;
                })
          )
        )
        .attr('x2', total_width)
        .attr(
          'y2',
          y(
            this.y_domain
              ? this.y_domain[0]
              : d3.min(this.data, (d) => {
                  return +d.value;
                })
          )
        )
        .attr('stroke', 'black')
        .style('cursor', 'pointer');
      focusDateBG = svg
        .append('rect')
        .style('fill', 'white')
        .attr('x', total_width)
        .attr('y', height + 7)
        .attr('width', 35)
        .attr('height', 12)
        .style('filter', 'url(#pill-gradient)')
        .attr('rx', '3')
        .attr('ry', '3')
        .style('transform', 'translate(-18px, 0px)');
      focusDate = svg
        .append('text')
        .html(
          d3.timeFormat(
            !!settings.DateFormat &&
              settings.DateFormat.formatString === 'MM/dd/yyyy'
              ? '%m/%d'
              : '%e-%m'
          )(x.invert(total_width))
        )
        .attr('font-size', isMobile ? '12px' : '16px')
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', 18)
        .attr('letter-spacing', 0)
        .attr('dy', -4)
        .attr('class', 'brushText2Time')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('y', height + (isSafari ? 16 : 21))
        .attr('x', total_width);
      focusValueBG = svg
        .append('rect')
        .style('fill', 'white')
        .attr('x', total_width)
        .attr('y', sy)
        .attr('width', 46)
        .attr('height', 12)
        .attr('opacity', 0)
        .style('filter', 'url(#pill-gradient)')
        .attr('rx', '3')
        .attr('ry', '3');
    };

    this.updateChart = () => {
      // const tick_num = this.data.filter(
      //   (d) =>
      //     d.timestamp >= this.x_domain[0] && d.timestamp <= this.x_domain[1]
      // ).length;
      // tick_factor = tick_num > MAX_TICKS ? tick_num / MAX_TICKS : 1.0;
      // tick_factor = 2.0;
      // total_width = width * tick_factor;
      // this.scrollable.scrollTo({ right: 0 });
      const tick_num = this.approximateTickNumber();
      // tick_factor = tick_num > MAX_TICKS ? tick_num / MAX_TICKS : 1.0;
      if(max_width > 375){
        tick_factor = tick_num > MAX_TICKS_WIDE_SCREEN ? tick_num / MAX_TICKS_WIDE_SCREEN : 1.0;
      }
      else{
         tick_factor = tick_num > MAX_TICKS_NARROW_SCREEN ? tick_num / MAX_TICKS_NARROW_SCREEN : 1.0;
      }

      if (total_width !== tick_factor * width) {
        updateScrollbar(tick_factor);
      }

      this.scrollable.scrollTo({ right: 0 });
      // update the chart for given boundaries / x domain
      if (!noData) updateXAxis();
      if (!noData) updateFocus();
      if (!noData) updateGanttBars();
    };

    const updateGanttBars = () => {
      clipMask
        .selectAll('.gantt-bar')
        .transition()
        .duration(1000)
        .attr('x', (d) => x(d.start) + (x(d.end) - x(d.start) - 2 <= 0 ? 0 : 1))
        .attr('width', (d) => Math.max(x(d.end) - x(d.start) - 2, 1));
    };

    const that = this;
    const updateFocus = () => {
      focusDate.html(
        d3.timeFormat(
          !!settings.DateFormat &&
            settings.DateFormat.formatString === 'MM/dd/yyyy'
            ? '%m/%d'
            : '%e-%m'
        )(x.invert(total_width - 20))
      );
    };

    const updateScrollbar = (tick_factor) => {
      // this function updates the total_width value which determines the scrollable area
      total_width = tick_factor * width;

      x.range([20, total_width - 20]);
      d3.select(element)
        .select('svg')
        .attr('width', total_width + margin.left + margin.right)
        .style('width', total_width + margin.left + margin.right + 'px');

      svg.selectAll('defs').call((defs) => {
        defs
          .select('clipPath')
          .select('rect')
          .attr('width', total_width + 10);
      });

      yAxis.attr('transform', 'translate(' + total_width + ',0)');

      clipMask.selectAll('line.total-width-line').attr('x2', total_width);
      svg.selectAll('line.total-width-line').attr('x2', total_width);

      focusLine.attr('x1', total_width).attr('x2', total_width);
      focusDateBG.attr('x', total_width);
      focusDate.attr('x', total_width);
      focusValueBG.attr('x', total_width);

      svg.selectAll('text.bracket-label').attr('x', total_width + 12);
    };

    const updateXAxis = () => {
      if (this.x_domain) {
        x.domain(this.x_domain);
      }
       x.range([20, total_width - 20]);
      if (!this.customRange) unbrushed_domain = null;

      const tickCall = generateTicks(this.x_domain[0], this.x_domain[1]);

      xAxis
        .transition()
        .duration(1000)
        .call(tickCall)
        .call((g) => g.selectAll('.tick line').attr('stroke', '#e6e7e7'))
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('color', '#afb4b9')
            .attr('font-size', isMobile ? '12px' : '16px')
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', '18px')
            .attr('letter-spacing', 0)
            .attr('transform', 'translate(0,-3)')
        );

      const yearTransitionInRange =
        d3.timeYear.range(this.x_domain[0], this.x_domain[1]).length > 0;
      yearAxis
        .transition()
        .duration(1000)
        .call(
          d3
            .axisBottom(x)
            .tickSize(height + (isSafari ? 30 : 28))
            .tickValues(
              yearTransitionInRange
                ? d3.timeYear.range(this.x_domain[0], this.x_domain[1])
                : [
                    new Date(
                      (this.x_domain[0].getTime() +
                        this.x_domain[1].getTime()) /
                        2
                    ),
                  ]
            )
            .tickPadding(15)
            .tickFormat((d) => {
              return !noData ? d3.timeFormat('%Y')(d) : '';
            })
        )
        .call((g) => g.selectAll('.tick line').attr('stroke', 'transparent'))
        .call((g) => g.select('.domain').remove())
        .call(
          (g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', isMobile ? '12px' : '16px')
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr(
                'dx',
                yearTransitionInRange ? 0 : (margin.right - margin.left) / 2
              ) // this centers the year in the wrapper when the year tick is not placed at the year transition
        )
        .call((g) =>
          g.selectAll('.tick:last-child text').attr('color', 'black')
        );
    };

    function generateTicks(start, end) {
      // const tick_num = that.data.filter(
      //   (d) =>
      //     d.timestamp >= that.x_domain[0] && d.timestamp <= that.x_domain[1]
      // ).length;
      // const small_screen1 = width < 450 || tick_num > SMALLER_TICK_THRESHOLD;
      // const small_screen2 = width < 350 || tick_num > SMALLER_TICK_THRESHOLD;
      const small_screen = width < 450;
      const timespan_days = daysBetween(start, end);
      const timespan_hours = hoursBetween(start, end);

      const locale = d3.timeFormatLocale(
        settings.Language.abbreviation === 'de' ? LOCALE_DE : LOCALE_EN
      );

      let ticks: any = d3.timeDay.every(small_screen ? 2 : 1);
      let tickFormat = (d) => {
        return d3.timeFormat(
          !!settings.DateFormat &&
            settings.DateFormat.formatString === 'MM/dd/yyyy'
            ? '%m/%d'
            : '%e-%m'
        )(d);
      };
      let tickValuesRangeEnd = end;
      let tickValuesRangeStart = start;
      if (timespan_days > 15) {
        ticks = d3.timeWeek.every(1);
        tickFormat = (d) => {
          return (
            (settings.Language.abbreviation === 'de' ? 'KW' : 'CW') +
            d3.timeFormat('%U')(d)
          );
        };
      }
      if (timespan_days > 45 || (small_screen && timespan_days > 25)) {
        ticks = d3.timeWeek.every(2);
      }
      if (timespan_days > 90 || (small_screen && timespan_days > 60)) {
        ticks = d3.timeMonth.every(1);
        // avoid jumbled month names
        if (!small_screen) {
          const tempTicks = ticks.range(
            tickValuesRangeStart,
            tickValuesRangeEnd
          );
          const widthPerTick = width / tempTicks.length;
          if (widthPerTick < 35) ticks = d3.timeMonth.every(4);
          else if (widthPerTick < 75) ticks = d3.timeMonth.every(2);
        }
        tickFormat = (d) => {
          return locale.format(small_screen ? '%b' : '%B')(d);
        };
      }
      const end_sx = total_width;
      let tickValues = ticks
        .range(tickValuesRangeStart, tickValuesRangeEnd)
        .filter((d) => {
          return (
            x(d) >=
              (timespan_days > 90 || (small_screen && timespan_days > 60)
                ? 30
                : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
          );
        }); // filter out ticks which are too far left or too far right which may result in text being cut off

      if (noData) {
        tickFormat = (d) => '';
      }
      let tickCall = d3
        .axisBottom(x)
        .tickSize(height)
        .tickValues(tickValues)
        .tickPadding(12)
        .tickFormat(tickFormat);

      if (that.customRange) {
        tickValuesRangeStart = start;
        tickValuesRangeEnd = end;
        if (timespan_days <= 15 && timespan_days > 7) {
          ticks = d3.timeDay.every(small_screen && timespan_days >= 6 ? 3 : 2);
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
        } else if (timespan_hours < 3) {
          ticks = d3.timeMinute.every(
            small_screen
              ? timespan_hours < 1
                ? 60
                : 30
              : timespan_hours < 1
              ? 30
              : 15
          );
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
        } else if (timespan_hours <= 24) {
          ticks = d3.timeHour.every(
            small_screen
              ? timespan_hours < 6
                ? 2
                : timespan_hours < 12
                ? 3
                : 6
              : timespan_hours < 6
              ? 1
              : timespan_hours < 12
              ? 2
              : 3
          );
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
          if (tickValues.length < 5 && timespan_hours < 6 && !small_screen) {
            tickValues = tickValues
              .map((d) => [
                new Date(new Date(d).setMinutes(d.getMinutes() - 30)),
                d,
                new Date(new Date(d).setMinutes(d.getMinutes() + 30)),
              ])
              .flat(2)
              .filter((d) => d < tickValuesRangeEnd)
              .filter((d) => {
                return (
                  x(d) >=
                    (timespan_days > 90 || (small_screen && timespan_days > 60)
                      ? 30
                      : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
                );
              }); // filter out ticks which are too far left or too far right which may result in text being cut off
          }
        } else if (timespan_days <= 7) {
          ticks = d3.timeDay.every(small_screen && timespan_days >= 6 ? 2 : 1);
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
          if (tickValues.length < 5 && !small_screen) {
            tickValues = tickValues
              .map((d) => [
                new Date(new Date(d).setHours(d.getHours() - 12)),
                d,
                new Date(new Date(d).setHours(d.getHours() + 12)),
              ])
              .flat(2)
              .filter((d) => d < tickValuesRangeEnd)
              .filter((d) => {
                return (
                  x(d) >=
                    (timespan_days > 90 || (small_screen && timespan_days > 60)
                      ? 30
                      : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
                );
              }); // filter out ticks which are too far left or too far right which may result in text being cut off
          }
        }

        const _locale = d3.timeFormatLocale(
          settings.Language.abbreviation === 'de' ? LOCALE_DE : LOCALE_EN
        );
        tickCall = d3
          .axisBottom(x)
          .tickSize(height)
          .tickValues(tickValues)
          .tickPadding(12)
          .tickFormat(
            timespan_days > 90 || (small_screen && timespan_days > 60)
              ? (d) => {
                  return _locale.format(small_screen ? '%b' : '%B')(d);
                }
              : timespan_days > 15
              ? (d) => {
                  return (
                    (settings.Language.abbreviation === 'de' ? 'KW' : 'CW') +
                    d3.timeFormat('%U')(d)
                  );
                }
              : timespan_days <= 1
              ? (d: Date) => {
                  return d3.timeFormat('%H:%M')(d);
                }
              : timespan_days < 7
              ? (d) => {
                  if (d.getHours() > 0 || d.getMinutes() > 0)
                    return _locale.format('%H:%M')(d);
                  else
                    return _locale.format(
                      !!settings.DateFormat &&
                        settings.DateFormat.formatString === 'MM/dd/yyyy'
                        ? '%m/%d'
                        : '%e-%m'
                    )(d);
                }
              : tickFormat
          );
      }

      return tickCall;
    }

    drawChart();
    setTimeout(() => this.updateChart(), 0);
  }

  public OnBrush(callback: (x_domain: any) => void) {
    this.brushCallback = callback;
  }

  public OnBrushReset(callback: (x_domain: any) => void) {
    this.brushResetCallback = callback;
  }

  public ResetBrush() {
    this.resetBrush(); // call to private member
  }
}

function treatAsUTC(date): number {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return +result;
}
function daysBetween(startDate, endDate): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

function hoursBetween(startDate, endDate): number {
  const millisecondsPerHour = 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerHour;
}

function minutesBetween(startDate, endDate): number {
  const millisecondsPerMinute = 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerMinute;
}

function minDate(a: Date, b: Date) {
  return a < b ? a : b;
}

function generateUniqueIdentifier() {
  const _number = Math.random(); // 0.9394456857981651
  _number.toString(36); // '0.xtis06h6'
  return _number.toString(36).substr(2, 9); // 'xtis06h6'
}

function generateCriticalGradient(ranges, y_extent, critical) {
  if (!critical) {
    return [
      {
        offset: '-100%',
        color: 'black',
      },
      {
        offset: '100%',
        color: 'black',
      },
    ];
  }
  let above_crit_offsets = [];
  let below_crit_offsets = [];

  const above_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.max <= red_range.min
      )
  );

  const below_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.min >= red_range.max
      )
  );
  const full_range = y_extent[1] - y_extent[0];
  const offset = full_range * 0.05;

  if (above_crit_range) {
    const gradient_start = above_crit_range.min - offset - y_extent[0];
    const gradient_end = above_crit_range.min + offset - y_extent[0];

    above_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
    ];
  }

  if (below_crit_range) {
    const gradient_start = below_crit_range.max - offset - y_extent[0];
    const gradient_end = below_crit_range.max + offset - y_extent[0];

    below_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
    ];
  }

  return [
    {
      offset: '-100%',
      color: below_crit_range ? '#E1000F' : 'black',
    },
    ...below_crit_offsets,
    ...above_crit_offsets,
    {
      offset: '100%',
      color: above_crit_range ? '#E1000F' : 'black',
    },
  ];
}

function pruneTimestamp(
  timestamp: Date,
  _type: TickType,
  center: boolean = false,
  roundUp: boolean = false
): Date {
  const out = new Date(timestamp);
  switch (_type) {
    case TickType.Year:
      out.setMonth(center ? 6 : 0);
      if (roundUp) {
        out.setFullYear(out.getFullYear() + 1);
      }
      out.setDate(15);
      out.setHours(12);
      break;
    case TickType.Month:
      out.setDate(center ? 15 : 1);
      if (roundUp) {
        out.setMonth(out.getMonth() + 1);
      }
      out.setHours(12);
      break;
    case TickType.Week:
      // source: https://stackoverflow.com/questions/4156434/javascript-get-the-first-day-of-the-week-from-current-date
      const day = out.getDay(),
        diff = out.getDate() - day + (center ? 3 : day === 0 ? -6 : 1); // adjust when day is sunday
      out.setDate(diff + (roundUp ? 7 : 0));
      out.setHours(12);
      break;
    case TickType.Day:
      out.setHours(center ? 12 : 0);
      if (roundUp) {
        out.setDate(out.getDate() + 1);
      }
  }
  out.setSeconds(0, 0);
  out.setMinutes(0);
  return out;
}
