import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, timer } from 'rxjs';

import { UserSettings } from '../../model/settings';
import { AssetsService } from '../../services/assets.service';
import { DocumentationService } from '../../services/documentation.service';
import { I18nService } from '../../services/i18n.service';
import { SettingsService } from '../../services/settings.service';
import { UiService } from '../../services/ui.service';
import { UtilService } from '../../services/util.service';
import { Documentation, DocumentationType } from './../../model/documentation';

@Component({
  selector: 'app-docu-wizard',
  templateUrl: './documentation-wizard.component.html',
  styleUrls: ['./documentation-wizard.component.scss'],
})
export class DocumentationWizardComponent implements AfterViewInit {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<Documentation> =
    new EventEmitter<Documentation>();

  @Input() assetId: number;
  @Input() sensorId: number;

  step: string = 'start';
  isLoading: boolean = false;
  radioValue: number = 0;

  documentation: Documentation = new Documentation();

  // get isMobile(): boolean {
  //   return this.ui.IsMobile;
  // }
  // get isTablet(): boolean {
  //   return this.ui.IsTablet;
  // }
  
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  constructor(
    private route: ActivatedRoute,
    private service: DocumentationService,
    private assets: AssetsService,
    private settings: SettingsService,
    private util: UtilService,
    public ui: UiService,
    public changeRef: ChangeDetectorRef,
    public i18n: I18nService
  ) {}

  ngAfterViewInit() {
    this.refresh(this.assetId, this.sensorId);
  }

  refresh(assetId: number, sensorId): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.changeRef.detectChanges();
    this.service
      .getUnfinishedDocumentation(assetId, sensorId)
      .subscribe((d) => {
        this.documentation = d;
        if (this.documentation.documentationType) {
          this.step = 'add';
        }
        this.isLoading = false;
        this.changeRef.detectChanges();
      });
  }

  setDocumentationType(documentationType: DocumentationType) {
    this.documentation.documentationType = documentationType;
    this.service.updateDocumentation(this.documentation).subscribe(() => {
      this.step = 'add';
      this.changeRef.detectChanges();
    });
  }

  setNote() {
    // this.documentation.note = this.currentNote;
    this.service.updateDocumentation(this.documentation).subscribe(() => {
      this.step = 'add';
      this.changeRef.detectChanges();
    });
  }

  save() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.changeRef.detectChanges();
    this.service
      .finishDocumentation(this.documentation.documentationId)
      .subscribe(() => {
        this.saved.emit(this.documentation);
        this.close.emit();
        this.isLoading = false;
        this.refreshAllAppData();
        this.changeRef.detectChanges();
      });
  }

  refreshAllAppData() {
    // this refreshes all data when you send out a documentation
    this.util.setLoadingStarted();
    this.settings.getUserSettings().subscribe((settings: UserSettings) => {
      forkJoin([
        this.assets.getRegions(true),
        this.assets.getObjectHistory(this.assetId, this.sensorId),
        this.assets.getAsset(this.assetId, true, settings.MeasurementSystem),
        this.assets.getNotifications(),
        this.settings.getUserSettings(),
        timer(1500),
      ]).subscribe(
        () => {
          this.util.setLoadingFinished();
        },
        (e) => {
          if (e.status === 401) {
            this.util.setLoadingFinished();
            return;
          }
          console.error(e);
          console.error(
            'If you see this, please forward the above error to florian.bannier@cosmoconsult.com. Thanks! :)'
          );
          this.util.setLoadingFinished(true);
        }
      );
    });
  }
}
