import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { Location } from '../../model/location';
import { I18nService } from '../../services/i18n.service';

class Breadcrumb {
  public text: string;
  public isAsset: boolean;
  public isCountryOrRegion: boolean;
  public id: number;
  public bold: boolean; // 'highlight' one or more breadcrumbs for example when one breadcrumb is a target of a current search
}
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
  @Input() object: Location = null;
  @Input() array: Location[] = [];
  @Input() isAsset: boolean = false;
  @Input() showCurrent: boolean = false;
  @Input() additionalBC: string = '';
  @Input() hightlighted: number | string = null;
  @Input() searchTealText: boolean = false;
  @Input() shorten: number = null;

  get Breadcrumbs(): Breadcrumb[] {
    if (!!this.object && this.object.breadcrumbs) {
      const bc: Breadcrumb[] = this.object.breadcrumbs
        .slice(
          this.isAsset ? 2 : !!this.shorten ? this.shorten : 0,
          this.object.breadcrumbs.length
        )
        .map((item) => {
          return {
            text: this.i18n.parse(item.objectName),
            isAsset: item.isAsset,
            isCountryOrRegion: !(
              item.curLayer && item.curLayer.charCodeAt(0) > 98
            ),
            // charCodeAt(0) > 98 -> layer is not 'a' (region) or 'b' (country)
            id: item.objectID,
            bold:
              item.searchTarget ||
              this.hightlighted === item.objectID ||
              this.hightlighted === item.objectName,
          };
        });

      if (this.showCurrent) {
        bc.push({
          text: this.i18n.parse(this.object.objectName),
          isAsset: this.object.isAsset,
          isCountryOrRegion: !(
            this.object.curLayer && this.object.curLayer.charCodeAt(0) > 98
          ),
          id:
            this.object.curLayer &&
            this.object.curLayer.charCodeAt(0) > 98
              ? this.object.objectID
              : null, // charCodeAt(0) > 98 -> layer is not 'a' (region) or 'b' (country),
          bold:
            this.hightlighted === null ||
            this.object.searchTarget ||
            this.hightlighted === this.object.objectID ||
            this.hightlighted === this.object.objectName,
        });
      }
      if (this.additionalBC && this.additionalBC !== '') {
        bc.push({
          text: this.additionalBC,
          isAsset: false,
          isCountryOrRegion: false,
          id: null,
          bold: this.hightlighted === this.additionalBC,
        });
      }
      if (bc.length > 0 && !bc.find((item) => item.bold))
        bc[bc.length - 1].bold = true; // make last item bold if no other entry is highlighted
      return bc;
    } else if (!!this.array) {
      const bc = this.array.map((item) => {
        return {
          text: this.i18n.parse(item.objectName),
          isAsset: item.isAsset,
          isCountryOrRegion: !(
            item.curLayer && item.curLayer.charCodeAt(0) > 98
          ),
          // charCodeAt(0) > 98 -> layer is not 'a' (region) or 'b' (country)
          id: item.objectID,
          bold:
            item.searchTarget ||
            this.hightlighted === item.objectID ||
            this.hightlighted === item.objectName,
        };
      });
      if (bc.length > 0 && !bc.find((item) => item.bold))
        bc[bc.length - 1].bold = true; // make last item bold if no other entry is highlighted

      return bc;
    } else return [];
  }

  public navTo(breadcrumb: Breadcrumb) {
    if (!breadcrumb || !breadcrumb.id) return;
    if (breadcrumb.isAsset) this.router.navigate(['asset', breadcrumb.id, -1]);
    else if (breadcrumb.isCountryOrRegion)
      this.router.navigate(['home', 'list', breadcrumb.id]);
    else this.router.navigate(['plant', breadcrumb.id]);
  }

  constructor(public i18n: I18nService, public router: Router) {}

  ngOnInit() {}
}
