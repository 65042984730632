import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sensor-instruction',
  templateUrl: './sensor-instruction.component.html',
  styleUrls: ['./sensor-instruction.component.scss'],
})
export class SensorInstructionComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  sensorInstructions = [
    { text: 'Overview', bold: true },
    { text: 'Before you start:' },
    {
      text: 'Verify that your personal qualification, training, and experience are suitable for the application.',
    },
    { text: '' },
    {
      text: 'Familiarize yourself with the overall repair process and the work steps in detail.',
    },
    { text: '' },
    {
      text: 'Plan the process steps in advance and be aware of the following:',
    },
    { text: ' •	accessibility of repair area' },
    { text: ' •	required tools, supplies, and equipment' },
    {
      text: ' •	environmental conditions.',
      image: 'assets/images/repair_instructions_2.png',
    },
    {
      text: 'Please refer to the Operation Manual for further information.',
      icon: 'help-round',
    },
    {
      text: 'In the event of an incident involving a leak, the repair site must first be isolated and offline from the source. The repair site must be clean and safe to perform the below operations.',
      icon: 'status-exclamation-triangle',
    },
    {
      text: 'Do not connect or disconnect sensors with the IoT unit powered ON in hazardous locations. Do not attempt to perform any repairs on the IoT unit as it is non-serviceable.',
      icon: 'status-exclamation-triangle',
    },
    { text: '.', hidden: true },
    { text: 'Place the IoT unit in Sleep Mode:' },
    {
      text: 'Place a magnet on the square indent next to the Loctite® logo and hold for 10 seconds. After 5 seconds, two beeps will be emitted, indicating a status message was sent. Continue to hold the magnet until five beeps are emitted. After five beeps, remove the magnet. The unit is now in sleep mode.',
      image: 'assets/images/repair_instructions_3.png',
    },
    {
      text: 'Disconnect the sensor chain from the IoT unit	(picture placeholder) ',
    },
    {
      text: 'Remove the entire sensor chain from the pipe(s) and discard according to local and federal regulations.',
    },
    { text: 'Repair the leak' },
    {
      text: 'Thoroughly clean the entire length of pipe to be monitored and ensure no hydrocarbons are present. ',
    },
    {
      text: 'Residual hydrocarbons can cause the new sensor to respond, causing a false alarm. This will require early replacement of the sensor and containment',
      icon: 'status-exclamation-triangle',
    },
    {
      text: 'Run the new sensor chain along the bottom of the pipe, ensuring the serial number is facing away from the pipe.',
    },
    {
      text: 'Improper orientation will cause decreased sensor performance',
      icon: 'status-exclamation-triangle',
      image: 'assets/images/repair_instructions_2.png',
    },
  ];

  constructor() {}

  ngOnInit() {}
}
